// @ts-ignore
import { getHttpClient } from "@utils/httpClient";
// @ts-ignore
import { hasProp, assert } from "@utils/assertion";
// @ts-ignore
import { isArray } from "@utils/arrayUtils";
// @ts-ignore
import { parseJsonWithNumbersAsBigint, renderJsonWithNumbersAsBigint } from "@utils/json";
// @ts-ignore
import { DateTime } from "luxon";
// @ts-ignore
import { type ReadonlyUint8Array } from "@utils/arrayUtils";
import { type ActionableListPortletView } from "./schema";
import { type ActionableListView } from "./schema";
import { type ActionableMiniView } from "./schema";
import { type ActionablePageProps } from "./schema";
import { type AdminSidebarWidgetProps } from "./schema";
import { type AdminToolLink } from "./schema";
import { type AdminToolLinkGroup } from "./schema";
import { type AdminToolLinkTargetType } from "./schema";
import { type AuthorAdminListFilters } from "./schema";
import { type AuthorAdminListView } from "./schema";
import { type AuthorEditView } from "./schema";
import { type ChannelListView } from "./schema";
import { type ConnectorCompletionExecutionLogListView } from "./schema";
import { type ConnectorCompletionFilters } from "./schema";
import { type ConnectorContentExecutionListView } from "./schema";
import { type ConnectorContentExecutionLogDetailView } from "./schema";
import { type ConnectorContentExecutionLogListView } from "./schema";
import { type ConnectorExecutionLogMetadata } from "./schema";
import { type ConnectorForm } from "./schema";
import { type ConnectorGrantType } from "./schema";
import { type ConnectorListView } from "./schema";
import { type ConnectorProduct } from "./schema";
import { type ConnectorProviderMetadata } from "./schema";
import { type ConnectorSettingsForm } from "./schema";
import { type ConnectorSettingsView } from "./schema";
import { type ConnectorSyncEntry } from "./schema";
import { type ConnectorType } from "./schema";
import { type ConnectorUserIdentifier } from "./schema";
import { type ConnectorView } from "./schema";
import { type Country } from "./schema";
import { type CustomPageAdminSearchForm } from "./schema";
import { type CustomPageCopyRequest } from "./schema";
import { type CustomPageEditForm } from "./schema";
import { type CustomPageEditListView } from "./schema";
import { type CustomPageEditView } from "./schema";
import { type CustomPageFilters } from "./schema";
import { type CustomPageHeaderLayout } from "./schema";
import { type CustomPageHighlightsForm } from "./schema";
import { type CustomPageListView } from "./schema";
import { type CustomPageLocalizationEditForm } from "./schema";
import { type CustomPageLocalizationEditView } from "./schema";
import { type CustomPageView } from "./schema";
import { type CustomerAdminDetailWidgetProps } from "./schema";
import { type DateRange } from "./schema";
import { type FilteredLearningItemAdminView } from "./schema";
import { type FormFilter } from "./schema";
import { type FormFilterOption } from "./schema";
import { type FormFilterPriority } from "./schema";
import { type FormFilterType } from "./schema";
import { type FrontendProject } from "./schema";
import { type FullSearchProps } from "./schema";
import { type FullSearchResults } from "./schema";
import { type Gender } from "./schema";
import { type GetabstractAiAnalyticsFeedbackEventForm } from "./schema";
import { type GetabstractAiAnswer } from "./schema";
import { type GetabstractAiAnswerContextDoc } from "./schema";
import { type GetabstractAiAnswerStatus } from "./schema";
import { type GetabstractAiProperties } from "./schema";
import { type GetabstractAiQuestion } from "./schema";
import { type GetabstractAiQuestionAnswers } from "./schema";
import { type ItemLayout } from "./schema";
import { type KindChannel } from "./schema";
import { type KindClassification } from "./schema";
import { type KindDynamicBoolean } from "./schema";
import { type KindDynamicNumber } from "./schema";
import { type KindDynamicString } from "./schema";
import { type KindDynamicVoid } from "./schema";
import { type KindKeyQuality } from "./schema";
import { type Language } from "./schema";
import { type LearningItemAdminActionableView } from "./schema";
import { type LearningItemAdminFilter } from "./schema";
import { type LearningItemAdminMetadataView } from "./schema";
import { type LearningItemAdminProps } from "./schema";
import { type LearningItemAdminSummaryView } from "./schema";
import { type LearningItemAdminView } from "./schema";
import { type LearningItemType } from "./schema";
import { type LearningItemTypeActionable } from "./schema";
import { type LearningItemTypeSummary } from "./schema";
import { type MyListPortletView } from "./schema";
import { type MyListView } from "./schema";
import { type NavigationDirection } from "./schema";
import { type Oauth2Flow } from "./schema";
import { type PageInfo } from "./schema";
import { type PagedActionableListView } from "./schema";
import { type PagedAuthorAdminListView } from "./schema";
import { type PagedConnectorCompletionExecutionLogListView } from "./schema";
import { type PagedConnectorContentExecutionListView } from "./schema";
import { type PagedConnectorContentExecutionLogListView } from "./schema";
import { type Person } from "./schema";
import { type PortletLayout } from "./schema";
import { type PortletMetadata } from "./schema";
import { type PortletType } from "./schema";
import { type PromoBoxEditProps } from "./schema";
import { type ReferencedItemEdit } from "./schema";
import { type ReferencedItemSaveRequest } from "./schema";
import { type ReferencedLearningItem } from "./schema";
import { type ReferencedLearningItemList } from "./schema";
import { type RightsProbability } from "./schema";
import { type RightsState } from "./schema";
import { type SearchFiltersForm } from "./schema";
import { type SummaryCardDemoProps } from "./schema";
import { type SummaryCoverInformation } from "./schema";
import { type SummaryDocumentType } from "./schema";
import { type SummaryIxStatusView } from "./schema";
import { type SummaryMetadata } from "./schema";
import { type SummarySourceType } from "./schema";
import { type Tag } from "./schema";
import { type TagAdminFilter } from "./schema";
import { type TagKind } from "./schema";
import { type TagKindChannel } from "./schema";
import { type TagKindClassification } from "./schema";
import { type TagKindDynamicBoolean } from "./schema";
import { type TagKindDynamicNumber } from "./schema";
import { type TagKindDynamicString } from "./schema";
import { type TagKindDynamicVoid } from "./schema";
import { type TagKindKeyQuality } from "./schema";
import { type TagSourceType } from "./schema";
import { type TagValidationResponse } from "./schema";
import { type TaggedItem } from "./schema";
import { type ToastState } from "./schema";
import { type ToastVariant } from "./schema";
import { type Translations } from "./schema";
import { type VerifaiCreatorPageProps } from "./schema";
import { type VueDemoProps } from "./schema";
// ActionableListPortletView
export function serialize_ActionableListPortletView(obj: ActionableListPortletView): string {
  const serialized = ((value: { items: ActionableListView[]; metadata: PortletMetadata; page: number; pageSize: number; totalCount: bigint; viewAllUri: string | null }): unknown => {
    return {
      items: ((value: ActionableListView[]): unknown => {
        return value.map(
          (value: {
            actionableId: bigint;
            activatedAt: DateTime<true> | null;
            active: boolean;
            coverUri: string;
            description: string;
            language: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr";
            modifiedAt: DateTime<true>;
            name: string;
            title: string;
          }): unknown => {
            return {
              actionableId: ((value: bigint): unknown => {
                return value;
              })(value["actionableId"]),
              activatedAt: ((value: DateTime<true> | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value.toMillis();
              })(value["activatedAt"]),
              active: ((value: boolean): unknown => {
                return value;
              })(value["active"]),
              coverUri: ((value: string): unknown => {
                return value;
              })(value["coverUri"]),
              description: ((value: string): unknown => {
                return value;
              })(value["description"]),
              language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                return value;
              })(value["language"]),
              modifiedAt: ((value: DateTime<true>): unknown => {
                return value.toMillis();
              })(value["modifiedAt"]),
              name: ((value: string): unknown => {
                return value;
              })(value["name"]),
              title: ((value: string): unknown => {
                return value;
              })(value["title"]),
            };
          },
        );
      })(value["items"]),
      metadata: ((value: {
        custom: boolean;
        defaultFilterAllLanguages: boolean;
        embedSortingFiltering: boolean;
        hideTitle: boolean;
        itemCount: number;
        itemLayout: ItemLayout;
        name: string;
        portletId: number;
        portletLayout: PortletLayout;
        subType: string | null;
        title: string;
        type: PortletType;
      }): unknown => {
        return {
          custom: ((value: boolean): unknown => {
            return value;
          })(value["custom"]),
          defaultFilterAllLanguages: ((value: boolean): unknown => {
            return value;
          })(value["defaultFilterAllLanguages"]),
          embedSortingFiltering: ((value: boolean): unknown => {
            return value;
          })(value["embedSortingFiltering"]),
          hideTitle: ((value: boolean): unknown => {
            return value;
          })(value["hideTitle"]),
          itemCount: ((value: number): unknown => {
            return value;
          })(value["itemCount"]),
          itemLayout: ((value: "MINI" | "REGULAR"): unknown => {
            return value;
          })(value["itemLayout"]),
          name: ((value: string): unknown => {
            return value;
          })(value["name"]),
          portletId: ((value: number): unknown => {
            return value;
          })(value["portletId"]),
          portletLayout: ((value: "GRID" | "CAROUSEL"): unknown => {
            return value;
          })(value["portletLayout"]),
          subType: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["subType"]),
          title: ((value: string): unknown => {
            return value;
          })(value["title"]),
          type: ((
            value:
              | "IMAGETEXT"
              | "SUMMARYLIST"
              | "TRENDINGCHANNEL"
              | "FEATUREDCHANNEL"
              | "FOLLOWINGCHANNEL"
              | "MYLIST"
              | "PRIMARYTOPICCHANNEL"
              | "CHANNELLIST"
              | "ANNOTATIONLIST"
              | "LGXPLIST"
              | "SKETCHNOTESLGXP"
              | "ACTIONABLELIST"
              | "ACTIONABLELISTCUSTOM"
              | "MYACTIONABLES"
              | "FREESUMMARY"
              | "SUMMARYLISTCUSTOM"
              | "CUSTOMPAGELIST",
          ): unknown => {
            return value;
          })(value["type"]),
        };
      })(value["metadata"]),
      page: ((value: number): unknown => {
        return value;
      })(value["page"]),
      pageSize: ((value: number): unknown => {
        return value;
      })(value["pageSize"]),
      totalCount: ((value: bigint): unknown => {
        return value;
      })(value["totalCount"]),
      viewAllUri: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["viewAllUri"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ActionableListPortletView(str: string): ActionableListPortletView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          items: ActionableListView[];
          metadata: PortletMetadata;
          page: number;
          pageSize: number;
          totalCount: bigint;
          viewAllUri: string | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "items")) {
      return { success: false };
    }
    const parsed_items = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ActionableListView[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: ActionableListView } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  actionableId: bigint;
                  activatedAt: DateTime<true> | null;
                  active: boolean;
                  coverUri: string;
                  description: string;
                  language: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr";
                  modifiedAt: DateTime<true>;
                  name: string;
                  title: string;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "actionableId")) {
              return { success: false };
            }
            const parsed_actionableId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                if (typeof value === "number") {
                  return { success: true, value: BigInt(value) };
                }
                if (typeof value === "bigint") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["actionableId"]);
            })();
            if (!parsed_actionableId.success) {
              return { success: false };
            }
            if (!hasProp(value, "activatedAt")) {
              return { success: false };
            }
            const parsed_activatedAt = (() => {
              return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "number") {
                  return { success: false };
                }
                const parsed = DateTime.fromMillis(value);
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: parsed };
              })(value["activatedAt"]);
            })();
            if (!parsed_activatedAt.success) {
              return { success: false };
            }
            if (!hasProp(value, "active")) {
              return { success: false };
            }
            const parsed_active = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["active"]);
            })();
            if (!parsed_active.success) {
              return { success: false };
            }
            if (!hasProp(value, "coverUri")) {
              return { success: false };
            }
            const parsed_coverUri = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["coverUri"]);
            })();
            if (!parsed_coverUri.success) {
              return { success: false };
            }
            if (!hasProp(value, "description")) {
              return { success: false };
            }
            const parsed_description = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["description"]);
            })();
            if (!parsed_description.success) {
              return { success: false };
            }
            if (!hasProp(value, "language")) {
              return { success: false };
            }
            const parsed_language = (() => {
              return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "en":
                    return { success: true, value: "en" };
                  case "de":
                    return { success: true, value: "de" };
                  case "es":
                    return { success: true, value: "es" };
                  case "ru":
                    return { success: true, value: "ru" };
                  case "zh":
                    return { success: true, value: "zh" };
                  case "pt":
                    return { success: true, value: "pt" };
                  case "fr":
                    return { success: true, value: "fr" };
                  default:
                    return { success: false };
                }
              })(value["language"]);
            })();
            if (!parsed_language.success) {
              return { success: false };
            }
            if (!hasProp(value, "modifiedAt")) {
              return { success: false };
            }
            const parsed_modifiedAt = (() => {
              return ((value: unknown): { success: false } | { success: true; value: DateTime<true> } => {
                if (typeof value !== "number") {
                  return { success: false };
                }
                const parsed = DateTime.fromMillis(value);
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: parsed };
              })(value["modifiedAt"]);
            })();
            if (!parsed_modifiedAt.success) {
              return { success: false };
            }
            if (!hasProp(value, "name")) {
              return { success: false };
            }
            const parsed_name = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["name"]);
            })();
            if (!parsed_name.success) {
              return { success: false };
            }
            if (!hasProp(value, "title")) {
              return { success: false };
            }
            const parsed_title = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["title"]);
            })();
            if (!parsed_title.success) {
              return { success: false };
            }
            return {
              success: true,
              value: {
                actionableId: parsed_actionableId.value,
                activatedAt: parsed_activatedAt.value,
                active: parsed_active.value,
                coverUri: parsed_coverUri.value,
                description: parsed_description.value,
                language: parsed_language.value,
                modifiedAt: parsed_modifiedAt.value,
                name: parsed_name.value,
                title: parsed_title.value,
              },
            };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["items"]);
    })();
    if (!parsed_items.success) {
      return { success: false };
    }
    if (!hasProp(value, "metadata")) {
      return { success: false };
    }
    const parsed_metadata = (() => {
      return ((value: unknown): { success: false } | { success: true; value: PortletMetadata } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                custom: boolean;
                defaultFilterAllLanguages: boolean;
                embedSortingFiltering: boolean;
                hideTitle: boolean;
                itemCount: number;
                itemLayout: ItemLayout;
                name: string;
                portletId: number;
                portletLayout: PortletLayout;
                subType: string | null;
                title: string;
                type: PortletType;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "custom")) {
            return { success: false };
          }
          const parsed_custom = (() => {
            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
              if (typeof value !== "boolean") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["custom"]);
          })();
          if (!parsed_custom.success) {
            return { success: false };
          }
          if (!hasProp(value, "defaultFilterAllLanguages")) {
            return { success: false };
          }
          const parsed_defaultFilterAllLanguages = (() => {
            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
              if (typeof value !== "boolean") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["defaultFilterAllLanguages"]);
          })();
          if (!parsed_defaultFilterAllLanguages.success) {
            return { success: false };
          }
          if (!hasProp(value, "embedSortingFiltering")) {
            return { success: false };
          }
          const parsed_embedSortingFiltering = (() => {
            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
              if (typeof value !== "boolean") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["embedSortingFiltering"]);
          })();
          if (!parsed_embedSortingFiltering.success) {
            return { success: false };
          }
          if (!hasProp(value, "hideTitle")) {
            return { success: false };
          }
          const parsed_hideTitle = (() => {
            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
              if (typeof value !== "boolean") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["hideTitle"]);
          })();
          if (!parsed_hideTitle.success) {
            return { success: false };
          }
          if (!hasProp(value, "itemCount")) {
            return { success: false };
          }
          const parsed_itemCount = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["itemCount"]);
          })();
          if (!parsed_itemCount.success) {
            return { success: false };
          }
          if (!hasProp(value, "itemLayout")) {
            return { success: false };
          }
          const parsed_itemLayout = (() => {
            return ((value: unknown): { success: false } | { success: true; value: ItemLayout } => {
              return ((value: unknown): { success: false } | { success: true; value: "MINI" | "REGULAR" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "MINI":
                    return { success: true, value: "MINI" };
                  case "REGULAR":
                    return { success: true, value: "REGULAR" };
                  default:
                    return { success: false };
                }
              })(value);
            })(value["itemLayout"]);
          })();
          if (!parsed_itemLayout.success) {
            return { success: false };
          }
          if (!hasProp(value, "name")) {
            return { success: false };
          }
          const parsed_name = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["name"]);
          })();
          if (!parsed_name.success) {
            return { success: false };
          }
          if (!hasProp(value, "portletId")) {
            return { success: false };
          }
          const parsed_portletId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["portletId"]);
          })();
          if (!parsed_portletId.success) {
            return { success: false };
          }
          if (!hasProp(value, "portletLayout")) {
            return { success: false };
          }
          const parsed_portletLayout = (() => {
            return ((value: unknown): { success: false } | { success: true; value: PortletLayout } => {
              return ((value: unknown): { success: false } | { success: true; value: "GRID" | "CAROUSEL" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "GRID":
                    return { success: true, value: "GRID" };
                  case "CAROUSEL":
                    return { success: true, value: "CAROUSEL" };
                  default:
                    return { success: false };
                }
              })(value);
            })(value["portletLayout"]);
          })();
          if (!parsed_portletLayout.success) {
            return { success: false };
          }
          if (!hasProp(value, "subType")) {
            return { success: false };
          }
          const parsed_subType = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["subType"]);
          })();
          if (!parsed_subType.success) {
            return { success: false };
          }
          if (!hasProp(value, "title")) {
            return { success: false };
          }
          const parsed_title = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["title"]);
          })();
          if (!parsed_title.success) {
            return { success: false };
          }
          if (!hasProp(value, "type")) {
            return { success: false };
          }
          const parsed_type = (() => {
            return ((value: unknown): { success: false } | { success: true; value: PortletType } => {
              return ((
                value: unknown,
              ):
                | { success: false }
                | {
                    success: true;
                    value:
                      | "IMAGETEXT"
                      | "SUMMARYLIST"
                      | "TRENDINGCHANNEL"
                      | "FEATUREDCHANNEL"
                      | "FOLLOWINGCHANNEL"
                      | "MYLIST"
                      | "PRIMARYTOPICCHANNEL"
                      | "CHANNELLIST"
                      | "ANNOTATIONLIST"
                      | "LGXPLIST"
                      | "SKETCHNOTESLGXP"
                      | "ACTIONABLELIST"
                      | "ACTIONABLELISTCUSTOM"
                      | "MYACTIONABLES"
                      | "FREESUMMARY"
                      | "SUMMARYLISTCUSTOM"
                      | "CUSTOMPAGELIST";
                  } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "IMAGETEXT":
                    return { success: true, value: "IMAGETEXT" };
                  case "SUMMARYLIST":
                    return { success: true, value: "SUMMARYLIST" };
                  case "TRENDINGCHANNEL":
                    return { success: true, value: "TRENDINGCHANNEL" };
                  case "FEATUREDCHANNEL":
                    return { success: true, value: "FEATUREDCHANNEL" };
                  case "FOLLOWINGCHANNEL":
                    return { success: true, value: "FOLLOWINGCHANNEL" };
                  case "MYLIST":
                    return { success: true, value: "MYLIST" };
                  case "PRIMARYTOPICCHANNEL":
                    return { success: true, value: "PRIMARYTOPICCHANNEL" };
                  case "CHANNELLIST":
                    return { success: true, value: "CHANNELLIST" };
                  case "ANNOTATIONLIST":
                    return { success: true, value: "ANNOTATIONLIST" };
                  case "LGXPLIST":
                    return { success: true, value: "LGXPLIST" };
                  case "SKETCHNOTESLGXP":
                    return { success: true, value: "SKETCHNOTESLGXP" };
                  case "ACTIONABLELIST":
                    return { success: true, value: "ACTIONABLELIST" };
                  case "ACTIONABLELISTCUSTOM":
                    return { success: true, value: "ACTIONABLELISTCUSTOM" };
                  case "MYACTIONABLES":
                    return { success: true, value: "MYACTIONABLES" };
                  case "FREESUMMARY":
                    return { success: true, value: "FREESUMMARY" };
                  case "SUMMARYLISTCUSTOM":
                    return { success: true, value: "SUMMARYLISTCUSTOM" };
                  case "CUSTOMPAGELIST":
                    return { success: true, value: "CUSTOMPAGELIST" };
                  default:
                    return { success: false };
                }
              })(value);
            })(value["type"]);
          })();
          if (!parsed_type.success) {
            return { success: false };
          }
          return {
            success: true,
            value: {
              custom: parsed_custom.value,
              defaultFilterAllLanguages: parsed_defaultFilterAllLanguages.value,
              embedSortingFiltering: parsed_embedSortingFiltering.value,
              hideTitle: parsed_hideTitle.value,
              itemCount: parsed_itemCount.value,
              itemLayout: parsed_itemLayout.value,
              name: parsed_name.value,
              portletId: parsed_portletId.value,
              portletLayout: parsed_portletLayout.value,
              subType: parsed_subType.value,
              title: parsed_title.value,
              type: parsed_type.value,
            },
          };
        })(value);
      })(value["metadata"]);
    })();
    if (!parsed_metadata.success) {
      return { success: false };
    }
    if (!hasProp(value, "page")) {
      return { success: false };
    }
    const parsed_page = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["page"]);
    })();
    if (!parsed_page.success) {
      return { success: false };
    }
    if (!hasProp(value, "pageSize")) {
      return { success: false };
    }
    const parsed_pageSize = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["pageSize"]);
    })();
    if (!parsed_pageSize.success) {
      return { success: false };
    }
    if (!hasProp(value, "totalCount")) {
      return { success: false };
    }
    const parsed_totalCount = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["totalCount"]);
    })();
    if (!parsed_totalCount.success) {
      return { success: false };
    }
    if (!hasProp(value, "viewAllUri")) {
      return { success: false };
    }
    const parsed_viewAllUri = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["viewAllUri"]);
    })();
    if (!parsed_viewAllUri.success) {
      return { success: false };
    }
    return { success: true, value: { items: parsed_items.value, metadata: parsed_metadata.value, page: parsed_page.value, pageSize: parsed_pageSize.value, totalCount: parsed_totalCount.value, viewAllUri: parsed_viewAllUri.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ActionableListView
export function serialize_ActionableListView(obj: ActionableListView): string {
  const serialized = ((value: {
    actionableId: bigint;
    activatedAt: DateTime<true> | null;
    active: boolean;
    coverUri: string;
    description: string;
    language: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr";
    modifiedAt: DateTime<true>;
    name: string;
    title: string;
  }): unknown => {
    return {
      actionableId: ((value: bigint): unknown => {
        return value;
      })(value["actionableId"]),
      activatedAt: ((value: DateTime<true> | null): unknown => {
        if (value === null) {
          return null;
        }
        return value.toMillis();
      })(value["activatedAt"]),
      active: ((value: boolean): unknown => {
        return value;
      })(value["active"]),
      coverUri: ((value: string): unknown => {
        return value;
      })(value["coverUri"]),
      description: ((value: string): unknown => {
        return value;
      })(value["description"]),
      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
        return value;
      })(value["language"]),
      modifiedAt: ((value: DateTime<true>): unknown => {
        return value.toMillis();
      })(value["modifiedAt"]),
      name: ((value: string): unknown => {
        return value;
      })(value["name"]),
      title: ((value: string): unknown => {
        return value;
      })(value["title"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ActionableListView(str: string): ActionableListView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          actionableId: bigint;
          activatedAt: DateTime<true> | null;
          active: boolean;
          coverUri: string;
          description: string;
          language: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr";
          modifiedAt: DateTime<true>;
          name: string;
          title: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "actionableId")) {
      return { success: false };
    }
    const parsed_actionableId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["actionableId"]);
    })();
    if (!parsed_actionableId.success) {
      return { success: false };
    }
    if (!hasProp(value, "activatedAt")) {
      return { success: false };
    }
    const parsed_activatedAt = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "number") {
          return { success: false };
        }
        const parsed = DateTime.fromMillis(value);
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["activatedAt"]);
    })();
    if (!parsed_activatedAt.success) {
      return { success: false };
    }
    if (!hasProp(value, "active")) {
      return { success: false };
    }
    const parsed_active = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["active"]);
    })();
    if (!parsed_active.success) {
      return { success: false };
    }
    if (!hasProp(value, "coverUri")) {
      return { success: false };
    }
    const parsed_coverUri = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["coverUri"]);
    })();
    if (!parsed_coverUri.success) {
      return { success: false };
    }
    if (!hasProp(value, "description")) {
      return { success: false };
    }
    const parsed_description = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["description"]);
    })();
    if (!parsed_description.success) {
      return { success: false };
    }
    if (!hasProp(value, "language")) {
      return { success: false };
    }
    const parsed_language = (() => {
      return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        switch (value) {
          case "en":
            return { success: true, value: "en" };
          case "de":
            return { success: true, value: "de" };
          case "es":
            return { success: true, value: "es" };
          case "ru":
            return { success: true, value: "ru" };
          case "zh":
            return { success: true, value: "zh" };
          case "pt":
            return { success: true, value: "pt" };
          case "fr":
            return { success: true, value: "fr" };
          default:
            return { success: false };
        }
      })(value["language"]);
    })();
    if (!parsed_language.success) {
      return { success: false };
    }
    if (!hasProp(value, "modifiedAt")) {
      return { success: false };
    }
    const parsed_modifiedAt = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> } => {
        if (typeof value !== "number") {
          return { success: false };
        }
        const parsed = DateTime.fromMillis(value);
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["modifiedAt"]);
    })();
    if (!parsed_modifiedAt.success) {
      return { success: false };
    }
    if (!hasProp(value, "name")) {
      return { success: false };
    }
    const parsed_name = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["name"]);
    })();
    if (!parsed_name.success) {
      return { success: false };
    }
    if (!hasProp(value, "title")) {
      return { success: false };
    }
    const parsed_title = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["title"]);
    })();
    if (!parsed_title.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        actionableId: parsed_actionableId.value,
        activatedAt: parsed_activatedAt.value,
        active: parsed_active.value,
        coverUri: parsed_coverUri.value,
        description: parsed_description.value,
        language: parsed_language.value,
        modifiedAt: parsed_modifiedAt.value,
        name: parsed_name.value,
        title: parsed_title.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ActionableMiniView
export function serialize_ActionableMiniView(obj: ActionableMiniView): string {
  const serialized = ((value: { actionableId: bigint; coverUri: string; name: string; title: string }): unknown => {
    return {
      actionableId: ((value: bigint): unknown => {
        return value;
      })(value["actionableId"]),
      coverUri: ((value: string): unknown => {
        return value;
      })(value["coverUri"]),
      name: ((value: string): unknown => {
        return value;
      })(value["name"]),
      title: ((value: string): unknown => {
        return value;
      })(value["title"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ActionableMiniView(str: string): ActionableMiniView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          actionableId: bigint;
          coverUri: string;
          name: string;
          title: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "actionableId")) {
      return { success: false };
    }
    const parsed_actionableId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["actionableId"]);
    })();
    if (!parsed_actionableId.success) {
      return { success: false };
    }
    if (!hasProp(value, "coverUri")) {
      return { success: false };
    }
    const parsed_coverUri = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["coverUri"]);
    })();
    if (!parsed_coverUri.success) {
      return { success: false };
    }
    if (!hasProp(value, "name")) {
      return { success: false };
    }
    const parsed_name = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["name"]);
    })();
    if (!parsed_name.success) {
      return { success: false };
    }
    if (!hasProp(value, "title")) {
      return { success: false };
    }
    const parsed_title = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["title"]);
    })();
    if (!parsed_title.success) {
      return { success: false };
    }
    return { success: true, value: { actionableId: parsed_actionableId.value, coverUri: parsed_coverUri.value, name: parsed_name.value, title: parsed_title.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ActionablePageProps
export function serialize_ActionablePageProps(obj: ActionablePageProps): string {
  const serialized = ((value: { actionableId: bigint; freeActionableActive: boolean; isMapp: boolean }): unknown => {
    return {
      actionableId: ((value: bigint): unknown => {
        return value;
      })(value["actionableId"]),
      freeActionableActive: ((value: boolean): unknown => {
        return value;
      })(value["freeActionableActive"]),
      isMapp: ((value: boolean): unknown => {
        return value;
      })(value["isMapp"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ActionablePageProps(str: string): ActionablePageProps | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          actionableId: bigint;
          freeActionableActive: boolean;
          isMapp: boolean;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "actionableId")) {
      return { success: false };
    }
    const parsed_actionableId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["actionableId"]);
    })();
    if (!parsed_actionableId.success) {
      return { success: false };
    }
    if (!hasProp(value, "freeActionableActive")) {
      return { success: false };
    }
    const parsed_freeActionableActive = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["freeActionableActive"]);
    })();
    if (!parsed_freeActionableActive.success) {
      return { success: false };
    }
    if (!hasProp(value, "isMapp")) {
      return { success: false };
    }
    const parsed_isMapp = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["isMapp"]);
    })();
    if (!parsed_isMapp.success) {
      return { success: false };
    }
    return { success: true, value: { actionableId: parsed_actionableId.value, freeActionableActive: parsed_freeActionableActive.value, isMapp: parsed_isMapp.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// AdminSidebarWidgetProps
export function serialize_AdminSidebarWidgetProps(obj: AdminSidebarWidgetProps): string {
  const serialized = ((value: { navigationItems: AdminToolLinkGroup[] }): unknown => {
    return {
      navigationItems: ((value: AdminToolLinkGroup[]): unknown => {
        return value.map((value: { ico: string | null; links: AdminToolLink[]; name: string | null }): unknown => {
          return {
            ico: ((value: string | null): unknown => {
              if (value === null) {
                return null;
              }
              return value;
            })(value["ico"]),
            links: ((value: AdminToolLink[]): unknown => {
              return value.map(
                (value: { description: string | null; hidden: boolean; ico: string | null; linkText: string | null; tabName: string | null; tabTitle: string | null; targetType: AdminToolLinkTargetType; url: string | null }): unknown => {
                  return {
                    description: ((value: string | null): unknown => {
                      if (value === null) {
                        return null;
                      }
                      return value;
                    })(value["description"]),
                    hidden: ((value: boolean): unknown => {
                      return value;
                    })(value["hidden"]),
                    ico: ((value: string | null): unknown => {
                      if (value === null) {
                        return null;
                      }
                      return value;
                    })(value["ico"]),
                    linkText: ((value: string | null): unknown => {
                      if (value === null) {
                        return null;
                      }
                      return value;
                    })(value["linkText"]),
                    tabName: ((value: string | null): unknown => {
                      if (value === null) {
                        return null;
                      }
                      return value;
                    })(value["tabName"]),
                    tabTitle: ((value: string | null): unknown => {
                      if (value === null) {
                        return null;
                      }
                      return value;
                    })(value["tabTitle"]),
                    targetType: ((value: "SELF" | "BLANK" | "ROUTER"): unknown => {
                      return value;
                    })(value["targetType"]),
                    url: ((value: string | null): unknown => {
                      if (value === null) {
                        return null;
                      }
                      return value;
                    })(value["url"]),
                  };
                },
              );
            })(value["links"]),
            name: ((value: string | null): unknown => {
              if (value === null) {
                return null;
              }
              return value;
            })(value["name"]),
          };
        });
      })(value["navigationItems"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_AdminSidebarWidgetProps(str: string): AdminSidebarWidgetProps | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          navigationItems: AdminToolLinkGroup[];
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "navigationItems")) {
      return { success: false };
    }
    const parsed_navigationItems = (() => {
      return ((value: unknown): { success: false } | { success: true; value: AdminToolLinkGroup[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: AdminToolLinkGroup } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  ico: string | null;
                  links: AdminToolLink[];
                  name: string | null;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "ico")) {
              return { success: false };
            }
            const parsed_ico = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["ico"]);
            })();
            if (!parsed_ico.success) {
              return { success: false };
            }
            if (!hasProp(value, "links")) {
              return { success: false };
            }
            const parsed_links = (() => {
              return ((value: unknown): { success: false } | { success: true; value: AdminToolLink[] } => {
                if (!isArray(value)) {
                  return { success: false };
                }
                const parsed = value.map((value: unknown): { success: false } | { success: true; value: AdminToolLink } => {
                  return ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          description: string | null;
                          hidden: boolean;
                          ico: string | null;
                          linkText: string | null;
                          tabName: string | null;
                          tabTitle: string | null;
                          targetType: AdminToolLinkTargetType;
                          url: string | null;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "description")) {
                      return { success: false };
                    }
                    const parsed_description = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                        if (value === null) {
                          return { success: true, value: null };
                        }
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["description"]);
                    })();
                    if (!parsed_description.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "hidden")) {
                      return { success: false };
                    }
                    const parsed_hidden = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["hidden"]);
                    })();
                    if (!parsed_hidden.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "ico")) {
                      return { success: false };
                    }
                    const parsed_ico = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                        if (value === null) {
                          return { success: true, value: null };
                        }
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["ico"]);
                    })();
                    if (!parsed_ico.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "linkText")) {
                      return { success: false };
                    }
                    const parsed_linkText = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                        if (value === null) {
                          return { success: true, value: null };
                        }
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["linkText"]);
                    })();
                    if (!parsed_linkText.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tabName")) {
                      return { success: false };
                    }
                    const parsed_tabName = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                        if (value === null) {
                          return { success: true, value: null };
                        }
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["tabName"]);
                    })();
                    if (!parsed_tabName.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tabTitle")) {
                      return { success: false };
                    }
                    const parsed_tabTitle = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                        if (value === null) {
                          return { success: true, value: null };
                        }
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["tabTitle"]);
                    })();
                    if (!parsed_tabTitle.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "targetType")) {
                      return { success: false };
                    }
                    const parsed_targetType = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: AdminToolLinkTargetType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "SELF" | "BLANK" | "ROUTER" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "SELF":
                              return { success: true, value: "SELF" };
                            case "BLANK":
                              return { success: true, value: "BLANK" };
                            case "ROUTER":
                              return { success: true, value: "ROUTER" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["targetType"]);
                    })();
                    if (!parsed_targetType.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "url")) {
                      return { success: false };
                    }
                    const parsed_url = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                        if (value === null) {
                          return { success: true, value: null };
                        }
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["url"]);
                    })();
                    if (!parsed_url.success) {
                      return { success: false };
                    }
                    return {
                      success: true,
                      value: {
                        description: parsed_description.value,
                        hidden: parsed_hidden.value,
                        ico: parsed_ico.value,
                        linkText: parsed_linkText.value,
                        tabName: parsed_tabName.value,
                        tabTitle: parsed_tabTitle.value,
                        targetType: parsed_targetType.value,
                        url: parsed_url.value,
                      },
                    };
                  })(value);
                });
                if (parsed.find((value) => !value.success) !== undefined) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: parsed.map((entry) => {
                    assert(entry.success == true);
                    return entry.value;
                  }),
                };
              })(value["links"]);
            })();
            if (!parsed_links.success) {
              return { success: false };
            }
            if (!hasProp(value, "name")) {
              return { success: false };
            }
            const parsed_name = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["name"]);
            })();
            if (!parsed_name.success) {
              return { success: false };
            }
            return { success: true, value: { ico: parsed_ico.value, links: parsed_links.value, name: parsed_name.value } };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["navigationItems"]);
    })();
    if (!parsed_navigationItems.success) {
      return { success: false };
    }
    return { success: true, value: { navigationItems: parsed_navigationItems.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// AdminToolLink
export function serialize_AdminToolLink(obj: AdminToolLink): string {
  const serialized = ((value: {
    description: string | null;
    hidden: boolean;
    ico: string | null;
    linkText: string | null;
    tabName: string | null;
    tabTitle: string | null;
    targetType: AdminToolLinkTargetType;
    url: string | null;
  }): unknown => {
    return {
      description: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["description"]),
      hidden: ((value: boolean): unknown => {
        return value;
      })(value["hidden"]),
      ico: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["ico"]),
      linkText: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["linkText"]),
      tabName: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["tabName"]),
      tabTitle: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["tabTitle"]),
      targetType: ((value: "SELF" | "BLANK" | "ROUTER"): unknown => {
        return value;
      })(value["targetType"]),
      url: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["url"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_AdminToolLink(str: string): AdminToolLink | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          description: string | null;
          hidden: boolean;
          ico: string | null;
          linkText: string | null;
          tabName: string | null;
          tabTitle: string | null;
          targetType: AdminToolLinkTargetType;
          url: string | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "description")) {
      return { success: false };
    }
    const parsed_description = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["description"]);
    })();
    if (!parsed_description.success) {
      return { success: false };
    }
    if (!hasProp(value, "hidden")) {
      return { success: false };
    }
    const parsed_hidden = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["hidden"]);
    })();
    if (!parsed_hidden.success) {
      return { success: false };
    }
    if (!hasProp(value, "ico")) {
      return { success: false };
    }
    const parsed_ico = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["ico"]);
    })();
    if (!parsed_ico.success) {
      return { success: false };
    }
    if (!hasProp(value, "linkText")) {
      return { success: false };
    }
    const parsed_linkText = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["linkText"]);
    })();
    if (!parsed_linkText.success) {
      return { success: false };
    }
    if (!hasProp(value, "tabName")) {
      return { success: false };
    }
    const parsed_tabName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["tabName"]);
    })();
    if (!parsed_tabName.success) {
      return { success: false };
    }
    if (!hasProp(value, "tabTitle")) {
      return { success: false };
    }
    const parsed_tabTitle = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["tabTitle"]);
    })();
    if (!parsed_tabTitle.success) {
      return { success: false };
    }
    if (!hasProp(value, "targetType")) {
      return { success: false };
    }
    const parsed_targetType = (() => {
      return ((value: unknown): { success: false } | { success: true; value: AdminToolLinkTargetType } => {
        return ((value: unknown): { success: false } | { success: true; value: "SELF" | "BLANK" | "ROUTER" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "SELF":
              return { success: true, value: "SELF" };
            case "BLANK":
              return { success: true, value: "BLANK" };
            case "ROUTER":
              return { success: true, value: "ROUTER" };
            default:
              return { success: false };
          }
        })(value);
      })(value["targetType"]);
    })();
    if (!parsed_targetType.success) {
      return { success: false };
    }
    if (!hasProp(value, "url")) {
      return { success: false };
    }
    const parsed_url = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["url"]);
    })();
    if (!parsed_url.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        description: parsed_description.value,
        hidden: parsed_hidden.value,
        ico: parsed_ico.value,
        linkText: parsed_linkText.value,
        tabName: parsed_tabName.value,
        tabTitle: parsed_tabTitle.value,
        targetType: parsed_targetType.value,
        url: parsed_url.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// AdminToolLinkGroup
export function serialize_AdminToolLinkGroup(obj: AdminToolLinkGroup): string {
  const serialized = ((value: { ico: string | null; links: AdminToolLink[]; name: string | null }): unknown => {
    return {
      ico: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["ico"]),
      links: ((value: AdminToolLink[]): unknown => {
        return value.map(
          (value: { description: string | null; hidden: boolean; ico: string | null; linkText: string | null; tabName: string | null; tabTitle: string | null; targetType: AdminToolLinkTargetType; url: string | null }): unknown => {
            return {
              description: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["description"]),
              hidden: ((value: boolean): unknown => {
                return value;
              })(value["hidden"]),
              ico: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["ico"]),
              linkText: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["linkText"]),
              tabName: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["tabName"]),
              tabTitle: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["tabTitle"]),
              targetType: ((value: "SELF" | "BLANK" | "ROUTER"): unknown => {
                return value;
              })(value["targetType"]),
              url: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["url"]),
            };
          },
        );
      })(value["links"]),
      name: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["name"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_AdminToolLinkGroup(str: string): AdminToolLinkGroup | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          ico: string | null;
          links: AdminToolLink[];
          name: string | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "ico")) {
      return { success: false };
    }
    const parsed_ico = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["ico"]);
    })();
    if (!parsed_ico.success) {
      return { success: false };
    }
    if (!hasProp(value, "links")) {
      return { success: false };
    }
    const parsed_links = (() => {
      return ((value: unknown): { success: false } | { success: true; value: AdminToolLink[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: AdminToolLink } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  description: string | null;
                  hidden: boolean;
                  ico: string | null;
                  linkText: string | null;
                  tabName: string | null;
                  tabTitle: string | null;
                  targetType: AdminToolLinkTargetType;
                  url: string | null;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "description")) {
              return { success: false };
            }
            const parsed_description = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["description"]);
            })();
            if (!parsed_description.success) {
              return { success: false };
            }
            if (!hasProp(value, "hidden")) {
              return { success: false };
            }
            const parsed_hidden = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["hidden"]);
            })();
            if (!parsed_hidden.success) {
              return { success: false };
            }
            if (!hasProp(value, "ico")) {
              return { success: false };
            }
            const parsed_ico = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["ico"]);
            })();
            if (!parsed_ico.success) {
              return { success: false };
            }
            if (!hasProp(value, "linkText")) {
              return { success: false };
            }
            const parsed_linkText = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["linkText"]);
            })();
            if (!parsed_linkText.success) {
              return { success: false };
            }
            if (!hasProp(value, "tabName")) {
              return { success: false };
            }
            const parsed_tabName = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["tabName"]);
            })();
            if (!parsed_tabName.success) {
              return { success: false };
            }
            if (!hasProp(value, "tabTitle")) {
              return { success: false };
            }
            const parsed_tabTitle = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["tabTitle"]);
            })();
            if (!parsed_tabTitle.success) {
              return { success: false };
            }
            if (!hasProp(value, "targetType")) {
              return { success: false };
            }
            const parsed_targetType = (() => {
              return ((value: unknown): { success: false } | { success: true; value: AdminToolLinkTargetType } => {
                return ((value: unknown): { success: false } | { success: true; value: "SELF" | "BLANK" | "ROUTER" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "SELF":
                      return { success: true, value: "SELF" };
                    case "BLANK":
                      return { success: true, value: "BLANK" };
                    case "ROUTER":
                      return { success: true, value: "ROUTER" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["targetType"]);
            })();
            if (!parsed_targetType.success) {
              return { success: false };
            }
            if (!hasProp(value, "url")) {
              return { success: false };
            }
            const parsed_url = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["url"]);
            })();
            if (!parsed_url.success) {
              return { success: false };
            }
            return {
              success: true,
              value: {
                description: parsed_description.value,
                hidden: parsed_hidden.value,
                ico: parsed_ico.value,
                linkText: parsed_linkText.value,
                tabName: parsed_tabName.value,
                tabTitle: parsed_tabTitle.value,
                targetType: parsed_targetType.value,
                url: parsed_url.value,
              },
            };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["links"]);
    })();
    if (!parsed_links.success) {
      return { success: false };
    }
    if (!hasProp(value, "name")) {
      return { success: false };
    }
    const parsed_name = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["name"]);
    })();
    if (!parsed_name.success) {
      return { success: false };
    }
    return { success: true, value: { ico: parsed_ico.value, links: parsed_links.value, name: parsed_name.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// AdminToolLinkTargetType
export function serialize_AdminToolLinkTargetType(obj: AdminToolLinkTargetType): string {
  const serialized = ((value: "SELF" | "BLANK" | "ROUTER"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_AdminToolLinkTargetType(str: string): AdminToolLinkTargetType | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "SELF" | "BLANK" | "ROUTER" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "SELF":
        return { success: true, value: "SELF" };
      case "BLANK":
        return { success: true, value: "BLANK" };
      case "ROUTER":
        return { success: true, value: "ROUTER" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// AuthorAdminListFilters
export function serialize_AuthorAdminListFilters(obj: AuthorAdminListFilters): string {
  const serialized = ((value: { authorId: string | null; channels: string | null; firstName: string | null; gender: string | null; lastName: string | null; middleName: string | null }): unknown => {
    return {
      authorId: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["authorId"]),
      channels: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["channels"]),
      firstName: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["firstName"]),
      gender: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["gender"]),
      lastName: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["lastName"]),
      middleName: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["middleName"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_AuthorAdminListFilters(str: string): AuthorAdminListFilters | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          authorId: string | null;
          channels: string | null;
          firstName: string | null;
          gender: string | null;
          lastName: string | null;
          middleName: string | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "authorId")) {
      return { success: false };
    }
    const parsed_authorId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["authorId"]);
    })();
    if (!parsed_authorId.success) {
      return { success: false };
    }
    if (!hasProp(value, "channels")) {
      return { success: false };
    }
    const parsed_channels = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["channels"]);
    })();
    if (!parsed_channels.success) {
      return { success: false };
    }
    if (!hasProp(value, "firstName")) {
      return { success: false };
    }
    const parsed_firstName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["firstName"]);
    })();
    if (!parsed_firstName.success) {
      return { success: false };
    }
    if (!hasProp(value, "gender")) {
      return { success: false };
    }
    const parsed_gender = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["gender"]);
    })();
    if (!parsed_gender.success) {
      return { success: false };
    }
    if (!hasProp(value, "lastName")) {
      return { success: false };
    }
    const parsed_lastName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["lastName"]);
    })();
    if (!parsed_lastName.success) {
      return { success: false };
    }
    if (!hasProp(value, "middleName")) {
      return { success: false };
    }
    const parsed_middleName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["middleName"]);
    })();
    if (!parsed_middleName.success) {
      return { success: false };
    }
    return { success: true, value: { authorId: parsed_authorId.value, channels: parsed_channels.value, firstName: parsed_firstName.value, gender: parsed_gender.value, lastName: parsed_lastName.value, middleName: parsed_middleName.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// AuthorAdminListView
export function serialize_AuthorAdminListView(obj: AuthorAdminListView): string {
  const serialized = ((value: { authorId: bigint; channels: string[]; firstName: string; gender: Gender; lastName: string | null; middleName: string | null }): unknown => {
    return {
      authorId: ((value: bigint): unknown => {
        return value;
      })(value["authorId"]),
      channels: ((value: string[]): unknown => {
        return value.map((value: string): unknown => {
          return value;
        });
      })(value["channels"]),
      firstName: ((value: string): unknown => {
        return value;
      })(value["firstName"]),
      gender: ((value: "FEMALE" | "MALE" | "UNKNOWN" | "CUSTOM"): unknown => {
        return value;
      })(value["gender"]),
      lastName: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["lastName"]),
      middleName: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["middleName"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_AuthorAdminListView(str: string): AuthorAdminListView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          authorId: bigint;
          channels: string[];
          firstName: string;
          gender: Gender;
          lastName: string | null;
          middleName: string | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "authorId")) {
      return { success: false };
    }
    const parsed_authorId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["authorId"]);
    })();
    if (!parsed_authorId.success) {
      return { success: false };
    }
    if (!hasProp(value, "channels")) {
      return { success: false };
    }
    const parsed_channels = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          return { success: true, value: value };
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["channels"]);
    })();
    if (!parsed_channels.success) {
      return { success: false };
    }
    if (!hasProp(value, "firstName")) {
      return { success: false };
    }
    const parsed_firstName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["firstName"]);
    })();
    if (!parsed_firstName.success) {
      return { success: false };
    }
    if (!hasProp(value, "gender")) {
      return { success: false };
    }
    const parsed_gender = (() => {
      return ((value: unknown): { success: false } | { success: true; value: Gender } => {
        return ((value: unknown): { success: false } | { success: true; value: "FEMALE" | "MALE" | "UNKNOWN" | "CUSTOM" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "FEMALE":
              return { success: true, value: "FEMALE" };
            case "MALE":
              return { success: true, value: "MALE" };
            case "UNKNOWN":
              return { success: true, value: "UNKNOWN" };
            case "CUSTOM":
              return { success: true, value: "CUSTOM" };
            default:
              return { success: false };
          }
        })(value);
      })(value["gender"]);
    })();
    if (!parsed_gender.success) {
      return { success: false };
    }
    if (!hasProp(value, "lastName")) {
      return { success: false };
    }
    const parsed_lastName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["lastName"]);
    })();
    if (!parsed_lastName.success) {
      return { success: false };
    }
    if (!hasProp(value, "middleName")) {
      return { success: false };
    }
    const parsed_middleName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["middleName"]);
    })();
    if (!parsed_middleName.success) {
      return { success: false };
    }
    return { success: true, value: { authorId: parsed_authorId.value, channels: parsed_channels.value, firstName: parsed_firstName.value, gender: parsed_gender.value, lastName: parsed_lastName.value, middleName: parsed_middleName.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// AuthorEditView
export function serialize_AuthorEditView(obj: AuthorEditView): string {
  const serialized = ((value: { authorId: bigint | null; firstName: string; gender: Gender; ignoringWarning: boolean; lastName: string | null; middleName: string | null }): unknown => {
    return {
      authorId: ((value: bigint | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["authorId"]),
      firstName: ((value: string): unknown => {
        return value;
      })(value["firstName"]),
      gender: ((value: "FEMALE" | "MALE" | "UNKNOWN" | "CUSTOM"): unknown => {
        return value;
      })(value["gender"]),
      ignoringWarning: ((value: boolean): unknown => {
        return value;
      })(value["ignoringWarning"]),
      lastName: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["lastName"]),
      middleName: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["middleName"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_AuthorEditView(str: string): AuthorEditView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          authorId: bigint | null;
          firstName: string;
          gender: Gender;
          ignoringWarning: boolean;
          lastName: string | null;
          middleName: string | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "authorId")) {
      return { success: false };
    }
    const parsed_authorId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["authorId"]);
    })();
    if (!parsed_authorId.success) {
      return { success: false };
    }
    if (!hasProp(value, "firstName")) {
      return { success: false };
    }
    const parsed_firstName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["firstName"]);
    })();
    if (!parsed_firstName.success) {
      return { success: false };
    }
    if (!hasProp(value, "gender")) {
      return { success: false };
    }
    const parsed_gender = (() => {
      return ((value: unknown): { success: false } | { success: true; value: Gender } => {
        return ((value: unknown): { success: false } | { success: true; value: "FEMALE" | "MALE" | "UNKNOWN" | "CUSTOM" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "FEMALE":
              return { success: true, value: "FEMALE" };
            case "MALE":
              return { success: true, value: "MALE" };
            case "UNKNOWN":
              return { success: true, value: "UNKNOWN" };
            case "CUSTOM":
              return { success: true, value: "CUSTOM" };
            default:
              return { success: false };
          }
        })(value);
      })(value["gender"]);
    })();
    if (!parsed_gender.success) {
      return { success: false };
    }
    if (!hasProp(value, "ignoringWarning")) {
      return { success: false };
    }
    const parsed_ignoringWarning = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["ignoringWarning"]);
    })();
    if (!parsed_ignoringWarning.success) {
      return { success: false };
    }
    if (!hasProp(value, "lastName")) {
      return { success: false };
    }
    const parsed_lastName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["lastName"]);
    })();
    if (!parsed_lastName.success) {
      return { success: false };
    }
    if (!hasProp(value, "middleName")) {
      return { success: false };
    }
    const parsed_middleName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["middleName"]);
    })();
    if (!parsed_middleName.success) {
      return { success: false };
    }
    return {
      success: true,
      value: { authorId: parsed_authorId.value, firstName: parsed_firstName.value, gender: parsed_gender.value, ignoringWarning: parsed_ignoringWarning.value, lastName: parsed_lastName.value, middleName: parsed_middleName.value },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ChannelListView
export function serialize_ChannelListView(obj: ChannelListView): string {
  const serialized = ((value: {
    activationTime: DateTime<true> | null;
    active: boolean;
    channelId: bigint;
    coverUri: string | null;
    definition: string | null;
    descriptionHtml: string | null;
    modificationTime: DateTime<true> | null;
    name: string | null;
    readingProgress: number;
    synonyms: string | null;
    title: string | null;
  }): unknown => {
    return {
      activationTime: ((value: DateTime<true> | null): unknown => {
        if (value === null) {
          return null;
        }
        return value.toMillis();
      })(value["activationTime"]),
      active: ((value: boolean): unknown => {
        return value;
      })(value["active"]),
      channelId: ((value: bigint): unknown => {
        return value;
      })(value["channelId"]),
      coverUri: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["coverUri"]),
      definition: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["definition"]),
      descriptionHtml: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["descriptionHtml"]),
      modificationTime: ((value: DateTime<true> | null): unknown => {
        if (value === null) {
          return null;
        }
        return value.toMillis();
      })(value["modificationTime"]),
      name: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["name"]),
      readingProgress: ((value: number): unknown => {
        return value;
      })(value["readingProgress"]),
      synonyms: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["synonyms"]),
      title: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["title"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ChannelListView(str: string): ChannelListView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          activationTime: DateTime<true> | null;
          active: boolean;
          channelId: bigint;
          coverUri: string | null;
          definition: string | null;
          descriptionHtml: string | null;
          modificationTime: DateTime<true> | null;
          name: string | null;
          readingProgress: number;
          synonyms: string | null;
          title: string | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "activationTime")) {
      return { success: false };
    }
    const parsed_activationTime = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "number") {
          return { success: false };
        }
        const parsed = DateTime.fromMillis(value);
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["activationTime"]);
    })();
    if (!parsed_activationTime.success) {
      return { success: false };
    }
    if (!hasProp(value, "active")) {
      return { success: false };
    }
    const parsed_active = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["active"]);
    })();
    if (!parsed_active.success) {
      return { success: false };
    }
    if (!hasProp(value, "channelId")) {
      return { success: false };
    }
    const parsed_channelId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["channelId"]);
    })();
    if (!parsed_channelId.success) {
      return { success: false };
    }
    if (!hasProp(value, "coverUri")) {
      return { success: false };
    }
    const parsed_coverUri = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["coverUri"]);
    })();
    if (!parsed_coverUri.success) {
      return { success: false };
    }
    if (!hasProp(value, "definition")) {
      return { success: false };
    }
    const parsed_definition = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["definition"]);
    })();
    if (!parsed_definition.success) {
      return { success: false };
    }
    if (!hasProp(value, "descriptionHtml")) {
      return { success: false };
    }
    const parsed_descriptionHtml = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["descriptionHtml"]);
    })();
    if (!parsed_descriptionHtml.success) {
      return { success: false };
    }
    if (!hasProp(value, "modificationTime")) {
      return { success: false };
    }
    const parsed_modificationTime = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "number") {
          return { success: false };
        }
        const parsed = DateTime.fromMillis(value);
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["modificationTime"]);
    })();
    if (!parsed_modificationTime.success) {
      return { success: false };
    }
    if (!hasProp(value, "name")) {
      return { success: false };
    }
    const parsed_name = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["name"]);
    })();
    if (!parsed_name.success) {
      return { success: false };
    }
    if (!hasProp(value, "readingProgress")) {
      return { success: false };
    }
    const parsed_readingProgress = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        if (typeof value === "bigint") {
          return { success: true, value: Number(value) };
        }
        return { success: false };
      })(value["readingProgress"]);
    })();
    if (!parsed_readingProgress.success) {
      return { success: false };
    }
    if (!hasProp(value, "synonyms")) {
      return { success: false };
    }
    const parsed_synonyms = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["synonyms"]);
    })();
    if (!parsed_synonyms.success) {
      return { success: false };
    }
    if (!hasProp(value, "title")) {
      return { success: false };
    }
    const parsed_title = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["title"]);
    })();
    if (!parsed_title.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        activationTime: parsed_activationTime.value,
        active: parsed_active.value,
        channelId: parsed_channelId.value,
        coverUri: parsed_coverUri.value,
        definition: parsed_definition.value,
        descriptionHtml: parsed_descriptionHtml.value,
        modificationTime: parsed_modificationTime.value,
        name: parsed_name.value,
        readingProgress: parsed_readingProgress.value,
        synonyms: parsed_synonyms.value,
        title: parsed_title.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ConnectorCompletionExecutionLogListView
export function serialize_ConnectorCompletionExecutionLogListView(obj: ConnectorCompletionExecutionLogListView): string {
  const serialized = ((value: {
    completedAt: DateTime<true> | null;
    connectorExecutionLogId: bigint;
    contentId: string;
    method: string;
    request: string;
    response: string;
    statusCode: number;
    syncedAt: DateTime<true>;
    userId: string;
  }): unknown => {
    return {
      completedAt: ((value: DateTime<true> | null): unknown => {
        if (value === null) {
          return null;
        }
        return value.toISO();
      })(value["completedAt"]),
      connectorExecutionLogId: ((value: bigint): unknown => {
        return value;
      })(value["connectorExecutionLogId"]),
      contentId: ((value: string): unknown => {
        return value;
      })(value["contentId"]),
      method: ((value: string): unknown => {
        return value;
      })(value["method"]),
      request: ((value: string): unknown => {
        return value;
      })(value["request"]),
      response: ((value: string): unknown => {
        return value;
      })(value["response"]),
      statusCode: ((value: number): unknown => {
        return value;
      })(value["statusCode"]),
      syncedAt: ((value: DateTime<true>): unknown => {
        return value.toISO();
      })(value["syncedAt"]),
      userId: ((value: string): unknown => {
        return value;
      })(value["userId"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ConnectorCompletionExecutionLogListView(str: string): ConnectorCompletionExecutionLogListView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          completedAt: DateTime<true> | null;
          connectorExecutionLogId: bigint;
          contentId: string;
          method: string;
          request: string;
          response: string;
          statusCode: number;
          syncedAt: DateTime<true>;
          userId: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "completedAt")) {
      return { success: false };
    }
    const parsed_completedAt = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        const parsed = DateTime.fromISO(value.toUpperCase(), { setZone: true });
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["completedAt"]);
    })();
    if (!parsed_completedAt.success) {
      return { success: false };
    }
    if (!hasProp(value, "connectorExecutionLogId")) {
      return { success: false };
    }
    const parsed_connectorExecutionLogId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["connectorExecutionLogId"]);
    })();
    if (!parsed_connectorExecutionLogId.success) {
      return { success: false };
    }
    if (!hasProp(value, "contentId")) {
      return { success: false };
    }
    const parsed_contentId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["contentId"]);
    })();
    if (!parsed_contentId.success) {
      return { success: false };
    }
    if (!hasProp(value, "method")) {
      return { success: false };
    }
    const parsed_method = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["method"]);
    })();
    if (!parsed_method.success) {
      return { success: false };
    }
    if (!hasProp(value, "request")) {
      return { success: false };
    }
    const parsed_request = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["request"]);
    })();
    if (!parsed_request.success) {
      return { success: false };
    }
    if (!hasProp(value, "response")) {
      return { success: false };
    }
    const parsed_response = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["response"]);
    })();
    if (!parsed_response.success) {
      return { success: false };
    }
    if (!hasProp(value, "statusCode")) {
      return { success: false };
    }
    const parsed_statusCode = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["statusCode"]);
    })();
    if (!parsed_statusCode.success) {
      return { success: false };
    }
    if (!hasProp(value, "syncedAt")) {
      return { success: false };
    }
    const parsed_syncedAt = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        const parsed = DateTime.fromISO(value.toUpperCase(), { setZone: true });
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["syncedAt"]);
    })();
    if (!parsed_syncedAt.success) {
      return { success: false };
    }
    if (!hasProp(value, "userId")) {
      return { success: false };
    }
    const parsed_userId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["userId"]);
    })();
    if (!parsed_userId.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        completedAt: parsed_completedAt.value,
        connectorExecutionLogId: parsed_connectorExecutionLogId.value,
        contentId: parsed_contentId.value,
        method: parsed_method.value,
        request: parsed_request.value,
        response: parsed_response.value,
        statusCode: parsed_statusCode.value,
        syncedAt: parsed_syncedAt.value,
        userId: parsed_userId.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ConnectorCompletionFilters
export function serialize_ConnectorCompletionFilters(obj: ConnectorCompletionFilters): string {
  const serialized = ((value: { timeFrame: "LAST_24_HOURS" | "LAST_WEEK" | "LAST_2_WEEKS" | "LAST_MONTH" | "LAST_3_MONTHS"; userId: string | null }): unknown => {
    return {
      timeFrame: ((value: "LAST_24_HOURS" | "LAST_WEEK" | "LAST_2_WEEKS" | "LAST_MONTH" | "LAST_3_MONTHS"): unknown => {
        return value;
      })(value["timeFrame"]),
      userId: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["userId"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ConnectorCompletionFilters(str: string): ConnectorCompletionFilters | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          timeFrame: "LAST_24_HOURS" | "LAST_WEEK" | "LAST_2_WEEKS" | "LAST_MONTH" | "LAST_3_MONTHS";
          userId: string | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "timeFrame")) {
      return { success: false };
    }
    const parsed_timeFrame = (() => {
      return ((value: unknown): { success: false } | { success: true; value: "LAST_24_HOURS" | "LAST_WEEK" | "LAST_2_WEEKS" | "LAST_MONTH" | "LAST_3_MONTHS" } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        switch (value) {
          case "LAST_24_HOURS":
            return { success: true, value: "LAST_24_HOURS" };
          case "LAST_WEEK":
            return { success: true, value: "LAST_WEEK" };
          case "LAST_2_WEEKS":
            return { success: true, value: "LAST_2_WEEKS" };
          case "LAST_MONTH":
            return { success: true, value: "LAST_MONTH" };
          case "LAST_3_MONTHS":
            return { success: true, value: "LAST_3_MONTHS" };
          default:
            return { success: false };
        }
      })(value["timeFrame"]);
    })();
    if (!parsed_timeFrame.success) {
      return { success: false };
    }
    if (!hasProp(value, "userId")) {
      return { success: false };
    }
    const parsed_userId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["userId"]);
    })();
    if (!parsed_userId.success) {
      return { success: false };
    }
    return { success: true, value: { timeFrame: parsed_timeFrame.value, userId: parsed_userId.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ConnectorContentExecutionListView
export function serialize_ConnectorContentExecutionListView(obj: ConnectorContentExecutionListView): string {
  const serialized = ((value: { connectorExecutionId: bigint; connectorName: string; description: string; detail: ConnectorContentExecutionLogDetailView; provider: ConnectorProviderMetadata; syncedAt: DateTime<true> | null }): unknown => {
    return {
      connectorExecutionId: ((value: bigint): unknown => {
        return value;
      })(value["connectorExecutionId"]),
      connectorName: ((value: string): unknown => {
        return value;
      })(value["connectorName"]),
      description: ((value: string): unknown => {
        return value;
      })(value["description"]),
      detail: ((value: { failedExportedItems: number; successfullyExportedItems: number }): unknown => {
        return {
          failedExportedItems: ((value: number): unknown => {
            return value;
          })(value["failedExportedItems"]),
          successfullyExportedItems: ((value: number): unknown => {
            return value;
          })(value["successfullyExportedItems"]),
        };
      })(value["detail"]),
      provider: ((value: { imageUri: string; name: string; type: ConnectorType }): unknown => {
        return {
          imageUri: ((value: string): unknown => {
            return value;
          })(value["imageUri"]),
          name: ((value: string): unknown => {
            return value;
          })(value["name"]),
          type: ((value: "SUCCESS_FACTORS" | "DEGREED" | "DOCEBO" | "CSOD" | "SUM_TOTAL" | "EDCAST" | "WORKDAY"): unknown => {
            return value;
          })(value["type"]),
        };
      })(value["provider"]),
      syncedAt: ((value: DateTime<true> | null): unknown => {
        if (value === null) {
          return null;
        }
        return value.toISO();
      })(value["syncedAt"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ConnectorContentExecutionListView(str: string): ConnectorContentExecutionListView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          connectorExecutionId: bigint;
          connectorName: string;
          description: string;
          detail: ConnectorContentExecutionLogDetailView;
          provider: ConnectorProviderMetadata;
          syncedAt: DateTime<true> | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "connectorExecutionId")) {
      return { success: false };
    }
    const parsed_connectorExecutionId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["connectorExecutionId"]);
    })();
    if (!parsed_connectorExecutionId.success) {
      return { success: false };
    }
    if (!hasProp(value, "connectorName")) {
      return { success: false };
    }
    const parsed_connectorName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["connectorName"]);
    })();
    if (!parsed_connectorName.success) {
      return { success: false };
    }
    if (!hasProp(value, "description")) {
      return { success: false };
    }
    const parsed_description = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["description"]);
    })();
    if (!parsed_description.success) {
      return { success: false };
    }
    if (!hasProp(value, "detail")) {
      return { success: false };
    }
    const parsed_detail = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ConnectorContentExecutionLogDetailView } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                failedExportedItems: number;
                successfullyExportedItems: number;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "failedExportedItems")) {
            return { success: false };
          }
          const parsed_failedExportedItems = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["failedExportedItems"]);
          })();
          if (!parsed_failedExportedItems.success) {
            return { success: false };
          }
          if (!hasProp(value, "successfullyExportedItems")) {
            return { success: false };
          }
          const parsed_successfullyExportedItems = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["successfullyExportedItems"]);
          })();
          if (!parsed_successfullyExportedItems.success) {
            return { success: false };
          }
          return { success: true, value: { failedExportedItems: parsed_failedExportedItems.value, successfullyExportedItems: parsed_successfullyExportedItems.value } };
        })(value);
      })(value["detail"]);
    })();
    if (!parsed_detail.success) {
      return { success: false };
    }
    if (!hasProp(value, "provider")) {
      return { success: false };
    }
    const parsed_provider = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ConnectorProviderMetadata } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                imageUri: string;
                name: string;
                type: ConnectorType;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "imageUri")) {
            return { success: false };
          }
          const parsed_imageUri = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              try {
                new URL(value);
              } catch (ex) {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["imageUri"]);
          })();
          if (!parsed_imageUri.success) {
            return { success: false };
          }
          if (!hasProp(value, "name")) {
            return { success: false };
          }
          const parsed_name = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["name"]);
          })();
          if (!parsed_name.success) {
            return { success: false };
          }
          if (!hasProp(value, "type")) {
            return { success: false };
          }
          const parsed_type = (() => {
            return ((value: unknown): { success: false } | { success: true; value: ConnectorType } => {
              return ((value: unknown): { success: false } | { success: true; value: "SUCCESS_FACTORS" | "DEGREED" | "DOCEBO" | "CSOD" | "SUM_TOTAL" | "EDCAST" | "WORKDAY" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "SUCCESS_FACTORS":
                    return { success: true, value: "SUCCESS_FACTORS" };
                  case "DEGREED":
                    return { success: true, value: "DEGREED" };
                  case "DOCEBO":
                    return { success: true, value: "DOCEBO" };
                  case "CSOD":
                    return { success: true, value: "CSOD" };
                  case "SUM_TOTAL":
                    return { success: true, value: "SUM_TOTAL" };
                  case "EDCAST":
                    return { success: true, value: "EDCAST" };
                  case "WORKDAY":
                    return { success: true, value: "WORKDAY" };
                  default:
                    return { success: false };
                }
              })(value);
            })(value["type"]);
          })();
          if (!parsed_type.success) {
            return { success: false };
          }
          return { success: true, value: { imageUri: parsed_imageUri.value, name: parsed_name.value, type: parsed_type.value } };
        })(value);
      })(value["provider"]);
    })();
    if (!parsed_provider.success) {
      return { success: false };
    }
    if (!hasProp(value, "syncedAt")) {
      return { success: false };
    }
    const parsed_syncedAt = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        const parsed = DateTime.fromISO(value.toUpperCase(), { setZone: true });
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["syncedAt"]);
    })();
    if (!parsed_syncedAt.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        connectorExecutionId: parsed_connectorExecutionId.value,
        connectorName: parsed_connectorName.value,
        description: parsed_description.value,
        detail: parsed_detail.value,
        provider: parsed_provider.value,
        syncedAt: parsed_syncedAt.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ConnectorContentExecutionLogDetailView
export function serialize_ConnectorContentExecutionLogDetailView(obj: ConnectorContentExecutionLogDetailView): string {
  const serialized = ((value: { failedExportedItems: number; successfullyExportedItems: number }): unknown => {
    return {
      failedExportedItems: ((value: number): unknown => {
        return value;
      })(value["failedExportedItems"]),
      successfullyExportedItems: ((value: number): unknown => {
        return value;
      })(value["successfullyExportedItems"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ConnectorContentExecutionLogDetailView(str: string): ConnectorContentExecutionLogDetailView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          failedExportedItems: number;
          successfullyExportedItems: number;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "failedExportedItems")) {
      return { success: false };
    }
    const parsed_failedExportedItems = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["failedExportedItems"]);
    })();
    if (!parsed_failedExportedItems.success) {
      return { success: false };
    }
    if (!hasProp(value, "successfullyExportedItems")) {
      return { success: false };
    }
    const parsed_successfullyExportedItems = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["successfullyExportedItems"]);
    })();
    if (!parsed_successfullyExportedItems.success) {
      return { success: false };
    }
    return { success: true, value: { failedExportedItems: parsed_failedExportedItems.value, successfullyExportedItems: parsed_successfullyExportedItems.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ConnectorContentExecutionLogListView
export function serialize_ConnectorContentExecutionLogListView(obj: ConnectorContentExecutionLogListView): string {
  const serialized = ((value: { connectorExecutionLogId: bigint; contentId: string; method: string; request: string; response: string; statusCode: number; syncedAt: DateTime<true> }): unknown => {
    return {
      connectorExecutionLogId: ((value: bigint): unknown => {
        return value;
      })(value["connectorExecutionLogId"]),
      contentId: ((value: string): unknown => {
        return value;
      })(value["contentId"]),
      method: ((value: string): unknown => {
        return value;
      })(value["method"]),
      request: ((value: string): unknown => {
        return value;
      })(value["request"]),
      response: ((value: string): unknown => {
        return value;
      })(value["response"]),
      statusCode: ((value: number): unknown => {
        return value;
      })(value["statusCode"]),
      syncedAt: ((value: DateTime<true>): unknown => {
        return value.toISO();
      })(value["syncedAt"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ConnectorContentExecutionLogListView(str: string): ConnectorContentExecutionLogListView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          connectorExecutionLogId: bigint;
          contentId: string;
          method: string;
          request: string;
          response: string;
          statusCode: number;
          syncedAt: DateTime<true>;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "connectorExecutionLogId")) {
      return { success: false };
    }
    const parsed_connectorExecutionLogId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["connectorExecutionLogId"]);
    })();
    if (!parsed_connectorExecutionLogId.success) {
      return { success: false };
    }
    if (!hasProp(value, "contentId")) {
      return { success: false };
    }
    const parsed_contentId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["contentId"]);
    })();
    if (!parsed_contentId.success) {
      return { success: false };
    }
    if (!hasProp(value, "method")) {
      return { success: false };
    }
    const parsed_method = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["method"]);
    })();
    if (!parsed_method.success) {
      return { success: false };
    }
    if (!hasProp(value, "request")) {
      return { success: false };
    }
    const parsed_request = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["request"]);
    })();
    if (!parsed_request.success) {
      return { success: false };
    }
    if (!hasProp(value, "response")) {
      return { success: false };
    }
    const parsed_response = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["response"]);
    })();
    if (!parsed_response.success) {
      return { success: false };
    }
    if (!hasProp(value, "statusCode")) {
      return { success: false };
    }
    const parsed_statusCode = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["statusCode"]);
    })();
    if (!parsed_statusCode.success) {
      return { success: false };
    }
    if (!hasProp(value, "syncedAt")) {
      return { success: false };
    }
    const parsed_syncedAt = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        const parsed = DateTime.fromISO(value.toUpperCase(), { setZone: true });
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["syncedAt"]);
    })();
    if (!parsed_syncedAt.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        connectorExecutionLogId: parsed_connectorExecutionLogId.value,
        contentId: parsed_contentId.value,
        method: parsed_method.value,
        request: parsed_request.value,
        response: parsed_response.value,
        statusCode: parsed_statusCode.value,
        syncedAt: parsed_syncedAt.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ConnectorExecutionLogMetadata
export function serialize_ConnectorExecutionLogMetadata(obj: ConnectorExecutionLogMetadata): string {
  const serialized = ((value: { connectorExecutionLogId: bigint; contentId: string; method: string; request: string; response: string; statusCode: number; syncedAt: DateTime<true> }): unknown => {
    return {
      connectorExecutionLogId: ((value: bigint): unknown => {
        return value;
      })(value["connectorExecutionLogId"]),
      contentId: ((value: string): unknown => {
        return value;
      })(value["contentId"]),
      method: ((value: string): unknown => {
        return value;
      })(value["method"]),
      request: ((value: string): unknown => {
        return value;
      })(value["request"]),
      response: ((value: string): unknown => {
        return value;
      })(value["response"]),
      statusCode: ((value: number): unknown => {
        return value;
      })(value["statusCode"]),
      syncedAt: ((value: DateTime<true>): unknown => {
        return value.toISO();
      })(value["syncedAt"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ConnectorExecutionLogMetadata(str: string): ConnectorExecutionLogMetadata | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          connectorExecutionLogId: bigint;
          contentId: string;
          method: string;
          request: string;
          response: string;
          statusCode: number;
          syncedAt: DateTime<true>;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "connectorExecutionLogId")) {
      return { success: false };
    }
    const parsed_connectorExecutionLogId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["connectorExecutionLogId"]);
    })();
    if (!parsed_connectorExecutionLogId.success) {
      return { success: false };
    }
    if (!hasProp(value, "contentId")) {
      return { success: false };
    }
    const parsed_contentId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["contentId"]);
    })();
    if (!parsed_contentId.success) {
      return { success: false };
    }
    if (!hasProp(value, "method")) {
      return { success: false };
    }
    const parsed_method = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["method"]);
    })();
    if (!parsed_method.success) {
      return { success: false };
    }
    if (!hasProp(value, "request")) {
      return { success: false };
    }
    const parsed_request = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["request"]);
    })();
    if (!parsed_request.success) {
      return { success: false };
    }
    if (!hasProp(value, "response")) {
      return { success: false };
    }
    const parsed_response = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["response"]);
    })();
    if (!parsed_response.success) {
      return { success: false };
    }
    if (!hasProp(value, "statusCode")) {
      return { success: false };
    }
    const parsed_statusCode = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["statusCode"]);
    })();
    if (!parsed_statusCode.success) {
      return { success: false };
    }
    if (!hasProp(value, "syncedAt")) {
      return { success: false };
    }
    const parsed_syncedAt = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        const parsed = DateTime.fromISO(value.toUpperCase(), { setZone: true });
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["syncedAt"]);
    })();
    if (!parsed_syncedAt.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        connectorExecutionLogId: parsed_connectorExecutionLogId.value,
        contentId: parsed_contentId.value,
        method: parsed_method.value,
        request: parsed_request.value,
        response: parsed_response.value,
        statusCode: parsed_statusCode.value,
        syncedAt: parsed_syncedAt.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ConnectorForm
export function serialize_ConnectorForm(obj: ConnectorForm): string {
  const serialized = ((value: {
    active: boolean;
    languages: ("en" | "de" | "es" | "ru" | "zh" | "pt" | "fr")[];
    name: string;
    products: ConnectorProduct[];
    settingsForm: ConnectorSettingsForm;
    syncCompletions: boolean;
    type: ConnectorType;
  }): unknown => {
    return {
      active: ((value: boolean): unknown => {
        return value;
      })(value["active"]),
      languages: ((value: ("en" | "de" | "es" | "ru" | "zh" | "pt" | "fr")[]): unknown => {
        return value.map((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
          return value;
        });
      })(value["languages"]),
      name: ((value: string): unknown => {
        return value;
      })(value["name"]),
      products: ((value: ConnectorProduct[]): unknown => {
        return value.map((value: "BUSINESS_BOOKS" | "ARTICLES_AND_REPORTS" | "VIDEOS_AND_PODCASTS" | "ACTIONABLES"): unknown => {
          return value;
        });
      })(value["products"]),
      settingsForm: ((value: {
        accountId: string | null;
        apiKey: string | null;
        clientId: string;
        clientSecret: string;
        doceboChannelId: bigint | null;
        grantType: ("CLIENT_CREDENTIALS" | "PASSWORD") | null;
        lmsApiUri: string | null;
        lmsBaseUri: string;
        lmsTokenUri: string | null;
        oauth2Flow: Oauth2Flow;
        password: string | null;
        scope: string | null;
        sfCompanyId: string | null;
        userId: string | null;
        userIdentifier: ConnectorUserIdentifier;
        username: string | null;
        workdayCategoryFallback: string | null;
      }): unknown => {
        return {
          accountId: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["accountId"]),
          apiKey: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["apiKey"]),
          clientId: ((value: string): unknown => {
            return value;
          })(value["clientId"]),
          clientSecret: ((value: string): unknown => {
            return value;
          })(value["clientSecret"]),
          doceboChannelId: ((value: bigint | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["doceboChannelId"]),
          grantType: ((value: ("CLIENT_CREDENTIALS" | "PASSWORD") | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["grantType"]),
          lmsApiUri: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["lmsApiUri"]),
          lmsBaseUri: ((value: string): unknown => {
            return value;
          })(value["lmsBaseUri"]),
          lmsTokenUri: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["lmsTokenUri"]),
          oauth2Flow: ((value: "BASIC" | "TWO_LEGGED_OAUTH"): unknown => {
            return value;
          })(value["oauth2Flow"]),
          password: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["password"]),
          scope: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["scope"]),
          sfCompanyId: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["sfCompanyId"]),
          userId: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["userId"]),
          userIdentifier: ((value: "NAME_ID" | "EMAIL" | "EXTRA_FIELD_1" | "EXTRA_FIELD_2" | "EXTRA_FIELD_3"): unknown => {
            return value;
          })(value["userIdentifier"]),
          username: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["username"]),
          workdayCategoryFallback: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["workdayCategoryFallback"]),
        };
      })(value["settingsForm"]),
      syncCompletions: ((value: boolean): unknown => {
        return value;
      })(value["syncCompletions"]),
      type: ((value: "SUCCESS_FACTORS" | "DEGREED" | "DOCEBO" | "CSOD" | "SUM_TOTAL" | "EDCAST" | "WORKDAY"): unknown => {
        return value;
      })(value["type"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ConnectorForm(str: string): ConnectorForm | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          active: boolean;
          languages: ("en" | "de" | "es" | "ru" | "zh" | "pt" | "fr")[];
          name: string;
          products: ConnectorProduct[];
          settingsForm: ConnectorSettingsForm;
          syncCompletions: boolean;
          type: ConnectorType;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "active")) {
      return { success: false };
    }
    const parsed_active = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["active"]);
    })();
    if (!parsed_active.success) {
      return { success: false };
    }
    if (!hasProp(value, "languages")) {
      return { success: false };
    }
    const parsed_languages = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ("en" | "de" | "es" | "ru" | "zh" | "pt" | "fr")[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "en":
              return { success: true, value: "en" };
            case "de":
              return { success: true, value: "de" };
            case "es":
              return { success: true, value: "es" };
            case "ru":
              return { success: true, value: "ru" };
            case "zh":
              return { success: true, value: "zh" };
            case "pt":
              return { success: true, value: "pt" };
            case "fr":
              return { success: true, value: "fr" };
            default:
              return { success: false };
          }
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["languages"]);
    })();
    if (!parsed_languages.success) {
      return { success: false };
    }
    if (!hasProp(value, "name")) {
      return { success: false };
    }
    const parsed_name = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["name"]);
    })();
    if (!parsed_name.success) {
      return { success: false };
    }
    if (!hasProp(value, "products")) {
      return { success: false };
    }
    const parsed_products = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ConnectorProduct[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: ConnectorProduct } => {
          return ((value: unknown): { success: false } | { success: true; value: "BUSINESS_BOOKS" | "ARTICLES_AND_REPORTS" | "VIDEOS_AND_PODCASTS" | "ACTIONABLES" } => {
            if (typeof value !== "string") {
              return { success: false };
            }
            switch (value) {
              case "BUSINESS_BOOKS":
                return { success: true, value: "BUSINESS_BOOKS" };
              case "ARTICLES_AND_REPORTS":
                return { success: true, value: "ARTICLES_AND_REPORTS" };
              case "VIDEOS_AND_PODCASTS":
                return { success: true, value: "VIDEOS_AND_PODCASTS" };
              case "ACTIONABLES":
                return { success: true, value: "ACTIONABLES" };
              default:
                return { success: false };
            }
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["products"]);
    })();
    if (!parsed_products.success) {
      return { success: false };
    }
    if (!hasProp(value, "settingsForm")) {
      return { success: false };
    }
    const parsed_settingsForm = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ConnectorSettingsForm } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                accountId: string | null;
                apiKey: string | null;
                clientId: string;
                clientSecret: string;
                doceboChannelId: bigint | null;
                grantType: ("CLIENT_CREDENTIALS" | "PASSWORD") | null;
                lmsApiUri: string | null;
                lmsBaseUri: string;
                lmsTokenUri: string | null;
                oauth2Flow: Oauth2Flow;
                password: string | null;
                scope: string | null;
                sfCompanyId: string | null;
                userId: string | null;
                userIdentifier: ConnectorUserIdentifier;
                username: string | null;
                workdayCategoryFallback: string | null;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "accountId")) {
            return { success: false };
          }
          const parsed_accountId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["accountId"]);
          })();
          if (!parsed_accountId.success) {
            return { success: false };
          }
          if (!hasProp(value, "apiKey")) {
            return { success: false };
          }
          const parsed_apiKey = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["apiKey"]);
          })();
          if (!parsed_apiKey.success) {
            return { success: false };
          }
          if (!hasProp(value, "clientId")) {
            return { success: false };
          }
          const parsed_clientId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["clientId"]);
          })();
          if (!parsed_clientId.success) {
            return { success: false };
          }
          if (!hasProp(value, "clientSecret")) {
            return { success: false };
          }
          const parsed_clientSecret = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["clientSecret"]);
          })();
          if (!parsed_clientSecret.success) {
            return { success: false };
          }
          if (!hasProp(value, "doceboChannelId")) {
            return { success: false };
          }
          const parsed_doceboChannelId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: bigint | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value === "number") {
                return { success: true, value: BigInt(value) };
              }
              if (typeof value === "bigint") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["doceboChannelId"]);
          })();
          if (!parsed_doceboChannelId.success) {
            return { success: false };
          }
          if (!hasProp(value, "grantType")) {
            return { success: false };
          }
          const parsed_grantType = (() => {
            return ((value: unknown): { success: false } | { success: true; value: ("CLIENT_CREDENTIALS" | "PASSWORD") | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              switch (value) {
                case "CLIENT_CREDENTIALS":
                  return { success: true, value: "CLIENT_CREDENTIALS" };
                case "PASSWORD":
                  return { success: true, value: "PASSWORD" };
                default:
                  return { success: false };
              }
            })(value["grantType"]);
          })();
          if (!parsed_grantType.success) {
            return { success: false };
          }
          if (!hasProp(value, "lmsApiUri")) {
            return { success: false };
          }
          const parsed_lmsApiUri = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["lmsApiUri"]);
          })();
          if (!parsed_lmsApiUri.success) {
            return { success: false };
          }
          if (!hasProp(value, "lmsBaseUri")) {
            return { success: false };
          }
          const parsed_lmsBaseUri = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["lmsBaseUri"]);
          })();
          if (!parsed_lmsBaseUri.success) {
            return { success: false };
          }
          if (!hasProp(value, "lmsTokenUri")) {
            return { success: false };
          }
          const parsed_lmsTokenUri = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["lmsTokenUri"]);
          })();
          if (!parsed_lmsTokenUri.success) {
            return { success: false };
          }
          if (!hasProp(value, "oauth2Flow")) {
            return { success: false };
          }
          const parsed_oauth2Flow = (() => {
            return ((value: unknown): { success: false } | { success: true; value: Oauth2Flow } => {
              return ((value: unknown): { success: false } | { success: true; value: "BASIC" | "TWO_LEGGED_OAUTH" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "BASIC":
                    return { success: true, value: "BASIC" };
                  case "TWO_LEGGED_OAUTH":
                    return { success: true, value: "TWO_LEGGED_OAUTH" };
                  default:
                    return { success: false };
                }
              })(value);
            })(value["oauth2Flow"]);
          })();
          if (!parsed_oauth2Flow.success) {
            return { success: false };
          }
          if (!hasProp(value, "password")) {
            return { success: false };
          }
          const parsed_password = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["password"]);
          })();
          if (!parsed_password.success) {
            return { success: false };
          }
          if (!hasProp(value, "scope")) {
            return { success: false };
          }
          const parsed_scope = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["scope"]);
          })();
          if (!parsed_scope.success) {
            return { success: false };
          }
          if (!hasProp(value, "sfCompanyId")) {
            return { success: false };
          }
          const parsed_sfCompanyId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["sfCompanyId"]);
          })();
          if (!parsed_sfCompanyId.success) {
            return { success: false };
          }
          if (!hasProp(value, "userId")) {
            return { success: false };
          }
          const parsed_userId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["userId"]);
          })();
          if (!parsed_userId.success) {
            return { success: false };
          }
          if (!hasProp(value, "userIdentifier")) {
            return { success: false };
          }
          const parsed_userIdentifier = (() => {
            return ((value: unknown): { success: false } | { success: true; value: ConnectorUserIdentifier } => {
              return ((value: unknown): { success: false } | { success: true; value: "NAME_ID" | "EMAIL" | "EXTRA_FIELD_1" | "EXTRA_FIELD_2" | "EXTRA_FIELD_3" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "NAME_ID":
                    return { success: true, value: "NAME_ID" };
                  case "EMAIL":
                    return { success: true, value: "EMAIL" };
                  case "EXTRA_FIELD_1":
                    return { success: true, value: "EXTRA_FIELD_1" };
                  case "EXTRA_FIELD_2":
                    return { success: true, value: "EXTRA_FIELD_2" };
                  case "EXTRA_FIELD_3":
                    return { success: true, value: "EXTRA_FIELD_3" };
                  default:
                    return { success: false };
                }
              })(value);
            })(value["userIdentifier"]);
          })();
          if (!parsed_userIdentifier.success) {
            return { success: false };
          }
          if (!hasProp(value, "username")) {
            return { success: false };
          }
          const parsed_username = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["username"]);
          })();
          if (!parsed_username.success) {
            return { success: false };
          }
          if (!hasProp(value, "workdayCategoryFallback")) {
            return { success: false };
          }
          const parsed_workdayCategoryFallback = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["workdayCategoryFallback"]);
          })();
          if (!parsed_workdayCategoryFallback.success) {
            return { success: false };
          }
          return {
            success: true,
            value: {
              accountId: parsed_accountId.value,
              apiKey: parsed_apiKey.value,
              clientId: parsed_clientId.value,
              clientSecret: parsed_clientSecret.value,
              doceboChannelId: parsed_doceboChannelId.value,
              grantType: parsed_grantType.value,
              lmsApiUri: parsed_lmsApiUri.value,
              lmsBaseUri: parsed_lmsBaseUri.value,
              lmsTokenUri: parsed_lmsTokenUri.value,
              oauth2Flow: parsed_oauth2Flow.value,
              password: parsed_password.value,
              scope: parsed_scope.value,
              sfCompanyId: parsed_sfCompanyId.value,
              userId: parsed_userId.value,
              userIdentifier: parsed_userIdentifier.value,
              username: parsed_username.value,
              workdayCategoryFallback: parsed_workdayCategoryFallback.value,
            },
          };
        })(value);
      })(value["settingsForm"]);
    })();
    if (!parsed_settingsForm.success) {
      return { success: false };
    }
    if (!hasProp(value, "syncCompletions")) {
      return { success: false };
    }
    const parsed_syncCompletions = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["syncCompletions"]);
    })();
    if (!parsed_syncCompletions.success) {
      return { success: false };
    }
    if (!hasProp(value, "type")) {
      return { success: false };
    }
    const parsed_type = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ConnectorType } => {
        return ((value: unknown): { success: false } | { success: true; value: "SUCCESS_FACTORS" | "DEGREED" | "DOCEBO" | "CSOD" | "SUM_TOTAL" | "EDCAST" | "WORKDAY" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "SUCCESS_FACTORS":
              return { success: true, value: "SUCCESS_FACTORS" };
            case "DEGREED":
              return { success: true, value: "DEGREED" };
            case "DOCEBO":
              return { success: true, value: "DOCEBO" };
            case "CSOD":
              return { success: true, value: "CSOD" };
            case "SUM_TOTAL":
              return { success: true, value: "SUM_TOTAL" };
            case "EDCAST":
              return { success: true, value: "EDCAST" };
            case "WORKDAY":
              return { success: true, value: "WORKDAY" };
            default:
              return { success: false };
          }
        })(value);
      })(value["type"]);
    })();
    if (!parsed_type.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        active: parsed_active.value,
        languages: parsed_languages.value,
        name: parsed_name.value,
        products: parsed_products.value,
        settingsForm: parsed_settingsForm.value,
        syncCompletions: parsed_syncCompletions.value,
        type: parsed_type.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ConnectorGrantType
export function serialize_ConnectorGrantType(obj: ConnectorGrantType): string {
  const serialized = ((value: "CLIENT_CREDENTIALS" | "PASSWORD"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ConnectorGrantType(str: string): ConnectorGrantType | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "CLIENT_CREDENTIALS" | "PASSWORD" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "CLIENT_CREDENTIALS":
        return { success: true, value: "CLIENT_CREDENTIALS" };
      case "PASSWORD":
        return { success: true, value: "PASSWORD" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ConnectorListView
export function serialize_ConnectorListView(obj: ConnectorListView): string {
  const serialized = ((value: { active: boolean; connectorId: bigint; name: string; provider: ConnectorProviderMetadata; syncedAt: DateTime<true> | null }): unknown => {
    return {
      active: ((value: boolean): unknown => {
        return value;
      })(value["active"]),
      connectorId: ((value: bigint): unknown => {
        return value;
      })(value["connectorId"]),
      name: ((value: string): unknown => {
        return value;
      })(value["name"]),
      provider: ((value: { imageUri: string; name: string; type: ConnectorType }): unknown => {
        return {
          imageUri: ((value: string): unknown => {
            return value;
          })(value["imageUri"]),
          name: ((value: string): unknown => {
            return value;
          })(value["name"]),
          type: ((value: "SUCCESS_FACTORS" | "DEGREED" | "DOCEBO" | "CSOD" | "SUM_TOTAL" | "EDCAST" | "WORKDAY"): unknown => {
            return value;
          })(value["type"]),
        };
      })(value["provider"]),
      syncedAt: ((value: DateTime<true> | null): unknown => {
        if (value === null) {
          return null;
        }
        return value.toISO();
      })(value["syncedAt"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ConnectorListView(str: string): ConnectorListView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          active: boolean;
          connectorId: bigint;
          name: string;
          provider: ConnectorProviderMetadata;
          syncedAt: DateTime<true> | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "active")) {
      return { success: false };
    }
    const parsed_active = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["active"]);
    })();
    if (!parsed_active.success) {
      return { success: false };
    }
    if (!hasProp(value, "connectorId")) {
      return { success: false };
    }
    const parsed_connectorId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["connectorId"]);
    })();
    if (!parsed_connectorId.success) {
      return { success: false };
    }
    if (!hasProp(value, "name")) {
      return { success: false };
    }
    const parsed_name = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["name"]);
    })();
    if (!parsed_name.success) {
      return { success: false };
    }
    if (!hasProp(value, "provider")) {
      return { success: false };
    }
    const parsed_provider = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ConnectorProviderMetadata } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                imageUri: string;
                name: string;
                type: ConnectorType;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "imageUri")) {
            return { success: false };
          }
          const parsed_imageUri = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              try {
                new URL(value);
              } catch (ex) {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["imageUri"]);
          })();
          if (!parsed_imageUri.success) {
            return { success: false };
          }
          if (!hasProp(value, "name")) {
            return { success: false };
          }
          const parsed_name = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["name"]);
          })();
          if (!parsed_name.success) {
            return { success: false };
          }
          if (!hasProp(value, "type")) {
            return { success: false };
          }
          const parsed_type = (() => {
            return ((value: unknown): { success: false } | { success: true; value: ConnectorType } => {
              return ((value: unknown): { success: false } | { success: true; value: "SUCCESS_FACTORS" | "DEGREED" | "DOCEBO" | "CSOD" | "SUM_TOTAL" | "EDCAST" | "WORKDAY" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "SUCCESS_FACTORS":
                    return { success: true, value: "SUCCESS_FACTORS" };
                  case "DEGREED":
                    return { success: true, value: "DEGREED" };
                  case "DOCEBO":
                    return { success: true, value: "DOCEBO" };
                  case "CSOD":
                    return { success: true, value: "CSOD" };
                  case "SUM_TOTAL":
                    return { success: true, value: "SUM_TOTAL" };
                  case "EDCAST":
                    return { success: true, value: "EDCAST" };
                  case "WORKDAY":
                    return { success: true, value: "WORKDAY" };
                  default:
                    return { success: false };
                }
              })(value);
            })(value["type"]);
          })();
          if (!parsed_type.success) {
            return { success: false };
          }
          return { success: true, value: { imageUri: parsed_imageUri.value, name: parsed_name.value, type: parsed_type.value } };
        })(value);
      })(value["provider"]);
    })();
    if (!parsed_provider.success) {
      return { success: false };
    }
    if (!hasProp(value, "syncedAt")) {
      return { success: false };
    }
    const parsed_syncedAt = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        const parsed = DateTime.fromISO(value.toUpperCase(), { setZone: true });
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["syncedAt"]);
    })();
    if (!parsed_syncedAt.success) {
      return { success: false };
    }
    return { success: true, value: { active: parsed_active.value, connectorId: parsed_connectorId.value, name: parsed_name.value, provider: parsed_provider.value, syncedAt: parsed_syncedAt.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ConnectorProduct
export function serialize_ConnectorProduct(obj: ConnectorProduct): string {
  const serialized = ((value: "BUSINESS_BOOKS" | "ARTICLES_AND_REPORTS" | "VIDEOS_AND_PODCASTS" | "ACTIONABLES"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ConnectorProduct(str: string): ConnectorProduct | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "BUSINESS_BOOKS" | "ARTICLES_AND_REPORTS" | "VIDEOS_AND_PODCASTS" | "ACTIONABLES" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "BUSINESS_BOOKS":
        return { success: true, value: "BUSINESS_BOOKS" };
      case "ARTICLES_AND_REPORTS":
        return { success: true, value: "ARTICLES_AND_REPORTS" };
      case "VIDEOS_AND_PODCASTS":
        return { success: true, value: "VIDEOS_AND_PODCASTS" };
      case "ACTIONABLES":
        return { success: true, value: "ACTIONABLES" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ConnectorProviderMetadata
export function serialize_ConnectorProviderMetadata(obj: ConnectorProviderMetadata): string {
  const serialized = ((value: { imageUri: string; name: string; type: ConnectorType }): unknown => {
    return {
      imageUri: ((value: string): unknown => {
        return value;
      })(value["imageUri"]),
      name: ((value: string): unknown => {
        return value;
      })(value["name"]),
      type: ((value: "SUCCESS_FACTORS" | "DEGREED" | "DOCEBO" | "CSOD" | "SUM_TOTAL" | "EDCAST" | "WORKDAY"): unknown => {
        return value;
      })(value["type"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ConnectorProviderMetadata(str: string): ConnectorProviderMetadata | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          imageUri: string;
          name: string;
          type: ConnectorType;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "imageUri")) {
      return { success: false };
    }
    const parsed_imageUri = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        try {
          new URL(value);
        } catch (ex) {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["imageUri"]);
    })();
    if (!parsed_imageUri.success) {
      return { success: false };
    }
    if (!hasProp(value, "name")) {
      return { success: false };
    }
    const parsed_name = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["name"]);
    })();
    if (!parsed_name.success) {
      return { success: false };
    }
    if (!hasProp(value, "type")) {
      return { success: false };
    }
    const parsed_type = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ConnectorType } => {
        return ((value: unknown): { success: false } | { success: true; value: "SUCCESS_FACTORS" | "DEGREED" | "DOCEBO" | "CSOD" | "SUM_TOTAL" | "EDCAST" | "WORKDAY" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "SUCCESS_FACTORS":
              return { success: true, value: "SUCCESS_FACTORS" };
            case "DEGREED":
              return { success: true, value: "DEGREED" };
            case "DOCEBO":
              return { success: true, value: "DOCEBO" };
            case "CSOD":
              return { success: true, value: "CSOD" };
            case "SUM_TOTAL":
              return { success: true, value: "SUM_TOTAL" };
            case "EDCAST":
              return { success: true, value: "EDCAST" };
            case "WORKDAY":
              return { success: true, value: "WORKDAY" };
            default:
              return { success: false };
          }
        })(value);
      })(value["type"]);
    })();
    if (!parsed_type.success) {
      return { success: false };
    }
    return { success: true, value: { imageUri: parsed_imageUri.value, name: parsed_name.value, type: parsed_type.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ConnectorSettingsForm
export function serialize_ConnectorSettingsForm(obj: ConnectorSettingsForm): string {
  const serialized = ((value: {
    accountId: string | null;
    apiKey: string | null;
    clientId: string;
    clientSecret: string;
    doceboChannelId: bigint | null;
    grantType: ("CLIENT_CREDENTIALS" | "PASSWORD") | null;
    lmsApiUri: string | null;
    lmsBaseUri: string;
    lmsTokenUri: string | null;
    oauth2Flow: Oauth2Flow;
    password: string | null;
    scope: string | null;
    sfCompanyId: string | null;
    userId: string | null;
    userIdentifier: ConnectorUserIdentifier;
    username: string | null;
    workdayCategoryFallback: string | null;
  }): unknown => {
    return {
      accountId: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["accountId"]),
      apiKey: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["apiKey"]),
      clientId: ((value: string): unknown => {
        return value;
      })(value["clientId"]),
      clientSecret: ((value: string): unknown => {
        return value;
      })(value["clientSecret"]),
      doceboChannelId: ((value: bigint | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["doceboChannelId"]),
      grantType: ((value: ("CLIENT_CREDENTIALS" | "PASSWORD") | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["grantType"]),
      lmsApiUri: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["lmsApiUri"]),
      lmsBaseUri: ((value: string): unknown => {
        return value;
      })(value["lmsBaseUri"]),
      lmsTokenUri: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["lmsTokenUri"]),
      oauth2Flow: ((value: "BASIC" | "TWO_LEGGED_OAUTH"): unknown => {
        return value;
      })(value["oauth2Flow"]),
      password: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["password"]),
      scope: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["scope"]),
      sfCompanyId: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["sfCompanyId"]),
      userId: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["userId"]),
      userIdentifier: ((value: "NAME_ID" | "EMAIL" | "EXTRA_FIELD_1" | "EXTRA_FIELD_2" | "EXTRA_FIELD_3"): unknown => {
        return value;
      })(value["userIdentifier"]),
      username: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["username"]),
      workdayCategoryFallback: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["workdayCategoryFallback"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ConnectorSettingsForm(str: string): ConnectorSettingsForm | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          accountId: string | null;
          apiKey: string | null;
          clientId: string;
          clientSecret: string;
          doceboChannelId: bigint | null;
          grantType: ("CLIENT_CREDENTIALS" | "PASSWORD") | null;
          lmsApiUri: string | null;
          lmsBaseUri: string;
          lmsTokenUri: string | null;
          oauth2Flow: Oauth2Flow;
          password: string | null;
          scope: string | null;
          sfCompanyId: string | null;
          userId: string | null;
          userIdentifier: ConnectorUserIdentifier;
          username: string | null;
          workdayCategoryFallback: string | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "accountId")) {
      return { success: false };
    }
    const parsed_accountId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["accountId"]);
    })();
    if (!parsed_accountId.success) {
      return { success: false };
    }
    if (!hasProp(value, "apiKey")) {
      return { success: false };
    }
    const parsed_apiKey = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["apiKey"]);
    })();
    if (!parsed_apiKey.success) {
      return { success: false };
    }
    if (!hasProp(value, "clientId")) {
      return { success: false };
    }
    const parsed_clientId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["clientId"]);
    })();
    if (!parsed_clientId.success) {
      return { success: false };
    }
    if (!hasProp(value, "clientSecret")) {
      return { success: false };
    }
    const parsed_clientSecret = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["clientSecret"]);
    })();
    if (!parsed_clientSecret.success) {
      return { success: false };
    }
    if (!hasProp(value, "doceboChannelId")) {
      return { success: false };
    }
    const parsed_doceboChannelId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["doceboChannelId"]);
    })();
    if (!parsed_doceboChannelId.success) {
      return { success: false };
    }
    if (!hasProp(value, "grantType")) {
      return { success: false };
    }
    const parsed_grantType = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ("CLIENT_CREDENTIALS" | "PASSWORD") | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        switch (value) {
          case "CLIENT_CREDENTIALS":
            return { success: true, value: "CLIENT_CREDENTIALS" };
          case "PASSWORD":
            return { success: true, value: "PASSWORD" };
          default:
            return { success: false };
        }
      })(value["grantType"]);
    })();
    if (!parsed_grantType.success) {
      return { success: false };
    }
    if (!hasProp(value, "lmsApiUri")) {
      return { success: false };
    }
    const parsed_lmsApiUri = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["lmsApiUri"]);
    })();
    if (!parsed_lmsApiUri.success) {
      return { success: false };
    }
    if (!hasProp(value, "lmsBaseUri")) {
      return { success: false };
    }
    const parsed_lmsBaseUri = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["lmsBaseUri"]);
    })();
    if (!parsed_lmsBaseUri.success) {
      return { success: false };
    }
    if (!hasProp(value, "lmsTokenUri")) {
      return { success: false };
    }
    const parsed_lmsTokenUri = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["lmsTokenUri"]);
    })();
    if (!parsed_lmsTokenUri.success) {
      return { success: false };
    }
    if (!hasProp(value, "oauth2Flow")) {
      return { success: false };
    }
    const parsed_oauth2Flow = (() => {
      return ((value: unknown): { success: false } | { success: true; value: Oauth2Flow } => {
        return ((value: unknown): { success: false } | { success: true; value: "BASIC" | "TWO_LEGGED_OAUTH" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "BASIC":
              return { success: true, value: "BASIC" };
            case "TWO_LEGGED_OAUTH":
              return { success: true, value: "TWO_LEGGED_OAUTH" };
            default:
              return { success: false };
          }
        })(value);
      })(value["oauth2Flow"]);
    })();
    if (!parsed_oauth2Flow.success) {
      return { success: false };
    }
    if (!hasProp(value, "password")) {
      return { success: false };
    }
    const parsed_password = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["password"]);
    })();
    if (!parsed_password.success) {
      return { success: false };
    }
    if (!hasProp(value, "scope")) {
      return { success: false };
    }
    const parsed_scope = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["scope"]);
    })();
    if (!parsed_scope.success) {
      return { success: false };
    }
    if (!hasProp(value, "sfCompanyId")) {
      return { success: false };
    }
    const parsed_sfCompanyId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["sfCompanyId"]);
    })();
    if (!parsed_sfCompanyId.success) {
      return { success: false };
    }
    if (!hasProp(value, "userId")) {
      return { success: false };
    }
    const parsed_userId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["userId"]);
    })();
    if (!parsed_userId.success) {
      return { success: false };
    }
    if (!hasProp(value, "userIdentifier")) {
      return { success: false };
    }
    const parsed_userIdentifier = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ConnectorUserIdentifier } => {
        return ((value: unknown): { success: false } | { success: true; value: "NAME_ID" | "EMAIL" | "EXTRA_FIELD_1" | "EXTRA_FIELD_2" | "EXTRA_FIELD_3" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "NAME_ID":
              return { success: true, value: "NAME_ID" };
            case "EMAIL":
              return { success: true, value: "EMAIL" };
            case "EXTRA_FIELD_1":
              return { success: true, value: "EXTRA_FIELD_1" };
            case "EXTRA_FIELD_2":
              return { success: true, value: "EXTRA_FIELD_2" };
            case "EXTRA_FIELD_3":
              return { success: true, value: "EXTRA_FIELD_3" };
            default:
              return { success: false };
          }
        })(value);
      })(value["userIdentifier"]);
    })();
    if (!parsed_userIdentifier.success) {
      return { success: false };
    }
    if (!hasProp(value, "username")) {
      return { success: false };
    }
    const parsed_username = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["username"]);
    })();
    if (!parsed_username.success) {
      return { success: false };
    }
    if (!hasProp(value, "workdayCategoryFallback")) {
      return { success: false };
    }
    const parsed_workdayCategoryFallback = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["workdayCategoryFallback"]);
    })();
    if (!parsed_workdayCategoryFallback.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        accountId: parsed_accountId.value,
        apiKey: parsed_apiKey.value,
        clientId: parsed_clientId.value,
        clientSecret: parsed_clientSecret.value,
        doceboChannelId: parsed_doceboChannelId.value,
        grantType: parsed_grantType.value,
        lmsApiUri: parsed_lmsApiUri.value,
        lmsBaseUri: parsed_lmsBaseUri.value,
        lmsTokenUri: parsed_lmsTokenUri.value,
        oauth2Flow: parsed_oauth2Flow.value,
        password: parsed_password.value,
        scope: parsed_scope.value,
        sfCompanyId: parsed_sfCompanyId.value,
        userId: parsed_userId.value,
        userIdentifier: parsed_userIdentifier.value,
        username: parsed_username.value,
        workdayCategoryFallback: parsed_workdayCategoryFallback.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ConnectorSettingsView
export function serialize_ConnectorSettingsView(obj: ConnectorSettingsView): string {
  const serialized = ((value: {
    accountId: string | null;
    apiKey: string | null;
    clientId: string;
    clientSecret: string;
    doceboChannelId: bigint | null;
    grantType: ConnectorGrantType;
    lmsApiUri: string | null;
    lmsBaseUri: string;
    lmsTokenUri: string | null;
    oauth2Flow: Oauth2Flow;
    password: string | null;
    scope: string | null;
    sfCompanyId: string | null;
    userId: string | null;
    userIdentifier: ConnectorUserIdentifier;
    username: string | null;
    workdayCategoryFallback: string | null;
  }): unknown => {
    return {
      accountId: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["accountId"]),
      apiKey: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["apiKey"]),
      clientId: ((value: string): unknown => {
        return value;
      })(value["clientId"]),
      clientSecret: ((value: string): unknown => {
        return value;
      })(value["clientSecret"]),
      doceboChannelId: ((value: bigint | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["doceboChannelId"]),
      grantType: ((value: "CLIENT_CREDENTIALS" | "PASSWORD"): unknown => {
        return value;
      })(value["grantType"]),
      lmsApiUri: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["lmsApiUri"]),
      lmsBaseUri: ((value: string): unknown => {
        return value;
      })(value["lmsBaseUri"]),
      lmsTokenUri: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["lmsTokenUri"]),
      oauth2Flow: ((value: "BASIC" | "TWO_LEGGED_OAUTH"): unknown => {
        return value;
      })(value["oauth2Flow"]),
      password: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["password"]),
      scope: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["scope"]),
      sfCompanyId: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["sfCompanyId"]),
      userId: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["userId"]),
      userIdentifier: ((value: "NAME_ID" | "EMAIL" | "EXTRA_FIELD_1" | "EXTRA_FIELD_2" | "EXTRA_FIELD_3"): unknown => {
        return value;
      })(value["userIdentifier"]),
      username: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["username"]),
      workdayCategoryFallback: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["workdayCategoryFallback"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ConnectorSettingsView(str: string): ConnectorSettingsView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          accountId: string | null;
          apiKey: string | null;
          clientId: string;
          clientSecret: string;
          doceboChannelId: bigint | null;
          grantType: ConnectorGrantType;
          lmsApiUri: string | null;
          lmsBaseUri: string;
          lmsTokenUri: string | null;
          oauth2Flow: Oauth2Flow;
          password: string | null;
          scope: string | null;
          sfCompanyId: string | null;
          userId: string | null;
          userIdentifier: ConnectorUserIdentifier;
          username: string | null;
          workdayCategoryFallback: string | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "accountId")) {
      return { success: false };
    }
    const parsed_accountId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["accountId"]);
    })();
    if (!parsed_accountId.success) {
      return { success: false };
    }
    if (!hasProp(value, "apiKey")) {
      return { success: false };
    }
    const parsed_apiKey = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["apiKey"]);
    })();
    if (!parsed_apiKey.success) {
      return { success: false };
    }
    if (!hasProp(value, "clientId")) {
      return { success: false };
    }
    const parsed_clientId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["clientId"]);
    })();
    if (!parsed_clientId.success) {
      return { success: false };
    }
    if (!hasProp(value, "clientSecret")) {
      return { success: false };
    }
    const parsed_clientSecret = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["clientSecret"]);
    })();
    if (!parsed_clientSecret.success) {
      return { success: false };
    }
    if (!hasProp(value, "doceboChannelId")) {
      return { success: false };
    }
    const parsed_doceboChannelId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["doceboChannelId"]);
    })();
    if (!parsed_doceboChannelId.success) {
      return { success: false };
    }
    if (!hasProp(value, "grantType")) {
      return { success: false };
    }
    const parsed_grantType = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ConnectorGrantType } => {
        return ((value: unknown): { success: false } | { success: true; value: "CLIENT_CREDENTIALS" | "PASSWORD" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "CLIENT_CREDENTIALS":
              return { success: true, value: "CLIENT_CREDENTIALS" };
            case "PASSWORD":
              return { success: true, value: "PASSWORD" };
            default:
              return { success: false };
          }
        })(value);
      })(value["grantType"]);
    })();
    if (!parsed_grantType.success) {
      return { success: false };
    }
    if (!hasProp(value, "lmsApiUri")) {
      return { success: false };
    }
    const parsed_lmsApiUri = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["lmsApiUri"]);
    })();
    if (!parsed_lmsApiUri.success) {
      return { success: false };
    }
    if (!hasProp(value, "lmsBaseUri")) {
      return { success: false };
    }
    const parsed_lmsBaseUri = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["lmsBaseUri"]);
    })();
    if (!parsed_lmsBaseUri.success) {
      return { success: false };
    }
    if (!hasProp(value, "lmsTokenUri")) {
      return { success: false };
    }
    const parsed_lmsTokenUri = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["lmsTokenUri"]);
    })();
    if (!parsed_lmsTokenUri.success) {
      return { success: false };
    }
    if (!hasProp(value, "oauth2Flow")) {
      return { success: false };
    }
    const parsed_oauth2Flow = (() => {
      return ((value: unknown): { success: false } | { success: true; value: Oauth2Flow } => {
        return ((value: unknown): { success: false } | { success: true; value: "BASIC" | "TWO_LEGGED_OAUTH" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "BASIC":
              return { success: true, value: "BASIC" };
            case "TWO_LEGGED_OAUTH":
              return { success: true, value: "TWO_LEGGED_OAUTH" };
            default:
              return { success: false };
          }
        })(value);
      })(value["oauth2Flow"]);
    })();
    if (!parsed_oauth2Flow.success) {
      return { success: false };
    }
    if (!hasProp(value, "password")) {
      return { success: false };
    }
    const parsed_password = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["password"]);
    })();
    if (!parsed_password.success) {
      return { success: false };
    }
    if (!hasProp(value, "scope")) {
      return { success: false };
    }
    const parsed_scope = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["scope"]);
    })();
    if (!parsed_scope.success) {
      return { success: false };
    }
    if (!hasProp(value, "sfCompanyId")) {
      return { success: false };
    }
    const parsed_sfCompanyId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["sfCompanyId"]);
    })();
    if (!parsed_sfCompanyId.success) {
      return { success: false };
    }
    if (!hasProp(value, "userId")) {
      return { success: false };
    }
    const parsed_userId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["userId"]);
    })();
    if (!parsed_userId.success) {
      return { success: false };
    }
    if (!hasProp(value, "userIdentifier")) {
      return { success: false };
    }
    const parsed_userIdentifier = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ConnectorUserIdentifier } => {
        return ((value: unknown): { success: false } | { success: true; value: "NAME_ID" | "EMAIL" | "EXTRA_FIELD_1" | "EXTRA_FIELD_2" | "EXTRA_FIELD_3" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "NAME_ID":
              return { success: true, value: "NAME_ID" };
            case "EMAIL":
              return { success: true, value: "EMAIL" };
            case "EXTRA_FIELD_1":
              return { success: true, value: "EXTRA_FIELD_1" };
            case "EXTRA_FIELD_2":
              return { success: true, value: "EXTRA_FIELD_2" };
            case "EXTRA_FIELD_3":
              return { success: true, value: "EXTRA_FIELD_3" };
            default:
              return { success: false };
          }
        })(value);
      })(value["userIdentifier"]);
    })();
    if (!parsed_userIdentifier.success) {
      return { success: false };
    }
    if (!hasProp(value, "username")) {
      return { success: false };
    }
    const parsed_username = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["username"]);
    })();
    if (!parsed_username.success) {
      return { success: false };
    }
    if (!hasProp(value, "workdayCategoryFallback")) {
      return { success: false };
    }
    const parsed_workdayCategoryFallback = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["workdayCategoryFallback"]);
    })();
    if (!parsed_workdayCategoryFallback.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        accountId: parsed_accountId.value,
        apiKey: parsed_apiKey.value,
        clientId: parsed_clientId.value,
        clientSecret: parsed_clientSecret.value,
        doceboChannelId: parsed_doceboChannelId.value,
        grantType: parsed_grantType.value,
        lmsApiUri: parsed_lmsApiUri.value,
        lmsBaseUri: parsed_lmsBaseUri.value,
        lmsTokenUri: parsed_lmsTokenUri.value,
        oauth2Flow: parsed_oauth2Flow.value,
        password: parsed_password.value,
        scope: parsed_scope.value,
        sfCompanyId: parsed_sfCompanyId.value,
        userId: parsed_userId.value,
        userIdentifier: parsed_userIdentifier.value,
        username: parsed_username.value,
        workdayCategoryFallback: parsed_workdayCategoryFallback.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ConnectorSyncEntry
export function serialize_ConnectorSyncEntry(obj: ConnectorSyncEntry): string {
  const serialized = ((value: { connectorId: bigint; corporateId: number; type: ConnectorType }): unknown => {
    return {
      connectorId: ((value: bigint): unknown => {
        return value;
      })(value["connectorId"]),
      corporateId: ((value: number): unknown => {
        return value;
      })(value["corporateId"]),
      type: ((value: "SUCCESS_FACTORS" | "DEGREED" | "DOCEBO" | "CSOD" | "SUM_TOTAL" | "EDCAST" | "WORKDAY"): unknown => {
        return value;
      })(value["type"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ConnectorSyncEntry(str: string): ConnectorSyncEntry | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          connectorId: bigint;
          corporateId: number;
          type: ConnectorType;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "connectorId")) {
      return { success: false };
    }
    const parsed_connectorId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["connectorId"]);
    })();
    if (!parsed_connectorId.success) {
      return { success: false };
    }
    if (!hasProp(value, "corporateId")) {
      return { success: false };
    }
    const parsed_corporateId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["corporateId"]);
    })();
    if (!parsed_corporateId.success) {
      return { success: false };
    }
    if (!hasProp(value, "type")) {
      return { success: false };
    }
    const parsed_type = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ConnectorType } => {
        return ((value: unknown): { success: false } | { success: true; value: "SUCCESS_FACTORS" | "DEGREED" | "DOCEBO" | "CSOD" | "SUM_TOTAL" | "EDCAST" | "WORKDAY" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "SUCCESS_FACTORS":
              return { success: true, value: "SUCCESS_FACTORS" };
            case "DEGREED":
              return { success: true, value: "DEGREED" };
            case "DOCEBO":
              return { success: true, value: "DOCEBO" };
            case "CSOD":
              return { success: true, value: "CSOD" };
            case "SUM_TOTAL":
              return { success: true, value: "SUM_TOTAL" };
            case "EDCAST":
              return { success: true, value: "EDCAST" };
            case "WORKDAY":
              return { success: true, value: "WORKDAY" };
            default:
              return { success: false };
          }
        })(value);
      })(value["type"]);
    })();
    if (!parsed_type.success) {
      return { success: false };
    }
    return { success: true, value: { connectorId: parsed_connectorId.value, corporateId: parsed_corporateId.value, type: parsed_type.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ConnectorType
export function serialize_ConnectorType(obj: ConnectorType): string {
  const serialized = ((value: "SUCCESS_FACTORS" | "DEGREED" | "DOCEBO" | "CSOD" | "SUM_TOTAL" | "EDCAST" | "WORKDAY"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ConnectorType(str: string): ConnectorType | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "SUCCESS_FACTORS" | "DEGREED" | "DOCEBO" | "CSOD" | "SUM_TOTAL" | "EDCAST" | "WORKDAY" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "SUCCESS_FACTORS":
        return { success: true, value: "SUCCESS_FACTORS" };
      case "DEGREED":
        return { success: true, value: "DEGREED" };
      case "DOCEBO":
        return { success: true, value: "DOCEBO" };
      case "CSOD":
        return { success: true, value: "CSOD" };
      case "SUM_TOTAL":
        return { success: true, value: "SUM_TOTAL" };
      case "EDCAST":
        return { success: true, value: "EDCAST" };
      case "WORKDAY":
        return { success: true, value: "WORKDAY" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ConnectorUserIdentifier
export function serialize_ConnectorUserIdentifier(obj: ConnectorUserIdentifier): string {
  const serialized = ((value: "NAME_ID" | "EMAIL" | "EXTRA_FIELD_1" | "EXTRA_FIELD_2" | "EXTRA_FIELD_3"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ConnectorUserIdentifier(str: string): ConnectorUserIdentifier | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "NAME_ID" | "EMAIL" | "EXTRA_FIELD_1" | "EXTRA_FIELD_2" | "EXTRA_FIELD_3" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "NAME_ID":
        return { success: true, value: "NAME_ID" };
      case "EMAIL":
        return { success: true, value: "EMAIL" };
      case "EXTRA_FIELD_1":
        return { success: true, value: "EXTRA_FIELD_1" };
      case "EXTRA_FIELD_2":
        return { success: true, value: "EXTRA_FIELD_2" };
      case "EXTRA_FIELD_3":
        return { success: true, value: "EXTRA_FIELD_3" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ConnectorView
export function serialize_ConnectorView(obj: ConnectorView): string {
  const serialized = ((value: {
    connectorId: bigint;
    languages: ("en" | "de" | "es" | "ru" | "zh" | "pt" | "fr")[];
    name: string;
    products: ConnectorProduct[];
    settings: ConnectorSettingsView;
    syncCompletions: boolean;
    type: ConnectorType;
  }): unknown => {
    return {
      connectorId: ((value: bigint): unknown => {
        return value;
      })(value["connectorId"]),
      languages: ((value: ("en" | "de" | "es" | "ru" | "zh" | "pt" | "fr")[]): unknown => {
        return value.map((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
          return value;
        });
      })(value["languages"]),
      name: ((value: string): unknown => {
        return value;
      })(value["name"]),
      products: ((value: ConnectorProduct[]): unknown => {
        return value.map((value: "BUSINESS_BOOKS" | "ARTICLES_AND_REPORTS" | "VIDEOS_AND_PODCASTS" | "ACTIONABLES"): unknown => {
          return value;
        });
      })(value["products"]),
      settings: ((value: {
        accountId: string | null;
        apiKey: string | null;
        clientId: string;
        clientSecret: string;
        doceboChannelId: bigint | null;
        grantType: ConnectorGrantType;
        lmsApiUri: string | null;
        lmsBaseUri: string;
        lmsTokenUri: string | null;
        oauth2Flow: Oauth2Flow;
        password: string | null;
        scope: string | null;
        sfCompanyId: string | null;
        userId: string | null;
        userIdentifier: ConnectorUserIdentifier;
        username: string | null;
        workdayCategoryFallback: string | null;
      }): unknown => {
        return {
          accountId: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["accountId"]),
          apiKey: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["apiKey"]),
          clientId: ((value: string): unknown => {
            return value;
          })(value["clientId"]),
          clientSecret: ((value: string): unknown => {
            return value;
          })(value["clientSecret"]),
          doceboChannelId: ((value: bigint | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["doceboChannelId"]),
          grantType: ((value: "CLIENT_CREDENTIALS" | "PASSWORD"): unknown => {
            return value;
          })(value["grantType"]),
          lmsApiUri: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["lmsApiUri"]),
          lmsBaseUri: ((value: string): unknown => {
            return value;
          })(value["lmsBaseUri"]),
          lmsTokenUri: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["lmsTokenUri"]),
          oauth2Flow: ((value: "BASIC" | "TWO_LEGGED_OAUTH"): unknown => {
            return value;
          })(value["oauth2Flow"]),
          password: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["password"]),
          scope: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["scope"]),
          sfCompanyId: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["sfCompanyId"]),
          userId: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["userId"]),
          userIdentifier: ((value: "NAME_ID" | "EMAIL" | "EXTRA_FIELD_1" | "EXTRA_FIELD_2" | "EXTRA_FIELD_3"): unknown => {
            return value;
          })(value["userIdentifier"]),
          username: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["username"]),
          workdayCategoryFallback: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["workdayCategoryFallback"]),
        };
      })(value["settings"]),
      syncCompletions: ((value: boolean): unknown => {
        return value;
      })(value["syncCompletions"]),
      type: ((value: "SUCCESS_FACTORS" | "DEGREED" | "DOCEBO" | "CSOD" | "SUM_TOTAL" | "EDCAST" | "WORKDAY"): unknown => {
        return value;
      })(value["type"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ConnectorView(str: string): ConnectorView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          connectorId: bigint;
          languages: ("en" | "de" | "es" | "ru" | "zh" | "pt" | "fr")[];
          name: string;
          products: ConnectorProduct[];
          settings: ConnectorSettingsView;
          syncCompletions: boolean;
          type: ConnectorType;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "connectorId")) {
      return { success: false };
    }
    const parsed_connectorId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["connectorId"]);
    })();
    if (!parsed_connectorId.success) {
      return { success: false };
    }
    if (!hasProp(value, "languages")) {
      return { success: false };
    }
    const parsed_languages = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ("en" | "de" | "es" | "ru" | "zh" | "pt" | "fr")[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "en":
              return { success: true, value: "en" };
            case "de":
              return { success: true, value: "de" };
            case "es":
              return { success: true, value: "es" };
            case "ru":
              return { success: true, value: "ru" };
            case "zh":
              return { success: true, value: "zh" };
            case "pt":
              return { success: true, value: "pt" };
            case "fr":
              return { success: true, value: "fr" };
            default:
              return { success: false };
          }
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["languages"]);
    })();
    if (!parsed_languages.success) {
      return { success: false };
    }
    if (!hasProp(value, "name")) {
      return { success: false };
    }
    const parsed_name = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["name"]);
    })();
    if (!parsed_name.success) {
      return { success: false };
    }
    if (!hasProp(value, "products")) {
      return { success: false };
    }
    const parsed_products = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ConnectorProduct[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: ConnectorProduct } => {
          return ((value: unknown): { success: false } | { success: true; value: "BUSINESS_BOOKS" | "ARTICLES_AND_REPORTS" | "VIDEOS_AND_PODCASTS" | "ACTIONABLES" } => {
            if (typeof value !== "string") {
              return { success: false };
            }
            switch (value) {
              case "BUSINESS_BOOKS":
                return { success: true, value: "BUSINESS_BOOKS" };
              case "ARTICLES_AND_REPORTS":
                return { success: true, value: "ARTICLES_AND_REPORTS" };
              case "VIDEOS_AND_PODCASTS":
                return { success: true, value: "VIDEOS_AND_PODCASTS" };
              case "ACTIONABLES":
                return { success: true, value: "ACTIONABLES" };
              default:
                return { success: false };
            }
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["products"]);
    })();
    if (!parsed_products.success) {
      return { success: false };
    }
    if (!hasProp(value, "settings")) {
      return { success: false };
    }
    const parsed_settings = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ConnectorSettingsView } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                accountId: string | null;
                apiKey: string | null;
                clientId: string;
                clientSecret: string;
                doceboChannelId: bigint | null;
                grantType: ConnectorGrantType;
                lmsApiUri: string | null;
                lmsBaseUri: string;
                lmsTokenUri: string | null;
                oauth2Flow: Oauth2Flow;
                password: string | null;
                scope: string | null;
                sfCompanyId: string | null;
                userId: string | null;
                userIdentifier: ConnectorUserIdentifier;
                username: string | null;
                workdayCategoryFallback: string | null;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "accountId")) {
            return { success: false };
          }
          const parsed_accountId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["accountId"]);
          })();
          if (!parsed_accountId.success) {
            return { success: false };
          }
          if (!hasProp(value, "apiKey")) {
            return { success: false };
          }
          const parsed_apiKey = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["apiKey"]);
          })();
          if (!parsed_apiKey.success) {
            return { success: false };
          }
          if (!hasProp(value, "clientId")) {
            return { success: false };
          }
          const parsed_clientId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["clientId"]);
          })();
          if (!parsed_clientId.success) {
            return { success: false };
          }
          if (!hasProp(value, "clientSecret")) {
            return { success: false };
          }
          const parsed_clientSecret = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["clientSecret"]);
          })();
          if (!parsed_clientSecret.success) {
            return { success: false };
          }
          if (!hasProp(value, "doceboChannelId")) {
            return { success: false };
          }
          const parsed_doceboChannelId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: bigint | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value === "number") {
                return { success: true, value: BigInt(value) };
              }
              if (typeof value === "bigint") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["doceboChannelId"]);
          })();
          if (!parsed_doceboChannelId.success) {
            return { success: false };
          }
          if (!hasProp(value, "grantType")) {
            return { success: false };
          }
          const parsed_grantType = (() => {
            return ((value: unknown): { success: false } | { success: true; value: ConnectorGrantType } => {
              return ((value: unknown): { success: false } | { success: true; value: "CLIENT_CREDENTIALS" | "PASSWORD" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "CLIENT_CREDENTIALS":
                    return { success: true, value: "CLIENT_CREDENTIALS" };
                  case "PASSWORD":
                    return { success: true, value: "PASSWORD" };
                  default:
                    return { success: false };
                }
              })(value);
            })(value["grantType"]);
          })();
          if (!parsed_grantType.success) {
            return { success: false };
          }
          if (!hasProp(value, "lmsApiUri")) {
            return { success: false };
          }
          const parsed_lmsApiUri = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["lmsApiUri"]);
          })();
          if (!parsed_lmsApiUri.success) {
            return { success: false };
          }
          if (!hasProp(value, "lmsBaseUri")) {
            return { success: false };
          }
          const parsed_lmsBaseUri = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["lmsBaseUri"]);
          })();
          if (!parsed_lmsBaseUri.success) {
            return { success: false };
          }
          if (!hasProp(value, "lmsTokenUri")) {
            return { success: false };
          }
          const parsed_lmsTokenUri = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["lmsTokenUri"]);
          })();
          if (!parsed_lmsTokenUri.success) {
            return { success: false };
          }
          if (!hasProp(value, "oauth2Flow")) {
            return { success: false };
          }
          const parsed_oauth2Flow = (() => {
            return ((value: unknown): { success: false } | { success: true; value: Oauth2Flow } => {
              return ((value: unknown): { success: false } | { success: true; value: "BASIC" | "TWO_LEGGED_OAUTH" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "BASIC":
                    return { success: true, value: "BASIC" };
                  case "TWO_LEGGED_OAUTH":
                    return { success: true, value: "TWO_LEGGED_OAUTH" };
                  default:
                    return { success: false };
                }
              })(value);
            })(value["oauth2Flow"]);
          })();
          if (!parsed_oauth2Flow.success) {
            return { success: false };
          }
          if (!hasProp(value, "password")) {
            return { success: false };
          }
          const parsed_password = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["password"]);
          })();
          if (!parsed_password.success) {
            return { success: false };
          }
          if (!hasProp(value, "scope")) {
            return { success: false };
          }
          const parsed_scope = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["scope"]);
          })();
          if (!parsed_scope.success) {
            return { success: false };
          }
          if (!hasProp(value, "sfCompanyId")) {
            return { success: false };
          }
          const parsed_sfCompanyId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["sfCompanyId"]);
          })();
          if (!parsed_sfCompanyId.success) {
            return { success: false };
          }
          if (!hasProp(value, "userId")) {
            return { success: false };
          }
          const parsed_userId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["userId"]);
          })();
          if (!parsed_userId.success) {
            return { success: false };
          }
          if (!hasProp(value, "userIdentifier")) {
            return { success: false };
          }
          const parsed_userIdentifier = (() => {
            return ((value: unknown): { success: false } | { success: true; value: ConnectorUserIdentifier } => {
              return ((value: unknown): { success: false } | { success: true; value: "NAME_ID" | "EMAIL" | "EXTRA_FIELD_1" | "EXTRA_FIELD_2" | "EXTRA_FIELD_3" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "NAME_ID":
                    return { success: true, value: "NAME_ID" };
                  case "EMAIL":
                    return { success: true, value: "EMAIL" };
                  case "EXTRA_FIELD_1":
                    return { success: true, value: "EXTRA_FIELD_1" };
                  case "EXTRA_FIELD_2":
                    return { success: true, value: "EXTRA_FIELD_2" };
                  case "EXTRA_FIELD_3":
                    return { success: true, value: "EXTRA_FIELD_3" };
                  default:
                    return { success: false };
                }
              })(value);
            })(value["userIdentifier"]);
          })();
          if (!parsed_userIdentifier.success) {
            return { success: false };
          }
          if (!hasProp(value, "username")) {
            return { success: false };
          }
          const parsed_username = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["username"]);
          })();
          if (!parsed_username.success) {
            return { success: false };
          }
          if (!hasProp(value, "workdayCategoryFallback")) {
            return { success: false };
          }
          const parsed_workdayCategoryFallback = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["workdayCategoryFallback"]);
          })();
          if (!parsed_workdayCategoryFallback.success) {
            return { success: false };
          }
          return {
            success: true,
            value: {
              accountId: parsed_accountId.value,
              apiKey: parsed_apiKey.value,
              clientId: parsed_clientId.value,
              clientSecret: parsed_clientSecret.value,
              doceboChannelId: parsed_doceboChannelId.value,
              grantType: parsed_grantType.value,
              lmsApiUri: parsed_lmsApiUri.value,
              lmsBaseUri: parsed_lmsBaseUri.value,
              lmsTokenUri: parsed_lmsTokenUri.value,
              oauth2Flow: parsed_oauth2Flow.value,
              password: parsed_password.value,
              scope: parsed_scope.value,
              sfCompanyId: parsed_sfCompanyId.value,
              userId: parsed_userId.value,
              userIdentifier: parsed_userIdentifier.value,
              username: parsed_username.value,
              workdayCategoryFallback: parsed_workdayCategoryFallback.value,
            },
          };
        })(value);
      })(value["settings"]);
    })();
    if (!parsed_settings.success) {
      return { success: false };
    }
    if (!hasProp(value, "syncCompletions")) {
      return { success: false };
    }
    const parsed_syncCompletions = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["syncCompletions"]);
    })();
    if (!parsed_syncCompletions.success) {
      return { success: false };
    }
    if (!hasProp(value, "type")) {
      return { success: false };
    }
    const parsed_type = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ConnectorType } => {
        return ((value: unknown): { success: false } | { success: true; value: "SUCCESS_FACTORS" | "DEGREED" | "DOCEBO" | "CSOD" | "SUM_TOTAL" | "EDCAST" | "WORKDAY" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "SUCCESS_FACTORS":
              return { success: true, value: "SUCCESS_FACTORS" };
            case "DEGREED":
              return { success: true, value: "DEGREED" };
            case "DOCEBO":
              return { success: true, value: "DOCEBO" };
            case "CSOD":
              return { success: true, value: "CSOD" };
            case "SUM_TOTAL":
              return { success: true, value: "SUM_TOTAL" };
            case "EDCAST":
              return { success: true, value: "EDCAST" };
            case "WORKDAY":
              return { success: true, value: "WORKDAY" };
            default:
              return { success: false };
          }
        })(value);
      })(value["type"]);
    })();
    if (!parsed_type.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        connectorId: parsed_connectorId.value,
        languages: parsed_languages.value,
        name: parsed_name.value,
        products: parsed_products.value,
        settings: parsed_settings.value,
        syncCompletions: parsed_syncCompletions.value,
        type: parsed_type.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// Country
export function serialize_Country(obj: Country): string {
  const serialized = ((value: {
    countryCode: string;
    currency: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY";
    names: {
      de: string;
      en: string;
      es: string;
      fr: string;
      pt: string;
      ru: string;
      zh: string;
    };
    phonePrefix: string;
    region: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA";
  }): unknown => {
    return {
      countryCode: ((value: string): unknown => {
        return value;
      })(value["countryCode"]),
      currency: ((value: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY"): unknown => {
        return value;
      })(value["currency"]),
      names: ((value: { de: string; en: string; es: string; fr: string; pt: string; ru: string; zh: string }): unknown => {
        return {
          de: ((value: string): unknown => {
            return value;
          })(value["de"]),
          en: ((value: string): unknown => {
            return value;
          })(value["en"]),
          es: ((value: string): unknown => {
            return value;
          })(value["es"]),
          fr: ((value: string): unknown => {
            return value;
          })(value["fr"]),
          pt: ((value: string): unknown => {
            return value;
          })(value["pt"]),
          ru: ((value: string): unknown => {
            return value;
          })(value["ru"]),
          zh: ((value: string): unknown => {
            return value;
          })(value["zh"]),
        };
      })(value["names"]),
      phonePrefix: ((value: string): unknown => {
        return value;
      })(value["phonePrefix"]),
      region: ((value: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA"): unknown => {
        return value;
      })(value["region"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_Country(str: string): Country | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          countryCode: string;
          currency: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY";
          names: {
            de: string;
            en: string;
            es: string;
            fr: string;
            pt: string;
            ru: string;
            zh: string;
          };
          phonePrefix: string;
          region: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA";
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "countryCode")) {
      return { success: false };
    }
    const parsed_countryCode = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["countryCode"]);
    })();
    if (!parsed_countryCode.success) {
      return { success: false };
    }
    if (!hasProp(value, "currency")) {
      return { success: false };
    }
    const parsed_currency = (() => {
      return ((value: unknown): { success: false } | { success: true; value: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY" } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        switch (value) {
          case "CHF":
            return { success: true, value: "CHF" };
          case "CNY":
            return { success: true, value: "CNY" };
          case "USD":
            return { success: true, value: "USD" };
          case "EUR":
            return { success: true, value: "EUR" };
          case "GBP":
            return { success: true, value: "GBP" };
          case "INR":
            return { success: true, value: "INR" };
          case "JPY":
            return { success: true, value: "JPY" };
          default:
            return { success: false };
        }
      })(value["currency"]);
    })();
    if (!parsed_currency.success) {
      return { success: false };
    }
    if (!hasProp(value, "names")) {
      return { success: false };
    }
    const parsed_names = (() => {
      return ((
        value: unknown,
      ):
        | { success: false }
        | {
            success: true;
            value: {
              de: string;
              en: string;
              es: string;
              fr: string;
              pt: string;
              ru: string;
              zh: string;
            };
          } => {
        if (typeof value !== "object") {
          return { success: false };
        }
        if (value === null) {
          return { success: false };
        }
        if (!hasProp(value, "de")) {
          return { success: false };
        }
        const parsed_de = (() => {
          return ((value: unknown): { success: false } | { success: true; value: string } => {
            if (typeof value !== "string") {
              return { success: false };
            }
            return { success: true, value: value };
          })(value["de"]);
        })();
        if (!parsed_de.success) {
          return { success: false };
        }
        if (!hasProp(value, "en")) {
          return { success: false };
        }
        const parsed_en = (() => {
          return ((value: unknown): { success: false } | { success: true; value: string } => {
            if (typeof value !== "string") {
              return { success: false };
            }
            return { success: true, value: value };
          })(value["en"]);
        })();
        if (!parsed_en.success) {
          return { success: false };
        }
        if (!hasProp(value, "es")) {
          return { success: false };
        }
        const parsed_es = (() => {
          return ((value: unknown): { success: false } | { success: true; value: string } => {
            if (typeof value !== "string") {
              return { success: false };
            }
            return { success: true, value: value };
          })(value["es"]);
        })();
        if (!parsed_es.success) {
          return { success: false };
        }
        if (!hasProp(value, "fr")) {
          return { success: false };
        }
        const parsed_fr = (() => {
          return ((value: unknown): { success: false } | { success: true; value: string } => {
            if (typeof value !== "string") {
              return { success: false };
            }
            return { success: true, value: value };
          })(value["fr"]);
        })();
        if (!parsed_fr.success) {
          return { success: false };
        }
        if (!hasProp(value, "pt")) {
          return { success: false };
        }
        const parsed_pt = (() => {
          return ((value: unknown): { success: false } | { success: true; value: string } => {
            if (typeof value !== "string") {
              return { success: false };
            }
            return { success: true, value: value };
          })(value["pt"]);
        })();
        if (!parsed_pt.success) {
          return { success: false };
        }
        if (!hasProp(value, "ru")) {
          return { success: false };
        }
        const parsed_ru = (() => {
          return ((value: unknown): { success: false } | { success: true; value: string } => {
            if (typeof value !== "string") {
              return { success: false };
            }
            return { success: true, value: value };
          })(value["ru"]);
        })();
        if (!parsed_ru.success) {
          return { success: false };
        }
        if (!hasProp(value, "zh")) {
          return { success: false };
        }
        const parsed_zh = (() => {
          return ((value: unknown): { success: false } | { success: true; value: string } => {
            if (typeof value !== "string") {
              return { success: false };
            }
            return { success: true, value: value };
          })(value["zh"]);
        })();
        if (!parsed_zh.success) {
          return { success: false };
        }
        return { success: true, value: { de: parsed_de.value, en: parsed_en.value, es: parsed_es.value, fr: parsed_fr.value, pt: parsed_pt.value, ru: parsed_ru.value, zh: parsed_zh.value } };
      })(value["names"]);
    })();
    if (!parsed_names.success) {
      return { success: false };
    }
    if (!hasProp(value, "phonePrefix")) {
      return { success: false };
    }
    const parsed_phonePrefix = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["phonePrefix"]);
    })();
    if (!parsed_phonePrefix.success) {
      return { success: false };
    }
    if (!hasProp(value, "region")) {
      return { success: false };
    }
    const parsed_region = (() => {
      return ((value: unknown): { success: false } | { success: true; value: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA" } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        switch (value) {
          case "SOUTHAMERICA":
            return { success: true, value: "SOUTHAMERICA" };
          case "EUROPE":
            return { success: true, value: "EUROPE" };
          case "OCEANIA":
            return { success: true, value: "OCEANIA" };
          case "NORTHAMERICA":
            return { success: true, value: "NORTHAMERICA" };
          case "AFRICA":
            return { success: true, value: "AFRICA" };
          case "ANTARCTICA":
            return { success: true, value: "ANTARCTICA" };
          case "ASIA":
            return { success: true, value: "ASIA" };
          default:
            return { success: false };
        }
      })(value["region"]);
    })();
    if (!parsed_region.success) {
      return { success: false };
    }
    return { success: true, value: { countryCode: parsed_countryCode.value, currency: parsed_currency.value, names: parsed_names.value, phonePrefix: parsed_phonePrefix.value, region: parsed_region.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// CustomPageAdminSearchForm
export function serialize_CustomPageAdminSearchForm(obj: CustomPageAdminSearchForm): string {
  const serialized = ((value: { includeInactive: boolean; onlyHighlighted: boolean; searchTerm: string }): unknown => {
    return {
      includeInactive: ((value: boolean): unknown => {
        return value;
      })(value["includeInactive"]),
      onlyHighlighted: ((value: boolean): unknown => {
        return value;
      })(value["onlyHighlighted"]),
      searchTerm: ((value: string): unknown => {
        return value;
      })(value["searchTerm"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_CustomPageAdminSearchForm(str: string): CustomPageAdminSearchForm | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          includeInactive: boolean;
          onlyHighlighted: boolean;
          searchTerm: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "includeInactive")) {
      return { success: false };
    }
    const parsed_includeInactive = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["includeInactive"]);
    })();
    if (!parsed_includeInactive.success) {
      return { success: false };
    }
    if (!hasProp(value, "onlyHighlighted")) {
      return { success: false };
    }
    const parsed_onlyHighlighted = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["onlyHighlighted"]);
    })();
    if (!parsed_onlyHighlighted.success) {
      return { success: false };
    }
    if (!hasProp(value, "searchTerm")) {
      return { success: false };
    }
    const parsed_searchTerm = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["searchTerm"]);
    })();
    if (!parsed_searchTerm.success) {
      return { success: false };
    }
    return { success: true, value: { includeInactive: parsed_includeInactive.value, onlyHighlighted: parsed_onlyHighlighted.value, searchTerm: parsed_searchTerm.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// CustomPageCopyRequest
export function serialize_CustomPageCopyRequest(obj: CustomPageCopyRequest): string {
  const serialized = ((value: { toCorporateId: number }): unknown => {
    return {
      toCorporateId: ((value: number): unknown => {
        return value;
      })(value["toCorporateId"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_CustomPageCopyRequest(str: string): CustomPageCopyRequest | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          toCorporateId: number;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "toCorporateId")) {
      return { success: false };
    }
    const parsed_toCorporateId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["toCorporateId"]);
    })();
    if (!parsed_toCorporateId.success) {
      return { success: false };
    }
    return { success: true, value: { toCorporateId: parsed_toCorporateId.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// CustomPageEditForm
export function serialize_CustomPageEditForm(obj: CustomPageEditForm): string {
  const serialized = ((value: {
    active: boolean;
    customPageLocalization: { additionalProperties: Record<string, CustomPageLocalizationEditForm> };
    defaultCover: boolean;
    headerLayout: CustomPageHeaderLayout;
    portletIds: number[];
  }): unknown => {
    return {
      active: ((value: boolean): unknown => {
        return value;
      })(value["active"]),
      customPageLocalization: ((value: { additionalProperties: Record<string, CustomPageLocalizationEditForm> }): unknown => {
        const unordered_obj: Record<string, unknown> = {
          ...Object.fromEntries(
            Object.entries(value.additionalProperties).map(([propertyKey, propertyValue]) => {
              return [
                propertyKey,
                ((value: { description: string | null; title: string }): unknown => {
                  return {
                    description: ((value: string | null): unknown => {
                      if (value === null) {
                        return null;
                      }
                      return value;
                    })(value["description"]),
                    title: ((value: string): unknown => {
                      return value;
                    })(value["title"]),
                  };
                })(propertyValue),
              ];
            }),
          ),
        };
        return Object.keys(unordered_obj)
          .sort()
          .reduce(
            (obj, key) => {
              obj[key] = unordered_obj[key];
              return obj;
            },
            {} as Record<string, unknown>,
          );
      })(value["customPageLocalization"]),
      defaultCover: ((value: boolean): unknown => {
        return value;
      })(value["defaultCover"]),
      headerLayout: ((value: "NONE" | "DEFAULT"): unknown => {
        return value;
      })(value["headerLayout"]),
      portletIds: ((value: number[]): unknown => {
        return value.map((value: number): unknown => {
          return value;
        });
      })(value["portletIds"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_CustomPageEditForm(str: string): CustomPageEditForm | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          active: boolean;
          customPageLocalization: { additionalProperties: Record<string, CustomPageLocalizationEditForm> };
          defaultCover: boolean;
          headerLayout: CustomPageHeaderLayout;
          portletIds: number[];
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "active")) {
      return { success: false };
    }
    const parsed_active = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["active"]);
    })();
    if (!parsed_active.success) {
      return { success: false };
    }
    if (!hasProp(value, "customPageLocalization")) {
      return { success: false };
    }
    const parsed_customPageLocalization = (() => {
      return ((value: unknown): { success: false } | { success: true; value: { additionalProperties: Record<string, CustomPageLocalizationEditForm> } } => {
        if (typeof value !== "object") {
          return { success: false };
        }
        if (value === null) {
          return { success: false };
        }
        const parsed_additionalProperties = ((): { success: false } | { success: true; value: Record<string, CustomPageLocalizationEditForm> } => {
          const parsed = Object.entries(value).map(([key, value]): [string, { success: false } | { success: true; value: CustomPageLocalizationEditForm }] => {
            return [
              key,
              ((value: unknown): { success: false } | { success: true; value: CustomPageLocalizationEditForm } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        description: string | null;
                        title: string;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "description")) {
                    return { success: false };
                  }
                  const parsed_description = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["description"]);
                  })();
                  if (!parsed_description.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "title")) {
                    return { success: false };
                  }
                  const parsed_title = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["title"]);
                  })();
                  if (!parsed_title.success) {
                    return { success: false };
                  }
                  return { success: true, value: { description: parsed_description.value, title: parsed_title.value } };
                })(value);
              })(value),
            ];
          });
          if (parsed.find(([_, value]) => !value.success) !== undefined) {
            console.log("1");
            console.log(parsed);
            return { success: false };
          }
          return {
            success: true,
            value: Object.fromEntries(
              parsed.map(([key, value]) => {
                assert(value.success);
                return [key, value.value];
              }),
            ),
          };
        })();
        if (!parsed_additionalProperties.success) {
          return { success: false };
        }
        return { success: true, value: { additionalProperties: parsed_additionalProperties.value } };
      })(value["customPageLocalization"]);
    })();
    if (!parsed_customPageLocalization.success) {
      return { success: false };
    }
    if (!hasProp(value, "defaultCover")) {
      return { success: false };
    }
    const parsed_defaultCover = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["defaultCover"]);
    })();
    if (!parsed_defaultCover.success) {
      return { success: false };
    }
    if (!hasProp(value, "headerLayout")) {
      return { success: false };
    }
    const parsed_headerLayout = (() => {
      return ((value: unknown): { success: false } | { success: true; value: CustomPageHeaderLayout } => {
        return ((value: unknown): { success: false } | { success: true; value: "NONE" | "DEFAULT" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "NONE":
              return { success: true, value: "NONE" };
            case "DEFAULT":
              return { success: true, value: "DEFAULT" };
            default:
              return { success: false };
          }
        })(value);
      })(value["headerLayout"]);
    })();
    if (!parsed_headerLayout.success) {
      return { success: false };
    }
    if (!hasProp(value, "portletIds")) {
      return { success: false };
    }
    const parsed_portletIds = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: number } => {
          if (typeof value === "number") {
            return { success: true, value: value };
          }
          return { success: false };
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["portletIds"]);
    })();
    if (!parsed_portletIds.success) {
      return { success: false };
    }
    return {
      success: true,
      value: { active: parsed_active.value, customPageLocalization: parsed_customPageLocalization.value, defaultCover: parsed_defaultCover.value, headerLayout: parsed_headerLayout.value, portletIds: parsed_portletIds.value },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// CustomPageEditListView
export function serialize_CustomPageEditListView(obj: CustomPageEditListView): string {
  const serialized = ((value: {
    active: boolean;
    corporateId: number;
    corporation: string;
    customPageId: bigint;
    highlightPriority: number | null;
    modifiedAt: DateTime<true>;
    titles: { additionalProperties: Record<string, string> };
  }): unknown => {
    return {
      active: ((value: boolean): unknown => {
        return value;
      })(value["active"]),
      corporateId: ((value: number): unknown => {
        return value;
      })(value["corporateId"]),
      corporation: ((value: string): unknown => {
        return value;
      })(value["corporation"]),
      customPageId: ((value: bigint): unknown => {
        return value;
      })(value["customPageId"]),
      highlightPriority: ((value: number | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["highlightPriority"]),
      modifiedAt: ((value: DateTime<true>): unknown => {
        return value.toISO();
      })(value["modifiedAt"]),
      titles: ((value: { additionalProperties: Record<string, string> }): unknown => {
        const unordered_obj: Record<string, unknown> = {
          ...Object.fromEntries(
            Object.entries(value.additionalProperties).map(([propertyKey, propertyValue]) => {
              return [
                propertyKey,
                ((value: string): unknown => {
                  return value;
                })(propertyValue),
              ];
            }),
          ),
        };
        return Object.keys(unordered_obj)
          .sort()
          .reduce(
            (obj, key) => {
              obj[key] = unordered_obj[key];
              return obj;
            },
            {} as Record<string, unknown>,
          );
      })(value["titles"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_CustomPageEditListView(str: string): CustomPageEditListView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          active: boolean;
          corporateId: number;
          corporation: string;
          customPageId: bigint;
          highlightPriority: number | null;
          modifiedAt: DateTime<true>;
          titles: { additionalProperties: Record<string, string> };
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "active")) {
      return { success: false };
    }
    const parsed_active = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["active"]);
    })();
    if (!parsed_active.success) {
      return { success: false };
    }
    if (!hasProp(value, "corporateId")) {
      return { success: false };
    }
    const parsed_corporateId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["corporateId"]);
    })();
    if (!parsed_corporateId.success) {
      return { success: false };
    }
    if (!hasProp(value, "corporation")) {
      return { success: false };
    }
    const parsed_corporation = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["corporation"]);
    })();
    if (!parsed_corporation.success) {
      return { success: false };
    }
    if (!hasProp(value, "customPageId")) {
      return { success: false };
    }
    const parsed_customPageId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["customPageId"]);
    })();
    if (!parsed_customPageId.success) {
      return { success: false };
    }
    if (!hasProp(value, "highlightPriority")) {
      return { success: false };
    }
    const parsed_highlightPriority = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["highlightPriority"]);
    })();
    if (!parsed_highlightPriority.success) {
      return { success: false };
    }
    if (!hasProp(value, "modifiedAt")) {
      return { success: false };
    }
    const parsed_modifiedAt = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        const parsed = DateTime.fromISO(value.toUpperCase(), { setZone: true });
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["modifiedAt"]);
    })();
    if (!parsed_modifiedAt.success) {
      return { success: false };
    }
    if (!hasProp(value, "titles")) {
      return { success: false };
    }
    const parsed_titles = (() => {
      return ((value: unknown): { success: false } | { success: true; value: { additionalProperties: Record<string, string> } } => {
        if (typeof value !== "object") {
          return { success: false };
        }
        if (value === null) {
          return { success: false };
        }
        const parsed_additionalProperties = ((): { success: false } | { success: true; value: Record<string, string> } => {
          const parsed = Object.entries(value).map(([key, value]): [string, { success: false } | { success: true; value: string }] => {
            return [
              key,
              ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value),
            ];
          });
          if (parsed.find(([_, value]) => !value.success) !== undefined) {
            console.log("1");
            console.log(parsed);
            return { success: false };
          }
          return {
            success: true,
            value: Object.fromEntries(
              parsed.map(([key, value]) => {
                assert(value.success);
                return [key, value.value];
              }),
            ),
          };
        })();
        if (!parsed_additionalProperties.success) {
          return { success: false };
        }
        return { success: true, value: { additionalProperties: parsed_additionalProperties.value } };
      })(value["titles"]);
    })();
    if (!parsed_titles.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        active: parsed_active.value,
        corporateId: parsed_corporateId.value,
        corporation: parsed_corporation.value,
        customPageId: parsed_customPageId.value,
        highlightPriority: parsed_highlightPriority.value,
        modifiedAt: parsed_modifiedAt.value,
        titles: parsed_titles.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// CustomPageEditView
export function serialize_CustomPageEditView(obj: CustomPageEditView): string {
  const serialized = ((value: {
    active: boolean;
    corporateId: number;
    coverUrl: string;
    customPageId: bigint;
    customPageLocalization: { additionalProperties: Record<string, CustomPageLocalizationEditView> };
    headerLayout: CustomPageHeaderLayout;
    portlets: PortletMetadata[];
  }): unknown => {
    return {
      active: ((value: boolean): unknown => {
        return value;
      })(value["active"]),
      corporateId: ((value: number): unknown => {
        return value;
      })(value["corporateId"]),
      coverUrl: ((value: string): unknown => {
        return value;
      })(value["coverUrl"]),
      customPageId: ((value: bigint): unknown => {
        return value;
      })(value["customPageId"]),
      customPageLocalization: ((value: { additionalProperties: Record<string, CustomPageLocalizationEditView> }): unknown => {
        const unordered_obj: Record<string, unknown> = {
          ...Object.fromEntries(
            Object.entries(value.additionalProperties).map(([propertyKey, propertyValue]) => {
              return [
                propertyKey,
                ((value: { description: string | null; title: string }): unknown => {
                  return {
                    description: ((value: string | null): unknown => {
                      if (value === null) {
                        return null;
                      }
                      return value;
                    })(value["description"]),
                    title: ((value: string): unknown => {
                      return value;
                    })(value["title"]),
                  };
                })(propertyValue),
              ];
            }),
          ),
        };
        return Object.keys(unordered_obj)
          .sort()
          .reduce(
            (obj, key) => {
              obj[key] = unordered_obj[key];
              return obj;
            },
            {} as Record<string, unknown>,
          );
      })(value["customPageLocalization"]),
      headerLayout: ((value: "NONE" | "DEFAULT"): unknown => {
        return value;
      })(value["headerLayout"]),
      portlets: ((value: PortletMetadata[]): unknown => {
        return value.map(
          (value: {
            custom: boolean;
            defaultFilterAllLanguages: boolean;
            embedSortingFiltering: boolean;
            hideTitle: boolean;
            itemCount: number;
            itemLayout: ItemLayout;
            name: string;
            portletId: number;
            portletLayout: PortletLayout;
            subType: string | null;
            title: string;
            type: PortletType;
          }): unknown => {
            return {
              custom: ((value: boolean): unknown => {
                return value;
              })(value["custom"]),
              defaultFilterAllLanguages: ((value: boolean): unknown => {
                return value;
              })(value["defaultFilterAllLanguages"]),
              embedSortingFiltering: ((value: boolean): unknown => {
                return value;
              })(value["embedSortingFiltering"]),
              hideTitle: ((value: boolean): unknown => {
                return value;
              })(value["hideTitle"]),
              itemCount: ((value: number): unknown => {
                return value;
              })(value["itemCount"]),
              itemLayout: ((value: "MINI" | "REGULAR"): unknown => {
                return value;
              })(value["itemLayout"]),
              name: ((value: string): unknown => {
                return value;
              })(value["name"]),
              portletId: ((value: number): unknown => {
                return value;
              })(value["portletId"]),
              portletLayout: ((value: "GRID" | "CAROUSEL"): unknown => {
                return value;
              })(value["portletLayout"]),
              subType: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["subType"]),
              title: ((value: string): unknown => {
                return value;
              })(value["title"]),
              type: ((
                value:
                  | "IMAGETEXT"
                  | "SUMMARYLIST"
                  | "TRENDINGCHANNEL"
                  | "FEATUREDCHANNEL"
                  | "FOLLOWINGCHANNEL"
                  | "MYLIST"
                  | "PRIMARYTOPICCHANNEL"
                  | "CHANNELLIST"
                  | "ANNOTATIONLIST"
                  | "LGXPLIST"
                  | "SKETCHNOTESLGXP"
                  | "ACTIONABLELIST"
                  | "ACTIONABLELISTCUSTOM"
                  | "MYACTIONABLES"
                  | "FREESUMMARY"
                  | "SUMMARYLISTCUSTOM"
                  | "CUSTOMPAGELIST",
              ): unknown => {
                return value;
              })(value["type"]),
            };
          },
        );
      })(value["portlets"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_CustomPageEditView(str: string): CustomPageEditView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          active: boolean;
          corporateId: number;
          coverUrl: string;
          customPageId: bigint;
          customPageLocalization: { additionalProperties: Record<string, CustomPageLocalizationEditView> };
          headerLayout: CustomPageHeaderLayout;
          portlets: PortletMetadata[];
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "active")) {
      return { success: false };
    }
    const parsed_active = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["active"]);
    })();
    if (!parsed_active.success) {
      return { success: false };
    }
    if (!hasProp(value, "corporateId")) {
      return { success: false };
    }
    const parsed_corporateId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["corporateId"]);
    })();
    if (!parsed_corporateId.success) {
      return { success: false };
    }
    if (!hasProp(value, "coverUrl")) {
      return { success: false };
    }
    const parsed_coverUrl = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["coverUrl"]);
    })();
    if (!parsed_coverUrl.success) {
      return { success: false };
    }
    if (!hasProp(value, "customPageId")) {
      return { success: false };
    }
    const parsed_customPageId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["customPageId"]);
    })();
    if (!parsed_customPageId.success) {
      return { success: false };
    }
    if (!hasProp(value, "customPageLocalization")) {
      return { success: false };
    }
    const parsed_customPageLocalization = (() => {
      return ((value: unknown): { success: false } | { success: true; value: { additionalProperties: Record<string, CustomPageLocalizationEditView> } } => {
        if (typeof value !== "object") {
          return { success: false };
        }
        if (value === null) {
          return { success: false };
        }
        const parsed_additionalProperties = ((): { success: false } | { success: true; value: Record<string, CustomPageLocalizationEditView> } => {
          const parsed = Object.entries(value).map(([key, value]): [string, { success: false } | { success: true; value: CustomPageLocalizationEditView }] => {
            return [
              key,
              ((value: unknown): { success: false } | { success: true; value: CustomPageLocalizationEditView } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        description: string | null;
                        title: string;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "description")) {
                    return { success: false };
                  }
                  const parsed_description = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["description"]);
                  })();
                  if (!parsed_description.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "title")) {
                    return { success: false };
                  }
                  const parsed_title = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["title"]);
                  })();
                  if (!parsed_title.success) {
                    return { success: false };
                  }
                  return { success: true, value: { description: parsed_description.value, title: parsed_title.value } };
                })(value);
              })(value),
            ];
          });
          if (parsed.find(([_, value]) => !value.success) !== undefined) {
            console.log("1");
            console.log(parsed);
            return { success: false };
          }
          return {
            success: true,
            value: Object.fromEntries(
              parsed.map(([key, value]) => {
                assert(value.success);
                return [key, value.value];
              }),
            ),
          };
        })();
        if (!parsed_additionalProperties.success) {
          return { success: false };
        }
        return { success: true, value: { additionalProperties: parsed_additionalProperties.value } };
      })(value["customPageLocalization"]);
    })();
    if (!parsed_customPageLocalization.success) {
      return { success: false };
    }
    if (!hasProp(value, "headerLayout")) {
      return { success: false };
    }
    const parsed_headerLayout = (() => {
      return ((value: unknown): { success: false } | { success: true; value: CustomPageHeaderLayout } => {
        return ((value: unknown): { success: false } | { success: true; value: "NONE" | "DEFAULT" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "NONE":
              return { success: true, value: "NONE" };
            case "DEFAULT":
              return { success: true, value: "DEFAULT" };
            default:
              return { success: false };
          }
        })(value);
      })(value["headerLayout"]);
    })();
    if (!parsed_headerLayout.success) {
      return { success: false };
    }
    if (!hasProp(value, "portlets")) {
      return { success: false };
    }
    const parsed_portlets = (() => {
      return ((value: unknown): { success: false } | { success: true; value: PortletMetadata[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: PortletMetadata } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  custom: boolean;
                  defaultFilterAllLanguages: boolean;
                  embedSortingFiltering: boolean;
                  hideTitle: boolean;
                  itemCount: number;
                  itemLayout: ItemLayout;
                  name: string;
                  portletId: number;
                  portletLayout: PortletLayout;
                  subType: string | null;
                  title: string;
                  type: PortletType;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "custom")) {
              return { success: false };
            }
            const parsed_custom = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["custom"]);
            })();
            if (!parsed_custom.success) {
              return { success: false };
            }
            if (!hasProp(value, "defaultFilterAllLanguages")) {
              return { success: false };
            }
            const parsed_defaultFilterAllLanguages = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["defaultFilterAllLanguages"]);
            })();
            if (!parsed_defaultFilterAllLanguages.success) {
              return { success: false };
            }
            if (!hasProp(value, "embedSortingFiltering")) {
              return { success: false };
            }
            const parsed_embedSortingFiltering = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["embedSortingFiltering"]);
            })();
            if (!parsed_embedSortingFiltering.success) {
              return { success: false };
            }
            if (!hasProp(value, "hideTitle")) {
              return { success: false };
            }
            const parsed_hideTitle = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["hideTitle"]);
            })();
            if (!parsed_hideTitle.success) {
              return { success: false };
            }
            if (!hasProp(value, "itemCount")) {
              return { success: false };
            }
            const parsed_itemCount = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["itemCount"]);
            })();
            if (!parsed_itemCount.success) {
              return { success: false };
            }
            if (!hasProp(value, "itemLayout")) {
              return { success: false };
            }
            const parsed_itemLayout = (() => {
              return ((value: unknown): { success: false } | { success: true; value: ItemLayout } => {
                return ((value: unknown): { success: false } | { success: true; value: "MINI" | "REGULAR" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "MINI":
                      return { success: true, value: "MINI" };
                    case "REGULAR":
                      return { success: true, value: "REGULAR" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["itemLayout"]);
            })();
            if (!parsed_itemLayout.success) {
              return { success: false };
            }
            if (!hasProp(value, "name")) {
              return { success: false };
            }
            const parsed_name = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["name"]);
            })();
            if (!parsed_name.success) {
              return { success: false };
            }
            if (!hasProp(value, "portletId")) {
              return { success: false };
            }
            const parsed_portletId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["portletId"]);
            })();
            if (!parsed_portletId.success) {
              return { success: false };
            }
            if (!hasProp(value, "portletLayout")) {
              return { success: false };
            }
            const parsed_portletLayout = (() => {
              return ((value: unknown): { success: false } | { success: true; value: PortletLayout } => {
                return ((value: unknown): { success: false } | { success: true; value: "GRID" | "CAROUSEL" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "GRID":
                      return { success: true, value: "GRID" };
                    case "CAROUSEL":
                      return { success: true, value: "CAROUSEL" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["portletLayout"]);
            })();
            if (!parsed_portletLayout.success) {
              return { success: false };
            }
            if (!hasProp(value, "subType")) {
              return { success: false };
            }
            const parsed_subType = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["subType"]);
            })();
            if (!parsed_subType.success) {
              return { success: false };
            }
            if (!hasProp(value, "title")) {
              return { success: false };
            }
            const parsed_title = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["title"]);
            })();
            if (!parsed_title.success) {
              return { success: false };
            }
            if (!hasProp(value, "type")) {
              return { success: false };
            }
            const parsed_type = (() => {
              return ((value: unknown): { success: false } | { success: true; value: PortletType } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value:
                        | "IMAGETEXT"
                        | "SUMMARYLIST"
                        | "TRENDINGCHANNEL"
                        | "FEATUREDCHANNEL"
                        | "FOLLOWINGCHANNEL"
                        | "MYLIST"
                        | "PRIMARYTOPICCHANNEL"
                        | "CHANNELLIST"
                        | "ANNOTATIONLIST"
                        | "LGXPLIST"
                        | "SKETCHNOTESLGXP"
                        | "ACTIONABLELIST"
                        | "ACTIONABLELISTCUSTOM"
                        | "MYACTIONABLES"
                        | "FREESUMMARY"
                        | "SUMMARYLISTCUSTOM"
                        | "CUSTOMPAGELIST";
                    } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "IMAGETEXT":
                      return { success: true, value: "IMAGETEXT" };
                    case "SUMMARYLIST":
                      return { success: true, value: "SUMMARYLIST" };
                    case "TRENDINGCHANNEL":
                      return { success: true, value: "TRENDINGCHANNEL" };
                    case "FEATUREDCHANNEL":
                      return { success: true, value: "FEATUREDCHANNEL" };
                    case "FOLLOWINGCHANNEL":
                      return { success: true, value: "FOLLOWINGCHANNEL" };
                    case "MYLIST":
                      return { success: true, value: "MYLIST" };
                    case "PRIMARYTOPICCHANNEL":
                      return { success: true, value: "PRIMARYTOPICCHANNEL" };
                    case "CHANNELLIST":
                      return { success: true, value: "CHANNELLIST" };
                    case "ANNOTATIONLIST":
                      return { success: true, value: "ANNOTATIONLIST" };
                    case "LGXPLIST":
                      return { success: true, value: "LGXPLIST" };
                    case "SKETCHNOTESLGXP":
                      return { success: true, value: "SKETCHNOTESLGXP" };
                    case "ACTIONABLELIST":
                      return { success: true, value: "ACTIONABLELIST" };
                    case "ACTIONABLELISTCUSTOM":
                      return { success: true, value: "ACTIONABLELISTCUSTOM" };
                    case "MYACTIONABLES":
                      return { success: true, value: "MYACTIONABLES" };
                    case "FREESUMMARY":
                      return { success: true, value: "FREESUMMARY" };
                    case "SUMMARYLISTCUSTOM":
                      return { success: true, value: "SUMMARYLISTCUSTOM" };
                    case "CUSTOMPAGELIST":
                      return { success: true, value: "CUSTOMPAGELIST" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["type"]);
            })();
            if (!parsed_type.success) {
              return { success: false };
            }
            return {
              success: true,
              value: {
                custom: parsed_custom.value,
                defaultFilterAllLanguages: parsed_defaultFilterAllLanguages.value,
                embedSortingFiltering: parsed_embedSortingFiltering.value,
                hideTitle: parsed_hideTitle.value,
                itemCount: parsed_itemCount.value,
                itemLayout: parsed_itemLayout.value,
                name: parsed_name.value,
                portletId: parsed_portletId.value,
                portletLayout: parsed_portletLayout.value,
                subType: parsed_subType.value,
                title: parsed_title.value,
                type: parsed_type.value,
              },
            };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["portlets"]);
    })();
    if (!parsed_portlets.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        active: parsed_active.value,
        corporateId: parsed_corporateId.value,
        coverUrl: parsed_coverUrl.value,
        customPageId: parsed_customPageId.value,
        customPageLocalization: parsed_customPageLocalization.value,
        headerLayout: parsed_headerLayout.value,
        portlets: parsed_portlets.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// CustomPageFilters
export function serialize_CustomPageFilters(obj: CustomPageFilters): string {
  const serialized = ((value: { includeInactive: boolean; onlyHighlighted: boolean }): unknown => {
    return {
      includeInactive: ((value: boolean): unknown => {
        return value;
      })(value["includeInactive"]),
      onlyHighlighted: ((value: boolean): unknown => {
        return value;
      })(value["onlyHighlighted"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_CustomPageFilters(str: string): CustomPageFilters | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          includeInactive: boolean;
          onlyHighlighted: boolean;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "includeInactive")) {
      return { success: false };
    }
    const parsed_includeInactive = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["includeInactive"]);
    })();
    if (!parsed_includeInactive.success) {
      return { success: false };
    }
    if (!hasProp(value, "onlyHighlighted")) {
      return { success: false };
    }
    const parsed_onlyHighlighted = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["onlyHighlighted"]);
    })();
    if (!parsed_onlyHighlighted.success) {
      return { success: false };
    }
    return { success: true, value: { includeInactive: parsed_includeInactive.value, onlyHighlighted: parsed_onlyHighlighted.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// CustomPageHeaderLayout
export function serialize_CustomPageHeaderLayout(obj: CustomPageHeaderLayout): string {
  const serialized = ((value: "NONE" | "DEFAULT"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_CustomPageHeaderLayout(str: string): CustomPageHeaderLayout | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "NONE" | "DEFAULT" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "NONE":
        return { success: true, value: "NONE" };
      case "DEFAULT":
        return { success: true, value: "DEFAULT" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// CustomPageHighlightsForm
export function serialize_CustomPageHighlightsForm(obj: CustomPageHighlightsForm): string {
  const serialized = ((value: { customPageIds: bigint[] }): unknown => {
    return {
      customPageIds: ((value: bigint[]): unknown => {
        return value.map((value: bigint): unknown => {
          return value;
        });
      })(value["customPageIds"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_CustomPageHighlightsForm(str: string): CustomPageHighlightsForm | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          customPageIds: bigint[];
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "customPageIds")) {
      return { success: false };
    }
    const parsed_customPageIds = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: bigint } => {
          if (typeof value === "number") {
            return { success: true, value: BigInt(value) };
          }
          if (typeof value === "bigint") {
            return { success: true, value: value };
          }
          return { success: false };
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["customPageIds"]);
    })();
    if (!parsed_customPageIds.success) {
      return { success: false };
    }
    return { success: true, value: { customPageIds: parsed_customPageIds.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// CustomPageListView
export function serialize_CustomPageListView(obj: CustomPageListView): string {
  const serialized = ((value: { corporateId: number; coverUri: string; customPageId: bigint; description: string | null; title: string }): unknown => {
    return {
      corporateId: ((value: number): unknown => {
        return value;
      })(value["corporateId"]),
      coverUri: ((value: string): unknown => {
        return value;
      })(value["coverUri"]),
      customPageId: ((value: bigint): unknown => {
        return value;
      })(value["customPageId"]),
      description: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["description"]),
      title: ((value: string): unknown => {
        return value;
      })(value["title"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_CustomPageListView(str: string): CustomPageListView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          corporateId: number;
          coverUri: string;
          customPageId: bigint;
          description: string | null;
          title: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "corporateId")) {
      return { success: false };
    }
    const parsed_corporateId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["corporateId"]);
    })();
    if (!parsed_corporateId.success) {
      return { success: false };
    }
    if (!hasProp(value, "coverUri")) {
      return { success: false };
    }
    const parsed_coverUri = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["coverUri"]);
    })();
    if (!parsed_coverUri.success) {
      return { success: false };
    }
    if (!hasProp(value, "customPageId")) {
      return { success: false };
    }
    const parsed_customPageId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["customPageId"]);
    })();
    if (!parsed_customPageId.success) {
      return { success: false };
    }
    if (!hasProp(value, "description")) {
      return { success: false };
    }
    const parsed_description = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["description"]);
    })();
    if (!parsed_description.success) {
      return { success: false };
    }
    if (!hasProp(value, "title")) {
      return { success: false };
    }
    const parsed_title = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["title"]);
    })();
    if (!parsed_title.success) {
      return { success: false };
    }
    return { success: true, value: { corporateId: parsed_corporateId.value, coverUri: parsed_coverUri.value, customPageId: parsed_customPageId.value, description: parsed_description.value, title: parsed_title.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// CustomPageLocalizationEditForm
export function serialize_CustomPageLocalizationEditForm(obj: CustomPageLocalizationEditForm): string {
  const serialized = ((value: { description: string | null; title: string }): unknown => {
    return {
      description: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["description"]),
      title: ((value: string): unknown => {
        return value;
      })(value["title"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_CustomPageLocalizationEditForm(str: string): CustomPageLocalizationEditForm | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          description: string | null;
          title: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "description")) {
      return { success: false };
    }
    const parsed_description = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["description"]);
    })();
    if (!parsed_description.success) {
      return { success: false };
    }
    if (!hasProp(value, "title")) {
      return { success: false };
    }
    const parsed_title = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["title"]);
    })();
    if (!parsed_title.success) {
      return { success: false };
    }
    return { success: true, value: { description: parsed_description.value, title: parsed_title.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// CustomPageLocalizationEditView
export function serialize_CustomPageLocalizationEditView(obj: CustomPageLocalizationEditView): string {
  const serialized = ((value: { description: string | null; title: string }): unknown => {
    return {
      description: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["description"]),
      title: ((value: string): unknown => {
        return value;
      })(value["title"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_CustomPageLocalizationEditView(str: string): CustomPageLocalizationEditView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          description: string | null;
          title: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "description")) {
      return { success: false };
    }
    const parsed_description = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["description"]);
    })();
    if (!parsed_description.success) {
      return { success: false };
    }
    if (!hasProp(value, "title")) {
      return { success: false };
    }
    const parsed_title = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["title"]);
    })();
    if (!parsed_title.success) {
      return { success: false };
    }
    return { success: true, value: { description: parsed_description.value, title: parsed_title.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// CustomPageView
export function serialize_CustomPageView(obj: CustomPageView): string {
  const serialized = ((value: { coverUrl: string; customPageId: bigint; description: string | null; headerLayout: CustomPageHeaderLayout; portlets: PortletMetadata[]; title: string }): unknown => {
    return {
      coverUrl: ((value: string): unknown => {
        return value;
      })(value["coverUrl"]),
      customPageId: ((value: bigint): unknown => {
        return value;
      })(value["customPageId"]),
      description: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["description"]),
      headerLayout: ((value: "NONE" | "DEFAULT"): unknown => {
        return value;
      })(value["headerLayout"]),
      portlets: ((value: PortletMetadata[]): unknown => {
        return value.map(
          (value: {
            custom: boolean;
            defaultFilterAllLanguages: boolean;
            embedSortingFiltering: boolean;
            hideTitle: boolean;
            itemCount: number;
            itemLayout: ItemLayout;
            name: string;
            portletId: number;
            portletLayout: PortletLayout;
            subType: string | null;
            title: string;
            type: PortletType;
          }): unknown => {
            return {
              custom: ((value: boolean): unknown => {
                return value;
              })(value["custom"]),
              defaultFilterAllLanguages: ((value: boolean): unknown => {
                return value;
              })(value["defaultFilterAllLanguages"]),
              embedSortingFiltering: ((value: boolean): unknown => {
                return value;
              })(value["embedSortingFiltering"]),
              hideTitle: ((value: boolean): unknown => {
                return value;
              })(value["hideTitle"]),
              itemCount: ((value: number): unknown => {
                return value;
              })(value["itemCount"]),
              itemLayout: ((value: "MINI" | "REGULAR"): unknown => {
                return value;
              })(value["itemLayout"]),
              name: ((value: string): unknown => {
                return value;
              })(value["name"]),
              portletId: ((value: number): unknown => {
                return value;
              })(value["portletId"]),
              portletLayout: ((value: "GRID" | "CAROUSEL"): unknown => {
                return value;
              })(value["portletLayout"]),
              subType: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["subType"]),
              title: ((value: string): unknown => {
                return value;
              })(value["title"]),
              type: ((
                value:
                  | "IMAGETEXT"
                  | "SUMMARYLIST"
                  | "TRENDINGCHANNEL"
                  | "FEATUREDCHANNEL"
                  | "FOLLOWINGCHANNEL"
                  | "MYLIST"
                  | "PRIMARYTOPICCHANNEL"
                  | "CHANNELLIST"
                  | "ANNOTATIONLIST"
                  | "LGXPLIST"
                  | "SKETCHNOTESLGXP"
                  | "ACTIONABLELIST"
                  | "ACTIONABLELISTCUSTOM"
                  | "MYACTIONABLES"
                  | "FREESUMMARY"
                  | "SUMMARYLISTCUSTOM"
                  | "CUSTOMPAGELIST",
              ): unknown => {
                return value;
              })(value["type"]),
            };
          },
        );
      })(value["portlets"]),
      title: ((value: string): unknown => {
        return value;
      })(value["title"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_CustomPageView(str: string): CustomPageView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          coverUrl: string;
          customPageId: bigint;
          description: string | null;
          headerLayout: CustomPageHeaderLayout;
          portlets: PortletMetadata[];
          title: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "coverUrl")) {
      return { success: false };
    }
    const parsed_coverUrl = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["coverUrl"]);
    })();
    if (!parsed_coverUrl.success) {
      return { success: false };
    }
    if (!hasProp(value, "customPageId")) {
      return { success: false };
    }
    const parsed_customPageId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["customPageId"]);
    })();
    if (!parsed_customPageId.success) {
      return { success: false };
    }
    if (!hasProp(value, "description")) {
      return { success: false };
    }
    const parsed_description = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["description"]);
    })();
    if (!parsed_description.success) {
      return { success: false };
    }
    if (!hasProp(value, "headerLayout")) {
      return { success: false };
    }
    const parsed_headerLayout = (() => {
      return ((value: unknown): { success: false } | { success: true; value: CustomPageHeaderLayout } => {
        return ((value: unknown): { success: false } | { success: true; value: "NONE" | "DEFAULT" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "NONE":
              return { success: true, value: "NONE" };
            case "DEFAULT":
              return { success: true, value: "DEFAULT" };
            default:
              return { success: false };
          }
        })(value);
      })(value["headerLayout"]);
    })();
    if (!parsed_headerLayout.success) {
      return { success: false };
    }
    if (!hasProp(value, "portlets")) {
      return { success: false };
    }
    const parsed_portlets = (() => {
      return ((value: unknown): { success: false } | { success: true; value: PortletMetadata[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: PortletMetadata } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  custom: boolean;
                  defaultFilterAllLanguages: boolean;
                  embedSortingFiltering: boolean;
                  hideTitle: boolean;
                  itemCount: number;
                  itemLayout: ItemLayout;
                  name: string;
                  portletId: number;
                  portletLayout: PortletLayout;
                  subType: string | null;
                  title: string;
                  type: PortletType;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "custom")) {
              return { success: false };
            }
            const parsed_custom = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["custom"]);
            })();
            if (!parsed_custom.success) {
              return { success: false };
            }
            if (!hasProp(value, "defaultFilterAllLanguages")) {
              return { success: false };
            }
            const parsed_defaultFilterAllLanguages = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["defaultFilterAllLanguages"]);
            })();
            if (!parsed_defaultFilterAllLanguages.success) {
              return { success: false };
            }
            if (!hasProp(value, "embedSortingFiltering")) {
              return { success: false };
            }
            const parsed_embedSortingFiltering = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["embedSortingFiltering"]);
            })();
            if (!parsed_embedSortingFiltering.success) {
              return { success: false };
            }
            if (!hasProp(value, "hideTitle")) {
              return { success: false };
            }
            const parsed_hideTitle = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["hideTitle"]);
            })();
            if (!parsed_hideTitle.success) {
              return { success: false };
            }
            if (!hasProp(value, "itemCount")) {
              return { success: false };
            }
            const parsed_itemCount = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["itemCount"]);
            })();
            if (!parsed_itemCount.success) {
              return { success: false };
            }
            if (!hasProp(value, "itemLayout")) {
              return { success: false };
            }
            const parsed_itemLayout = (() => {
              return ((value: unknown): { success: false } | { success: true; value: ItemLayout } => {
                return ((value: unknown): { success: false } | { success: true; value: "MINI" | "REGULAR" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "MINI":
                      return { success: true, value: "MINI" };
                    case "REGULAR":
                      return { success: true, value: "REGULAR" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["itemLayout"]);
            })();
            if (!parsed_itemLayout.success) {
              return { success: false };
            }
            if (!hasProp(value, "name")) {
              return { success: false };
            }
            const parsed_name = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["name"]);
            })();
            if (!parsed_name.success) {
              return { success: false };
            }
            if (!hasProp(value, "portletId")) {
              return { success: false };
            }
            const parsed_portletId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["portletId"]);
            })();
            if (!parsed_portletId.success) {
              return { success: false };
            }
            if (!hasProp(value, "portletLayout")) {
              return { success: false };
            }
            const parsed_portletLayout = (() => {
              return ((value: unknown): { success: false } | { success: true; value: PortletLayout } => {
                return ((value: unknown): { success: false } | { success: true; value: "GRID" | "CAROUSEL" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "GRID":
                      return { success: true, value: "GRID" };
                    case "CAROUSEL":
                      return { success: true, value: "CAROUSEL" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["portletLayout"]);
            })();
            if (!parsed_portletLayout.success) {
              return { success: false };
            }
            if (!hasProp(value, "subType")) {
              return { success: false };
            }
            const parsed_subType = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["subType"]);
            })();
            if (!parsed_subType.success) {
              return { success: false };
            }
            if (!hasProp(value, "title")) {
              return { success: false };
            }
            const parsed_title = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["title"]);
            })();
            if (!parsed_title.success) {
              return { success: false };
            }
            if (!hasProp(value, "type")) {
              return { success: false };
            }
            const parsed_type = (() => {
              return ((value: unknown): { success: false } | { success: true; value: PortletType } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value:
                        | "IMAGETEXT"
                        | "SUMMARYLIST"
                        | "TRENDINGCHANNEL"
                        | "FEATUREDCHANNEL"
                        | "FOLLOWINGCHANNEL"
                        | "MYLIST"
                        | "PRIMARYTOPICCHANNEL"
                        | "CHANNELLIST"
                        | "ANNOTATIONLIST"
                        | "LGXPLIST"
                        | "SKETCHNOTESLGXP"
                        | "ACTIONABLELIST"
                        | "ACTIONABLELISTCUSTOM"
                        | "MYACTIONABLES"
                        | "FREESUMMARY"
                        | "SUMMARYLISTCUSTOM"
                        | "CUSTOMPAGELIST";
                    } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "IMAGETEXT":
                      return { success: true, value: "IMAGETEXT" };
                    case "SUMMARYLIST":
                      return { success: true, value: "SUMMARYLIST" };
                    case "TRENDINGCHANNEL":
                      return { success: true, value: "TRENDINGCHANNEL" };
                    case "FEATUREDCHANNEL":
                      return { success: true, value: "FEATUREDCHANNEL" };
                    case "FOLLOWINGCHANNEL":
                      return { success: true, value: "FOLLOWINGCHANNEL" };
                    case "MYLIST":
                      return { success: true, value: "MYLIST" };
                    case "PRIMARYTOPICCHANNEL":
                      return { success: true, value: "PRIMARYTOPICCHANNEL" };
                    case "CHANNELLIST":
                      return { success: true, value: "CHANNELLIST" };
                    case "ANNOTATIONLIST":
                      return { success: true, value: "ANNOTATIONLIST" };
                    case "LGXPLIST":
                      return { success: true, value: "LGXPLIST" };
                    case "SKETCHNOTESLGXP":
                      return { success: true, value: "SKETCHNOTESLGXP" };
                    case "ACTIONABLELIST":
                      return { success: true, value: "ACTIONABLELIST" };
                    case "ACTIONABLELISTCUSTOM":
                      return { success: true, value: "ACTIONABLELISTCUSTOM" };
                    case "MYACTIONABLES":
                      return { success: true, value: "MYACTIONABLES" };
                    case "FREESUMMARY":
                      return { success: true, value: "FREESUMMARY" };
                    case "SUMMARYLISTCUSTOM":
                      return { success: true, value: "SUMMARYLISTCUSTOM" };
                    case "CUSTOMPAGELIST":
                      return { success: true, value: "CUSTOMPAGELIST" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["type"]);
            })();
            if (!parsed_type.success) {
              return { success: false };
            }
            return {
              success: true,
              value: {
                custom: parsed_custom.value,
                defaultFilterAllLanguages: parsed_defaultFilterAllLanguages.value,
                embedSortingFiltering: parsed_embedSortingFiltering.value,
                hideTitle: parsed_hideTitle.value,
                itemCount: parsed_itemCount.value,
                itemLayout: parsed_itemLayout.value,
                name: parsed_name.value,
                portletId: parsed_portletId.value,
                portletLayout: parsed_portletLayout.value,
                subType: parsed_subType.value,
                title: parsed_title.value,
                type: parsed_type.value,
              },
            };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["portlets"]);
    })();
    if (!parsed_portlets.success) {
      return { success: false };
    }
    if (!hasProp(value, "title")) {
      return { success: false };
    }
    const parsed_title = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["title"]);
    })();
    if (!parsed_title.success) {
      return { success: false };
    }
    return {
      success: true,
      value: { coverUrl: parsed_coverUrl.value, customPageId: parsed_customPageId.value, description: parsed_description.value, headerLayout: parsed_headerLayout.value, portlets: parsed_portlets.value, title: parsed_title.value },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// CustomerAdminDetailWidgetProps
export function serialize_CustomerAdminDetailWidgetProps(obj: CustomerAdminDetailWidgetProps): string {
  const serialized = ((value: { customerId: number }): unknown => {
    return {
      customerId: ((value: number): unknown => {
        return value;
      })(value["customerId"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_CustomerAdminDetailWidgetProps(str: string): CustomerAdminDetailWidgetProps | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          customerId: number;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "customerId")) {
      return { success: false };
    }
    const parsed_customerId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["customerId"]);
    })();
    if (!parsed_customerId.success) {
      return { success: false };
    }
    return { success: true, value: { customerId: parsed_customerId.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// DateRange
export function serialize_DateRange(obj: DateRange): string {
  const serialized = ((value: { from: { year: number; month: number; day: number }; to: { year: number; month: number; day: number } }): unknown => {
    return {
      from: ((value: { year: number; month: number; day: number }): unknown => {
        return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
      })(value["from"]),
      to: ((value: { year: number; month: number; day: number }): unknown => {
        return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
      })(value["to"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_DateRange(str: string): DateRange | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          from: { year: number; month: number; day: number };
          to: { year: number; month: number; day: number };
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "from")) {
      return { success: false };
    }
    const parsed_from = (() => {
      return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
      })(value["from"]);
    })();
    if (!parsed_from.success) {
      return { success: false };
    }
    if (!hasProp(value, "to")) {
      return { success: false };
    }
    const parsed_to = (() => {
      return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
      })(value["to"]);
    })();
    if (!parsed_to.success) {
      return { success: false };
    }
    return { success: true, value: { from: parsed_from.value, to: parsed_to.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// FilteredLearningItemAdminView
export function serialize_FilteredLearningItemAdminView(obj: FilteredLearningItemAdminView): string {
  const serialized = ((value: { items: LearningItemAdminView[]; totalCount: number }): unknown => {
    return {
      items: ((value: LearningItemAdminView[]): unknown => {
        return value.map(
          (
            value:
              | { learningItemType: "ACTIONABLE"; description: string; metadata: LearningItemAdminMetadataView }
              | { learningItemType: "SUMMARY"; audioActive: boolean; documentType: SummaryDocumentType; metadata: LearningItemAdminMetadataView; publisher: string | null; sourceType: SummarySourceType },
          ): unknown => {
            switch (value.learningItemType) {
              case "ACTIONABLE": {
                return {
                  learningItemType: "ACTIONABLE",
                  description: ((value: string): unknown => {
                    return value;
                  })(value["description"]),
                  metadata: ((value: {
                    activatedAt: { year: number; month: number; day: number } | null;
                    active: boolean;
                    createdAt: { year: number; month: number; day: number };
                    deactivatedAt: { year: number; month: number; day: number } | null;
                    id: bigint;
                    language: Language;
                    manager: string | null;
                    tags: Tag[];
                    title: string;
                  }): unknown => {
                    return {
                      activatedAt: ((value: { year: number; month: number; day: number } | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
                      })(value["activatedAt"]),
                      active: ((value: boolean): unknown => {
                        return value;
                      })(value["active"]),
                      createdAt: ((value: { year: number; month: number; day: number }): unknown => {
                        return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
                      })(value["createdAt"]),
                      deactivatedAt: ((value: { year: number; month: number; day: number } | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
                      })(value["deactivatedAt"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                        return value;
                      })(value["language"]),
                      manager: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["manager"]),
                      tags: ((value: Tag[]): unknown => {
                        return value.map(
                          (
                            value:
                              | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                              | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                              | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                              | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                              | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                              | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                              | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string },
                          ): unknown => {
                            switch (value.discriminator) {
                              case "dynamicString": {
                                return {
                                  discriminator: "dynamicString",
                                  exclude: ((value: boolean): unknown => {
                                    return value;
                                  })(value["exclude"]),
                                  source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                                    return value;
                                  })(value["source"]),
                                  tagKind: ((value: { discriminator: KindDynamicString; id: bigint; kind: string }): unknown => {
                                    return {
                                      discriminator: ((value: "dynamicString"): unknown => {
                                        return value;
                                      })(value["discriminator"]),
                                      id: ((value: bigint): unknown => {
                                        return value;
                                      })(value["id"]),
                                      kind: ((value: string): unknown => {
                                        return value;
                                      })(value["kind"]),
                                    };
                                  })(value["tagKind"]),
                                  value: ((value: string): unknown => {
                                    return value;
                                  })(value["value"]),
                                };
                              }
                              case "dynamicBoolean": {
                                return {
                                  discriminator: "dynamicBoolean",
                                  exclude: ((value: boolean): unknown => {
                                    return value;
                                  })(value["exclude"]),
                                  source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                                    return value;
                                  })(value["source"]),
                                  tagKind: ((value: { discriminator: KindDynamicBoolean; id: bigint; kind: string }): unknown => {
                                    return {
                                      discriminator: ((value: "dynamicBoolean"): unknown => {
                                        return value;
                                      })(value["discriminator"]),
                                      id: ((value: bigint): unknown => {
                                        return value;
                                      })(value["id"]),
                                      kind: ((value: string): unknown => {
                                        return value;
                                      })(value["kind"]),
                                    };
                                  })(value["tagKind"]),
                                  value: ((value: boolean): unknown => {
                                    return value;
                                  })(value["value"]),
                                };
                              }
                              case "dynamicNumber": {
                                return {
                                  discriminator: "dynamicNumber",
                                  exclude: ((value: boolean): unknown => {
                                    return value;
                                  })(value["exclude"]),
                                  source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                                    return value;
                                  })(value["source"]),
                                  tagKind: ((value: { discriminator: KindDynamicNumber; id: bigint; kind: string }): unknown => {
                                    return {
                                      discriminator: ((value: "dynamicNumber"): unknown => {
                                        return value;
                                      })(value["discriminator"]),
                                      id: ((value: bigint): unknown => {
                                        return value;
                                      })(value["id"]),
                                      kind: ((value: string): unknown => {
                                        return value;
                                      })(value["kind"]),
                                    };
                                  })(value["tagKind"]),
                                  value: ((value: number): unknown => {
                                    return value;
                                  })(value["value"]),
                                };
                              }
                              case "dynamicVoid": {
                                return {
                                  discriminator: "dynamicVoid",
                                  exclude: ((value: boolean): unknown => {
                                    return value;
                                  })(value["exclude"]),
                                  source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                                    return value;
                                  })(value["source"]),
                                  tagKind: ((value: { discriminator: KindDynamicVoid; id: bigint; kind: string }): unknown => {
                                    return {
                                      discriminator: ((value: "dynamicVoid"): unknown => {
                                        return value;
                                      })(value["discriminator"]),
                                      id: ((value: bigint): unknown => {
                                        return value;
                                      })(value["id"]),
                                      kind: ((value: string): unknown => {
                                        return value;
                                      })(value["kind"]),
                                    };
                                  })(value["tagKind"]),
                                };
                              }
                              case "channel": {
                                return {
                                  discriminator: "channel",
                                  exclude: ((value: boolean): unknown => {
                                    return value;
                                  })(value["exclude"]),
                                  source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                                    return value;
                                  })(value["source"]),
                                  tagKind: ((value: { discriminator: KindChannel; id: bigint; kind: KindChannel }): unknown => {
                                    return {
                                      discriminator: ((value: "channel"): unknown => {
                                        return value;
                                      })(value["discriminator"]),
                                      id: ((value: bigint): unknown => {
                                        return value;
                                      })(value["id"]),
                                      kind: ((value: "channel"): unknown => {
                                        return value;
                                      })(value["kind"]),
                                    };
                                  })(value["tagKind"]),
                                  value: ((value: string): unknown => {
                                    return value;
                                  })(value["value"]),
                                };
                              }
                              case "key-quality": {
                                return {
                                  discriminator: "key-quality",
                                  exclude: ((value: boolean): unknown => {
                                    return value;
                                  })(value["exclude"]),
                                  source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                                    return value;
                                  })(value["source"]),
                                  tagKind: ((value: { discriminator: KindKeyQuality; id: bigint; kind: KindKeyQuality }): unknown => {
                                    return {
                                      discriminator: ((value: "key-quality"): unknown => {
                                        return value;
                                      })(value["discriminator"]),
                                      id: ((value: bigint): unknown => {
                                        return value;
                                      })(value["id"]),
                                      kind: ((value: "key-quality"): unknown => {
                                        return value;
                                      })(value["kind"]),
                                    };
                                  })(value["tagKind"]),
                                  value: ((value: string): unknown => {
                                    return value;
                                  })(value["value"]),
                                };
                              }
                              case "classification": {
                                return {
                                  discriminator: "classification",
                                  exclude: ((value: boolean): unknown => {
                                    return value;
                                  })(value["exclude"]),
                                  source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                                    return value;
                                  })(value["source"]),
                                  tagKind: ((value: { discriminator: KindClassification; id: bigint; kind: KindClassification }): unknown => {
                                    return {
                                      discriminator: ((value: "classification"): unknown => {
                                        return value;
                                      })(value["discriminator"]),
                                      id: ((value: bigint): unknown => {
                                        return value;
                                      })(value["id"]),
                                      kind: ((value: "classification"): unknown => {
                                        return value;
                                      })(value["kind"]),
                                    };
                                  })(value["tagKind"]),
                                  value: ((value: string): unknown => {
                                    return value;
                                  })(value["value"]),
                                };
                              }
                            }
                          },
                        );
                      })(value["tags"]),
                      title: ((value: string): unknown => {
                        return value;
                      })(value["title"]),
                    };
                  })(value["metadata"]),
                };
              }
              case "SUMMARY": {
                return {
                  learningItemType: "SUMMARY",
                  audioActive: ((value: boolean): unknown => {
                    return value;
                  })(value["audioActive"]),
                  documentType: ((value: "ABSTRACT" | "REVIEW"): unknown => {
                    return value;
                  })(value["documentType"]),
                  metadata: ((value: {
                    activatedAt: { year: number; month: number; day: number } | null;
                    active: boolean;
                    createdAt: { year: number; month: number; day: number };
                    deactivatedAt: { year: number; month: number; day: number } | null;
                    id: bigint;
                    language: Language;
                    manager: string | null;
                    tags: Tag[];
                    title: string;
                  }): unknown => {
                    return {
                      activatedAt: ((value: { year: number; month: number; day: number } | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
                      })(value["activatedAt"]),
                      active: ((value: boolean): unknown => {
                        return value;
                      })(value["active"]),
                      createdAt: ((value: { year: number; month: number; day: number }): unknown => {
                        return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
                      })(value["createdAt"]),
                      deactivatedAt: ((value: { year: number; month: number; day: number } | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
                      })(value["deactivatedAt"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                        return value;
                      })(value["language"]),
                      manager: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["manager"]),
                      tags: ((value: Tag[]): unknown => {
                        return value.map(
                          (
                            value:
                              | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                              | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                              | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                              | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                              | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                              | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                              | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string },
                          ): unknown => {
                            switch (value.discriminator) {
                              case "dynamicString": {
                                return {
                                  discriminator: "dynamicString",
                                  exclude: ((value: boolean): unknown => {
                                    return value;
                                  })(value["exclude"]),
                                  source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                                    return value;
                                  })(value["source"]),
                                  tagKind: ((value: { discriminator: KindDynamicString; id: bigint; kind: string }): unknown => {
                                    return {
                                      discriminator: ((value: "dynamicString"): unknown => {
                                        return value;
                                      })(value["discriminator"]),
                                      id: ((value: bigint): unknown => {
                                        return value;
                                      })(value["id"]),
                                      kind: ((value: string): unknown => {
                                        return value;
                                      })(value["kind"]),
                                    };
                                  })(value["tagKind"]),
                                  value: ((value: string): unknown => {
                                    return value;
                                  })(value["value"]),
                                };
                              }
                              case "dynamicBoolean": {
                                return {
                                  discriminator: "dynamicBoolean",
                                  exclude: ((value: boolean): unknown => {
                                    return value;
                                  })(value["exclude"]),
                                  source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                                    return value;
                                  })(value["source"]),
                                  tagKind: ((value: { discriminator: KindDynamicBoolean; id: bigint; kind: string }): unknown => {
                                    return {
                                      discriminator: ((value: "dynamicBoolean"): unknown => {
                                        return value;
                                      })(value["discriminator"]),
                                      id: ((value: bigint): unknown => {
                                        return value;
                                      })(value["id"]),
                                      kind: ((value: string): unknown => {
                                        return value;
                                      })(value["kind"]),
                                    };
                                  })(value["tagKind"]),
                                  value: ((value: boolean): unknown => {
                                    return value;
                                  })(value["value"]),
                                };
                              }
                              case "dynamicNumber": {
                                return {
                                  discriminator: "dynamicNumber",
                                  exclude: ((value: boolean): unknown => {
                                    return value;
                                  })(value["exclude"]),
                                  source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                                    return value;
                                  })(value["source"]),
                                  tagKind: ((value: { discriminator: KindDynamicNumber; id: bigint; kind: string }): unknown => {
                                    return {
                                      discriminator: ((value: "dynamicNumber"): unknown => {
                                        return value;
                                      })(value["discriminator"]),
                                      id: ((value: bigint): unknown => {
                                        return value;
                                      })(value["id"]),
                                      kind: ((value: string): unknown => {
                                        return value;
                                      })(value["kind"]),
                                    };
                                  })(value["tagKind"]),
                                  value: ((value: number): unknown => {
                                    return value;
                                  })(value["value"]),
                                };
                              }
                              case "dynamicVoid": {
                                return {
                                  discriminator: "dynamicVoid",
                                  exclude: ((value: boolean): unknown => {
                                    return value;
                                  })(value["exclude"]),
                                  source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                                    return value;
                                  })(value["source"]),
                                  tagKind: ((value: { discriminator: KindDynamicVoid; id: bigint; kind: string }): unknown => {
                                    return {
                                      discriminator: ((value: "dynamicVoid"): unknown => {
                                        return value;
                                      })(value["discriminator"]),
                                      id: ((value: bigint): unknown => {
                                        return value;
                                      })(value["id"]),
                                      kind: ((value: string): unknown => {
                                        return value;
                                      })(value["kind"]),
                                    };
                                  })(value["tagKind"]),
                                };
                              }
                              case "channel": {
                                return {
                                  discriminator: "channel",
                                  exclude: ((value: boolean): unknown => {
                                    return value;
                                  })(value["exclude"]),
                                  source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                                    return value;
                                  })(value["source"]),
                                  tagKind: ((value: { discriminator: KindChannel; id: bigint; kind: KindChannel }): unknown => {
                                    return {
                                      discriminator: ((value: "channel"): unknown => {
                                        return value;
                                      })(value["discriminator"]),
                                      id: ((value: bigint): unknown => {
                                        return value;
                                      })(value["id"]),
                                      kind: ((value: "channel"): unknown => {
                                        return value;
                                      })(value["kind"]),
                                    };
                                  })(value["tagKind"]),
                                  value: ((value: string): unknown => {
                                    return value;
                                  })(value["value"]),
                                };
                              }
                              case "key-quality": {
                                return {
                                  discriminator: "key-quality",
                                  exclude: ((value: boolean): unknown => {
                                    return value;
                                  })(value["exclude"]),
                                  source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                                    return value;
                                  })(value["source"]),
                                  tagKind: ((value: { discriminator: KindKeyQuality; id: bigint; kind: KindKeyQuality }): unknown => {
                                    return {
                                      discriminator: ((value: "key-quality"): unknown => {
                                        return value;
                                      })(value["discriminator"]),
                                      id: ((value: bigint): unknown => {
                                        return value;
                                      })(value["id"]),
                                      kind: ((value: "key-quality"): unknown => {
                                        return value;
                                      })(value["kind"]),
                                    };
                                  })(value["tagKind"]),
                                  value: ((value: string): unknown => {
                                    return value;
                                  })(value["value"]),
                                };
                              }
                              case "classification": {
                                return {
                                  discriminator: "classification",
                                  exclude: ((value: boolean): unknown => {
                                    return value;
                                  })(value["exclude"]),
                                  source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                                    return value;
                                  })(value["source"]),
                                  tagKind: ((value: { discriminator: KindClassification; id: bigint; kind: KindClassification }): unknown => {
                                    return {
                                      discriminator: ((value: "classification"): unknown => {
                                        return value;
                                      })(value["discriminator"]),
                                      id: ((value: bigint): unknown => {
                                        return value;
                                      })(value["id"]),
                                      kind: ((value: "classification"): unknown => {
                                        return value;
                                      })(value["kind"]),
                                    };
                                  })(value["tagKind"]),
                                  value: ((value: string): unknown => {
                                    return value;
                                  })(value["value"]),
                                };
                              }
                            }
                          },
                        );
                      })(value["tags"]),
                      title: ((value: string): unknown => {
                        return value;
                      })(value["title"]),
                    };
                  })(value["metadata"]),
                  publisher: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["publisher"]),
                  sourceType: ((value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST"): unknown => {
                    return value;
                  })(value["sourceType"]),
                };
              }
            }
          },
        );
      })(value["items"]),
      totalCount: ((value: number): unknown => {
        return value;
      })(value["totalCount"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_FilteredLearningItemAdminView(str: string): FilteredLearningItemAdminView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          items: LearningItemAdminView[];
          totalCount: number;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "items")) {
      return { success: false };
    }
    const parsed_items = (() => {
      return ((value: unknown): { success: false } | { success: true; value: LearningItemAdminView[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: LearningItemAdminView } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value:
                  | { learningItemType: "ACTIONABLE"; description: string; metadata: LearningItemAdminMetadataView }
                  | { learningItemType: "SUMMARY"; audioActive: boolean; documentType: SummaryDocumentType; metadata: LearningItemAdminMetadataView; publisher: string | null; sourceType: SummarySourceType };
              } => {
            return ((
              value: unknown,
            ):
              | { success: false }
              | {
                  success: true;
                  value:
                    | { learningItemType: "ACTIONABLE"; description: string; metadata: LearningItemAdminMetadataView }
                    | { learningItemType: "SUMMARY"; audioActive: boolean; documentType: SummaryDocumentType; metadata: LearningItemAdminMetadataView; publisher: string | null; sourceType: SummarySourceType };
                } => {
              if (value == null) {
                return { success: false };
              }
              if (typeof value != "object") {
                return { success: false };
              }
              if (!hasProp(value, "learningItemType")) {
                return { success: false };
              }
              if (typeof value["learningItemType"] != "string") {
                return { success: false };
              }
              const discriminator = value["learningItemType"];
              switch (discriminator) {
                case "ACTIONABLE": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          description: string;
                          metadata: LearningItemAdminMetadataView;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "description")) {
                      return { success: false };
                    }
                    const parsed_description = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["description"]);
                    })();
                    if (!parsed_description.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "metadata")) {
                      return { success: false };
                    }
                    const parsed_metadata = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: LearningItemAdminMetadataView } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                activatedAt: { year: number; month: number; day: number } | null;
                                active: boolean;
                                createdAt: { year: number; month: number; day: number };
                                deactivatedAt: { year: number; month: number; day: number } | null;
                                id: bigint;
                                language: Language;
                                manager: string | null;
                                tags: Tag[];
                                title: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "activatedAt")) {
                            return { success: false };
                          }
                          const parsed_activatedAt = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
                              if (!parsed.isValid) {
                                return { success: false };
                              }
                              return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
                            })(value["activatedAt"]);
                          })();
                          if (!parsed_activatedAt.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "active")) {
                            return { success: false };
                          }
                          const parsed_active = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["active"]);
                          })();
                          if (!parsed_active.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "createdAt")) {
                            return { success: false };
                          }
                          const parsed_createdAt = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
                              if (!parsed.isValid) {
                                return { success: false };
                              }
                              return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
                            })(value["createdAt"]);
                          })();
                          if (!parsed_createdAt.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "deactivatedAt")) {
                            return { success: false };
                          }
                          const parsed_deactivatedAt = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
                              if (!parsed.isValid) {
                                return { success: false };
                              }
                              return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
                            })(value["deactivatedAt"]);
                          })();
                          if (!parsed_deactivatedAt.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "language")) {
                            return { success: false };
                          }
                          const parsed_language = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: Language } => {
                              return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "en":
                                    return { success: true, value: "en" };
                                  case "de":
                                    return { success: true, value: "de" };
                                  case "es":
                                    return { success: true, value: "es" };
                                  case "ru":
                                    return { success: true, value: "ru" };
                                  case "zh":
                                    return { success: true, value: "zh" };
                                  case "pt":
                                    return { success: true, value: "pt" };
                                  case "fr":
                                    return { success: true, value: "fr" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["language"]);
                          })();
                          if (!parsed_language.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "manager")) {
                            return { success: false };
                          }
                          const parsed_manager = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["manager"]);
                          })();
                          if (!parsed_manager.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tags")) {
                            return { success: false };
                          }
                          const parsed_tags = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: Tag[] } => {
                              if (!isArray(value)) {
                                return { success: false };
                              }
                              const parsed = value.map((value: unknown): { success: false } | { success: true; value: Tag } => {
                                return ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value:
                                        | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                                        | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                                        | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                                        | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                                        | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                                        | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                                        | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                                    } => {
                                  return ((
                                    value: unknown,
                                  ):
                                    | { success: false }
                                    | {
                                        success: true;
                                        value:
                                          | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                                          | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                                          | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                                          | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                                          | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                                          | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                                          | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                                      } => {
                                    if (value == null) {
                                      return { success: false };
                                    }
                                    if (typeof value != "object") {
                                      return { success: false };
                                    }
                                    if (!hasProp(value, "discriminator")) {
                                      return { success: false };
                                    }
                                    if (typeof value["discriminator"] != "string") {
                                      return { success: false };
                                    }
                                    const discriminator = value["discriminator"];
                                    switch (discriminator) {
                                      case "dynamicString": {
                                        const parsedVariant = ((
                                          value: unknown,
                                        ):
                                          | { success: false }
                                          | {
                                              success: true;
                                              value: {
                                                exclude: boolean;
                                                source: TagSourceType;
                                                tagKind: TagKindDynamicString;
                                                value: string;
                                              };
                                            } => {
                                          if (typeof value !== "object") {
                                            return { success: false };
                                          }
                                          if (value === null) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "exclude")) {
                                            return { success: false };
                                          }
                                          const parsed_exclude = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                              if (typeof value !== "boolean") {
                                                return { success: false };
                                              }
                                              return { success: true, value: value };
                                            })(value["exclude"]);
                                          })();
                                          if (!parsed_exclude.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "source")) {
                                            return { success: false };
                                          }
                                          const parsed_source = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                                if (typeof value !== "string") {
                                                  return { success: false };
                                                }
                                                switch (value) {
                                                  case "USER":
                                                    return { success: true, value: "USER" };
                                                  case "CHANNEL_HIERARCHY":
                                                    return { success: true, value: "CHANNEL_HIERARCHY" };
                                                  case "AI":
                                                    return { success: true, value: "AI" };
                                                  default:
                                                    return { success: false };
                                                }
                                              })(value);
                                            })(value["source"]);
                                          })();
                                          if (!parsed_source.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "tagKind")) {
                                            return { success: false };
                                          }
                                          const parsed_tagKind = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicString } => {
                                              return ((
                                                value: unknown,
                                              ):
                                                | { success: false }
                                                | {
                                                    success: true;
                                                    value: {
                                                      discriminator: KindDynamicString;
                                                      id: bigint;
                                                      kind: string;
                                                    };
                                                  } => {
                                                if (typeof value !== "object") {
                                                  return { success: false };
                                                }
                                                if (value === null) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "discriminator")) {
                                                  return { success: false };
                                                }
                                                const parsed_discriminator = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: KindDynamicString } => {
                                                    return ((value: unknown): { success: false } | { success: true; value: "dynamicString" } => {
                                                      if (typeof value !== "string") {
                                                        return { success: false };
                                                      }
                                                      switch (value) {
                                                        case "dynamicString":
                                                          return { success: true, value: "dynamicString" };
                                                        default:
                                                          return { success: false };
                                                      }
                                                    })(value);
                                                  })(value["discriminator"]);
                                                })();
                                                if (!parsed_discriminator.success) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "id")) {
                                                  return { success: false };
                                                }
                                                const parsed_id = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                                    if (typeof value === "number") {
                                                      return { success: true, value: BigInt(value) };
                                                    }
                                                    if (typeof value === "bigint") {
                                                      return { success: true, value: value };
                                                    }
                                                    return { success: false };
                                                  })(value["id"]);
                                                })();
                                                if (!parsed_id.success) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "kind")) {
                                                  return { success: false };
                                                }
                                                const parsed_kind = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                                                    if (typeof value !== "string") {
                                                      return { success: false };
                                                    }
                                                    return { success: true, value: value };
                                                  })(value["kind"]);
                                                })();
                                                if (!parsed_kind.success) {
                                                  return { success: false };
                                                }
                                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                              })(value);
                                            })(value["tagKind"]);
                                          })();
                                          if (!parsed_tagKind.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "value")) {
                                            return { success: false };
                                          }
                                          const parsed_value = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              return { success: true, value: value };
                                            })(value["value"]);
                                          })();
                                          if (!parsed_value.success) {
                                            return { success: false };
                                          }
                                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                        })(value);
                                        if (!parsedVariant.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: "dynamicString" as const, ...parsedVariant.value } };
                                      }
                                      case "dynamicBoolean": {
                                        const parsedVariant = ((
                                          value: unknown,
                                        ):
                                          | { success: false }
                                          | {
                                              success: true;
                                              value: {
                                                exclude: boolean;
                                                source: TagSourceType;
                                                tagKind: TagKindDynamicBoolean;
                                                value: boolean;
                                              };
                                            } => {
                                          if (typeof value !== "object") {
                                            return { success: false };
                                          }
                                          if (value === null) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "exclude")) {
                                            return { success: false };
                                          }
                                          const parsed_exclude = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                              if (typeof value !== "boolean") {
                                                return { success: false };
                                              }
                                              return { success: true, value: value };
                                            })(value["exclude"]);
                                          })();
                                          if (!parsed_exclude.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "source")) {
                                            return { success: false };
                                          }
                                          const parsed_source = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                                if (typeof value !== "string") {
                                                  return { success: false };
                                                }
                                                switch (value) {
                                                  case "USER":
                                                    return { success: true, value: "USER" };
                                                  case "CHANNEL_HIERARCHY":
                                                    return { success: true, value: "CHANNEL_HIERARCHY" };
                                                  case "AI":
                                                    return { success: true, value: "AI" };
                                                  default:
                                                    return { success: false };
                                                }
                                              })(value);
                                            })(value["source"]);
                                          })();
                                          if (!parsed_source.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "tagKind")) {
                                            return { success: false };
                                          }
                                          const parsed_tagKind = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicBoolean } => {
                                              return ((
                                                value: unknown,
                                              ):
                                                | { success: false }
                                                | {
                                                    success: true;
                                                    value: {
                                                      discriminator: KindDynamicBoolean;
                                                      id: bigint;
                                                      kind: string;
                                                    };
                                                  } => {
                                                if (typeof value !== "object") {
                                                  return { success: false };
                                                }
                                                if (value === null) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "discriminator")) {
                                                  return { success: false };
                                                }
                                                const parsed_discriminator = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: KindDynamicBoolean } => {
                                                    return ((value: unknown): { success: false } | { success: true; value: "dynamicBoolean" } => {
                                                      if (typeof value !== "string") {
                                                        return { success: false };
                                                      }
                                                      switch (value) {
                                                        case "dynamicBoolean":
                                                          return { success: true, value: "dynamicBoolean" };
                                                        default:
                                                          return { success: false };
                                                      }
                                                    })(value);
                                                  })(value["discriminator"]);
                                                })();
                                                if (!parsed_discriminator.success) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "id")) {
                                                  return { success: false };
                                                }
                                                const parsed_id = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                                    if (typeof value === "number") {
                                                      return { success: true, value: BigInt(value) };
                                                    }
                                                    if (typeof value === "bigint") {
                                                      return { success: true, value: value };
                                                    }
                                                    return { success: false };
                                                  })(value["id"]);
                                                })();
                                                if (!parsed_id.success) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "kind")) {
                                                  return { success: false };
                                                }
                                                const parsed_kind = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                                                    if (typeof value !== "string") {
                                                      return { success: false };
                                                    }
                                                    return { success: true, value: value };
                                                  })(value["kind"]);
                                                })();
                                                if (!parsed_kind.success) {
                                                  return { success: false };
                                                }
                                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                              })(value);
                                            })(value["tagKind"]);
                                          })();
                                          if (!parsed_tagKind.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "value")) {
                                            return { success: false };
                                          }
                                          const parsed_value = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                              if (typeof value !== "boolean") {
                                                return { success: false };
                                              }
                                              return { success: true, value: value };
                                            })(value["value"]);
                                          })();
                                          if (!parsed_value.success) {
                                            return { success: false };
                                          }
                                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                        })(value);
                                        if (!parsedVariant.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: "dynamicBoolean" as const, ...parsedVariant.value } };
                                      }
                                      case "dynamicNumber": {
                                        const parsedVariant = ((
                                          value: unknown,
                                        ):
                                          | { success: false }
                                          | {
                                              success: true;
                                              value: {
                                                exclude: boolean;
                                                source: TagSourceType;
                                                tagKind: TagKindDynamicNumber;
                                                value: number;
                                              };
                                            } => {
                                          if (typeof value !== "object") {
                                            return { success: false };
                                          }
                                          if (value === null) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "exclude")) {
                                            return { success: false };
                                          }
                                          const parsed_exclude = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                              if (typeof value !== "boolean") {
                                                return { success: false };
                                              }
                                              return { success: true, value: value };
                                            })(value["exclude"]);
                                          })();
                                          if (!parsed_exclude.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "source")) {
                                            return { success: false };
                                          }
                                          const parsed_source = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                                if (typeof value !== "string") {
                                                  return { success: false };
                                                }
                                                switch (value) {
                                                  case "USER":
                                                    return { success: true, value: "USER" };
                                                  case "CHANNEL_HIERARCHY":
                                                    return { success: true, value: "CHANNEL_HIERARCHY" };
                                                  case "AI":
                                                    return { success: true, value: "AI" };
                                                  default:
                                                    return { success: false };
                                                }
                                              })(value);
                                            })(value["source"]);
                                          })();
                                          if (!parsed_source.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "tagKind")) {
                                            return { success: false };
                                          }
                                          const parsed_tagKind = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicNumber } => {
                                              return ((
                                                value: unknown,
                                              ):
                                                | { success: false }
                                                | {
                                                    success: true;
                                                    value: {
                                                      discriminator: KindDynamicNumber;
                                                      id: bigint;
                                                      kind: string;
                                                    };
                                                  } => {
                                                if (typeof value !== "object") {
                                                  return { success: false };
                                                }
                                                if (value === null) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "discriminator")) {
                                                  return { success: false };
                                                }
                                                const parsed_discriminator = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: KindDynamicNumber } => {
                                                    return ((value: unknown): { success: false } | { success: true; value: "dynamicNumber" } => {
                                                      if (typeof value !== "string") {
                                                        return { success: false };
                                                      }
                                                      switch (value) {
                                                        case "dynamicNumber":
                                                          return { success: true, value: "dynamicNumber" };
                                                        default:
                                                          return { success: false };
                                                      }
                                                    })(value);
                                                  })(value["discriminator"]);
                                                })();
                                                if (!parsed_discriminator.success) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "id")) {
                                                  return { success: false };
                                                }
                                                const parsed_id = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                                    if (typeof value === "number") {
                                                      return { success: true, value: BigInt(value) };
                                                    }
                                                    if (typeof value === "bigint") {
                                                      return { success: true, value: value };
                                                    }
                                                    return { success: false };
                                                  })(value["id"]);
                                                })();
                                                if (!parsed_id.success) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "kind")) {
                                                  return { success: false };
                                                }
                                                const parsed_kind = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                                                    if (typeof value !== "string") {
                                                      return { success: false };
                                                    }
                                                    return { success: true, value: value };
                                                  })(value["kind"]);
                                                })();
                                                if (!parsed_kind.success) {
                                                  return { success: false };
                                                }
                                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                              })(value);
                                            })(value["tagKind"]);
                                          })();
                                          if (!parsed_tagKind.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "value")) {
                                            return { success: false };
                                          }
                                          const parsed_value = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: number } => {
                                              if (typeof value === "number") {
                                                return { success: true, value: value };
                                              }
                                              return { success: false };
                                            })(value["value"]);
                                          })();
                                          if (!parsed_value.success) {
                                            return { success: false };
                                          }
                                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                        })(value);
                                        if (!parsedVariant.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: "dynamicNumber" as const, ...parsedVariant.value } };
                                      }
                                      case "dynamicVoid": {
                                        const parsedVariant = ((
                                          value: unknown,
                                        ):
                                          | { success: false }
                                          | {
                                              success: true;
                                              value: {
                                                exclude: boolean;
                                                source: TagSourceType;
                                                tagKind: TagKindDynamicVoid;
                                              };
                                            } => {
                                          if (typeof value !== "object") {
                                            return { success: false };
                                          }
                                          if (value === null) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "exclude")) {
                                            return { success: false };
                                          }
                                          const parsed_exclude = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                              if (typeof value !== "boolean") {
                                                return { success: false };
                                              }
                                              return { success: true, value: value };
                                            })(value["exclude"]);
                                          })();
                                          if (!parsed_exclude.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "source")) {
                                            return { success: false };
                                          }
                                          const parsed_source = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                                if (typeof value !== "string") {
                                                  return { success: false };
                                                }
                                                switch (value) {
                                                  case "USER":
                                                    return { success: true, value: "USER" };
                                                  case "CHANNEL_HIERARCHY":
                                                    return { success: true, value: "CHANNEL_HIERARCHY" };
                                                  case "AI":
                                                    return { success: true, value: "AI" };
                                                  default:
                                                    return { success: false };
                                                }
                                              })(value);
                                            })(value["source"]);
                                          })();
                                          if (!parsed_source.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "tagKind")) {
                                            return { success: false };
                                          }
                                          const parsed_tagKind = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicVoid } => {
                                              return ((
                                                value: unknown,
                                              ):
                                                | { success: false }
                                                | {
                                                    success: true;
                                                    value: {
                                                      discriminator: KindDynamicVoid;
                                                      id: bigint;
                                                      kind: string;
                                                    };
                                                  } => {
                                                if (typeof value !== "object") {
                                                  return { success: false };
                                                }
                                                if (value === null) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "discriminator")) {
                                                  return { success: false };
                                                }
                                                const parsed_discriminator = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: KindDynamicVoid } => {
                                                    return ((value: unknown): { success: false } | { success: true; value: "dynamicVoid" } => {
                                                      if (typeof value !== "string") {
                                                        return { success: false };
                                                      }
                                                      switch (value) {
                                                        case "dynamicVoid":
                                                          return { success: true, value: "dynamicVoid" };
                                                        default:
                                                          return { success: false };
                                                      }
                                                    })(value);
                                                  })(value["discriminator"]);
                                                })();
                                                if (!parsed_discriminator.success) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "id")) {
                                                  return { success: false };
                                                }
                                                const parsed_id = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                                    if (typeof value === "number") {
                                                      return { success: true, value: BigInt(value) };
                                                    }
                                                    if (typeof value === "bigint") {
                                                      return { success: true, value: value };
                                                    }
                                                    return { success: false };
                                                  })(value["id"]);
                                                })();
                                                if (!parsed_id.success) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "kind")) {
                                                  return { success: false };
                                                }
                                                const parsed_kind = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                                                    if (typeof value !== "string") {
                                                      return { success: false };
                                                    }
                                                    return { success: true, value: value };
                                                  })(value["kind"]);
                                                })();
                                                if (!parsed_kind.success) {
                                                  return { success: false };
                                                }
                                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                              })(value);
                                            })(value["tagKind"]);
                                          })();
                                          if (!parsed_tagKind.success) {
                                            return { success: false };
                                          }
                                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value } };
                                        })(value);
                                        if (!parsedVariant.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: "dynamicVoid" as const, ...parsedVariant.value } };
                                      }
                                      case "channel": {
                                        const parsedVariant = ((
                                          value: unknown,
                                        ):
                                          | { success: false }
                                          | {
                                              success: true;
                                              value: {
                                                exclude: boolean;
                                                source: TagSourceType;
                                                tagKind: TagKindChannel;
                                                value: string;
                                              };
                                            } => {
                                          if (typeof value !== "object") {
                                            return { success: false };
                                          }
                                          if (value === null) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "exclude")) {
                                            return { success: false };
                                          }
                                          const parsed_exclude = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                              if (typeof value !== "boolean") {
                                                return { success: false };
                                              }
                                              return { success: true, value: value };
                                            })(value["exclude"]);
                                          })();
                                          if (!parsed_exclude.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "source")) {
                                            return { success: false };
                                          }
                                          const parsed_source = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                                if (typeof value !== "string") {
                                                  return { success: false };
                                                }
                                                switch (value) {
                                                  case "USER":
                                                    return { success: true, value: "USER" };
                                                  case "CHANNEL_HIERARCHY":
                                                    return { success: true, value: "CHANNEL_HIERARCHY" };
                                                  case "AI":
                                                    return { success: true, value: "AI" };
                                                  default:
                                                    return { success: false };
                                                }
                                              })(value);
                                            })(value["source"]);
                                          })();
                                          if (!parsed_source.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "tagKind")) {
                                            return { success: false };
                                          }
                                          const parsed_tagKind = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: TagKindChannel } => {
                                              return ((
                                                value: unknown,
                                              ):
                                                | { success: false }
                                                | {
                                                    success: true;
                                                    value: {
                                                      discriminator: KindChannel;
                                                      id: bigint;
                                                      kind: KindChannel;
                                                    };
                                                  } => {
                                                if (typeof value !== "object") {
                                                  return { success: false };
                                                }
                                                if (value === null) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "discriminator")) {
                                                  return { success: false };
                                                }
                                                const parsed_discriminator = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                                                    return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                                      if (typeof value !== "string") {
                                                        return { success: false };
                                                      }
                                                      switch (value) {
                                                        case "channel":
                                                          return { success: true, value: "channel" };
                                                        default:
                                                          return { success: false };
                                                      }
                                                    })(value);
                                                  })(value["discriminator"]);
                                                })();
                                                if (!parsed_discriminator.success) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "id")) {
                                                  return { success: false };
                                                }
                                                const parsed_id = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                                    if (typeof value === "number") {
                                                      return { success: true, value: BigInt(value) };
                                                    }
                                                    if (typeof value === "bigint") {
                                                      return { success: true, value: value };
                                                    }
                                                    return { success: false };
                                                  })(value["id"]);
                                                })();
                                                if (!parsed_id.success) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "kind")) {
                                                  return { success: false };
                                                }
                                                const parsed_kind = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                                                    return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                                      if (typeof value !== "string") {
                                                        return { success: false };
                                                      }
                                                      switch (value) {
                                                        case "channel":
                                                          return { success: true, value: "channel" };
                                                        default:
                                                          return { success: false };
                                                      }
                                                    })(value);
                                                  })(value["kind"]);
                                                })();
                                                if (!parsed_kind.success) {
                                                  return { success: false };
                                                }
                                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                              })(value);
                                            })(value["tagKind"]);
                                          })();
                                          if (!parsed_tagKind.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "value")) {
                                            return { success: false };
                                          }
                                          const parsed_value = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              return { success: true, value: value };
                                            })(value["value"]);
                                          })();
                                          if (!parsed_value.success) {
                                            return { success: false };
                                          }
                                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                        })(value);
                                        if (!parsedVariant.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: "channel" as const, ...parsedVariant.value } };
                                      }
                                      case "key-quality": {
                                        const parsedVariant = ((
                                          value: unknown,
                                        ):
                                          | { success: false }
                                          | {
                                              success: true;
                                              value: {
                                                exclude: boolean;
                                                source: TagSourceType;
                                                tagKind: TagKindKeyQuality;
                                                value: string;
                                              };
                                            } => {
                                          if (typeof value !== "object") {
                                            return { success: false };
                                          }
                                          if (value === null) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "exclude")) {
                                            return { success: false };
                                          }
                                          const parsed_exclude = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                              if (typeof value !== "boolean") {
                                                return { success: false };
                                              }
                                              return { success: true, value: value };
                                            })(value["exclude"]);
                                          })();
                                          if (!parsed_exclude.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "source")) {
                                            return { success: false };
                                          }
                                          const parsed_source = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                                if (typeof value !== "string") {
                                                  return { success: false };
                                                }
                                                switch (value) {
                                                  case "USER":
                                                    return { success: true, value: "USER" };
                                                  case "CHANNEL_HIERARCHY":
                                                    return { success: true, value: "CHANNEL_HIERARCHY" };
                                                  case "AI":
                                                    return { success: true, value: "AI" };
                                                  default:
                                                    return { success: false };
                                                }
                                              })(value);
                                            })(value["source"]);
                                          })();
                                          if (!parsed_source.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "tagKind")) {
                                            return { success: false };
                                          }
                                          const parsed_tagKind = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: TagKindKeyQuality } => {
                                              return ((
                                                value: unknown,
                                              ):
                                                | { success: false }
                                                | {
                                                    success: true;
                                                    value: {
                                                      discriminator: KindKeyQuality;
                                                      id: bigint;
                                                      kind: KindKeyQuality;
                                                    };
                                                  } => {
                                                if (typeof value !== "object") {
                                                  return { success: false };
                                                }
                                                if (value === null) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "discriminator")) {
                                                  return { success: false };
                                                }
                                                const parsed_discriminator = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                                                    return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                                      if (typeof value !== "string") {
                                                        return { success: false };
                                                      }
                                                      switch (value) {
                                                        case "key-quality":
                                                          return { success: true, value: "key-quality" };
                                                        default:
                                                          return { success: false };
                                                      }
                                                    })(value);
                                                  })(value["discriminator"]);
                                                })();
                                                if (!parsed_discriminator.success) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "id")) {
                                                  return { success: false };
                                                }
                                                const parsed_id = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                                    if (typeof value === "number") {
                                                      return { success: true, value: BigInt(value) };
                                                    }
                                                    if (typeof value === "bigint") {
                                                      return { success: true, value: value };
                                                    }
                                                    return { success: false };
                                                  })(value["id"]);
                                                })();
                                                if (!parsed_id.success) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "kind")) {
                                                  return { success: false };
                                                }
                                                const parsed_kind = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                                                    return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                                      if (typeof value !== "string") {
                                                        return { success: false };
                                                      }
                                                      switch (value) {
                                                        case "key-quality":
                                                          return { success: true, value: "key-quality" };
                                                        default:
                                                          return { success: false };
                                                      }
                                                    })(value);
                                                  })(value["kind"]);
                                                })();
                                                if (!parsed_kind.success) {
                                                  return { success: false };
                                                }
                                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                              })(value);
                                            })(value["tagKind"]);
                                          })();
                                          if (!parsed_tagKind.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "value")) {
                                            return { success: false };
                                          }
                                          const parsed_value = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              return { success: true, value: value };
                                            })(value["value"]);
                                          })();
                                          if (!parsed_value.success) {
                                            return { success: false };
                                          }
                                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                        })(value);
                                        if (!parsedVariant.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: "key-quality" as const, ...parsedVariant.value } };
                                      }
                                      case "classification": {
                                        const parsedVariant = ((
                                          value: unknown,
                                        ):
                                          | { success: false }
                                          | {
                                              success: true;
                                              value: {
                                                exclude: boolean;
                                                source: TagSourceType;
                                                tagKind: TagKindClassification;
                                                value: string;
                                              };
                                            } => {
                                          if (typeof value !== "object") {
                                            return { success: false };
                                          }
                                          if (value === null) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "exclude")) {
                                            return { success: false };
                                          }
                                          const parsed_exclude = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                              if (typeof value !== "boolean") {
                                                return { success: false };
                                              }
                                              return { success: true, value: value };
                                            })(value["exclude"]);
                                          })();
                                          if (!parsed_exclude.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "source")) {
                                            return { success: false };
                                          }
                                          const parsed_source = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                                if (typeof value !== "string") {
                                                  return { success: false };
                                                }
                                                switch (value) {
                                                  case "USER":
                                                    return { success: true, value: "USER" };
                                                  case "CHANNEL_HIERARCHY":
                                                    return { success: true, value: "CHANNEL_HIERARCHY" };
                                                  case "AI":
                                                    return { success: true, value: "AI" };
                                                  default:
                                                    return { success: false };
                                                }
                                              })(value);
                                            })(value["source"]);
                                          })();
                                          if (!parsed_source.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "tagKind")) {
                                            return { success: false };
                                          }
                                          const parsed_tagKind = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: TagKindClassification } => {
                                              return ((
                                                value: unknown,
                                              ):
                                                | { success: false }
                                                | {
                                                    success: true;
                                                    value: {
                                                      discriminator: KindClassification;
                                                      id: bigint;
                                                      kind: KindClassification;
                                                    };
                                                  } => {
                                                if (typeof value !== "object") {
                                                  return { success: false };
                                                }
                                                if (value === null) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "discriminator")) {
                                                  return { success: false };
                                                }
                                                const parsed_discriminator = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                                                    return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                                      if (typeof value !== "string") {
                                                        return { success: false };
                                                      }
                                                      switch (value) {
                                                        case "classification":
                                                          return { success: true, value: "classification" };
                                                        default:
                                                          return { success: false };
                                                      }
                                                    })(value);
                                                  })(value["discriminator"]);
                                                })();
                                                if (!parsed_discriminator.success) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "id")) {
                                                  return { success: false };
                                                }
                                                const parsed_id = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                                    if (typeof value === "number") {
                                                      return { success: true, value: BigInt(value) };
                                                    }
                                                    if (typeof value === "bigint") {
                                                      return { success: true, value: value };
                                                    }
                                                    return { success: false };
                                                  })(value["id"]);
                                                })();
                                                if (!parsed_id.success) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "kind")) {
                                                  return { success: false };
                                                }
                                                const parsed_kind = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                                                    return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                                      if (typeof value !== "string") {
                                                        return { success: false };
                                                      }
                                                      switch (value) {
                                                        case "classification":
                                                          return { success: true, value: "classification" };
                                                        default:
                                                          return { success: false };
                                                      }
                                                    })(value);
                                                  })(value["kind"]);
                                                })();
                                                if (!parsed_kind.success) {
                                                  return { success: false };
                                                }
                                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                              })(value);
                                            })(value["tagKind"]);
                                          })();
                                          if (!parsed_tagKind.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "value")) {
                                            return { success: false };
                                          }
                                          const parsed_value = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              return { success: true, value: value };
                                            })(value["value"]);
                                          })();
                                          if (!parsed_value.success) {
                                            return { success: false };
                                          }
                                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                        })(value);
                                        if (!parsedVariant.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: "classification" as const, ...parsedVariant.value } };
                                      }
                                      default:
                                        return { success: false };
                                    }
                                  })(value);
                                })(value);
                              });
                              if (parsed.find((value) => !value.success) !== undefined) {
                                return { success: false };
                              }
                              return {
                                success: true,
                                value: parsed.map((entry) => {
                                  assert(entry.success == true);
                                  return entry.value;
                                }),
                              };
                            })(value["tags"]);
                          })();
                          if (!parsed_tags.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "title")) {
                            return { success: false };
                          }
                          const parsed_title = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["title"]);
                          })();
                          if (!parsed_title.success) {
                            return { success: false };
                          }
                          return {
                            success: true,
                            value: {
                              activatedAt: parsed_activatedAt.value,
                              active: parsed_active.value,
                              createdAt: parsed_createdAt.value,
                              deactivatedAt: parsed_deactivatedAt.value,
                              id: parsed_id.value,
                              language: parsed_language.value,
                              manager: parsed_manager.value,
                              tags: parsed_tags.value,
                              title: parsed_title.value,
                            },
                          };
                        })(value);
                      })(value["metadata"]);
                    })();
                    if (!parsed_metadata.success) {
                      return { success: false };
                    }
                    return { success: true, value: { description: parsed_description.value, metadata: parsed_metadata.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { learningItemType: "ACTIONABLE" as const, ...parsedVariant.value } };
                }
                case "SUMMARY": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          audioActive: boolean;
                          documentType: SummaryDocumentType;
                          metadata: LearningItemAdminMetadataView;
                          publisher: string | null;
                          sourceType: SummarySourceType;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "audioActive")) {
                      return { success: false };
                    }
                    const parsed_audioActive = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["audioActive"]);
                    })();
                    if (!parsed_audioActive.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "documentType")) {
                      return { success: false };
                    }
                    const parsed_documentType = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: SummaryDocumentType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "ABSTRACT" | "REVIEW" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "ABSTRACT":
                              return { success: true, value: "ABSTRACT" };
                            case "REVIEW":
                              return { success: true, value: "REVIEW" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["documentType"]);
                    })();
                    if (!parsed_documentType.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "metadata")) {
                      return { success: false };
                    }
                    const parsed_metadata = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: LearningItemAdminMetadataView } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                activatedAt: { year: number; month: number; day: number } | null;
                                active: boolean;
                                createdAt: { year: number; month: number; day: number };
                                deactivatedAt: { year: number; month: number; day: number } | null;
                                id: bigint;
                                language: Language;
                                manager: string | null;
                                tags: Tag[];
                                title: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "activatedAt")) {
                            return { success: false };
                          }
                          const parsed_activatedAt = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
                              if (!parsed.isValid) {
                                return { success: false };
                              }
                              return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
                            })(value["activatedAt"]);
                          })();
                          if (!parsed_activatedAt.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "active")) {
                            return { success: false };
                          }
                          const parsed_active = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["active"]);
                          })();
                          if (!parsed_active.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "createdAt")) {
                            return { success: false };
                          }
                          const parsed_createdAt = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
                              if (!parsed.isValid) {
                                return { success: false };
                              }
                              return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
                            })(value["createdAt"]);
                          })();
                          if (!parsed_createdAt.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "deactivatedAt")) {
                            return { success: false };
                          }
                          const parsed_deactivatedAt = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
                              if (!parsed.isValid) {
                                return { success: false };
                              }
                              return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
                            })(value["deactivatedAt"]);
                          })();
                          if (!parsed_deactivatedAt.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "language")) {
                            return { success: false };
                          }
                          const parsed_language = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: Language } => {
                              return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "en":
                                    return { success: true, value: "en" };
                                  case "de":
                                    return { success: true, value: "de" };
                                  case "es":
                                    return { success: true, value: "es" };
                                  case "ru":
                                    return { success: true, value: "ru" };
                                  case "zh":
                                    return { success: true, value: "zh" };
                                  case "pt":
                                    return { success: true, value: "pt" };
                                  case "fr":
                                    return { success: true, value: "fr" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["language"]);
                          })();
                          if (!parsed_language.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "manager")) {
                            return { success: false };
                          }
                          const parsed_manager = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["manager"]);
                          })();
                          if (!parsed_manager.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tags")) {
                            return { success: false };
                          }
                          const parsed_tags = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: Tag[] } => {
                              if (!isArray(value)) {
                                return { success: false };
                              }
                              const parsed = value.map((value: unknown): { success: false } | { success: true; value: Tag } => {
                                return ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value:
                                        | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                                        | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                                        | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                                        | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                                        | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                                        | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                                        | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                                    } => {
                                  return ((
                                    value: unknown,
                                  ):
                                    | { success: false }
                                    | {
                                        success: true;
                                        value:
                                          | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                                          | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                                          | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                                          | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                                          | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                                          | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                                          | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                                      } => {
                                    if (value == null) {
                                      return { success: false };
                                    }
                                    if (typeof value != "object") {
                                      return { success: false };
                                    }
                                    if (!hasProp(value, "discriminator")) {
                                      return { success: false };
                                    }
                                    if (typeof value["discriminator"] != "string") {
                                      return { success: false };
                                    }
                                    const discriminator = value["discriminator"];
                                    switch (discriminator) {
                                      case "dynamicString": {
                                        const parsedVariant = ((
                                          value: unknown,
                                        ):
                                          | { success: false }
                                          | {
                                              success: true;
                                              value: {
                                                exclude: boolean;
                                                source: TagSourceType;
                                                tagKind: TagKindDynamicString;
                                                value: string;
                                              };
                                            } => {
                                          if (typeof value !== "object") {
                                            return { success: false };
                                          }
                                          if (value === null) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "exclude")) {
                                            return { success: false };
                                          }
                                          const parsed_exclude = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                              if (typeof value !== "boolean") {
                                                return { success: false };
                                              }
                                              return { success: true, value: value };
                                            })(value["exclude"]);
                                          })();
                                          if (!parsed_exclude.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "source")) {
                                            return { success: false };
                                          }
                                          const parsed_source = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                                if (typeof value !== "string") {
                                                  return { success: false };
                                                }
                                                switch (value) {
                                                  case "USER":
                                                    return { success: true, value: "USER" };
                                                  case "CHANNEL_HIERARCHY":
                                                    return { success: true, value: "CHANNEL_HIERARCHY" };
                                                  case "AI":
                                                    return { success: true, value: "AI" };
                                                  default:
                                                    return { success: false };
                                                }
                                              })(value);
                                            })(value["source"]);
                                          })();
                                          if (!parsed_source.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "tagKind")) {
                                            return { success: false };
                                          }
                                          const parsed_tagKind = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicString } => {
                                              return ((
                                                value: unknown,
                                              ):
                                                | { success: false }
                                                | {
                                                    success: true;
                                                    value: {
                                                      discriminator: KindDynamicString;
                                                      id: bigint;
                                                      kind: string;
                                                    };
                                                  } => {
                                                if (typeof value !== "object") {
                                                  return { success: false };
                                                }
                                                if (value === null) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "discriminator")) {
                                                  return { success: false };
                                                }
                                                const parsed_discriminator = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: KindDynamicString } => {
                                                    return ((value: unknown): { success: false } | { success: true; value: "dynamicString" } => {
                                                      if (typeof value !== "string") {
                                                        return { success: false };
                                                      }
                                                      switch (value) {
                                                        case "dynamicString":
                                                          return { success: true, value: "dynamicString" };
                                                        default:
                                                          return { success: false };
                                                      }
                                                    })(value);
                                                  })(value["discriminator"]);
                                                })();
                                                if (!parsed_discriminator.success) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "id")) {
                                                  return { success: false };
                                                }
                                                const parsed_id = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                                    if (typeof value === "number") {
                                                      return { success: true, value: BigInt(value) };
                                                    }
                                                    if (typeof value === "bigint") {
                                                      return { success: true, value: value };
                                                    }
                                                    return { success: false };
                                                  })(value["id"]);
                                                })();
                                                if (!parsed_id.success) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "kind")) {
                                                  return { success: false };
                                                }
                                                const parsed_kind = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                                                    if (typeof value !== "string") {
                                                      return { success: false };
                                                    }
                                                    return { success: true, value: value };
                                                  })(value["kind"]);
                                                })();
                                                if (!parsed_kind.success) {
                                                  return { success: false };
                                                }
                                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                              })(value);
                                            })(value["tagKind"]);
                                          })();
                                          if (!parsed_tagKind.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "value")) {
                                            return { success: false };
                                          }
                                          const parsed_value = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              return { success: true, value: value };
                                            })(value["value"]);
                                          })();
                                          if (!parsed_value.success) {
                                            return { success: false };
                                          }
                                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                        })(value);
                                        if (!parsedVariant.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: "dynamicString" as const, ...parsedVariant.value } };
                                      }
                                      case "dynamicBoolean": {
                                        const parsedVariant = ((
                                          value: unknown,
                                        ):
                                          | { success: false }
                                          | {
                                              success: true;
                                              value: {
                                                exclude: boolean;
                                                source: TagSourceType;
                                                tagKind: TagKindDynamicBoolean;
                                                value: boolean;
                                              };
                                            } => {
                                          if (typeof value !== "object") {
                                            return { success: false };
                                          }
                                          if (value === null) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "exclude")) {
                                            return { success: false };
                                          }
                                          const parsed_exclude = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                              if (typeof value !== "boolean") {
                                                return { success: false };
                                              }
                                              return { success: true, value: value };
                                            })(value["exclude"]);
                                          })();
                                          if (!parsed_exclude.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "source")) {
                                            return { success: false };
                                          }
                                          const parsed_source = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                                if (typeof value !== "string") {
                                                  return { success: false };
                                                }
                                                switch (value) {
                                                  case "USER":
                                                    return { success: true, value: "USER" };
                                                  case "CHANNEL_HIERARCHY":
                                                    return { success: true, value: "CHANNEL_HIERARCHY" };
                                                  case "AI":
                                                    return { success: true, value: "AI" };
                                                  default:
                                                    return { success: false };
                                                }
                                              })(value);
                                            })(value["source"]);
                                          })();
                                          if (!parsed_source.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "tagKind")) {
                                            return { success: false };
                                          }
                                          const parsed_tagKind = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicBoolean } => {
                                              return ((
                                                value: unknown,
                                              ):
                                                | { success: false }
                                                | {
                                                    success: true;
                                                    value: {
                                                      discriminator: KindDynamicBoolean;
                                                      id: bigint;
                                                      kind: string;
                                                    };
                                                  } => {
                                                if (typeof value !== "object") {
                                                  return { success: false };
                                                }
                                                if (value === null) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "discriminator")) {
                                                  return { success: false };
                                                }
                                                const parsed_discriminator = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: KindDynamicBoolean } => {
                                                    return ((value: unknown): { success: false } | { success: true; value: "dynamicBoolean" } => {
                                                      if (typeof value !== "string") {
                                                        return { success: false };
                                                      }
                                                      switch (value) {
                                                        case "dynamicBoolean":
                                                          return { success: true, value: "dynamicBoolean" };
                                                        default:
                                                          return { success: false };
                                                      }
                                                    })(value);
                                                  })(value["discriminator"]);
                                                })();
                                                if (!parsed_discriminator.success) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "id")) {
                                                  return { success: false };
                                                }
                                                const parsed_id = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                                    if (typeof value === "number") {
                                                      return { success: true, value: BigInt(value) };
                                                    }
                                                    if (typeof value === "bigint") {
                                                      return { success: true, value: value };
                                                    }
                                                    return { success: false };
                                                  })(value["id"]);
                                                })();
                                                if (!parsed_id.success) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "kind")) {
                                                  return { success: false };
                                                }
                                                const parsed_kind = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                                                    if (typeof value !== "string") {
                                                      return { success: false };
                                                    }
                                                    return { success: true, value: value };
                                                  })(value["kind"]);
                                                })();
                                                if (!parsed_kind.success) {
                                                  return { success: false };
                                                }
                                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                              })(value);
                                            })(value["tagKind"]);
                                          })();
                                          if (!parsed_tagKind.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "value")) {
                                            return { success: false };
                                          }
                                          const parsed_value = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                              if (typeof value !== "boolean") {
                                                return { success: false };
                                              }
                                              return { success: true, value: value };
                                            })(value["value"]);
                                          })();
                                          if (!parsed_value.success) {
                                            return { success: false };
                                          }
                                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                        })(value);
                                        if (!parsedVariant.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: "dynamicBoolean" as const, ...parsedVariant.value } };
                                      }
                                      case "dynamicNumber": {
                                        const parsedVariant = ((
                                          value: unknown,
                                        ):
                                          | { success: false }
                                          | {
                                              success: true;
                                              value: {
                                                exclude: boolean;
                                                source: TagSourceType;
                                                tagKind: TagKindDynamicNumber;
                                                value: number;
                                              };
                                            } => {
                                          if (typeof value !== "object") {
                                            return { success: false };
                                          }
                                          if (value === null) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "exclude")) {
                                            return { success: false };
                                          }
                                          const parsed_exclude = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                              if (typeof value !== "boolean") {
                                                return { success: false };
                                              }
                                              return { success: true, value: value };
                                            })(value["exclude"]);
                                          })();
                                          if (!parsed_exclude.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "source")) {
                                            return { success: false };
                                          }
                                          const parsed_source = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                                if (typeof value !== "string") {
                                                  return { success: false };
                                                }
                                                switch (value) {
                                                  case "USER":
                                                    return { success: true, value: "USER" };
                                                  case "CHANNEL_HIERARCHY":
                                                    return { success: true, value: "CHANNEL_HIERARCHY" };
                                                  case "AI":
                                                    return { success: true, value: "AI" };
                                                  default:
                                                    return { success: false };
                                                }
                                              })(value);
                                            })(value["source"]);
                                          })();
                                          if (!parsed_source.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "tagKind")) {
                                            return { success: false };
                                          }
                                          const parsed_tagKind = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicNumber } => {
                                              return ((
                                                value: unknown,
                                              ):
                                                | { success: false }
                                                | {
                                                    success: true;
                                                    value: {
                                                      discriminator: KindDynamicNumber;
                                                      id: bigint;
                                                      kind: string;
                                                    };
                                                  } => {
                                                if (typeof value !== "object") {
                                                  return { success: false };
                                                }
                                                if (value === null) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "discriminator")) {
                                                  return { success: false };
                                                }
                                                const parsed_discriminator = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: KindDynamicNumber } => {
                                                    return ((value: unknown): { success: false } | { success: true; value: "dynamicNumber" } => {
                                                      if (typeof value !== "string") {
                                                        return { success: false };
                                                      }
                                                      switch (value) {
                                                        case "dynamicNumber":
                                                          return { success: true, value: "dynamicNumber" };
                                                        default:
                                                          return { success: false };
                                                      }
                                                    })(value);
                                                  })(value["discriminator"]);
                                                })();
                                                if (!parsed_discriminator.success) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "id")) {
                                                  return { success: false };
                                                }
                                                const parsed_id = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                                    if (typeof value === "number") {
                                                      return { success: true, value: BigInt(value) };
                                                    }
                                                    if (typeof value === "bigint") {
                                                      return { success: true, value: value };
                                                    }
                                                    return { success: false };
                                                  })(value["id"]);
                                                })();
                                                if (!parsed_id.success) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "kind")) {
                                                  return { success: false };
                                                }
                                                const parsed_kind = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                                                    if (typeof value !== "string") {
                                                      return { success: false };
                                                    }
                                                    return { success: true, value: value };
                                                  })(value["kind"]);
                                                })();
                                                if (!parsed_kind.success) {
                                                  return { success: false };
                                                }
                                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                              })(value);
                                            })(value["tagKind"]);
                                          })();
                                          if (!parsed_tagKind.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "value")) {
                                            return { success: false };
                                          }
                                          const parsed_value = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: number } => {
                                              if (typeof value === "number") {
                                                return { success: true, value: value };
                                              }
                                              return { success: false };
                                            })(value["value"]);
                                          })();
                                          if (!parsed_value.success) {
                                            return { success: false };
                                          }
                                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                        })(value);
                                        if (!parsedVariant.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: "dynamicNumber" as const, ...parsedVariant.value } };
                                      }
                                      case "dynamicVoid": {
                                        const parsedVariant = ((
                                          value: unknown,
                                        ):
                                          | { success: false }
                                          | {
                                              success: true;
                                              value: {
                                                exclude: boolean;
                                                source: TagSourceType;
                                                tagKind: TagKindDynamicVoid;
                                              };
                                            } => {
                                          if (typeof value !== "object") {
                                            return { success: false };
                                          }
                                          if (value === null) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "exclude")) {
                                            return { success: false };
                                          }
                                          const parsed_exclude = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                              if (typeof value !== "boolean") {
                                                return { success: false };
                                              }
                                              return { success: true, value: value };
                                            })(value["exclude"]);
                                          })();
                                          if (!parsed_exclude.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "source")) {
                                            return { success: false };
                                          }
                                          const parsed_source = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                                if (typeof value !== "string") {
                                                  return { success: false };
                                                }
                                                switch (value) {
                                                  case "USER":
                                                    return { success: true, value: "USER" };
                                                  case "CHANNEL_HIERARCHY":
                                                    return { success: true, value: "CHANNEL_HIERARCHY" };
                                                  case "AI":
                                                    return { success: true, value: "AI" };
                                                  default:
                                                    return { success: false };
                                                }
                                              })(value);
                                            })(value["source"]);
                                          })();
                                          if (!parsed_source.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "tagKind")) {
                                            return { success: false };
                                          }
                                          const parsed_tagKind = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicVoid } => {
                                              return ((
                                                value: unknown,
                                              ):
                                                | { success: false }
                                                | {
                                                    success: true;
                                                    value: {
                                                      discriminator: KindDynamicVoid;
                                                      id: bigint;
                                                      kind: string;
                                                    };
                                                  } => {
                                                if (typeof value !== "object") {
                                                  return { success: false };
                                                }
                                                if (value === null) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "discriminator")) {
                                                  return { success: false };
                                                }
                                                const parsed_discriminator = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: KindDynamicVoid } => {
                                                    return ((value: unknown): { success: false } | { success: true; value: "dynamicVoid" } => {
                                                      if (typeof value !== "string") {
                                                        return { success: false };
                                                      }
                                                      switch (value) {
                                                        case "dynamicVoid":
                                                          return { success: true, value: "dynamicVoid" };
                                                        default:
                                                          return { success: false };
                                                      }
                                                    })(value);
                                                  })(value["discriminator"]);
                                                })();
                                                if (!parsed_discriminator.success) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "id")) {
                                                  return { success: false };
                                                }
                                                const parsed_id = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                                    if (typeof value === "number") {
                                                      return { success: true, value: BigInt(value) };
                                                    }
                                                    if (typeof value === "bigint") {
                                                      return { success: true, value: value };
                                                    }
                                                    return { success: false };
                                                  })(value["id"]);
                                                })();
                                                if (!parsed_id.success) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "kind")) {
                                                  return { success: false };
                                                }
                                                const parsed_kind = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                                                    if (typeof value !== "string") {
                                                      return { success: false };
                                                    }
                                                    return { success: true, value: value };
                                                  })(value["kind"]);
                                                })();
                                                if (!parsed_kind.success) {
                                                  return { success: false };
                                                }
                                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                              })(value);
                                            })(value["tagKind"]);
                                          })();
                                          if (!parsed_tagKind.success) {
                                            return { success: false };
                                          }
                                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value } };
                                        })(value);
                                        if (!parsedVariant.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: "dynamicVoid" as const, ...parsedVariant.value } };
                                      }
                                      case "channel": {
                                        const parsedVariant = ((
                                          value: unknown,
                                        ):
                                          | { success: false }
                                          | {
                                              success: true;
                                              value: {
                                                exclude: boolean;
                                                source: TagSourceType;
                                                tagKind: TagKindChannel;
                                                value: string;
                                              };
                                            } => {
                                          if (typeof value !== "object") {
                                            return { success: false };
                                          }
                                          if (value === null) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "exclude")) {
                                            return { success: false };
                                          }
                                          const parsed_exclude = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                              if (typeof value !== "boolean") {
                                                return { success: false };
                                              }
                                              return { success: true, value: value };
                                            })(value["exclude"]);
                                          })();
                                          if (!parsed_exclude.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "source")) {
                                            return { success: false };
                                          }
                                          const parsed_source = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                                if (typeof value !== "string") {
                                                  return { success: false };
                                                }
                                                switch (value) {
                                                  case "USER":
                                                    return { success: true, value: "USER" };
                                                  case "CHANNEL_HIERARCHY":
                                                    return { success: true, value: "CHANNEL_HIERARCHY" };
                                                  case "AI":
                                                    return { success: true, value: "AI" };
                                                  default:
                                                    return { success: false };
                                                }
                                              })(value);
                                            })(value["source"]);
                                          })();
                                          if (!parsed_source.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "tagKind")) {
                                            return { success: false };
                                          }
                                          const parsed_tagKind = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: TagKindChannel } => {
                                              return ((
                                                value: unknown,
                                              ):
                                                | { success: false }
                                                | {
                                                    success: true;
                                                    value: {
                                                      discriminator: KindChannel;
                                                      id: bigint;
                                                      kind: KindChannel;
                                                    };
                                                  } => {
                                                if (typeof value !== "object") {
                                                  return { success: false };
                                                }
                                                if (value === null) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "discriminator")) {
                                                  return { success: false };
                                                }
                                                const parsed_discriminator = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                                                    return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                                      if (typeof value !== "string") {
                                                        return { success: false };
                                                      }
                                                      switch (value) {
                                                        case "channel":
                                                          return { success: true, value: "channel" };
                                                        default:
                                                          return { success: false };
                                                      }
                                                    })(value);
                                                  })(value["discriminator"]);
                                                })();
                                                if (!parsed_discriminator.success) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "id")) {
                                                  return { success: false };
                                                }
                                                const parsed_id = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                                    if (typeof value === "number") {
                                                      return { success: true, value: BigInt(value) };
                                                    }
                                                    if (typeof value === "bigint") {
                                                      return { success: true, value: value };
                                                    }
                                                    return { success: false };
                                                  })(value["id"]);
                                                })();
                                                if (!parsed_id.success) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "kind")) {
                                                  return { success: false };
                                                }
                                                const parsed_kind = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                                                    return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                                      if (typeof value !== "string") {
                                                        return { success: false };
                                                      }
                                                      switch (value) {
                                                        case "channel":
                                                          return { success: true, value: "channel" };
                                                        default:
                                                          return { success: false };
                                                      }
                                                    })(value);
                                                  })(value["kind"]);
                                                })();
                                                if (!parsed_kind.success) {
                                                  return { success: false };
                                                }
                                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                              })(value);
                                            })(value["tagKind"]);
                                          })();
                                          if (!parsed_tagKind.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "value")) {
                                            return { success: false };
                                          }
                                          const parsed_value = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              return { success: true, value: value };
                                            })(value["value"]);
                                          })();
                                          if (!parsed_value.success) {
                                            return { success: false };
                                          }
                                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                        })(value);
                                        if (!parsedVariant.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: "channel" as const, ...parsedVariant.value } };
                                      }
                                      case "key-quality": {
                                        const parsedVariant = ((
                                          value: unknown,
                                        ):
                                          | { success: false }
                                          | {
                                              success: true;
                                              value: {
                                                exclude: boolean;
                                                source: TagSourceType;
                                                tagKind: TagKindKeyQuality;
                                                value: string;
                                              };
                                            } => {
                                          if (typeof value !== "object") {
                                            return { success: false };
                                          }
                                          if (value === null) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "exclude")) {
                                            return { success: false };
                                          }
                                          const parsed_exclude = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                              if (typeof value !== "boolean") {
                                                return { success: false };
                                              }
                                              return { success: true, value: value };
                                            })(value["exclude"]);
                                          })();
                                          if (!parsed_exclude.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "source")) {
                                            return { success: false };
                                          }
                                          const parsed_source = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                                if (typeof value !== "string") {
                                                  return { success: false };
                                                }
                                                switch (value) {
                                                  case "USER":
                                                    return { success: true, value: "USER" };
                                                  case "CHANNEL_HIERARCHY":
                                                    return { success: true, value: "CHANNEL_HIERARCHY" };
                                                  case "AI":
                                                    return { success: true, value: "AI" };
                                                  default:
                                                    return { success: false };
                                                }
                                              })(value);
                                            })(value["source"]);
                                          })();
                                          if (!parsed_source.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "tagKind")) {
                                            return { success: false };
                                          }
                                          const parsed_tagKind = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: TagKindKeyQuality } => {
                                              return ((
                                                value: unknown,
                                              ):
                                                | { success: false }
                                                | {
                                                    success: true;
                                                    value: {
                                                      discriminator: KindKeyQuality;
                                                      id: bigint;
                                                      kind: KindKeyQuality;
                                                    };
                                                  } => {
                                                if (typeof value !== "object") {
                                                  return { success: false };
                                                }
                                                if (value === null) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "discriminator")) {
                                                  return { success: false };
                                                }
                                                const parsed_discriminator = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                                                    return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                                      if (typeof value !== "string") {
                                                        return { success: false };
                                                      }
                                                      switch (value) {
                                                        case "key-quality":
                                                          return { success: true, value: "key-quality" };
                                                        default:
                                                          return { success: false };
                                                      }
                                                    })(value);
                                                  })(value["discriminator"]);
                                                })();
                                                if (!parsed_discriminator.success) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "id")) {
                                                  return { success: false };
                                                }
                                                const parsed_id = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                                    if (typeof value === "number") {
                                                      return { success: true, value: BigInt(value) };
                                                    }
                                                    if (typeof value === "bigint") {
                                                      return { success: true, value: value };
                                                    }
                                                    return { success: false };
                                                  })(value["id"]);
                                                })();
                                                if (!parsed_id.success) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "kind")) {
                                                  return { success: false };
                                                }
                                                const parsed_kind = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                                                    return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                                      if (typeof value !== "string") {
                                                        return { success: false };
                                                      }
                                                      switch (value) {
                                                        case "key-quality":
                                                          return { success: true, value: "key-quality" };
                                                        default:
                                                          return { success: false };
                                                      }
                                                    })(value);
                                                  })(value["kind"]);
                                                })();
                                                if (!parsed_kind.success) {
                                                  return { success: false };
                                                }
                                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                              })(value);
                                            })(value["tagKind"]);
                                          })();
                                          if (!parsed_tagKind.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "value")) {
                                            return { success: false };
                                          }
                                          const parsed_value = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              return { success: true, value: value };
                                            })(value["value"]);
                                          })();
                                          if (!parsed_value.success) {
                                            return { success: false };
                                          }
                                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                        })(value);
                                        if (!parsedVariant.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: "key-quality" as const, ...parsedVariant.value } };
                                      }
                                      case "classification": {
                                        const parsedVariant = ((
                                          value: unknown,
                                        ):
                                          | { success: false }
                                          | {
                                              success: true;
                                              value: {
                                                exclude: boolean;
                                                source: TagSourceType;
                                                tagKind: TagKindClassification;
                                                value: string;
                                              };
                                            } => {
                                          if (typeof value !== "object") {
                                            return { success: false };
                                          }
                                          if (value === null) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "exclude")) {
                                            return { success: false };
                                          }
                                          const parsed_exclude = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                              if (typeof value !== "boolean") {
                                                return { success: false };
                                              }
                                              return { success: true, value: value };
                                            })(value["exclude"]);
                                          })();
                                          if (!parsed_exclude.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "source")) {
                                            return { success: false };
                                          }
                                          const parsed_source = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                                if (typeof value !== "string") {
                                                  return { success: false };
                                                }
                                                switch (value) {
                                                  case "USER":
                                                    return { success: true, value: "USER" };
                                                  case "CHANNEL_HIERARCHY":
                                                    return { success: true, value: "CHANNEL_HIERARCHY" };
                                                  case "AI":
                                                    return { success: true, value: "AI" };
                                                  default:
                                                    return { success: false };
                                                }
                                              })(value);
                                            })(value["source"]);
                                          })();
                                          if (!parsed_source.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "tagKind")) {
                                            return { success: false };
                                          }
                                          const parsed_tagKind = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: TagKindClassification } => {
                                              return ((
                                                value: unknown,
                                              ):
                                                | { success: false }
                                                | {
                                                    success: true;
                                                    value: {
                                                      discriminator: KindClassification;
                                                      id: bigint;
                                                      kind: KindClassification;
                                                    };
                                                  } => {
                                                if (typeof value !== "object") {
                                                  return { success: false };
                                                }
                                                if (value === null) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "discriminator")) {
                                                  return { success: false };
                                                }
                                                const parsed_discriminator = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                                                    return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                                      if (typeof value !== "string") {
                                                        return { success: false };
                                                      }
                                                      switch (value) {
                                                        case "classification":
                                                          return { success: true, value: "classification" };
                                                        default:
                                                          return { success: false };
                                                      }
                                                    })(value);
                                                  })(value["discriminator"]);
                                                })();
                                                if (!parsed_discriminator.success) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "id")) {
                                                  return { success: false };
                                                }
                                                const parsed_id = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                                    if (typeof value === "number") {
                                                      return { success: true, value: BigInt(value) };
                                                    }
                                                    if (typeof value === "bigint") {
                                                      return { success: true, value: value };
                                                    }
                                                    return { success: false };
                                                  })(value["id"]);
                                                })();
                                                if (!parsed_id.success) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "kind")) {
                                                  return { success: false };
                                                }
                                                const parsed_kind = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                                                    return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                                      if (typeof value !== "string") {
                                                        return { success: false };
                                                      }
                                                      switch (value) {
                                                        case "classification":
                                                          return { success: true, value: "classification" };
                                                        default:
                                                          return { success: false };
                                                      }
                                                    })(value);
                                                  })(value["kind"]);
                                                })();
                                                if (!parsed_kind.success) {
                                                  return { success: false };
                                                }
                                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                              })(value);
                                            })(value["tagKind"]);
                                          })();
                                          if (!parsed_tagKind.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "value")) {
                                            return { success: false };
                                          }
                                          const parsed_value = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              return { success: true, value: value };
                                            })(value["value"]);
                                          })();
                                          if (!parsed_value.success) {
                                            return { success: false };
                                          }
                                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                        })(value);
                                        if (!parsedVariant.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: "classification" as const, ...parsedVariant.value } };
                                      }
                                      default:
                                        return { success: false };
                                    }
                                  })(value);
                                })(value);
                              });
                              if (parsed.find((value) => !value.success) !== undefined) {
                                return { success: false };
                              }
                              return {
                                success: true,
                                value: parsed.map((entry) => {
                                  assert(entry.success == true);
                                  return entry.value;
                                }),
                              };
                            })(value["tags"]);
                          })();
                          if (!parsed_tags.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "title")) {
                            return { success: false };
                          }
                          const parsed_title = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["title"]);
                          })();
                          if (!parsed_title.success) {
                            return { success: false };
                          }
                          return {
                            success: true,
                            value: {
                              activatedAt: parsed_activatedAt.value,
                              active: parsed_active.value,
                              createdAt: parsed_createdAt.value,
                              deactivatedAt: parsed_deactivatedAt.value,
                              id: parsed_id.value,
                              language: parsed_language.value,
                              manager: parsed_manager.value,
                              tags: parsed_tags.value,
                              title: parsed_title.value,
                            },
                          };
                        })(value);
                      })(value["metadata"]);
                    })();
                    if (!parsed_metadata.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "publisher")) {
                      return { success: false };
                    }
                    const parsed_publisher = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                        if (value === null) {
                          return { success: true, value: null };
                        }
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["publisher"]);
                    })();
                    if (!parsed_publisher.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "sourceType")) {
                      return { success: false };
                    }
                    const parsed_sourceType = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: SummarySourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "BOOK":
                              return { success: true, value: "BOOK" };
                            case "VIDEO":
                              return { success: true, value: "VIDEO" };
                            case "ARTICLE":
                              return { success: true, value: "ARTICLE" };
                            case "CHAPTER":
                              return { success: true, value: "CHAPTER" };
                            case "REPORT":
                              return { success: true, value: "REPORT" };
                            case "PODCAST":
                              return { success: true, value: "PODCAST" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["sourceType"]);
                    })();
                    if (!parsed_sourceType.success) {
                      return { success: false };
                    }
                    return {
                      success: true,
                      value: { audioActive: parsed_audioActive.value, documentType: parsed_documentType.value, metadata: parsed_metadata.value, publisher: parsed_publisher.value, sourceType: parsed_sourceType.value },
                    };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { learningItemType: "SUMMARY" as const, ...parsedVariant.value } };
                }
                default:
                  return { success: false };
              }
            })(value);
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["items"]);
    })();
    if (!parsed_items.success) {
      return { success: false };
    }
    if (!hasProp(value, "totalCount")) {
      return { success: false };
    }
    const parsed_totalCount = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["totalCount"]);
    })();
    if (!parsed_totalCount.success) {
      return { success: false };
    }
    return { success: true, value: { items: parsed_items.value, totalCount: parsed_totalCount.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// FormFilter
export function serialize_FormFilter(obj: FormFilter): string {
  const serialized = ((value: { activeValues: string[]; label: string; name: string; options: FormFilterOption[]; priority: FormFilterPriority; type: FormFilterType }): unknown => {
    return {
      activeValues: ((value: string[]): unknown => {
        return value.map((value: string): unknown => {
          return value;
        });
      })(value["activeValues"]),
      label: ((value: string): unknown => {
        return value;
      })(value["label"]),
      name: ((value: string): unknown => {
        return value;
      })(value["name"]),
      options: ((value: FormFilterOption[]): unknown => {
        return value.map((value: { label: string; shortLabel: string | null; value: string }): unknown => {
          return {
            label: ((value: string): unknown => {
              return value;
            })(value["label"]),
            shortLabel: ((value: string | null): unknown => {
              if (value === null) {
                return null;
              }
              return value;
            })(value["shortLabel"]),
            value: ((value: string): unknown => {
              return value;
            })(value["value"]),
          };
        });
      })(value["options"]),
      priority: ((value: "PRIMARY" | "SECONDARY"): unknown => {
        return value;
      })(value["priority"]),
      type: ((value: "MULTICHECKBOX" | "CHECKBOX" | "MULTISELECT" | "RANGE" | "RANGE_MIN"): unknown => {
        return value;
      })(value["type"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_FormFilter(str: string): FormFilter | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          activeValues: string[];
          label: string;
          name: string;
          options: FormFilterOption[];
          priority: FormFilterPriority;
          type: FormFilterType;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "activeValues")) {
      return { success: false };
    }
    const parsed_activeValues = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          return { success: true, value: value };
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["activeValues"]);
    })();
    if (!parsed_activeValues.success) {
      return { success: false };
    }
    if (!hasProp(value, "label")) {
      return { success: false };
    }
    const parsed_label = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["label"]);
    })();
    if (!parsed_label.success) {
      return { success: false };
    }
    if (!hasProp(value, "name")) {
      return { success: false };
    }
    const parsed_name = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["name"]);
    })();
    if (!parsed_name.success) {
      return { success: false };
    }
    if (!hasProp(value, "options")) {
      return { success: false };
    }
    const parsed_options = (() => {
      return ((value: unknown): { success: false } | { success: true; value: FormFilterOption[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: FormFilterOption } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  label: string;
                  shortLabel: string | null;
                  value: string;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "label")) {
              return { success: false };
            }
            const parsed_label = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["label"]);
            })();
            if (!parsed_label.success) {
              return { success: false };
            }
            if (!hasProp(value, "shortLabel")) {
              return { success: false };
            }
            const parsed_shortLabel = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["shortLabel"]);
            })();
            if (!parsed_shortLabel.success) {
              return { success: false };
            }
            if (!hasProp(value, "value")) {
              return { success: false };
            }
            const parsed_value = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["value"]);
            })();
            if (!parsed_value.success) {
              return { success: false };
            }
            return { success: true, value: { label: parsed_label.value, shortLabel: parsed_shortLabel.value, value: parsed_value.value } };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["options"]);
    })();
    if (!parsed_options.success) {
      return { success: false };
    }
    if (!hasProp(value, "priority")) {
      return { success: false };
    }
    const parsed_priority = (() => {
      return ((value: unknown): { success: false } | { success: true; value: FormFilterPriority } => {
        return ((value: unknown): { success: false } | { success: true; value: "PRIMARY" | "SECONDARY" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "PRIMARY":
              return { success: true, value: "PRIMARY" };
            case "SECONDARY":
              return { success: true, value: "SECONDARY" };
            default:
              return { success: false };
          }
        })(value);
      })(value["priority"]);
    })();
    if (!parsed_priority.success) {
      return { success: false };
    }
    if (!hasProp(value, "type")) {
      return { success: false };
    }
    const parsed_type = (() => {
      return ((value: unknown): { success: false } | { success: true; value: FormFilterType } => {
        return ((value: unknown): { success: false } | { success: true; value: "MULTICHECKBOX" | "CHECKBOX" | "MULTISELECT" | "RANGE" | "RANGE_MIN" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "MULTICHECKBOX":
              return { success: true, value: "MULTICHECKBOX" };
            case "CHECKBOX":
              return { success: true, value: "CHECKBOX" };
            case "MULTISELECT":
              return { success: true, value: "MULTISELECT" };
            case "RANGE":
              return { success: true, value: "RANGE" };
            case "RANGE_MIN":
              return { success: true, value: "RANGE_MIN" };
            default:
              return { success: false };
          }
        })(value);
      })(value["type"]);
    })();
    if (!parsed_type.success) {
      return { success: false };
    }
    return { success: true, value: { activeValues: parsed_activeValues.value, label: parsed_label.value, name: parsed_name.value, options: parsed_options.value, priority: parsed_priority.value, type: parsed_type.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// FormFilterOption
export function serialize_FormFilterOption(obj: FormFilterOption): string {
  const serialized = ((value: { label: string; shortLabel: string | null; value: string }): unknown => {
    return {
      label: ((value: string): unknown => {
        return value;
      })(value["label"]),
      shortLabel: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["shortLabel"]),
      value: ((value: string): unknown => {
        return value;
      })(value["value"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_FormFilterOption(str: string): FormFilterOption | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          label: string;
          shortLabel: string | null;
          value: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "label")) {
      return { success: false };
    }
    const parsed_label = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["label"]);
    })();
    if (!parsed_label.success) {
      return { success: false };
    }
    if (!hasProp(value, "shortLabel")) {
      return { success: false };
    }
    const parsed_shortLabel = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["shortLabel"]);
    })();
    if (!parsed_shortLabel.success) {
      return { success: false };
    }
    if (!hasProp(value, "value")) {
      return { success: false };
    }
    const parsed_value = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["value"]);
    })();
    if (!parsed_value.success) {
      return { success: false };
    }
    return { success: true, value: { label: parsed_label.value, shortLabel: parsed_shortLabel.value, value: parsed_value.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// FormFilterPriority
export function serialize_FormFilterPriority(obj: FormFilterPriority): string {
  const serialized = ((value: "PRIMARY" | "SECONDARY"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_FormFilterPriority(str: string): FormFilterPriority | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "PRIMARY" | "SECONDARY" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "PRIMARY":
        return { success: true, value: "PRIMARY" };
      case "SECONDARY":
        return { success: true, value: "SECONDARY" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// FormFilterType
export function serialize_FormFilterType(obj: FormFilterType): string {
  const serialized = ((value: "MULTICHECKBOX" | "CHECKBOX" | "MULTISELECT" | "RANGE" | "RANGE_MIN"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_FormFilterType(str: string): FormFilterType | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "MULTICHECKBOX" | "CHECKBOX" | "MULTISELECT" | "RANGE" | "RANGE_MIN" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "MULTICHECKBOX":
        return { success: true, value: "MULTICHECKBOX" };
      case "CHECKBOX":
        return { success: true, value: "CHECKBOX" };
      case "MULTISELECT":
        return { success: true, value: "MULTISELECT" };
      case "RANGE":
        return { success: true, value: "RANGE" };
      case "RANGE_MIN":
        return { success: true, value: "RANGE_MIN" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// FrontendProject
export function serialize_FrontendProject(obj: FrontendProject): string {
  const serialized = ((value: "VUE" | "DESIGN"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_FrontendProject(str: string): FrontendProject | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "VUE" | "DESIGN" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "VUE":
        return { success: true, value: "VUE" };
      case "DESIGN":
        return { success: true, value: "DESIGN" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// FullSearchProps
export function serialize_FullSearchProps(obj: FullSearchProps): string {
  const serialized = ((value: { initialFormFilters: FormFilter[]; query: string | null }): unknown => {
    return {
      initialFormFilters: ((value: FormFilter[]): unknown => {
        return value.map((value: { activeValues: string[]; label: string; name: string; options: FormFilterOption[]; priority: FormFilterPriority; type: FormFilterType }): unknown => {
          return {
            activeValues: ((value: string[]): unknown => {
              return value.map((value: string): unknown => {
                return value;
              });
            })(value["activeValues"]),
            label: ((value: string): unknown => {
              return value;
            })(value["label"]),
            name: ((value: string): unknown => {
              return value;
            })(value["name"]),
            options: ((value: FormFilterOption[]): unknown => {
              return value.map((value: { label: string; shortLabel: string | null; value: string }): unknown => {
                return {
                  label: ((value: string): unknown => {
                    return value;
                  })(value["label"]),
                  shortLabel: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["shortLabel"]),
                  value: ((value: string): unknown => {
                    return value;
                  })(value["value"]),
                };
              });
            })(value["options"]),
            priority: ((value: "PRIMARY" | "SECONDARY"): unknown => {
              return value;
            })(value["priority"]),
            type: ((value: "MULTICHECKBOX" | "CHECKBOX" | "MULTISELECT" | "RANGE" | "RANGE_MIN"): unknown => {
              return value;
            })(value["type"]),
          };
        });
      })(value["initialFormFilters"]),
      query: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["query"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_FullSearchProps(str: string): FullSearchProps | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          initialFormFilters: FormFilter[];
          query: string | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "initialFormFilters")) {
      return { success: false };
    }
    const parsed_initialFormFilters = (() => {
      return ((value: unknown): { success: false } | { success: true; value: FormFilter[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: FormFilter } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  activeValues: string[];
                  label: string;
                  name: string;
                  options: FormFilterOption[];
                  priority: FormFilterPriority;
                  type: FormFilterType;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "activeValues")) {
              return { success: false };
            }
            const parsed_activeValues = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string[] } => {
                if (!isArray(value)) {
                  return { success: false };
                }
                const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  return { success: true, value: value };
                });
                if (parsed.find((value) => !value.success) !== undefined) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: parsed.map((entry) => {
                    assert(entry.success == true);
                    return entry.value;
                  }),
                };
              })(value["activeValues"]);
            })();
            if (!parsed_activeValues.success) {
              return { success: false };
            }
            if (!hasProp(value, "label")) {
              return { success: false };
            }
            const parsed_label = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["label"]);
            })();
            if (!parsed_label.success) {
              return { success: false };
            }
            if (!hasProp(value, "name")) {
              return { success: false };
            }
            const parsed_name = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["name"]);
            })();
            if (!parsed_name.success) {
              return { success: false };
            }
            if (!hasProp(value, "options")) {
              return { success: false };
            }
            const parsed_options = (() => {
              return ((value: unknown): { success: false } | { success: true; value: FormFilterOption[] } => {
                if (!isArray(value)) {
                  return { success: false };
                }
                const parsed = value.map((value: unknown): { success: false } | { success: true; value: FormFilterOption } => {
                  return ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          label: string;
                          shortLabel: string | null;
                          value: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "label")) {
                      return { success: false };
                    }
                    const parsed_label = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["label"]);
                    })();
                    if (!parsed_label.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "shortLabel")) {
                      return { success: false };
                    }
                    const parsed_shortLabel = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                        if (value === null) {
                          return { success: true, value: null };
                        }
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["shortLabel"]);
                    })();
                    if (!parsed_shortLabel.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { label: parsed_label.value, shortLabel: parsed_shortLabel.value, value: parsed_value.value } };
                  })(value);
                });
                if (parsed.find((value) => !value.success) !== undefined) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: parsed.map((entry) => {
                    assert(entry.success == true);
                    return entry.value;
                  }),
                };
              })(value["options"]);
            })();
            if (!parsed_options.success) {
              return { success: false };
            }
            if (!hasProp(value, "priority")) {
              return { success: false };
            }
            const parsed_priority = (() => {
              return ((value: unknown): { success: false } | { success: true; value: FormFilterPriority } => {
                return ((value: unknown): { success: false } | { success: true; value: "PRIMARY" | "SECONDARY" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "PRIMARY":
                      return { success: true, value: "PRIMARY" };
                    case "SECONDARY":
                      return { success: true, value: "SECONDARY" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["priority"]);
            })();
            if (!parsed_priority.success) {
              return { success: false };
            }
            if (!hasProp(value, "type")) {
              return { success: false };
            }
            const parsed_type = (() => {
              return ((value: unknown): { success: false } | { success: true; value: FormFilterType } => {
                return ((value: unknown): { success: false } | { success: true; value: "MULTICHECKBOX" | "CHECKBOX" | "MULTISELECT" | "RANGE" | "RANGE_MIN" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "MULTICHECKBOX":
                      return { success: true, value: "MULTICHECKBOX" };
                    case "CHECKBOX":
                      return { success: true, value: "CHECKBOX" };
                    case "MULTISELECT":
                      return { success: true, value: "MULTISELECT" };
                    case "RANGE":
                      return { success: true, value: "RANGE" };
                    case "RANGE_MIN":
                      return { success: true, value: "RANGE_MIN" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["type"]);
            })();
            if (!parsed_type.success) {
              return { success: false };
            }
            return { success: true, value: { activeValues: parsed_activeValues.value, label: parsed_label.value, name: parsed_name.value, options: parsed_options.value, priority: parsed_priority.value, type: parsed_type.value } };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["initialFormFilters"]);
    })();
    if (!parsed_initialFormFilters.success) {
      return { success: false };
    }
    if (!hasProp(value, "query")) {
      return { success: false };
    }
    const parsed_query = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["query"]);
    })();
    if (!parsed_query.success) {
      return { success: false };
    }
    return { success: true, value: { initialFormFilters: parsed_initialFormFilters.value, query: parsed_query.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// FullSearchResults
export function serialize_FullSearchResults(obj: FullSearchResults): string {
  const serialized = ((value: {
    actionables: ActionableListView[];
    channels: ChannelListView[];
    customPages: CustomPageListView[];
    formFilters: FormFilter[];
    summaries: SummaryMetadata[];
    summariesPaging: {
      page: number;
      totalCount: bigint;
    };
  }): unknown => {
    return {
      actionables: ((value: ActionableListView[]): unknown => {
        return value.map(
          (value: {
            actionableId: bigint;
            activatedAt: DateTime<true> | null;
            active: boolean;
            coverUri: string;
            description: string;
            language: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr";
            modifiedAt: DateTime<true>;
            name: string;
            title: string;
          }): unknown => {
            return {
              actionableId: ((value: bigint): unknown => {
                return value;
              })(value["actionableId"]),
              activatedAt: ((value: DateTime<true> | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value.toMillis();
              })(value["activatedAt"]),
              active: ((value: boolean): unknown => {
                return value;
              })(value["active"]),
              coverUri: ((value: string): unknown => {
                return value;
              })(value["coverUri"]),
              description: ((value: string): unknown => {
                return value;
              })(value["description"]),
              language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                return value;
              })(value["language"]),
              modifiedAt: ((value: DateTime<true>): unknown => {
                return value.toMillis();
              })(value["modifiedAt"]),
              name: ((value: string): unknown => {
                return value;
              })(value["name"]),
              title: ((value: string): unknown => {
                return value;
              })(value["title"]),
            };
          },
        );
      })(value["actionables"]),
      channels: ((value: ChannelListView[]): unknown => {
        return value.map(
          (value: {
            activationTime: DateTime<true> | null;
            active: boolean;
            channelId: bigint;
            coverUri: string | null;
            definition: string | null;
            descriptionHtml: string | null;
            modificationTime: DateTime<true> | null;
            name: string | null;
            readingProgress: number;
            synonyms: string | null;
            title: string | null;
          }): unknown => {
            return {
              activationTime: ((value: DateTime<true> | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value.toMillis();
              })(value["activationTime"]),
              active: ((value: boolean): unknown => {
                return value;
              })(value["active"]),
              channelId: ((value: bigint): unknown => {
                return value;
              })(value["channelId"]),
              coverUri: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["coverUri"]),
              definition: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["definition"]),
              descriptionHtml: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["descriptionHtml"]),
              modificationTime: ((value: DateTime<true> | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value.toMillis();
              })(value["modificationTime"]),
              name: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["name"]),
              readingProgress: ((value: number): unknown => {
                return value;
              })(value["readingProgress"]),
              synonyms: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["synonyms"]),
              title: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["title"]),
            };
          },
        );
      })(value["channels"]),
      customPages: ((value: CustomPageListView[]): unknown => {
        return value.map((value: { corporateId: number; coverUri: string; customPageId: bigint; description: string | null; title: string }): unknown => {
          return {
            corporateId: ((value: number): unknown => {
              return value;
            })(value["corporateId"]),
            coverUri: ((value: string): unknown => {
              return value;
            })(value["coverUri"]),
            customPageId: ((value: bigint): unknown => {
              return value;
            })(value["customPageId"]),
            description: ((value: string | null): unknown => {
              if (value === null) {
                return null;
              }
              return value;
            })(value["description"]),
            title: ((value: string): unknown => {
              return value;
            })(value["title"]),
          };
        });
      })(value["customPages"]),
      formFilters: ((value: FormFilter[]): unknown => {
        return value.map((value: { activeValues: string[]; label: string; name: string; options: FormFilterOption[]; priority: FormFilterPriority; type: FormFilterType }): unknown => {
          return {
            activeValues: ((value: string[]): unknown => {
              return value.map((value: string): unknown => {
                return value;
              });
            })(value["activeValues"]),
            label: ((value: string): unknown => {
              return value;
            })(value["label"]),
            name: ((value: string): unknown => {
              return value;
            })(value["name"]),
            options: ((value: FormFilterOption[]): unknown => {
              return value.map((value: { label: string; shortLabel: string | null; value: string }): unknown => {
                return {
                  label: ((value: string): unknown => {
                    return value;
                  })(value["label"]),
                  shortLabel: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["shortLabel"]),
                  value: ((value: string): unknown => {
                    return value;
                  })(value["value"]),
                };
              });
            })(value["options"]),
            priority: ((value: "PRIMARY" | "SECONDARY"): unknown => {
              return value;
            })(value["priority"]),
            type: ((value: "MULTICHECKBOX" | "CHECKBOX" | "MULTISELECT" | "RANGE" | "RANGE_MIN"): unknown => {
              return value;
            })(value["type"]),
          };
        });
      })(value["formFilters"]),
      summaries: ((value: SummaryMetadata[]): unknown => {
        return value.map(
          (value: {
            activatedAt: DateTime<true> | null;
            activationTime: DateTime<true> | null;
            active: boolean;
            audioActivatedAt: DateTime<true> | null;
            audioActive: boolean;
            audioDuration: number;
            authorInfo: string | null;
            authors: {
              authorId: number;
              firstName: string | null;
              gender: ("MALE" | "FEMALE" | "CUSTOM" | "UNKNOWN") | null;
              lastName: string | null;
              middleName: string | null;
            }[];
            bestseller: boolean;
            businessClassic: boolean;
            canonicalName: string;
            contentItemId: bigint;
            contentItemType: "UNKNOWN" | "SUMMARY" | "CATEGORY" | "DISCUSSION" | "CHANNEL" | "LGXP" | "ACTIONABLE" | "MYLIST" | "CUSTOMPAGE";
            countriesExcluded: Country[];
            countriesIncluded: Country[];
            coverModifiedAt: DateTime<true> | null;
            coverUri: string;
            dataId: number;
            descriptionHtml: string | null;
            documentType: "ABSTRACT" | "REVIEW";
            duration: number;
            imageBorder: boolean;
            language: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr";
            modifiedAt: DateTime<true> | null;
            originalTitle: string | null;
            previewAvailable: boolean;
            publicationDate: string | null;
            publisherInfo: string | null;
            publisherName: string | null;
            rating: number | null;
            sourceType: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST";
            subtitle: string | null;
            summaryPages: number;
            summaryWords: number;
            tags: Tag[];
            teaser: string | null;
            title: string | null;
          }): unknown => {
            return {
              activatedAt: ((value: DateTime<true> | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value.toMillis();
              })(value["activatedAt"]),
              activationTime: ((value: DateTime<true> | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value.toMillis();
              })(value["activationTime"]),
              active: ((value: boolean): unknown => {
                return value;
              })(value["active"]),
              audioActivatedAt: ((value: DateTime<true> | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value.toMillis();
              })(value["audioActivatedAt"]),
              audioActive: ((value: boolean): unknown => {
                return value;
              })(value["audioActive"]),
              audioDuration: ((value: number): unknown => {
                return value;
              })(value["audioDuration"]),
              authorInfo: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["authorInfo"]),
              authors: ((
                value: {
                  authorId: number;
                  firstName: string | null;
                  gender: ("MALE" | "FEMALE" | "CUSTOM" | "UNKNOWN") | null;
                  lastName: string | null;
                  middleName: string | null;
                }[],
              ): unknown => {
                return value.map((value: { authorId: number; firstName: string | null; gender: ("MALE" | "FEMALE" | "CUSTOM" | "UNKNOWN") | null; lastName: string | null; middleName: string | null }): unknown => {
                  return {
                    authorId: ((value: number): unknown => {
                      return value;
                    })(value["authorId"]),
                    firstName: ((value: string | null): unknown => {
                      if (value === null) {
                        return null;
                      }
                      return value;
                    })(value["firstName"]),
                    gender: ((value: ("MALE" | "FEMALE" | "CUSTOM" | "UNKNOWN") | null): unknown => {
                      if (value === null) {
                        return null;
                      }
                      return value;
                    })(value["gender"]),
                    lastName: ((value: string | null): unknown => {
                      if (value === null) {
                        return null;
                      }
                      return value;
                    })(value["lastName"]),
                    middleName: ((value: string | null): unknown => {
                      if (value === null) {
                        return null;
                      }
                      return value;
                    })(value["middleName"]),
                  };
                });
              })(value["authors"]),
              bestseller: ((value: boolean): unknown => {
                return value;
              })(value["bestseller"]),
              businessClassic: ((value: boolean): unknown => {
                return value;
              })(value["businessClassic"]),
              canonicalName: ((value: string): unknown => {
                return value;
              })(value["canonicalName"]),
              contentItemId: ((value: bigint): unknown => {
                return value;
              })(value["contentItemId"]),
              contentItemType: ((value: "UNKNOWN" | "SUMMARY" | "CATEGORY" | "DISCUSSION" | "CHANNEL" | "LGXP" | "ACTIONABLE" | "MYLIST" | "CUSTOMPAGE"): unknown => {
                return value;
              })(value["contentItemType"]),
              countriesExcluded: ((value: Country[]): unknown => {
                return value.map(
                  (value: {
                    countryCode: string;
                    currency: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY";
                    names: {
                      de: string;
                      en: string;
                      es: string;
                      fr: string;
                      pt: string;
                      ru: string;
                      zh: string;
                    };
                    phonePrefix: string;
                    region: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA";
                  }): unknown => {
                    return {
                      countryCode: ((value: string): unknown => {
                        return value;
                      })(value["countryCode"]),
                      currency: ((value: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY"): unknown => {
                        return value;
                      })(value["currency"]),
                      names: ((value: { de: string; en: string; es: string; fr: string; pt: string; ru: string; zh: string }): unknown => {
                        return {
                          de: ((value: string): unknown => {
                            return value;
                          })(value["de"]),
                          en: ((value: string): unknown => {
                            return value;
                          })(value["en"]),
                          es: ((value: string): unknown => {
                            return value;
                          })(value["es"]),
                          fr: ((value: string): unknown => {
                            return value;
                          })(value["fr"]),
                          pt: ((value: string): unknown => {
                            return value;
                          })(value["pt"]),
                          ru: ((value: string): unknown => {
                            return value;
                          })(value["ru"]),
                          zh: ((value: string): unknown => {
                            return value;
                          })(value["zh"]),
                        };
                      })(value["names"]),
                      phonePrefix: ((value: string): unknown => {
                        return value;
                      })(value["phonePrefix"]),
                      region: ((value: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA"): unknown => {
                        return value;
                      })(value["region"]),
                    };
                  },
                );
              })(value["countriesExcluded"]),
              countriesIncluded: ((value: Country[]): unknown => {
                return value.map(
                  (value: {
                    countryCode: string;
                    currency: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY";
                    names: {
                      de: string;
                      en: string;
                      es: string;
                      fr: string;
                      pt: string;
                      ru: string;
                      zh: string;
                    };
                    phonePrefix: string;
                    region: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA";
                  }): unknown => {
                    return {
                      countryCode: ((value: string): unknown => {
                        return value;
                      })(value["countryCode"]),
                      currency: ((value: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY"): unknown => {
                        return value;
                      })(value["currency"]),
                      names: ((value: { de: string; en: string; es: string; fr: string; pt: string; ru: string; zh: string }): unknown => {
                        return {
                          de: ((value: string): unknown => {
                            return value;
                          })(value["de"]),
                          en: ((value: string): unknown => {
                            return value;
                          })(value["en"]),
                          es: ((value: string): unknown => {
                            return value;
                          })(value["es"]),
                          fr: ((value: string): unknown => {
                            return value;
                          })(value["fr"]),
                          pt: ((value: string): unknown => {
                            return value;
                          })(value["pt"]),
                          ru: ((value: string): unknown => {
                            return value;
                          })(value["ru"]),
                          zh: ((value: string): unknown => {
                            return value;
                          })(value["zh"]),
                        };
                      })(value["names"]),
                      phonePrefix: ((value: string): unknown => {
                        return value;
                      })(value["phonePrefix"]),
                      region: ((value: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA"): unknown => {
                        return value;
                      })(value["region"]),
                    };
                  },
                );
              })(value["countriesIncluded"]),
              coverModifiedAt: ((value: DateTime<true> | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value.toMillis();
              })(value["coverModifiedAt"]),
              coverUri: ((value: string): unknown => {
                return value;
              })(value["coverUri"]),
              dataId: ((value: number): unknown => {
                return value;
              })(value["dataId"]),
              descriptionHtml: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["descriptionHtml"]),
              documentType: ((value: "ABSTRACT" | "REVIEW"): unknown => {
                return value;
              })(value["documentType"]),
              duration: ((value: number): unknown => {
                return value;
              })(value["duration"]),
              imageBorder: ((value: boolean): unknown => {
                return value;
              })(value["imageBorder"]),
              language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                return value;
              })(value["language"]),
              modifiedAt: ((value: DateTime<true> | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value.toMillis();
              })(value["modifiedAt"]),
              originalTitle: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["originalTitle"]),
              previewAvailable: ((value: boolean): unknown => {
                return value;
              })(value["previewAvailable"]),
              publicationDate: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["publicationDate"]),
              publisherInfo: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["publisherInfo"]),
              publisherName: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["publisherName"]),
              rating: ((value: number | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["rating"]),
              sourceType: ((value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST"): unknown => {
                return value;
              })(value["sourceType"]),
              subtitle: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["subtitle"]),
              summaryPages: ((value: number): unknown => {
                return value;
              })(value["summaryPages"]),
              summaryWords: ((value: number): unknown => {
                return value;
              })(value["summaryWords"]),
              tags: ((value: Tag[]): unknown => {
                return value.map(
                  (
                    value:
                      | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                      | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                      | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                      | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                      | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                      | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                      | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string },
                  ): unknown => {
                    switch (value.discriminator) {
                      case "dynamicString": {
                        return {
                          discriminator: "dynamicString",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindDynamicString; id: bigint; kind: string }): unknown => {
                            return {
                              discriminator: ((value: "dynamicString"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: string): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: string): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                      case "dynamicBoolean": {
                        return {
                          discriminator: "dynamicBoolean",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindDynamicBoolean; id: bigint; kind: string }): unknown => {
                            return {
                              discriminator: ((value: "dynamicBoolean"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: string): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: boolean): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                      case "dynamicNumber": {
                        return {
                          discriminator: "dynamicNumber",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindDynamicNumber; id: bigint; kind: string }): unknown => {
                            return {
                              discriminator: ((value: "dynamicNumber"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: string): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: number): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                      case "dynamicVoid": {
                        return {
                          discriminator: "dynamicVoid",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindDynamicVoid; id: bigint; kind: string }): unknown => {
                            return {
                              discriminator: ((value: "dynamicVoid"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: string): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                        };
                      }
                      case "channel": {
                        return {
                          discriminator: "channel",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindChannel; id: bigint; kind: KindChannel }): unknown => {
                            return {
                              discriminator: ((value: "channel"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: "channel"): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: string): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                      case "key-quality": {
                        return {
                          discriminator: "key-quality",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindKeyQuality; id: bigint; kind: KindKeyQuality }): unknown => {
                            return {
                              discriminator: ((value: "key-quality"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: "key-quality"): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: string): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                      case "classification": {
                        return {
                          discriminator: "classification",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindClassification; id: bigint; kind: KindClassification }): unknown => {
                            return {
                              discriminator: ((value: "classification"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: "classification"): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: string): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                    }
                  },
                );
              })(value["tags"]),
              teaser: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["teaser"]),
              title: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["title"]),
            };
          },
        );
      })(value["summaries"]),
      summariesPaging: ((value: { page: number; totalCount: bigint }): unknown => {
        return {
          page: ((value: number): unknown => {
            return value;
          })(value["page"]),
          totalCount: ((value: bigint): unknown => {
            return value;
          })(value["totalCount"]),
        };
      })(value["summariesPaging"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_FullSearchResults(str: string): FullSearchResults | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          actionables: ActionableListView[];
          channels: ChannelListView[];
          customPages: CustomPageListView[];
          formFilters: FormFilter[];
          summaries: SummaryMetadata[];
          summariesPaging: {
            page: number;
            totalCount: bigint;
          };
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "actionables")) {
      return { success: false };
    }
    const parsed_actionables = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ActionableListView[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: ActionableListView } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  actionableId: bigint;
                  activatedAt: DateTime<true> | null;
                  active: boolean;
                  coverUri: string;
                  description: string;
                  language: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr";
                  modifiedAt: DateTime<true>;
                  name: string;
                  title: string;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "actionableId")) {
              return { success: false };
            }
            const parsed_actionableId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                if (typeof value === "number") {
                  return { success: true, value: BigInt(value) };
                }
                if (typeof value === "bigint") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["actionableId"]);
            })();
            if (!parsed_actionableId.success) {
              return { success: false };
            }
            if (!hasProp(value, "activatedAt")) {
              return { success: false };
            }
            const parsed_activatedAt = (() => {
              return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "number") {
                  return { success: false };
                }
                const parsed = DateTime.fromMillis(value);
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: parsed };
              })(value["activatedAt"]);
            })();
            if (!parsed_activatedAt.success) {
              return { success: false };
            }
            if (!hasProp(value, "active")) {
              return { success: false };
            }
            const parsed_active = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["active"]);
            })();
            if (!parsed_active.success) {
              return { success: false };
            }
            if (!hasProp(value, "coverUri")) {
              return { success: false };
            }
            const parsed_coverUri = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["coverUri"]);
            })();
            if (!parsed_coverUri.success) {
              return { success: false };
            }
            if (!hasProp(value, "description")) {
              return { success: false };
            }
            const parsed_description = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["description"]);
            })();
            if (!parsed_description.success) {
              return { success: false };
            }
            if (!hasProp(value, "language")) {
              return { success: false };
            }
            const parsed_language = (() => {
              return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "en":
                    return { success: true, value: "en" };
                  case "de":
                    return { success: true, value: "de" };
                  case "es":
                    return { success: true, value: "es" };
                  case "ru":
                    return { success: true, value: "ru" };
                  case "zh":
                    return { success: true, value: "zh" };
                  case "pt":
                    return { success: true, value: "pt" };
                  case "fr":
                    return { success: true, value: "fr" };
                  default:
                    return { success: false };
                }
              })(value["language"]);
            })();
            if (!parsed_language.success) {
              return { success: false };
            }
            if (!hasProp(value, "modifiedAt")) {
              return { success: false };
            }
            const parsed_modifiedAt = (() => {
              return ((value: unknown): { success: false } | { success: true; value: DateTime<true> } => {
                if (typeof value !== "number") {
                  return { success: false };
                }
                const parsed = DateTime.fromMillis(value);
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: parsed };
              })(value["modifiedAt"]);
            })();
            if (!parsed_modifiedAt.success) {
              return { success: false };
            }
            if (!hasProp(value, "name")) {
              return { success: false };
            }
            const parsed_name = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["name"]);
            })();
            if (!parsed_name.success) {
              return { success: false };
            }
            if (!hasProp(value, "title")) {
              return { success: false };
            }
            const parsed_title = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["title"]);
            })();
            if (!parsed_title.success) {
              return { success: false };
            }
            return {
              success: true,
              value: {
                actionableId: parsed_actionableId.value,
                activatedAt: parsed_activatedAt.value,
                active: parsed_active.value,
                coverUri: parsed_coverUri.value,
                description: parsed_description.value,
                language: parsed_language.value,
                modifiedAt: parsed_modifiedAt.value,
                name: parsed_name.value,
                title: parsed_title.value,
              },
            };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["actionables"]);
    })();
    if (!parsed_actionables.success) {
      return { success: false };
    }
    if (!hasProp(value, "channels")) {
      return { success: false };
    }
    const parsed_channels = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ChannelListView[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: ChannelListView } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  activationTime: DateTime<true> | null;
                  active: boolean;
                  channelId: bigint;
                  coverUri: string | null;
                  definition: string | null;
                  descriptionHtml: string | null;
                  modificationTime: DateTime<true> | null;
                  name: string | null;
                  readingProgress: number;
                  synonyms: string | null;
                  title: string | null;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "activationTime")) {
              return { success: false };
            }
            const parsed_activationTime = (() => {
              return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "number") {
                  return { success: false };
                }
                const parsed = DateTime.fromMillis(value);
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: parsed };
              })(value["activationTime"]);
            })();
            if (!parsed_activationTime.success) {
              return { success: false };
            }
            if (!hasProp(value, "active")) {
              return { success: false };
            }
            const parsed_active = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["active"]);
            })();
            if (!parsed_active.success) {
              return { success: false };
            }
            if (!hasProp(value, "channelId")) {
              return { success: false };
            }
            const parsed_channelId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                if (typeof value === "number") {
                  return { success: true, value: BigInt(value) };
                }
                if (typeof value === "bigint") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["channelId"]);
            })();
            if (!parsed_channelId.success) {
              return { success: false };
            }
            if (!hasProp(value, "coverUri")) {
              return { success: false };
            }
            const parsed_coverUri = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["coverUri"]);
            })();
            if (!parsed_coverUri.success) {
              return { success: false };
            }
            if (!hasProp(value, "definition")) {
              return { success: false };
            }
            const parsed_definition = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["definition"]);
            })();
            if (!parsed_definition.success) {
              return { success: false };
            }
            if (!hasProp(value, "descriptionHtml")) {
              return { success: false };
            }
            const parsed_descriptionHtml = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["descriptionHtml"]);
            })();
            if (!parsed_descriptionHtml.success) {
              return { success: false };
            }
            if (!hasProp(value, "modificationTime")) {
              return { success: false };
            }
            const parsed_modificationTime = (() => {
              return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "number") {
                  return { success: false };
                }
                const parsed = DateTime.fromMillis(value);
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: parsed };
              })(value["modificationTime"]);
            })();
            if (!parsed_modificationTime.success) {
              return { success: false };
            }
            if (!hasProp(value, "name")) {
              return { success: false };
            }
            const parsed_name = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["name"]);
            })();
            if (!parsed_name.success) {
              return { success: false };
            }
            if (!hasProp(value, "readingProgress")) {
              return { success: false };
            }
            const parsed_readingProgress = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                if (typeof value === "bigint") {
                  return { success: true, value: Number(value) };
                }
                return { success: false };
              })(value["readingProgress"]);
            })();
            if (!parsed_readingProgress.success) {
              return { success: false };
            }
            if (!hasProp(value, "synonyms")) {
              return { success: false };
            }
            const parsed_synonyms = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["synonyms"]);
            })();
            if (!parsed_synonyms.success) {
              return { success: false };
            }
            if (!hasProp(value, "title")) {
              return { success: false };
            }
            const parsed_title = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["title"]);
            })();
            if (!parsed_title.success) {
              return { success: false };
            }
            return {
              success: true,
              value: {
                activationTime: parsed_activationTime.value,
                active: parsed_active.value,
                channelId: parsed_channelId.value,
                coverUri: parsed_coverUri.value,
                definition: parsed_definition.value,
                descriptionHtml: parsed_descriptionHtml.value,
                modificationTime: parsed_modificationTime.value,
                name: parsed_name.value,
                readingProgress: parsed_readingProgress.value,
                synonyms: parsed_synonyms.value,
                title: parsed_title.value,
              },
            };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["channels"]);
    })();
    if (!parsed_channels.success) {
      return { success: false };
    }
    if (!hasProp(value, "customPages")) {
      return { success: false };
    }
    const parsed_customPages = (() => {
      return ((value: unknown): { success: false } | { success: true; value: CustomPageListView[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: CustomPageListView } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  corporateId: number;
                  coverUri: string;
                  customPageId: bigint;
                  description: string | null;
                  title: string;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "corporateId")) {
              return { success: false };
            }
            const parsed_corporateId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["corporateId"]);
            })();
            if (!parsed_corporateId.success) {
              return { success: false };
            }
            if (!hasProp(value, "coverUri")) {
              return { success: false };
            }
            const parsed_coverUri = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["coverUri"]);
            })();
            if (!parsed_coverUri.success) {
              return { success: false };
            }
            if (!hasProp(value, "customPageId")) {
              return { success: false };
            }
            const parsed_customPageId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                if (typeof value === "number") {
                  return { success: true, value: BigInt(value) };
                }
                if (typeof value === "bigint") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["customPageId"]);
            })();
            if (!parsed_customPageId.success) {
              return { success: false };
            }
            if (!hasProp(value, "description")) {
              return { success: false };
            }
            const parsed_description = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["description"]);
            })();
            if (!parsed_description.success) {
              return { success: false };
            }
            if (!hasProp(value, "title")) {
              return { success: false };
            }
            const parsed_title = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["title"]);
            })();
            if (!parsed_title.success) {
              return { success: false };
            }
            return { success: true, value: { corporateId: parsed_corporateId.value, coverUri: parsed_coverUri.value, customPageId: parsed_customPageId.value, description: parsed_description.value, title: parsed_title.value } };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["customPages"]);
    })();
    if (!parsed_customPages.success) {
      return { success: false };
    }
    if (!hasProp(value, "formFilters")) {
      return { success: false };
    }
    const parsed_formFilters = (() => {
      return ((value: unknown): { success: false } | { success: true; value: FormFilter[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: FormFilter } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  activeValues: string[];
                  label: string;
                  name: string;
                  options: FormFilterOption[];
                  priority: FormFilterPriority;
                  type: FormFilterType;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "activeValues")) {
              return { success: false };
            }
            const parsed_activeValues = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string[] } => {
                if (!isArray(value)) {
                  return { success: false };
                }
                const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  return { success: true, value: value };
                });
                if (parsed.find((value) => !value.success) !== undefined) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: parsed.map((entry) => {
                    assert(entry.success == true);
                    return entry.value;
                  }),
                };
              })(value["activeValues"]);
            })();
            if (!parsed_activeValues.success) {
              return { success: false };
            }
            if (!hasProp(value, "label")) {
              return { success: false };
            }
            const parsed_label = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["label"]);
            })();
            if (!parsed_label.success) {
              return { success: false };
            }
            if (!hasProp(value, "name")) {
              return { success: false };
            }
            const parsed_name = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["name"]);
            })();
            if (!parsed_name.success) {
              return { success: false };
            }
            if (!hasProp(value, "options")) {
              return { success: false };
            }
            const parsed_options = (() => {
              return ((value: unknown): { success: false } | { success: true; value: FormFilterOption[] } => {
                if (!isArray(value)) {
                  return { success: false };
                }
                const parsed = value.map((value: unknown): { success: false } | { success: true; value: FormFilterOption } => {
                  return ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          label: string;
                          shortLabel: string | null;
                          value: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "label")) {
                      return { success: false };
                    }
                    const parsed_label = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["label"]);
                    })();
                    if (!parsed_label.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "shortLabel")) {
                      return { success: false };
                    }
                    const parsed_shortLabel = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                        if (value === null) {
                          return { success: true, value: null };
                        }
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["shortLabel"]);
                    })();
                    if (!parsed_shortLabel.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { label: parsed_label.value, shortLabel: parsed_shortLabel.value, value: parsed_value.value } };
                  })(value);
                });
                if (parsed.find((value) => !value.success) !== undefined) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: parsed.map((entry) => {
                    assert(entry.success == true);
                    return entry.value;
                  }),
                };
              })(value["options"]);
            })();
            if (!parsed_options.success) {
              return { success: false };
            }
            if (!hasProp(value, "priority")) {
              return { success: false };
            }
            const parsed_priority = (() => {
              return ((value: unknown): { success: false } | { success: true; value: FormFilterPriority } => {
                return ((value: unknown): { success: false } | { success: true; value: "PRIMARY" | "SECONDARY" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "PRIMARY":
                      return { success: true, value: "PRIMARY" };
                    case "SECONDARY":
                      return { success: true, value: "SECONDARY" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["priority"]);
            })();
            if (!parsed_priority.success) {
              return { success: false };
            }
            if (!hasProp(value, "type")) {
              return { success: false };
            }
            const parsed_type = (() => {
              return ((value: unknown): { success: false } | { success: true; value: FormFilterType } => {
                return ((value: unknown): { success: false } | { success: true; value: "MULTICHECKBOX" | "CHECKBOX" | "MULTISELECT" | "RANGE" | "RANGE_MIN" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "MULTICHECKBOX":
                      return { success: true, value: "MULTICHECKBOX" };
                    case "CHECKBOX":
                      return { success: true, value: "CHECKBOX" };
                    case "MULTISELECT":
                      return { success: true, value: "MULTISELECT" };
                    case "RANGE":
                      return { success: true, value: "RANGE" };
                    case "RANGE_MIN":
                      return { success: true, value: "RANGE_MIN" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["type"]);
            })();
            if (!parsed_type.success) {
              return { success: false };
            }
            return { success: true, value: { activeValues: parsed_activeValues.value, label: parsed_label.value, name: parsed_name.value, options: parsed_options.value, priority: parsed_priority.value, type: parsed_type.value } };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["formFilters"]);
    })();
    if (!parsed_formFilters.success) {
      return { success: false };
    }
    if (!hasProp(value, "summaries")) {
      return { success: false };
    }
    const parsed_summaries = (() => {
      return ((value: unknown): { success: false } | { success: true; value: SummaryMetadata[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: SummaryMetadata } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  activatedAt: DateTime<true> | null;
                  activationTime: DateTime<true> | null;
                  active: boolean;
                  audioActivatedAt: DateTime<true> | null;
                  audioActive: boolean;
                  audioDuration: number;
                  authorInfo: string | null;
                  authors: {
                    authorId: number;
                    firstName: string | null;
                    gender: ("MALE" | "FEMALE" | "CUSTOM" | "UNKNOWN") | null;
                    lastName: string | null;
                    middleName: string | null;
                  }[];
                  bestseller: boolean;
                  businessClassic: boolean;
                  canonicalName: string;
                  contentItemId: bigint;
                  contentItemType: "UNKNOWN" | "SUMMARY" | "CATEGORY" | "DISCUSSION" | "CHANNEL" | "LGXP" | "ACTIONABLE" | "MYLIST" | "CUSTOMPAGE";
                  countriesExcluded: Country[];
                  countriesIncluded: Country[];
                  coverModifiedAt: DateTime<true> | null;
                  coverUri: string;
                  dataId: number;
                  descriptionHtml: string | null;
                  documentType: "ABSTRACT" | "REVIEW";
                  duration: number;
                  imageBorder: boolean;
                  language: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr";
                  modifiedAt: DateTime<true> | null;
                  originalTitle: string | null;
                  previewAvailable: boolean;
                  publicationDate: string | null;
                  publisherInfo: string | null;
                  publisherName: string | null;
                  rating: number | null;
                  sourceType: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST";
                  subtitle: string | null;
                  summaryPages: number;
                  summaryWords: number;
                  tags: Tag[];
                  teaser: string | null;
                  title: string | null;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "activatedAt")) {
              return { success: false };
            }
            const parsed_activatedAt = (() => {
              return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "number") {
                  return { success: false };
                }
                const parsed = DateTime.fromMillis(value);
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: parsed };
              })(value["activatedAt"]);
            })();
            if (!parsed_activatedAt.success) {
              return { success: false };
            }
            if (!hasProp(value, "activationTime")) {
              return { success: false };
            }
            const parsed_activationTime = (() => {
              return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "number") {
                  return { success: false };
                }
                const parsed = DateTime.fromMillis(value);
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: parsed };
              })(value["activationTime"]);
            })();
            if (!parsed_activationTime.success) {
              return { success: false };
            }
            if (!hasProp(value, "active")) {
              return { success: false };
            }
            const parsed_active = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["active"]);
            })();
            if (!parsed_active.success) {
              return { success: false };
            }
            if (!hasProp(value, "audioActivatedAt")) {
              return { success: false };
            }
            const parsed_audioActivatedAt = (() => {
              return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "number") {
                  return { success: false };
                }
                const parsed = DateTime.fromMillis(value);
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: parsed };
              })(value["audioActivatedAt"]);
            })();
            if (!parsed_audioActivatedAt.success) {
              return { success: false };
            }
            if (!hasProp(value, "audioActive")) {
              return { success: false };
            }
            const parsed_audioActive = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["audioActive"]);
            })();
            if (!parsed_audioActive.success) {
              return { success: false };
            }
            if (!hasProp(value, "audioDuration")) {
              return { success: false };
            }
            const parsed_audioDuration = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["audioDuration"]);
            })();
            if (!parsed_audioDuration.success) {
              return { success: false };
            }
            if (!hasProp(value, "authorInfo")) {
              return { success: false };
            }
            const parsed_authorInfo = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["authorInfo"]);
            })();
            if (!parsed_authorInfo.success) {
              return { success: false };
            }
            if (!hasProp(value, "authors")) {
              return { success: false };
            }
            const parsed_authors = (() => {
              return ((
                value: unknown,
              ):
                | { success: false }
                | {
                    success: true;
                    value: {
                      authorId: number;
                      firstName: string | null;
                      gender: ("MALE" | "FEMALE" | "CUSTOM" | "UNKNOWN") | null;
                      lastName: string | null;
                      middleName: string | null;
                    }[];
                  } => {
                if (!isArray(value)) {
                  return { success: false };
                }
                const parsed = value.map(
                  (
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          authorId: number;
                          firstName: string | null;
                          gender: ("MALE" | "FEMALE" | "CUSTOM" | "UNKNOWN") | null;
                          lastName: string | null;
                          middleName: string | null;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "authorId")) {
                      return { success: false };
                    }
                    const parsed_authorId = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: number } => {
                        if (typeof value === "number") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["authorId"]);
                    })();
                    if (!parsed_authorId.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "firstName")) {
                      return { success: false };
                    }
                    const parsed_firstName = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                        if (value === null) {
                          return { success: true, value: null };
                        }
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["firstName"]);
                    })();
                    if (!parsed_firstName.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "gender")) {
                      return { success: false };
                    }
                    const parsed_gender = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: ("MALE" | "FEMALE" | "CUSTOM" | "UNKNOWN") | null } => {
                        if (value === null) {
                          return { success: true, value: null };
                        }
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "MALE":
                            return { success: true, value: "MALE" };
                          case "FEMALE":
                            return { success: true, value: "FEMALE" };
                          case "CUSTOM":
                            return { success: true, value: "CUSTOM" };
                          case "UNKNOWN":
                            return { success: true, value: "UNKNOWN" };
                          default:
                            return { success: false };
                        }
                      })(value["gender"]);
                    })();
                    if (!parsed_gender.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "lastName")) {
                      return { success: false };
                    }
                    const parsed_lastName = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                        if (value === null) {
                          return { success: true, value: null };
                        }
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["lastName"]);
                    })();
                    if (!parsed_lastName.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "middleName")) {
                      return { success: false };
                    }
                    const parsed_middleName = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                        if (value === null) {
                          return { success: true, value: null };
                        }
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["middleName"]);
                    })();
                    if (!parsed_middleName.success) {
                      return { success: false };
                    }
                    return { success: true, value: { authorId: parsed_authorId.value, firstName: parsed_firstName.value, gender: parsed_gender.value, lastName: parsed_lastName.value, middleName: parsed_middleName.value } };
                  },
                );
                if (parsed.find((value) => !value.success) !== undefined) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: parsed.map((entry) => {
                    assert(entry.success == true);
                    return entry.value;
                  }),
                };
              })(value["authors"]);
            })();
            if (!parsed_authors.success) {
              return { success: false };
            }
            if (!hasProp(value, "bestseller")) {
              return { success: false };
            }
            const parsed_bestseller = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["bestseller"]);
            })();
            if (!parsed_bestseller.success) {
              return { success: false };
            }
            if (!hasProp(value, "businessClassic")) {
              return { success: false };
            }
            const parsed_businessClassic = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["businessClassic"]);
            })();
            if (!parsed_businessClassic.success) {
              return { success: false };
            }
            if (!hasProp(value, "canonicalName")) {
              return { success: false };
            }
            const parsed_canonicalName = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["canonicalName"]);
            })();
            if (!parsed_canonicalName.success) {
              return { success: false };
            }
            if (!hasProp(value, "contentItemId")) {
              return { success: false };
            }
            const parsed_contentItemId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                if (typeof value === "number") {
                  return { success: true, value: BigInt(value) };
                }
                if (typeof value === "bigint") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["contentItemId"]);
            })();
            if (!parsed_contentItemId.success) {
              return { success: false };
            }
            if (!hasProp(value, "contentItemType")) {
              return { success: false };
            }
            const parsed_contentItemType = (() => {
              return ((value: unknown): { success: false } | { success: true; value: "UNKNOWN" | "SUMMARY" | "CATEGORY" | "DISCUSSION" | "CHANNEL" | "LGXP" | "ACTIONABLE" | "MYLIST" | "CUSTOMPAGE" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "UNKNOWN":
                    return { success: true, value: "UNKNOWN" };
                  case "SUMMARY":
                    return { success: true, value: "SUMMARY" };
                  case "CATEGORY":
                    return { success: true, value: "CATEGORY" };
                  case "DISCUSSION":
                    return { success: true, value: "DISCUSSION" };
                  case "CHANNEL":
                    return { success: true, value: "CHANNEL" };
                  case "LGXP":
                    return { success: true, value: "LGXP" };
                  case "ACTIONABLE":
                    return { success: true, value: "ACTIONABLE" };
                  case "MYLIST":
                    return { success: true, value: "MYLIST" };
                  case "CUSTOMPAGE":
                    return { success: true, value: "CUSTOMPAGE" };
                  default:
                    return { success: false };
                }
              })(value["contentItemType"]);
            })();
            if (!parsed_contentItemType.success) {
              return { success: false };
            }
            if (!hasProp(value, "countriesExcluded")) {
              return { success: false };
            }
            const parsed_countriesExcluded = (() => {
              return ((value: unknown): { success: false } | { success: true; value: Country[] } => {
                if (!isArray(value)) {
                  return { success: false };
                }
                const parsed = value.map((value: unknown): { success: false } | { success: true; value: Country } => {
                  return ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          countryCode: string;
                          currency: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY";
                          names: {
                            de: string;
                            en: string;
                            es: string;
                            fr: string;
                            pt: string;
                            ru: string;
                            zh: string;
                          };
                          phonePrefix: string;
                          region: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA";
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "countryCode")) {
                      return { success: false };
                    }
                    const parsed_countryCode = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["countryCode"]);
                    })();
                    if (!parsed_countryCode.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "currency")) {
                      return { success: false };
                    }
                    const parsed_currency = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "CHF":
                            return { success: true, value: "CHF" };
                          case "CNY":
                            return { success: true, value: "CNY" };
                          case "USD":
                            return { success: true, value: "USD" };
                          case "EUR":
                            return { success: true, value: "EUR" };
                          case "GBP":
                            return { success: true, value: "GBP" };
                          case "INR":
                            return { success: true, value: "INR" };
                          case "JPY":
                            return { success: true, value: "JPY" };
                          default:
                            return { success: false };
                        }
                      })(value["currency"]);
                    })();
                    if (!parsed_currency.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "names")) {
                      return { success: false };
                    }
                    const parsed_names = (() => {
                      return ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              de: string;
                              en: string;
                              es: string;
                              fr: string;
                              pt: string;
                              ru: string;
                              zh: string;
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "de")) {
                          return { success: false };
                        }
                        const parsed_de = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["de"]);
                        })();
                        if (!parsed_de.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "en")) {
                          return { success: false };
                        }
                        const parsed_en = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["en"]);
                        })();
                        if (!parsed_en.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "es")) {
                          return { success: false };
                        }
                        const parsed_es = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["es"]);
                        })();
                        if (!parsed_es.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "fr")) {
                          return { success: false };
                        }
                        const parsed_fr = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["fr"]);
                        })();
                        if (!parsed_fr.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "pt")) {
                          return { success: false };
                        }
                        const parsed_pt = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["pt"]);
                        })();
                        if (!parsed_pt.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "ru")) {
                          return { success: false };
                        }
                        const parsed_ru = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["ru"]);
                        })();
                        if (!parsed_ru.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "zh")) {
                          return { success: false };
                        }
                        const parsed_zh = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["zh"]);
                        })();
                        if (!parsed_zh.success) {
                          return { success: false };
                        }
                        return { success: true, value: { de: parsed_de.value, en: parsed_en.value, es: parsed_es.value, fr: parsed_fr.value, pt: parsed_pt.value, ru: parsed_ru.value, zh: parsed_zh.value } };
                      })(value["names"]);
                    })();
                    if (!parsed_names.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "phonePrefix")) {
                      return { success: false };
                    }
                    const parsed_phonePrefix = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["phonePrefix"]);
                    })();
                    if (!parsed_phonePrefix.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "region")) {
                      return { success: false };
                    }
                    const parsed_region = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "SOUTHAMERICA":
                            return { success: true, value: "SOUTHAMERICA" };
                          case "EUROPE":
                            return { success: true, value: "EUROPE" };
                          case "OCEANIA":
                            return { success: true, value: "OCEANIA" };
                          case "NORTHAMERICA":
                            return { success: true, value: "NORTHAMERICA" };
                          case "AFRICA":
                            return { success: true, value: "AFRICA" };
                          case "ANTARCTICA":
                            return { success: true, value: "ANTARCTICA" };
                          case "ASIA":
                            return { success: true, value: "ASIA" };
                          default:
                            return { success: false };
                        }
                      })(value["region"]);
                    })();
                    if (!parsed_region.success) {
                      return { success: false };
                    }
                    return { success: true, value: { countryCode: parsed_countryCode.value, currency: parsed_currency.value, names: parsed_names.value, phonePrefix: parsed_phonePrefix.value, region: parsed_region.value } };
                  })(value);
                });
                if (parsed.find((value) => !value.success) !== undefined) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: parsed.map((entry) => {
                    assert(entry.success == true);
                    return entry.value;
                  }),
                };
              })(value["countriesExcluded"]);
            })();
            if (!parsed_countriesExcluded.success) {
              return { success: false };
            }
            if (!hasProp(value, "countriesIncluded")) {
              return { success: false };
            }
            const parsed_countriesIncluded = (() => {
              return ((value: unknown): { success: false } | { success: true; value: Country[] } => {
                if (!isArray(value)) {
                  return { success: false };
                }
                const parsed = value.map((value: unknown): { success: false } | { success: true; value: Country } => {
                  return ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          countryCode: string;
                          currency: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY";
                          names: {
                            de: string;
                            en: string;
                            es: string;
                            fr: string;
                            pt: string;
                            ru: string;
                            zh: string;
                          };
                          phonePrefix: string;
                          region: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA";
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "countryCode")) {
                      return { success: false };
                    }
                    const parsed_countryCode = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["countryCode"]);
                    })();
                    if (!parsed_countryCode.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "currency")) {
                      return { success: false };
                    }
                    const parsed_currency = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "CHF":
                            return { success: true, value: "CHF" };
                          case "CNY":
                            return { success: true, value: "CNY" };
                          case "USD":
                            return { success: true, value: "USD" };
                          case "EUR":
                            return { success: true, value: "EUR" };
                          case "GBP":
                            return { success: true, value: "GBP" };
                          case "INR":
                            return { success: true, value: "INR" };
                          case "JPY":
                            return { success: true, value: "JPY" };
                          default:
                            return { success: false };
                        }
                      })(value["currency"]);
                    })();
                    if (!parsed_currency.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "names")) {
                      return { success: false };
                    }
                    const parsed_names = (() => {
                      return ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              de: string;
                              en: string;
                              es: string;
                              fr: string;
                              pt: string;
                              ru: string;
                              zh: string;
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "de")) {
                          return { success: false };
                        }
                        const parsed_de = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["de"]);
                        })();
                        if (!parsed_de.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "en")) {
                          return { success: false };
                        }
                        const parsed_en = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["en"]);
                        })();
                        if (!parsed_en.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "es")) {
                          return { success: false };
                        }
                        const parsed_es = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["es"]);
                        })();
                        if (!parsed_es.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "fr")) {
                          return { success: false };
                        }
                        const parsed_fr = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["fr"]);
                        })();
                        if (!parsed_fr.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "pt")) {
                          return { success: false };
                        }
                        const parsed_pt = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["pt"]);
                        })();
                        if (!parsed_pt.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "ru")) {
                          return { success: false };
                        }
                        const parsed_ru = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["ru"]);
                        })();
                        if (!parsed_ru.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "zh")) {
                          return { success: false };
                        }
                        const parsed_zh = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["zh"]);
                        })();
                        if (!parsed_zh.success) {
                          return { success: false };
                        }
                        return { success: true, value: { de: parsed_de.value, en: parsed_en.value, es: parsed_es.value, fr: parsed_fr.value, pt: parsed_pt.value, ru: parsed_ru.value, zh: parsed_zh.value } };
                      })(value["names"]);
                    })();
                    if (!parsed_names.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "phonePrefix")) {
                      return { success: false };
                    }
                    const parsed_phonePrefix = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["phonePrefix"]);
                    })();
                    if (!parsed_phonePrefix.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "region")) {
                      return { success: false };
                    }
                    const parsed_region = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "SOUTHAMERICA":
                            return { success: true, value: "SOUTHAMERICA" };
                          case "EUROPE":
                            return { success: true, value: "EUROPE" };
                          case "OCEANIA":
                            return { success: true, value: "OCEANIA" };
                          case "NORTHAMERICA":
                            return { success: true, value: "NORTHAMERICA" };
                          case "AFRICA":
                            return { success: true, value: "AFRICA" };
                          case "ANTARCTICA":
                            return { success: true, value: "ANTARCTICA" };
                          case "ASIA":
                            return { success: true, value: "ASIA" };
                          default:
                            return { success: false };
                        }
                      })(value["region"]);
                    })();
                    if (!parsed_region.success) {
                      return { success: false };
                    }
                    return { success: true, value: { countryCode: parsed_countryCode.value, currency: parsed_currency.value, names: parsed_names.value, phonePrefix: parsed_phonePrefix.value, region: parsed_region.value } };
                  })(value);
                });
                if (parsed.find((value) => !value.success) !== undefined) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: parsed.map((entry) => {
                    assert(entry.success == true);
                    return entry.value;
                  }),
                };
              })(value["countriesIncluded"]);
            })();
            if (!parsed_countriesIncluded.success) {
              return { success: false };
            }
            if (!hasProp(value, "coverModifiedAt")) {
              return { success: false };
            }
            const parsed_coverModifiedAt = (() => {
              return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "number") {
                  return { success: false };
                }
                const parsed = DateTime.fromMillis(value);
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: parsed };
              })(value["coverModifiedAt"]);
            })();
            if (!parsed_coverModifiedAt.success) {
              return { success: false };
            }
            if (!hasProp(value, "coverUri")) {
              return { success: false };
            }
            const parsed_coverUri = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["coverUri"]);
            })();
            if (!parsed_coverUri.success) {
              return { success: false };
            }
            if (!hasProp(value, "dataId")) {
              return { success: false };
            }
            const parsed_dataId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["dataId"]);
            })();
            if (!parsed_dataId.success) {
              return { success: false };
            }
            if (!hasProp(value, "descriptionHtml")) {
              return { success: false };
            }
            const parsed_descriptionHtml = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["descriptionHtml"]);
            })();
            if (!parsed_descriptionHtml.success) {
              return { success: false };
            }
            if (!hasProp(value, "documentType")) {
              return { success: false };
            }
            const parsed_documentType = (() => {
              return ((value: unknown): { success: false } | { success: true; value: "ABSTRACT" | "REVIEW" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "ABSTRACT":
                    return { success: true, value: "ABSTRACT" };
                  case "REVIEW":
                    return { success: true, value: "REVIEW" };
                  default:
                    return { success: false };
                }
              })(value["documentType"]);
            })();
            if (!parsed_documentType.success) {
              return { success: false };
            }
            if (!hasProp(value, "duration")) {
              return { success: false };
            }
            const parsed_duration = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["duration"]);
            })();
            if (!parsed_duration.success) {
              return { success: false };
            }
            if (!hasProp(value, "imageBorder")) {
              return { success: false };
            }
            const parsed_imageBorder = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["imageBorder"]);
            })();
            if (!parsed_imageBorder.success) {
              return { success: false };
            }
            if (!hasProp(value, "language")) {
              return { success: false };
            }
            const parsed_language = (() => {
              return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "en":
                    return { success: true, value: "en" };
                  case "de":
                    return { success: true, value: "de" };
                  case "es":
                    return { success: true, value: "es" };
                  case "ru":
                    return { success: true, value: "ru" };
                  case "zh":
                    return { success: true, value: "zh" };
                  case "pt":
                    return { success: true, value: "pt" };
                  case "fr":
                    return { success: true, value: "fr" };
                  default:
                    return { success: false };
                }
              })(value["language"]);
            })();
            if (!parsed_language.success) {
              return { success: false };
            }
            if (!hasProp(value, "modifiedAt")) {
              return { success: false };
            }
            const parsed_modifiedAt = (() => {
              return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "number") {
                  return { success: false };
                }
                const parsed = DateTime.fromMillis(value);
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: parsed };
              })(value["modifiedAt"]);
            })();
            if (!parsed_modifiedAt.success) {
              return { success: false };
            }
            if (!hasProp(value, "originalTitle")) {
              return { success: false };
            }
            const parsed_originalTitle = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["originalTitle"]);
            })();
            if (!parsed_originalTitle.success) {
              return { success: false };
            }
            if (!hasProp(value, "previewAvailable")) {
              return { success: false };
            }
            const parsed_previewAvailable = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["previewAvailable"]);
            })();
            if (!parsed_previewAvailable.success) {
              return { success: false };
            }
            if (!hasProp(value, "publicationDate")) {
              return { success: false };
            }
            const parsed_publicationDate = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["publicationDate"]);
            })();
            if (!parsed_publicationDate.success) {
              return { success: false };
            }
            if (!hasProp(value, "publisherInfo")) {
              return { success: false };
            }
            const parsed_publisherInfo = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["publisherInfo"]);
            })();
            if (!parsed_publisherInfo.success) {
              return { success: false };
            }
            if (!hasProp(value, "publisherName")) {
              return { success: false };
            }
            const parsed_publisherName = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["publisherName"]);
            })();
            if (!parsed_publisherName.success) {
              return { success: false };
            }
            if (!hasProp(value, "rating")) {
              return { success: false };
            }
            const parsed_rating = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["rating"]);
            })();
            if (!parsed_rating.success) {
              return { success: false };
            }
            if (!hasProp(value, "sourceType")) {
              return { success: false };
            }
            const parsed_sourceType = (() => {
              return ((value: unknown): { success: false } | { success: true; value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "BOOK":
                    return { success: true, value: "BOOK" };
                  case "VIDEO":
                    return { success: true, value: "VIDEO" };
                  case "ARTICLE":
                    return { success: true, value: "ARTICLE" };
                  case "CHAPTER":
                    return { success: true, value: "CHAPTER" };
                  case "REPORT":
                    return { success: true, value: "REPORT" };
                  case "PODCAST":
                    return { success: true, value: "PODCAST" };
                  default:
                    return { success: false };
                }
              })(value["sourceType"]);
            })();
            if (!parsed_sourceType.success) {
              return { success: false };
            }
            if (!hasProp(value, "subtitle")) {
              return { success: false };
            }
            const parsed_subtitle = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["subtitle"]);
            })();
            if (!parsed_subtitle.success) {
              return { success: false };
            }
            if (!hasProp(value, "summaryPages")) {
              return { success: false };
            }
            const parsed_summaryPages = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["summaryPages"]);
            })();
            if (!parsed_summaryPages.success) {
              return { success: false };
            }
            if (!hasProp(value, "summaryWords")) {
              return { success: false };
            }
            const parsed_summaryWords = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["summaryWords"]);
            })();
            if (!parsed_summaryWords.success) {
              return { success: false };
            }
            if (!hasProp(value, "tags")) {
              return { success: false };
            }
            const parsed_tags = (() => {
              return ((value: unknown): { success: false } | { success: true; value: Tag[] } => {
                if (!isArray(value)) {
                  return { success: false };
                }
                const parsed = value.map((value: unknown): { success: false } | { success: true; value: Tag } => {
                  return ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value:
                          | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                          | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                          | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                          | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                          | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                          | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                          | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                      } => {
                    return ((
                      value: unknown,
                    ):
                      | { success: false }
                      | {
                          success: true;
                          value:
                            | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                            | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                            | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                            | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                            | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                            | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                            | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                        } => {
                      if (value == null) {
                        return { success: false };
                      }
                      if (typeof value != "object") {
                        return { success: false };
                      }
                      if (!hasProp(value, "discriminator")) {
                        return { success: false };
                      }
                      if (typeof value["discriminator"] != "string") {
                        return { success: false };
                      }
                      const discriminator = value["discriminator"];
                      switch (discriminator) {
                        case "dynamicString": {
                          const parsedVariant = ((
                            value: unknown,
                          ):
                            | { success: false }
                            | {
                                success: true;
                                value: {
                                  exclude: boolean;
                                  source: TagSourceType;
                                  tagKind: TagKindDynamicString;
                                  value: string;
                                };
                              } => {
                            if (typeof value !== "object") {
                              return { success: false };
                            }
                            if (value === null) {
                              return { success: false };
                            }
                            if (!hasProp(value, "exclude")) {
                              return { success: false };
                            }
                            const parsed_exclude = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                if (typeof value !== "boolean") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["exclude"]);
                            })();
                            if (!parsed_exclude.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "source")) {
                              return { success: false };
                            }
                            const parsed_source = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  switch (value) {
                                    case "USER":
                                      return { success: true, value: "USER" };
                                    case "CHANNEL_HIERARCHY":
                                      return { success: true, value: "CHANNEL_HIERARCHY" };
                                    case "AI":
                                      return { success: true, value: "AI" };
                                    default:
                                      return { success: false };
                                  }
                                })(value);
                              })(value["source"]);
                            })();
                            if (!parsed_source.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "tagKind")) {
                              return { success: false };
                            }
                            const parsed_tagKind = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicString } => {
                                return ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        discriminator: KindDynamicString;
                                        id: bigint;
                                        kind: string;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "discriminator")) {
                                    return { success: false };
                                  }
                                  const parsed_discriminator = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindDynamicString } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "dynamicString" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "dynamicString":
                                            return { success: true, value: "dynamicString" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["discriminator"]);
                                  })();
                                  if (!parsed_discriminator.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "id")) {
                                    return { success: false };
                                  }
                                  const parsed_id = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                      if (typeof value === "number") {
                                        return { success: true, value: BigInt(value) };
                                      }
                                      if (typeof value === "bigint") {
                                        return { success: true, value: value };
                                      }
                                      return { success: false };
                                    })(value["id"]);
                                  })();
                                  if (!parsed_id.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "kind")) {
                                    return { success: false };
                                  }
                                  const parsed_kind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["kind"]);
                                  })();
                                  if (!parsed_kind.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                })(value);
                              })(value["tagKind"]);
                            })();
                            if (!parsed_tagKind.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "value")) {
                              return { success: false };
                            }
                            const parsed_value = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: string } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["value"]);
                            })();
                            if (!parsed_value.success) {
                              return { success: false };
                            }
                            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                          })(value);
                          if (!parsedVariant.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: "dynamicString" as const, ...parsedVariant.value } };
                        }
                        case "dynamicBoolean": {
                          const parsedVariant = ((
                            value: unknown,
                          ):
                            | { success: false }
                            | {
                                success: true;
                                value: {
                                  exclude: boolean;
                                  source: TagSourceType;
                                  tagKind: TagKindDynamicBoolean;
                                  value: boolean;
                                };
                              } => {
                            if (typeof value !== "object") {
                              return { success: false };
                            }
                            if (value === null) {
                              return { success: false };
                            }
                            if (!hasProp(value, "exclude")) {
                              return { success: false };
                            }
                            const parsed_exclude = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                if (typeof value !== "boolean") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["exclude"]);
                            })();
                            if (!parsed_exclude.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "source")) {
                              return { success: false };
                            }
                            const parsed_source = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  switch (value) {
                                    case "USER":
                                      return { success: true, value: "USER" };
                                    case "CHANNEL_HIERARCHY":
                                      return { success: true, value: "CHANNEL_HIERARCHY" };
                                    case "AI":
                                      return { success: true, value: "AI" };
                                    default:
                                      return { success: false };
                                  }
                                })(value);
                              })(value["source"]);
                            })();
                            if (!parsed_source.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "tagKind")) {
                              return { success: false };
                            }
                            const parsed_tagKind = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicBoolean } => {
                                return ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        discriminator: KindDynamicBoolean;
                                        id: bigint;
                                        kind: string;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "discriminator")) {
                                    return { success: false };
                                  }
                                  const parsed_discriminator = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindDynamicBoolean } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "dynamicBoolean" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "dynamicBoolean":
                                            return { success: true, value: "dynamicBoolean" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["discriminator"]);
                                  })();
                                  if (!parsed_discriminator.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "id")) {
                                    return { success: false };
                                  }
                                  const parsed_id = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                      if (typeof value === "number") {
                                        return { success: true, value: BigInt(value) };
                                      }
                                      if (typeof value === "bigint") {
                                        return { success: true, value: value };
                                      }
                                      return { success: false };
                                    })(value["id"]);
                                  })();
                                  if (!parsed_id.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "kind")) {
                                    return { success: false };
                                  }
                                  const parsed_kind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["kind"]);
                                  })();
                                  if (!parsed_kind.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                })(value);
                              })(value["tagKind"]);
                            })();
                            if (!parsed_tagKind.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "value")) {
                              return { success: false };
                            }
                            const parsed_value = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                if (typeof value !== "boolean") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["value"]);
                            })();
                            if (!parsed_value.success) {
                              return { success: false };
                            }
                            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                          })(value);
                          if (!parsedVariant.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: "dynamicBoolean" as const, ...parsedVariant.value } };
                        }
                        case "dynamicNumber": {
                          const parsedVariant = ((
                            value: unknown,
                          ):
                            | { success: false }
                            | {
                                success: true;
                                value: {
                                  exclude: boolean;
                                  source: TagSourceType;
                                  tagKind: TagKindDynamicNumber;
                                  value: number;
                                };
                              } => {
                            if (typeof value !== "object") {
                              return { success: false };
                            }
                            if (value === null) {
                              return { success: false };
                            }
                            if (!hasProp(value, "exclude")) {
                              return { success: false };
                            }
                            const parsed_exclude = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                if (typeof value !== "boolean") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["exclude"]);
                            })();
                            if (!parsed_exclude.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "source")) {
                              return { success: false };
                            }
                            const parsed_source = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  switch (value) {
                                    case "USER":
                                      return { success: true, value: "USER" };
                                    case "CHANNEL_HIERARCHY":
                                      return { success: true, value: "CHANNEL_HIERARCHY" };
                                    case "AI":
                                      return { success: true, value: "AI" };
                                    default:
                                      return { success: false };
                                  }
                                })(value);
                              })(value["source"]);
                            })();
                            if (!parsed_source.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "tagKind")) {
                              return { success: false };
                            }
                            const parsed_tagKind = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicNumber } => {
                                return ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        discriminator: KindDynamicNumber;
                                        id: bigint;
                                        kind: string;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "discriminator")) {
                                    return { success: false };
                                  }
                                  const parsed_discriminator = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindDynamicNumber } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "dynamicNumber" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "dynamicNumber":
                                            return { success: true, value: "dynamicNumber" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["discriminator"]);
                                  })();
                                  if (!parsed_discriminator.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "id")) {
                                    return { success: false };
                                  }
                                  const parsed_id = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                      if (typeof value === "number") {
                                        return { success: true, value: BigInt(value) };
                                      }
                                      if (typeof value === "bigint") {
                                        return { success: true, value: value };
                                      }
                                      return { success: false };
                                    })(value["id"]);
                                  })();
                                  if (!parsed_id.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "kind")) {
                                    return { success: false };
                                  }
                                  const parsed_kind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["kind"]);
                                  })();
                                  if (!parsed_kind.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                })(value);
                              })(value["tagKind"]);
                            })();
                            if (!parsed_tagKind.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "value")) {
                              return { success: false };
                            }
                            const parsed_value = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: number } => {
                                if (typeof value === "number") {
                                  return { success: true, value: value };
                                }
                                return { success: false };
                              })(value["value"]);
                            })();
                            if (!parsed_value.success) {
                              return { success: false };
                            }
                            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                          })(value);
                          if (!parsedVariant.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: "dynamicNumber" as const, ...parsedVariant.value } };
                        }
                        case "dynamicVoid": {
                          const parsedVariant = ((
                            value: unknown,
                          ):
                            | { success: false }
                            | {
                                success: true;
                                value: {
                                  exclude: boolean;
                                  source: TagSourceType;
                                  tagKind: TagKindDynamicVoid;
                                };
                              } => {
                            if (typeof value !== "object") {
                              return { success: false };
                            }
                            if (value === null) {
                              return { success: false };
                            }
                            if (!hasProp(value, "exclude")) {
                              return { success: false };
                            }
                            const parsed_exclude = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                if (typeof value !== "boolean") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["exclude"]);
                            })();
                            if (!parsed_exclude.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "source")) {
                              return { success: false };
                            }
                            const parsed_source = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  switch (value) {
                                    case "USER":
                                      return { success: true, value: "USER" };
                                    case "CHANNEL_HIERARCHY":
                                      return { success: true, value: "CHANNEL_HIERARCHY" };
                                    case "AI":
                                      return { success: true, value: "AI" };
                                    default:
                                      return { success: false };
                                  }
                                })(value);
                              })(value["source"]);
                            })();
                            if (!parsed_source.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "tagKind")) {
                              return { success: false };
                            }
                            const parsed_tagKind = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicVoid } => {
                                return ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        discriminator: KindDynamicVoid;
                                        id: bigint;
                                        kind: string;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "discriminator")) {
                                    return { success: false };
                                  }
                                  const parsed_discriminator = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindDynamicVoid } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "dynamicVoid" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "dynamicVoid":
                                            return { success: true, value: "dynamicVoid" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["discriminator"]);
                                  })();
                                  if (!parsed_discriminator.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "id")) {
                                    return { success: false };
                                  }
                                  const parsed_id = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                      if (typeof value === "number") {
                                        return { success: true, value: BigInt(value) };
                                      }
                                      if (typeof value === "bigint") {
                                        return { success: true, value: value };
                                      }
                                      return { success: false };
                                    })(value["id"]);
                                  })();
                                  if (!parsed_id.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "kind")) {
                                    return { success: false };
                                  }
                                  const parsed_kind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["kind"]);
                                  })();
                                  if (!parsed_kind.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                })(value);
                              })(value["tagKind"]);
                            })();
                            if (!parsed_tagKind.success) {
                              return { success: false };
                            }
                            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value } };
                          })(value);
                          if (!parsedVariant.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: "dynamicVoid" as const, ...parsedVariant.value } };
                        }
                        case "channel": {
                          const parsedVariant = ((
                            value: unknown,
                          ):
                            | { success: false }
                            | {
                                success: true;
                                value: {
                                  exclude: boolean;
                                  source: TagSourceType;
                                  tagKind: TagKindChannel;
                                  value: string;
                                };
                              } => {
                            if (typeof value !== "object") {
                              return { success: false };
                            }
                            if (value === null) {
                              return { success: false };
                            }
                            if (!hasProp(value, "exclude")) {
                              return { success: false };
                            }
                            const parsed_exclude = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                if (typeof value !== "boolean") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["exclude"]);
                            })();
                            if (!parsed_exclude.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "source")) {
                              return { success: false };
                            }
                            const parsed_source = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  switch (value) {
                                    case "USER":
                                      return { success: true, value: "USER" };
                                    case "CHANNEL_HIERARCHY":
                                      return { success: true, value: "CHANNEL_HIERARCHY" };
                                    case "AI":
                                      return { success: true, value: "AI" };
                                    default:
                                      return { success: false };
                                  }
                                })(value);
                              })(value["source"]);
                            })();
                            if (!parsed_source.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "tagKind")) {
                              return { success: false };
                            }
                            const parsed_tagKind = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagKindChannel } => {
                                return ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        discriminator: KindChannel;
                                        id: bigint;
                                        kind: KindChannel;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "discriminator")) {
                                    return { success: false };
                                  }
                                  const parsed_discriminator = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "channel":
                                            return { success: true, value: "channel" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["discriminator"]);
                                  })();
                                  if (!parsed_discriminator.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "id")) {
                                    return { success: false };
                                  }
                                  const parsed_id = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                      if (typeof value === "number") {
                                        return { success: true, value: BigInt(value) };
                                      }
                                      if (typeof value === "bigint") {
                                        return { success: true, value: value };
                                      }
                                      return { success: false };
                                    })(value["id"]);
                                  })();
                                  if (!parsed_id.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "kind")) {
                                    return { success: false };
                                  }
                                  const parsed_kind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "channel":
                                            return { success: true, value: "channel" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["kind"]);
                                  })();
                                  if (!parsed_kind.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                })(value);
                              })(value["tagKind"]);
                            })();
                            if (!parsed_tagKind.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "value")) {
                              return { success: false };
                            }
                            const parsed_value = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: string } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["value"]);
                            })();
                            if (!parsed_value.success) {
                              return { success: false };
                            }
                            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                          })(value);
                          if (!parsedVariant.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: "channel" as const, ...parsedVariant.value } };
                        }
                        case "key-quality": {
                          const parsedVariant = ((
                            value: unknown,
                          ):
                            | { success: false }
                            | {
                                success: true;
                                value: {
                                  exclude: boolean;
                                  source: TagSourceType;
                                  tagKind: TagKindKeyQuality;
                                  value: string;
                                };
                              } => {
                            if (typeof value !== "object") {
                              return { success: false };
                            }
                            if (value === null) {
                              return { success: false };
                            }
                            if (!hasProp(value, "exclude")) {
                              return { success: false };
                            }
                            const parsed_exclude = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                if (typeof value !== "boolean") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["exclude"]);
                            })();
                            if (!parsed_exclude.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "source")) {
                              return { success: false };
                            }
                            const parsed_source = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  switch (value) {
                                    case "USER":
                                      return { success: true, value: "USER" };
                                    case "CHANNEL_HIERARCHY":
                                      return { success: true, value: "CHANNEL_HIERARCHY" };
                                    case "AI":
                                      return { success: true, value: "AI" };
                                    default:
                                      return { success: false };
                                  }
                                })(value);
                              })(value["source"]);
                            })();
                            if (!parsed_source.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "tagKind")) {
                              return { success: false };
                            }
                            const parsed_tagKind = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagKindKeyQuality } => {
                                return ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        discriminator: KindKeyQuality;
                                        id: bigint;
                                        kind: KindKeyQuality;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "discriminator")) {
                                    return { success: false };
                                  }
                                  const parsed_discriminator = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "key-quality":
                                            return { success: true, value: "key-quality" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["discriminator"]);
                                  })();
                                  if (!parsed_discriminator.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "id")) {
                                    return { success: false };
                                  }
                                  const parsed_id = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                      if (typeof value === "number") {
                                        return { success: true, value: BigInt(value) };
                                      }
                                      if (typeof value === "bigint") {
                                        return { success: true, value: value };
                                      }
                                      return { success: false };
                                    })(value["id"]);
                                  })();
                                  if (!parsed_id.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "kind")) {
                                    return { success: false };
                                  }
                                  const parsed_kind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "key-quality":
                                            return { success: true, value: "key-quality" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["kind"]);
                                  })();
                                  if (!parsed_kind.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                })(value);
                              })(value["tagKind"]);
                            })();
                            if (!parsed_tagKind.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "value")) {
                              return { success: false };
                            }
                            const parsed_value = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: string } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["value"]);
                            })();
                            if (!parsed_value.success) {
                              return { success: false };
                            }
                            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                          })(value);
                          if (!parsedVariant.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: "key-quality" as const, ...parsedVariant.value } };
                        }
                        case "classification": {
                          const parsedVariant = ((
                            value: unknown,
                          ):
                            | { success: false }
                            | {
                                success: true;
                                value: {
                                  exclude: boolean;
                                  source: TagSourceType;
                                  tagKind: TagKindClassification;
                                  value: string;
                                };
                              } => {
                            if (typeof value !== "object") {
                              return { success: false };
                            }
                            if (value === null) {
                              return { success: false };
                            }
                            if (!hasProp(value, "exclude")) {
                              return { success: false };
                            }
                            const parsed_exclude = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                if (typeof value !== "boolean") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["exclude"]);
                            })();
                            if (!parsed_exclude.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "source")) {
                              return { success: false };
                            }
                            const parsed_source = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  switch (value) {
                                    case "USER":
                                      return { success: true, value: "USER" };
                                    case "CHANNEL_HIERARCHY":
                                      return { success: true, value: "CHANNEL_HIERARCHY" };
                                    case "AI":
                                      return { success: true, value: "AI" };
                                    default:
                                      return { success: false };
                                  }
                                })(value);
                              })(value["source"]);
                            })();
                            if (!parsed_source.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "tagKind")) {
                              return { success: false };
                            }
                            const parsed_tagKind = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagKindClassification } => {
                                return ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        discriminator: KindClassification;
                                        id: bigint;
                                        kind: KindClassification;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "discriminator")) {
                                    return { success: false };
                                  }
                                  const parsed_discriminator = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "classification":
                                            return { success: true, value: "classification" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["discriminator"]);
                                  })();
                                  if (!parsed_discriminator.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "id")) {
                                    return { success: false };
                                  }
                                  const parsed_id = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                      if (typeof value === "number") {
                                        return { success: true, value: BigInt(value) };
                                      }
                                      if (typeof value === "bigint") {
                                        return { success: true, value: value };
                                      }
                                      return { success: false };
                                    })(value["id"]);
                                  })();
                                  if (!parsed_id.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "kind")) {
                                    return { success: false };
                                  }
                                  const parsed_kind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "classification":
                                            return { success: true, value: "classification" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["kind"]);
                                  })();
                                  if (!parsed_kind.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                })(value);
                              })(value["tagKind"]);
                            })();
                            if (!parsed_tagKind.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "value")) {
                              return { success: false };
                            }
                            const parsed_value = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: string } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["value"]);
                            })();
                            if (!parsed_value.success) {
                              return { success: false };
                            }
                            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                          })(value);
                          if (!parsedVariant.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: "classification" as const, ...parsedVariant.value } };
                        }
                        default:
                          return { success: false };
                      }
                    })(value);
                  })(value);
                });
                if (parsed.find((value) => !value.success) !== undefined) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: parsed.map((entry) => {
                    assert(entry.success == true);
                    return entry.value;
                  }),
                };
              })(value["tags"]);
            })();
            if (!parsed_tags.success) {
              return { success: false };
            }
            if (!hasProp(value, "teaser")) {
              return { success: false };
            }
            const parsed_teaser = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["teaser"]);
            })();
            if (!parsed_teaser.success) {
              return { success: false };
            }
            if (!hasProp(value, "title")) {
              return { success: false };
            }
            const parsed_title = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["title"]);
            })();
            if (!parsed_title.success) {
              return { success: false };
            }
            return {
              success: true,
              value: {
                activatedAt: parsed_activatedAt.value,
                activationTime: parsed_activationTime.value,
                active: parsed_active.value,
                audioActivatedAt: parsed_audioActivatedAt.value,
                audioActive: parsed_audioActive.value,
                audioDuration: parsed_audioDuration.value,
                authorInfo: parsed_authorInfo.value,
                authors: parsed_authors.value,
                bestseller: parsed_bestseller.value,
                businessClassic: parsed_businessClassic.value,
                canonicalName: parsed_canonicalName.value,
                contentItemId: parsed_contentItemId.value,
                contentItemType: parsed_contentItemType.value,
                countriesExcluded: parsed_countriesExcluded.value,
                countriesIncluded: parsed_countriesIncluded.value,
                coverModifiedAt: parsed_coverModifiedAt.value,
                coverUri: parsed_coverUri.value,
                dataId: parsed_dataId.value,
                descriptionHtml: parsed_descriptionHtml.value,
                documentType: parsed_documentType.value,
                duration: parsed_duration.value,
                imageBorder: parsed_imageBorder.value,
                language: parsed_language.value,
                modifiedAt: parsed_modifiedAt.value,
                originalTitle: parsed_originalTitle.value,
                previewAvailable: parsed_previewAvailable.value,
                publicationDate: parsed_publicationDate.value,
                publisherInfo: parsed_publisherInfo.value,
                publisherName: parsed_publisherName.value,
                rating: parsed_rating.value,
                sourceType: parsed_sourceType.value,
                subtitle: parsed_subtitle.value,
                summaryPages: parsed_summaryPages.value,
                summaryWords: parsed_summaryWords.value,
                tags: parsed_tags.value,
                teaser: parsed_teaser.value,
                title: parsed_title.value,
              },
            };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["summaries"]);
    })();
    if (!parsed_summaries.success) {
      return { success: false };
    }
    if (!hasProp(value, "summariesPaging")) {
      return { success: false };
    }
    const parsed_summariesPaging = (() => {
      return ((
        value: unknown,
      ):
        | { success: false }
        | {
            success: true;
            value: {
              page: number;
              totalCount: bigint;
            };
          } => {
        if (typeof value !== "object") {
          return { success: false };
        }
        if (value === null) {
          return { success: false };
        }
        if (!hasProp(value, "page")) {
          return { success: false };
        }
        const parsed_page = (() => {
          return ((value: unknown): { success: false } | { success: true; value: number } => {
            if (typeof value === "number") {
              return { success: true, value: value };
            }
            return { success: false };
          })(value["page"]);
        })();
        if (!parsed_page.success) {
          return { success: false };
        }
        if (!hasProp(value, "totalCount")) {
          return { success: false };
        }
        const parsed_totalCount = (() => {
          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
            if (typeof value === "number") {
              return { success: true, value: BigInt(value) };
            }
            if (typeof value === "bigint") {
              return { success: true, value: value };
            }
            return { success: false };
          })(value["totalCount"]);
        })();
        if (!parsed_totalCount.success) {
          return { success: false };
        }
        return { success: true, value: { page: parsed_page.value, totalCount: parsed_totalCount.value } };
      })(value["summariesPaging"]);
    })();
    if (!parsed_summariesPaging.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        actionables: parsed_actionables.value,
        channels: parsed_channels.value,
        customPages: parsed_customPages.value,
        formFilters: parsed_formFilters.value,
        summaries: parsed_summaries.value,
        summariesPaging: parsed_summariesPaging.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// Gender
export function serialize_Gender(obj: Gender): string {
  const serialized = ((value: "FEMALE" | "MALE" | "UNKNOWN" | "CUSTOM"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_Gender(str: string): Gender | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "FEMALE" | "MALE" | "UNKNOWN" | "CUSTOM" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "FEMALE":
        return { success: true, value: "FEMALE" };
      case "MALE":
        return { success: true, value: "MALE" };
      case "UNKNOWN":
        return { success: true, value: "UNKNOWN" };
      case "CUSTOM":
        return { success: true, value: "CUSTOM" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// GetabstractAiAnalyticsFeedbackEventForm
export function serialize_GetabstractAiAnalyticsFeedbackEventForm(obj: GetabstractAiAnalyticsFeedbackEventForm): string {
  const serialized = ((value: { isPositive: boolean; questionUuid: string }): unknown => {
    return {
      isPositive: ((value: boolean): unknown => {
        return value;
      })(value["isPositive"]),
      questionUuid: ((value: string): unknown => {
        return value;
      })(value["questionUuid"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_GetabstractAiAnalyticsFeedbackEventForm(str: string): GetabstractAiAnalyticsFeedbackEventForm | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          isPositive: boolean;
          questionUuid: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "isPositive")) {
      return { success: false };
    }
    const parsed_isPositive = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["isPositive"]);
    })();
    if (!parsed_isPositive.success) {
      return { success: false };
    }
    if (!hasProp(value, "questionUuid")) {
      return { success: false };
    }
    const parsed_questionUuid = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["questionUuid"]);
    })();
    if (!parsed_questionUuid.success) {
      return { success: false };
    }
    return { success: true, value: { isPositive: parsed_isPositive.value, questionUuid: parsed_questionUuid.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// GetabstractAiAnswer
export function serialize_GetabstractAiAnswer(obj: GetabstractAiAnswer): string {
  const serialized = ((value: {
    answer: string;
    contextDocs: GetabstractAiAnswerContextDoc[];
    detectedLanguageLabel: string | null;
    previousQuestionUuid: string | null;
    question: string;
    questionUuid: string;
    relatedActionables: ActionableMiniView[];
    relatedQuestions: string[];
    status: GetabstractAiAnswerStatus;
  }): unknown => {
    return {
      answer: ((value: string): unknown => {
        return value;
      })(value["answer"]),
      contextDocs: ((value: GetabstractAiAnswerContextDoc[]): unknown => {
        return value.map(
          (value: {
            bookmarked: boolean;
            dataId: number;
            rating: number | null;
            referenceNumber: number;
            snippet: string;
            sourceTypeString: string;
            title: string;
            urls: {
              cover: string;
              list: string;
              reference: string;
            };
          }): unknown => {
            return {
              bookmarked: ((value: boolean): unknown => {
                return value;
              })(value["bookmarked"]),
              dataId: ((value: number): unknown => {
                return value;
              })(value["dataId"]),
              rating: ((value: number | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["rating"]),
              referenceNumber: ((value: number): unknown => {
                return value;
              })(value["referenceNumber"]),
              snippet: ((value: string): unknown => {
                return value;
              })(value["snippet"]),
              sourceTypeString: ((value: string): unknown => {
                return value;
              })(value["sourceTypeString"]),
              title: ((value: string): unknown => {
                return value;
              })(value["title"]),
              urls: ((value: { cover: string; list: string; reference: string }): unknown => {
                return {
                  cover: ((value: string): unknown => {
                    return value;
                  })(value["cover"]),
                  list: ((value: string): unknown => {
                    return value;
                  })(value["list"]),
                  reference: ((value: string): unknown => {
                    return value;
                  })(value["reference"]),
                };
              })(value["urls"]),
            };
          },
        );
      })(value["contextDocs"]),
      detectedLanguageLabel: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["detectedLanguageLabel"]),
      previousQuestionUuid: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["previousQuestionUuid"]),
      question: ((value: string): unknown => {
        return value;
      })(value["question"]),
      questionUuid: ((value: string): unknown => {
        return value;
      })(value["questionUuid"]),
      relatedActionables: ((value: ActionableMiniView[]): unknown => {
        return value.map((value: { actionableId: bigint; coverUri: string; name: string; title: string }): unknown => {
          return {
            actionableId: ((value: bigint): unknown => {
              return value;
            })(value["actionableId"]),
            coverUri: ((value: string): unknown => {
              return value;
            })(value["coverUri"]),
            name: ((value: string): unknown => {
              return value;
            })(value["name"]),
            title: ((value: string): unknown => {
              return value;
            })(value["title"]),
          };
        });
      })(value["relatedActionables"]),
      relatedQuestions: ((value: string[]): unknown => {
        return value.map((value: string): unknown => {
          return value;
        });
      })(value["relatedQuestions"]),
      status: ((value: "PROCESSING" | "FINISHED" | "NO_DOCS_FROM_VECTOR_DB" | "NO_DOCS_AFTER_FILTER" | "NO_REFERENCE_IN_ANSWER" | "GENERAL_ERROR" | "TIMEOUT"): unknown => {
        return value;
      })(value["status"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_GetabstractAiAnswer(str: string): GetabstractAiAnswer | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          answer: string;
          contextDocs: GetabstractAiAnswerContextDoc[];
          detectedLanguageLabel: string | null;
          previousQuestionUuid: string | null;
          question: string;
          questionUuid: string;
          relatedActionables: ActionableMiniView[];
          relatedQuestions: string[];
          status: GetabstractAiAnswerStatus;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "answer")) {
      return { success: false };
    }
    const parsed_answer = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["answer"]);
    })();
    if (!parsed_answer.success) {
      return { success: false };
    }
    if (!hasProp(value, "contextDocs")) {
      return { success: false };
    }
    const parsed_contextDocs = (() => {
      return ((value: unknown): { success: false } | { success: true; value: GetabstractAiAnswerContextDoc[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: GetabstractAiAnswerContextDoc } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  bookmarked: boolean;
                  dataId: number;
                  rating: number | null;
                  referenceNumber: number;
                  snippet: string;
                  sourceTypeString: string;
                  title: string;
                  urls: {
                    cover: string;
                    list: string;
                    reference: string;
                  };
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "bookmarked")) {
              return { success: false };
            }
            const parsed_bookmarked = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["bookmarked"]);
            })();
            if (!parsed_bookmarked.success) {
              return { success: false };
            }
            if (!hasProp(value, "dataId")) {
              return { success: false };
            }
            const parsed_dataId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["dataId"]);
            })();
            if (!parsed_dataId.success) {
              return { success: false };
            }
            if (!hasProp(value, "rating")) {
              return { success: false };
            }
            const parsed_rating = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["rating"]);
            })();
            if (!parsed_rating.success) {
              return { success: false };
            }
            if (!hasProp(value, "referenceNumber")) {
              return { success: false };
            }
            const parsed_referenceNumber = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["referenceNumber"]);
            })();
            if (!parsed_referenceNumber.success) {
              return { success: false };
            }
            if (!hasProp(value, "snippet")) {
              return { success: false };
            }
            const parsed_snippet = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["snippet"]);
            })();
            if (!parsed_snippet.success) {
              return { success: false };
            }
            if (!hasProp(value, "sourceTypeString")) {
              return { success: false };
            }
            const parsed_sourceTypeString = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["sourceTypeString"]);
            })();
            if (!parsed_sourceTypeString.success) {
              return { success: false };
            }
            if (!hasProp(value, "title")) {
              return { success: false };
            }
            const parsed_title = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["title"]);
            })();
            if (!parsed_title.success) {
              return { success: false };
            }
            if (!hasProp(value, "urls")) {
              return { success: false };
            }
            const parsed_urls = (() => {
              return ((
                value: unknown,
              ):
                | { success: false }
                | {
                    success: true;
                    value: {
                      cover: string;
                      list: string;
                      reference: string;
                    };
                  } => {
                if (typeof value !== "object") {
                  return { success: false };
                }
                if (value === null) {
                  return { success: false };
                }
                if (!hasProp(value, "cover")) {
                  return { success: false };
                }
                const parsed_cover = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["cover"]);
                })();
                if (!parsed_cover.success) {
                  return { success: false };
                }
                if (!hasProp(value, "list")) {
                  return { success: false };
                }
                const parsed_list = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["list"]);
                })();
                if (!parsed_list.success) {
                  return { success: false };
                }
                if (!hasProp(value, "reference")) {
                  return { success: false };
                }
                const parsed_reference = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["reference"]);
                })();
                if (!parsed_reference.success) {
                  return { success: false };
                }
                return { success: true, value: { cover: parsed_cover.value, list: parsed_list.value, reference: parsed_reference.value } };
              })(value["urls"]);
            })();
            if (!parsed_urls.success) {
              return { success: false };
            }
            return {
              success: true,
              value: {
                bookmarked: parsed_bookmarked.value,
                dataId: parsed_dataId.value,
                rating: parsed_rating.value,
                referenceNumber: parsed_referenceNumber.value,
                snippet: parsed_snippet.value,
                sourceTypeString: parsed_sourceTypeString.value,
                title: parsed_title.value,
                urls: parsed_urls.value,
              },
            };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["contextDocs"]);
    })();
    if (!parsed_contextDocs.success) {
      return { success: false };
    }
    if (!hasProp(value, "detectedLanguageLabel")) {
      return { success: false };
    }
    const parsed_detectedLanguageLabel = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["detectedLanguageLabel"]);
    })();
    if (!parsed_detectedLanguageLabel.success) {
      return { success: false };
    }
    if (!hasProp(value, "previousQuestionUuid")) {
      return { success: false };
    }
    const parsed_previousQuestionUuid = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["previousQuestionUuid"]);
    })();
    if (!parsed_previousQuestionUuid.success) {
      return { success: false };
    }
    if (!hasProp(value, "question")) {
      return { success: false };
    }
    const parsed_question = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["question"]);
    })();
    if (!parsed_question.success) {
      return { success: false };
    }
    if (!hasProp(value, "questionUuid")) {
      return { success: false };
    }
    const parsed_questionUuid = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["questionUuid"]);
    })();
    if (!parsed_questionUuid.success) {
      return { success: false };
    }
    if (!hasProp(value, "relatedActionables")) {
      return { success: false };
    }
    const parsed_relatedActionables = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ActionableMiniView[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: ActionableMiniView } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  actionableId: bigint;
                  coverUri: string;
                  name: string;
                  title: string;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "actionableId")) {
              return { success: false };
            }
            const parsed_actionableId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                if (typeof value === "number") {
                  return { success: true, value: BigInt(value) };
                }
                if (typeof value === "bigint") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["actionableId"]);
            })();
            if (!parsed_actionableId.success) {
              return { success: false };
            }
            if (!hasProp(value, "coverUri")) {
              return { success: false };
            }
            const parsed_coverUri = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["coverUri"]);
            })();
            if (!parsed_coverUri.success) {
              return { success: false };
            }
            if (!hasProp(value, "name")) {
              return { success: false };
            }
            const parsed_name = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["name"]);
            })();
            if (!parsed_name.success) {
              return { success: false };
            }
            if (!hasProp(value, "title")) {
              return { success: false };
            }
            const parsed_title = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["title"]);
            })();
            if (!parsed_title.success) {
              return { success: false };
            }
            return { success: true, value: { actionableId: parsed_actionableId.value, coverUri: parsed_coverUri.value, name: parsed_name.value, title: parsed_title.value } };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["relatedActionables"]);
    })();
    if (!parsed_relatedActionables.success) {
      return { success: false };
    }
    if (!hasProp(value, "relatedQuestions")) {
      return { success: false };
    }
    const parsed_relatedQuestions = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          return { success: true, value: value };
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["relatedQuestions"]);
    })();
    if (!parsed_relatedQuestions.success) {
      return { success: false };
    }
    if (!hasProp(value, "status")) {
      return { success: false };
    }
    const parsed_status = (() => {
      return ((value: unknown): { success: false } | { success: true; value: GetabstractAiAnswerStatus } => {
        return ((value: unknown): { success: false } | { success: true; value: "PROCESSING" | "FINISHED" | "NO_DOCS_FROM_VECTOR_DB" | "NO_DOCS_AFTER_FILTER" | "NO_REFERENCE_IN_ANSWER" | "GENERAL_ERROR" | "TIMEOUT" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "PROCESSING":
              return { success: true, value: "PROCESSING" };
            case "FINISHED":
              return { success: true, value: "FINISHED" };
            case "NO_DOCS_FROM_VECTOR_DB":
              return { success: true, value: "NO_DOCS_FROM_VECTOR_DB" };
            case "NO_DOCS_AFTER_FILTER":
              return { success: true, value: "NO_DOCS_AFTER_FILTER" };
            case "NO_REFERENCE_IN_ANSWER":
              return { success: true, value: "NO_REFERENCE_IN_ANSWER" };
            case "GENERAL_ERROR":
              return { success: true, value: "GENERAL_ERROR" };
            case "TIMEOUT":
              return { success: true, value: "TIMEOUT" };
            default:
              return { success: false };
          }
        })(value);
      })(value["status"]);
    })();
    if (!parsed_status.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        answer: parsed_answer.value,
        contextDocs: parsed_contextDocs.value,
        detectedLanguageLabel: parsed_detectedLanguageLabel.value,
        previousQuestionUuid: parsed_previousQuestionUuid.value,
        question: parsed_question.value,
        questionUuid: parsed_questionUuid.value,
        relatedActionables: parsed_relatedActionables.value,
        relatedQuestions: parsed_relatedQuestions.value,
        status: parsed_status.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// GetabstractAiAnswerContextDoc
export function serialize_GetabstractAiAnswerContextDoc(obj: GetabstractAiAnswerContextDoc): string {
  const serialized = ((value: {
    bookmarked: boolean;
    dataId: number;
    rating: number | null;
    referenceNumber: number;
    snippet: string;
    sourceTypeString: string;
    title: string;
    urls: {
      cover: string;
      list: string;
      reference: string;
    };
  }): unknown => {
    return {
      bookmarked: ((value: boolean): unknown => {
        return value;
      })(value["bookmarked"]),
      dataId: ((value: number): unknown => {
        return value;
      })(value["dataId"]),
      rating: ((value: number | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["rating"]),
      referenceNumber: ((value: number): unknown => {
        return value;
      })(value["referenceNumber"]),
      snippet: ((value: string): unknown => {
        return value;
      })(value["snippet"]),
      sourceTypeString: ((value: string): unknown => {
        return value;
      })(value["sourceTypeString"]),
      title: ((value: string): unknown => {
        return value;
      })(value["title"]),
      urls: ((value: { cover: string; list: string; reference: string }): unknown => {
        return {
          cover: ((value: string): unknown => {
            return value;
          })(value["cover"]),
          list: ((value: string): unknown => {
            return value;
          })(value["list"]),
          reference: ((value: string): unknown => {
            return value;
          })(value["reference"]),
        };
      })(value["urls"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_GetabstractAiAnswerContextDoc(str: string): GetabstractAiAnswerContextDoc | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          bookmarked: boolean;
          dataId: number;
          rating: number | null;
          referenceNumber: number;
          snippet: string;
          sourceTypeString: string;
          title: string;
          urls: {
            cover: string;
            list: string;
            reference: string;
          };
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "bookmarked")) {
      return { success: false };
    }
    const parsed_bookmarked = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["bookmarked"]);
    })();
    if (!parsed_bookmarked.success) {
      return { success: false };
    }
    if (!hasProp(value, "dataId")) {
      return { success: false };
    }
    const parsed_dataId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["dataId"]);
    })();
    if (!parsed_dataId.success) {
      return { success: false };
    }
    if (!hasProp(value, "rating")) {
      return { success: false };
    }
    const parsed_rating = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["rating"]);
    })();
    if (!parsed_rating.success) {
      return { success: false };
    }
    if (!hasProp(value, "referenceNumber")) {
      return { success: false };
    }
    const parsed_referenceNumber = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["referenceNumber"]);
    })();
    if (!parsed_referenceNumber.success) {
      return { success: false };
    }
    if (!hasProp(value, "snippet")) {
      return { success: false };
    }
    const parsed_snippet = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["snippet"]);
    })();
    if (!parsed_snippet.success) {
      return { success: false };
    }
    if (!hasProp(value, "sourceTypeString")) {
      return { success: false };
    }
    const parsed_sourceTypeString = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["sourceTypeString"]);
    })();
    if (!parsed_sourceTypeString.success) {
      return { success: false };
    }
    if (!hasProp(value, "title")) {
      return { success: false };
    }
    const parsed_title = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["title"]);
    })();
    if (!parsed_title.success) {
      return { success: false };
    }
    if (!hasProp(value, "urls")) {
      return { success: false };
    }
    const parsed_urls = (() => {
      return ((
        value: unknown,
      ):
        | { success: false }
        | {
            success: true;
            value: {
              cover: string;
              list: string;
              reference: string;
            };
          } => {
        if (typeof value !== "object") {
          return { success: false };
        }
        if (value === null) {
          return { success: false };
        }
        if (!hasProp(value, "cover")) {
          return { success: false };
        }
        const parsed_cover = (() => {
          return ((value: unknown): { success: false } | { success: true; value: string } => {
            if (typeof value !== "string") {
              return { success: false };
            }
            return { success: true, value: value };
          })(value["cover"]);
        })();
        if (!parsed_cover.success) {
          return { success: false };
        }
        if (!hasProp(value, "list")) {
          return { success: false };
        }
        const parsed_list = (() => {
          return ((value: unknown): { success: false } | { success: true; value: string } => {
            if (typeof value !== "string") {
              return { success: false };
            }
            return { success: true, value: value };
          })(value["list"]);
        })();
        if (!parsed_list.success) {
          return { success: false };
        }
        if (!hasProp(value, "reference")) {
          return { success: false };
        }
        const parsed_reference = (() => {
          return ((value: unknown): { success: false } | { success: true; value: string } => {
            if (typeof value !== "string") {
              return { success: false };
            }
            return { success: true, value: value };
          })(value["reference"]);
        })();
        if (!parsed_reference.success) {
          return { success: false };
        }
        return { success: true, value: { cover: parsed_cover.value, list: parsed_list.value, reference: parsed_reference.value } };
      })(value["urls"]);
    })();
    if (!parsed_urls.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        bookmarked: parsed_bookmarked.value,
        dataId: parsed_dataId.value,
        rating: parsed_rating.value,
        referenceNumber: parsed_referenceNumber.value,
        snippet: parsed_snippet.value,
        sourceTypeString: parsed_sourceTypeString.value,
        title: parsed_title.value,
        urls: parsed_urls.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// GetabstractAiAnswerStatus
export function serialize_GetabstractAiAnswerStatus(obj: GetabstractAiAnswerStatus): string {
  const serialized = ((value: "PROCESSING" | "FINISHED" | "NO_DOCS_FROM_VECTOR_DB" | "NO_DOCS_AFTER_FILTER" | "NO_REFERENCE_IN_ANSWER" | "GENERAL_ERROR" | "TIMEOUT"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_GetabstractAiAnswerStatus(str: string): GetabstractAiAnswerStatus | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "PROCESSING" | "FINISHED" | "NO_DOCS_FROM_VECTOR_DB" | "NO_DOCS_AFTER_FILTER" | "NO_REFERENCE_IN_ANSWER" | "GENERAL_ERROR" | "TIMEOUT" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "PROCESSING":
        return { success: true, value: "PROCESSING" };
      case "FINISHED":
        return { success: true, value: "FINISHED" };
      case "NO_DOCS_FROM_VECTOR_DB":
        return { success: true, value: "NO_DOCS_FROM_VECTOR_DB" };
      case "NO_DOCS_AFTER_FILTER":
        return { success: true, value: "NO_DOCS_AFTER_FILTER" };
      case "NO_REFERENCE_IN_ANSWER":
        return { success: true, value: "NO_REFERENCE_IN_ANSWER" };
      case "GENERAL_ERROR":
        return { success: true, value: "GENERAL_ERROR" };
      case "TIMEOUT":
        return { success: true, value: "TIMEOUT" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// GetabstractAiProperties
export function serialize_GetabstractAiProperties(obj: GetabstractAiProperties): string {
  const serialized = ((value: { isTrendingQuestion: boolean; language: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"; question: string | null; questionUuid: string | null; showAiDataProtectionDisclaimer: boolean }): unknown => {
    return {
      isTrendingQuestion: ((value: boolean): unknown => {
        return value;
      })(value["isTrendingQuestion"]),
      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
        return value;
      })(value["language"]),
      question: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["question"]),
      questionUuid: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["questionUuid"]),
      showAiDataProtectionDisclaimer: ((value: boolean): unknown => {
        return value;
      })(value["showAiDataProtectionDisclaimer"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_GetabstractAiProperties(str: string): GetabstractAiProperties | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          isTrendingQuestion: boolean;
          language: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr";
          question: string | null;
          questionUuid: string | null;
          showAiDataProtectionDisclaimer: boolean;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "isTrendingQuestion")) {
      return { success: false };
    }
    const parsed_isTrendingQuestion = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["isTrendingQuestion"]);
    })();
    if (!parsed_isTrendingQuestion.success) {
      return { success: false };
    }
    if (!hasProp(value, "language")) {
      return { success: false };
    }
    const parsed_language = (() => {
      return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        switch (value) {
          case "en":
            return { success: true, value: "en" };
          case "de":
            return { success: true, value: "de" };
          case "es":
            return { success: true, value: "es" };
          case "ru":
            return { success: true, value: "ru" };
          case "zh":
            return { success: true, value: "zh" };
          case "pt":
            return { success: true, value: "pt" };
          case "fr":
            return { success: true, value: "fr" };
          default:
            return { success: false };
        }
      })(value["language"]);
    })();
    if (!parsed_language.success) {
      return { success: false };
    }
    if (!hasProp(value, "question")) {
      return { success: false };
    }
    const parsed_question = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["question"]);
    })();
    if (!parsed_question.success) {
      return { success: false };
    }
    if (!hasProp(value, "questionUuid")) {
      return { success: false };
    }
    const parsed_questionUuid = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["questionUuid"]);
    })();
    if (!parsed_questionUuid.success) {
      return { success: false };
    }
    if (!hasProp(value, "showAiDataProtectionDisclaimer")) {
      return { success: false };
    }
    const parsed_showAiDataProtectionDisclaimer = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["showAiDataProtectionDisclaimer"]);
    })();
    if (!parsed_showAiDataProtectionDisclaimer.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        isTrendingQuestion: parsed_isTrendingQuestion.value,
        language: parsed_language.value,
        question: parsed_question.value,
        questionUuid: parsed_questionUuid.value,
        showAiDataProtectionDisclaimer: parsed_showAiDataProtectionDisclaimer.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// GetabstractAiQuestion
export function serialize_GetabstractAiQuestion(obj: GetabstractAiQuestion): string {
  const serialized = ((value: { blacklistQuestions: string[]; previousQuestionUuid: string | null; question: string; questionUuid: string; relatedQuestionStep: number; trendingQuestion: boolean }): unknown => {
    return {
      blacklistQuestions: ((value: string[]): unknown => {
        return value.map((value: string): unknown => {
          return value;
        });
      })(value["blacklistQuestions"]),
      previousQuestionUuid: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["previousQuestionUuid"]),
      question: ((value: string): unknown => {
        return value;
      })(value["question"]),
      questionUuid: ((value: string): unknown => {
        return value;
      })(value["questionUuid"]),
      relatedQuestionStep: ((value: number): unknown => {
        return value;
      })(value["relatedQuestionStep"]),
      trendingQuestion: ((value: boolean): unknown => {
        return value;
      })(value["trendingQuestion"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_GetabstractAiQuestion(str: string): GetabstractAiQuestion | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          blacklistQuestions: string[];
          previousQuestionUuid: string | null;
          question: string;
          questionUuid: string;
          relatedQuestionStep: number;
          trendingQuestion: boolean;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "blacklistQuestions")) {
      return { success: false };
    }
    const parsed_blacklistQuestions = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          return { success: true, value: value };
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["blacklistQuestions"]);
    })();
    if (!parsed_blacklistQuestions.success) {
      return { success: false };
    }
    if (!hasProp(value, "previousQuestionUuid")) {
      return { success: false };
    }
    const parsed_previousQuestionUuid = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["previousQuestionUuid"]);
    })();
    if (!parsed_previousQuestionUuid.success) {
      return { success: false };
    }
    if (!hasProp(value, "question")) {
      return { success: false };
    }
    const parsed_question = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["question"]);
    })();
    if (!parsed_question.success) {
      return { success: false };
    }
    if (!hasProp(value, "questionUuid")) {
      return { success: false };
    }
    const parsed_questionUuid = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["questionUuid"]);
    })();
    if (!parsed_questionUuid.success) {
      return { success: false };
    }
    if (!hasProp(value, "relatedQuestionStep")) {
      return { success: false };
    }
    const parsed_relatedQuestionStep = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["relatedQuestionStep"]);
    })();
    if (!parsed_relatedQuestionStep.success) {
      return { success: false };
    }
    if (!hasProp(value, "trendingQuestion")) {
      return { success: false };
    }
    const parsed_trendingQuestion = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["trendingQuestion"]);
    })();
    if (!parsed_trendingQuestion.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        blacklistQuestions: parsed_blacklistQuestions.value,
        previousQuestionUuid: parsed_previousQuestionUuid.value,
        question: parsed_question.value,
        questionUuid: parsed_questionUuid.value,
        relatedQuestionStep: parsed_relatedQuestionStep.value,
        trendingQuestion: parsed_trendingQuestion.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// GetabstractAiQuestionAnswers
export function serialize_GetabstractAiQuestionAnswers(obj: GetabstractAiQuestionAnswers): string {
  const serialized = ((value: { current: GetabstractAiAnswer; history: GetabstractAiAnswer[] }): unknown => {
    return {
      current: ((value: {
        answer: string;
        contextDocs: GetabstractAiAnswerContextDoc[];
        detectedLanguageLabel: string | null;
        previousQuestionUuid: string | null;
        question: string;
        questionUuid: string;
        relatedActionables: ActionableMiniView[];
        relatedQuestions: string[];
        status: GetabstractAiAnswerStatus;
      }): unknown => {
        return {
          answer: ((value: string): unknown => {
            return value;
          })(value["answer"]),
          contextDocs: ((value: GetabstractAiAnswerContextDoc[]): unknown => {
            return value.map(
              (value: {
                bookmarked: boolean;
                dataId: number;
                rating: number | null;
                referenceNumber: number;
                snippet: string;
                sourceTypeString: string;
                title: string;
                urls: {
                  cover: string;
                  list: string;
                  reference: string;
                };
              }): unknown => {
                return {
                  bookmarked: ((value: boolean): unknown => {
                    return value;
                  })(value["bookmarked"]),
                  dataId: ((value: number): unknown => {
                    return value;
                  })(value["dataId"]),
                  rating: ((value: number | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["rating"]),
                  referenceNumber: ((value: number): unknown => {
                    return value;
                  })(value["referenceNumber"]),
                  snippet: ((value: string): unknown => {
                    return value;
                  })(value["snippet"]),
                  sourceTypeString: ((value: string): unknown => {
                    return value;
                  })(value["sourceTypeString"]),
                  title: ((value: string): unknown => {
                    return value;
                  })(value["title"]),
                  urls: ((value: { cover: string; list: string; reference: string }): unknown => {
                    return {
                      cover: ((value: string): unknown => {
                        return value;
                      })(value["cover"]),
                      list: ((value: string): unknown => {
                        return value;
                      })(value["list"]),
                      reference: ((value: string): unknown => {
                        return value;
                      })(value["reference"]),
                    };
                  })(value["urls"]),
                };
              },
            );
          })(value["contextDocs"]),
          detectedLanguageLabel: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["detectedLanguageLabel"]),
          previousQuestionUuid: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["previousQuestionUuid"]),
          question: ((value: string): unknown => {
            return value;
          })(value["question"]),
          questionUuid: ((value: string): unknown => {
            return value;
          })(value["questionUuid"]),
          relatedActionables: ((value: ActionableMiniView[]): unknown => {
            return value.map((value: { actionableId: bigint; coverUri: string; name: string; title: string }): unknown => {
              return {
                actionableId: ((value: bigint): unknown => {
                  return value;
                })(value["actionableId"]),
                coverUri: ((value: string): unknown => {
                  return value;
                })(value["coverUri"]),
                name: ((value: string): unknown => {
                  return value;
                })(value["name"]),
                title: ((value: string): unknown => {
                  return value;
                })(value["title"]),
              };
            });
          })(value["relatedActionables"]),
          relatedQuestions: ((value: string[]): unknown => {
            return value.map((value: string): unknown => {
              return value;
            });
          })(value["relatedQuestions"]),
          status: ((value: "PROCESSING" | "FINISHED" | "NO_DOCS_FROM_VECTOR_DB" | "NO_DOCS_AFTER_FILTER" | "NO_REFERENCE_IN_ANSWER" | "GENERAL_ERROR" | "TIMEOUT"): unknown => {
            return value;
          })(value["status"]),
        };
      })(value["current"]),
      history: ((value: GetabstractAiAnswer[]): unknown => {
        return value.map(
          (value: {
            answer: string;
            contextDocs: GetabstractAiAnswerContextDoc[];
            detectedLanguageLabel: string | null;
            previousQuestionUuid: string | null;
            question: string;
            questionUuid: string;
            relatedActionables: ActionableMiniView[];
            relatedQuestions: string[];
            status: GetabstractAiAnswerStatus;
          }): unknown => {
            return {
              answer: ((value: string): unknown => {
                return value;
              })(value["answer"]),
              contextDocs: ((value: GetabstractAiAnswerContextDoc[]): unknown => {
                return value.map(
                  (value: {
                    bookmarked: boolean;
                    dataId: number;
                    rating: number | null;
                    referenceNumber: number;
                    snippet: string;
                    sourceTypeString: string;
                    title: string;
                    urls: {
                      cover: string;
                      list: string;
                      reference: string;
                    };
                  }): unknown => {
                    return {
                      bookmarked: ((value: boolean): unknown => {
                        return value;
                      })(value["bookmarked"]),
                      dataId: ((value: number): unknown => {
                        return value;
                      })(value["dataId"]),
                      rating: ((value: number | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["rating"]),
                      referenceNumber: ((value: number): unknown => {
                        return value;
                      })(value["referenceNumber"]),
                      snippet: ((value: string): unknown => {
                        return value;
                      })(value["snippet"]),
                      sourceTypeString: ((value: string): unknown => {
                        return value;
                      })(value["sourceTypeString"]),
                      title: ((value: string): unknown => {
                        return value;
                      })(value["title"]),
                      urls: ((value: { cover: string; list: string; reference: string }): unknown => {
                        return {
                          cover: ((value: string): unknown => {
                            return value;
                          })(value["cover"]),
                          list: ((value: string): unknown => {
                            return value;
                          })(value["list"]),
                          reference: ((value: string): unknown => {
                            return value;
                          })(value["reference"]),
                        };
                      })(value["urls"]),
                    };
                  },
                );
              })(value["contextDocs"]),
              detectedLanguageLabel: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["detectedLanguageLabel"]),
              previousQuestionUuid: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["previousQuestionUuid"]),
              question: ((value: string): unknown => {
                return value;
              })(value["question"]),
              questionUuid: ((value: string): unknown => {
                return value;
              })(value["questionUuid"]),
              relatedActionables: ((value: ActionableMiniView[]): unknown => {
                return value.map((value: { actionableId: bigint; coverUri: string; name: string; title: string }): unknown => {
                  return {
                    actionableId: ((value: bigint): unknown => {
                      return value;
                    })(value["actionableId"]),
                    coverUri: ((value: string): unknown => {
                      return value;
                    })(value["coverUri"]),
                    name: ((value: string): unknown => {
                      return value;
                    })(value["name"]),
                    title: ((value: string): unknown => {
                      return value;
                    })(value["title"]),
                  };
                });
              })(value["relatedActionables"]),
              relatedQuestions: ((value: string[]): unknown => {
                return value.map((value: string): unknown => {
                  return value;
                });
              })(value["relatedQuestions"]),
              status: ((value: "PROCESSING" | "FINISHED" | "NO_DOCS_FROM_VECTOR_DB" | "NO_DOCS_AFTER_FILTER" | "NO_REFERENCE_IN_ANSWER" | "GENERAL_ERROR" | "TIMEOUT"): unknown => {
                return value;
              })(value["status"]),
            };
          },
        );
      })(value["history"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_GetabstractAiQuestionAnswers(str: string): GetabstractAiQuestionAnswers | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          current: GetabstractAiAnswer;
          history: GetabstractAiAnswer[];
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "current")) {
      return { success: false };
    }
    const parsed_current = (() => {
      return ((value: unknown): { success: false } | { success: true; value: GetabstractAiAnswer } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                answer: string;
                contextDocs: GetabstractAiAnswerContextDoc[];
                detectedLanguageLabel: string | null;
                previousQuestionUuid: string | null;
                question: string;
                questionUuid: string;
                relatedActionables: ActionableMiniView[];
                relatedQuestions: string[];
                status: GetabstractAiAnswerStatus;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "answer")) {
            return { success: false };
          }
          const parsed_answer = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["answer"]);
          })();
          if (!parsed_answer.success) {
            return { success: false };
          }
          if (!hasProp(value, "contextDocs")) {
            return { success: false };
          }
          const parsed_contextDocs = (() => {
            return ((value: unknown): { success: false } | { success: true; value: GetabstractAiAnswerContextDoc[] } => {
              if (!isArray(value)) {
                return { success: false };
              }
              const parsed = value.map((value: unknown): { success: false } | { success: true; value: GetabstractAiAnswerContextDoc } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        bookmarked: boolean;
                        dataId: number;
                        rating: number | null;
                        referenceNumber: number;
                        snippet: string;
                        sourceTypeString: string;
                        title: string;
                        urls: {
                          cover: string;
                          list: string;
                          reference: string;
                        };
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "bookmarked")) {
                    return { success: false };
                  }
                  const parsed_bookmarked = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["bookmarked"]);
                  })();
                  if (!parsed_bookmarked.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "dataId")) {
                    return { success: false };
                  }
                  const parsed_dataId = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number } => {
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["dataId"]);
                  })();
                  if (!parsed_dataId.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "rating")) {
                    return { success: false };
                  }
                  const parsed_rating = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["rating"]);
                  })();
                  if (!parsed_rating.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "referenceNumber")) {
                    return { success: false };
                  }
                  const parsed_referenceNumber = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number } => {
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["referenceNumber"]);
                  })();
                  if (!parsed_referenceNumber.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "snippet")) {
                    return { success: false };
                  }
                  const parsed_snippet = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["snippet"]);
                  })();
                  if (!parsed_snippet.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "sourceTypeString")) {
                    return { success: false };
                  }
                  const parsed_sourceTypeString = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["sourceTypeString"]);
                  })();
                  if (!parsed_sourceTypeString.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "title")) {
                    return { success: false };
                  }
                  const parsed_title = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["title"]);
                  })();
                  if (!parsed_title.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "urls")) {
                    return { success: false };
                  }
                  const parsed_urls = (() => {
                    return ((
                      value: unknown,
                    ):
                      | { success: false }
                      | {
                          success: true;
                          value: {
                            cover: string;
                            list: string;
                            reference: string;
                          };
                        } => {
                      if (typeof value !== "object") {
                        return { success: false };
                      }
                      if (value === null) {
                        return { success: false };
                      }
                      if (!hasProp(value, "cover")) {
                        return { success: false };
                      }
                      const parsed_cover = (() => {
                        return ((value: unknown): { success: false } | { success: true; value: string } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          return { success: true, value: value };
                        })(value["cover"]);
                      })();
                      if (!parsed_cover.success) {
                        return { success: false };
                      }
                      if (!hasProp(value, "list")) {
                        return { success: false };
                      }
                      const parsed_list = (() => {
                        return ((value: unknown): { success: false } | { success: true; value: string } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          return { success: true, value: value };
                        })(value["list"]);
                      })();
                      if (!parsed_list.success) {
                        return { success: false };
                      }
                      if (!hasProp(value, "reference")) {
                        return { success: false };
                      }
                      const parsed_reference = (() => {
                        return ((value: unknown): { success: false } | { success: true; value: string } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          return { success: true, value: value };
                        })(value["reference"]);
                      })();
                      if (!parsed_reference.success) {
                        return { success: false };
                      }
                      return { success: true, value: { cover: parsed_cover.value, list: parsed_list.value, reference: parsed_reference.value } };
                    })(value["urls"]);
                  })();
                  if (!parsed_urls.success) {
                    return { success: false };
                  }
                  return {
                    success: true,
                    value: {
                      bookmarked: parsed_bookmarked.value,
                      dataId: parsed_dataId.value,
                      rating: parsed_rating.value,
                      referenceNumber: parsed_referenceNumber.value,
                      snippet: parsed_snippet.value,
                      sourceTypeString: parsed_sourceTypeString.value,
                      title: parsed_title.value,
                      urls: parsed_urls.value,
                    },
                  };
                })(value);
              });
              if (parsed.find((value) => !value.success) !== undefined) {
                return { success: false };
              }
              return {
                success: true,
                value: parsed.map((entry) => {
                  assert(entry.success == true);
                  return entry.value;
                }),
              };
            })(value["contextDocs"]);
          })();
          if (!parsed_contextDocs.success) {
            return { success: false };
          }
          if (!hasProp(value, "detectedLanguageLabel")) {
            return { success: false };
          }
          const parsed_detectedLanguageLabel = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["detectedLanguageLabel"]);
          })();
          if (!parsed_detectedLanguageLabel.success) {
            return { success: false };
          }
          if (!hasProp(value, "previousQuestionUuid")) {
            return { success: false };
          }
          const parsed_previousQuestionUuid = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["previousQuestionUuid"]);
          })();
          if (!parsed_previousQuestionUuid.success) {
            return { success: false };
          }
          if (!hasProp(value, "question")) {
            return { success: false };
          }
          const parsed_question = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["question"]);
          })();
          if (!parsed_question.success) {
            return { success: false };
          }
          if (!hasProp(value, "questionUuid")) {
            return { success: false };
          }
          const parsed_questionUuid = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["questionUuid"]);
          })();
          if (!parsed_questionUuid.success) {
            return { success: false };
          }
          if (!hasProp(value, "relatedActionables")) {
            return { success: false };
          }
          const parsed_relatedActionables = (() => {
            return ((value: unknown): { success: false } | { success: true; value: ActionableMiniView[] } => {
              if (!isArray(value)) {
                return { success: false };
              }
              const parsed = value.map((value: unknown): { success: false } | { success: true; value: ActionableMiniView } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        actionableId: bigint;
                        coverUri: string;
                        name: string;
                        title: string;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "actionableId")) {
                    return { success: false };
                  }
                  const parsed_actionableId = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                      if (typeof value === "number") {
                        return { success: true, value: BigInt(value) };
                      }
                      if (typeof value === "bigint") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["actionableId"]);
                  })();
                  if (!parsed_actionableId.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "coverUri")) {
                    return { success: false };
                  }
                  const parsed_coverUri = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["coverUri"]);
                  })();
                  if (!parsed_coverUri.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "name")) {
                    return { success: false };
                  }
                  const parsed_name = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["name"]);
                  })();
                  if (!parsed_name.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "title")) {
                    return { success: false };
                  }
                  const parsed_title = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["title"]);
                  })();
                  if (!parsed_title.success) {
                    return { success: false };
                  }
                  return { success: true, value: { actionableId: parsed_actionableId.value, coverUri: parsed_coverUri.value, name: parsed_name.value, title: parsed_title.value } };
                })(value);
              });
              if (parsed.find((value) => !value.success) !== undefined) {
                return { success: false };
              }
              return {
                success: true,
                value: parsed.map((entry) => {
                  assert(entry.success == true);
                  return entry.value;
                }),
              };
            })(value["relatedActionables"]);
          })();
          if (!parsed_relatedActionables.success) {
            return { success: false };
          }
          if (!hasProp(value, "relatedQuestions")) {
            return { success: false };
          }
          const parsed_relatedQuestions = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string[] } => {
              if (!isArray(value)) {
                return { success: false };
              }
              const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              });
              if (parsed.find((value) => !value.success) !== undefined) {
                return { success: false };
              }
              return {
                success: true,
                value: parsed.map((entry) => {
                  assert(entry.success == true);
                  return entry.value;
                }),
              };
            })(value["relatedQuestions"]);
          })();
          if (!parsed_relatedQuestions.success) {
            return { success: false };
          }
          if (!hasProp(value, "status")) {
            return { success: false };
          }
          const parsed_status = (() => {
            return ((value: unknown): { success: false } | { success: true; value: GetabstractAiAnswerStatus } => {
              return ((value: unknown): { success: false } | { success: true; value: "PROCESSING" | "FINISHED" | "NO_DOCS_FROM_VECTOR_DB" | "NO_DOCS_AFTER_FILTER" | "NO_REFERENCE_IN_ANSWER" | "GENERAL_ERROR" | "TIMEOUT" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "PROCESSING":
                    return { success: true, value: "PROCESSING" };
                  case "FINISHED":
                    return { success: true, value: "FINISHED" };
                  case "NO_DOCS_FROM_VECTOR_DB":
                    return { success: true, value: "NO_DOCS_FROM_VECTOR_DB" };
                  case "NO_DOCS_AFTER_FILTER":
                    return { success: true, value: "NO_DOCS_AFTER_FILTER" };
                  case "NO_REFERENCE_IN_ANSWER":
                    return { success: true, value: "NO_REFERENCE_IN_ANSWER" };
                  case "GENERAL_ERROR":
                    return { success: true, value: "GENERAL_ERROR" };
                  case "TIMEOUT":
                    return { success: true, value: "TIMEOUT" };
                  default:
                    return { success: false };
                }
              })(value);
            })(value["status"]);
          })();
          if (!parsed_status.success) {
            return { success: false };
          }
          return {
            success: true,
            value: {
              answer: parsed_answer.value,
              contextDocs: parsed_contextDocs.value,
              detectedLanguageLabel: parsed_detectedLanguageLabel.value,
              previousQuestionUuid: parsed_previousQuestionUuid.value,
              question: parsed_question.value,
              questionUuid: parsed_questionUuid.value,
              relatedActionables: parsed_relatedActionables.value,
              relatedQuestions: parsed_relatedQuestions.value,
              status: parsed_status.value,
            },
          };
        })(value);
      })(value["current"]);
    })();
    if (!parsed_current.success) {
      return { success: false };
    }
    if (!hasProp(value, "history")) {
      return { success: false };
    }
    const parsed_history = (() => {
      return ((value: unknown): { success: false } | { success: true; value: GetabstractAiAnswer[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: GetabstractAiAnswer } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  answer: string;
                  contextDocs: GetabstractAiAnswerContextDoc[];
                  detectedLanguageLabel: string | null;
                  previousQuestionUuid: string | null;
                  question: string;
                  questionUuid: string;
                  relatedActionables: ActionableMiniView[];
                  relatedQuestions: string[];
                  status: GetabstractAiAnswerStatus;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "answer")) {
              return { success: false };
            }
            const parsed_answer = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["answer"]);
            })();
            if (!parsed_answer.success) {
              return { success: false };
            }
            if (!hasProp(value, "contextDocs")) {
              return { success: false };
            }
            const parsed_contextDocs = (() => {
              return ((value: unknown): { success: false } | { success: true; value: GetabstractAiAnswerContextDoc[] } => {
                if (!isArray(value)) {
                  return { success: false };
                }
                const parsed = value.map((value: unknown): { success: false } | { success: true; value: GetabstractAiAnswerContextDoc } => {
                  return ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          bookmarked: boolean;
                          dataId: number;
                          rating: number | null;
                          referenceNumber: number;
                          snippet: string;
                          sourceTypeString: string;
                          title: string;
                          urls: {
                            cover: string;
                            list: string;
                            reference: string;
                          };
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "bookmarked")) {
                      return { success: false };
                    }
                    const parsed_bookmarked = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["bookmarked"]);
                    })();
                    if (!parsed_bookmarked.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "dataId")) {
                      return { success: false };
                    }
                    const parsed_dataId = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: number } => {
                        if (typeof value === "number") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["dataId"]);
                    })();
                    if (!parsed_dataId.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "rating")) {
                      return { success: false };
                    }
                    const parsed_rating = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: number | null } => {
                        if (value === null) {
                          return { success: true, value: null };
                        }
                        if (typeof value === "number") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["rating"]);
                    })();
                    if (!parsed_rating.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "referenceNumber")) {
                      return { success: false };
                    }
                    const parsed_referenceNumber = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: number } => {
                        if (typeof value === "number") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["referenceNumber"]);
                    })();
                    if (!parsed_referenceNumber.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "snippet")) {
                      return { success: false };
                    }
                    const parsed_snippet = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["snippet"]);
                    })();
                    if (!parsed_snippet.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "sourceTypeString")) {
                      return { success: false };
                    }
                    const parsed_sourceTypeString = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["sourceTypeString"]);
                    })();
                    if (!parsed_sourceTypeString.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "title")) {
                      return { success: false };
                    }
                    const parsed_title = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["title"]);
                    })();
                    if (!parsed_title.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "urls")) {
                      return { success: false };
                    }
                    const parsed_urls = (() => {
                      return ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              cover: string;
                              list: string;
                              reference: string;
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "cover")) {
                          return { success: false };
                        }
                        const parsed_cover = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["cover"]);
                        })();
                        if (!parsed_cover.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "list")) {
                          return { success: false };
                        }
                        const parsed_list = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["list"]);
                        })();
                        if (!parsed_list.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "reference")) {
                          return { success: false };
                        }
                        const parsed_reference = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["reference"]);
                        })();
                        if (!parsed_reference.success) {
                          return { success: false };
                        }
                        return { success: true, value: { cover: parsed_cover.value, list: parsed_list.value, reference: parsed_reference.value } };
                      })(value["urls"]);
                    })();
                    if (!parsed_urls.success) {
                      return { success: false };
                    }
                    return {
                      success: true,
                      value: {
                        bookmarked: parsed_bookmarked.value,
                        dataId: parsed_dataId.value,
                        rating: parsed_rating.value,
                        referenceNumber: parsed_referenceNumber.value,
                        snippet: parsed_snippet.value,
                        sourceTypeString: parsed_sourceTypeString.value,
                        title: parsed_title.value,
                        urls: parsed_urls.value,
                      },
                    };
                  })(value);
                });
                if (parsed.find((value) => !value.success) !== undefined) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: parsed.map((entry) => {
                    assert(entry.success == true);
                    return entry.value;
                  }),
                };
              })(value["contextDocs"]);
            })();
            if (!parsed_contextDocs.success) {
              return { success: false };
            }
            if (!hasProp(value, "detectedLanguageLabel")) {
              return { success: false };
            }
            const parsed_detectedLanguageLabel = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["detectedLanguageLabel"]);
            })();
            if (!parsed_detectedLanguageLabel.success) {
              return { success: false };
            }
            if (!hasProp(value, "previousQuestionUuid")) {
              return { success: false };
            }
            const parsed_previousQuestionUuid = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["previousQuestionUuid"]);
            })();
            if (!parsed_previousQuestionUuid.success) {
              return { success: false };
            }
            if (!hasProp(value, "question")) {
              return { success: false };
            }
            const parsed_question = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["question"]);
            })();
            if (!parsed_question.success) {
              return { success: false };
            }
            if (!hasProp(value, "questionUuid")) {
              return { success: false };
            }
            const parsed_questionUuid = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["questionUuid"]);
            })();
            if (!parsed_questionUuid.success) {
              return { success: false };
            }
            if (!hasProp(value, "relatedActionables")) {
              return { success: false };
            }
            const parsed_relatedActionables = (() => {
              return ((value: unknown): { success: false } | { success: true; value: ActionableMiniView[] } => {
                if (!isArray(value)) {
                  return { success: false };
                }
                const parsed = value.map((value: unknown): { success: false } | { success: true; value: ActionableMiniView } => {
                  return ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          actionableId: bigint;
                          coverUri: string;
                          name: string;
                          title: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "actionableId")) {
                      return { success: false };
                    }
                    const parsed_actionableId = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                        if (typeof value === "number") {
                          return { success: true, value: BigInt(value) };
                        }
                        if (typeof value === "bigint") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["actionableId"]);
                    })();
                    if (!parsed_actionableId.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "coverUri")) {
                      return { success: false };
                    }
                    const parsed_coverUri = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["coverUri"]);
                    })();
                    if (!parsed_coverUri.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "name")) {
                      return { success: false };
                    }
                    const parsed_name = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["name"]);
                    })();
                    if (!parsed_name.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "title")) {
                      return { success: false };
                    }
                    const parsed_title = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["title"]);
                    })();
                    if (!parsed_title.success) {
                      return { success: false };
                    }
                    return { success: true, value: { actionableId: parsed_actionableId.value, coverUri: parsed_coverUri.value, name: parsed_name.value, title: parsed_title.value } };
                  })(value);
                });
                if (parsed.find((value) => !value.success) !== undefined) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: parsed.map((entry) => {
                    assert(entry.success == true);
                    return entry.value;
                  }),
                };
              })(value["relatedActionables"]);
            })();
            if (!parsed_relatedActionables.success) {
              return { success: false };
            }
            if (!hasProp(value, "relatedQuestions")) {
              return { success: false };
            }
            const parsed_relatedQuestions = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string[] } => {
                if (!isArray(value)) {
                  return { success: false };
                }
                const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  return { success: true, value: value };
                });
                if (parsed.find((value) => !value.success) !== undefined) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: parsed.map((entry) => {
                    assert(entry.success == true);
                    return entry.value;
                  }),
                };
              })(value["relatedQuestions"]);
            })();
            if (!parsed_relatedQuestions.success) {
              return { success: false };
            }
            if (!hasProp(value, "status")) {
              return { success: false };
            }
            const parsed_status = (() => {
              return ((value: unknown): { success: false } | { success: true; value: GetabstractAiAnswerStatus } => {
                return ((value: unknown): { success: false } | { success: true; value: "PROCESSING" | "FINISHED" | "NO_DOCS_FROM_VECTOR_DB" | "NO_DOCS_AFTER_FILTER" | "NO_REFERENCE_IN_ANSWER" | "GENERAL_ERROR" | "TIMEOUT" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "PROCESSING":
                      return { success: true, value: "PROCESSING" };
                    case "FINISHED":
                      return { success: true, value: "FINISHED" };
                    case "NO_DOCS_FROM_VECTOR_DB":
                      return { success: true, value: "NO_DOCS_FROM_VECTOR_DB" };
                    case "NO_DOCS_AFTER_FILTER":
                      return { success: true, value: "NO_DOCS_AFTER_FILTER" };
                    case "NO_REFERENCE_IN_ANSWER":
                      return { success: true, value: "NO_REFERENCE_IN_ANSWER" };
                    case "GENERAL_ERROR":
                      return { success: true, value: "GENERAL_ERROR" };
                    case "TIMEOUT":
                      return { success: true, value: "TIMEOUT" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["status"]);
            })();
            if (!parsed_status.success) {
              return { success: false };
            }
            return {
              success: true,
              value: {
                answer: parsed_answer.value,
                contextDocs: parsed_contextDocs.value,
                detectedLanguageLabel: parsed_detectedLanguageLabel.value,
                previousQuestionUuid: parsed_previousQuestionUuid.value,
                question: parsed_question.value,
                questionUuid: parsed_questionUuid.value,
                relatedActionables: parsed_relatedActionables.value,
                relatedQuestions: parsed_relatedQuestions.value,
                status: parsed_status.value,
              },
            };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["history"]);
    })();
    if (!parsed_history.success) {
      return { success: false };
    }
    return { success: true, value: { current: parsed_current.value, history: parsed_history.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ItemLayout
export function serialize_ItemLayout(obj: ItemLayout): string {
  const serialized = ((value: "MINI" | "REGULAR"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ItemLayout(str: string): ItemLayout | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "MINI" | "REGULAR" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "MINI":
        return { success: true, value: "MINI" };
      case "REGULAR":
        return { success: true, value: "REGULAR" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// KindChannel
export function serialize_KindChannel(obj: KindChannel): string {
  const serialized = ((value: "channel"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_KindChannel(str: string): KindChannel | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "channel" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "channel":
        return { success: true, value: "channel" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// KindClassification
export function serialize_KindClassification(obj: KindClassification): string {
  const serialized = ((value: "classification"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_KindClassification(str: string): KindClassification | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "classification" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "classification":
        return { success: true, value: "classification" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// KindDynamicBoolean
export function serialize_KindDynamicBoolean(obj: KindDynamicBoolean): string {
  const serialized = ((value: "dynamicBoolean"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_KindDynamicBoolean(str: string): KindDynamicBoolean | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "dynamicBoolean" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "dynamicBoolean":
        return { success: true, value: "dynamicBoolean" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// KindDynamicNumber
export function serialize_KindDynamicNumber(obj: KindDynamicNumber): string {
  const serialized = ((value: "dynamicNumber"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_KindDynamicNumber(str: string): KindDynamicNumber | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "dynamicNumber" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "dynamicNumber":
        return { success: true, value: "dynamicNumber" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// KindDynamicString
export function serialize_KindDynamicString(obj: KindDynamicString): string {
  const serialized = ((value: "dynamicString"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_KindDynamicString(str: string): KindDynamicString | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "dynamicString" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "dynamicString":
        return { success: true, value: "dynamicString" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// KindDynamicVoid
export function serialize_KindDynamicVoid(obj: KindDynamicVoid): string {
  const serialized = ((value: "dynamicVoid"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_KindDynamicVoid(str: string): KindDynamicVoid | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "dynamicVoid" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "dynamicVoid":
        return { success: true, value: "dynamicVoid" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// KindKeyQuality
export function serialize_KindKeyQuality(obj: KindKeyQuality): string {
  const serialized = ((value: "key-quality"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_KindKeyQuality(str: string): KindKeyQuality | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "key-quality":
        return { success: true, value: "key-quality" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// Language
export function serialize_Language(obj: Language): string {
  const serialized = ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_Language(str: string): Language | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "en":
        return { success: true, value: "en" };
      case "de":
        return { success: true, value: "de" };
      case "es":
        return { success: true, value: "es" };
      case "ru":
        return { success: true, value: "ru" };
      case "zh":
        return { success: true, value: "zh" };
      case "pt":
        return { success: true, value: "pt" };
      case "fr":
        return { success: true, value: "fr" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// LearningItemAdminActionableView
export function serialize_LearningItemAdminActionableView(obj: LearningItemAdminActionableView): string {
  const serialized = ((value: { description: string; learningItemType: LearningItemTypeActionable; metadata: LearningItemAdminMetadataView }): unknown => {
    return {
      description: ((value: string): unknown => {
        return value;
      })(value["description"]),
      learningItemType: ((value: "ACTIONABLE"): unknown => {
        return value;
      })(value["learningItemType"]),
      metadata: ((value: {
        activatedAt: { year: number; month: number; day: number } | null;
        active: boolean;
        createdAt: { year: number; month: number; day: number };
        deactivatedAt: { year: number; month: number; day: number } | null;
        id: bigint;
        language: Language;
        manager: string | null;
        tags: Tag[];
        title: string;
      }): unknown => {
        return {
          activatedAt: ((value: { year: number; month: number; day: number } | null): unknown => {
            if (value === null) {
              return null;
            }
            return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
          })(value["activatedAt"]),
          active: ((value: boolean): unknown => {
            return value;
          })(value["active"]),
          createdAt: ((value: { year: number; month: number; day: number }): unknown => {
            return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
          })(value["createdAt"]),
          deactivatedAt: ((value: { year: number; month: number; day: number } | null): unknown => {
            if (value === null) {
              return null;
            }
            return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
          })(value["deactivatedAt"]),
          id: ((value: bigint): unknown => {
            return value;
          })(value["id"]),
          language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
            return value;
          })(value["language"]),
          manager: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["manager"]),
          tags: ((value: Tag[]): unknown => {
            return value.map(
              (
                value:
                  | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                  | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                  | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                  | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                  | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                  | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                  | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string },
              ): unknown => {
                switch (value.discriminator) {
                  case "dynamicString": {
                    return {
                      discriminator: "dynamicString",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindDynamicString; id: bigint; kind: string }): unknown => {
                        return {
                          discriminator: ((value: "dynamicString"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: string): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: string): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "dynamicBoolean": {
                    return {
                      discriminator: "dynamicBoolean",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindDynamicBoolean; id: bigint; kind: string }): unknown => {
                        return {
                          discriminator: ((value: "dynamicBoolean"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: string): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: boolean): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "dynamicNumber": {
                    return {
                      discriminator: "dynamicNumber",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindDynamicNumber; id: bigint; kind: string }): unknown => {
                        return {
                          discriminator: ((value: "dynamicNumber"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: string): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: number): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "dynamicVoid": {
                    return {
                      discriminator: "dynamicVoid",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindDynamicVoid; id: bigint; kind: string }): unknown => {
                        return {
                          discriminator: ((value: "dynamicVoid"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: string): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                    };
                  }
                  case "channel": {
                    return {
                      discriminator: "channel",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindChannel; id: bigint; kind: KindChannel }): unknown => {
                        return {
                          discriminator: ((value: "channel"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: "channel"): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: string): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "key-quality": {
                    return {
                      discriminator: "key-quality",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindKeyQuality; id: bigint; kind: KindKeyQuality }): unknown => {
                        return {
                          discriminator: ((value: "key-quality"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: "key-quality"): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: string): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "classification": {
                    return {
                      discriminator: "classification",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindClassification; id: bigint; kind: KindClassification }): unknown => {
                        return {
                          discriminator: ((value: "classification"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: "classification"): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: string): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                }
              },
            );
          })(value["tags"]),
          title: ((value: string): unknown => {
            return value;
          })(value["title"]),
        };
      })(value["metadata"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_LearningItemAdminActionableView(str: string): LearningItemAdminActionableView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          description: string;
          learningItemType: LearningItemTypeActionable;
          metadata: LearningItemAdminMetadataView;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "description")) {
      return { success: false };
    }
    const parsed_description = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["description"]);
    })();
    if (!parsed_description.success) {
      return { success: false };
    }
    if (!hasProp(value, "learningItemType")) {
      return { success: false };
    }
    const parsed_learningItemType = (() => {
      return ((value: unknown): { success: false } | { success: true; value: LearningItemTypeActionable } => {
        return ((value: unknown): { success: false } | { success: true; value: "ACTIONABLE" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "ACTIONABLE":
              return { success: true, value: "ACTIONABLE" };
            default:
              return { success: false };
          }
        })(value);
      })(value["learningItemType"]);
    })();
    if (!parsed_learningItemType.success) {
      return { success: false };
    }
    if (!hasProp(value, "metadata")) {
      return { success: false };
    }
    const parsed_metadata = (() => {
      return ((value: unknown): { success: false } | { success: true; value: LearningItemAdminMetadataView } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                activatedAt: { year: number; month: number; day: number } | null;
                active: boolean;
                createdAt: { year: number; month: number; day: number };
                deactivatedAt: { year: number; month: number; day: number } | null;
                id: bigint;
                language: Language;
                manager: string | null;
                tags: Tag[];
                title: string;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "activatedAt")) {
            return { success: false };
          }
          const parsed_activatedAt = (() => {
            return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
              if (!parsed.isValid) {
                return { success: false };
              }
              return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
            })(value["activatedAt"]);
          })();
          if (!parsed_activatedAt.success) {
            return { success: false };
          }
          if (!hasProp(value, "active")) {
            return { success: false };
          }
          const parsed_active = (() => {
            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
              if (typeof value !== "boolean") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["active"]);
          })();
          if (!parsed_active.success) {
            return { success: false };
          }
          if (!hasProp(value, "createdAt")) {
            return { success: false };
          }
          const parsed_createdAt = (() => {
            return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
              if (!parsed.isValid) {
                return { success: false };
              }
              return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
            })(value["createdAt"]);
          })();
          if (!parsed_createdAt.success) {
            return { success: false };
          }
          if (!hasProp(value, "deactivatedAt")) {
            return { success: false };
          }
          const parsed_deactivatedAt = (() => {
            return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
              if (!parsed.isValid) {
                return { success: false };
              }
              return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
            })(value["deactivatedAt"]);
          })();
          if (!parsed_deactivatedAt.success) {
            return { success: false };
          }
          if (!hasProp(value, "id")) {
            return { success: false };
          }
          const parsed_id = (() => {
            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
              if (typeof value === "number") {
                return { success: true, value: BigInt(value) };
              }
              if (typeof value === "bigint") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["id"]);
          })();
          if (!parsed_id.success) {
            return { success: false };
          }
          if (!hasProp(value, "language")) {
            return { success: false };
          }
          const parsed_language = (() => {
            return ((value: unknown): { success: false } | { success: true; value: Language } => {
              return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "en":
                    return { success: true, value: "en" };
                  case "de":
                    return { success: true, value: "de" };
                  case "es":
                    return { success: true, value: "es" };
                  case "ru":
                    return { success: true, value: "ru" };
                  case "zh":
                    return { success: true, value: "zh" };
                  case "pt":
                    return { success: true, value: "pt" };
                  case "fr":
                    return { success: true, value: "fr" };
                  default:
                    return { success: false };
                }
              })(value);
            })(value["language"]);
          })();
          if (!parsed_language.success) {
            return { success: false };
          }
          if (!hasProp(value, "manager")) {
            return { success: false };
          }
          const parsed_manager = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["manager"]);
          })();
          if (!parsed_manager.success) {
            return { success: false };
          }
          if (!hasProp(value, "tags")) {
            return { success: false };
          }
          const parsed_tags = (() => {
            return ((value: unknown): { success: false } | { success: true; value: Tag[] } => {
              if (!isArray(value)) {
                return { success: false };
              }
              const parsed = value.map((value: unknown): { success: false } | { success: true; value: Tag } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value:
                        | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                        | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                        | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                        | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                        | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                        | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                        | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                    } => {
                  return ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value:
                          | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                          | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                          | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                          | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                          | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                          | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                          | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                      } => {
                    if (value == null) {
                      return { success: false };
                    }
                    if (typeof value != "object") {
                      return { success: false };
                    }
                    if (!hasProp(value, "discriminator")) {
                      return { success: false };
                    }
                    if (typeof value["discriminator"] != "string") {
                      return { success: false };
                    }
                    const discriminator = value["discriminator"];
                    switch (discriminator) {
                      case "dynamicString": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindDynamicString;
                                value: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "CHANNEL_HIERARCHY":
                                    return { success: true, value: "CHANNEL_HIERARCHY" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicString } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindDynamicString;
                                      id: bigint;
                                      kind: string;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindDynamicString } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "dynamicString" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "dynamicString":
                                          return { success: true, value: "dynamicString" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                                    if (typeof value !== "string") {
                                      return { success: false };
                                    }
                                    return { success: true, value: value };
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "dynamicString" as const, ...parsedVariant.value } };
                      }
                      case "dynamicBoolean": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindDynamicBoolean;
                                value: boolean;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "CHANNEL_HIERARCHY":
                                    return { success: true, value: "CHANNEL_HIERARCHY" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicBoolean } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindDynamicBoolean;
                                      id: bigint;
                                      kind: string;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindDynamicBoolean } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "dynamicBoolean" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "dynamicBoolean":
                                          return { success: true, value: "dynamicBoolean" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                                    if (typeof value !== "string") {
                                      return { success: false };
                                    }
                                    return { success: true, value: value };
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "dynamicBoolean" as const, ...parsedVariant.value } };
                      }
                      case "dynamicNumber": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindDynamicNumber;
                                value: number;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "CHANNEL_HIERARCHY":
                                    return { success: true, value: "CHANNEL_HIERARCHY" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicNumber } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindDynamicNumber;
                                      id: bigint;
                                      kind: string;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindDynamicNumber } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "dynamicNumber" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "dynamicNumber":
                                          return { success: true, value: "dynamicNumber" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                                    if (typeof value !== "string") {
                                      return { success: false };
                                    }
                                    return { success: true, value: value };
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: number } => {
                              if (typeof value === "number") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "dynamicNumber" as const, ...parsedVariant.value } };
                      }
                      case "dynamicVoid": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindDynamicVoid;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "CHANNEL_HIERARCHY":
                                    return { success: true, value: "CHANNEL_HIERARCHY" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicVoid } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindDynamicVoid;
                                      id: bigint;
                                      kind: string;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindDynamicVoid } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "dynamicVoid" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "dynamicVoid":
                                          return { success: true, value: "dynamicVoid" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                                    if (typeof value !== "string") {
                                      return { success: false };
                                    }
                                    return { success: true, value: value };
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "dynamicVoid" as const, ...parsedVariant.value } };
                      }
                      case "channel": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindChannel;
                                value: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "CHANNEL_HIERARCHY":
                                    return { success: true, value: "CHANNEL_HIERARCHY" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindChannel } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindChannel;
                                      id: bigint;
                                      kind: KindChannel;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "channel":
                                          return { success: true, value: "channel" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "channel":
                                          return { success: true, value: "channel" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "channel" as const, ...parsedVariant.value } };
                      }
                      case "key-quality": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindKeyQuality;
                                value: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "CHANNEL_HIERARCHY":
                                    return { success: true, value: "CHANNEL_HIERARCHY" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindKeyQuality } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindKeyQuality;
                                      id: bigint;
                                      kind: KindKeyQuality;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "key-quality":
                                          return { success: true, value: "key-quality" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "key-quality":
                                          return { success: true, value: "key-quality" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "key-quality" as const, ...parsedVariant.value } };
                      }
                      case "classification": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindClassification;
                                value: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "CHANNEL_HIERARCHY":
                                    return { success: true, value: "CHANNEL_HIERARCHY" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindClassification } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindClassification;
                                      id: bigint;
                                      kind: KindClassification;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "classification":
                                          return { success: true, value: "classification" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "classification":
                                          return { success: true, value: "classification" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "classification" as const, ...parsedVariant.value } };
                      }
                      default:
                        return { success: false };
                    }
                  })(value);
                })(value);
              });
              if (parsed.find((value) => !value.success) !== undefined) {
                return { success: false };
              }
              return {
                success: true,
                value: parsed.map((entry) => {
                  assert(entry.success == true);
                  return entry.value;
                }),
              };
            })(value["tags"]);
          })();
          if (!parsed_tags.success) {
            return { success: false };
          }
          if (!hasProp(value, "title")) {
            return { success: false };
          }
          const parsed_title = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["title"]);
          })();
          if (!parsed_title.success) {
            return { success: false };
          }
          return {
            success: true,
            value: {
              activatedAt: parsed_activatedAt.value,
              active: parsed_active.value,
              createdAt: parsed_createdAt.value,
              deactivatedAt: parsed_deactivatedAt.value,
              id: parsed_id.value,
              language: parsed_language.value,
              manager: parsed_manager.value,
              tags: parsed_tags.value,
              title: parsed_title.value,
            },
          };
        })(value);
      })(value["metadata"]);
    })();
    if (!parsed_metadata.success) {
      return { success: false };
    }
    return { success: true, value: { description: parsed_description.value, learningItemType: parsed_learningItemType.value, metadata: parsed_metadata.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// LearningItemAdminFilter
export function serialize_LearningItemAdminFilter(obj: LearningItemAdminFilter): string {
  const serialized = ((value: {
    activatedAtRange: DateRange | null;
    active: boolean | null;
    audio: boolean | null;
    createdAtRange: DateRange | null;
    documentTypes: SummaryDocumentType[];
    ids: number[];
    itemTypes: LearningItemType[];
    languages: Language[];
    manager: string | null;
    publisher: string | null;
    resultSize: number;
    sourceTypes: SummarySourceType[];
    tagCount: number | null;
    tags: Tag[];
    title: string | null;
  }): unknown => {
    return {
      activatedAtRange: ((
        value: {
          from: { year: number; month: number; day: number };
          to: { year: number; month: number; day: number };
        } | null,
      ): unknown => {
        if (value === null) {
          return null;
        }
        return {
          from: ((value: { year: number; month: number; day: number }): unknown => {
            return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
          })(value["from"]),
          to: ((value: { year: number; month: number; day: number }): unknown => {
            return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
          })(value["to"]),
        };
      })(value["activatedAtRange"]),
      active: ((value: boolean | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["active"]),
      audio: ((value: boolean | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["audio"]),
      createdAtRange: ((
        value: {
          from: { year: number; month: number; day: number };
          to: { year: number; month: number; day: number };
        } | null,
      ): unknown => {
        if (value === null) {
          return null;
        }
        return {
          from: ((value: { year: number; month: number; day: number }): unknown => {
            return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
          })(value["from"]),
          to: ((value: { year: number; month: number; day: number }): unknown => {
            return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
          })(value["to"]),
        };
      })(value["createdAtRange"]),
      documentTypes: ((value: SummaryDocumentType[]): unknown => {
        return value.map((value: "ABSTRACT" | "REVIEW"): unknown => {
          return value;
        });
      })(value["documentTypes"]),
      ids: ((value: number[]): unknown => {
        return value.map((value: number): unknown => {
          return value;
        });
      })(value["ids"]),
      itemTypes: ((value: LearningItemType[]): unknown => {
        return value.map((value: "SUMMARY" | "ACTIONABLE"): unknown => {
          return value;
        });
      })(value["itemTypes"]),
      languages: ((value: Language[]): unknown => {
        return value.map((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
          return value;
        });
      })(value["languages"]),
      manager: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["manager"]),
      publisher: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["publisher"]),
      resultSize: ((value: number): unknown => {
        return value;
      })(value["resultSize"]),
      sourceTypes: ((value: SummarySourceType[]): unknown => {
        return value.map((value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST"): unknown => {
          return value;
        });
      })(value["sourceTypes"]),
      tagCount: ((value: number | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["tagCount"]),
      tags: ((value: Tag[]): unknown => {
        return value.map(
          (
            value:
              | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
              | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
              | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
              | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
              | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
              | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
              | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string },
          ): unknown => {
            switch (value.discriminator) {
              case "dynamicString": {
                return {
                  discriminator: "dynamicString",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindDynamicString; id: bigint; kind: string }): unknown => {
                    return {
                      discriminator: ((value: "dynamicString"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: string): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: string): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
              case "dynamicBoolean": {
                return {
                  discriminator: "dynamicBoolean",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindDynamicBoolean; id: bigint; kind: string }): unknown => {
                    return {
                      discriminator: ((value: "dynamicBoolean"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: string): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: boolean): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
              case "dynamicNumber": {
                return {
                  discriminator: "dynamicNumber",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindDynamicNumber; id: bigint; kind: string }): unknown => {
                    return {
                      discriminator: ((value: "dynamicNumber"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: string): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: number): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
              case "dynamicVoid": {
                return {
                  discriminator: "dynamicVoid",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindDynamicVoid; id: bigint; kind: string }): unknown => {
                    return {
                      discriminator: ((value: "dynamicVoid"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: string): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                };
              }
              case "channel": {
                return {
                  discriminator: "channel",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindChannel; id: bigint; kind: KindChannel }): unknown => {
                    return {
                      discriminator: ((value: "channel"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: "channel"): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: string): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
              case "key-quality": {
                return {
                  discriminator: "key-quality",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindKeyQuality; id: bigint; kind: KindKeyQuality }): unknown => {
                    return {
                      discriminator: ((value: "key-quality"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: "key-quality"): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: string): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
              case "classification": {
                return {
                  discriminator: "classification",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindClassification; id: bigint; kind: KindClassification }): unknown => {
                    return {
                      discriminator: ((value: "classification"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: "classification"): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: string): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
            }
          },
        );
      })(value["tags"]),
      title: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["title"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_LearningItemAdminFilter(str: string): LearningItemAdminFilter | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          activatedAtRange: DateRange | null;
          active: boolean | null;
          audio: boolean | null;
          createdAtRange: DateRange | null;
          documentTypes: SummaryDocumentType[];
          ids: number[];
          itemTypes: LearningItemType[];
          languages: Language[];
          manager: string | null;
          publisher: string | null;
          resultSize: number;
          sourceTypes: SummarySourceType[];
          tagCount: number | null;
          tags: Tag[];
          title: string | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "activatedAtRange")) {
      return { success: false };
    }
    const parsed_activatedAtRange = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateRange | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                from: { year: number; month: number; day: number };
                to: { year: number; month: number; day: number };
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "from")) {
            return { success: false };
          }
          const parsed_from = (() => {
            return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
              if (!parsed.isValid) {
                return { success: false };
              }
              return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
            })(value["from"]);
          })();
          if (!parsed_from.success) {
            return { success: false };
          }
          if (!hasProp(value, "to")) {
            return { success: false };
          }
          const parsed_to = (() => {
            return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
              if (!parsed.isValid) {
                return { success: false };
              }
              return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
            })(value["to"]);
          })();
          if (!parsed_to.success) {
            return { success: false };
          }
          return { success: true, value: { from: parsed_from.value, to: parsed_to.value } };
        })(value);
      })(value["activatedAtRange"]);
    })();
    if (!parsed_activatedAtRange.success) {
      return { success: false };
    }
    if (!hasProp(value, "active")) {
      return { success: false };
    }
    const parsed_active = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["active"]);
    })();
    if (!parsed_active.success) {
      return { success: false };
    }
    if (!hasProp(value, "audio")) {
      return { success: false };
    }
    const parsed_audio = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["audio"]);
    })();
    if (!parsed_audio.success) {
      return { success: false };
    }
    if (!hasProp(value, "createdAtRange")) {
      return { success: false };
    }
    const parsed_createdAtRange = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateRange | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                from: { year: number; month: number; day: number };
                to: { year: number; month: number; day: number };
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "from")) {
            return { success: false };
          }
          const parsed_from = (() => {
            return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
              if (!parsed.isValid) {
                return { success: false };
              }
              return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
            })(value["from"]);
          })();
          if (!parsed_from.success) {
            return { success: false };
          }
          if (!hasProp(value, "to")) {
            return { success: false };
          }
          const parsed_to = (() => {
            return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
              if (!parsed.isValid) {
                return { success: false };
              }
              return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
            })(value["to"]);
          })();
          if (!parsed_to.success) {
            return { success: false };
          }
          return { success: true, value: { from: parsed_from.value, to: parsed_to.value } };
        })(value);
      })(value["createdAtRange"]);
    })();
    if (!parsed_createdAtRange.success) {
      return { success: false };
    }
    if (!hasProp(value, "documentTypes")) {
      return { success: false };
    }
    const parsed_documentTypes = (() => {
      return ((value: unknown): { success: false } | { success: true; value: SummaryDocumentType[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: SummaryDocumentType } => {
          return ((value: unknown): { success: false } | { success: true; value: "ABSTRACT" | "REVIEW" } => {
            if (typeof value !== "string") {
              return { success: false };
            }
            switch (value) {
              case "ABSTRACT":
                return { success: true, value: "ABSTRACT" };
              case "REVIEW":
                return { success: true, value: "REVIEW" };
              default:
                return { success: false };
            }
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["documentTypes"]);
    })();
    if (!parsed_documentTypes.success) {
      return { success: false };
    }
    if (!hasProp(value, "ids")) {
      return { success: false };
    }
    const parsed_ids = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: number } => {
          if (typeof value === "number") {
            return { success: true, value: value };
          }
          return { success: false };
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["ids"]);
    })();
    if (!parsed_ids.success) {
      return { success: false };
    }
    if (!hasProp(value, "itemTypes")) {
      return { success: false };
    }
    const parsed_itemTypes = (() => {
      return ((value: unknown): { success: false } | { success: true; value: LearningItemType[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: LearningItemType } => {
          return ((value: unknown): { success: false } | { success: true; value: "SUMMARY" | "ACTIONABLE" } => {
            if (typeof value !== "string") {
              return { success: false };
            }
            switch (value) {
              case "SUMMARY":
                return { success: true, value: "SUMMARY" };
              case "ACTIONABLE":
                return { success: true, value: "ACTIONABLE" };
              default:
                return { success: false };
            }
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["itemTypes"]);
    })();
    if (!parsed_itemTypes.success) {
      return { success: false };
    }
    if (!hasProp(value, "languages")) {
      return { success: false };
    }
    const parsed_languages = (() => {
      return ((value: unknown): { success: false } | { success: true; value: Language[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: Language } => {
          return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
            if (typeof value !== "string") {
              return { success: false };
            }
            switch (value) {
              case "en":
                return { success: true, value: "en" };
              case "de":
                return { success: true, value: "de" };
              case "es":
                return { success: true, value: "es" };
              case "ru":
                return { success: true, value: "ru" };
              case "zh":
                return { success: true, value: "zh" };
              case "pt":
                return { success: true, value: "pt" };
              case "fr":
                return { success: true, value: "fr" };
              default:
                return { success: false };
            }
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["languages"]);
    })();
    if (!parsed_languages.success) {
      return { success: false };
    }
    if (!hasProp(value, "manager")) {
      return { success: false };
    }
    const parsed_manager = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["manager"]);
    })();
    if (!parsed_manager.success) {
      return { success: false };
    }
    if (!hasProp(value, "publisher")) {
      return { success: false };
    }
    const parsed_publisher = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["publisher"]);
    })();
    if (!parsed_publisher.success) {
      return { success: false };
    }
    if (!hasProp(value, "resultSize")) {
      return { success: false };
    }
    const parsed_resultSize = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["resultSize"]);
    })();
    if (!parsed_resultSize.success) {
      return { success: false };
    }
    if (!hasProp(value, "sourceTypes")) {
      return { success: false };
    }
    const parsed_sourceTypes = (() => {
      return ((value: unknown): { success: false } | { success: true; value: SummarySourceType[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: SummarySourceType } => {
          return ((value: unknown): { success: false } | { success: true; value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST" } => {
            if (typeof value !== "string") {
              return { success: false };
            }
            switch (value) {
              case "BOOK":
                return { success: true, value: "BOOK" };
              case "VIDEO":
                return { success: true, value: "VIDEO" };
              case "ARTICLE":
                return { success: true, value: "ARTICLE" };
              case "CHAPTER":
                return { success: true, value: "CHAPTER" };
              case "REPORT":
                return { success: true, value: "REPORT" };
              case "PODCAST":
                return { success: true, value: "PODCAST" };
              default:
                return { success: false };
            }
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["sourceTypes"]);
    })();
    if (!parsed_sourceTypes.success) {
      return { success: false };
    }
    if (!hasProp(value, "tagCount")) {
      return { success: false };
    }
    const parsed_tagCount = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["tagCount"]);
    })();
    if (!parsed_tagCount.success) {
      return { success: false };
    }
    if (!hasProp(value, "tags")) {
      return { success: false };
    }
    const parsed_tags = (() => {
      return ((value: unknown): { success: false } | { success: true; value: Tag[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: Tag } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value:
                  | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                  | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                  | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                  | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                  | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                  | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                  | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
              } => {
            return ((
              value: unknown,
            ):
              | { success: false }
              | {
                  success: true;
                  value:
                    | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                    | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                    | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                    | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                    | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                    | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                    | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                } => {
              if (value == null) {
                return { success: false };
              }
              if (typeof value != "object") {
                return { success: false };
              }
              if (!hasProp(value, "discriminator")) {
                return { success: false };
              }
              if (typeof value["discriminator"] != "string") {
                return { success: false };
              }
              const discriminator = value["discriminator"];
              switch (discriminator) {
                case "dynamicString": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindDynamicString;
                          value: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "CHANNEL_HIERARCHY":
                              return { success: true, value: "CHANNEL_HIERARCHY" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicString } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindDynamicString;
                                id: bigint;
                                kind: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindDynamicString } => {
                              return ((value: unknown): { success: false } | { success: true; value: "dynamicString" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "dynamicString":
                                    return { success: true, value: "dynamicString" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "dynamicString" as const, ...parsedVariant.value } };
                }
                case "dynamicBoolean": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindDynamicBoolean;
                          value: boolean;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "CHANNEL_HIERARCHY":
                              return { success: true, value: "CHANNEL_HIERARCHY" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicBoolean } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindDynamicBoolean;
                                id: bigint;
                                kind: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindDynamicBoolean } => {
                              return ((value: unknown): { success: false } | { success: true; value: "dynamicBoolean" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "dynamicBoolean":
                                    return { success: true, value: "dynamicBoolean" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "dynamicBoolean" as const, ...parsedVariant.value } };
                }
                case "dynamicNumber": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindDynamicNumber;
                          value: number;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "CHANNEL_HIERARCHY":
                              return { success: true, value: "CHANNEL_HIERARCHY" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicNumber } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindDynamicNumber;
                                id: bigint;
                                kind: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindDynamicNumber } => {
                              return ((value: unknown): { success: false } | { success: true; value: "dynamicNumber" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "dynamicNumber":
                                    return { success: true, value: "dynamicNumber" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: number } => {
                        if (typeof value === "number") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "dynamicNumber" as const, ...parsedVariant.value } };
                }
                case "dynamicVoid": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindDynamicVoid;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "CHANNEL_HIERARCHY":
                              return { success: true, value: "CHANNEL_HIERARCHY" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicVoid } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindDynamicVoid;
                                id: bigint;
                                kind: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindDynamicVoid } => {
                              return ((value: unknown): { success: false } | { success: true; value: "dynamicVoid" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "dynamicVoid":
                                    return { success: true, value: "dynamicVoid" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "dynamicVoid" as const, ...parsedVariant.value } };
                }
                case "channel": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindChannel;
                          value: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "CHANNEL_HIERARCHY":
                              return { success: true, value: "CHANNEL_HIERARCHY" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindChannel } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindChannel;
                                id: bigint;
                                kind: KindChannel;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                              return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "channel":
                                    return { success: true, value: "channel" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                              return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "channel":
                                    return { success: true, value: "channel" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "channel" as const, ...parsedVariant.value } };
                }
                case "key-quality": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindKeyQuality;
                          value: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "CHANNEL_HIERARCHY":
                              return { success: true, value: "CHANNEL_HIERARCHY" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindKeyQuality } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindKeyQuality;
                                id: bigint;
                                kind: KindKeyQuality;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                              return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "key-quality":
                                    return { success: true, value: "key-quality" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                              return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "key-quality":
                                    return { success: true, value: "key-quality" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "key-quality" as const, ...parsedVariant.value } };
                }
                case "classification": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindClassification;
                          value: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "CHANNEL_HIERARCHY":
                              return { success: true, value: "CHANNEL_HIERARCHY" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindClassification } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindClassification;
                                id: bigint;
                                kind: KindClassification;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                              return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "classification":
                                    return { success: true, value: "classification" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                              return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "classification":
                                    return { success: true, value: "classification" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "classification" as const, ...parsedVariant.value } };
                }
                default:
                  return { success: false };
              }
            })(value);
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["tags"]);
    })();
    if (!parsed_tags.success) {
      return { success: false };
    }
    if (!hasProp(value, "title")) {
      return { success: false };
    }
    const parsed_title = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["title"]);
    })();
    if (!parsed_title.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        activatedAtRange: parsed_activatedAtRange.value,
        active: parsed_active.value,
        audio: parsed_audio.value,
        createdAtRange: parsed_createdAtRange.value,
        documentTypes: parsed_documentTypes.value,
        ids: parsed_ids.value,
        itemTypes: parsed_itemTypes.value,
        languages: parsed_languages.value,
        manager: parsed_manager.value,
        publisher: parsed_publisher.value,
        resultSize: parsed_resultSize.value,
        sourceTypes: parsed_sourceTypes.value,
        tagCount: parsed_tagCount.value,
        tags: parsed_tags.value,
        title: parsed_title.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// LearningItemAdminMetadataView
export function serialize_LearningItemAdminMetadataView(obj: LearningItemAdminMetadataView): string {
  const serialized = ((value: {
    activatedAt: { year: number; month: number; day: number } | null;
    active: boolean;
    createdAt: { year: number; month: number; day: number };
    deactivatedAt: { year: number; month: number; day: number } | null;
    id: bigint;
    language: Language;
    manager: string | null;
    tags: Tag[];
    title: string;
  }): unknown => {
    return {
      activatedAt: ((value: { year: number; month: number; day: number } | null): unknown => {
        if (value === null) {
          return null;
        }
        return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
      })(value["activatedAt"]),
      active: ((value: boolean): unknown => {
        return value;
      })(value["active"]),
      createdAt: ((value: { year: number; month: number; day: number }): unknown => {
        return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
      })(value["createdAt"]),
      deactivatedAt: ((value: { year: number; month: number; day: number } | null): unknown => {
        if (value === null) {
          return null;
        }
        return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
      })(value["deactivatedAt"]),
      id: ((value: bigint): unknown => {
        return value;
      })(value["id"]),
      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
        return value;
      })(value["language"]),
      manager: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["manager"]),
      tags: ((value: Tag[]): unknown => {
        return value.map(
          (
            value:
              | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
              | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
              | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
              | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
              | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
              | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
              | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string },
          ): unknown => {
            switch (value.discriminator) {
              case "dynamicString": {
                return {
                  discriminator: "dynamicString",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindDynamicString; id: bigint; kind: string }): unknown => {
                    return {
                      discriminator: ((value: "dynamicString"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: string): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: string): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
              case "dynamicBoolean": {
                return {
                  discriminator: "dynamicBoolean",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindDynamicBoolean; id: bigint; kind: string }): unknown => {
                    return {
                      discriminator: ((value: "dynamicBoolean"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: string): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: boolean): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
              case "dynamicNumber": {
                return {
                  discriminator: "dynamicNumber",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindDynamicNumber; id: bigint; kind: string }): unknown => {
                    return {
                      discriminator: ((value: "dynamicNumber"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: string): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: number): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
              case "dynamicVoid": {
                return {
                  discriminator: "dynamicVoid",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindDynamicVoid; id: bigint; kind: string }): unknown => {
                    return {
                      discriminator: ((value: "dynamicVoid"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: string): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                };
              }
              case "channel": {
                return {
                  discriminator: "channel",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindChannel; id: bigint; kind: KindChannel }): unknown => {
                    return {
                      discriminator: ((value: "channel"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: "channel"): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: string): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
              case "key-quality": {
                return {
                  discriminator: "key-quality",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindKeyQuality; id: bigint; kind: KindKeyQuality }): unknown => {
                    return {
                      discriminator: ((value: "key-quality"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: "key-quality"): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: string): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
              case "classification": {
                return {
                  discriminator: "classification",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindClassification; id: bigint; kind: KindClassification }): unknown => {
                    return {
                      discriminator: ((value: "classification"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: "classification"): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: string): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
            }
          },
        );
      })(value["tags"]),
      title: ((value: string): unknown => {
        return value;
      })(value["title"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_LearningItemAdminMetadataView(str: string): LearningItemAdminMetadataView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          activatedAt: { year: number; month: number; day: number } | null;
          active: boolean;
          createdAt: { year: number; month: number; day: number };
          deactivatedAt: { year: number; month: number; day: number } | null;
          id: bigint;
          language: Language;
          manager: string | null;
          tags: Tag[];
          title: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "activatedAt")) {
      return { success: false };
    }
    const parsed_activatedAt = (() => {
      return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
      })(value["activatedAt"]);
    })();
    if (!parsed_activatedAt.success) {
      return { success: false };
    }
    if (!hasProp(value, "active")) {
      return { success: false };
    }
    const parsed_active = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["active"]);
    })();
    if (!parsed_active.success) {
      return { success: false };
    }
    if (!hasProp(value, "createdAt")) {
      return { success: false };
    }
    const parsed_createdAt = (() => {
      return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
      })(value["createdAt"]);
    })();
    if (!parsed_createdAt.success) {
      return { success: false };
    }
    if (!hasProp(value, "deactivatedAt")) {
      return { success: false };
    }
    const parsed_deactivatedAt = (() => {
      return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
      })(value["deactivatedAt"]);
    })();
    if (!parsed_deactivatedAt.success) {
      return { success: false };
    }
    if (!hasProp(value, "id")) {
      return { success: false };
    }
    const parsed_id = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["id"]);
    })();
    if (!parsed_id.success) {
      return { success: false };
    }
    if (!hasProp(value, "language")) {
      return { success: false };
    }
    const parsed_language = (() => {
      return ((value: unknown): { success: false } | { success: true; value: Language } => {
        return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "en":
              return { success: true, value: "en" };
            case "de":
              return { success: true, value: "de" };
            case "es":
              return { success: true, value: "es" };
            case "ru":
              return { success: true, value: "ru" };
            case "zh":
              return { success: true, value: "zh" };
            case "pt":
              return { success: true, value: "pt" };
            case "fr":
              return { success: true, value: "fr" };
            default:
              return { success: false };
          }
        })(value);
      })(value["language"]);
    })();
    if (!parsed_language.success) {
      return { success: false };
    }
    if (!hasProp(value, "manager")) {
      return { success: false };
    }
    const parsed_manager = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["manager"]);
    })();
    if (!parsed_manager.success) {
      return { success: false };
    }
    if (!hasProp(value, "tags")) {
      return { success: false };
    }
    const parsed_tags = (() => {
      return ((value: unknown): { success: false } | { success: true; value: Tag[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: Tag } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value:
                  | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                  | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                  | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                  | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                  | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                  | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                  | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
              } => {
            return ((
              value: unknown,
            ):
              | { success: false }
              | {
                  success: true;
                  value:
                    | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                    | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                    | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                    | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                    | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                    | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                    | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                } => {
              if (value == null) {
                return { success: false };
              }
              if (typeof value != "object") {
                return { success: false };
              }
              if (!hasProp(value, "discriminator")) {
                return { success: false };
              }
              if (typeof value["discriminator"] != "string") {
                return { success: false };
              }
              const discriminator = value["discriminator"];
              switch (discriminator) {
                case "dynamicString": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindDynamicString;
                          value: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "CHANNEL_HIERARCHY":
                              return { success: true, value: "CHANNEL_HIERARCHY" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicString } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindDynamicString;
                                id: bigint;
                                kind: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindDynamicString } => {
                              return ((value: unknown): { success: false } | { success: true; value: "dynamicString" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "dynamicString":
                                    return { success: true, value: "dynamicString" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "dynamicString" as const, ...parsedVariant.value } };
                }
                case "dynamicBoolean": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindDynamicBoolean;
                          value: boolean;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "CHANNEL_HIERARCHY":
                              return { success: true, value: "CHANNEL_HIERARCHY" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicBoolean } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindDynamicBoolean;
                                id: bigint;
                                kind: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindDynamicBoolean } => {
                              return ((value: unknown): { success: false } | { success: true; value: "dynamicBoolean" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "dynamicBoolean":
                                    return { success: true, value: "dynamicBoolean" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "dynamicBoolean" as const, ...parsedVariant.value } };
                }
                case "dynamicNumber": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindDynamicNumber;
                          value: number;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "CHANNEL_HIERARCHY":
                              return { success: true, value: "CHANNEL_HIERARCHY" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicNumber } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindDynamicNumber;
                                id: bigint;
                                kind: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindDynamicNumber } => {
                              return ((value: unknown): { success: false } | { success: true; value: "dynamicNumber" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "dynamicNumber":
                                    return { success: true, value: "dynamicNumber" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: number } => {
                        if (typeof value === "number") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "dynamicNumber" as const, ...parsedVariant.value } };
                }
                case "dynamicVoid": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindDynamicVoid;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "CHANNEL_HIERARCHY":
                              return { success: true, value: "CHANNEL_HIERARCHY" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicVoid } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindDynamicVoid;
                                id: bigint;
                                kind: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindDynamicVoid } => {
                              return ((value: unknown): { success: false } | { success: true; value: "dynamicVoid" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "dynamicVoid":
                                    return { success: true, value: "dynamicVoid" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "dynamicVoid" as const, ...parsedVariant.value } };
                }
                case "channel": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindChannel;
                          value: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "CHANNEL_HIERARCHY":
                              return { success: true, value: "CHANNEL_HIERARCHY" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindChannel } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindChannel;
                                id: bigint;
                                kind: KindChannel;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                              return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "channel":
                                    return { success: true, value: "channel" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                              return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "channel":
                                    return { success: true, value: "channel" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "channel" as const, ...parsedVariant.value } };
                }
                case "key-quality": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindKeyQuality;
                          value: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "CHANNEL_HIERARCHY":
                              return { success: true, value: "CHANNEL_HIERARCHY" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindKeyQuality } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindKeyQuality;
                                id: bigint;
                                kind: KindKeyQuality;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                              return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "key-quality":
                                    return { success: true, value: "key-quality" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                              return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "key-quality":
                                    return { success: true, value: "key-quality" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "key-quality" as const, ...parsedVariant.value } };
                }
                case "classification": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindClassification;
                          value: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "CHANNEL_HIERARCHY":
                              return { success: true, value: "CHANNEL_HIERARCHY" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindClassification } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindClassification;
                                id: bigint;
                                kind: KindClassification;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                              return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "classification":
                                    return { success: true, value: "classification" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                              return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "classification":
                                    return { success: true, value: "classification" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "classification" as const, ...parsedVariant.value } };
                }
                default:
                  return { success: false };
              }
            })(value);
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["tags"]);
    })();
    if (!parsed_tags.success) {
      return { success: false };
    }
    if (!hasProp(value, "title")) {
      return { success: false };
    }
    const parsed_title = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["title"]);
    })();
    if (!parsed_title.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        activatedAt: parsed_activatedAt.value,
        active: parsed_active.value,
        createdAt: parsed_createdAt.value,
        deactivatedAt: parsed_deactivatedAt.value,
        id: parsed_id.value,
        language: parsed_language.value,
        manager: parsed_manager.value,
        tags: parsed_tags.value,
        title: parsed_title.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// LearningItemAdminProps
export function serialize_LearningItemAdminProps(obj: LearningItemAdminProps): string {
  const serialized = ((value: { filter: LearningItemAdminFilter; readonly: boolean }): unknown => {
    return {
      filter: ((value: {
        activatedAtRange: DateRange | null;
        active: boolean | null;
        audio: boolean | null;
        createdAtRange: DateRange | null;
        documentTypes: SummaryDocumentType[];
        ids: number[];
        itemTypes: LearningItemType[];
        languages: Language[];
        manager: string | null;
        publisher: string | null;
        resultSize: number;
        sourceTypes: SummarySourceType[];
        tagCount: number | null;
        tags: Tag[];
        title: string | null;
      }): unknown => {
        return {
          activatedAtRange: ((
            value: {
              from: { year: number; month: number; day: number };
              to: { year: number; month: number; day: number };
            } | null,
          ): unknown => {
            if (value === null) {
              return null;
            }
            return {
              from: ((value: { year: number; month: number; day: number }): unknown => {
                return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
              })(value["from"]),
              to: ((value: { year: number; month: number; day: number }): unknown => {
                return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
              })(value["to"]),
            };
          })(value["activatedAtRange"]),
          active: ((value: boolean | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["active"]),
          audio: ((value: boolean | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["audio"]),
          createdAtRange: ((
            value: {
              from: { year: number; month: number; day: number };
              to: { year: number; month: number; day: number };
            } | null,
          ): unknown => {
            if (value === null) {
              return null;
            }
            return {
              from: ((value: { year: number; month: number; day: number }): unknown => {
                return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
              })(value["from"]),
              to: ((value: { year: number; month: number; day: number }): unknown => {
                return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
              })(value["to"]),
            };
          })(value["createdAtRange"]),
          documentTypes: ((value: SummaryDocumentType[]): unknown => {
            return value.map((value: "ABSTRACT" | "REVIEW"): unknown => {
              return value;
            });
          })(value["documentTypes"]),
          ids: ((value: number[]): unknown => {
            return value.map((value: number): unknown => {
              return value;
            });
          })(value["ids"]),
          itemTypes: ((value: LearningItemType[]): unknown => {
            return value.map((value: "SUMMARY" | "ACTIONABLE"): unknown => {
              return value;
            });
          })(value["itemTypes"]),
          languages: ((value: Language[]): unknown => {
            return value.map((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
              return value;
            });
          })(value["languages"]),
          manager: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["manager"]),
          publisher: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["publisher"]),
          resultSize: ((value: number): unknown => {
            return value;
          })(value["resultSize"]),
          sourceTypes: ((value: SummarySourceType[]): unknown => {
            return value.map((value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST"): unknown => {
              return value;
            });
          })(value["sourceTypes"]),
          tagCount: ((value: number | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["tagCount"]),
          tags: ((value: Tag[]): unknown => {
            return value.map(
              (
                value:
                  | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                  | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                  | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                  | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                  | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                  | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                  | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string },
              ): unknown => {
                switch (value.discriminator) {
                  case "dynamicString": {
                    return {
                      discriminator: "dynamicString",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindDynamicString; id: bigint; kind: string }): unknown => {
                        return {
                          discriminator: ((value: "dynamicString"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: string): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: string): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "dynamicBoolean": {
                    return {
                      discriminator: "dynamicBoolean",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindDynamicBoolean; id: bigint; kind: string }): unknown => {
                        return {
                          discriminator: ((value: "dynamicBoolean"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: string): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: boolean): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "dynamicNumber": {
                    return {
                      discriminator: "dynamicNumber",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindDynamicNumber; id: bigint; kind: string }): unknown => {
                        return {
                          discriminator: ((value: "dynamicNumber"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: string): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: number): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "dynamicVoid": {
                    return {
                      discriminator: "dynamicVoid",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindDynamicVoid; id: bigint; kind: string }): unknown => {
                        return {
                          discriminator: ((value: "dynamicVoid"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: string): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                    };
                  }
                  case "channel": {
                    return {
                      discriminator: "channel",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindChannel; id: bigint; kind: KindChannel }): unknown => {
                        return {
                          discriminator: ((value: "channel"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: "channel"): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: string): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "key-quality": {
                    return {
                      discriminator: "key-quality",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindKeyQuality; id: bigint; kind: KindKeyQuality }): unknown => {
                        return {
                          discriminator: ((value: "key-quality"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: "key-quality"): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: string): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "classification": {
                    return {
                      discriminator: "classification",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindClassification; id: bigint; kind: KindClassification }): unknown => {
                        return {
                          discriminator: ((value: "classification"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: "classification"): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: string): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                }
              },
            );
          })(value["tags"]),
          title: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["title"]),
        };
      })(value["filter"]),
      readonly: ((value: boolean): unknown => {
        return value;
      })(value["readonly"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_LearningItemAdminProps(str: string): LearningItemAdminProps | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          filter: LearningItemAdminFilter;
          readonly: boolean;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "filter")) {
      return { success: false };
    }
    const parsed_filter = (() => {
      return ((value: unknown): { success: false } | { success: true; value: LearningItemAdminFilter } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                activatedAtRange: DateRange | null;
                active: boolean | null;
                audio: boolean | null;
                createdAtRange: DateRange | null;
                documentTypes: SummaryDocumentType[];
                ids: number[];
                itemTypes: LearningItemType[];
                languages: Language[];
                manager: string | null;
                publisher: string | null;
                resultSize: number;
                sourceTypes: SummarySourceType[];
                tagCount: number | null;
                tags: Tag[];
                title: string | null;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "activatedAtRange")) {
            return { success: false };
          }
          const parsed_activatedAtRange = (() => {
            return ((value: unknown): { success: false } | { success: true; value: DateRange | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              return ((
                value: unknown,
              ):
                | { success: false }
                | {
                    success: true;
                    value: {
                      from: { year: number; month: number; day: number };
                      to: { year: number; month: number; day: number };
                    };
                  } => {
                if (typeof value !== "object") {
                  return { success: false };
                }
                if (value === null) {
                  return { success: false };
                }
                if (!hasProp(value, "from")) {
                  return { success: false };
                }
                const parsed_from = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
                    if (!parsed.isValid) {
                      return { success: false };
                    }
                    return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
                  })(value["from"]);
                })();
                if (!parsed_from.success) {
                  return { success: false };
                }
                if (!hasProp(value, "to")) {
                  return { success: false };
                }
                const parsed_to = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
                    if (!parsed.isValid) {
                      return { success: false };
                    }
                    return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
                  })(value["to"]);
                })();
                if (!parsed_to.success) {
                  return { success: false };
                }
                return { success: true, value: { from: parsed_from.value, to: parsed_to.value } };
              })(value);
            })(value["activatedAtRange"]);
          })();
          if (!parsed_activatedAtRange.success) {
            return { success: false };
          }
          if (!hasProp(value, "active")) {
            return { success: false };
          }
          const parsed_active = (() => {
            return ((value: unknown): { success: false } | { success: true; value: boolean | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "boolean") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["active"]);
          })();
          if (!parsed_active.success) {
            return { success: false };
          }
          if (!hasProp(value, "audio")) {
            return { success: false };
          }
          const parsed_audio = (() => {
            return ((value: unknown): { success: false } | { success: true; value: boolean | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "boolean") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["audio"]);
          })();
          if (!parsed_audio.success) {
            return { success: false };
          }
          if (!hasProp(value, "createdAtRange")) {
            return { success: false };
          }
          const parsed_createdAtRange = (() => {
            return ((value: unknown): { success: false } | { success: true; value: DateRange | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              return ((
                value: unknown,
              ):
                | { success: false }
                | {
                    success: true;
                    value: {
                      from: { year: number; month: number; day: number };
                      to: { year: number; month: number; day: number };
                    };
                  } => {
                if (typeof value !== "object") {
                  return { success: false };
                }
                if (value === null) {
                  return { success: false };
                }
                if (!hasProp(value, "from")) {
                  return { success: false };
                }
                const parsed_from = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
                    if (!parsed.isValid) {
                      return { success: false };
                    }
                    return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
                  })(value["from"]);
                })();
                if (!parsed_from.success) {
                  return { success: false };
                }
                if (!hasProp(value, "to")) {
                  return { success: false };
                }
                const parsed_to = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
                    if (!parsed.isValid) {
                      return { success: false };
                    }
                    return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
                  })(value["to"]);
                })();
                if (!parsed_to.success) {
                  return { success: false };
                }
                return { success: true, value: { from: parsed_from.value, to: parsed_to.value } };
              })(value);
            })(value["createdAtRange"]);
          })();
          if (!parsed_createdAtRange.success) {
            return { success: false };
          }
          if (!hasProp(value, "documentTypes")) {
            return { success: false };
          }
          const parsed_documentTypes = (() => {
            return ((value: unknown): { success: false } | { success: true; value: SummaryDocumentType[] } => {
              if (!isArray(value)) {
                return { success: false };
              }
              const parsed = value.map((value: unknown): { success: false } | { success: true; value: SummaryDocumentType } => {
                return ((value: unknown): { success: false } | { success: true; value: "ABSTRACT" | "REVIEW" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "ABSTRACT":
                      return { success: true, value: "ABSTRACT" };
                    case "REVIEW":
                      return { success: true, value: "REVIEW" };
                    default:
                      return { success: false };
                  }
                })(value);
              });
              if (parsed.find((value) => !value.success) !== undefined) {
                return { success: false };
              }
              return {
                success: true,
                value: parsed.map((entry) => {
                  assert(entry.success == true);
                  return entry.value;
                }),
              };
            })(value["documentTypes"]);
          })();
          if (!parsed_documentTypes.success) {
            return { success: false };
          }
          if (!hasProp(value, "ids")) {
            return { success: false };
          }
          const parsed_ids = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number[] } => {
              if (!isArray(value)) {
                return { success: false };
              }
              const parsed = value.map((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              });
              if (parsed.find((value) => !value.success) !== undefined) {
                return { success: false };
              }
              return {
                success: true,
                value: parsed.map((entry) => {
                  assert(entry.success == true);
                  return entry.value;
                }),
              };
            })(value["ids"]);
          })();
          if (!parsed_ids.success) {
            return { success: false };
          }
          if (!hasProp(value, "itemTypes")) {
            return { success: false };
          }
          const parsed_itemTypes = (() => {
            return ((value: unknown): { success: false } | { success: true; value: LearningItemType[] } => {
              if (!isArray(value)) {
                return { success: false };
              }
              const parsed = value.map((value: unknown): { success: false } | { success: true; value: LearningItemType } => {
                return ((value: unknown): { success: false } | { success: true; value: "SUMMARY" | "ACTIONABLE" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "SUMMARY":
                      return { success: true, value: "SUMMARY" };
                    case "ACTIONABLE":
                      return { success: true, value: "ACTIONABLE" };
                    default:
                      return { success: false };
                  }
                })(value);
              });
              if (parsed.find((value) => !value.success) !== undefined) {
                return { success: false };
              }
              return {
                success: true,
                value: parsed.map((entry) => {
                  assert(entry.success == true);
                  return entry.value;
                }),
              };
            })(value["itemTypes"]);
          })();
          if (!parsed_itemTypes.success) {
            return { success: false };
          }
          if (!hasProp(value, "languages")) {
            return { success: false };
          }
          const parsed_languages = (() => {
            return ((value: unknown): { success: false } | { success: true; value: Language[] } => {
              if (!isArray(value)) {
                return { success: false };
              }
              const parsed = value.map((value: unknown): { success: false } | { success: true; value: Language } => {
                return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "en":
                      return { success: true, value: "en" };
                    case "de":
                      return { success: true, value: "de" };
                    case "es":
                      return { success: true, value: "es" };
                    case "ru":
                      return { success: true, value: "ru" };
                    case "zh":
                      return { success: true, value: "zh" };
                    case "pt":
                      return { success: true, value: "pt" };
                    case "fr":
                      return { success: true, value: "fr" };
                    default:
                      return { success: false };
                  }
                })(value);
              });
              if (parsed.find((value) => !value.success) !== undefined) {
                return { success: false };
              }
              return {
                success: true,
                value: parsed.map((entry) => {
                  assert(entry.success == true);
                  return entry.value;
                }),
              };
            })(value["languages"]);
          })();
          if (!parsed_languages.success) {
            return { success: false };
          }
          if (!hasProp(value, "manager")) {
            return { success: false };
          }
          const parsed_manager = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["manager"]);
          })();
          if (!parsed_manager.success) {
            return { success: false };
          }
          if (!hasProp(value, "publisher")) {
            return { success: false };
          }
          const parsed_publisher = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["publisher"]);
          })();
          if (!parsed_publisher.success) {
            return { success: false };
          }
          if (!hasProp(value, "resultSize")) {
            return { success: false };
          }
          const parsed_resultSize = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["resultSize"]);
          })();
          if (!parsed_resultSize.success) {
            return { success: false };
          }
          if (!hasProp(value, "sourceTypes")) {
            return { success: false };
          }
          const parsed_sourceTypes = (() => {
            return ((value: unknown): { success: false } | { success: true; value: SummarySourceType[] } => {
              if (!isArray(value)) {
                return { success: false };
              }
              const parsed = value.map((value: unknown): { success: false } | { success: true; value: SummarySourceType } => {
                return ((value: unknown): { success: false } | { success: true; value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "BOOK":
                      return { success: true, value: "BOOK" };
                    case "VIDEO":
                      return { success: true, value: "VIDEO" };
                    case "ARTICLE":
                      return { success: true, value: "ARTICLE" };
                    case "CHAPTER":
                      return { success: true, value: "CHAPTER" };
                    case "REPORT":
                      return { success: true, value: "REPORT" };
                    case "PODCAST":
                      return { success: true, value: "PODCAST" };
                    default:
                      return { success: false };
                  }
                })(value);
              });
              if (parsed.find((value) => !value.success) !== undefined) {
                return { success: false };
              }
              return {
                success: true,
                value: parsed.map((entry) => {
                  assert(entry.success == true);
                  return entry.value;
                }),
              };
            })(value["sourceTypes"]);
          })();
          if (!parsed_sourceTypes.success) {
            return { success: false };
          }
          if (!hasProp(value, "tagCount")) {
            return { success: false };
          }
          const parsed_tagCount = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["tagCount"]);
          })();
          if (!parsed_tagCount.success) {
            return { success: false };
          }
          if (!hasProp(value, "tags")) {
            return { success: false };
          }
          const parsed_tags = (() => {
            return ((value: unknown): { success: false } | { success: true; value: Tag[] } => {
              if (!isArray(value)) {
                return { success: false };
              }
              const parsed = value.map((value: unknown): { success: false } | { success: true; value: Tag } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value:
                        | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                        | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                        | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                        | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                        | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                        | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                        | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                    } => {
                  return ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value:
                          | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                          | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                          | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                          | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                          | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                          | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                          | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                      } => {
                    if (value == null) {
                      return { success: false };
                    }
                    if (typeof value != "object") {
                      return { success: false };
                    }
                    if (!hasProp(value, "discriminator")) {
                      return { success: false };
                    }
                    if (typeof value["discriminator"] != "string") {
                      return { success: false };
                    }
                    const discriminator = value["discriminator"];
                    switch (discriminator) {
                      case "dynamicString": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindDynamicString;
                                value: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "CHANNEL_HIERARCHY":
                                    return { success: true, value: "CHANNEL_HIERARCHY" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicString } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindDynamicString;
                                      id: bigint;
                                      kind: string;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindDynamicString } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "dynamicString" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "dynamicString":
                                          return { success: true, value: "dynamicString" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                                    if (typeof value !== "string") {
                                      return { success: false };
                                    }
                                    return { success: true, value: value };
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "dynamicString" as const, ...parsedVariant.value } };
                      }
                      case "dynamicBoolean": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindDynamicBoolean;
                                value: boolean;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "CHANNEL_HIERARCHY":
                                    return { success: true, value: "CHANNEL_HIERARCHY" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicBoolean } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindDynamicBoolean;
                                      id: bigint;
                                      kind: string;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindDynamicBoolean } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "dynamicBoolean" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "dynamicBoolean":
                                          return { success: true, value: "dynamicBoolean" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                                    if (typeof value !== "string") {
                                      return { success: false };
                                    }
                                    return { success: true, value: value };
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "dynamicBoolean" as const, ...parsedVariant.value } };
                      }
                      case "dynamicNumber": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindDynamicNumber;
                                value: number;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "CHANNEL_HIERARCHY":
                                    return { success: true, value: "CHANNEL_HIERARCHY" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicNumber } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindDynamicNumber;
                                      id: bigint;
                                      kind: string;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindDynamicNumber } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "dynamicNumber" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "dynamicNumber":
                                          return { success: true, value: "dynamicNumber" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                                    if (typeof value !== "string") {
                                      return { success: false };
                                    }
                                    return { success: true, value: value };
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: number } => {
                              if (typeof value === "number") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "dynamicNumber" as const, ...parsedVariant.value } };
                      }
                      case "dynamicVoid": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindDynamicVoid;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "CHANNEL_HIERARCHY":
                                    return { success: true, value: "CHANNEL_HIERARCHY" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicVoid } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindDynamicVoid;
                                      id: bigint;
                                      kind: string;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindDynamicVoid } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "dynamicVoid" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "dynamicVoid":
                                          return { success: true, value: "dynamicVoid" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                                    if (typeof value !== "string") {
                                      return { success: false };
                                    }
                                    return { success: true, value: value };
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "dynamicVoid" as const, ...parsedVariant.value } };
                      }
                      case "channel": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindChannel;
                                value: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "CHANNEL_HIERARCHY":
                                    return { success: true, value: "CHANNEL_HIERARCHY" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindChannel } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindChannel;
                                      id: bigint;
                                      kind: KindChannel;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "channel":
                                          return { success: true, value: "channel" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "channel":
                                          return { success: true, value: "channel" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "channel" as const, ...parsedVariant.value } };
                      }
                      case "key-quality": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindKeyQuality;
                                value: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "CHANNEL_HIERARCHY":
                                    return { success: true, value: "CHANNEL_HIERARCHY" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindKeyQuality } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindKeyQuality;
                                      id: bigint;
                                      kind: KindKeyQuality;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "key-quality":
                                          return { success: true, value: "key-quality" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "key-quality":
                                          return { success: true, value: "key-quality" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "key-quality" as const, ...parsedVariant.value } };
                      }
                      case "classification": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindClassification;
                                value: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "CHANNEL_HIERARCHY":
                                    return { success: true, value: "CHANNEL_HIERARCHY" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindClassification } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindClassification;
                                      id: bigint;
                                      kind: KindClassification;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "classification":
                                          return { success: true, value: "classification" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "classification":
                                          return { success: true, value: "classification" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "classification" as const, ...parsedVariant.value } };
                      }
                      default:
                        return { success: false };
                    }
                  })(value);
                })(value);
              });
              if (parsed.find((value) => !value.success) !== undefined) {
                return { success: false };
              }
              return {
                success: true,
                value: parsed.map((entry) => {
                  assert(entry.success == true);
                  return entry.value;
                }),
              };
            })(value["tags"]);
          })();
          if (!parsed_tags.success) {
            return { success: false };
          }
          if (!hasProp(value, "title")) {
            return { success: false };
          }
          const parsed_title = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["title"]);
          })();
          if (!parsed_title.success) {
            return { success: false };
          }
          return {
            success: true,
            value: {
              activatedAtRange: parsed_activatedAtRange.value,
              active: parsed_active.value,
              audio: parsed_audio.value,
              createdAtRange: parsed_createdAtRange.value,
              documentTypes: parsed_documentTypes.value,
              ids: parsed_ids.value,
              itemTypes: parsed_itemTypes.value,
              languages: parsed_languages.value,
              manager: parsed_manager.value,
              publisher: parsed_publisher.value,
              resultSize: parsed_resultSize.value,
              sourceTypes: parsed_sourceTypes.value,
              tagCount: parsed_tagCount.value,
              tags: parsed_tags.value,
              title: parsed_title.value,
            },
          };
        })(value);
      })(value["filter"]);
    })();
    if (!parsed_filter.success) {
      return { success: false };
    }
    if (!hasProp(value, "readonly")) {
      return { success: false };
    }
    const parsed_readonly = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["readonly"]);
    })();
    if (!parsed_readonly.success) {
      return { success: false };
    }
    return { success: true, value: { filter: parsed_filter.value, readonly: parsed_readonly.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// LearningItemAdminSummaryView
export function serialize_LearningItemAdminSummaryView(obj: LearningItemAdminSummaryView): string {
  const serialized = ((value: {
    audioActive: boolean;
    documentType: SummaryDocumentType;
    learningItemType: LearningItemTypeSummary;
    metadata: LearningItemAdminMetadataView;
    publisher: string | null;
    sourceType: SummarySourceType;
  }): unknown => {
    return {
      audioActive: ((value: boolean): unknown => {
        return value;
      })(value["audioActive"]),
      documentType: ((value: "ABSTRACT" | "REVIEW"): unknown => {
        return value;
      })(value["documentType"]),
      learningItemType: ((value: "SUMMARY"): unknown => {
        return value;
      })(value["learningItemType"]),
      metadata: ((value: {
        activatedAt: { year: number; month: number; day: number } | null;
        active: boolean;
        createdAt: { year: number; month: number; day: number };
        deactivatedAt: { year: number; month: number; day: number } | null;
        id: bigint;
        language: Language;
        manager: string | null;
        tags: Tag[];
        title: string;
      }): unknown => {
        return {
          activatedAt: ((value: { year: number; month: number; day: number } | null): unknown => {
            if (value === null) {
              return null;
            }
            return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
          })(value["activatedAt"]),
          active: ((value: boolean): unknown => {
            return value;
          })(value["active"]),
          createdAt: ((value: { year: number; month: number; day: number }): unknown => {
            return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
          })(value["createdAt"]),
          deactivatedAt: ((value: { year: number; month: number; day: number } | null): unknown => {
            if (value === null) {
              return null;
            }
            return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
          })(value["deactivatedAt"]),
          id: ((value: bigint): unknown => {
            return value;
          })(value["id"]),
          language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
            return value;
          })(value["language"]),
          manager: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["manager"]),
          tags: ((value: Tag[]): unknown => {
            return value.map(
              (
                value:
                  | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                  | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                  | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                  | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                  | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                  | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                  | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string },
              ): unknown => {
                switch (value.discriminator) {
                  case "dynamicString": {
                    return {
                      discriminator: "dynamicString",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindDynamicString; id: bigint; kind: string }): unknown => {
                        return {
                          discriminator: ((value: "dynamicString"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: string): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: string): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "dynamicBoolean": {
                    return {
                      discriminator: "dynamicBoolean",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindDynamicBoolean; id: bigint; kind: string }): unknown => {
                        return {
                          discriminator: ((value: "dynamicBoolean"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: string): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: boolean): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "dynamicNumber": {
                    return {
                      discriminator: "dynamicNumber",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindDynamicNumber; id: bigint; kind: string }): unknown => {
                        return {
                          discriminator: ((value: "dynamicNumber"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: string): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: number): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "dynamicVoid": {
                    return {
                      discriminator: "dynamicVoid",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindDynamicVoid; id: bigint; kind: string }): unknown => {
                        return {
                          discriminator: ((value: "dynamicVoid"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: string): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                    };
                  }
                  case "channel": {
                    return {
                      discriminator: "channel",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindChannel; id: bigint; kind: KindChannel }): unknown => {
                        return {
                          discriminator: ((value: "channel"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: "channel"): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: string): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "key-quality": {
                    return {
                      discriminator: "key-quality",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindKeyQuality; id: bigint; kind: KindKeyQuality }): unknown => {
                        return {
                          discriminator: ((value: "key-quality"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: "key-quality"): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: string): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "classification": {
                    return {
                      discriminator: "classification",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindClassification; id: bigint; kind: KindClassification }): unknown => {
                        return {
                          discriminator: ((value: "classification"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: "classification"): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: string): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                }
              },
            );
          })(value["tags"]),
          title: ((value: string): unknown => {
            return value;
          })(value["title"]),
        };
      })(value["metadata"]),
      publisher: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["publisher"]),
      sourceType: ((value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST"): unknown => {
        return value;
      })(value["sourceType"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_LearningItemAdminSummaryView(str: string): LearningItemAdminSummaryView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          audioActive: boolean;
          documentType: SummaryDocumentType;
          learningItemType: LearningItemTypeSummary;
          metadata: LearningItemAdminMetadataView;
          publisher: string | null;
          sourceType: SummarySourceType;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "audioActive")) {
      return { success: false };
    }
    const parsed_audioActive = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["audioActive"]);
    })();
    if (!parsed_audioActive.success) {
      return { success: false };
    }
    if (!hasProp(value, "documentType")) {
      return { success: false };
    }
    const parsed_documentType = (() => {
      return ((value: unknown): { success: false } | { success: true; value: SummaryDocumentType } => {
        return ((value: unknown): { success: false } | { success: true; value: "ABSTRACT" | "REVIEW" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "ABSTRACT":
              return { success: true, value: "ABSTRACT" };
            case "REVIEW":
              return { success: true, value: "REVIEW" };
            default:
              return { success: false };
          }
        })(value);
      })(value["documentType"]);
    })();
    if (!parsed_documentType.success) {
      return { success: false };
    }
    if (!hasProp(value, "learningItemType")) {
      return { success: false };
    }
    const parsed_learningItemType = (() => {
      return ((value: unknown): { success: false } | { success: true; value: LearningItemTypeSummary } => {
        return ((value: unknown): { success: false } | { success: true; value: "SUMMARY" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "SUMMARY":
              return { success: true, value: "SUMMARY" };
            default:
              return { success: false };
          }
        })(value);
      })(value["learningItemType"]);
    })();
    if (!parsed_learningItemType.success) {
      return { success: false };
    }
    if (!hasProp(value, "metadata")) {
      return { success: false };
    }
    const parsed_metadata = (() => {
      return ((value: unknown): { success: false } | { success: true; value: LearningItemAdminMetadataView } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                activatedAt: { year: number; month: number; day: number } | null;
                active: boolean;
                createdAt: { year: number; month: number; day: number };
                deactivatedAt: { year: number; month: number; day: number } | null;
                id: bigint;
                language: Language;
                manager: string | null;
                tags: Tag[];
                title: string;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "activatedAt")) {
            return { success: false };
          }
          const parsed_activatedAt = (() => {
            return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
              if (!parsed.isValid) {
                return { success: false };
              }
              return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
            })(value["activatedAt"]);
          })();
          if (!parsed_activatedAt.success) {
            return { success: false };
          }
          if (!hasProp(value, "active")) {
            return { success: false };
          }
          const parsed_active = (() => {
            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
              if (typeof value !== "boolean") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["active"]);
          })();
          if (!parsed_active.success) {
            return { success: false };
          }
          if (!hasProp(value, "createdAt")) {
            return { success: false };
          }
          const parsed_createdAt = (() => {
            return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
              if (!parsed.isValid) {
                return { success: false };
              }
              return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
            })(value["createdAt"]);
          })();
          if (!parsed_createdAt.success) {
            return { success: false };
          }
          if (!hasProp(value, "deactivatedAt")) {
            return { success: false };
          }
          const parsed_deactivatedAt = (() => {
            return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
              if (!parsed.isValid) {
                return { success: false };
              }
              return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
            })(value["deactivatedAt"]);
          })();
          if (!parsed_deactivatedAt.success) {
            return { success: false };
          }
          if (!hasProp(value, "id")) {
            return { success: false };
          }
          const parsed_id = (() => {
            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
              if (typeof value === "number") {
                return { success: true, value: BigInt(value) };
              }
              if (typeof value === "bigint") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["id"]);
          })();
          if (!parsed_id.success) {
            return { success: false };
          }
          if (!hasProp(value, "language")) {
            return { success: false };
          }
          const parsed_language = (() => {
            return ((value: unknown): { success: false } | { success: true; value: Language } => {
              return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "en":
                    return { success: true, value: "en" };
                  case "de":
                    return { success: true, value: "de" };
                  case "es":
                    return { success: true, value: "es" };
                  case "ru":
                    return { success: true, value: "ru" };
                  case "zh":
                    return { success: true, value: "zh" };
                  case "pt":
                    return { success: true, value: "pt" };
                  case "fr":
                    return { success: true, value: "fr" };
                  default:
                    return { success: false };
                }
              })(value);
            })(value["language"]);
          })();
          if (!parsed_language.success) {
            return { success: false };
          }
          if (!hasProp(value, "manager")) {
            return { success: false };
          }
          const parsed_manager = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["manager"]);
          })();
          if (!parsed_manager.success) {
            return { success: false };
          }
          if (!hasProp(value, "tags")) {
            return { success: false };
          }
          const parsed_tags = (() => {
            return ((value: unknown): { success: false } | { success: true; value: Tag[] } => {
              if (!isArray(value)) {
                return { success: false };
              }
              const parsed = value.map((value: unknown): { success: false } | { success: true; value: Tag } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value:
                        | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                        | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                        | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                        | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                        | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                        | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                        | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                    } => {
                  return ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value:
                          | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                          | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                          | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                          | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                          | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                          | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                          | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                      } => {
                    if (value == null) {
                      return { success: false };
                    }
                    if (typeof value != "object") {
                      return { success: false };
                    }
                    if (!hasProp(value, "discriminator")) {
                      return { success: false };
                    }
                    if (typeof value["discriminator"] != "string") {
                      return { success: false };
                    }
                    const discriminator = value["discriminator"];
                    switch (discriminator) {
                      case "dynamicString": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindDynamicString;
                                value: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "CHANNEL_HIERARCHY":
                                    return { success: true, value: "CHANNEL_HIERARCHY" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicString } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindDynamicString;
                                      id: bigint;
                                      kind: string;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindDynamicString } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "dynamicString" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "dynamicString":
                                          return { success: true, value: "dynamicString" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                                    if (typeof value !== "string") {
                                      return { success: false };
                                    }
                                    return { success: true, value: value };
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "dynamicString" as const, ...parsedVariant.value } };
                      }
                      case "dynamicBoolean": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindDynamicBoolean;
                                value: boolean;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "CHANNEL_HIERARCHY":
                                    return { success: true, value: "CHANNEL_HIERARCHY" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicBoolean } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindDynamicBoolean;
                                      id: bigint;
                                      kind: string;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindDynamicBoolean } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "dynamicBoolean" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "dynamicBoolean":
                                          return { success: true, value: "dynamicBoolean" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                                    if (typeof value !== "string") {
                                      return { success: false };
                                    }
                                    return { success: true, value: value };
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "dynamicBoolean" as const, ...parsedVariant.value } };
                      }
                      case "dynamicNumber": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindDynamicNumber;
                                value: number;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "CHANNEL_HIERARCHY":
                                    return { success: true, value: "CHANNEL_HIERARCHY" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicNumber } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindDynamicNumber;
                                      id: bigint;
                                      kind: string;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindDynamicNumber } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "dynamicNumber" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "dynamicNumber":
                                          return { success: true, value: "dynamicNumber" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                                    if (typeof value !== "string") {
                                      return { success: false };
                                    }
                                    return { success: true, value: value };
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: number } => {
                              if (typeof value === "number") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "dynamicNumber" as const, ...parsedVariant.value } };
                      }
                      case "dynamicVoid": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindDynamicVoid;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "CHANNEL_HIERARCHY":
                                    return { success: true, value: "CHANNEL_HIERARCHY" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicVoid } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindDynamicVoid;
                                      id: bigint;
                                      kind: string;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindDynamicVoid } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "dynamicVoid" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "dynamicVoid":
                                          return { success: true, value: "dynamicVoid" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                                    if (typeof value !== "string") {
                                      return { success: false };
                                    }
                                    return { success: true, value: value };
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "dynamicVoid" as const, ...parsedVariant.value } };
                      }
                      case "channel": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindChannel;
                                value: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "CHANNEL_HIERARCHY":
                                    return { success: true, value: "CHANNEL_HIERARCHY" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindChannel } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindChannel;
                                      id: bigint;
                                      kind: KindChannel;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "channel":
                                          return { success: true, value: "channel" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "channel":
                                          return { success: true, value: "channel" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "channel" as const, ...parsedVariant.value } };
                      }
                      case "key-quality": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindKeyQuality;
                                value: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "CHANNEL_HIERARCHY":
                                    return { success: true, value: "CHANNEL_HIERARCHY" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindKeyQuality } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindKeyQuality;
                                      id: bigint;
                                      kind: KindKeyQuality;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "key-quality":
                                          return { success: true, value: "key-quality" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "key-quality":
                                          return { success: true, value: "key-quality" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "key-quality" as const, ...parsedVariant.value } };
                      }
                      case "classification": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindClassification;
                                value: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "CHANNEL_HIERARCHY":
                                    return { success: true, value: "CHANNEL_HIERARCHY" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindClassification } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindClassification;
                                      id: bigint;
                                      kind: KindClassification;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "classification":
                                          return { success: true, value: "classification" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "classification":
                                          return { success: true, value: "classification" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "classification" as const, ...parsedVariant.value } };
                      }
                      default:
                        return { success: false };
                    }
                  })(value);
                })(value);
              });
              if (parsed.find((value) => !value.success) !== undefined) {
                return { success: false };
              }
              return {
                success: true,
                value: parsed.map((entry) => {
                  assert(entry.success == true);
                  return entry.value;
                }),
              };
            })(value["tags"]);
          })();
          if (!parsed_tags.success) {
            return { success: false };
          }
          if (!hasProp(value, "title")) {
            return { success: false };
          }
          const parsed_title = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["title"]);
          })();
          if (!parsed_title.success) {
            return { success: false };
          }
          return {
            success: true,
            value: {
              activatedAt: parsed_activatedAt.value,
              active: parsed_active.value,
              createdAt: parsed_createdAt.value,
              deactivatedAt: parsed_deactivatedAt.value,
              id: parsed_id.value,
              language: parsed_language.value,
              manager: parsed_manager.value,
              tags: parsed_tags.value,
              title: parsed_title.value,
            },
          };
        })(value);
      })(value["metadata"]);
    })();
    if (!parsed_metadata.success) {
      return { success: false };
    }
    if (!hasProp(value, "publisher")) {
      return { success: false };
    }
    const parsed_publisher = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["publisher"]);
    })();
    if (!parsed_publisher.success) {
      return { success: false };
    }
    if (!hasProp(value, "sourceType")) {
      return { success: false };
    }
    const parsed_sourceType = (() => {
      return ((value: unknown): { success: false } | { success: true; value: SummarySourceType } => {
        return ((value: unknown): { success: false } | { success: true; value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "BOOK":
              return { success: true, value: "BOOK" };
            case "VIDEO":
              return { success: true, value: "VIDEO" };
            case "ARTICLE":
              return { success: true, value: "ARTICLE" };
            case "CHAPTER":
              return { success: true, value: "CHAPTER" };
            case "REPORT":
              return { success: true, value: "REPORT" };
            case "PODCAST":
              return { success: true, value: "PODCAST" };
            default:
              return { success: false };
          }
        })(value);
      })(value["sourceType"]);
    })();
    if (!parsed_sourceType.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        audioActive: parsed_audioActive.value,
        documentType: parsed_documentType.value,
        learningItemType: parsed_learningItemType.value,
        metadata: parsed_metadata.value,
        publisher: parsed_publisher.value,
        sourceType: parsed_sourceType.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// LearningItemAdminView
export function serialize_LearningItemAdminView(obj: LearningItemAdminView): string {
  const serialized = ((
    value:
      | { learningItemType: "ACTIONABLE"; description: string; metadata: LearningItemAdminMetadataView }
      | { learningItemType: "SUMMARY"; audioActive: boolean; documentType: SummaryDocumentType; metadata: LearningItemAdminMetadataView; publisher: string | null; sourceType: SummarySourceType },
  ): unknown => {
    switch (value.learningItemType) {
      case "ACTIONABLE": {
        return {
          learningItemType: "ACTIONABLE",
          description: ((value: string): unknown => {
            return value;
          })(value["description"]),
          metadata: ((value: {
            activatedAt: { year: number; month: number; day: number } | null;
            active: boolean;
            createdAt: { year: number; month: number; day: number };
            deactivatedAt: { year: number; month: number; day: number } | null;
            id: bigint;
            language: Language;
            manager: string | null;
            tags: Tag[];
            title: string;
          }): unknown => {
            return {
              activatedAt: ((value: { year: number; month: number; day: number } | null): unknown => {
                if (value === null) {
                  return null;
                }
                return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
              })(value["activatedAt"]),
              active: ((value: boolean): unknown => {
                return value;
              })(value["active"]),
              createdAt: ((value: { year: number; month: number; day: number }): unknown => {
                return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
              })(value["createdAt"]),
              deactivatedAt: ((value: { year: number; month: number; day: number } | null): unknown => {
                if (value === null) {
                  return null;
                }
                return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
              })(value["deactivatedAt"]),
              id: ((value: bigint): unknown => {
                return value;
              })(value["id"]),
              language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                return value;
              })(value["language"]),
              manager: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["manager"]),
              tags: ((value: Tag[]): unknown => {
                return value.map(
                  (
                    value:
                      | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                      | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                      | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                      | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                      | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                      | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                      | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string },
                  ): unknown => {
                    switch (value.discriminator) {
                      case "dynamicString": {
                        return {
                          discriminator: "dynamicString",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindDynamicString; id: bigint; kind: string }): unknown => {
                            return {
                              discriminator: ((value: "dynamicString"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: string): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: string): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                      case "dynamicBoolean": {
                        return {
                          discriminator: "dynamicBoolean",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindDynamicBoolean; id: bigint; kind: string }): unknown => {
                            return {
                              discriminator: ((value: "dynamicBoolean"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: string): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: boolean): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                      case "dynamicNumber": {
                        return {
                          discriminator: "dynamicNumber",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindDynamicNumber; id: bigint; kind: string }): unknown => {
                            return {
                              discriminator: ((value: "dynamicNumber"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: string): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: number): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                      case "dynamicVoid": {
                        return {
                          discriminator: "dynamicVoid",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindDynamicVoid; id: bigint; kind: string }): unknown => {
                            return {
                              discriminator: ((value: "dynamicVoid"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: string): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                        };
                      }
                      case "channel": {
                        return {
                          discriminator: "channel",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindChannel; id: bigint; kind: KindChannel }): unknown => {
                            return {
                              discriminator: ((value: "channel"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: "channel"): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: string): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                      case "key-quality": {
                        return {
                          discriminator: "key-quality",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindKeyQuality; id: bigint; kind: KindKeyQuality }): unknown => {
                            return {
                              discriminator: ((value: "key-quality"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: "key-quality"): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: string): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                      case "classification": {
                        return {
                          discriminator: "classification",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindClassification; id: bigint; kind: KindClassification }): unknown => {
                            return {
                              discriminator: ((value: "classification"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: "classification"): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: string): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                    }
                  },
                );
              })(value["tags"]),
              title: ((value: string): unknown => {
                return value;
              })(value["title"]),
            };
          })(value["metadata"]),
        };
      }
      case "SUMMARY": {
        return {
          learningItemType: "SUMMARY",
          audioActive: ((value: boolean): unknown => {
            return value;
          })(value["audioActive"]),
          documentType: ((value: "ABSTRACT" | "REVIEW"): unknown => {
            return value;
          })(value["documentType"]),
          metadata: ((value: {
            activatedAt: { year: number; month: number; day: number } | null;
            active: boolean;
            createdAt: { year: number; month: number; day: number };
            deactivatedAt: { year: number; month: number; day: number } | null;
            id: bigint;
            language: Language;
            manager: string | null;
            tags: Tag[];
            title: string;
          }): unknown => {
            return {
              activatedAt: ((value: { year: number; month: number; day: number } | null): unknown => {
                if (value === null) {
                  return null;
                }
                return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
              })(value["activatedAt"]),
              active: ((value: boolean): unknown => {
                return value;
              })(value["active"]),
              createdAt: ((value: { year: number; month: number; day: number }): unknown => {
                return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
              })(value["createdAt"]),
              deactivatedAt: ((value: { year: number; month: number; day: number } | null): unknown => {
                if (value === null) {
                  return null;
                }
                return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
              })(value["deactivatedAt"]),
              id: ((value: bigint): unknown => {
                return value;
              })(value["id"]),
              language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                return value;
              })(value["language"]),
              manager: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["manager"]),
              tags: ((value: Tag[]): unknown => {
                return value.map(
                  (
                    value:
                      | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                      | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                      | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                      | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                      | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                      | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                      | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string },
                  ): unknown => {
                    switch (value.discriminator) {
                      case "dynamicString": {
                        return {
                          discriminator: "dynamicString",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindDynamicString; id: bigint; kind: string }): unknown => {
                            return {
                              discriminator: ((value: "dynamicString"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: string): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: string): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                      case "dynamicBoolean": {
                        return {
                          discriminator: "dynamicBoolean",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindDynamicBoolean; id: bigint; kind: string }): unknown => {
                            return {
                              discriminator: ((value: "dynamicBoolean"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: string): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: boolean): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                      case "dynamicNumber": {
                        return {
                          discriminator: "dynamicNumber",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindDynamicNumber; id: bigint; kind: string }): unknown => {
                            return {
                              discriminator: ((value: "dynamicNumber"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: string): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: number): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                      case "dynamicVoid": {
                        return {
                          discriminator: "dynamicVoid",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindDynamicVoid; id: bigint; kind: string }): unknown => {
                            return {
                              discriminator: ((value: "dynamicVoid"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: string): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                        };
                      }
                      case "channel": {
                        return {
                          discriminator: "channel",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindChannel; id: bigint; kind: KindChannel }): unknown => {
                            return {
                              discriminator: ((value: "channel"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: "channel"): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: string): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                      case "key-quality": {
                        return {
                          discriminator: "key-quality",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindKeyQuality; id: bigint; kind: KindKeyQuality }): unknown => {
                            return {
                              discriminator: ((value: "key-quality"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: "key-quality"): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: string): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                      case "classification": {
                        return {
                          discriminator: "classification",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindClassification; id: bigint; kind: KindClassification }): unknown => {
                            return {
                              discriminator: ((value: "classification"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: "classification"): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: string): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                    }
                  },
                );
              })(value["tags"]),
              title: ((value: string): unknown => {
                return value;
              })(value["title"]),
            };
          })(value["metadata"]),
          publisher: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["publisher"]),
          sourceType: ((value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST"): unknown => {
            return value;
          })(value["sourceType"]),
        };
      }
    }
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_LearningItemAdminView(str: string): LearningItemAdminView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value:
          | { learningItemType: "ACTIONABLE"; description: string; metadata: LearningItemAdminMetadataView }
          | { learningItemType: "SUMMARY"; audioActive: boolean; documentType: SummaryDocumentType; metadata: LearningItemAdminMetadataView; publisher: string | null; sourceType: SummarySourceType };
      } => {
    return ((
      value: unknown,
    ):
      | { success: false }
      | {
          success: true;
          value:
            | { learningItemType: "ACTIONABLE"; description: string; metadata: LearningItemAdminMetadataView }
            | { learningItemType: "SUMMARY"; audioActive: boolean; documentType: SummaryDocumentType; metadata: LearningItemAdminMetadataView; publisher: string | null; sourceType: SummarySourceType };
        } => {
      if (value == null) {
        return { success: false };
      }
      if (typeof value != "object") {
        return { success: false };
      }
      if (!hasProp(value, "learningItemType")) {
        return { success: false };
      }
      if (typeof value["learningItemType"] != "string") {
        return { success: false };
      }
      const discriminator = value["learningItemType"];
      switch (discriminator) {
        case "ACTIONABLE": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  description: string;
                  metadata: LearningItemAdminMetadataView;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "description")) {
              return { success: false };
            }
            const parsed_description = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["description"]);
            })();
            if (!parsed_description.success) {
              return { success: false };
            }
            if (!hasProp(value, "metadata")) {
              return { success: false };
            }
            const parsed_metadata = (() => {
              return ((value: unknown): { success: false } | { success: true; value: LearningItemAdminMetadataView } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        activatedAt: { year: number; month: number; day: number } | null;
                        active: boolean;
                        createdAt: { year: number; month: number; day: number };
                        deactivatedAt: { year: number; month: number; day: number } | null;
                        id: bigint;
                        language: Language;
                        manager: string | null;
                        tags: Tag[];
                        title: string;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "activatedAt")) {
                    return { success: false };
                  }
                  const parsed_activatedAt = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
                      if (!parsed.isValid) {
                        return { success: false };
                      }
                      return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
                    })(value["activatedAt"]);
                  })();
                  if (!parsed_activatedAt.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "active")) {
                    return { success: false };
                  }
                  const parsed_active = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["active"]);
                  })();
                  if (!parsed_active.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "createdAt")) {
                    return { success: false };
                  }
                  const parsed_createdAt = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
                      if (!parsed.isValid) {
                        return { success: false };
                      }
                      return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
                    })(value["createdAt"]);
                  })();
                  if (!parsed_createdAt.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "deactivatedAt")) {
                    return { success: false };
                  }
                  const parsed_deactivatedAt = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
                      if (!parsed.isValid) {
                        return { success: false };
                      }
                      return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
                    })(value["deactivatedAt"]);
                  })();
                  if (!parsed_deactivatedAt.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "id")) {
                    return { success: false };
                  }
                  const parsed_id = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                      if (typeof value === "number") {
                        return { success: true, value: BigInt(value) };
                      }
                      if (typeof value === "bigint") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["id"]);
                  })();
                  if (!parsed_id.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "language")) {
                    return { success: false };
                  }
                  const parsed_language = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: Language } => {
                      return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "en":
                            return { success: true, value: "en" };
                          case "de":
                            return { success: true, value: "de" };
                          case "es":
                            return { success: true, value: "es" };
                          case "ru":
                            return { success: true, value: "ru" };
                          case "zh":
                            return { success: true, value: "zh" };
                          case "pt":
                            return { success: true, value: "pt" };
                          case "fr":
                            return { success: true, value: "fr" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["language"]);
                  })();
                  if (!parsed_language.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "manager")) {
                    return { success: false };
                  }
                  const parsed_manager = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["manager"]);
                  })();
                  if (!parsed_manager.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "tags")) {
                    return { success: false };
                  }
                  const parsed_tags = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: Tag[] } => {
                      if (!isArray(value)) {
                        return { success: false };
                      }
                      const parsed = value.map((value: unknown): { success: false } | { success: true; value: Tag } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value:
                                | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                                | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                                | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                                | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                                | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                                | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                                | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                            } => {
                          return ((
                            value: unknown,
                          ):
                            | { success: false }
                            | {
                                success: true;
                                value:
                                  | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                                  | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                                  | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                                  | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                                  | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                                  | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                                  | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                              } => {
                            if (value == null) {
                              return { success: false };
                            }
                            if (typeof value != "object") {
                              return { success: false };
                            }
                            if (!hasProp(value, "discriminator")) {
                              return { success: false };
                            }
                            if (typeof value["discriminator"] != "string") {
                              return { success: false };
                            }
                            const discriminator = value["discriminator"];
                            switch (discriminator) {
                              case "dynamicString": {
                                const parsedVariant = ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        exclude: boolean;
                                        source: TagSourceType;
                                        tagKind: TagKindDynamicString;
                                        value: string;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "exclude")) {
                                    return { success: false };
                                  }
                                  const parsed_exclude = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                      if (typeof value !== "boolean") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["exclude"]);
                                  })();
                                  if (!parsed_exclude.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "source")) {
                                    return { success: false };
                                  }
                                  const parsed_source = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "USER":
                                            return { success: true, value: "USER" };
                                          case "CHANNEL_HIERARCHY":
                                            return { success: true, value: "CHANNEL_HIERARCHY" };
                                          case "AI":
                                            return { success: true, value: "AI" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["source"]);
                                  })();
                                  if (!parsed_source.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "tagKind")) {
                                    return { success: false };
                                  }
                                  const parsed_tagKind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicString } => {
                                      return ((
                                        value: unknown,
                                      ):
                                        | { success: false }
                                        | {
                                            success: true;
                                            value: {
                                              discriminator: KindDynamicString;
                                              id: bigint;
                                              kind: string;
                                            };
                                          } => {
                                        if (typeof value !== "object") {
                                          return { success: false };
                                        }
                                        if (value === null) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "discriminator")) {
                                          return { success: false };
                                        }
                                        const parsed_discriminator = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindDynamicString } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "dynamicString" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "dynamicString":
                                                  return { success: true, value: "dynamicString" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["discriminator"]);
                                        })();
                                        if (!parsed_discriminator.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "id")) {
                                          return { success: false };
                                        }
                                        const parsed_id = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                            if (typeof value === "number") {
                                              return { success: true, value: BigInt(value) };
                                            }
                                            if (typeof value === "bigint") {
                                              return { success: true, value: value };
                                            }
                                            return { success: false };
                                          })(value["id"]);
                                        })();
                                        if (!parsed_id.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "kind")) {
                                          return { success: false };
                                        }
                                        const parsed_kind = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                                            if (typeof value !== "string") {
                                              return { success: false };
                                            }
                                            return { success: true, value: value };
                                          })(value["kind"]);
                                        })();
                                        if (!parsed_kind.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                      })(value);
                                    })(value["tagKind"]);
                                  })();
                                  if (!parsed_tagKind.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "value")) {
                                    return { success: false };
                                  }
                                  const parsed_value = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["value"]);
                                  })();
                                  if (!parsed_value.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                })(value);
                                if (!parsedVariant.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: "dynamicString" as const, ...parsedVariant.value } };
                              }
                              case "dynamicBoolean": {
                                const parsedVariant = ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        exclude: boolean;
                                        source: TagSourceType;
                                        tagKind: TagKindDynamicBoolean;
                                        value: boolean;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "exclude")) {
                                    return { success: false };
                                  }
                                  const parsed_exclude = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                      if (typeof value !== "boolean") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["exclude"]);
                                  })();
                                  if (!parsed_exclude.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "source")) {
                                    return { success: false };
                                  }
                                  const parsed_source = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "USER":
                                            return { success: true, value: "USER" };
                                          case "CHANNEL_HIERARCHY":
                                            return { success: true, value: "CHANNEL_HIERARCHY" };
                                          case "AI":
                                            return { success: true, value: "AI" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["source"]);
                                  })();
                                  if (!parsed_source.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "tagKind")) {
                                    return { success: false };
                                  }
                                  const parsed_tagKind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicBoolean } => {
                                      return ((
                                        value: unknown,
                                      ):
                                        | { success: false }
                                        | {
                                            success: true;
                                            value: {
                                              discriminator: KindDynamicBoolean;
                                              id: bigint;
                                              kind: string;
                                            };
                                          } => {
                                        if (typeof value !== "object") {
                                          return { success: false };
                                        }
                                        if (value === null) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "discriminator")) {
                                          return { success: false };
                                        }
                                        const parsed_discriminator = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindDynamicBoolean } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "dynamicBoolean" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "dynamicBoolean":
                                                  return { success: true, value: "dynamicBoolean" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["discriminator"]);
                                        })();
                                        if (!parsed_discriminator.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "id")) {
                                          return { success: false };
                                        }
                                        const parsed_id = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                            if (typeof value === "number") {
                                              return { success: true, value: BigInt(value) };
                                            }
                                            if (typeof value === "bigint") {
                                              return { success: true, value: value };
                                            }
                                            return { success: false };
                                          })(value["id"]);
                                        })();
                                        if (!parsed_id.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "kind")) {
                                          return { success: false };
                                        }
                                        const parsed_kind = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                                            if (typeof value !== "string") {
                                              return { success: false };
                                            }
                                            return { success: true, value: value };
                                          })(value["kind"]);
                                        })();
                                        if (!parsed_kind.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                      })(value);
                                    })(value["tagKind"]);
                                  })();
                                  if (!parsed_tagKind.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "value")) {
                                    return { success: false };
                                  }
                                  const parsed_value = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                      if (typeof value !== "boolean") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["value"]);
                                  })();
                                  if (!parsed_value.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                })(value);
                                if (!parsedVariant.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: "dynamicBoolean" as const, ...parsedVariant.value } };
                              }
                              case "dynamicNumber": {
                                const parsedVariant = ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        exclude: boolean;
                                        source: TagSourceType;
                                        tagKind: TagKindDynamicNumber;
                                        value: number;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "exclude")) {
                                    return { success: false };
                                  }
                                  const parsed_exclude = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                      if (typeof value !== "boolean") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["exclude"]);
                                  })();
                                  if (!parsed_exclude.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "source")) {
                                    return { success: false };
                                  }
                                  const parsed_source = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "USER":
                                            return { success: true, value: "USER" };
                                          case "CHANNEL_HIERARCHY":
                                            return { success: true, value: "CHANNEL_HIERARCHY" };
                                          case "AI":
                                            return { success: true, value: "AI" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["source"]);
                                  })();
                                  if (!parsed_source.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "tagKind")) {
                                    return { success: false };
                                  }
                                  const parsed_tagKind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicNumber } => {
                                      return ((
                                        value: unknown,
                                      ):
                                        | { success: false }
                                        | {
                                            success: true;
                                            value: {
                                              discriminator: KindDynamicNumber;
                                              id: bigint;
                                              kind: string;
                                            };
                                          } => {
                                        if (typeof value !== "object") {
                                          return { success: false };
                                        }
                                        if (value === null) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "discriminator")) {
                                          return { success: false };
                                        }
                                        const parsed_discriminator = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindDynamicNumber } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "dynamicNumber" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "dynamicNumber":
                                                  return { success: true, value: "dynamicNumber" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["discriminator"]);
                                        })();
                                        if (!parsed_discriminator.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "id")) {
                                          return { success: false };
                                        }
                                        const parsed_id = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                            if (typeof value === "number") {
                                              return { success: true, value: BigInt(value) };
                                            }
                                            if (typeof value === "bigint") {
                                              return { success: true, value: value };
                                            }
                                            return { success: false };
                                          })(value["id"]);
                                        })();
                                        if (!parsed_id.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "kind")) {
                                          return { success: false };
                                        }
                                        const parsed_kind = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                                            if (typeof value !== "string") {
                                              return { success: false };
                                            }
                                            return { success: true, value: value };
                                          })(value["kind"]);
                                        })();
                                        if (!parsed_kind.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                      })(value);
                                    })(value["tagKind"]);
                                  })();
                                  if (!parsed_tagKind.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "value")) {
                                    return { success: false };
                                  }
                                  const parsed_value = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: number } => {
                                      if (typeof value === "number") {
                                        return { success: true, value: value };
                                      }
                                      return { success: false };
                                    })(value["value"]);
                                  })();
                                  if (!parsed_value.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                })(value);
                                if (!parsedVariant.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: "dynamicNumber" as const, ...parsedVariant.value } };
                              }
                              case "dynamicVoid": {
                                const parsedVariant = ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        exclude: boolean;
                                        source: TagSourceType;
                                        tagKind: TagKindDynamicVoid;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "exclude")) {
                                    return { success: false };
                                  }
                                  const parsed_exclude = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                      if (typeof value !== "boolean") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["exclude"]);
                                  })();
                                  if (!parsed_exclude.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "source")) {
                                    return { success: false };
                                  }
                                  const parsed_source = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "USER":
                                            return { success: true, value: "USER" };
                                          case "CHANNEL_HIERARCHY":
                                            return { success: true, value: "CHANNEL_HIERARCHY" };
                                          case "AI":
                                            return { success: true, value: "AI" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["source"]);
                                  })();
                                  if (!parsed_source.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "tagKind")) {
                                    return { success: false };
                                  }
                                  const parsed_tagKind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicVoid } => {
                                      return ((
                                        value: unknown,
                                      ):
                                        | { success: false }
                                        | {
                                            success: true;
                                            value: {
                                              discriminator: KindDynamicVoid;
                                              id: bigint;
                                              kind: string;
                                            };
                                          } => {
                                        if (typeof value !== "object") {
                                          return { success: false };
                                        }
                                        if (value === null) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "discriminator")) {
                                          return { success: false };
                                        }
                                        const parsed_discriminator = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindDynamicVoid } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "dynamicVoid" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "dynamicVoid":
                                                  return { success: true, value: "dynamicVoid" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["discriminator"]);
                                        })();
                                        if (!parsed_discriminator.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "id")) {
                                          return { success: false };
                                        }
                                        const parsed_id = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                            if (typeof value === "number") {
                                              return { success: true, value: BigInt(value) };
                                            }
                                            if (typeof value === "bigint") {
                                              return { success: true, value: value };
                                            }
                                            return { success: false };
                                          })(value["id"]);
                                        })();
                                        if (!parsed_id.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "kind")) {
                                          return { success: false };
                                        }
                                        const parsed_kind = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                                            if (typeof value !== "string") {
                                              return { success: false };
                                            }
                                            return { success: true, value: value };
                                          })(value["kind"]);
                                        })();
                                        if (!parsed_kind.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                      })(value);
                                    })(value["tagKind"]);
                                  })();
                                  if (!parsed_tagKind.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value } };
                                })(value);
                                if (!parsedVariant.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: "dynamicVoid" as const, ...parsedVariant.value } };
                              }
                              case "channel": {
                                const parsedVariant = ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        exclude: boolean;
                                        source: TagSourceType;
                                        tagKind: TagKindChannel;
                                        value: string;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "exclude")) {
                                    return { success: false };
                                  }
                                  const parsed_exclude = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                      if (typeof value !== "boolean") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["exclude"]);
                                  })();
                                  if (!parsed_exclude.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "source")) {
                                    return { success: false };
                                  }
                                  const parsed_source = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "USER":
                                            return { success: true, value: "USER" };
                                          case "CHANNEL_HIERARCHY":
                                            return { success: true, value: "CHANNEL_HIERARCHY" };
                                          case "AI":
                                            return { success: true, value: "AI" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["source"]);
                                  })();
                                  if (!parsed_source.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "tagKind")) {
                                    return { success: false };
                                  }
                                  const parsed_tagKind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagKindChannel } => {
                                      return ((
                                        value: unknown,
                                      ):
                                        | { success: false }
                                        | {
                                            success: true;
                                            value: {
                                              discriminator: KindChannel;
                                              id: bigint;
                                              kind: KindChannel;
                                            };
                                          } => {
                                        if (typeof value !== "object") {
                                          return { success: false };
                                        }
                                        if (value === null) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "discriminator")) {
                                          return { success: false };
                                        }
                                        const parsed_discriminator = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "channel":
                                                  return { success: true, value: "channel" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["discriminator"]);
                                        })();
                                        if (!parsed_discriminator.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "id")) {
                                          return { success: false };
                                        }
                                        const parsed_id = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                            if (typeof value === "number") {
                                              return { success: true, value: BigInt(value) };
                                            }
                                            if (typeof value === "bigint") {
                                              return { success: true, value: value };
                                            }
                                            return { success: false };
                                          })(value["id"]);
                                        })();
                                        if (!parsed_id.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "kind")) {
                                          return { success: false };
                                        }
                                        const parsed_kind = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "channel":
                                                  return { success: true, value: "channel" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["kind"]);
                                        })();
                                        if (!parsed_kind.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                      })(value);
                                    })(value["tagKind"]);
                                  })();
                                  if (!parsed_tagKind.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "value")) {
                                    return { success: false };
                                  }
                                  const parsed_value = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["value"]);
                                  })();
                                  if (!parsed_value.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                })(value);
                                if (!parsedVariant.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: "channel" as const, ...parsedVariant.value } };
                              }
                              case "key-quality": {
                                const parsedVariant = ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        exclude: boolean;
                                        source: TagSourceType;
                                        tagKind: TagKindKeyQuality;
                                        value: string;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "exclude")) {
                                    return { success: false };
                                  }
                                  const parsed_exclude = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                      if (typeof value !== "boolean") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["exclude"]);
                                  })();
                                  if (!parsed_exclude.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "source")) {
                                    return { success: false };
                                  }
                                  const parsed_source = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "USER":
                                            return { success: true, value: "USER" };
                                          case "CHANNEL_HIERARCHY":
                                            return { success: true, value: "CHANNEL_HIERARCHY" };
                                          case "AI":
                                            return { success: true, value: "AI" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["source"]);
                                  })();
                                  if (!parsed_source.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "tagKind")) {
                                    return { success: false };
                                  }
                                  const parsed_tagKind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagKindKeyQuality } => {
                                      return ((
                                        value: unknown,
                                      ):
                                        | { success: false }
                                        | {
                                            success: true;
                                            value: {
                                              discriminator: KindKeyQuality;
                                              id: bigint;
                                              kind: KindKeyQuality;
                                            };
                                          } => {
                                        if (typeof value !== "object") {
                                          return { success: false };
                                        }
                                        if (value === null) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "discriminator")) {
                                          return { success: false };
                                        }
                                        const parsed_discriminator = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "key-quality":
                                                  return { success: true, value: "key-quality" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["discriminator"]);
                                        })();
                                        if (!parsed_discriminator.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "id")) {
                                          return { success: false };
                                        }
                                        const parsed_id = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                            if (typeof value === "number") {
                                              return { success: true, value: BigInt(value) };
                                            }
                                            if (typeof value === "bigint") {
                                              return { success: true, value: value };
                                            }
                                            return { success: false };
                                          })(value["id"]);
                                        })();
                                        if (!parsed_id.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "kind")) {
                                          return { success: false };
                                        }
                                        const parsed_kind = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "key-quality":
                                                  return { success: true, value: "key-quality" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["kind"]);
                                        })();
                                        if (!parsed_kind.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                      })(value);
                                    })(value["tagKind"]);
                                  })();
                                  if (!parsed_tagKind.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "value")) {
                                    return { success: false };
                                  }
                                  const parsed_value = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["value"]);
                                  })();
                                  if (!parsed_value.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                })(value);
                                if (!parsedVariant.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: "key-quality" as const, ...parsedVariant.value } };
                              }
                              case "classification": {
                                const parsedVariant = ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        exclude: boolean;
                                        source: TagSourceType;
                                        tagKind: TagKindClassification;
                                        value: string;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "exclude")) {
                                    return { success: false };
                                  }
                                  const parsed_exclude = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                      if (typeof value !== "boolean") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["exclude"]);
                                  })();
                                  if (!parsed_exclude.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "source")) {
                                    return { success: false };
                                  }
                                  const parsed_source = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "USER":
                                            return { success: true, value: "USER" };
                                          case "CHANNEL_HIERARCHY":
                                            return { success: true, value: "CHANNEL_HIERARCHY" };
                                          case "AI":
                                            return { success: true, value: "AI" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["source"]);
                                  })();
                                  if (!parsed_source.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "tagKind")) {
                                    return { success: false };
                                  }
                                  const parsed_tagKind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagKindClassification } => {
                                      return ((
                                        value: unknown,
                                      ):
                                        | { success: false }
                                        | {
                                            success: true;
                                            value: {
                                              discriminator: KindClassification;
                                              id: bigint;
                                              kind: KindClassification;
                                            };
                                          } => {
                                        if (typeof value !== "object") {
                                          return { success: false };
                                        }
                                        if (value === null) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "discriminator")) {
                                          return { success: false };
                                        }
                                        const parsed_discriminator = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "classification":
                                                  return { success: true, value: "classification" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["discriminator"]);
                                        })();
                                        if (!parsed_discriminator.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "id")) {
                                          return { success: false };
                                        }
                                        const parsed_id = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                            if (typeof value === "number") {
                                              return { success: true, value: BigInt(value) };
                                            }
                                            if (typeof value === "bigint") {
                                              return { success: true, value: value };
                                            }
                                            return { success: false };
                                          })(value["id"]);
                                        })();
                                        if (!parsed_id.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "kind")) {
                                          return { success: false };
                                        }
                                        const parsed_kind = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "classification":
                                                  return { success: true, value: "classification" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["kind"]);
                                        })();
                                        if (!parsed_kind.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                      })(value);
                                    })(value["tagKind"]);
                                  })();
                                  if (!parsed_tagKind.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "value")) {
                                    return { success: false };
                                  }
                                  const parsed_value = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["value"]);
                                  })();
                                  if (!parsed_value.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                })(value);
                                if (!parsedVariant.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: "classification" as const, ...parsedVariant.value } };
                              }
                              default:
                                return { success: false };
                            }
                          })(value);
                        })(value);
                      });
                      if (parsed.find((value) => !value.success) !== undefined) {
                        return { success: false };
                      }
                      return {
                        success: true,
                        value: parsed.map((entry) => {
                          assert(entry.success == true);
                          return entry.value;
                        }),
                      };
                    })(value["tags"]);
                  })();
                  if (!parsed_tags.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "title")) {
                    return { success: false };
                  }
                  const parsed_title = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["title"]);
                  })();
                  if (!parsed_title.success) {
                    return { success: false };
                  }
                  return {
                    success: true,
                    value: {
                      activatedAt: parsed_activatedAt.value,
                      active: parsed_active.value,
                      createdAt: parsed_createdAt.value,
                      deactivatedAt: parsed_deactivatedAt.value,
                      id: parsed_id.value,
                      language: parsed_language.value,
                      manager: parsed_manager.value,
                      tags: parsed_tags.value,
                      title: parsed_title.value,
                    },
                  };
                })(value);
              })(value["metadata"]);
            })();
            if (!parsed_metadata.success) {
              return { success: false };
            }
            return { success: true, value: { description: parsed_description.value, metadata: parsed_metadata.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { learningItemType: "ACTIONABLE" as const, ...parsedVariant.value } };
        }
        case "SUMMARY": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  audioActive: boolean;
                  documentType: SummaryDocumentType;
                  metadata: LearningItemAdminMetadataView;
                  publisher: string | null;
                  sourceType: SummarySourceType;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "audioActive")) {
              return { success: false };
            }
            const parsed_audioActive = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["audioActive"]);
            })();
            if (!parsed_audioActive.success) {
              return { success: false };
            }
            if (!hasProp(value, "documentType")) {
              return { success: false };
            }
            const parsed_documentType = (() => {
              return ((value: unknown): { success: false } | { success: true; value: SummaryDocumentType } => {
                return ((value: unknown): { success: false } | { success: true; value: "ABSTRACT" | "REVIEW" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "ABSTRACT":
                      return { success: true, value: "ABSTRACT" };
                    case "REVIEW":
                      return { success: true, value: "REVIEW" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["documentType"]);
            })();
            if (!parsed_documentType.success) {
              return { success: false };
            }
            if (!hasProp(value, "metadata")) {
              return { success: false };
            }
            const parsed_metadata = (() => {
              return ((value: unknown): { success: false } | { success: true; value: LearningItemAdminMetadataView } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        activatedAt: { year: number; month: number; day: number } | null;
                        active: boolean;
                        createdAt: { year: number; month: number; day: number };
                        deactivatedAt: { year: number; month: number; day: number } | null;
                        id: bigint;
                        language: Language;
                        manager: string | null;
                        tags: Tag[];
                        title: string;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "activatedAt")) {
                    return { success: false };
                  }
                  const parsed_activatedAt = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
                      if (!parsed.isValid) {
                        return { success: false };
                      }
                      return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
                    })(value["activatedAt"]);
                  })();
                  if (!parsed_activatedAt.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "active")) {
                    return { success: false };
                  }
                  const parsed_active = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["active"]);
                  })();
                  if (!parsed_active.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "createdAt")) {
                    return { success: false };
                  }
                  const parsed_createdAt = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
                      if (!parsed.isValid) {
                        return { success: false };
                      }
                      return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
                    })(value["createdAt"]);
                  })();
                  if (!parsed_createdAt.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "deactivatedAt")) {
                    return { success: false };
                  }
                  const parsed_deactivatedAt = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
                      if (!parsed.isValid) {
                        return { success: false };
                      }
                      return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
                    })(value["deactivatedAt"]);
                  })();
                  if (!parsed_deactivatedAt.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "id")) {
                    return { success: false };
                  }
                  const parsed_id = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                      if (typeof value === "number") {
                        return { success: true, value: BigInt(value) };
                      }
                      if (typeof value === "bigint") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["id"]);
                  })();
                  if (!parsed_id.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "language")) {
                    return { success: false };
                  }
                  const parsed_language = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: Language } => {
                      return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "en":
                            return { success: true, value: "en" };
                          case "de":
                            return { success: true, value: "de" };
                          case "es":
                            return { success: true, value: "es" };
                          case "ru":
                            return { success: true, value: "ru" };
                          case "zh":
                            return { success: true, value: "zh" };
                          case "pt":
                            return { success: true, value: "pt" };
                          case "fr":
                            return { success: true, value: "fr" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["language"]);
                  })();
                  if (!parsed_language.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "manager")) {
                    return { success: false };
                  }
                  const parsed_manager = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["manager"]);
                  })();
                  if (!parsed_manager.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "tags")) {
                    return { success: false };
                  }
                  const parsed_tags = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: Tag[] } => {
                      if (!isArray(value)) {
                        return { success: false };
                      }
                      const parsed = value.map((value: unknown): { success: false } | { success: true; value: Tag } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value:
                                | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                                | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                                | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                                | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                                | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                                | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                                | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                            } => {
                          return ((
                            value: unknown,
                          ):
                            | { success: false }
                            | {
                                success: true;
                                value:
                                  | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                                  | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                                  | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                                  | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                                  | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                                  | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                                  | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                              } => {
                            if (value == null) {
                              return { success: false };
                            }
                            if (typeof value != "object") {
                              return { success: false };
                            }
                            if (!hasProp(value, "discriminator")) {
                              return { success: false };
                            }
                            if (typeof value["discriminator"] != "string") {
                              return { success: false };
                            }
                            const discriminator = value["discriminator"];
                            switch (discriminator) {
                              case "dynamicString": {
                                const parsedVariant = ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        exclude: boolean;
                                        source: TagSourceType;
                                        tagKind: TagKindDynamicString;
                                        value: string;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "exclude")) {
                                    return { success: false };
                                  }
                                  const parsed_exclude = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                      if (typeof value !== "boolean") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["exclude"]);
                                  })();
                                  if (!parsed_exclude.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "source")) {
                                    return { success: false };
                                  }
                                  const parsed_source = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "USER":
                                            return { success: true, value: "USER" };
                                          case "CHANNEL_HIERARCHY":
                                            return { success: true, value: "CHANNEL_HIERARCHY" };
                                          case "AI":
                                            return { success: true, value: "AI" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["source"]);
                                  })();
                                  if (!parsed_source.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "tagKind")) {
                                    return { success: false };
                                  }
                                  const parsed_tagKind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicString } => {
                                      return ((
                                        value: unknown,
                                      ):
                                        | { success: false }
                                        | {
                                            success: true;
                                            value: {
                                              discriminator: KindDynamicString;
                                              id: bigint;
                                              kind: string;
                                            };
                                          } => {
                                        if (typeof value !== "object") {
                                          return { success: false };
                                        }
                                        if (value === null) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "discriminator")) {
                                          return { success: false };
                                        }
                                        const parsed_discriminator = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindDynamicString } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "dynamicString" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "dynamicString":
                                                  return { success: true, value: "dynamicString" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["discriminator"]);
                                        })();
                                        if (!parsed_discriminator.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "id")) {
                                          return { success: false };
                                        }
                                        const parsed_id = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                            if (typeof value === "number") {
                                              return { success: true, value: BigInt(value) };
                                            }
                                            if (typeof value === "bigint") {
                                              return { success: true, value: value };
                                            }
                                            return { success: false };
                                          })(value["id"]);
                                        })();
                                        if (!parsed_id.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "kind")) {
                                          return { success: false };
                                        }
                                        const parsed_kind = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                                            if (typeof value !== "string") {
                                              return { success: false };
                                            }
                                            return { success: true, value: value };
                                          })(value["kind"]);
                                        })();
                                        if (!parsed_kind.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                      })(value);
                                    })(value["tagKind"]);
                                  })();
                                  if (!parsed_tagKind.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "value")) {
                                    return { success: false };
                                  }
                                  const parsed_value = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["value"]);
                                  })();
                                  if (!parsed_value.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                })(value);
                                if (!parsedVariant.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: "dynamicString" as const, ...parsedVariant.value } };
                              }
                              case "dynamicBoolean": {
                                const parsedVariant = ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        exclude: boolean;
                                        source: TagSourceType;
                                        tagKind: TagKindDynamicBoolean;
                                        value: boolean;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "exclude")) {
                                    return { success: false };
                                  }
                                  const parsed_exclude = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                      if (typeof value !== "boolean") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["exclude"]);
                                  })();
                                  if (!parsed_exclude.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "source")) {
                                    return { success: false };
                                  }
                                  const parsed_source = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "USER":
                                            return { success: true, value: "USER" };
                                          case "CHANNEL_HIERARCHY":
                                            return { success: true, value: "CHANNEL_HIERARCHY" };
                                          case "AI":
                                            return { success: true, value: "AI" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["source"]);
                                  })();
                                  if (!parsed_source.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "tagKind")) {
                                    return { success: false };
                                  }
                                  const parsed_tagKind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicBoolean } => {
                                      return ((
                                        value: unknown,
                                      ):
                                        | { success: false }
                                        | {
                                            success: true;
                                            value: {
                                              discriminator: KindDynamicBoolean;
                                              id: bigint;
                                              kind: string;
                                            };
                                          } => {
                                        if (typeof value !== "object") {
                                          return { success: false };
                                        }
                                        if (value === null) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "discriminator")) {
                                          return { success: false };
                                        }
                                        const parsed_discriminator = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindDynamicBoolean } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "dynamicBoolean" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "dynamicBoolean":
                                                  return { success: true, value: "dynamicBoolean" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["discriminator"]);
                                        })();
                                        if (!parsed_discriminator.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "id")) {
                                          return { success: false };
                                        }
                                        const parsed_id = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                            if (typeof value === "number") {
                                              return { success: true, value: BigInt(value) };
                                            }
                                            if (typeof value === "bigint") {
                                              return { success: true, value: value };
                                            }
                                            return { success: false };
                                          })(value["id"]);
                                        })();
                                        if (!parsed_id.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "kind")) {
                                          return { success: false };
                                        }
                                        const parsed_kind = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                                            if (typeof value !== "string") {
                                              return { success: false };
                                            }
                                            return { success: true, value: value };
                                          })(value["kind"]);
                                        })();
                                        if (!parsed_kind.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                      })(value);
                                    })(value["tagKind"]);
                                  })();
                                  if (!parsed_tagKind.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "value")) {
                                    return { success: false };
                                  }
                                  const parsed_value = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                      if (typeof value !== "boolean") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["value"]);
                                  })();
                                  if (!parsed_value.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                })(value);
                                if (!parsedVariant.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: "dynamicBoolean" as const, ...parsedVariant.value } };
                              }
                              case "dynamicNumber": {
                                const parsedVariant = ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        exclude: boolean;
                                        source: TagSourceType;
                                        tagKind: TagKindDynamicNumber;
                                        value: number;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "exclude")) {
                                    return { success: false };
                                  }
                                  const parsed_exclude = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                      if (typeof value !== "boolean") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["exclude"]);
                                  })();
                                  if (!parsed_exclude.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "source")) {
                                    return { success: false };
                                  }
                                  const parsed_source = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "USER":
                                            return { success: true, value: "USER" };
                                          case "CHANNEL_HIERARCHY":
                                            return { success: true, value: "CHANNEL_HIERARCHY" };
                                          case "AI":
                                            return { success: true, value: "AI" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["source"]);
                                  })();
                                  if (!parsed_source.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "tagKind")) {
                                    return { success: false };
                                  }
                                  const parsed_tagKind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicNumber } => {
                                      return ((
                                        value: unknown,
                                      ):
                                        | { success: false }
                                        | {
                                            success: true;
                                            value: {
                                              discriminator: KindDynamicNumber;
                                              id: bigint;
                                              kind: string;
                                            };
                                          } => {
                                        if (typeof value !== "object") {
                                          return { success: false };
                                        }
                                        if (value === null) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "discriminator")) {
                                          return { success: false };
                                        }
                                        const parsed_discriminator = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindDynamicNumber } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "dynamicNumber" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "dynamicNumber":
                                                  return { success: true, value: "dynamicNumber" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["discriminator"]);
                                        })();
                                        if (!parsed_discriminator.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "id")) {
                                          return { success: false };
                                        }
                                        const parsed_id = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                            if (typeof value === "number") {
                                              return { success: true, value: BigInt(value) };
                                            }
                                            if (typeof value === "bigint") {
                                              return { success: true, value: value };
                                            }
                                            return { success: false };
                                          })(value["id"]);
                                        })();
                                        if (!parsed_id.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "kind")) {
                                          return { success: false };
                                        }
                                        const parsed_kind = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                                            if (typeof value !== "string") {
                                              return { success: false };
                                            }
                                            return { success: true, value: value };
                                          })(value["kind"]);
                                        })();
                                        if (!parsed_kind.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                      })(value);
                                    })(value["tagKind"]);
                                  })();
                                  if (!parsed_tagKind.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "value")) {
                                    return { success: false };
                                  }
                                  const parsed_value = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: number } => {
                                      if (typeof value === "number") {
                                        return { success: true, value: value };
                                      }
                                      return { success: false };
                                    })(value["value"]);
                                  })();
                                  if (!parsed_value.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                })(value);
                                if (!parsedVariant.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: "dynamicNumber" as const, ...parsedVariant.value } };
                              }
                              case "dynamicVoid": {
                                const parsedVariant = ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        exclude: boolean;
                                        source: TagSourceType;
                                        tagKind: TagKindDynamicVoid;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "exclude")) {
                                    return { success: false };
                                  }
                                  const parsed_exclude = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                      if (typeof value !== "boolean") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["exclude"]);
                                  })();
                                  if (!parsed_exclude.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "source")) {
                                    return { success: false };
                                  }
                                  const parsed_source = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "USER":
                                            return { success: true, value: "USER" };
                                          case "CHANNEL_HIERARCHY":
                                            return { success: true, value: "CHANNEL_HIERARCHY" };
                                          case "AI":
                                            return { success: true, value: "AI" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["source"]);
                                  })();
                                  if (!parsed_source.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "tagKind")) {
                                    return { success: false };
                                  }
                                  const parsed_tagKind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicVoid } => {
                                      return ((
                                        value: unknown,
                                      ):
                                        | { success: false }
                                        | {
                                            success: true;
                                            value: {
                                              discriminator: KindDynamicVoid;
                                              id: bigint;
                                              kind: string;
                                            };
                                          } => {
                                        if (typeof value !== "object") {
                                          return { success: false };
                                        }
                                        if (value === null) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "discriminator")) {
                                          return { success: false };
                                        }
                                        const parsed_discriminator = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindDynamicVoid } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "dynamicVoid" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "dynamicVoid":
                                                  return { success: true, value: "dynamicVoid" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["discriminator"]);
                                        })();
                                        if (!parsed_discriminator.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "id")) {
                                          return { success: false };
                                        }
                                        const parsed_id = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                            if (typeof value === "number") {
                                              return { success: true, value: BigInt(value) };
                                            }
                                            if (typeof value === "bigint") {
                                              return { success: true, value: value };
                                            }
                                            return { success: false };
                                          })(value["id"]);
                                        })();
                                        if (!parsed_id.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "kind")) {
                                          return { success: false };
                                        }
                                        const parsed_kind = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                                            if (typeof value !== "string") {
                                              return { success: false };
                                            }
                                            return { success: true, value: value };
                                          })(value["kind"]);
                                        })();
                                        if (!parsed_kind.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                      })(value);
                                    })(value["tagKind"]);
                                  })();
                                  if (!parsed_tagKind.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value } };
                                })(value);
                                if (!parsedVariant.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: "dynamicVoid" as const, ...parsedVariant.value } };
                              }
                              case "channel": {
                                const parsedVariant = ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        exclude: boolean;
                                        source: TagSourceType;
                                        tagKind: TagKindChannel;
                                        value: string;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "exclude")) {
                                    return { success: false };
                                  }
                                  const parsed_exclude = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                      if (typeof value !== "boolean") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["exclude"]);
                                  })();
                                  if (!parsed_exclude.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "source")) {
                                    return { success: false };
                                  }
                                  const parsed_source = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "USER":
                                            return { success: true, value: "USER" };
                                          case "CHANNEL_HIERARCHY":
                                            return { success: true, value: "CHANNEL_HIERARCHY" };
                                          case "AI":
                                            return { success: true, value: "AI" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["source"]);
                                  })();
                                  if (!parsed_source.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "tagKind")) {
                                    return { success: false };
                                  }
                                  const parsed_tagKind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagKindChannel } => {
                                      return ((
                                        value: unknown,
                                      ):
                                        | { success: false }
                                        | {
                                            success: true;
                                            value: {
                                              discriminator: KindChannel;
                                              id: bigint;
                                              kind: KindChannel;
                                            };
                                          } => {
                                        if (typeof value !== "object") {
                                          return { success: false };
                                        }
                                        if (value === null) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "discriminator")) {
                                          return { success: false };
                                        }
                                        const parsed_discriminator = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "channel":
                                                  return { success: true, value: "channel" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["discriminator"]);
                                        })();
                                        if (!parsed_discriminator.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "id")) {
                                          return { success: false };
                                        }
                                        const parsed_id = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                            if (typeof value === "number") {
                                              return { success: true, value: BigInt(value) };
                                            }
                                            if (typeof value === "bigint") {
                                              return { success: true, value: value };
                                            }
                                            return { success: false };
                                          })(value["id"]);
                                        })();
                                        if (!parsed_id.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "kind")) {
                                          return { success: false };
                                        }
                                        const parsed_kind = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "channel":
                                                  return { success: true, value: "channel" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["kind"]);
                                        })();
                                        if (!parsed_kind.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                      })(value);
                                    })(value["tagKind"]);
                                  })();
                                  if (!parsed_tagKind.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "value")) {
                                    return { success: false };
                                  }
                                  const parsed_value = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["value"]);
                                  })();
                                  if (!parsed_value.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                })(value);
                                if (!parsedVariant.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: "channel" as const, ...parsedVariant.value } };
                              }
                              case "key-quality": {
                                const parsedVariant = ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        exclude: boolean;
                                        source: TagSourceType;
                                        tagKind: TagKindKeyQuality;
                                        value: string;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "exclude")) {
                                    return { success: false };
                                  }
                                  const parsed_exclude = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                      if (typeof value !== "boolean") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["exclude"]);
                                  })();
                                  if (!parsed_exclude.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "source")) {
                                    return { success: false };
                                  }
                                  const parsed_source = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "USER":
                                            return { success: true, value: "USER" };
                                          case "CHANNEL_HIERARCHY":
                                            return { success: true, value: "CHANNEL_HIERARCHY" };
                                          case "AI":
                                            return { success: true, value: "AI" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["source"]);
                                  })();
                                  if (!parsed_source.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "tagKind")) {
                                    return { success: false };
                                  }
                                  const parsed_tagKind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagKindKeyQuality } => {
                                      return ((
                                        value: unknown,
                                      ):
                                        | { success: false }
                                        | {
                                            success: true;
                                            value: {
                                              discriminator: KindKeyQuality;
                                              id: bigint;
                                              kind: KindKeyQuality;
                                            };
                                          } => {
                                        if (typeof value !== "object") {
                                          return { success: false };
                                        }
                                        if (value === null) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "discriminator")) {
                                          return { success: false };
                                        }
                                        const parsed_discriminator = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "key-quality":
                                                  return { success: true, value: "key-quality" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["discriminator"]);
                                        })();
                                        if (!parsed_discriminator.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "id")) {
                                          return { success: false };
                                        }
                                        const parsed_id = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                            if (typeof value === "number") {
                                              return { success: true, value: BigInt(value) };
                                            }
                                            if (typeof value === "bigint") {
                                              return { success: true, value: value };
                                            }
                                            return { success: false };
                                          })(value["id"]);
                                        })();
                                        if (!parsed_id.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "kind")) {
                                          return { success: false };
                                        }
                                        const parsed_kind = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "key-quality":
                                                  return { success: true, value: "key-quality" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["kind"]);
                                        })();
                                        if (!parsed_kind.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                      })(value);
                                    })(value["tagKind"]);
                                  })();
                                  if (!parsed_tagKind.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "value")) {
                                    return { success: false };
                                  }
                                  const parsed_value = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["value"]);
                                  })();
                                  if (!parsed_value.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                })(value);
                                if (!parsedVariant.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: "key-quality" as const, ...parsedVariant.value } };
                              }
                              case "classification": {
                                const parsedVariant = ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        exclude: boolean;
                                        source: TagSourceType;
                                        tagKind: TagKindClassification;
                                        value: string;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "exclude")) {
                                    return { success: false };
                                  }
                                  const parsed_exclude = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                      if (typeof value !== "boolean") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["exclude"]);
                                  })();
                                  if (!parsed_exclude.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "source")) {
                                    return { success: false };
                                  }
                                  const parsed_source = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "USER":
                                            return { success: true, value: "USER" };
                                          case "CHANNEL_HIERARCHY":
                                            return { success: true, value: "CHANNEL_HIERARCHY" };
                                          case "AI":
                                            return { success: true, value: "AI" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["source"]);
                                  })();
                                  if (!parsed_source.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "tagKind")) {
                                    return { success: false };
                                  }
                                  const parsed_tagKind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagKindClassification } => {
                                      return ((
                                        value: unknown,
                                      ):
                                        | { success: false }
                                        | {
                                            success: true;
                                            value: {
                                              discriminator: KindClassification;
                                              id: bigint;
                                              kind: KindClassification;
                                            };
                                          } => {
                                        if (typeof value !== "object") {
                                          return { success: false };
                                        }
                                        if (value === null) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "discriminator")) {
                                          return { success: false };
                                        }
                                        const parsed_discriminator = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "classification":
                                                  return { success: true, value: "classification" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["discriminator"]);
                                        })();
                                        if (!parsed_discriminator.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "id")) {
                                          return { success: false };
                                        }
                                        const parsed_id = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                            if (typeof value === "number") {
                                              return { success: true, value: BigInt(value) };
                                            }
                                            if (typeof value === "bigint") {
                                              return { success: true, value: value };
                                            }
                                            return { success: false };
                                          })(value["id"]);
                                        })();
                                        if (!parsed_id.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "kind")) {
                                          return { success: false };
                                        }
                                        const parsed_kind = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "classification":
                                                  return { success: true, value: "classification" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["kind"]);
                                        })();
                                        if (!parsed_kind.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                      })(value);
                                    })(value["tagKind"]);
                                  })();
                                  if (!parsed_tagKind.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "value")) {
                                    return { success: false };
                                  }
                                  const parsed_value = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["value"]);
                                  })();
                                  if (!parsed_value.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                })(value);
                                if (!parsedVariant.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: "classification" as const, ...parsedVariant.value } };
                              }
                              default:
                                return { success: false };
                            }
                          })(value);
                        })(value);
                      });
                      if (parsed.find((value) => !value.success) !== undefined) {
                        return { success: false };
                      }
                      return {
                        success: true,
                        value: parsed.map((entry) => {
                          assert(entry.success == true);
                          return entry.value;
                        }),
                      };
                    })(value["tags"]);
                  })();
                  if (!parsed_tags.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "title")) {
                    return { success: false };
                  }
                  const parsed_title = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["title"]);
                  })();
                  if (!parsed_title.success) {
                    return { success: false };
                  }
                  return {
                    success: true,
                    value: {
                      activatedAt: parsed_activatedAt.value,
                      active: parsed_active.value,
                      createdAt: parsed_createdAt.value,
                      deactivatedAt: parsed_deactivatedAt.value,
                      id: parsed_id.value,
                      language: parsed_language.value,
                      manager: parsed_manager.value,
                      tags: parsed_tags.value,
                      title: parsed_title.value,
                    },
                  };
                })(value);
              })(value["metadata"]);
            })();
            if (!parsed_metadata.success) {
              return { success: false };
            }
            if (!hasProp(value, "publisher")) {
              return { success: false };
            }
            const parsed_publisher = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["publisher"]);
            })();
            if (!parsed_publisher.success) {
              return { success: false };
            }
            if (!hasProp(value, "sourceType")) {
              return { success: false };
            }
            const parsed_sourceType = (() => {
              return ((value: unknown): { success: false } | { success: true; value: SummarySourceType } => {
                return ((value: unknown): { success: false } | { success: true; value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "BOOK":
                      return { success: true, value: "BOOK" };
                    case "VIDEO":
                      return { success: true, value: "VIDEO" };
                    case "ARTICLE":
                      return { success: true, value: "ARTICLE" };
                    case "CHAPTER":
                      return { success: true, value: "CHAPTER" };
                    case "REPORT":
                      return { success: true, value: "REPORT" };
                    case "PODCAST":
                      return { success: true, value: "PODCAST" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["sourceType"]);
            })();
            if (!parsed_sourceType.success) {
              return { success: false };
            }
            return { success: true, value: { audioActive: parsed_audioActive.value, documentType: parsed_documentType.value, metadata: parsed_metadata.value, publisher: parsed_publisher.value, sourceType: parsed_sourceType.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { learningItemType: "SUMMARY" as const, ...parsedVariant.value } };
        }
        default:
          return { success: false };
      }
    })(value);
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// LearningItemType
export function serialize_LearningItemType(obj: LearningItemType): string {
  const serialized = ((value: "SUMMARY" | "ACTIONABLE"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_LearningItemType(str: string): LearningItemType | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "SUMMARY" | "ACTIONABLE" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "SUMMARY":
        return { success: true, value: "SUMMARY" };
      case "ACTIONABLE":
        return { success: true, value: "ACTIONABLE" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// LearningItemTypeActionable
export function serialize_LearningItemTypeActionable(obj: LearningItemTypeActionable): string {
  const serialized = ((value: "ACTIONABLE"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_LearningItemTypeActionable(str: string): LearningItemTypeActionable | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "ACTIONABLE" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "ACTIONABLE":
        return { success: true, value: "ACTIONABLE" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// LearningItemTypeSummary
export function serialize_LearningItemTypeSummary(obj: LearningItemTypeSummary): string {
  const serialized = ((value: "SUMMARY"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_LearningItemTypeSummary(str: string): LearningItemTypeSummary | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "SUMMARY" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "SUMMARY":
        return { success: true, value: "SUMMARY" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// MyListPortletView
export function serialize_MyListPortletView(obj: MyListPortletView): string {
  const serialized = ((value: { items: MyListView[]; metadata: PortletMetadata; page: number; pageSize: number; totalCount: bigint; viewAllUri: string | null }): unknown => {
    return {
      items: ((value: MyListView[]): unknown => {
        return value.map((value: { coverUri: string; key: string; myListId: bigint; name: string }): unknown => {
          return {
            coverUri: ((value: string): unknown => {
              return value;
            })(value["coverUri"]),
            key: ((value: string): unknown => {
              return value;
            })(value["key"]),
            myListId: ((value: bigint): unknown => {
              return value;
            })(value["myListId"]),
            name: ((value: string): unknown => {
              return value;
            })(value["name"]),
          };
        });
      })(value["items"]),
      metadata: ((value: {
        custom: boolean;
        defaultFilterAllLanguages: boolean;
        embedSortingFiltering: boolean;
        hideTitle: boolean;
        itemCount: number;
        itemLayout: ItemLayout;
        name: string;
        portletId: number;
        portletLayout: PortletLayout;
        subType: string | null;
        title: string;
        type: PortletType;
      }): unknown => {
        return {
          custom: ((value: boolean): unknown => {
            return value;
          })(value["custom"]),
          defaultFilterAllLanguages: ((value: boolean): unknown => {
            return value;
          })(value["defaultFilterAllLanguages"]),
          embedSortingFiltering: ((value: boolean): unknown => {
            return value;
          })(value["embedSortingFiltering"]),
          hideTitle: ((value: boolean): unknown => {
            return value;
          })(value["hideTitle"]),
          itemCount: ((value: number): unknown => {
            return value;
          })(value["itemCount"]),
          itemLayout: ((value: "MINI" | "REGULAR"): unknown => {
            return value;
          })(value["itemLayout"]),
          name: ((value: string): unknown => {
            return value;
          })(value["name"]),
          portletId: ((value: number): unknown => {
            return value;
          })(value["portletId"]),
          portletLayout: ((value: "GRID" | "CAROUSEL"): unknown => {
            return value;
          })(value["portletLayout"]),
          subType: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["subType"]),
          title: ((value: string): unknown => {
            return value;
          })(value["title"]),
          type: ((
            value:
              | "IMAGETEXT"
              | "SUMMARYLIST"
              | "TRENDINGCHANNEL"
              | "FEATUREDCHANNEL"
              | "FOLLOWINGCHANNEL"
              | "MYLIST"
              | "PRIMARYTOPICCHANNEL"
              | "CHANNELLIST"
              | "ANNOTATIONLIST"
              | "LGXPLIST"
              | "SKETCHNOTESLGXP"
              | "ACTIONABLELIST"
              | "ACTIONABLELISTCUSTOM"
              | "MYACTIONABLES"
              | "FREESUMMARY"
              | "SUMMARYLISTCUSTOM"
              | "CUSTOMPAGELIST",
          ): unknown => {
            return value;
          })(value["type"]),
        };
      })(value["metadata"]),
      page: ((value: number): unknown => {
        return value;
      })(value["page"]),
      pageSize: ((value: number): unknown => {
        return value;
      })(value["pageSize"]),
      totalCount: ((value: bigint): unknown => {
        return value;
      })(value["totalCount"]),
      viewAllUri: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["viewAllUri"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_MyListPortletView(str: string): MyListPortletView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          items: MyListView[];
          metadata: PortletMetadata;
          page: number;
          pageSize: number;
          totalCount: bigint;
          viewAllUri: string | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "items")) {
      return { success: false };
    }
    const parsed_items = (() => {
      return ((value: unknown): { success: false } | { success: true; value: MyListView[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: MyListView } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  coverUri: string;
                  key: string;
                  myListId: bigint;
                  name: string;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "coverUri")) {
              return { success: false };
            }
            const parsed_coverUri = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["coverUri"]);
            })();
            if (!parsed_coverUri.success) {
              return { success: false };
            }
            if (!hasProp(value, "key")) {
              return { success: false };
            }
            const parsed_key = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["key"]);
            })();
            if (!parsed_key.success) {
              return { success: false };
            }
            if (!hasProp(value, "myListId")) {
              return { success: false };
            }
            const parsed_myListId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                if (typeof value === "number") {
                  return { success: true, value: BigInt(value) };
                }
                if (typeof value === "bigint") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["myListId"]);
            })();
            if (!parsed_myListId.success) {
              return { success: false };
            }
            if (!hasProp(value, "name")) {
              return { success: false };
            }
            const parsed_name = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["name"]);
            })();
            if (!parsed_name.success) {
              return { success: false };
            }
            return { success: true, value: { coverUri: parsed_coverUri.value, key: parsed_key.value, myListId: parsed_myListId.value, name: parsed_name.value } };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["items"]);
    })();
    if (!parsed_items.success) {
      return { success: false };
    }
    if (!hasProp(value, "metadata")) {
      return { success: false };
    }
    const parsed_metadata = (() => {
      return ((value: unknown): { success: false } | { success: true; value: PortletMetadata } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                custom: boolean;
                defaultFilterAllLanguages: boolean;
                embedSortingFiltering: boolean;
                hideTitle: boolean;
                itemCount: number;
                itemLayout: ItemLayout;
                name: string;
                portletId: number;
                portletLayout: PortletLayout;
                subType: string | null;
                title: string;
                type: PortletType;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "custom")) {
            return { success: false };
          }
          const parsed_custom = (() => {
            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
              if (typeof value !== "boolean") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["custom"]);
          })();
          if (!parsed_custom.success) {
            return { success: false };
          }
          if (!hasProp(value, "defaultFilterAllLanguages")) {
            return { success: false };
          }
          const parsed_defaultFilterAllLanguages = (() => {
            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
              if (typeof value !== "boolean") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["defaultFilterAllLanguages"]);
          })();
          if (!parsed_defaultFilterAllLanguages.success) {
            return { success: false };
          }
          if (!hasProp(value, "embedSortingFiltering")) {
            return { success: false };
          }
          const parsed_embedSortingFiltering = (() => {
            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
              if (typeof value !== "boolean") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["embedSortingFiltering"]);
          })();
          if (!parsed_embedSortingFiltering.success) {
            return { success: false };
          }
          if (!hasProp(value, "hideTitle")) {
            return { success: false };
          }
          const parsed_hideTitle = (() => {
            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
              if (typeof value !== "boolean") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["hideTitle"]);
          })();
          if (!parsed_hideTitle.success) {
            return { success: false };
          }
          if (!hasProp(value, "itemCount")) {
            return { success: false };
          }
          const parsed_itemCount = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["itemCount"]);
          })();
          if (!parsed_itemCount.success) {
            return { success: false };
          }
          if (!hasProp(value, "itemLayout")) {
            return { success: false };
          }
          const parsed_itemLayout = (() => {
            return ((value: unknown): { success: false } | { success: true; value: ItemLayout } => {
              return ((value: unknown): { success: false } | { success: true; value: "MINI" | "REGULAR" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "MINI":
                    return { success: true, value: "MINI" };
                  case "REGULAR":
                    return { success: true, value: "REGULAR" };
                  default:
                    return { success: false };
                }
              })(value);
            })(value["itemLayout"]);
          })();
          if (!parsed_itemLayout.success) {
            return { success: false };
          }
          if (!hasProp(value, "name")) {
            return { success: false };
          }
          const parsed_name = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["name"]);
          })();
          if (!parsed_name.success) {
            return { success: false };
          }
          if (!hasProp(value, "portletId")) {
            return { success: false };
          }
          const parsed_portletId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["portletId"]);
          })();
          if (!parsed_portletId.success) {
            return { success: false };
          }
          if (!hasProp(value, "portletLayout")) {
            return { success: false };
          }
          const parsed_portletLayout = (() => {
            return ((value: unknown): { success: false } | { success: true; value: PortletLayout } => {
              return ((value: unknown): { success: false } | { success: true; value: "GRID" | "CAROUSEL" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "GRID":
                    return { success: true, value: "GRID" };
                  case "CAROUSEL":
                    return { success: true, value: "CAROUSEL" };
                  default:
                    return { success: false };
                }
              })(value);
            })(value["portletLayout"]);
          })();
          if (!parsed_portletLayout.success) {
            return { success: false };
          }
          if (!hasProp(value, "subType")) {
            return { success: false };
          }
          const parsed_subType = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["subType"]);
          })();
          if (!parsed_subType.success) {
            return { success: false };
          }
          if (!hasProp(value, "title")) {
            return { success: false };
          }
          const parsed_title = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["title"]);
          })();
          if (!parsed_title.success) {
            return { success: false };
          }
          if (!hasProp(value, "type")) {
            return { success: false };
          }
          const parsed_type = (() => {
            return ((value: unknown): { success: false } | { success: true; value: PortletType } => {
              return ((
                value: unknown,
              ):
                | { success: false }
                | {
                    success: true;
                    value:
                      | "IMAGETEXT"
                      | "SUMMARYLIST"
                      | "TRENDINGCHANNEL"
                      | "FEATUREDCHANNEL"
                      | "FOLLOWINGCHANNEL"
                      | "MYLIST"
                      | "PRIMARYTOPICCHANNEL"
                      | "CHANNELLIST"
                      | "ANNOTATIONLIST"
                      | "LGXPLIST"
                      | "SKETCHNOTESLGXP"
                      | "ACTIONABLELIST"
                      | "ACTIONABLELISTCUSTOM"
                      | "MYACTIONABLES"
                      | "FREESUMMARY"
                      | "SUMMARYLISTCUSTOM"
                      | "CUSTOMPAGELIST";
                  } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "IMAGETEXT":
                    return { success: true, value: "IMAGETEXT" };
                  case "SUMMARYLIST":
                    return { success: true, value: "SUMMARYLIST" };
                  case "TRENDINGCHANNEL":
                    return { success: true, value: "TRENDINGCHANNEL" };
                  case "FEATUREDCHANNEL":
                    return { success: true, value: "FEATUREDCHANNEL" };
                  case "FOLLOWINGCHANNEL":
                    return { success: true, value: "FOLLOWINGCHANNEL" };
                  case "MYLIST":
                    return { success: true, value: "MYLIST" };
                  case "PRIMARYTOPICCHANNEL":
                    return { success: true, value: "PRIMARYTOPICCHANNEL" };
                  case "CHANNELLIST":
                    return { success: true, value: "CHANNELLIST" };
                  case "ANNOTATIONLIST":
                    return { success: true, value: "ANNOTATIONLIST" };
                  case "LGXPLIST":
                    return { success: true, value: "LGXPLIST" };
                  case "SKETCHNOTESLGXP":
                    return { success: true, value: "SKETCHNOTESLGXP" };
                  case "ACTIONABLELIST":
                    return { success: true, value: "ACTIONABLELIST" };
                  case "ACTIONABLELISTCUSTOM":
                    return { success: true, value: "ACTIONABLELISTCUSTOM" };
                  case "MYACTIONABLES":
                    return { success: true, value: "MYACTIONABLES" };
                  case "FREESUMMARY":
                    return { success: true, value: "FREESUMMARY" };
                  case "SUMMARYLISTCUSTOM":
                    return { success: true, value: "SUMMARYLISTCUSTOM" };
                  case "CUSTOMPAGELIST":
                    return { success: true, value: "CUSTOMPAGELIST" };
                  default:
                    return { success: false };
                }
              })(value);
            })(value["type"]);
          })();
          if (!parsed_type.success) {
            return { success: false };
          }
          return {
            success: true,
            value: {
              custom: parsed_custom.value,
              defaultFilterAllLanguages: parsed_defaultFilterAllLanguages.value,
              embedSortingFiltering: parsed_embedSortingFiltering.value,
              hideTitle: parsed_hideTitle.value,
              itemCount: parsed_itemCount.value,
              itemLayout: parsed_itemLayout.value,
              name: parsed_name.value,
              portletId: parsed_portletId.value,
              portletLayout: parsed_portletLayout.value,
              subType: parsed_subType.value,
              title: parsed_title.value,
              type: parsed_type.value,
            },
          };
        })(value);
      })(value["metadata"]);
    })();
    if (!parsed_metadata.success) {
      return { success: false };
    }
    if (!hasProp(value, "page")) {
      return { success: false };
    }
    const parsed_page = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["page"]);
    })();
    if (!parsed_page.success) {
      return { success: false };
    }
    if (!hasProp(value, "pageSize")) {
      return { success: false };
    }
    const parsed_pageSize = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["pageSize"]);
    })();
    if (!parsed_pageSize.success) {
      return { success: false };
    }
    if (!hasProp(value, "totalCount")) {
      return { success: false };
    }
    const parsed_totalCount = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["totalCount"]);
    })();
    if (!parsed_totalCount.success) {
      return { success: false };
    }
    if (!hasProp(value, "viewAllUri")) {
      return { success: false };
    }
    const parsed_viewAllUri = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["viewAllUri"]);
    })();
    if (!parsed_viewAllUri.success) {
      return { success: false };
    }
    return { success: true, value: { items: parsed_items.value, metadata: parsed_metadata.value, page: parsed_page.value, pageSize: parsed_pageSize.value, totalCount: parsed_totalCount.value, viewAllUri: parsed_viewAllUri.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// MyListView
export function serialize_MyListView(obj: MyListView): string {
  const serialized = ((value: { coverUri: string; key: string; myListId: bigint; name: string }): unknown => {
    return {
      coverUri: ((value: string): unknown => {
        return value;
      })(value["coverUri"]),
      key: ((value: string): unknown => {
        return value;
      })(value["key"]),
      myListId: ((value: bigint): unknown => {
        return value;
      })(value["myListId"]),
      name: ((value: string): unknown => {
        return value;
      })(value["name"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_MyListView(str: string): MyListView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          coverUri: string;
          key: string;
          myListId: bigint;
          name: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "coverUri")) {
      return { success: false };
    }
    const parsed_coverUri = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["coverUri"]);
    })();
    if (!parsed_coverUri.success) {
      return { success: false };
    }
    if (!hasProp(value, "key")) {
      return { success: false };
    }
    const parsed_key = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["key"]);
    })();
    if (!parsed_key.success) {
      return { success: false };
    }
    if (!hasProp(value, "myListId")) {
      return { success: false };
    }
    const parsed_myListId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["myListId"]);
    })();
    if (!parsed_myListId.success) {
      return { success: false };
    }
    if (!hasProp(value, "name")) {
      return { success: false };
    }
    const parsed_name = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["name"]);
    })();
    if (!parsed_name.success) {
      return { success: false };
    }
    return { success: true, value: { coverUri: parsed_coverUri.value, key: parsed_key.value, myListId: parsed_myListId.value, name: parsed_name.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// NavigationDirection
export function serialize_NavigationDirection(obj: NavigationDirection): string {
  const serialized = ((value: "NEXT" | "PREVIOUS"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_NavigationDirection(str: string): NavigationDirection | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "NEXT" | "PREVIOUS" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "NEXT":
        return { success: true, value: "NEXT" };
      case "PREVIOUS":
        return { success: true, value: "PREVIOUS" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// Oauth2Flow
export function serialize_Oauth2Flow(obj: Oauth2Flow): string {
  const serialized = ((value: "BASIC" | "TWO_LEGGED_OAUTH"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_Oauth2Flow(str: string): Oauth2Flow | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "BASIC" | "TWO_LEGGED_OAUTH" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "BASIC":
        return { success: true, value: "BASIC" };
      case "TWO_LEGGED_OAUTH":
        return { success: true, value: "TWO_LEGGED_OAUTH" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// PageInfo
export function serialize_PageInfo(obj: PageInfo): string {
  const serialized = ((value: { page: number; pageSize: number; totalCount: number }): unknown => {
    return {
      page: ((value: number): unknown => {
        return value;
      })(value["page"]),
      pageSize: ((value: number): unknown => {
        return value;
      })(value["pageSize"]),
      totalCount: ((value: number): unknown => {
        return value;
      })(value["totalCount"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_PageInfo(str: string): PageInfo | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          page: number;
          pageSize: number;
          totalCount: number;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "page")) {
      return { success: false };
    }
    const parsed_page = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["page"]);
    })();
    if (!parsed_page.success) {
      return { success: false };
    }
    if (!hasProp(value, "pageSize")) {
      return { success: false };
    }
    const parsed_pageSize = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["pageSize"]);
    })();
    if (!parsed_pageSize.success) {
      return { success: false };
    }
    if (!hasProp(value, "totalCount")) {
      return { success: false };
    }
    const parsed_totalCount = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["totalCount"]);
    })();
    if (!parsed_totalCount.success) {
      return { success: false };
    }
    return { success: true, value: { page: parsed_page.value, pageSize: parsed_pageSize.value, totalCount: parsed_totalCount.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// PagedActionableListView
export function serialize_PagedActionableListView(obj: PagedActionableListView): string {
  const serialized = ((value: { items: ActionableListView[]; page: number; pageSize: number; totalCount: bigint }): unknown => {
    return {
      items: ((value: ActionableListView[]): unknown => {
        return value.map(
          (value: {
            actionableId: bigint;
            activatedAt: DateTime<true> | null;
            active: boolean;
            coverUri: string;
            description: string;
            language: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr";
            modifiedAt: DateTime<true>;
            name: string;
            title: string;
          }): unknown => {
            return {
              actionableId: ((value: bigint): unknown => {
                return value;
              })(value["actionableId"]),
              activatedAt: ((value: DateTime<true> | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value.toMillis();
              })(value["activatedAt"]),
              active: ((value: boolean): unknown => {
                return value;
              })(value["active"]),
              coverUri: ((value: string): unknown => {
                return value;
              })(value["coverUri"]),
              description: ((value: string): unknown => {
                return value;
              })(value["description"]),
              language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                return value;
              })(value["language"]),
              modifiedAt: ((value: DateTime<true>): unknown => {
                return value.toMillis();
              })(value["modifiedAt"]),
              name: ((value: string): unknown => {
                return value;
              })(value["name"]),
              title: ((value: string): unknown => {
                return value;
              })(value["title"]),
            };
          },
        );
      })(value["items"]),
      page: ((value: number): unknown => {
        return value;
      })(value["page"]),
      pageSize: ((value: number): unknown => {
        return value;
      })(value["pageSize"]),
      totalCount: ((value: bigint): unknown => {
        return value;
      })(value["totalCount"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_PagedActionableListView(str: string): PagedActionableListView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          items: ActionableListView[];
          page: number;
          pageSize: number;
          totalCount: bigint;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "items")) {
      return { success: false };
    }
    const parsed_items = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ActionableListView[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: ActionableListView } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  actionableId: bigint;
                  activatedAt: DateTime<true> | null;
                  active: boolean;
                  coverUri: string;
                  description: string;
                  language: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr";
                  modifiedAt: DateTime<true>;
                  name: string;
                  title: string;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "actionableId")) {
              return { success: false };
            }
            const parsed_actionableId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                if (typeof value === "number") {
                  return { success: true, value: BigInt(value) };
                }
                if (typeof value === "bigint") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["actionableId"]);
            })();
            if (!parsed_actionableId.success) {
              return { success: false };
            }
            if (!hasProp(value, "activatedAt")) {
              return { success: false };
            }
            const parsed_activatedAt = (() => {
              return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "number") {
                  return { success: false };
                }
                const parsed = DateTime.fromMillis(value);
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: parsed };
              })(value["activatedAt"]);
            })();
            if (!parsed_activatedAt.success) {
              return { success: false };
            }
            if (!hasProp(value, "active")) {
              return { success: false };
            }
            const parsed_active = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["active"]);
            })();
            if (!parsed_active.success) {
              return { success: false };
            }
            if (!hasProp(value, "coverUri")) {
              return { success: false };
            }
            const parsed_coverUri = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["coverUri"]);
            })();
            if (!parsed_coverUri.success) {
              return { success: false };
            }
            if (!hasProp(value, "description")) {
              return { success: false };
            }
            const parsed_description = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["description"]);
            })();
            if (!parsed_description.success) {
              return { success: false };
            }
            if (!hasProp(value, "language")) {
              return { success: false };
            }
            const parsed_language = (() => {
              return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "en":
                    return { success: true, value: "en" };
                  case "de":
                    return { success: true, value: "de" };
                  case "es":
                    return { success: true, value: "es" };
                  case "ru":
                    return { success: true, value: "ru" };
                  case "zh":
                    return { success: true, value: "zh" };
                  case "pt":
                    return { success: true, value: "pt" };
                  case "fr":
                    return { success: true, value: "fr" };
                  default:
                    return { success: false };
                }
              })(value["language"]);
            })();
            if (!parsed_language.success) {
              return { success: false };
            }
            if (!hasProp(value, "modifiedAt")) {
              return { success: false };
            }
            const parsed_modifiedAt = (() => {
              return ((value: unknown): { success: false } | { success: true; value: DateTime<true> } => {
                if (typeof value !== "number") {
                  return { success: false };
                }
                const parsed = DateTime.fromMillis(value);
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: parsed };
              })(value["modifiedAt"]);
            })();
            if (!parsed_modifiedAt.success) {
              return { success: false };
            }
            if (!hasProp(value, "name")) {
              return { success: false };
            }
            const parsed_name = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["name"]);
            })();
            if (!parsed_name.success) {
              return { success: false };
            }
            if (!hasProp(value, "title")) {
              return { success: false };
            }
            const parsed_title = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["title"]);
            })();
            if (!parsed_title.success) {
              return { success: false };
            }
            return {
              success: true,
              value: {
                actionableId: parsed_actionableId.value,
                activatedAt: parsed_activatedAt.value,
                active: parsed_active.value,
                coverUri: parsed_coverUri.value,
                description: parsed_description.value,
                language: parsed_language.value,
                modifiedAt: parsed_modifiedAt.value,
                name: parsed_name.value,
                title: parsed_title.value,
              },
            };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["items"]);
    })();
    if (!parsed_items.success) {
      return { success: false };
    }
    if (!hasProp(value, "page")) {
      return { success: false };
    }
    const parsed_page = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["page"]);
    })();
    if (!parsed_page.success) {
      return { success: false };
    }
    if (!hasProp(value, "pageSize")) {
      return { success: false };
    }
    const parsed_pageSize = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["pageSize"]);
    })();
    if (!parsed_pageSize.success) {
      return { success: false };
    }
    if (!hasProp(value, "totalCount")) {
      return { success: false };
    }
    const parsed_totalCount = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["totalCount"]);
    })();
    if (!parsed_totalCount.success) {
      return { success: false };
    }
    return { success: true, value: { items: parsed_items.value, page: parsed_page.value, pageSize: parsed_pageSize.value, totalCount: parsed_totalCount.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// PagedAuthorAdminListView
export function serialize_PagedAuthorAdminListView(obj: PagedAuthorAdminListView): string {
  const serialized = ((value: { items: AuthorAdminListView[]; page: number; pageSize: number; totalCount: bigint }): unknown => {
    return {
      items: ((value: AuthorAdminListView[]): unknown => {
        return value.map((value: { authorId: bigint; channels: string[]; firstName: string; gender: Gender; lastName: string | null; middleName: string | null }): unknown => {
          return {
            authorId: ((value: bigint): unknown => {
              return value;
            })(value["authorId"]),
            channels: ((value: string[]): unknown => {
              return value.map((value: string): unknown => {
                return value;
              });
            })(value["channels"]),
            firstName: ((value: string): unknown => {
              return value;
            })(value["firstName"]),
            gender: ((value: "FEMALE" | "MALE" | "UNKNOWN" | "CUSTOM"): unknown => {
              return value;
            })(value["gender"]),
            lastName: ((value: string | null): unknown => {
              if (value === null) {
                return null;
              }
              return value;
            })(value["lastName"]),
            middleName: ((value: string | null): unknown => {
              if (value === null) {
                return null;
              }
              return value;
            })(value["middleName"]),
          };
        });
      })(value["items"]),
      page: ((value: number): unknown => {
        return value;
      })(value["page"]),
      pageSize: ((value: number): unknown => {
        return value;
      })(value["pageSize"]),
      totalCount: ((value: bigint): unknown => {
        return value;
      })(value["totalCount"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_PagedAuthorAdminListView(str: string): PagedAuthorAdminListView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          items: AuthorAdminListView[];
          page: number;
          pageSize: number;
          totalCount: bigint;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "items")) {
      return { success: false };
    }
    const parsed_items = (() => {
      return ((value: unknown): { success: false } | { success: true; value: AuthorAdminListView[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: AuthorAdminListView } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  authorId: bigint;
                  channels: string[];
                  firstName: string;
                  gender: Gender;
                  lastName: string | null;
                  middleName: string | null;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "authorId")) {
              return { success: false };
            }
            const parsed_authorId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                if (typeof value === "number") {
                  return { success: true, value: BigInt(value) };
                }
                if (typeof value === "bigint") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["authorId"]);
            })();
            if (!parsed_authorId.success) {
              return { success: false };
            }
            if (!hasProp(value, "channels")) {
              return { success: false };
            }
            const parsed_channels = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string[] } => {
                if (!isArray(value)) {
                  return { success: false };
                }
                const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  return { success: true, value: value };
                });
                if (parsed.find((value) => !value.success) !== undefined) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: parsed.map((entry) => {
                    assert(entry.success == true);
                    return entry.value;
                  }),
                };
              })(value["channels"]);
            })();
            if (!parsed_channels.success) {
              return { success: false };
            }
            if (!hasProp(value, "firstName")) {
              return { success: false };
            }
            const parsed_firstName = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["firstName"]);
            })();
            if (!parsed_firstName.success) {
              return { success: false };
            }
            if (!hasProp(value, "gender")) {
              return { success: false };
            }
            const parsed_gender = (() => {
              return ((value: unknown): { success: false } | { success: true; value: Gender } => {
                return ((value: unknown): { success: false } | { success: true; value: "FEMALE" | "MALE" | "UNKNOWN" | "CUSTOM" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "FEMALE":
                      return { success: true, value: "FEMALE" };
                    case "MALE":
                      return { success: true, value: "MALE" };
                    case "UNKNOWN":
                      return { success: true, value: "UNKNOWN" };
                    case "CUSTOM":
                      return { success: true, value: "CUSTOM" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["gender"]);
            })();
            if (!parsed_gender.success) {
              return { success: false };
            }
            if (!hasProp(value, "lastName")) {
              return { success: false };
            }
            const parsed_lastName = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["lastName"]);
            })();
            if (!parsed_lastName.success) {
              return { success: false };
            }
            if (!hasProp(value, "middleName")) {
              return { success: false };
            }
            const parsed_middleName = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["middleName"]);
            })();
            if (!parsed_middleName.success) {
              return { success: false };
            }
            return {
              success: true,
              value: { authorId: parsed_authorId.value, channels: parsed_channels.value, firstName: parsed_firstName.value, gender: parsed_gender.value, lastName: parsed_lastName.value, middleName: parsed_middleName.value },
            };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["items"]);
    })();
    if (!parsed_items.success) {
      return { success: false };
    }
    if (!hasProp(value, "page")) {
      return { success: false };
    }
    const parsed_page = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["page"]);
    })();
    if (!parsed_page.success) {
      return { success: false };
    }
    if (!hasProp(value, "pageSize")) {
      return { success: false };
    }
    const parsed_pageSize = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["pageSize"]);
    })();
    if (!parsed_pageSize.success) {
      return { success: false };
    }
    if (!hasProp(value, "totalCount")) {
      return { success: false };
    }
    const parsed_totalCount = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["totalCount"]);
    })();
    if (!parsed_totalCount.success) {
      return { success: false };
    }
    return { success: true, value: { items: parsed_items.value, page: parsed_page.value, pageSize: parsed_pageSize.value, totalCount: parsed_totalCount.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// PagedConnectorCompletionExecutionLogListView
export function serialize_PagedConnectorCompletionExecutionLogListView(obj: PagedConnectorCompletionExecutionLogListView): string {
  const serialized = ((value: { items: ConnectorCompletionExecutionLogListView[]; page: number; pageSize: number; totalCount: bigint }): unknown => {
    return {
      items: ((value: ConnectorCompletionExecutionLogListView[]): unknown => {
        return value.map(
          (value: { completedAt: DateTime<true> | null; connectorExecutionLogId: bigint; contentId: string; method: string; request: string; response: string; statusCode: number; syncedAt: DateTime<true>; userId: string }): unknown => {
            return {
              completedAt: ((value: DateTime<true> | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value.toISO();
              })(value["completedAt"]),
              connectorExecutionLogId: ((value: bigint): unknown => {
                return value;
              })(value["connectorExecutionLogId"]),
              contentId: ((value: string): unknown => {
                return value;
              })(value["contentId"]),
              method: ((value: string): unknown => {
                return value;
              })(value["method"]),
              request: ((value: string): unknown => {
                return value;
              })(value["request"]),
              response: ((value: string): unknown => {
                return value;
              })(value["response"]),
              statusCode: ((value: number): unknown => {
                return value;
              })(value["statusCode"]),
              syncedAt: ((value: DateTime<true>): unknown => {
                return value.toISO();
              })(value["syncedAt"]),
              userId: ((value: string): unknown => {
                return value;
              })(value["userId"]),
            };
          },
        );
      })(value["items"]),
      page: ((value: number): unknown => {
        return value;
      })(value["page"]),
      pageSize: ((value: number): unknown => {
        return value;
      })(value["pageSize"]),
      totalCount: ((value: bigint): unknown => {
        return value;
      })(value["totalCount"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_PagedConnectorCompletionExecutionLogListView(str: string): PagedConnectorCompletionExecutionLogListView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          items: ConnectorCompletionExecutionLogListView[];
          page: number;
          pageSize: number;
          totalCount: bigint;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "items")) {
      return { success: false };
    }
    const parsed_items = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ConnectorCompletionExecutionLogListView[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: ConnectorCompletionExecutionLogListView } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  completedAt: DateTime<true> | null;
                  connectorExecutionLogId: bigint;
                  contentId: string;
                  method: string;
                  request: string;
                  response: string;
                  statusCode: number;
                  syncedAt: DateTime<true>;
                  userId: string;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "completedAt")) {
              return { success: false };
            }
            const parsed_completedAt = (() => {
              return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                const parsed = DateTime.fromISO(value.toUpperCase(), { setZone: true });
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: parsed };
              })(value["completedAt"]);
            })();
            if (!parsed_completedAt.success) {
              return { success: false };
            }
            if (!hasProp(value, "connectorExecutionLogId")) {
              return { success: false };
            }
            const parsed_connectorExecutionLogId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                if (typeof value === "number") {
                  return { success: true, value: BigInt(value) };
                }
                if (typeof value === "bigint") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["connectorExecutionLogId"]);
            })();
            if (!parsed_connectorExecutionLogId.success) {
              return { success: false };
            }
            if (!hasProp(value, "contentId")) {
              return { success: false };
            }
            const parsed_contentId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["contentId"]);
            })();
            if (!parsed_contentId.success) {
              return { success: false };
            }
            if (!hasProp(value, "method")) {
              return { success: false };
            }
            const parsed_method = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["method"]);
            })();
            if (!parsed_method.success) {
              return { success: false };
            }
            if (!hasProp(value, "request")) {
              return { success: false };
            }
            const parsed_request = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["request"]);
            })();
            if (!parsed_request.success) {
              return { success: false };
            }
            if (!hasProp(value, "response")) {
              return { success: false };
            }
            const parsed_response = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["response"]);
            })();
            if (!parsed_response.success) {
              return { success: false };
            }
            if (!hasProp(value, "statusCode")) {
              return { success: false };
            }
            const parsed_statusCode = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["statusCode"]);
            })();
            if (!parsed_statusCode.success) {
              return { success: false };
            }
            if (!hasProp(value, "syncedAt")) {
              return { success: false };
            }
            const parsed_syncedAt = (() => {
              return ((value: unknown): { success: false } | { success: true; value: DateTime<true> } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                const parsed = DateTime.fromISO(value.toUpperCase(), { setZone: true });
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: parsed };
              })(value["syncedAt"]);
            })();
            if (!parsed_syncedAt.success) {
              return { success: false };
            }
            if (!hasProp(value, "userId")) {
              return { success: false };
            }
            const parsed_userId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["userId"]);
            })();
            if (!parsed_userId.success) {
              return { success: false };
            }
            return {
              success: true,
              value: {
                completedAt: parsed_completedAt.value,
                connectorExecutionLogId: parsed_connectorExecutionLogId.value,
                contentId: parsed_contentId.value,
                method: parsed_method.value,
                request: parsed_request.value,
                response: parsed_response.value,
                statusCode: parsed_statusCode.value,
                syncedAt: parsed_syncedAt.value,
                userId: parsed_userId.value,
              },
            };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["items"]);
    })();
    if (!parsed_items.success) {
      return { success: false };
    }
    if (!hasProp(value, "page")) {
      return { success: false };
    }
    const parsed_page = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["page"]);
    })();
    if (!parsed_page.success) {
      return { success: false };
    }
    if (!hasProp(value, "pageSize")) {
      return { success: false };
    }
    const parsed_pageSize = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["pageSize"]);
    })();
    if (!parsed_pageSize.success) {
      return { success: false };
    }
    if (!hasProp(value, "totalCount")) {
      return { success: false };
    }
    const parsed_totalCount = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["totalCount"]);
    })();
    if (!parsed_totalCount.success) {
      return { success: false };
    }
    return { success: true, value: { items: parsed_items.value, page: parsed_page.value, pageSize: parsed_pageSize.value, totalCount: parsed_totalCount.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// PagedConnectorContentExecutionListView
export function serialize_PagedConnectorContentExecutionListView(obj: PagedConnectorContentExecutionListView): string {
  const serialized = ((value: { items: ConnectorContentExecutionListView[]; page: number; pageSize: number; totalCount: bigint }): unknown => {
    return {
      items: ((value: ConnectorContentExecutionListView[]): unknown => {
        return value.map(
          (value: { connectorExecutionId: bigint; connectorName: string; description: string; detail: ConnectorContentExecutionLogDetailView; provider: ConnectorProviderMetadata; syncedAt: DateTime<true> | null }): unknown => {
            return {
              connectorExecutionId: ((value: bigint): unknown => {
                return value;
              })(value["connectorExecutionId"]),
              connectorName: ((value: string): unknown => {
                return value;
              })(value["connectorName"]),
              description: ((value: string): unknown => {
                return value;
              })(value["description"]),
              detail: ((value: { failedExportedItems: number; successfullyExportedItems: number }): unknown => {
                return {
                  failedExportedItems: ((value: number): unknown => {
                    return value;
                  })(value["failedExportedItems"]),
                  successfullyExportedItems: ((value: number): unknown => {
                    return value;
                  })(value["successfullyExportedItems"]),
                };
              })(value["detail"]),
              provider: ((value: { imageUri: string; name: string; type: ConnectorType }): unknown => {
                return {
                  imageUri: ((value: string): unknown => {
                    return value;
                  })(value["imageUri"]),
                  name: ((value: string): unknown => {
                    return value;
                  })(value["name"]),
                  type: ((value: "SUCCESS_FACTORS" | "DEGREED" | "DOCEBO" | "CSOD" | "SUM_TOTAL" | "EDCAST" | "WORKDAY"): unknown => {
                    return value;
                  })(value["type"]),
                };
              })(value["provider"]),
              syncedAt: ((value: DateTime<true> | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value.toISO();
              })(value["syncedAt"]),
            };
          },
        );
      })(value["items"]),
      page: ((value: number): unknown => {
        return value;
      })(value["page"]),
      pageSize: ((value: number): unknown => {
        return value;
      })(value["pageSize"]),
      totalCount: ((value: bigint): unknown => {
        return value;
      })(value["totalCount"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_PagedConnectorContentExecutionListView(str: string): PagedConnectorContentExecutionListView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          items: ConnectorContentExecutionListView[];
          page: number;
          pageSize: number;
          totalCount: bigint;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "items")) {
      return { success: false };
    }
    const parsed_items = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ConnectorContentExecutionListView[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: ConnectorContentExecutionListView } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  connectorExecutionId: bigint;
                  connectorName: string;
                  description: string;
                  detail: ConnectorContentExecutionLogDetailView;
                  provider: ConnectorProviderMetadata;
                  syncedAt: DateTime<true> | null;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "connectorExecutionId")) {
              return { success: false };
            }
            const parsed_connectorExecutionId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                if (typeof value === "number") {
                  return { success: true, value: BigInt(value) };
                }
                if (typeof value === "bigint") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["connectorExecutionId"]);
            })();
            if (!parsed_connectorExecutionId.success) {
              return { success: false };
            }
            if (!hasProp(value, "connectorName")) {
              return { success: false };
            }
            const parsed_connectorName = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["connectorName"]);
            })();
            if (!parsed_connectorName.success) {
              return { success: false };
            }
            if (!hasProp(value, "description")) {
              return { success: false };
            }
            const parsed_description = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["description"]);
            })();
            if (!parsed_description.success) {
              return { success: false };
            }
            if (!hasProp(value, "detail")) {
              return { success: false };
            }
            const parsed_detail = (() => {
              return ((value: unknown): { success: false } | { success: true; value: ConnectorContentExecutionLogDetailView } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        failedExportedItems: number;
                        successfullyExportedItems: number;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "failedExportedItems")) {
                    return { success: false };
                  }
                  const parsed_failedExportedItems = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number } => {
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["failedExportedItems"]);
                  })();
                  if (!parsed_failedExportedItems.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "successfullyExportedItems")) {
                    return { success: false };
                  }
                  const parsed_successfullyExportedItems = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number } => {
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["successfullyExportedItems"]);
                  })();
                  if (!parsed_successfullyExportedItems.success) {
                    return { success: false };
                  }
                  return { success: true, value: { failedExportedItems: parsed_failedExportedItems.value, successfullyExportedItems: parsed_successfullyExportedItems.value } };
                })(value);
              })(value["detail"]);
            })();
            if (!parsed_detail.success) {
              return { success: false };
            }
            if (!hasProp(value, "provider")) {
              return { success: false };
            }
            const parsed_provider = (() => {
              return ((value: unknown): { success: false } | { success: true; value: ConnectorProviderMetadata } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        imageUri: string;
                        name: string;
                        type: ConnectorType;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "imageUri")) {
                    return { success: false };
                  }
                  const parsed_imageUri = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      try {
                        new URL(value);
                      } catch (ex) {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["imageUri"]);
                  })();
                  if (!parsed_imageUri.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "name")) {
                    return { success: false };
                  }
                  const parsed_name = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["name"]);
                  })();
                  if (!parsed_name.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "type")) {
                    return { success: false };
                  }
                  const parsed_type = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: ConnectorType } => {
                      return ((value: unknown): { success: false } | { success: true; value: "SUCCESS_FACTORS" | "DEGREED" | "DOCEBO" | "CSOD" | "SUM_TOTAL" | "EDCAST" | "WORKDAY" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "SUCCESS_FACTORS":
                            return { success: true, value: "SUCCESS_FACTORS" };
                          case "DEGREED":
                            return { success: true, value: "DEGREED" };
                          case "DOCEBO":
                            return { success: true, value: "DOCEBO" };
                          case "CSOD":
                            return { success: true, value: "CSOD" };
                          case "SUM_TOTAL":
                            return { success: true, value: "SUM_TOTAL" };
                          case "EDCAST":
                            return { success: true, value: "EDCAST" };
                          case "WORKDAY":
                            return { success: true, value: "WORKDAY" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["type"]);
                  })();
                  if (!parsed_type.success) {
                    return { success: false };
                  }
                  return { success: true, value: { imageUri: parsed_imageUri.value, name: parsed_name.value, type: parsed_type.value } };
                })(value);
              })(value["provider"]);
            })();
            if (!parsed_provider.success) {
              return { success: false };
            }
            if (!hasProp(value, "syncedAt")) {
              return { success: false };
            }
            const parsed_syncedAt = (() => {
              return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                const parsed = DateTime.fromISO(value.toUpperCase(), { setZone: true });
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: parsed };
              })(value["syncedAt"]);
            })();
            if (!parsed_syncedAt.success) {
              return { success: false };
            }
            return {
              success: true,
              value: {
                connectorExecutionId: parsed_connectorExecutionId.value,
                connectorName: parsed_connectorName.value,
                description: parsed_description.value,
                detail: parsed_detail.value,
                provider: parsed_provider.value,
                syncedAt: parsed_syncedAt.value,
              },
            };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["items"]);
    })();
    if (!parsed_items.success) {
      return { success: false };
    }
    if (!hasProp(value, "page")) {
      return { success: false };
    }
    const parsed_page = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["page"]);
    })();
    if (!parsed_page.success) {
      return { success: false };
    }
    if (!hasProp(value, "pageSize")) {
      return { success: false };
    }
    const parsed_pageSize = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["pageSize"]);
    })();
    if (!parsed_pageSize.success) {
      return { success: false };
    }
    if (!hasProp(value, "totalCount")) {
      return { success: false };
    }
    const parsed_totalCount = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["totalCount"]);
    })();
    if (!parsed_totalCount.success) {
      return { success: false };
    }
    return { success: true, value: { items: parsed_items.value, page: parsed_page.value, pageSize: parsed_pageSize.value, totalCount: parsed_totalCount.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// PagedConnectorContentExecutionLogListView
export function serialize_PagedConnectorContentExecutionLogListView(obj: PagedConnectorContentExecutionLogListView): string {
  const serialized = ((value: { items: ConnectorContentExecutionLogListView[]; page: number; pageSize: number; totalCount: bigint }): unknown => {
    return {
      items: ((value: ConnectorContentExecutionLogListView[]): unknown => {
        return value.map((value: { connectorExecutionLogId: bigint; contentId: string; method: string; request: string; response: string; statusCode: number; syncedAt: DateTime<true> }): unknown => {
          return {
            connectorExecutionLogId: ((value: bigint): unknown => {
              return value;
            })(value["connectorExecutionLogId"]),
            contentId: ((value: string): unknown => {
              return value;
            })(value["contentId"]),
            method: ((value: string): unknown => {
              return value;
            })(value["method"]),
            request: ((value: string): unknown => {
              return value;
            })(value["request"]),
            response: ((value: string): unknown => {
              return value;
            })(value["response"]),
            statusCode: ((value: number): unknown => {
              return value;
            })(value["statusCode"]),
            syncedAt: ((value: DateTime<true>): unknown => {
              return value.toISO();
            })(value["syncedAt"]),
          };
        });
      })(value["items"]),
      page: ((value: number): unknown => {
        return value;
      })(value["page"]),
      pageSize: ((value: number): unknown => {
        return value;
      })(value["pageSize"]),
      totalCount: ((value: bigint): unknown => {
        return value;
      })(value["totalCount"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_PagedConnectorContentExecutionLogListView(str: string): PagedConnectorContentExecutionLogListView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          items: ConnectorContentExecutionLogListView[];
          page: number;
          pageSize: number;
          totalCount: bigint;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "items")) {
      return { success: false };
    }
    const parsed_items = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ConnectorContentExecutionLogListView[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: ConnectorContentExecutionLogListView } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  connectorExecutionLogId: bigint;
                  contentId: string;
                  method: string;
                  request: string;
                  response: string;
                  statusCode: number;
                  syncedAt: DateTime<true>;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "connectorExecutionLogId")) {
              return { success: false };
            }
            const parsed_connectorExecutionLogId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                if (typeof value === "number") {
                  return { success: true, value: BigInt(value) };
                }
                if (typeof value === "bigint") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["connectorExecutionLogId"]);
            })();
            if (!parsed_connectorExecutionLogId.success) {
              return { success: false };
            }
            if (!hasProp(value, "contentId")) {
              return { success: false };
            }
            const parsed_contentId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["contentId"]);
            })();
            if (!parsed_contentId.success) {
              return { success: false };
            }
            if (!hasProp(value, "method")) {
              return { success: false };
            }
            const parsed_method = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["method"]);
            })();
            if (!parsed_method.success) {
              return { success: false };
            }
            if (!hasProp(value, "request")) {
              return { success: false };
            }
            const parsed_request = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["request"]);
            })();
            if (!parsed_request.success) {
              return { success: false };
            }
            if (!hasProp(value, "response")) {
              return { success: false };
            }
            const parsed_response = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["response"]);
            })();
            if (!parsed_response.success) {
              return { success: false };
            }
            if (!hasProp(value, "statusCode")) {
              return { success: false };
            }
            const parsed_statusCode = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["statusCode"]);
            })();
            if (!parsed_statusCode.success) {
              return { success: false };
            }
            if (!hasProp(value, "syncedAt")) {
              return { success: false };
            }
            const parsed_syncedAt = (() => {
              return ((value: unknown): { success: false } | { success: true; value: DateTime<true> } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                const parsed = DateTime.fromISO(value.toUpperCase(), { setZone: true });
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: parsed };
              })(value["syncedAt"]);
            })();
            if (!parsed_syncedAt.success) {
              return { success: false };
            }
            return {
              success: true,
              value: {
                connectorExecutionLogId: parsed_connectorExecutionLogId.value,
                contentId: parsed_contentId.value,
                method: parsed_method.value,
                request: parsed_request.value,
                response: parsed_response.value,
                statusCode: parsed_statusCode.value,
                syncedAt: parsed_syncedAt.value,
              },
            };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["items"]);
    })();
    if (!parsed_items.success) {
      return { success: false };
    }
    if (!hasProp(value, "page")) {
      return { success: false };
    }
    const parsed_page = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["page"]);
    })();
    if (!parsed_page.success) {
      return { success: false };
    }
    if (!hasProp(value, "pageSize")) {
      return { success: false };
    }
    const parsed_pageSize = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["pageSize"]);
    })();
    if (!parsed_pageSize.success) {
      return { success: false };
    }
    if (!hasProp(value, "totalCount")) {
      return { success: false };
    }
    const parsed_totalCount = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["totalCount"]);
    })();
    if (!parsed_totalCount.success) {
      return { success: false };
    }
    return { success: true, value: { items: parsed_items.value, page: parsed_page.value, pageSize: parsed_pageSize.value, totalCount: parsed_totalCount.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// Person
export function serialize_Person(obj: Person): string {
  const serialized = ((value: { firstName: string; gender: Gender; lastName: string | null; middleName: string | null }): unknown => {
    return {
      firstName: ((value: string): unknown => {
        return value;
      })(value["firstName"]),
      gender: ((value: "FEMALE" | "MALE" | "UNKNOWN" | "CUSTOM"): unknown => {
        return value;
      })(value["gender"]),
      lastName: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["lastName"]),
      middleName: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["middleName"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_Person(str: string): Person | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          firstName: string;
          gender: Gender;
          lastName: string | null;
          middleName: string | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "firstName")) {
      return { success: false };
    }
    const parsed_firstName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["firstName"]);
    })();
    if (!parsed_firstName.success) {
      return { success: false };
    }
    if (!hasProp(value, "gender")) {
      return { success: false };
    }
    const parsed_gender = (() => {
      return ((value: unknown): { success: false } | { success: true; value: Gender } => {
        return ((value: unknown): { success: false } | { success: true; value: "FEMALE" | "MALE" | "UNKNOWN" | "CUSTOM" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "FEMALE":
              return { success: true, value: "FEMALE" };
            case "MALE":
              return { success: true, value: "MALE" };
            case "UNKNOWN":
              return { success: true, value: "UNKNOWN" };
            case "CUSTOM":
              return { success: true, value: "CUSTOM" };
            default:
              return { success: false };
          }
        })(value);
      })(value["gender"]);
    })();
    if (!parsed_gender.success) {
      return { success: false };
    }
    if (!hasProp(value, "lastName")) {
      return { success: false };
    }
    const parsed_lastName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["lastName"]);
    })();
    if (!parsed_lastName.success) {
      return { success: false };
    }
    if (!hasProp(value, "middleName")) {
      return { success: false };
    }
    const parsed_middleName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["middleName"]);
    })();
    if (!parsed_middleName.success) {
      return { success: false };
    }
    return { success: true, value: { firstName: parsed_firstName.value, gender: parsed_gender.value, lastName: parsed_lastName.value, middleName: parsed_middleName.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// PortletLayout
export function serialize_PortletLayout(obj: PortletLayout): string {
  const serialized = ((value: "GRID" | "CAROUSEL"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_PortletLayout(str: string): PortletLayout | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "GRID" | "CAROUSEL" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "GRID":
        return { success: true, value: "GRID" };
      case "CAROUSEL":
        return { success: true, value: "CAROUSEL" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// PortletMetadata
export function serialize_PortletMetadata(obj: PortletMetadata): string {
  const serialized = ((value: {
    custom: boolean;
    defaultFilterAllLanguages: boolean;
    embedSortingFiltering: boolean;
    hideTitle: boolean;
    itemCount: number;
    itemLayout: ItemLayout;
    name: string;
    portletId: number;
    portletLayout: PortletLayout;
    subType: string | null;
    title: string;
    type: PortletType;
  }): unknown => {
    return {
      custom: ((value: boolean): unknown => {
        return value;
      })(value["custom"]),
      defaultFilterAllLanguages: ((value: boolean): unknown => {
        return value;
      })(value["defaultFilterAllLanguages"]),
      embedSortingFiltering: ((value: boolean): unknown => {
        return value;
      })(value["embedSortingFiltering"]),
      hideTitle: ((value: boolean): unknown => {
        return value;
      })(value["hideTitle"]),
      itemCount: ((value: number): unknown => {
        return value;
      })(value["itemCount"]),
      itemLayout: ((value: "MINI" | "REGULAR"): unknown => {
        return value;
      })(value["itemLayout"]),
      name: ((value: string): unknown => {
        return value;
      })(value["name"]),
      portletId: ((value: number): unknown => {
        return value;
      })(value["portletId"]),
      portletLayout: ((value: "GRID" | "CAROUSEL"): unknown => {
        return value;
      })(value["portletLayout"]),
      subType: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["subType"]),
      title: ((value: string): unknown => {
        return value;
      })(value["title"]),
      type: ((
        value:
          | "IMAGETEXT"
          | "SUMMARYLIST"
          | "TRENDINGCHANNEL"
          | "FEATUREDCHANNEL"
          | "FOLLOWINGCHANNEL"
          | "MYLIST"
          | "PRIMARYTOPICCHANNEL"
          | "CHANNELLIST"
          | "ANNOTATIONLIST"
          | "LGXPLIST"
          | "SKETCHNOTESLGXP"
          | "ACTIONABLELIST"
          | "ACTIONABLELISTCUSTOM"
          | "MYACTIONABLES"
          | "FREESUMMARY"
          | "SUMMARYLISTCUSTOM"
          | "CUSTOMPAGELIST",
      ): unknown => {
        return value;
      })(value["type"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_PortletMetadata(str: string): PortletMetadata | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          custom: boolean;
          defaultFilterAllLanguages: boolean;
          embedSortingFiltering: boolean;
          hideTitle: boolean;
          itemCount: number;
          itemLayout: ItemLayout;
          name: string;
          portletId: number;
          portletLayout: PortletLayout;
          subType: string | null;
          title: string;
          type: PortletType;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "custom")) {
      return { success: false };
    }
    const parsed_custom = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["custom"]);
    })();
    if (!parsed_custom.success) {
      return { success: false };
    }
    if (!hasProp(value, "defaultFilterAllLanguages")) {
      return { success: false };
    }
    const parsed_defaultFilterAllLanguages = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["defaultFilterAllLanguages"]);
    })();
    if (!parsed_defaultFilterAllLanguages.success) {
      return { success: false };
    }
    if (!hasProp(value, "embedSortingFiltering")) {
      return { success: false };
    }
    const parsed_embedSortingFiltering = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["embedSortingFiltering"]);
    })();
    if (!parsed_embedSortingFiltering.success) {
      return { success: false };
    }
    if (!hasProp(value, "hideTitle")) {
      return { success: false };
    }
    const parsed_hideTitle = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["hideTitle"]);
    })();
    if (!parsed_hideTitle.success) {
      return { success: false };
    }
    if (!hasProp(value, "itemCount")) {
      return { success: false };
    }
    const parsed_itemCount = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["itemCount"]);
    })();
    if (!parsed_itemCount.success) {
      return { success: false };
    }
    if (!hasProp(value, "itemLayout")) {
      return { success: false };
    }
    const parsed_itemLayout = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ItemLayout } => {
        return ((value: unknown): { success: false } | { success: true; value: "MINI" | "REGULAR" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "MINI":
              return { success: true, value: "MINI" };
            case "REGULAR":
              return { success: true, value: "REGULAR" };
            default:
              return { success: false };
          }
        })(value);
      })(value["itemLayout"]);
    })();
    if (!parsed_itemLayout.success) {
      return { success: false };
    }
    if (!hasProp(value, "name")) {
      return { success: false };
    }
    const parsed_name = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["name"]);
    })();
    if (!parsed_name.success) {
      return { success: false };
    }
    if (!hasProp(value, "portletId")) {
      return { success: false };
    }
    const parsed_portletId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["portletId"]);
    })();
    if (!parsed_portletId.success) {
      return { success: false };
    }
    if (!hasProp(value, "portletLayout")) {
      return { success: false };
    }
    const parsed_portletLayout = (() => {
      return ((value: unknown): { success: false } | { success: true; value: PortletLayout } => {
        return ((value: unknown): { success: false } | { success: true; value: "GRID" | "CAROUSEL" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "GRID":
              return { success: true, value: "GRID" };
            case "CAROUSEL":
              return { success: true, value: "CAROUSEL" };
            default:
              return { success: false };
          }
        })(value);
      })(value["portletLayout"]);
    })();
    if (!parsed_portletLayout.success) {
      return { success: false };
    }
    if (!hasProp(value, "subType")) {
      return { success: false };
    }
    const parsed_subType = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["subType"]);
    })();
    if (!parsed_subType.success) {
      return { success: false };
    }
    if (!hasProp(value, "title")) {
      return { success: false };
    }
    const parsed_title = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["title"]);
    })();
    if (!parsed_title.success) {
      return { success: false };
    }
    if (!hasProp(value, "type")) {
      return { success: false };
    }
    const parsed_type = (() => {
      return ((value: unknown): { success: false } | { success: true; value: PortletType } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value:
                | "IMAGETEXT"
                | "SUMMARYLIST"
                | "TRENDINGCHANNEL"
                | "FEATUREDCHANNEL"
                | "FOLLOWINGCHANNEL"
                | "MYLIST"
                | "PRIMARYTOPICCHANNEL"
                | "CHANNELLIST"
                | "ANNOTATIONLIST"
                | "LGXPLIST"
                | "SKETCHNOTESLGXP"
                | "ACTIONABLELIST"
                | "ACTIONABLELISTCUSTOM"
                | "MYACTIONABLES"
                | "FREESUMMARY"
                | "SUMMARYLISTCUSTOM"
                | "CUSTOMPAGELIST";
            } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "IMAGETEXT":
              return { success: true, value: "IMAGETEXT" };
            case "SUMMARYLIST":
              return { success: true, value: "SUMMARYLIST" };
            case "TRENDINGCHANNEL":
              return { success: true, value: "TRENDINGCHANNEL" };
            case "FEATUREDCHANNEL":
              return { success: true, value: "FEATUREDCHANNEL" };
            case "FOLLOWINGCHANNEL":
              return { success: true, value: "FOLLOWINGCHANNEL" };
            case "MYLIST":
              return { success: true, value: "MYLIST" };
            case "PRIMARYTOPICCHANNEL":
              return { success: true, value: "PRIMARYTOPICCHANNEL" };
            case "CHANNELLIST":
              return { success: true, value: "CHANNELLIST" };
            case "ANNOTATIONLIST":
              return { success: true, value: "ANNOTATIONLIST" };
            case "LGXPLIST":
              return { success: true, value: "LGXPLIST" };
            case "SKETCHNOTESLGXP":
              return { success: true, value: "SKETCHNOTESLGXP" };
            case "ACTIONABLELIST":
              return { success: true, value: "ACTIONABLELIST" };
            case "ACTIONABLELISTCUSTOM":
              return { success: true, value: "ACTIONABLELISTCUSTOM" };
            case "MYACTIONABLES":
              return { success: true, value: "MYACTIONABLES" };
            case "FREESUMMARY":
              return { success: true, value: "FREESUMMARY" };
            case "SUMMARYLISTCUSTOM":
              return { success: true, value: "SUMMARYLISTCUSTOM" };
            case "CUSTOMPAGELIST":
              return { success: true, value: "CUSTOMPAGELIST" };
            default:
              return { success: false };
          }
        })(value);
      })(value["type"]);
    })();
    if (!parsed_type.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        custom: parsed_custom.value,
        defaultFilterAllLanguages: parsed_defaultFilterAllLanguages.value,
        embedSortingFiltering: parsed_embedSortingFiltering.value,
        hideTitle: parsed_hideTitle.value,
        itemCount: parsed_itemCount.value,
        itemLayout: parsed_itemLayout.value,
        name: parsed_name.value,
        portletId: parsed_portletId.value,
        portletLayout: parsed_portletLayout.value,
        subType: parsed_subType.value,
        title: parsed_title.value,
        type: parsed_type.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// PortletType
export function serialize_PortletType(obj: PortletType): string {
  const serialized = ((
    value:
      | "IMAGETEXT"
      | "SUMMARYLIST"
      | "TRENDINGCHANNEL"
      | "FEATUREDCHANNEL"
      | "FOLLOWINGCHANNEL"
      | "MYLIST"
      | "PRIMARYTOPICCHANNEL"
      | "CHANNELLIST"
      | "ANNOTATIONLIST"
      | "LGXPLIST"
      | "SKETCHNOTESLGXP"
      | "ACTIONABLELIST"
      | "ACTIONABLELISTCUSTOM"
      | "MYACTIONABLES"
      | "FREESUMMARY"
      | "SUMMARYLISTCUSTOM"
      | "CUSTOMPAGELIST",
  ): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_PortletType(str: string): PortletType | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value:
          | "IMAGETEXT"
          | "SUMMARYLIST"
          | "TRENDINGCHANNEL"
          | "FEATUREDCHANNEL"
          | "FOLLOWINGCHANNEL"
          | "MYLIST"
          | "PRIMARYTOPICCHANNEL"
          | "CHANNELLIST"
          | "ANNOTATIONLIST"
          | "LGXPLIST"
          | "SKETCHNOTESLGXP"
          | "ACTIONABLELIST"
          | "ACTIONABLELISTCUSTOM"
          | "MYACTIONABLES"
          | "FREESUMMARY"
          | "SUMMARYLISTCUSTOM"
          | "CUSTOMPAGELIST";
      } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "IMAGETEXT":
        return { success: true, value: "IMAGETEXT" };
      case "SUMMARYLIST":
        return { success: true, value: "SUMMARYLIST" };
      case "TRENDINGCHANNEL":
        return { success: true, value: "TRENDINGCHANNEL" };
      case "FEATUREDCHANNEL":
        return { success: true, value: "FEATUREDCHANNEL" };
      case "FOLLOWINGCHANNEL":
        return { success: true, value: "FOLLOWINGCHANNEL" };
      case "MYLIST":
        return { success: true, value: "MYLIST" };
      case "PRIMARYTOPICCHANNEL":
        return { success: true, value: "PRIMARYTOPICCHANNEL" };
      case "CHANNELLIST":
        return { success: true, value: "CHANNELLIST" };
      case "ANNOTATIONLIST":
        return { success: true, value: "ANNOTATIONLIST" };
      case "LGXPLIST":
        return { success: true, value: "LGXPLIST" };
      case "SKETCHNOTESLGXP":
        return { success: true, value: "SKETCHNOTESLGXP" };
      case "ACTIONABLELIST":
        return { success: true, value: "ACTIONABLELIST" };
      case "ACTIONABLELISTCUSTOM":
        return { success: true, value: "ACTIONABLELISTCUSTOM" };
      case "MYACTIONABLES":
        return { success: true, value: "MYACTIONABLES" };
      case "FREESUMMARY":
        return { success: true, value: "FREESUMMARY" };
      case "SUMMARYLISTCUSTOM":
        return { success: true, value: "SUMMARYLISTCUSTOM" };
      case "CUSTOMPAGELIST":
        return { success: true, value: "CUSTOMPAGELIST" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// PromoBoxEditProps
export function serialize_PromoBoxEditProps(obj: PromoBoxEditProps): string {
  const serialized = ((value: { corporateId: number; promoBoxId: number }): unknown => {
    return {
      corporateId: ((value: number): unknown => {
        return value;
      })(value["corporateId"]),
      promoBoxId: ((value: number): unknown => {
        return value;
      })(value["promoBoxId"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_PromoBoxEditProps(str: string): PromoBoxEditProps | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          corporateId: number;
          promoBoxId: number;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "corporateId")) {
      return { success: false };
    }
    const parsed_corporateId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["corporateId"]);
    })();
    if (!parsed_corporateId.success) {
      return { success: false };
    }
    if (!hasProp(value, "promoBoxId")) {
      return { success: false };
    }
    const parsed_promoBoxId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["promoBoxId"]);
    })();
    if (!parsed_promoBoxId.success) {
      return { success: false };
    }
    return { success: true, value: { corporateId: parsed_corporateId.value, promoBoxId: parsed_promoBoxId.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ReferencedItemEdit
export function serialize_ReferencedItemEdit(obj: ReferencedItemEdit): string {
  const serialized = ((value: { refItem: ReferencedLearningItem; tags: Tag[] }): unknown => {
    return {
      refItem: ((value: { learningItemType: "SUMMARY"; id: number } | { learningItemType: "ACTIONABLE"; id: bigint }): unknown => {
        switch (value.learningItemType) {
          case "SUMMARY": {
            return {
              learningItemType: "SUMMARY",
              id: ((value: number): unknown => {
                return value;
              })(value["id"]),
            };
          }
          case "ACTIONABLE": {
            return {
              learningItemType: "ACTIONABLE",
              id: ((value: bigint): unknown => {
                return value;
              })(value["id"]),
            };
          }
        }
      })(value["refItem"]),
      tags: ((value: Tag[]): unknown => {
        return value.map(
          (
            value:
              | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
              | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
              | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
              | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
              | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
              | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
              | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string },
          ): unknown => {
            switch (value.discriminator) {
              case "dynamicString": {
                return {
                  discriminator: "dynamicString",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindDynamicString; id: bigint; kind: string }): unknown => {
                    return {
                      discriminator: ((value: "dynamicString"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: string): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: string): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
              case "dynamicBoolean": {
                return {
                  discriminator: "dynamicBoolean",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindDynamicBoolean; id: bigint; kind: string }): unknown => {
                    return {
                      discriminator: ((value: "dynamicBoolean"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: string): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: boolean): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
              case "dynamicNumber": {
                return {
                  discriminator: "dynamicNumber",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindDynamicNumber; id: bigint; kind: string }): unknown => {
                    return {
                      discriminator: ((value: "dynamicNumber"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: string): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: number): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
              case "dynamicVoid": {
                return {
                  discriminator: "dynamicVoid",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindDynamicVoid; id: bigint; kind: string }): unknown => {
                    return {
                      discriminator: ((value: "dynamicVoid"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: string): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                };
              }
              case "channel": {
                return {
                  discriminator: "channel",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindChannel; id: bigint; kind: KindChannel }): unknown => {
                    return {
                      discriminator: ((value: "channel"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: "channel"): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: string): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
              case "key-quality": {
                return {
                  discriminator: "key-quality",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindKeyQuality; id: bigint; kind: KindKeyQuality }): unknown => {
                    return {
                      discriminator: ((value: "key-quality"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: "key-quality"): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: string): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
              case "classification": {
                return {
                  discriminator: "classification",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindClassification; id: bigint; kind: KindClassification }): unknown => {
                    return {
                      discriminator: ((value: "classification"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: "classification"): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: string): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
            }
          },
        );
      })(value["tags"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ReferencedItemEdit(str: string): ReferencedItemEdit | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          refItem: ReferencedLearningItem;
          tags: Tag[];
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "refItem")) {
      return { success: false };
    }
    const parsed_refItem = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ReferencedLearningItem } => {
        return ((value: unknown): { success: false } | { success: true; value: { learningItemType: "SUMMARY"; id: number } | { learningItemType: "ACTIONABLE"; id: bigint } } => {
          return ((value: unknown): { success: false } | { success: true; value: { learningItemType: "SUMMARY"; id: number } | { learningItemType: "ACTIONABLE"; id: bigint } } => {
            if (value == null) {
              return { success: false };
            }
            if (typeof value != "object") {
              return { success: false };
            }
            if (!hasProp(value, "learningItemType")) {
              return { success: false };
            }
            if (typeof value["learningItemType"] != "string") {
              return { success: false };
            }
            const discriminator = value["learningItemType"];
            switch (discriminator) {
              case "SUMMARY": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        id: number;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "id")) {
                    return { success: false };
                  }
                  const parsed_id = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number } => {
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["id"]);
                  })();
                  if (!parsed_id.success) {
                    return { success: false };
                  }
                  return { success: true, value: { id: parsed_id.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { learningItemType: "SUMMARY" as const, ...parsedVariant.value } };
              }
              case "ACTIONABLE": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        id: bigint;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "id")) {
                    return { success: false };
                  }
                  const parsed_id = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                      if (typeof value === "number") {
                        return { success: true, value: BigInt(value) };
                      }
                      if (typeof value === "bigint") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["id"]);
                  })();
                  if (!parsed_id.success) {
                    return { success: false };
                  }
                  return { success: true, value: { id: parsed_id.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { learningItemType: "ACTIONABLE" as const, ...parsedVariant.value } };
              }
              default:
                return { success: false };
            }
          })(value);
        })(value);
      })(value["refItem"]);
    })();
    if (!parsed_refItem.success) {
      return { success: false };
    }
    if (!hasProp(value, "tags")) {
      return { success: false };
    }
    const parsed_tags = (() => {
      return ((value: unknown): { success: false } | { success: true; value: Tag[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: Tag } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value:
                  | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                  | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                  | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                  | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                  | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                  | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                  | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
              } => {
            return ((
              value: unknown,
            ):
              | { success: false }
              | {
                  success: true;
                  value:
                    | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                    | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                    | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                    | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                    | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                    | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                    | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                } => {
              if (value == null) {
                return { success: false };
              }
              if (typeof value != "object") {
                return { success: false };
              }
              if (!hasProp(value, "discriminator")) {
                return { success: false };
              }
              if (typeof value["discriminator"] != "string") {
                return { success: false };
              }
              const discriminator = value["discriminator"];
              switch (discriminator) {
                case "dynamicString": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindDynamicString;
                          value: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "CHANNEL_HIERARCHY":
                              return { success: true, value: "CHANNEL_HIERARCHY" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicString } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindDynamicString;
                                id: bigint;
                                kind: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindDynamicString } => {
                              return ((value: unknown): { success: false } | { success: true; value: "dynamicString" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "dynamicString":
                                    return { success: true, value: "dynamicString" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "dynamicString" as const, ...parsedVariant.value } };
                }
                case "dynamicBoolean": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindDynamicBoolean;
                          value: boolean;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "CHANNEL_HIERARCHY":
                              return { success: true, value: "CHANNEL_HIERARCHY" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicBoolean } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindDynamicBoolean;
                                id: bigint;
                                kind: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindDynamicBoolean } => {
                              return ((value: unknown): { success: false } | { success: true; value: "dynamicBoolean" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "dynamicBoolean":
                                    return { success: true, value: "dynamicBoolean" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "dynamicBoolean" as const, ...parsedVariant.value } };
                }
                case "dynamicNumber": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindDynamicNumber;
                          value: number;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "CHANNEL_HIERARCHY":
                              return { success: true, value: "CHANNEL_HIERARCHY" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicNumber } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindDynamicNumber;
                                id: bigint;
                                kind: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindDynamicNumber } => {
                              return ((value: unknown): { success: false } | { success: true; value: "dynamicNumber" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "dynamicNumber":
                                    return { success: true, value: "dynamicNumber" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: number } => {
                        if (typeof value === "number") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "dynamicNumber" as const, ...parsedVariant.value } };
                }
                case "dynamicVoid": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindDynamicVoid;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "CHANNEL_HIERARCHY":
                              return { success: true, value: "CHANNEL_HIERARCHY" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicVoid } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindDynamicVoid;
                                id: bigint;
                                kind: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindDynamicVoid } => {
                              return ((value: unknown): { success: false } | { success: true; value: "dynamicVoid" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "dynamicVoid":
                                    return { success: true, value: "dynamicVoid" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "dynamicVoid" as const, ...parsedVariant.value } };
                }
                case "channel": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindChannel;
                          value: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "CHANNEL_HIERARCHY":
                              return { success: true, value: "CHANNEL_HIERARCHY" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindChannel } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindChannel;
                                id: bigint;
                                kind: KindChannel;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                              return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "channel":
                                    return { success: true, value: "channel" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                              return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "channel":
                                    return { success: true, value: "channel" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "channel" as const, ...parsedVariant.value } };
                }
                case "key-quality": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindKeyQuality;
                          value: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "CHANNEL_HIERARCHY":
                              return { success: true, value: "CHANNEL_HIERARCHY" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindKeyQuality } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindKeyQuality;
                                id: bigint;
                                kind: KindKeyQuality;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                              return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "key-quality":
                                    return { success: true, value: "key-quality" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                              return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "key-quality":
                                    return { success: true, value: "key-quality" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "key-quality" as const, ...parsedVariant.value } };
                }
                case "classification": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindClassification;
                          value: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "CHANNEL_HIERARCHY":
                              return { success: true, value: "CHANNEL_HIERARCHY" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindClassification } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindClassification;
                                id: bigint;
                                kind: KindClassification;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                              return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "classification":
                                    return { success: true, value: "classification" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                              return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "classification":
                                    return { success: true, value: "classification" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "classification" as const, ...parsedVariant.value } };
                }
                default:
                  return { success: false };
              }
            })(value);
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["tags"]);
    })();
    if (!parsed_tags.success) {
      return { success: false };
    }
    return { success: true, value: { refItem: parsed_refItem.value, tags: parsed_tags.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ReferencedItemSaveRequest
export function serialize_ReferencedItemSaveRequest(obj: ReferencedItemSaveRequest): string {
  const serialized = ((value: { items: ReferencedItemEdit[] }): unknown => {
    return {
      items: ((value: ReferencedItemEdit[]): unknown => {
        return value.map((value: { refItem: ReferencedLearningItem; tags: Tag[] }): unknown => {
          return {
            refItem: ((value: { learningItemType: "SUMMARY"; id: number } | { learningItemType: "ACTIONABLE"; id: bigint }): unknown => {
              switch (value.learningItemType) {
                case "SUMMARY": {
                  return {
                    learningItemType: "SUMMARY",
                    id: ((value: number): unknown => {
                      return value;
                    })(value["id"]),
                  };
                }
                case "ACTIONABLE": {
                  return {
                    learningItemType: "ACTIONABLE",
                    id: ((value: bigint): unknown => {
                      return value;
                    })(value["id"]),
                  };
                }
              }
            })(value["refItem"]),
            tags: ((value: Tag[]): unknown => {
              return value.map(
                (
                  value:
                    | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                    | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                    | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                    | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                    | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                    | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                    | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string },
                ): unknown => {
                  switch (value.discriminator) {
                    case "dynamicString": {
                      return {
                        discriminator: "dynamicString",
                        exclude: ((value: boolean): unknown => {
                          return value;
                        })(value["exclude"]),
                        source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                          return value;
                        })(value["source"]),
                        tagKind: ((value: { discriminator: KindDynamicString; id: bigint; kind: string }): unknown => {
                          return {
                            discriminator: ((value: "dynamicString"): unknown => {
                              return value;
                            })(value["discriminator"]),
                            id: ((value: bigint): unknown => {
                              return value;
                            })(value["id"]),
                            kind: ((value: string): unknown => {
                              return value;
                            })(value["kind"]),
                          };
                        })(value["tagKind"]),
                        value: ((value: string): unknown => {
                          return value;
                        })(value["value"]),
                      };
                    }
                    case "dynamicBoolean": {
                      return {
                        discriminator: "dynamicBoolean",
                        exclude: ((value: boolean): unknown => {
                          return value;
                        })(value["exclude"]),
                        source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                          return value;
                        })(value["source"]),
                        tagKind: ((value: { discriminator: KindDynamicBoolean; id: bigint; kind: string }): unknown => {
                          return {
                            discriminator: ((value: "dynamicBoolean"): unknown => {
                              return value;
                            })(value["discriminator"]),
                            id: ((value: bigint): unknown => {
                              return value;
                            })(value["id"]),
                            kind: ((value: string): unknown => {
                              return value;
                            })(value["kind"]),
                          };
                        })(value["tagKind"]),
                        value: ((value: boolean): unknown => {
                          return value;
                        })(value["value"]),
                      };
                    }
                    case "dynamicNumber": {
                      return {
                        discriminator: "dynamicNumber",
                        exclude: ((value: boolean): unknown => {
                          return value;
                        })(value["exclude"]),
                        source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                          return value;
                        })(value["source"]),
                        tagKind: ((value: { discriminator: KindDynamicNumber; id: bigint; kind: string }): unknown => {
                          return {
                            discriminator: ((value: "dynamicNumber"): unknown => {
                              return value;
                            })(value["discriminator"]),
                            id: ((value: bigint): unknown => {
                              return value;
                            })(value["id"]),
                            kind: ((value: string): unknown => {
                              return value;
                            })(value["kind"]),
                          };
                        })(value["tagKind"]),
                        value: ((value: number): unknown => {
                          return value;
                        })(value["value"]),
                      };
                    }
                    case "dynamicVoid": {
                      return {
                        discriminator: "dynamicVoid",
                        exclude: ((value: boolean): unknown => {
                          return value;
                        })(value["exclude"]),
                        source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                          return value;
                        })(value["source"]),
                        tagKind: ((value: { discriminator: KindDynamicVoid; id: bigint; kind: string }): unknown => {
                          return {
                            discriminator: ((value: "dynamicVoid"): unknown => {
                              return value;
                            })(value["discriminator"]),
                            id: ((value: bigint): unknown => {
                              return value;
                            })(value["id"]),
                            kind: ((value: string): unknown => {
                              return value;
                            })(value["kind"]),
                          };
                        })(value["tagKind"]),
                      };
                    }
                    case "channel": {
                      return {
                        discriminator: "channel",
                        exclude: ((value: boolean): unknown => {
                          return value;
                        })(value["exclude"]),
                        source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                          return value;
                        })(value["source"]),
                        tagKind: ((value: { discriminator: KindChannel; id: bigint; kind: KindChannel }): unknown => {
                          return {
                            discriminator: ((value: "channel"): unknown => {
                              return value;
                            })(value["discriminator"]),
                            id: ((value: bigint): unknown => {
                              return value;
                            })(value["id"]),
                            kind: ((value: "channel"): unknown => {
                              return value;
                            })(value["kind"]),
                          };
                        })(value["tagKind"]),
                        value: ((value: string): unknown => {
                          return value;
                        })(value["value"]),
                      };
                    }
                    case "key-quality": {
                      return {
                        discriminator: "key-quality",
                        exclude: ((value: boolean): unknown => {
                          return value;
                        })(value["exclude"]),
                        source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                          return value;
                        })(value["source"]),
                        tagKind: ((value: { discriminator: KindKeyQuality; id: bigint; kind: KindKeyQuality }): unknown => {
                          return {
                            discriminator: ((value: "key-quality"): unknown => {
                              return value;
                            })(value["discriminator"]),
                            id: ((value: bigint): unknown => {
                              return value;
                            })(value["id"]),
                            kind: ((value: "key-quality"): unknown => {
                              return value;
                            })(value["kind"]),
                          };
                        })(value["tagKind"]),
                        value: ((value: string): unknown => {
                          return value;
                        })(value["value"]),
                      };
                    }
                    case "classification": {
                      return {
                        discriminator: "classification",
                        exclude: ((value: boolean): unknown => {
                          return value;
                        })(value["exclude"]),
                        source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                          return value;
                        })(value["source"]),
                        tagKind: ((value: { discriminator: KindClassification; id: bigint; kind: KindClassification }): unknown => {
                          return {
                            discriminator: ((value: "classification"): unknown => {
                              return value;
                            })(value["discriminator"]),
                            id: ((value: bigint): unknown => {
                              return value;
                            })(value["id"]),
                            kind: ((value: "classification"): unknown => {
                              return value;
                            })(value["kind"]),
                          };
                        })(value["tagKind"]),
                        value: ((value: string): unknown => {
                          return value;
                        })(value["value"]),
                      };
                    }
                  }
                },
              );
            })(value["tags"]),
          };
        });
      })(value["items"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ReferencedItemSaveRequest(str: string): ReferencedItemSaveRequest | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          items: ReferencedItemEdit[];
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "items")) {
      return { success: false };
    }
    const parsed_items = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ReferencedItemEdit[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: ReferencedItemEdit } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  refItem: ReferencedLearningItem;
                  tags: Tag[];
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "refItem")) {
              return { success: false };
            }
            const parsed_refItem = (() => {
              return ((value: unknown): { success: false } | { success: true; value: ReferencedLearningItem } => {
                return ((value: unknown): { success: false } | { success: true; value: { learningItemType: "SUMMARY"; id: number } | { learningItemType: "ACTIONABLE"; id: bigint } } => {
                  return ((value: unknown): { success: false } | { success: true; value: { learningItemType: "SUMMARY"; id: number } | { learningItemType: "ACTIONABLE"; id: bigint } } => {
                    if (value == null) {
                      return { success: false };
                    }
                    if (typeof value != "object") {
                      return { success: false };
                    }
                    if (!hasProp(value, "learningItemType")) {
                      return { success: false };
                    }
                    if (typeof value["learningItemType"] != "string") {
                      return { success: false };
                    }
                    const discriminator = value["learningItemType"];
                    switch (discriminator) {
                      case "SUMMARY": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                id: number;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: number } => {
                              if (typeof value === "number") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          return { success: true, value: { id: parsed_id.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { learningItemType: "SUMMARY" as const, ...parsedVariant.value } };
                      }
                      case "ACTIONABLE": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                id: bigint;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          return { success: true, value: { id: parsed_id.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { learningItemType: "ACTIONABLE" as const, ...parsedVariant.value } };
                      }
                      default:
                        return { success: false };
                    }
                  })(value);
                })(value);
              })(value["refItem"]);
            })();
            if (!parsed_refItem.success) {
              return { success: false };
            }
            if (!hasProp(value, "tags")) {
              return { success: false };
            }
            const parsed_tags = (() => {
              return ((value: unknown): { success: false } | { success: true; value: Tag[] } => {
                if (!isArray(value)) {
                  return { success: false };
                }
                const parsed = value.map((value: unknown): { success: false } | { success: true; value: Tag } => {
                  return ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value:
                          | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                          | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                          | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                          | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                          | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                          | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                          | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                      } => {
                    return ((
                      value: unknown,
                    ):
                      | { success: false }
                      | {
                          success: true;
                          value:
                            | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                            | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                            | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                            | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                            | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                            | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                            | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                        } => {
                      if (value == null) {
                        return { success: false };
                      }
                      if (typeof value != "object") {
                        return { success: false };
                      }
                      if (!hasProp(value, "discriminator")) {
                        return { success: false };
                      }
                      if (typeof value["discriminator"] != "string") {
                        return { success: false };
                      }
                      const discriminator = value["discriminator"];
                      switch (discriminator) {
                        case "dynamicString": {
                          const parsedVariant = ((
                            value: unknown,
                          ):
                            | { success: false }
                            | {
                                success: true;
                                value: {
                                  exclude: boolean;
                                  source: TagSourceType;
                                  tagKind: TagKindDynamicString;
                                  value: string;
                                };
                              } => {
                            if (typeof value !== "object") {
                              return { success: false };
                            }
                            if (value === null) {
                              return { success: false };
                            }
                            if (!hasProp(value, "exclude")) {
                              return { success: false };
                            }
                            const parsed_exclude = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                if (typeof value !== "boolean") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["exclude"]);
                            })();
                            if (!parsed_exclude.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "source")) {
                              return { success: false };
                            }
                            const parsed_source = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  switch (value) {
                                    case "USER":
                                      return { success: true, value: "USER" };
                                    case "CHANNEL_HIERARCHY":
                                      return { success: true, value: "CHANNEL_HIERARCHY" };
                                    case "AI":
                                      return { success: true, value: "AI" };
                                    default:
                                      return { success: false };
                                  }
                                })(value);
                              })(value["source"]);
                            })();
                            if (!parsed_source.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "tagKind")) {
                              return { success: false };
                            }
                            const parsed_tagKind = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicString } => {
                                return ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        discriminator: KindDynamicString;
                                        id: bigint;
                                        kind: string;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "discriminator")) {
                                    return { success: false };
                                  }
                                  const parsed_discriminator = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindDynamicString } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "dynamicString" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "dynamicString":
                                            return { success: true, value: "dynamicString" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["discriminator"]);
                                  })();
                                  if (!parsed_discriminator.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "id")) {
                                    return { success: false };
                                  }
                                  const parsed_id = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                      if (typeof value === "number") {
                                        return { success: true, value: BigInt(value) };
                                      }
                                      if (typeof value === "bigint") {
                                        return { success: true, value: value };
                                      }
                                      return { success: false };
                                    })(value["id"]);
                                  })();
                                  if (!parsed_id.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "kind")) {
                                    return { success: false };
                                  }
                                  const parsed_kind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["kind"]);
                                  })();
                                  if (!parsed_kind.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                })(value);
                              })(value["tagKind"]);
                            })();
                            if (!parsed_tagKind.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "value")) {
                              return { success: false };
                            }
                            const parsed_value = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: string } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["value"]);
                            })();
                            if (!parsed_value.success) {
                              return { success: false };
                            }
                            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                          })(value);
                          if (!parsedVariant.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: "dynamicString" as const, ...parsedVariant.value } };
                        }
                        case "dynamicBoolean": {
                          const parsedVariant = ((
                            value: unknown,
                          ):
                            | { success: false }
                            | {
                                success: true;
                                value: {
                                  exclude: boolean;
                                  source: TagSourceType;
                                  tagKind: TagKindDynamicBoolean;
                                  value: boolean;
                                };
                              } => {
                            if (typeof value !== "object") {
                              return { success: false };
                            }
                            if (value === null) {
                              return { success: false };
                            }
                            if (!hasProp(value, "exclude")) {
                              return { success: false };
                            }
                            const parsed_exclude = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                if (typeof value !== "boolean") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["exclude"]);
                            })();
                            if (!parsed_exclude.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "source")) {
                              return { success: false };
                            }
                            const parsed_source = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  switch (value) {
                                    case "USER":
                                      return { success: true, value: "USER" };
                                    case "CHANNEL_HIERARCHY":
                                      return { success: true, value: "CHANNEL_HIERARCHY" };
                                    case "AI":
                                      return { success: true, value: "AI" };
                                    default:
                                      return { success: false };
                                  }
                                })(value);
                              })(value["source"]);
                            })();
                            if (!parsed_source.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "tagKind")) {
                              return { success: false };
                            }
                            const parsed_tagKind = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicBoolean } => {
                                return ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        discriminator: KindDynamicBoolean;
                                        id: bigint;
                                        kind: string;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "discriminator")) {
                                    return { success: false };
                                  }
                                  const parsed_discriminator = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindDynamicBoolean } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "dynamicBoolean" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "dynamicBoolean":
                                            return { success: true, value: "dynamicBoolean" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["discriminator"]);
                                  })();
                                  if (!parsed_discriminator.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "id")) {
                                    return { success: false };
                                  }
                                  const parsed_id = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                      if (typeof value === "number") {
                                        return { success: true, value: BigInt(value) };
                                      }
                                      if (typeof value === "bigint") {
                                        return { success: true, value: value };
                                      }
                                      return { success: false };
                                    })(value["id"]);
                                  })();
                                  if (!parsed_id.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "kind")) {
                                    return { success: false };
                                  }
                                  const parsed_kind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["kind"]);
                                  })();
                                  if (!parsed_kind.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                })(value);
                              })(value["tagKind"]);
                            })();
                            if (!parsed_tagKind.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "value")) {
                              return { success: false };
                            }
                            const parsed_value = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                if (typeof value !== "boolean") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["value"]);
                            })();
                            if (!parsed_value.success) {
                              return { success: false };
                            }
                            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                          })(value);
                          if (!parsedVariant.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: "dynamicBoolean" as const, ...parsedVariant.value } };
                        }
                        case "dynamicNumber": {
                          const parsedVariant = ((
                            value: unknown,
                          ):
                            | { success: false }
                            | {
                                success: true;
                                value: {
                                  exclude: boolean;
                                  source: TagSourceType;
                                  tagKind: TagKindDynamicNumber;
                                  value: number;
                                };
                              } => {
                            if (typeof value !== "object") {
                              return { success: false };
                            }
                            if (value === null) {
                              return { success: false };
                            }
                            if (!hasProp(value, "exclude")) {
                              return { success: false };
                            }
                            const parsed_exclude = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                if (typeof value !== "boolean") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["exclude"]);
                            })();
                            if (!parsed_exclude.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "source")) {
                              return { success: false };
                            }
                            const parsed_source = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  switch (value) {
                                    case "USER":
                                      return { success: true, value: "USER" };
                                    case "CHANNEL_HIERARCHY":
                                      return { success: true, value: "CHANNEL_HIERARCHY" };
                                    case "AI":
                                      return { success: true, value: "AI" };
                                    default:
                                      return { success: false };
                                  }
                                })(value);
                              })(value["source"]);
                            })();
                            if (!parsed_source.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "tagKind")) {
                              return { success: false };
                            }
                            const parsed_tagKind = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicNumber } => {
                                return ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        discriminator: KindDynamicNumber;
                                        id: bigint;
                                        kind: string;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "discriminator")) {
                                    return { success: false };
                                  }
                                  const parsed_discriminator = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindDynamicNumber } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "dynamicNumber" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "dynamicNumber":
                                            return { success: true, value: "dynamicNumber" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["discriminator"]);
                                  })();
                                  if (!parsed_discriminator.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "id")) {
                                    return { success: false };
                                  }
                                  const parsed_id = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                      if (typeof value === "number") {
                                        return { success: true, value: BigInt(value) };
                                      }
                                      if (typeof value === "bigint") {
                                        return { success: true, value: value };
                                      }
                                      return { success: false };
                                    })(value["id"]);
                                  })();
                                  if (!parsed_id.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "kind")) {
                                    return { success: false };
                                  }
                                  const parsed_kind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["kind"]);
                                  })();
                                  if (!parsed_kind.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                })(value);
                              })(value["tagKind"]);
                            })();
                            if (!parsed_tagKind.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "value")) {
                              return { success: false };
                            }
                            const parsed_value = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: number } => {
                                if (typeof value === "number") {
                                  return { success: true, value: value };
                                }
                                return { success: false };
                              })(value["value"]);
                            })();
                            if (!parsed_value.success) {
                              return { success: false };
                            }
                            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                          })(value);
                          if (!parsedVariant.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: "dynamicNumber" as const, ...parsedVariant.value } };
                        }
                        case "dynamicVoid": {
                          const parsedVariant = ((
                            value: unknown,
                          ):
                            | { success: false }
                            | {
                                success: true;
                                value: {
                                  exclude: boolean;
                                  source: TagSourceType;
                                  tagKind: TagKindDynamicVoid;
                                };
                              } => {
                            if (typeof value !== "object") {
                              return { success: false };
                            }
                            if (value === null) {
                              return { success: false };
                            }
                            if (!hasProp(value, "exclude")) {
                              return { success: false };
                            }
                            const parsed_exclude = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                if (typeof value !== "boolean") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["exclude"]);
                            })();
                            if (!parsed_exclude.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "source")) {
                              return { success: false };
                            }
                            const parsed_source = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  switch (value) {
                                    case "USER":
                                      return { success: true, value: "USER" };
                                    case "CHANNEL_HIERARCHY":
                                      return { success: true, value: "CHANNEL_HIERARCHY" };
                                    case "AI":
                                      return { success: true, value: "AI" };
                                    default:
                                      return { success: false };
                                  }
                                })(value);
                              })(value["source"]);
                            })();
                            if (!parsed_source.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "tagKind")) {
                              return { success: false };
                            }
                            const parsed_tagKind = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicVoid } => {
                                return ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        discriminator: KindDynamicVoid;
                                        id: bigint;
                                        kind: string;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "discriminator")) {
                                    return { success: false };
                                  }
                                  const parsed_discriminator = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindDynamicVoid } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "dynamicVoid" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "dynamicVoid":
                                            return { success: true, value: "dynamicVoid" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["discriminator"]);
                                  })();
                                  if (!parsed_discriminator.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "id")) {
                                    return { success: false };
                                  }
                                  const parsed_id = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                      if (typeof value === "number") {
                                        return { success: true, value: BigInt(value) };
                                      }
                                      if (typeof value === "bigint") {
                                        return { success: true, value: value };
                                      }
                                      return { success: false };
                                    })(value["id"]);
                                  })();
                                  if (!parsed_id.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "kind")) {
                                    return { success: false };
                                  }
                                  const parsed_kind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["kind"]);
                                  })();
                                  if (!parsed_kind.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                })(value);
                              })(value["tagKind"]);
                            })();
                            if (!parsed_tagKind.success) {
                              return { success: false };
                            }
                            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value } };
                          })(value);
                          if (!parsedVariant.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: "dynamicVoid" as const, ...parsedVariant.value } };
                        }
                        case "channel": {
                          const parsedVariant = ((
                            value: unknown,
                          ):
                            | { success: false }
                            | {
                                success: true;
                                value: {
                                  exclude: boolean;
                                  source: TagSourceType;
                                  tagKind: TagKindChannel;
                                  value: string;
                                };
                              } => {
                            if (typeof value !== "object") {
                              return { success: false };
                            }
                            if (value === null) {
                              return { success: false };
                            }
                            if (!hasProp(value, "exclude")) {
                              return { success: false };
                            }
                            const parsed_exclude = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                if (typeof value !== "boolean") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["exclude"]);
                            })();
                            if (!parsed_exclude.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "source")) {
                              return { success: false };
                            }
                            const parsed_source = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  switch (value) {
                                    case "USER":
                                      return { success: true, value: "USER" };
                                    case "CHANNEL_HIERARCHY":
                                      return { success: true, value: "CHANNEL_HIERARCHY" };
                                    case "AI":
                                      return { success: true, value: "AI" };
                                    default:
                                      return { success: false };
                                  }
                                })(value);
                              })(value["source"]);
                            })();
                            if (!parsed_source.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "tagKind")) {
                              return { success: false };
                            }
                            const parsed_tagKind = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagKindChannel } => {
                                return ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        discriminator: KindChannel;
                                        id: bigint;
                                        kind: KindChannel;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "discriminator")) {
                                    return { success: false };
                                  }
                                  const parsed_discriminator = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "channel":
                                            return { success: true, value: "channel" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["discriminator"]);
                                  })();
                                  if (!parsed_discriminator.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "id")) {
                                    return { success: false };
                                  }
                                  const parsed_id = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                      if (typeof value === "number") {
                                        return { success: true, value: BigInt(value) };
                                      }
                                      if (typeof value === "bigint") {
                                        return { success: true, value: value };
                                      }
                                      return { success: false };
                                    })(value["id"]);
                                  })();
                                  if (!parsed_id.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "kind")) {
                                    return { success: false };
                                  }
                                  const parsed_kind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "channel":
                                            return { success: true, value: "channel" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["kind"]);
                                  })();
                                  if (!parsed_kind.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                })(value);
                              })(value["tagKind"]);
                            })();
                            if (!parsed_tagKind.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "value")) {
                              return { success: false };
                            }
                            const parsed_value = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: string } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["value"]);
                            })();
                            if (!parsed_value.success) {
                              return { success: false };
                            }
                            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                          })(value);
                          if (!parsedVariant.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: "channel" as const, ...parsedVariant.value } };
                        }
                        case "key-quality": {
                          const parsedVariant = ((
                            value: unknown,
                          ):
                            | { success: false }
                            | {
                                success: true;
                                value: {
                                  exclude: boolean;
                                  source: TagSourceType;
                                  tagKind: TagKindKeyQuality;
                                  value: string;
                                };
                              } => {
                            if (typeof value !== "object") {
                              return { success: false };
                            }
                            if (value === null) {
                              return { success: false };
                            }
                            if (!hasProp(value, "exclude")) {
                              return { success: false };
                            }
                            const parsed_exclude = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                if (typeof value !== "boolean") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["exclude"]);
                            })();
                            if (!parsed_exclude.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "source")) {
                              return { success: false };
                            }
                            const parsed_source = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  switch (value) {
                                    case "USER":
                                      return { success: true, value: "USER" };
                                    case "CHANNEL_HIERARCHY":
                                      return { success: true, value: "CHANNEL_HIERARCHY" };
                                    case "AI":
                                      return { success: true, value: "AI" };
                                    default:
                                      return { success: false };
                                  }
                                })(value);
                              })(value["source"]);
                            })();
                            if (!parsed_source.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "tagKind")) {
                              return { success: false };
                            }
                            const parsed_tagKind = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagKindKeyQuality } => {
                                return ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        discriminator: KindKeyQuality;
                                        id: bigint;
                                        kind: KindKeyQuality;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "discriminator")) {
                                    return { success: false };
                                  }
                                  const parsed_discriminator = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "key-quality":
                                            return { success: true, value: "key-quality" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["discriminator"]);
                                  })();
                                  if (!parsed_discriminator.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "id")) {
                                    return { success: false };
                                  }
                                  const parsed_id = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                      if (typeof value === "number") {
                                        return { success: true, value: BigInt(value) };
                                      }
                                      if (typeof value === "bigint") {
                                        return { success: true, value: value };
                                      }
                                      return { success: false };
                                    })(value["id"]);
                                  })();
                                  if (!parsed_id.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "kind")) {
                                    return { success: false };
                                  }
                                  const parsed_kind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "key-quality":
                                            return { success: true, value: "key-quality" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["kind"]);
                                  })();
                                  if (!parsed_kind.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                })(value);
                              })(value["tagKind"]);
                            })();
                            if (!parsed_tagKind.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "value")) {
                              return { success: false };
                            }
                            const parsed_value = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: string } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["value"]);
                            })();
                            if (!parsed_value.success) {
                              return { success: false };
                            }
                            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                          })(value);
                          if (!parsedVariant.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: "key-quality" as const, ...parsedVariant.value } };
                        }
                        case "classification": {
                          const parsedVariant = ((
                            value: unknown,
                          ):
                            | { success: false }
                            | {
                                success: true;
                                value: {
                                  exclude: boolean;
                                  source: TagSourceType;
                                  tagKind: TagKindClassification;
                                  value: string;
                                };
                              } => {
                            if (typeof value !== "object") {
                              return { success: false };
                            }
                            if (value === null) {
                              return { success: false };
                            }
                            if (!hasProp(value, "exclude")) {
                              return { success: false };
                            }
                            const parsed_exclude = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                if (typeof value !== "boolean") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["exclude"]);
                            })();
                            if (!parsed_exclude.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "source")) {
                              return { success: false };
                            }
                            const parsed_source = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  switch (value) {
                                    case "USER":
                                      return { success: true, value: "USER" };
                                    case "CHANNEL_HIERARCHY":
                                      return { success: true, value: "CHANNEL_HIERARCHY" };
                                    case "AI":
                                      return { success: true, value: "AI" };
                                    default:
                                      return { success: false };
                                  }
                                })(value);
                              })(value["source"]);
                            })();
                            if (!parsed_source.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "tagKind")) {
                              return { success: false };
                            }
                            const parsed_tagKind = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagKindClassification } => {
                                return ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        discriminator: KindClassification;
                                        id: bigint;
                                        kind: KindClassification;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "discriminator")) {
                                    return { success: false };
                                  }
                                  const parsed_discriminator = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "classification":
                                            return { success: true, value: "classification" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["discriminator"]);
                                  })();
                                  if (!parsed_discriminator.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "id")) {
                                    return { success: false };
                                  }
                                  const parsed_id = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                      if (typeof value === "number") {
                                        return { success: true, value: BigInt(value) };
                                      }
                                      if (typeof value === "bigint") {
                                        return { success: true, value: value };
                                      }
                                      return { success: false };
                                    })(value["id"]);
                                  })();
                                  if (!parsed_id.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "kind")) {
                                    return { success: false };
                                  }
                                  const parsed_kind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "classification":
                                            return { success: true, value: "classification" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["kind"]);
                                  })();
                                  if (!parsed_kind.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                })(value);
                              })(value["tagKind"]);
                            })();
                            if (!parsed_tagKind.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "value")) {
                              return { success: false };
                            }
                            const parsed_value = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: string } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["value"]);
                            })();
                            if (!parsed_value.success) {
                              return { success: false };
                            }
                            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                          })(value);
                          if (!parsedVariant.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: "classification" as const, ...parsedVariant.value } };
                        }
                        default:
                          return { success: false };
                      }
                    })(value);
                  })(value);
                });
                if (parsed.find((value) => !value.success) !== undefined) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: parsed.map((entry) => {
                    assert(entry.success == true);
                    return entry.value;
                  }),
                };
              })(value["tags"]);
            })();
            if (!parsed_tags.success) {
              return { success: false };
            }
            return { success: true, value: { refItem: parsed_refItem.value, tags: parsed_tags.value } };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["items"]);
    })();
    if (!parsed_items.success) {
      return { success: false };
    }
    return { success: true, value: { items: parsed_items.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ReferencedLearningItem
export function serialize_ReferencedLearningItem(obj: ReferencedLearningItem): string {
  const serialized = ((value: { learningItemType: "SUMMARY"; id: number } | { learningItemType: "ACTIONABLE"; id: bigint }): unknown => {
    switch (value.learningItemType) {
      case "SUMMARY": {
        return {
          learningItemType: "SUMMARY",
          id: ((value: number): unknown => {
            return value;
          })(value["id"]),
        };
      }
      case "ACTIONABLE": {
        return {
          learningItemType: "ACTIONABLE",
          id: ((value: bigint): unknown => {
            return value;
          })(value["id"]),
        };
      }
    }
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ReferencedLearningItem(str: string): ReferencedLearningItem | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: { learningItemType: "SUMMARY"; id: number } | { learningItemType: "ACTIONABLE"; id: bigint } } => {
    return ((value: unknown): { success: false } | { success: true; value: { learningItemType: "SUMMARY"; id: number } | { learningItemType: "ACTIONABLE"; id: bigint } } => {
      if (value == null) {
        return { success: false };
      }
      if (typeof value != "object") {
        return { success: false };
      }
      if (!hasProp(value, "learningItemType")) {
        return { success: false };
      }
      if (typeof value["learningItemType"] != "string") {
        return { success: false };
      }
      const discriminator = value["learningItemType"];
      switch (discriminator) {
        case "SUMMARY": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  id: number;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "id")) {
              return { success: false };
            }
            const parsed_id = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["id"]);
            })();
            if (!parsed_id.success) {
              return { success: false };
            }
            return { success: true, value: { id: parsed_id.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { learningItemType: "SUMMARY" as const, ...parsedVariant.value } };
        }
        case "ACTIONABLE": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  id: bigint;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "id")) {
              return { success: false };
            }
            const parsed_id = (() => {
              return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                if (typeof value === "number") {
                  return { success: true, value: BigInt(value) };
                }
                if (typeof value === "bigint") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["id"]);
            })();
            if (!parsed_id.success) {
              return { success: false };
            }
            return { success: true, value: { id: parsed_id.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { learningItemType: "ACTIONABLE" as const, ...parsedVariant.value } };
        }
        default:
          return { success: false };
      }
    })(value);
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ReferencedLearningItemList
export function serialize_ReferencedLearningItemList(obj: ReferencedLearningItemList): string {
  const serialized = ((value: { items: ReferencedLearningItem[] }): unknown => {
    return {
      items: ((value: ReferencedLearningItem[]): unknown => {
        return value.map((value: { learningItemType: "SUMMARY"; id: number } | { learningItemType: "ACTIONABLE"; id: bigint }): unknown => {
          switch (value.learningItemType) {
            case "SUMMARY": {
              return {
                learningItemType: "SUMMARY",
                id: ((value: number): unknown => {
                  return value;
                })(value["id"]),
              };
            }
            case "ACTIONABLE": {
              return {
                learningItemType: "ACTIONABLE",
                id: ((value: bigint): unknown => {
                  return value;
                })(value["id"]),
              };
            }
          }
        });
      })(value["items"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ReferencedLearningItemList(str: string): ReferencedLearningItemList | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          items: ReferencedLearningItem[];
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "items")) {
      return { success: false };
    }
    const parsed_items = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ReferencedLearningItem[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: ReferencedLearningItem } => {
          return ((value: unknown): { success: false } | { success: true; value: { learningItemType: "SUMMARY"; id: number } | { learningItemType: "ACTIONABLE"; id: bigint } } => {
            return ((value: unknown): { success: false } | { success: true; value: { learningItemType: "SUMMARY"; id: number } | { learningItemType: "ACTIONABLE"; id: bigint } } => {
              if (value == null) {
                return { success: false };
              }
              if (typeof value != "object") {
                return { success: false };
              }
              if (!hasProp(value, "learningItemType")) {
                return { success: false };
              }
              if (typeof value["learningItemType"] != "string") {
                return { success: false };
              }
              const discriminator = value["learningItemType"];
              switch (discriminator) {
                case "SUMMARY": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          id: number;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "id")) {
                      return { success: false };
                    }
                    const parsed_id = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: number } => {
                        if (typeof value === "number") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["id"]);
                    })();
                    if (!parsed_id.success) {
                      return { success: false };
                    }
                    return { success: true, value: { id: parsed_id.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { learningItemType: "SUMMARY" as const, ...parsedVariant.value } };
                }
                case "ACTIONABLE": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          id: bigint;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "id")) {
                      return { success: false };
                    }
                    const parsed_id = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                        if (typeof value === "number") {
                          return { success: true, value: BigInt(value) };
                        }
                        if (typeof value === "bigint") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["id"]);
                    })();
                    if (!parsed_id.success) {
                      return { success: false };
                    }
                    return { success: true, value: { id: parsed_id.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { learningItemType: "ACTIONABLE" as const, ...parsedVariant.value } };
                }
                default:
                  return { success: false };
              }
            })(value);
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["items"]);
    })();
    if (!parsed_items.success) {
      return { success: false };
    }
    return { success: true, value: { items: parsed_items.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// RightsProbability
export function serialize_RightsProbability(obj: RightsProbability): string {
  const serialized = ((value: "UNPREDICTABLE" | "LIKELY" | "VERY_LIKELY" | "UNLIKELY"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_RightsProbability(str: string): RightsProbability | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "UNPREDICTABLE" | "LIKELY" | "VERY_LIKELY" | "UNLIKELY" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "UNPREDICTABLE":
        return { success: true, value: "UNPREDICTABLE" };
      case "LIKELY":
        return { success: true, value: "LIKELY" };
      case "VERY_LIKELY":
        return { success: true, value: "VERY_LIKELY" };
      case "UNLIKELY":
        return { success: true, value: "UNLIKELY" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// RightsState
export function serialize_RightsState(obj: RightsState): string {
  const serialized = ((value: "RIGHTS_NOT_REQUESTED" | "PENDING" | "PENDING_PUBLISHER_APPROVAL" | "OK" | "NO_RIGHTS"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_RightsState(str: string): RightsState | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "RIGHTS_NOT_REQUESTED" | "PENDING" | "PENDING_PUBLISHER_APPROVAL" | "OK" | "NO_RIGHTS" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "RIGHTS_NOT_REQUESTED":
        return { success: true, value: "RIGHTS_NOT_REQUESTED" };
      case "PENDING":
        return { success: true, value: "PENDING" };
      case "PENDING_PUBLISHER_APPROVAL":
        return { success: true, value: "PENDING_PUBLISHER_APPROVAL" };
      case "OK":
        return { success: true, value: "OK" };
      case "NO_RIGHTS":
        return { success: true, value: "NO_RIGHTS" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// SearchFiltersForm
export function serialize_SearchFiltersForm(obj: SearchFiltersForm): string {
  const serialized = ((value: {
    audioFormFilter: boolean;
    languageFormFilter: string[];
    publicationDateFormFilter: number[];
    qualityFormFilter: string[];
    query: string;
    ratingFormFilter: number[];
    sourceFormFilter: string[];
    summariesPage: number;
  }): unknown => {
    return {
      audioFormFilter: ((value: boolean): unknown => {
        return value;
      })(value["audioFormFilter"]),
      languageFormFilter: ((value: string[]): unknown => {
        return value.map((value: string): unknown => {
          return value;
        });
      })(value["languageFormFilter"]),
      publicationDateFormFilter: ((value: number[]): unknown => {
        return value.map((value: number): unknown => {
          return value;
        });
      })(value["publicationDateFormFilter"]),
      qualityFormFilter: ((value: string[]): unknown => {
        return value.map((value: string): unknown => {
          return value;
        });
      })(value["qualityFormFilter"]),
      query: ((value: string): unknown => {
        return value;
      })(value["query"]),
      ratingFormFilter: ((value: number[]): unknown => {
        return value.map((value: number): unknown => {
          return value;
        });
      })(value["ratingFormFilter"]),
      sourceFormFilter: ((value: string[]): unknown => {
        return value.map((value: string): unknown => {
          return value;
        });
      })(value["sourceFormFilter"]),
      summariesPage: ((value: number): unknown => {
        return value;
      })(value["summariesPage"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_SearchFiltersForm(str: string): SearchFiltersForm | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          audioFormFilter: boolean;
          languageFormFilter: string[];
          publicationDateFormFilter: number[];
          qualityFormFilter: string[];
          query: string;
          ratingFormFilter: number[];
          sourceFormFilter: string[];
          summariesPage: number;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "audioFormFilter")) {
      return { success: false };
    }
    const parsed_audioFormFilter = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["audioFormFilter"]);
    })();
    if (!parsed_audioFormFilter.success) {
      return { success: false };
    }
    if (!hasProp(value, "languageFormFilter")) {
      return { success: false };
    }
    const parsed_languageFormFilter = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          return { success: true, value: value };
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["languageFormFilter"]);
    })();
    if (!parsed_languageFormFilter.success) {
      return { success: false };
    }
    if (!hasProp(value, "publicationDateFormFilter")) {
      return { success: false };
    }
    const parsed_publicationDateFormFilter = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: number } => {
          if (typeof value === "number") {
            return { success: true, value: value };
          }
          return { success: false };
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["publicationDateFormFilter"]);
    })();
    if (!parsed_publicationDateFormFilter.success) {
      return { success: false };
    }
    if (!hasProp(value, "qualityFormFilter")) {
      return { success: false };
    }
    const parsed_qualityFormFilter = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          return { success: true, value: value };
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["qualityFormFilter"]);
    })();
    if (!parsed_qualityFormFilter.success) {
      return { success: false };
    }
    if (!hasProp(value, "query")) {
      return { success: false };
    }
    const parsed_query = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["query"]);
    })();
    if (!parsed_query.success) {
      return { success: false };
    }
    if (!hasProp(value, "ratingFormFilter")) {
      return { success: false };
    }
    const parsed_ratingFormFilter = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: number } => {
          if (typeof value === "number") {
            return { success: true, value: value };
          }
          return { success: false };
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["ratingFormFilter"]);
    })();
    if (!parsed_ratingFormFilter.success) {
      return { success: false };
    }
    if (!hasProp(value, "sourceFormFilter")) {
      return { success: false };
    }
    const parsed_sourceFormFilter = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          return { success: true, value: value };
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["sourceFormFilter"]);
    })();
    if (!parsed_sourceFormFilter.success) {
      return { success: false };
    }
    if (!hasProp(value, "summariesPage")) {
      return { success: false };
    }
    const parsed_summariesPage = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["summariesPage"]);
    })();
    if (!parsed_summariesPage.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        audioFormFilter: parsed_audioFormFilter.value,
        languageFormFilter: parsed_languageFormFilter.value,
        publicationDateFormFilter: parsed_publicationDateFormFilter.value,
        qualityFormFilter: parsed_qualityFormFilter.value,
        query: parsed_query.value,
        ratingFormFilter: parsed_ratingFormFilter.value,
        sourceFormFilter: parsed_sourceFormFilter.value,
        summariesPage: parsed_summariesPage.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// SummaryCardDemoProps
export function serialize_SummaryCardDemoProps(obj: SummaryCardDemoProps): string {
  const serialized = ((value: { summaryList: SummaryMetadata[] }): unknown => {
    return {
      summaryList: ((value: SummaryMetadata[]): unknown => {
        return value.map(
          (value: {
            activatedAt: DateTime<true> | null;
            activationTime: DateTime<true> | null;
            active: boolean;
            audioActivatedAt: DateTime<true> | null;
            audioActive: boolean;
            audioDuration: number;
            authorInfo: string | null;
            authors: {
              authorId: number;
              firstName: string | null;
              gender: ("MALE" | "FEMALE" | "CUSTOM" | "UNKNOWN") | null;
              lastName: string | null;
              middleName: string | null;
            }[];
            bestseller: boolean;
            businessClassic: boolean;
            canonicalName: string;
            contentItemId: bigint;
            contentItemType: "UNKNOWN" | "SUMMARY" | "CATEGORY" | "DISCUSSION" | "CHANNEL" | "LGXP" | "ACTIONABLE" | "MYLIST" | "CUSTOMPAGE";
            countriesExcluded: Country[];
            countriesIncluded: Country[];
            coverModifiedAt: DateTime<true> | null;
            coverUri: string;
            dataId: number;
            descriptionHtml: string | null;
            documentType: "ABSTRACT" | "REVIEW";
            duration: number;
            imageBorder: boolean;
            language: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr";
            modifiedAt: DateTime<true> | null;
            originalTitle: string | null;
            previewAvailable: boolean;
            publicationDate: string | null;
            publisherInfo: string | null;
            publisherName: string | null;
            rating: number | null;
            sourceType: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST";
            subtitle: string | null;
            summaryPages: number;
            summaryWords: number;
            tags: Tag[];
            teaser: string | null;
            title: string | null;
          }): unknown => {
            return {
              activatedAt: ((value: DateTime<true> | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value.toMillis();
              })(value["activatedAt"]),
              activationTime: ((value: DateTime<true> | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value.toMillis();
              })(value["activationTime"]),
              active: ((value: boolean): unknown => {
                return value;
              })(value["active"]),
              audioActivatedAt: ((value: DateTime<true> | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value.toMillis();
              })(value["audioActivatedAt"]),
              audioActive: ((value: boolean): unknown => {
                return value;
              })(value["audioActive"]),
              audioDuration: ((value: number): unknown => {
                return value;
              })(value["audioDuration"]),
              authorInfo: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["authorInfo"]),
              authors: ((
                value: {
                  authorId: number;
                  firstName: string | null;
                  gender: ("MALE" | "FEMALE" | "CUSTOM" | "UNKNOWN") | null;
                  lastName: string | null;
                  middleName: string | null;
                }[],
              ): unknown => {
                return value.map((value: { authorId: number; firstName: string | null; gender: ("MALE" | "FEMALE" | "CUSTOM" | "UNKNOWN") | null; lastName: string | null; middleName: string | null }): unknown => {
                  return {
                    authorId: ((value: number): unknown => {
                      return value;
                    })(value["authorId"]),
                    firstName: ((value: string | null): unknown => {
                      if (value === null) {
                        return null;
                      }
                      return value;
                    })(value["firstName"]),
                    gender: ((value: ("MALE" | "FEMALE" | "CUSTOM" | "UNKNOWN") | null): unknown => {
                      if (value === null) {
                        return null;
                      }
                      return value;
                    })(value["gender"]),
                    lastName: ((value: string | null): unknown => {
                      if (value === null) {
                        return null;
                      }
                      return value;
                    })(value["lastName"]),
                    middleName: ((value: string | null): unknown => {
                      if (value === null) {
                        return null;
                      }
                      return value;
                    })(value["middleName"]),
                  };
                });
              })(value["authors"]),
              bestseller: ((value: boolean): unknown => {
                return value;
              })(value["bestseller"]),
              businessClassic: ((value: boolean): unknown => {
                return value;
              })(value["businessClassic"]),
              canonicalName: ((value: string): unknown => {
                return value;
              })(value["canonicalName"]),
              contentItemId: ((value: bigint): unknown => {
                return value;
              })(value["contentItemId"]),
              contentItemType: ((value: "UNKNOWN" | "SUMMARY" | "CATEGORY" | "DISCUSSION" | "CHANNEL" | "LGXP" | "ACTIONABLE" | "MYLIST" | "CUSTOMPAGE"): unknown => {
                return value;
              })(value["contentItemType"]),
              countriesExcluded: ((value: Country[]): unknown => {
                return value.map(
                  (value: {
                    countryCode: string;
                    currency: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY";
                    names: {
                      de: string;
                      en: string;
                      es: string;
                      fr: string;
                      pt: string;
                      ru: string;
                      zh: string;
                    };
                    phonePrefix: string;
                    region: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA";
                  }): unknown => {
                    return {
                      countryCode: ((value: string): unknown => {
                        return value;
                      })(value["countryCode"]),
                      currency: ((value: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY"): unknown => {
                        return value;
                      })(value["currency"]),
                      names: ((value: { de: string; en: string; es: string; fr: string; pt: string; ru: string; zh: string }): unknown => {
                        return {
                          de: ((value: string): unknown => {
                            return value;
                          })(value["de"]),
                          en: ((value: string): unknown => {
                            return value;
                          })(value["en"]),
                          es: ((value: string): unknown => {
                            return value;
                          })(value["es"]),
                          fr: ((value: string): unknown => {
                            return value;
                          })(value["fr"]),
                          pt: ((value: string): unknown => {
                            return value;
                          })(value["pt"]),
                          ru: ((value: string): unknown => {
                            return value;
                          })(value["ru"]),
                          zh: ((value: string): unknown => {
                            return value;
                          })(value["zh"]),
                        };
                      })(value["names"]),
                      phonePrefix: ((value: string): unknown => {
                        return value;
                      })(value["phonePrefix"]),
                      region: ((value: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA"): unknown => {
                        return value;
                      })(value["region"]),
                    };
                  },
                );
              })(value["countriesExcluded"]),
              countriesIncluded: ((value: Country[]): unknown => {
                return value.map(
                  (value: {
                    countryCode: string;
                    currency: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY";
                    names: {
                      de: string;
                      en: string;
                      es: string;
                      fr: string;
                      pt: string;
                      ru: string;
                      zh: string;
                    };
                    phonePrefix: string;
                    region: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA";
                  }): unknown => {
                    return {
                      countryCode: ((value: string): unknown => {
                        return value;
                      })(value["countryCode"]),
                      currency: ((value: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY"): unknown => {
                        return value;
                      })(value["currency"]),
                      names: ((value: { de: string; en: string; es: string; fr: string; pt: string; ru: string; zh: string }): unknown => {
                        return {
                          de: ((value: string): unknown => {
                            return value;
                          })(value["de"]),
                          en: ((value: string): unknown => {
                            return value;
                          })(value["en"]),
                          es: ((value: string): unknown => {
                            return value;
                          })(value["es"]),
                          fr: ((value: string): unknown => {
                            return value;
                          })(value["fr"]),
                          pt: ((value: string): unknown => {
                            return value;
                          })(value["pt"]),
                          ru: ((value: string): unknown => {
                            return value;
                          })(value["ru"]),
                          zh: ((value: string): unknown => {
                            return value;
                          })(value["zh"]),
                        };
                      })(value["names"]),
                      phonePrefix: ((value: string): unknown => {
                        return value;
                      })(value["phonePrefix"]),
                      region: ((value: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA"): unknown => {
                        return value;
                      })(value["region"]),
                    };
                  },
                );
              })(value["countriesIncluded"]),
              coverModifiedAt: ((value: DateTime<true> | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value.toMillis();
              })(value["coverModifiedAt"]),
              coverUri: ((value: string): unknown => {
                return value;
              })(value["coverUri"]),
              dataId: ((value: number): unknown => {
                return value;
              })(value["dataId"]),
              descriptionHtml: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["descriptionHtml"]),
              documentType: ((value: "ABSTRACT" | "REVIEW"): unknown => {
                return value;
              })(value["documentType"]),
              duration: ((value: number): unknown => {
                return value;
              })(value["duration"]),
              imageBorder: ((value: boolean): unknown => {
                return value;
              })(value["imageBorder"]),
              language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                return value;
              })(value["language"]),
              modifiedAt: ((value: DateTime<true> | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value.toMillis();
              })(value["modifiedAt"]),
              originalTitle: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["originalTitle"]),
              previewAvailable: ((value: boolean): unknown => {
                return value;
              })(value["previewAvailable"]),
              publicationDate: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["publicationDate"]),
              publisherInfo: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["publisherInfo"]),
              publisherName: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["publisherName"]),
              rating: ((value: number | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["rating"]),
              sourceType: ((value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST"): unknown => {
                return value;
              })(value["sourceType"]),
              subtitle: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["subtitle"]),
              summaryPages: ((value: number): unknown => {
                return value;
              })(value["summaryPages"]),
              summaryWords: ((value: number): unknown => {
                return value;
              })(value["summaryWords"]),
              tags: ((value: Tag[]): unknown => {
                return value.map(
                  (
                    value:
                      | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                      | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                      | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                      | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                      | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                      | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                      | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string },
                  ): unknown => {
                    switch (value.discriminator) {
                      case "dynamicString": {
                        return {
                          discriminator: "dynamicString",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindDynamicString; id: bigint; kind: string }): unknown => {
                            return {
                              discriminator: ((value: "dynamicString"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: string): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: string): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                      case "dynamicBoolean": {
                        return {
                          discriminator: "dynamicBoolean",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindDynamicBoolean; id: bigint; kind: string }): unknown => {
                            return {
                              discriminator: ((value: "dynamicBoolean"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: string): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: boolean): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                      case "dynamicNumber": {
                        return {
                          discriminator: "dynamicNumber",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindDynamicNumber; id: bigint; kind: string }): unknown => {
                            return {
                              discriminator: ((value: "dynamicNumber"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: string): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: number): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                      case "dynamicVoid": {
                        return {
                          discriminator: "dynamicVoid",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindDynamicVoid; id: bigint; kind: string }): unknown => {
                            return {
                              discriminator: ((value: "dynamicVoid"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: string): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                        };
                      }
                      case "channel": {
                        return {
                          discriminator: "channel",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindChannel; id: bigint; kind: KindChannel }): unknown => {
                            return {
                              discriminator: ((value: "channel"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: "channel"): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: string): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                      case "key-quality": {
                        return {
                          discriminator: "key-quality",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindKeyQuality; id: bigint; kind: KindKeyQuality }): unknown => {
                            return {
                              discriminator: ((value: "key-quality"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: "key-quality"): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: string): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                      case "classification": {
                        return {
                          discriminator: "classification",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindClassification; id: bigint; kind: KindClassification }): unknown => {
                            return {
                              discriminator: ((value: "classification"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: "classification"): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: string): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                    }
                  },
                );
              })(value["tags"]),
              teaser: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["teaser"]),
              title: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["title"]),
            };
          },
        );
      })(value["summaryList"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_SummaryCardDemoProps(str: string): SummaryCardDemoProps | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          summaryList: SummaryMetadata[];
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "summaryList")) {
      return { success: false };
    }
    const parsed_summaryList = (() => {
      return ((value: unknown): { success: false } | { success: true; value: SummaryMetadata[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: SummaryMetadata } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  activatedAt: DateTime<true> | null;
                  activationTime: DateTime<true> | null;
                  active: boolean;
                  audioActivatedAt: DateTime<true> | null;
                  audioActive: boolean;
                  audioDuration: number;
                  authorInfo: string | null;
                  authors: {
                    authorId: number;
                    firstName: string | null;
                    gender: ("MALE" | "FEMALE" | "CUSTOM" | "UNKNOWN") | null;
                    lastName: string | null;
                    middleName: string | null;
                  }[];
                  bestseller: boolean;
                  businessClassic: boolean;
                  canonicalName: string;
                  contentItemId: bigint;
                  contentItemType: "UNKNOWN" | "SUMMARY" | "CATEGORY" | "DISCUSSION" | "CHANNEL" | "LGXP" | "ACTIONABLE" | "MYLIST" | "CUSTOMPAGE";
                  countriesExcluded: Country[];
                  countriesIncluded: Country[];
                  coverModifiedAt: DateTime<true> | null;
                  coverUri: string;
                  dataId: number;
                  descriptionHtml: string | null;
                  documentType: "ABSTRACT" | "REVIEW";
                  duration: number;
                  imageBorder: boolean;
                  language: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr";
                  modifiedAt: DateTime<true> | null;
                  originalTitle: string | null;
                  previewAvailable: boolean;
                  publicationDate: string | null;
                  publisherInfo: string | null;
                  publisherName: string | null;
                  rating: number | null;
                  sourceType: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST";
                  subtitle: string | null;
                  summaryPages: number;
                  summaryWords: number;
                  tags: Tag[];
                  teaser: string | null;
                  title: string | null;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "activatedAt")) {
              return { success: false };
            }
            const parsed_activatedAt = (() => {
              return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "number") {
                  return { success: false };
                }
                const parsed = DateTime.fromMillis(value);
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: parsed };
              })(value["activatedAt"]);
            })();
            if (!parsed_activatedAt.success) {
              return { success: false };
            }
            if (!hasProp(value, "activationTime")) {
              return { success: false };
            }
            const parsed_activationTime = (() => {
              return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "number") {
                  return { success: false };
                }
                const parsed = DateTime.fromMillis(value);
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: parsed };
              })(value["activationTime"]);
            })();
            if (!parsed_activationTime.success) {
              return { success: false };
            }
            if (!hasProp(value, "active")) {
              return { success: false };
            }
            const parsed_active = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["active"]);
            })();
            if (!parsed_active.success) {
              return { success: false };
            }
            if (!hasProp(value, "audioActivatedAt")) {
              return { success: false };
            }
            const parsed_audioActivatedAt = (() => {
              return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "number") {
                  return { success: false };
                }
                const parsed = DateTime.fromMillis(value);
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: parsed };
              })(value["audioActivatedAt"]);
            })();
            if (!parsed_audioActivatedAt.success) {
              return { success: false };
            }
            if (!hasProp(value, "audioActive")) {
              return { success: false };
            }
            const parsed_audioActive = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["audioActive"]);
            })();
            if (!parsed_audioActive.success) {
              return { success: false };
            }
            if (!hasProp(value, "audioDuration")) {
              return { success: false };
            }
            const parsed_audioDuration = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["audioDuration"]);
            })();
            if (!parsed_audioDuration.success) {
              return { success: false };
            }
            if (!hasProp(value, "authorInfo")) {
              return { success: false };
            }
            const parsed_authorInfo = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["authorInfo"]);
            })();
            if (!parsed_authorInfo.success) {
              return { success: false };
            }
            if (!hasProp(value, "authors")) {
              return { success: false };
            }
            const parsed_authors = (() => {
              return ((
                value: unknown,
              ):
                | { success: false }
                | {
                    success: true;
                    value: {
                      authorId: number;
                      firstName: string | null;
                      gender: ("MALE" | "FEMALE" | "CUSTOM" | "UNKNOWN") | null;
                      lastName: string | null;
                      middleName: string | null;
                    }[];
                  } => {
                if (!isArray(value)) {
                  return { success: false };
                }
                const parsed = value.map(
                  (
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          authorId: number;
                          firstName: string | null;
                          gender: ("MALE" | "FEMALE" | "CUSTOM" | "UNKNOWN") | null;
                          lastName: string | null;
                          middleName: string | null;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "authorId")) {
                      return { success: false };
                    }
                    const parsed_authorId = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: number } => {
                        if (typeof value === "number") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["authorId"]);
                    })();
                    if (!parsed_authorId.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "firstName")) {
                      return { success: false };
                    }
                    const parsed_firstName = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                        if (value === null) {
                          return { success: true, value: null };
                        }
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["firstName"]);
                    })();
                    if (!parsed_firstName.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "gender")) {
                      return { success: false };
                    }
                    const parsed_gender = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: ("MALE" | "FEMALE" | "CUSTOM" | "UNKNOWN") | null } => {
                        if (value === null) {
                          return { success: true, value: null };
                        }
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "MALE":
                            return { success: true, value: "MALE" };
                          case "FEMALE":
                            return { success: true, value: "FEMALE" };
                          case "CUSTOM":
                            return { success: true, value: "CUSTOM" };
                          case "UNKNOWN":
                            return { success: true, value: "UNKNOWN" };
                          default:
                            return { success: false };
                        }
                      })(value["gender"]);
                    })();
                    if (!parsed_gender.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "lastName")) {
                      return { success: false };
                    }
                    const parsed_lastName = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                        if (value === null) {
                          return { success: true, value: null };
                        }
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["lastName"]);
                    })();
                    if (!parsed_lastName.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "middleName")) {
                      return { success: false };
                    }
                    const parsed_middleName = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                        if (value === null) {
                          return { success: true, value: null };
                        }
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["middleName"]);
                    })();
                    if (!parsed_middleName.success) {
                      return { success: false };
                    }
                    return { success: true, value: { authorId: parsed_authorId.value, firstName: parsed_firstName.value, gender: parsed_gender.value, lastName: parsed_lastName.value, middleName: parsed_middleName.value } };
                  },
                );
                if (parsed.find((value) => !value.success) !== undefined) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: parsed.map((entry) => {
                    assert(entry.success == true);
                    return entry.value;
                  }),
                };
              })(value["authors"]);
            })();
            if (!parsed_authors.success) {
              return { success: false };
            }
            if (!hasProp(value, "bestseller")) {
              return { success: false };
            }
            const parsed_bestseller = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["bestseller"]);
            })();
            if (!parsed_bestseller.success) {
              return { success: false };
            }
            if (!hasProp(value, "businessClassic")) {
              return { success: false };
            }
            const parsed_businessClassic = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["businessClassic"]);
            })();
            if (!parsed_businessClassic.success) {
              return { success: false };
            }
            if (!hasProp(value, "canonicalName")) {
              return { success: false };
            }
            const parsed_canonicalName = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["canonicalName"]);
            })();
            if (!parsed_canonicalName.success) {
              return { success: false };
            }
            if (!hasProp(value, "contentItemId")) {
              return { success: false };
            }
            const parsed_contentItemId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                if (typeof value === "number") {
                  return { success: true, value: BigInt(value) };
                }
                if (typeof value === "bigint") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["contentItemId"]);
            })();
            if (!parsed_contentItemId.success) {
              return { success: false };
            }
            if (!hasProp(value, "contentItemType")) {
              return { success: false };
            }
            const parsed_contentItemType = (() => {
              return ((value: unknown): { success: false } | { success: true; value: "UNKNOWN" | "SUMMARY" | "CATEGORY" | "DISCUSSION" | "CHANNEL" | "LGXP" | "ACTIONABLE" | "MYLIST" | "CUSTOMPAGE" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "UNKNOWN":
                    return { success: true, value: "UNKNOWN" };
                  case "SUMMARY":
                    return { success: true, value: "SUMMARY" };
                  case "CATEGORY":
                    return { success: true, value: "CATEGORY" };
                  case "DISCUSSION":
                    return { success: true, value: "DISCUSSION" };
                  case "CHANNEL":
                    return { success: true, value: "CHANNEL" };
                  case "LGXP":
                    return { success: true, value: "LGXP" };
                  case "ACTIONABLE":
                    return { success: true, value: "ACTIONABLE" };
                  case "MYLIST":
                    return { success: true, value: "MYLIST" };
                  case "CUSTOMPAGE":
                    return { success: true, value: "CUSTOMPAGE" };
                  default:
                    return { success: false };
                }
              })(value["contentItemType"]);
            })();
            if (!parsed_contentItemType.success) {
              return { success: false };
            }
            if (!hasProp(value, "countriesExcluded")) {
              return { success: false };
            }
            const parsed_countriesExcluded = (() => {
              return ((value: unknown): { success: false } | { success: true; value: Country[] } => {
                if (!isArray(value)) {
                  return { success: false };
                }
                const parsed = value.map((value: unknown): { success: false } | { success: true; value: Country } => {
                  return ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          countryCode: string;
                          currency: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY";
                          names: {
                            de: string;
                            en: string;
                            es: string;
                            fr: string;
                            pt: string;
                            ru: string;
                            zh: string;
                          };
                          phonePrefix: string;
                          region: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA";
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "countryCode")) {
                      return { success: false };
                    }
                    const parsed_countryCode = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["countryCode"]);
                    })();
                    if (!parsed_countryCode.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "currency")) {
                      return { success: false };
                    }
                    const parsed_currency = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "CHF":
                            return { success: true, value: "CHF" };
                          case "CNY":
                            return { success: true, value: "CNY" };
                          case "USD":
                            return { success: true, value: "USD" };
                          case "EUR":
                            return { success: true, value: "EUR" };
                          case "GBP":
                            return { success: true, value: "GBP" };
                          case "INR":
                            return { success: true, value: "INR" };
                          case "JPY":
                            return { success: true, value: "JPY" };
                          default:
                            return { success: false };
                        }
                      })(value["currency"]);
                    })();
                    if (!parsed_currency.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "names")) {
                      return { success: false };
                    }
                    const parsed_names = (() => {
                      return ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              de: string;
                              en: string;
                              es: string;
                              fr: string;
                              pt: string;
                              ru: string;
                              zh: string;
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "de")) {
                          return { success: false };
                        }
                        const parsed_de = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["de"]);
                        })();
                        if (!parsed_de.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "en")) {
                          return { success: false };
                        }
                        const parsed_en = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["en"]);
                        })();
                        if (!parsed_en.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "es")) {
                          return { success: false };
                        }
                        const parsed_es = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["es"]);
                        })();
                        if (!parsed_es.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "fr")) {
                          return { success: false };
                        }
                        const parsed_fr = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["fr"]);
                        })();
                        if (!parsed_fr.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "pt")) {
                          return { success: false };
                        }
                        const parsed_pt = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["pt"]);
                        })();
                        if (!parsed_pt.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "ru")) {
                          return { success: false };
                        }
                        const parsed_ru = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["ru"]);
                        })();
                        if (!parsed_ru.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "zh")) {
                          return { success: false };
                        }
                        const parsed_zh = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["zh"]);
                        })();
                        if (!parsed_zh.success) {
                          return { success: false };
                        }
                        return { success: true, value: { de: parsed_de.value, en: parsed_en.value, es: parsed_es.value, fr: parsed_fr.value, pt: parsed_pt.value, ru: parsed_ru.value, zh: parsed_zh.value } };
                      })(value["names"]);
                    })();
                    if (!parsed_names.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "phonePrefix")) {
                      return { success: false };
                    }
                    const parsed_phonePrefix = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["phonePrefix"]);
                    })();
                    if (!parsed_phonePrefix.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "region")) {
                      return { success: false };
                    }
                    const parsed_region = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "SOUTHAMERICA":
                            return { success: true, value: "SOUTHAMERICA" };
                          case "EUROPE":
                            return { success: true, value: "EUROPE" };
                          case "OCEANIA":
                            return { success: true, value: "OCEANIA" };
                          case "NORTHAMERICA":
                            return { success: true, value: "NORTHAMERICA" };
                          case "AFRICA":
                            return { success: true, value: "AFRICA" };
                          case "ANTARCTICA":
                            return { success: true, value: "ANTARCTICA" };
                          case "ASIA":
                            return { success: true, value: "ASIA" };
                          default:
                            return { success: false };
                        }
                      })(value["region"]);
                    })();
                    if (!parsed_region.success) {
                      return { success: false };
                    }
                    return { success: true, value: { countryCode: parsed_countryCode.value, currency: parsed_currency.value, names: parsed_names.value, phonePrefix: parsed_phonePrefix.value, region: parsed_region.value } };
                  })(value);
                });
                if (parsed.find((value) => !value.success) !== undefined) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: parsed.map((entry) => {
                    assert(entry.success == true);
                    return entry.value;
                  }),
                };
              })(value["countriesExcluded"]);
            })();
            if (!parsed_countriesExcluded.success) {
              return { success: false };
            }
            if (!hasProp(value, "countriesIncluded")) {
              return { success: false };
            }
            const parsed_countriesIncluded = (() => {
              return ((value: unknown): { success: false } | { success: true; value: Country[] } => {
                if (!isArray(value)) {
                  return { success: false };
                }
                const parsed = value.map((value: unknown): { success: false } | { success: true; value: Country } => {
                  return ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          countryCode: string;
                          currency: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY";
                          names: {
                            de: string;
                            en: string;
                            es: string;
                            fr: string;
                            pt: string;
                            ru: string;
                            zh: string;
                          };
                          phonePrefix: string;
                          region: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA";
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "countryCode")) {
                      return { success: false };
                    }
                    const parsed_countryCode = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["countryCode"]);
                    })();
                    if (!parsed_countryCode.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "currency")) {
                      return { success: false };
                    }
                    const parsed_currency = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "CHF":
                            return { success: true, value: "CHF" };
                          case "CNY":
                            return { success: true, value: "CNY" };
                          case "USD":
                            return { success: true, value: "USD" };
                          case "EUR":
                            return { success: true, value: "EUR" };
                          case "GBP":
                            return { success: true, value: "GBP" };
                          case "INR":
                            return { success: true, value: "INR" };
                          case "JPY":
                            return { success: true, value: "JPY" };
                          default:
                            return { success: false };
                        }
                      })(value["currency"]);
                    })();
                    if (!parsed_currency.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "names")) {
                      return { success: false };
                    }
                    const parsed_names = (() => {
                      return ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              de: string;
                              en: string;
                              es: string;
                              fr: string;
                              pt: string;
                              ru: string;
                              zh: string;
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "de")) {
                          return { success: false };
                        }
                        const parsed_de = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["de"]);
                        })();
                        if (!parsed_de.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "en")) {
                          return { success: false };
                        }
                        const parsed_en = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["en"]);
                        })();
                        if (!parsed_en.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "es")) {
                          return { success: false };
                        }
                        const parsed_es = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["es"]);
                        })();
                        if (!parsed_es.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "fr")) {
                          return { success: false };
                        }
                        const parsed_fr = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["fr"]);
                        })();
                        if (!parsed_fr.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "pt")) {
                          return { success: false };
                        }
                        const parsed_pt = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["pt"]);
                        })();
                        if (!parsed_pt.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "ru")) {
                          return { success: false };
                        }
                        const parsed_ru = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["ru"]);
                        })();
                        if (!parsed_ru.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "zh")) {
                          return { success: false };
                        }
                        const parsed_zh = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["zh"]);
                        })();
                        if (!parsed_zh.success) {
                          return { success: false };
                        }
                        return { success: true, value: { de: parsed_de.value, en: parsed_en.value, es: parsed_es.value, fr: parsed_fr.value, pt: parsed_pt.value, ru: parsed_ru.value, zh: parsed_zh.value } };
                      })(value["names"]);
                    })();
                    if (!parsed_names.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "phonePrefix")) {
                      return { success: false };
                    }
                    const parsed_phonePrefix = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["phonePrefix"]);
                    })();
                    if (!parsed_phonePrefix.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "region")) {
                      return { success: false };
                    }
                    const parsed_region = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "SOUTHAMERICA":
                            return { success: true, value: "SOUTHAMERICA" };
                          case "EUROPE":
                            return { success: true, value: "EUROPE" };
                          case "OCEANIA":
                            return { success: true, value: "OCEANIA" };
                          case "NORTHAMERICA":
                            return { success: true, value: "NORTHAMERICA" };
                          case "AFRICA":
                            return { success: true, value: "AFRICA" };
                          case "ANTARCTICA":
                            return { success: true, value: "ANTARCTICA" };
                          case "ASIA":
                            return { success: true, value: "ASIA" };
                          default:
                            return { success: false };
                        }
                      })(value["region"]);
                    })();
                    if (!parsed_region.success) {
                      return { success: false };
                    }
                    return { success: true, value: { countryCode: parsed_countryCode.value, currency: parsed_currency.value, names: parsed_names.value, phonePrefix: parsed_phonePrefix.value, region: parsed_region.value } };
                  })(value);
                });
                if (parsed.find((value) => !value.success) !== undefined) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: parsed.map((entry) => {
                    assert(entry.success == true);
                    return entry.value;
                  }),
                };
              })(value["countriesIncluded"]);
            })();
            if (!parsed_countriesIncluded.success) {
              return { success: false };
            }
            if (!hasProp(value, "coverModifiedAt")) {
              return { success: false };
            }
            const parsed_coverModifiedAt = (() => {
              return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "number") {
                  return { success: false };
                }
                const parsed = DateTime.fromMillis(value);
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: parsed };
              })(value["coverModifiedAt"]);
            })();
            if (!parsed_coverModifiedAt.success) {
              return { success: false };
            }
            if (!hasProp(value, "coverUri")) {
              return { success: false };
            }
            const parsed_coverUri = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["coverUri"]);
            })();
            if (!parsed_coverUri.success) {
              return { success: false };
            }
            if (!hasProp(value, "dataId")) {
              return { success: false };
            }
            const parsed_dataId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["dataId"]);
            })();
            if (!parsed_dataId.success) {
              return { success: false };
            }
            if (!hasProp(value, "descriptionHtml")) {
              return { success: false };
            }
            const parsed_descriptionHtml = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["descriptionHtml"]);
            })();
            if (!parsed_descriptionHtml.success) {
              return { success: false };
            }
            if (!hasProp(value, "documentType")) {
              return { success: false };
            }
            const parsed_documentType = (() => {
              return ((value: unknown): { success: false } | { success: true; value: "ABSTRACT" | "REVIEW" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "ABSTRACT":
                    return { success: true, value: "ABSTRACT" };
                  case "REVIEW":
                    return { success: true, value: "REVIEW" };
                  default:
                    return { success: false };
                }
              })(value["documentType"]);
            })();
            if (!parsed_documentType.success) {
              return { success: false };
            }
            if (!hasProp(value, "duration")) {
              return { success: false };
            }
            const parsed_duration = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["duration"]);
            })();
            if (!parsed_duration.success) {
              return { success: false };
            }
            if (!hasProp(value, "imageBorder")) {
              return { success: false };
            }
            const parsed_imageBorder = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["imageBorder"]);
            })();
            if (!parsed_imageBorder.success) {
              return { success: false };
            }
            if (!hasProp(value, "language")) {
              return { success: false };
            }
            const parsed_language = (() => {
              return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "en":
                    return { success: true, value: "en" };
                  case "de":
                    return { success: true, value: "de" };
                  case "es":
                    return { success: true, value: "es" };
                  case "ru":
                    return { success: true, value: "ru" };
                  case "zh":
                    return { success: true, value: "zh" };
                  case "pt":
                    return { success: true, value: "pt" };
                  case "fr":
                    return { success: true, value: "fr" };
                  default:
                    return { success: false };
                }
              })(value["language"]);
            })();
            if (!parsed_language.success) {
              return { success: false };
            }
            if (!hasProp(value, "modifiedAt")) {
              return { success: false };
            }
            const parsed_modifiedAt = (() => {
              return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "number") {
                  return { success: false };
                }
                const parsed = DateTime.fromMillis(value);
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: parsed };
              })(value["modifiedAt"]);
            })();
            if (!parsed_modifiedAt.success) {
              return { success: false };
            }
            if (!hasProp(value, "originalTitle")) {
              return { success: false };
            }
            const parsed_originalTitle = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["originalTitle"]);
            })();
            if (!parsed_originalTitle.success) {
              return { success: false };
            }
            if (!hasProp(value, "previewAvailable")) {
              return { success: false };
            }
            const parsed_previewAvailable = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["previewAvailable"]);
            })();
            if (!parsed_previewAvailable.success) {
              return { success: false };
            }
            if (!hasProp(value, "publicationDate")) {
              return { success: false };
            }
            const parsed_publicationDate = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["publicationDate"]);
            })();
            if (!parsed_publicationDate.success) {
              return { success: false };
            }
            if (!hasProp(value, "publisherInfo")) {
              return { success: false };
            }
            const parsed_publisherInfo = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["publisherInfo"]);
            })();
            if (!parsed_publisherInfo.success) {
              return { success: false };
            }
            if (!hasProp(value, "publisherName")) {
              return { success: false };
            }
            const parsed_publisherName = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["publisherName"]);
            })();
            if (!parsed_publisherName.success) {
              return { success: false };
            }
            if (!hasProp(value, "rating")) {
              return { success: false };
            }
            const parsed_rating = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["rating"]);
            })();
            if (!parsed_rating.success) {
              return { success: false };
            }
            if (!hasProp(value, "sourceType")) {
              return { success: false };
            }
            const parsed_sourceType = (() => {
              return ((value: unknown): { success: false } | { success: true; value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "BOOK":
                    return { success: true, value: "BOOK" };
                  case "VIDEO":
                    return { success: true, value: "VIDEO" };
                  case "ARTICLE":
                    return { success: true, value: "ARTICLE" };
                  case "CHAPTER":
                    return { success: true, value: "CHAPTER" };
                  case "REPORT":
                    return { success: true, value: "REPORT" };
                  case "PODCAST":
                    return { success: true, value: "PODCAST" };
                  default:
                    return { success: false };
                }
              })(value["sourceType"]);
            })();
            if (!parsed_sourceType.success) {
              return { success: false };
            }
            if (!hasProp(value, "subtitle")) {
              return { success: false };
            }
            const parsed_subtitle = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["subtitle"]);
            })();
            if (!parsed_subtitle.success) {
              return { success: false };
            }
            if (!hasProp(value, "summaryPages")) {
              return { success: false };
            }
            const parsed_summaryPages = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["summaryPages"]);
            })();
            if (!parsed_summaryPages.success) {
              return { success: false };
            }
            if (!hasProp(value, "summaryWords")) {
              return { success: false };
            }
            const parsed_summaryWords = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["summaryWords"]);
            })();
            if (!parsed_summaryWords.success) {
              return { success: false };
            }
            if (!hasProp(value, "tags")) {
              return { success: false };
            }
            const parsed_tags = (() => {
              return ((value: unknown): { success: false } | { success: true; value: Tag[] } => {
                if (!isArray(value)) {
                  return { success: false };
                }
                const parsed = value.map((value: unknown): { success: false } | { success: true; value: Tag } => {
                  return ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value:
                          | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                          | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                          | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                          | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                          | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                          | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                          | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                      } => {
                    return ((
                      value: unknown,
                    ):
                      | { success: false }
                      | {
                          success: true;
                          value:
                            | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                            | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                            | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                            | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                            | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                            | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                            | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                        } => {
                      if (value == null) {
                        return { success: false };
                      }
                      if (typeof value != "object") {
                        return { success: false };
                      }
                      if (!hasProp(value, "discriminator")) {
                        return { success: false };
                      }
                      if (typeof value["discriminator"] != "string") {
                        return { success: false };
                      }
                      const discriminator = value["discriminator"];
                      switch (discriminator) {
                        case "dynamicString": {
                          const parsedVariant = ((
                            value: unknown,
                          ):
                            | { success: false }
                            | {
                                success: true;
                                value: {
                                  exclude: boolean;
                                  source: TagSourceType;
                                  tagKind: TagKindDynamicString;
                                  value: string;
                                };
                              } => {
                            if (typeof value !== "object") {
                              return { success: false };
                            }
                            if (value === null) {
                              return { success: false };
                            }
                            if (!hasProp(value, "exclude")) {
                              return { success: false };
                            }
                            const parsed_exclude = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                if (typeof value !== "boolean") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["exclude"]);
                            })();
                            if (!parsed_exclude.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "source")) {
                              return { success: false };
                            }
                            const parsed_source = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  switch (value) {
                                    case "USER":
                                      return { success: true, value: "USER" };
                                    case "CHANNEL_HIERARCHY":
                                      return { success: true, value: "CHANNEL_HIERARCHY" };
                                    case "AI":
                                      return { success: true, value: "AI" };
                                    default:
                                      return { success: false };
                                  }
                                })(value);
                              })(value["source"]);
                            })();
                            if (!parsed_source.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "tagKind")) {
                              return { success: false };
                            }
                            const parsed_tagKind = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicString } => {
                                return ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        discriminator: KindDynamicString;
                                        id: bigint;
                                        kind: string;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "discriminator")) {
                                    return { success: false };
                                  }
                                  const parsed_discriminator = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindDynamicString } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "dynamicString" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "dynamicString":
                                            return { success: true, value: "dynamicString" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["discriminator"]);
                                  })();
                                  if (!parsed_discriminator.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "id")) {
                                    return { success: false };
                                  }
                                  const parsed_id = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                      if (typeof value === "number") {
                                        return { success: true, value: BigInt(value) };
                                      }
                                      if (typeof value === "bigint") {
                                        return { success: true, value: value };
                                      }
                                      return { success: false };
                                    })(value["id"]);
                                  })();
                                  if (!parsed_id.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "kind")) {
                                    return { success: false };
                                  }
                                  const parsed_kind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["kind"]);
                                  })();
                                  if (!parsed_kind.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                })(value);
                              })(value["tagKind"]);
                            })();
                            if (!parsed_tagKind.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "value")) {
                              return { success: false };
                            }
                            const parsed_value = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: string } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["value"]);
                            })();
                            if (!parsed_value.success) {
                              return { success: false };
                            }
                            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                          })(value);
                          if (!parsedVariant.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: "dynamicString" as const, ...parsedVariant.value } };
                        }
                        case "dynamicBoolean": {
                          const parsedVariant = ((
                            value: unknown,
                          ):
                            | { success: false }
                            | {
                                success: true;
                                value: {
                                  exclude: boolean;
                                  source: TagSourceType;
                                  tagKind: TagKindDynamicBoolean;
                                  value: boolean;
                                };
                              } => {
                            if (typeof value !== "object") {
                              return { success: false };
                            }
                            if (value === null) {
                              return { success: false };
                            }
                            if (!hasProp(value, "exclude")) {
                              return { success: false };
                            }
                            const parsed_exclude = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                if (typeof value !== "boolean") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["exclude"]);
                            })();
                            if (!parsed_exclude.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "source")) {
                              return { success: false };
                            }
                            const parsed_source = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  switch (value) {
                                    case "USER":
                                      return { success: true, value: "USER" };
                                    case "CHANNEL_HIERARCHY":
                                      return { success: true, value: "CHANNEL_HIERARCHY" };
                                    case "AI":
                                      return { success: true, value: "AI" };
                                    default:
                                      return { success: false };
                                  }
                                })(value);
                              })(value["source"]);
                            })();
                            if (!parsed_source.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "tagKind")) {
                              return { success: false };
                            }
                            const parsed_tagKind = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicBoolean } => {
                                return ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        discriminator: KindDynamicBoolean;
                                        id: bigint;
                                        kind: string;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "discriminator")) {
                                    return { success: false };
                                  }
                                  const parsed_discriminator = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindDynamicBoolean } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "dynamicBoolean" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "dynamicBoolean":
                                            return { success: true, value: "dynamicBoolean" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["discriminator"]);
                                  })();
                                  if (!parsed_discriminator.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "id")) {
                                    return { success: false };
                                  }
                                  const parsed_id = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                      if (typeof value === "number") {
                                        return { success: true, value: BigInt(value) };
                                      }
                                      if (typeof value === "bigint") {
                                        return { success: true, value: value };
                                      }
                                      return { success: false };
                                    })(value["id"]);
                                  })();
                                  if (!parsed_id.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "kind")) {
                                    return { success: false };
                                  }
                                  const parsed_kind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["kind"]);
                                  })();
                                  if (!parsed_kind.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                })(value);
                              })(value["tagKind"]);
                            })();
                            if (!parsed_tagKind.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "value")) {
                              return { success: false };
                            }
                            const parsed_value = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                if (typeof value !== "boolean") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["value"]);
                            })();
                            if (!parsed_value.success) {
                              return { success: false };
                            }
                            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                          })(value);
                          if (!parsedVariant.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: "dynamicBoolean" as const, ...parsedVariant.value } };
                        }
                        case "dynamicNumber": {
                          const parsedVariant = ((
                            value: unknown,
                          ):
                            | { success: false }
                            | {
                                success: true;
                                value: {
                                  exclude: boolean;
                                  source: TagSourceType;
                                  tagKind: TagKindDynamicNumber;
                                  value: number;
                                };
                              } => {
                            if (typeof value !== "object") {
                              return { success: false };
                            }
                            if (value === null) {
                              return { success: false };
                            }
                            if (!hasProp(value, "exclude")) {
                              return { success: false };
                            }
                            const parsed_exclude = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                if (typeof value !== "boolean") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["exclude"]);
                            })();
                            if (!parsed_exclude.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "source")) {
                              return { success: false };
                            }
                            const parsed_source = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  switch (value) {
                                    case "USER":
                                      return { success: true, value: "USER" };
                                    case "CHANNEL_HIERARCHY":
                                      return { success: true, value: "CHANNEL_HIERARCHY" };
                                    case "AI":
                                      return { success: true, value: "AI" };
                                    default:
                                      return { success: false };
                                  }
                                })(value);
                              })(value["source"]);
                            })();
                            if (!parsed_source.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "tagKind")) {
                              return { success: false };
                            }
                            const parsed_tagKind = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicNumber } => {
                                return ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        discriminator: KindDynamicNumber;
                                        id: bigint;
                                        kind: string;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "discriminator")) {
                                    return { success: false };
                                  }
                                  const parsed_discriminator = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindDynamicNumber } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "dynamicNumber" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "dynamicNumber":
                                            return { success: true, value: "dynamicNumber" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["discriminator"]);
                                  })();
                                  if (!parsed_discriminator.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "id")) {
                                    return { success: false };
                                  }
                                  const parsed_id = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                      if (typeof value === "number") {
                                        return { success: true, value: BigInt(value) };
                                      }
                                      if (typeof value === "bigint") {
                                        return { success: true, value: value };
                                      }
                                      return { success: false };
                                    })(value["id"]);
                                  })();
                                  if (!parsed_id.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "kind")) {
                                    return { success: false };
                                  }
                                  const parsed_kind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["kind"]);
                                  })();
                                  if (!parsed_kind.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                })(value);
                              })(value["tagKind"]);
                            })();
                            if (!parsed_tagKind.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "value")) {
                              return { success: false };
                            }
                            const parsed_value = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: number } => {
                                if (typeof value === "number") {
                                  return { success: true, value: value };
                                }
                                return { success: false };
                              })(value["value"]);
                            })();
                            if (!parsed_value.success) {
                              return { success: false };
                            }
                            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                          })(value);
                          if (!parsedVariant.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: "dynamicNumber" as const, ...parsedVariant.value } };
                        }
                        case "dynamicVoid": {
                          const parsedVariant = ((
                            value: unknown,
                          ):
                            | { success: false }
                            | {
                                success: true;
                                value: {
                                  exclude: boolean;
                                  source: TagSourceType;
                                  tagKind: TagKindDynamicVoid;
                                };
                              } => {
                            if (typeof value !== "object") {
                              return { success: false };
                            }
                            if (value === null) {
                              return { success: false };
                            }
                            if (!hasProp(value, "exclude")) {
                              return { success: false };
                            }
                            const parsed_exclude = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                if (typeof value !== "boolean") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["exclude"]);
                            })();
                            if (!parsed_exclude.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "source")) {
                              return { success: false };
                            }
                            const parsed_source = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  switch (value) {
                                    case "USER":
                                      return { success: true, value: "USER" };
                                    case "CHANNEL_HIERARCHY":
                                      return { success: true, value: "CHANNEL_HIERARCHY" };
                                    case "AI":
                                      return { success: true, value: "AI" };
                                    default:
                                      return { success: false };
                                  }
                                })(value);
                              })(value["source"]);
                            })();
                            if (!parsed_source.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "tagKind")) {
                              return { success: false };
                            }
                            const parsed_tagKind = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicVoid } => {
                                return ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        discriminator: KindDynamicVoid;
                                        id: bigint;
                                        kind: string;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "discriminator")) {
                                    return { success: false };
                                  }
                                  const parsed_discriminator = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindDynamicVoid } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "dynamicVoid" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "dynamicVoid":
                                            return { success: true, value: "dynamicVoid" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["discriminator"]);
                                  })();
                                  if (!parsed_discriminator.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "id")) {
                                    return { success: false };
                                  }
                                  const parsed_id = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                      if (typeof value === "number") {
                                        return { success: true, value: BigInt(value) };
                                      }
                                      if (typeof value === "bigint") {
                                        return { success: true, value: value };
                                      }
                                      return { success: false };
                                    })(value["id"]);
                                  })();
                                  if (!parsed_id.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "kind")) {
                                    return { success: false };
                                  }
                                  const parsed_kind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["kind"]);
                                  })();
                                  if (!parsed_kind.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                })(value);
                              })(value["tagKind"]);
                            })();
                            if (!parsed_tagKind.success) {
                              return { success: false };
                            }
                            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value } };
                          })(value);
                          if (!parsedVariant.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: "dynamicVoid" as const, ...parsedVariant.value } };
                        }
                        case "channel": {
                          const parsedVariant = ((
                            value: unknown,
                          ):
                            | { success: false }
                            | {
                                success: true;
                                value: {
                                  exclude: boolean;
                                  source: TagSourceType;
                                  tagKind: TagKindChannel;
                                  value: string;
                                };
                              } => {
                            if (typeof value !== "object") {
                              return { success: false };
                            }
                            if (value === null) {
                              return { success: false };
                            }
                            if (!hasProp(value, "exclude")) {
                              return { success: false };
                            }
                            const parsed_exclude = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                if (typeof value !== "boolean") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["exclude"]);
                            })();
                            if (!parsed_exclude.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "source")) {
                              return { success: false };
                            }
                            const parsed_source = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  switch (value) {
                                    case "USER":
                                      return { success: true, value: "USER" };
                                    case "CHANNEL_HIERARCHY":
                                      return { success: true, value: "CHANNEL_HIERARCHY" };
                                    case "AI":
                                      return { success: true, value: "AI" };
                                    default:
                                      return { success: false };
                                  }
                                })(value);
                              })(value["source"]);
                            })();
                            if (!parsed_source.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "tagKind")) {
                              return { success: false };
                            }
                            const parsed_tagKind = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagKindChannel } => {
                                return ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        discriminator: KindChannel;
                                        id: bigint;
                                        kind: KindChannel;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "discriminator")) {
                                    return { success: false };
                                  }
                                  const parsed_discriminator = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "channel":
                                            return { success: true, value: "channel" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["discriminator"]);
                                  })();
                                  if (!parsed_discriminator.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "id")) {
                                    return { success: false };
                                  }
                                  const parsed_id = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                      if (typeof value === "number") {
                                        return { success: true, value: BigInt(value) };
                                      }
                                      if (typeof value === "bigint") {
                                        return { success: true, value: value };
                                      }
                                      return { success: false };
                                    })(value["id"]);
                                  })();
                                  if (!parsed_id.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "kind")) {
                                    return { success: false };
                                  }
                                  const parsed_kind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "channel":
                                            return { success: true, value: "channel" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["kind"]);
                                  })();
                                  if (!parsed_kind.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                })(value);
                              })(value["tagKind"]);
                            })();
                            if (!parsed_tagKind.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "value")) {
                              return { success: false };
                            }
                            const parsed_value = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: string } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["value"]);
                            })();
                            if (!parsed_value.success) {
                              return { success: false };
                            }
                            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                          })(value);
                          if (!parsedVariant.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: "channel" as const, ...parsedVariant.value } };
                        }
                        case "key-quality": {
                          const parsedVariant = ((
                            value: unknown,
                          ):
                            | { success: false }
                            | {
                                success: true;
                                value: {
                                  exclude: boolean;
                                  source: TagSourceType;
                                  tagKind: TagKindKeyQuality;
                                  value: string;
                                };
                              } => {
                            if (typeof value !== "object") {
                              return { success: false };
                            }
                            if (value === null) {
                              return { success: false };
                            }
                            if (!hasProp(value, "exclude")) {
                              return { success: false };
                            }
                            const parsed_exclude = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                if (typeof value !== "boolean") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["exclude"]);
                            })();
                            if (!parsed_exclude.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "source")) {
                              return { success: false };
                            }
                            const parsed_source = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  switch (value) {
                                    case "USER":
                                      return { success: true, value: "USER" };
                                    case "CHANNEL_HIERARCHY":
                                      return { success: true, value: "CHANNEL_HIERARCHY" };
                                    case "AI":
                                      return { success: true, value: "AI" };
                                    default:
                                      return { success: false };
                                  }
                                })(value);
                              })(value["source"]);
                            })();
                            if (!parsed_source.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "tagKind")) {
                              return { success: false };
                            }
                            const parsed_tagKind = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagKindKeyQuality } => {
                                return ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        discriminator: KindKeyQuality;
                                        id: bigint;
                                        kind: KindKeyQuality;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "discriminator")) {
                                    return { success: false };
                                  }
                                  const parsed_discriminator = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "key-quality":
                                            return { success: true, value: "key-quality" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["discriminator"]);
                                  })();
                                  if (!parsed_discriminator.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "id")) {
                                    return { success: false };
                                  }
                                  const parsed_id = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                      if (typeof value === "number") {
                                        return { success: true, value: BigInt(value) };
                                      }
                                      if (typeof value === "bigint") {
                                        return { success: true, value: value };
                                      }
                                      return { success: false };
                                    })(value["id"]);
                                  })();
                                  if (!parsed_id.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "kind")) {
                                    return { success: false };
                                  }
                                  const parsed_kind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "key-quality":
                                            return { success: true, value: "key-quality" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["kind"]);
                                  })();
                                  if (!parsed_kind.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                })(value);
                              })(value["tagKind"]);
                            })();
                            if (!parsed_tagKind.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "value")) {
                              return { success: false };
                            }
                            const parsed_value = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: string } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["value"]);
                            })();
                            if (!parsed_value.success) {
                              return { success: false };
                            }
                            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                          })(value);
                          if (!parsedVariant.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: "key-quality" as const, ...parsedVariant.value } };
                        }
                        case "classification": {
                          const parsedVariant = ((
                            value: unknown,
                          ):
                            | { success: false }
                            | {
                                success: true;
                                value: {
                                  exclude: boolean;
                                  source: TagSourceType;
                                  tagKind: TagKindClassification;
                                  value: string;
                                };
                              } => {
                            if (typeof value !== "object") {
                              return { success: false };
                            }
                            if (value === null) {
                              return { success: false };
                            }
                            if (!hasProp(value, "exclude")) {
                              return { success: false };
                            }
                            const parsed_exclude = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                if (typeof value !== "boolean") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["exclude"]);
                            })();
                            if (!parsed_exclude.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "source")) {
                              return { success: false };
                            }
                            const parsed_source = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  switch (value) {
                                    case "USER":
                                      return { success: true, value: "USER" };
                                    case "CHANNEL_HIERARCHY":
                                      return { success: true, value: "CHANNEL_HIERARCHY" };
                                    case "AI":
                                      return { success: true, value: "AI" };
                                    default:
                                      return { success: false };
                                  }
                                })(value);
                              })(value["source"]);
                            })();
                            if (!parsed_source.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "tagKind")) {
                              return { success: false };
                            }
                            const parsed_tagKind = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagKindClassification } => {
                                return ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        discriminator: KindClassification;
                                        id: bigint;
                                        kind: KindClassification;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "discriminator")) {
                                    return { success: false };
                                  }
                                  const parsed_discriminator = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "classification":
                                            return { success: true, value: "classification" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["discriminator"]);
                                  })();
                                  if (!parsed_discriminator.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "id")) {
                                    return { success: false };
                                  }
                                  const parsed_id = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                      if (typeof value === "number") {
                                        return { success: true, value: BigInt(value) };
                                      }
                                      if (typeof value === "bigint") {
                                        return { success: true, value: value };
                                      }
                                      return { success: false };
                                    })(value["id"]);
                                  })();
                                  if (!parsed_id.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "kind")) {
                                    return { success: false };
                                  }
                                  const parsed_kind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "classification":
                                            return { success: true, value: "classification" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["kind"]);
                                  })();
                                  if (!parsed_kind.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                })(value);
                              })(value["tagKind"]);
                            })();
                            if (!parsed_tagKind.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "value")) {
                              return { success: false };
                            }
                            const parsed_value = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: string } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["value"]);
                            })();
                            if (!parsed_value.success) {
                              return { success: false };
                            }
                            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                          })(value);
                          if (!parsedVariant.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: "classification" as const, ...parsedVariant.value } };
                        }
                        default:
                          return { success: false };
                      }
                    })(value);
                  })(value);
                });
                if (parsed.find((value) => !value.success) !== undefined) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: parsed.map((entry) => {
                    assert(entry.success == true);
                    return entry.value;
                  }),
                };
              })(value["tags"]);
            })();
            if (!parsed_tags.success) {
              return { success: false };
            }
            if (!hasProp(value, "teaser")) {
              return { success: false };
            }
            const parsed_teaser = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["teaser"]);
            })();
            if (!parsed_teaser.success) {
              return { success: false };
            }
            if (!hasProp(value, "title")) {
              return { success: false };
            }
            const parsed_title = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["title"]);
            })();
            if (!parsed_title.success) {
              return { success: false };
            }
            return {
              success: true,
              value: {
                activatedAt: parsed_activatedAt.value,
                activationTime: parsed_activationTime.value,
                active: parsed_active.value,
                audioActivatedAt: parsed_audioActivatedAt.value,
                audioActive: parsed_audioActive.value,
                audioDuration: parsed_audioDuration.value,
                authorInfo: parsed_authorInfo.value,
                authors: parsed_authors.value,
                bestseller: parsed_bestseller.value,
                businessClassic: parsed_businessClassic.value,
                canonicalName: parsed_canonicalName.value,
                contentItemId: parsed_contentItemId.value,
                contentItemType: parsed_contentItemType.value,
                countriesExcluded: parsed_countriesExcluded.value,
                countriesIncluded: parsed_countriesIncluded.value,
                coverModifiedAt: parsed_coverModifiedAt.value,
                coverUri: parsed_coverUri.value,
                dataId: parsed_dataId.value,
                descriptionHtml: parsed_descriptionHtml.value,
                documentType: parsed_documentType.value,
                duration: parsed_duration.value,
                imageBorder: parsed_imageBorder.value,
                language: parsed_language.value,
                modifiedAt: parsed_modifiedAt.value,
                originalTitle: parsed_originalTitle.value,
                previewAvailable: parsed_previewAvailable.value,
                publicationDate: parsed_publicationDate.value,
                publisherInfo: parsed_publisherInfo.value,
                publisherName: parsed_publisherName.value,
                rating: parsed_rating.value,
                sourceType: parsed_sourceType.value,
                subtitle: parsed_subtitle.value,
                summaryPages: parsed_summaryPages.value,
                summaryWords: parsed_summaryWords.value,
                tags: parsed_tags.value,
                teaser: parsed_teaser.value,
                title: parsed_title.value,
              },
            };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["summaryList"]);
    })();
    if (!parsed_summaryList.success) {
      return { success: false };
    }
    return { success: true, value: { summaryList: parsed_summaryList.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// SummaryCoverInformation
export function serialize_SummaryCoverInformation(obj: SummaryCoverInformation): string {
  const serialized = ((value: { imageUri: string; summarySourceType: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST"; title: string | null }): unknown => {
    return {
      imageUri: ((value: string): unknown => {
        return value;
      })(value["imageUri"]),
      summarySourceType: ((value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST"): unknown => {
        return value;
      })(value["summarySourceType"]),
      title: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["title"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_SummaryCoverInformation(str: string): SummaryCoverInformation | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          imageUri: string;
          summarySourceType: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST";
          title: string | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "imageUri")) {
      return { success: false };
    }
    const parsed_imageUri = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["imageUri"]);
    })();
    if (!parsed_imageUri.success) {
      return { success: false };
    }
    if (!hasProp(value, "summarySourceType")) {
      return { success: false };
    }
    const parsed_summarySourceType = (() => {
      return ((value: unknown): { success: false } | { success: true; value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST" } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        switch (value) {
          case "BOOK":
            return { success: true, value: "BOOK" };
          case "VIDEO":
            return { success: true, value: "VIDEO" };
          case "ARTICLE":
            return { success: true, value: "ARTICLE" };
          case "CHAPTER":
            return { success: true, value: "CHAPTER" };
          case "REPORT":
            return { success: true, value: "REPORT" };
          case "PODCAST":
            return { success: true, value: "PODCAST" };
          default:
            return { success: false };
        }
      })(value["summarySourceType"]);
    })();
    if (!parsed_summarySourceType.success) {
      return { success: false };
    }
    if (!hasProp(value, "title")) {
      return { success: false };
    }
    const parsed_title = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["title"]);
    })();
    if (!parsed_title.success) {
      return { success: false };
    }
    return { success: true, value: { imageUri: parsed_imageUri.value, summarySourceType: parsed_summarySourceType.value, title: parsed_title.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// SummaryDocumentType
export function serialize_SummaryDocumentType(obj: SummaryDocumentType): string {
  const serialized = ((value: "ABSTRACT" | "REVIEW"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_SummaryDocumentType(str: string): SummaryDocumentType | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "ABSTRACT" | "REVIEW" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "ABSTRACT":
        return { success: true, value: "ABSTRACT" };
      case "REVIEW":
        return { success: true, value: "REVIEW" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// SummaryIxStatusView
export function serialize_SummaryIxStatusView(obj: SummaryIxStatusView): string {
  const serialized = ((value: { bookmarkedAt: DateTime<true> | null; consumedAt: DateTime<true> | null; customerId: number; dataId: number; likedAt: DateTime<true> | null; readingProgress: number }): unknown => {
    return {
      bookmarkedAt: ((value: DateTime<true> | null): unknown => {
        if (value === null) {
          return null;
        }
        return value.toMillis();
      })(value["bookmarkedAt"]),
      consumedAt: ((value: DateTime<true> | null): unknown => {
        if (value === null) {
          return null;
        }
        return value.toMillis();
      })(value["consumedAt"]),
      customerId: ((value: number): unknown => {
        return value;
      })(value["customerId"]),
      dataId: ((value: number): unknown => {
        return value;
      })(value["dataId"]),
      likedAt: ((value: DateTime<true> | null): unknown => {
        if (value === null) {
          return null;
        }
        return value.toMillis();
      })(value["likedAt"]),
      readingProgress: ((value: number): unknown => {
        return value;
      })(value["readingProgress"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_SummaryIxStatusView(str: string): SummaryIxStatusView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          bookmarkedAt: DateTime<true> | null;
          consumedAt: DateTime<true> | null;
          customerId: number;
          dataId: number;
          likedAt: DateTime<true> | null;
          readingProgress: number;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "bookmarkedAt")) {
      return { success: false };
    }
    const parsed_bookmarkedAt = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "number") {
          return { success: false };
        }
        const parsed = DateTime.fromMillis(value);
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["bookmarkedAt"]);
    })();
    if (!parsed_bookmarkedAt.success) {
      return { success: false };
    }
    if (!hasProp(value, "consumedAt")) {
      return { success: false };
    }
    const parsed_consumedAt = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "number") {
          return { success: false };
        }
        const parsed = DateTime.fromMillis(value);
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["consumedAt"]);
    })();
    if (!parsed_consumedAt.success) {
      return { success: false };
    }
    if (!hasProp(value, "customerId")) {
      return { success: false };
    }
    const parsed_customerId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["customerId"]);
    })();
    if (!parsed_customerId.success) {
      return { success: false };
    }
    if (!hasProp(value, "dataId")) {
      return { success: false };
    }
    const parsed_dataId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["dataId"]);
    })();
    if (!parsed_dataId.success) {
      return { success: false };
    }
    if (!hasProp(value, "likedAt")) {
      return { success: false };
    }
    const parsed_likedAt = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "number") {
          return { success: false };
        }
        const parsed = DateTime.fromMillis(value);
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["likedAt"]);
    })();
    if (!parsed_likedAt.success) {
      return { success: false };
    }
    if (!hasProp(value, "readingProgress")) {
      return { success: false };
    }
    const parsed_readingProgress = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        if (typeof value === "bigint") {
          return { success: true, value: Number(value) };
        }
        return { success: false };
      })(value["readingProgress"]);
    })();
    if (!parsed_readingProgress.success) {
      return { success: false };
    }
    return {
      success: true,
      value: { bookmarkedAt: parsed_bookmarkedAt.value, consumedAt: parsed_consumedAt.value, customerId: parsed_customerId.value, dataId: parsed_dataId.value, likedAt: parsed_likedAt.value, readingProgress: parsed_readingProgress.value },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// SummaryMetadata
export function serialize_SummaryMetadata(obj: SummaryMetadata): string {
  const serialized = ((value: {
    activatedAt: DateTime<true> | null;
    activationTime: DateTime<true> | null;
    active: boolean;
    audioActivatedAt: DateTime<true> | null;
    audioActive: boolean;
    audioDuration: number;
    authorInfo: string | null;
    authors: {
      authorId: number;
      firstName: string | null;
      gender: ("MALE" | "FEMALE" | "CUSTOM" | "UNKNOWN") | null;
      lastName: string | null;
      middleName: string | null;
    }[];
    bestseller: boolean;
    businessClassic: boolean;
    canonicalName: string;
    contentItemId: bigint;
    contentItemType: "UNKNOWN" | "SUMMARY" | "CATEGORY" | "DISCUSSION" | "CHANNEL" | "LGXP" | "ACTIONABLE" | "MYLIST" | "CUSTOMPAGE";
    countriesExcluded: Country[];
    countriesIncluded: Country[];
    coverModifiedAt: DateTime<true> | null;
    coverUri: string;
    dataId: number;
    descriptionHtml: string | null;
    documentType: "ABSTRACT" | "REVIEW";
    duration: number;
    imageBorder: boolean;
    language: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr";
    modifiedAt: DateTime<true> | null;
    originalTitle: string | null;
    previewAvailable: boolean;
    publicationDate: string | null;
    publisherInfo: string | null;
    publisherName: string | null;
    rating: number | null;
    sourceType: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST";
    subtitle: string | null;
    summaryPages: number;
    summaryWords: number;
    tags: Tag[];
    teaser: string | null;
    title: string | null;
  }): unknown => {
    return {
      activatedAt: ((value: DateTime<true> | null): unknown => {
        if (value === null) {
          return null;
        }
        return value.toMillis();
      })(value["activatedAt"]),
      activationTime: ((value: DateTime<true> | null): unknown => {
        if (value === null) {
          return null;
        }
        return value.toMillis();
      })(value["activationTime"]),
      active: ((value: boolean): unknown => {
        return value;
      })(value["active"]),
      audioActivatedAt: ((value: DateTime<true> | null): unknown => {
        if (value === null) {
          return null;
        }
        return value.toMillis();
      })(value["audioActivatedAt"]),
      audioActive: ((value: boolean): unknown => {
        return value;
      })(value["audioActive"]),
      audioDuration: ((value: number): unknown => {
        return value;
      })(value["audioDuration"]),
      authorInfo: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["authorInfo"]),
      authors: ((
        value: {
          authorId: number;
          firstName: string | null;
          gender: ("MALE" | "FEMALE" | "CUSTOM" | "UNKNOWN") | null;
          lastName: string | null;
          middleName: string | null;
        }[],
      ): unknown => {
        return value.map((value: { authorId: number; firstName: string | null; gender: ("MALE" | "FEMALE" | "CUSTOM" | "UNKNOWN") | null; lastName: string | null; middleName: string | null }): unknown => {
          return {
            authorId: ((value: number): unknown => {
              return value;
            })(value["authorId"]),
            firstName: ((value: string | null): unknown => {
              if (value === null) {
                return null;
              }
              return value;
            })(value["firstName"]),
            gender: ((value: ("MALE" | "FEMALE" | "CUSTOM" | "UNKNOWN") | null): unknown => {
              if (value === null) {
                return null;
              }
              return value;
            })(value["gender"]),
            lastName: ((value: string | null): unknown => {
              if (value === null) {
                return null;
              }
              return value;
            })(value["lastName"]),
            middleName: ((value: string | null): unknown => {
              if (value === null) {
                return null;
              }
              return value;
            })(value["middleName"]),
          };
        });
      })(value["authors"]),
      bestseller: ((value: boolean): unknown => {
        return value;
      })(value["bestseller"]),
      businessClassic: ((value: boolean): unknown => {
        return value;
      })(value["businessClassic"]),
      canonicalName: ((value: string): unknown => {
        return value;
      })(value["canonicalName"]),
      contentItemId: ((value: bigint): unknown => {
        return value;
      })(value["contentItemId"]),
      contentItemType: ((value: "UNKNOWN" | "SUMMARY" | "CATEGORY" | "DISCUSSION" | "CHANNEL" | "LGXP" | "ACTIONABLE" | "MYLIST" | "CUSTOMPAGE"): unknown => {
        return value;
      })(value["contentItemType"]),
      countriesExcluded: ((value: Country[]): unknown => {
        return value.map(
          (value: {
            countryCode: string;
            currency: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY";
            names: {
              de: string;
              en: string;
              es: string;
              fr: string;
              pt: string;
              ru: string;
              zh: string;
            };
            phonePrefix: string;
            region: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA";
          }): unknown => {
            return {
              countryCode: ((value: string): unknown => {
                return value;
              })(value["countryCode"]),
              currency: ((value: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY"): unknown => {
                return value;
              })(value["currency"]),
              names: ((value: { de: string; en: string; es: string; fr: string; pt: string; ru: string; zh: string }): unknown => {
                return {
                  de: ((value: string): unknown => {
                    return value;
                  })(value["de"]),
                  en: ((value: string): unknown => {
                    return value;
                  })(value["en"]),
                  es: ((value: string): unknown => {
                    return value;
                  })(value["es"]),
                  fr: ((value: string): unknown => {
                    return value;
                  })(value["fr"]),
                  pt: ((value: string): unknown => {
                    return value;
                  })(value["pt"]),
                  ru: ((value: string): unknown => {
                    return value;
                  })(value["ru"]),
                  zh: ((value: string): unknown => {
                    return value;
                  })(value["zh"]),
                };
              })(value["names"]),
              phonePrefix: ((value: string): unknown => {
                return value;
              })(value["phonePrefix"]),
              region: ((value: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA"): unknown => {
                return value;
              })(value["region"]),
            };
          },
        );
      })(value["countriesExcluded"]),
      countriesIncluded: ((value: Country[]): unknown => {
        return value.map(
          (value: {
            countryCode: string;
            currency: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY";
            names: {
              de: string;
              en: string;
              es: string;
              fr: string;
              pt: string;
              ru: string;
              zh: string;
            };
            phonePrefix: string;
            region: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA";
          }): unknown => {
            return {
              countryCode: ((value: string): unknown => {
                return value;
              })(value["countryCode"]),
              currency: ((value: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY"): unknown => {
                return value;
              })(value["currency"]),
              names: ((value: { de: string; en: string; es: string; fr: string; pt: string; ru: string; zh: string }): unknown => {
                return {
                  de: ((value: string): unknown => {
                    return value;
                  })(value["de"]),
                  en: ((value: string): unknown => {
                    return value;
                  })(value["en"]),
                  es: ((value: string): unknown => {
                    return value;
                  })(value["es"]),
                  fr: ((value: string): unknown => {
                    return value;
                  })(value["fr"]),
                  pt: ((value: string): unknown => {
                    return value;
                  })(value["pt"]),
                  ru: ((value: string): unknown => {
                    return value;
                  })(value["ru"]),
                  zh: ((value: string): unknown => {
                    return value;
                  })(value["zh"]),
                };
              })(value["names"]),
              phonePrefix: ((value: string): unknown => {
                return value;
              })(value["phonePrefix"]),
              region: ((value: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA"): unknown => {
                return value;
              })(value["region"]),
            };
          },
        );
      })(value["countriesIncluded"]),
      coverModifiedAt: ((value: DateTime<true> | null): unknown => {
        if (value === null) {
          return null;
        }
        return value.toMillis();
      })(value["coverModifiedAt"]),
      coverUri: ((value: string): unknown => {
        return value;
      })(value["coverUri"]),
      dataId: ((value: number): unknown => {
        return value;
      })(value["dataId"]),
      descriptionHtml: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["descriptionHtml"]),
      documentType: ((value: "ABSTRACT" | "REVIEW"): unknown => {
        return value;
      })(value["documentType"]),
      duration: ((value: number): unknown => {
        return value;
      })(value["duration"]),
      imageBorder: ((value: boolean): unknown => {
        return value;
      })(value["imageBorder"]),
      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
        return value;
      })(value["language"]),
      modifiedAt: ((value: DateTime<true> | null): unknown => {
        if (value === null) {
          return null;
        }
        return value.toMillis();
      })(value["modifiedAt"]),
      originalTitle: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["originalTitle"]),
      previewAvailable: ((value: boolean): unknown => {
        return value;
      })(value["previewAvailable"]),
      publicationDate: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["publicationDate"]),
      publisherInfo: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["publisherInfo"]),
      publisherName: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["publisherName"]),
      rating: ((value: number | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["rating"]),
      sourceType: ((value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST"): unknown => {
        return value;
      })(value["sourceType"]),
      subtitle: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["subtitle"]),
      summaryPages: ((value: number): unknown => {
        return value;
      })(value["summaryPages"]),
      summaryWords: ((value: number): unknown => {
        return value;
      })(value["summaryWords"]),
      tags: ((value: Tag[]): unknown => {
        return value.map(
          (
            value:
              | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
              | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
              | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
              | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
              | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
              | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
              | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string },
          ): unknown => {
            switch (value.discriminator) {
              case "dynamicString": {
                return {
                  discriminator: "dynamicString",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindDynamicString; id: bigint; kind: string }): unknown => {
                    return {
                      discriminator: ((value: "dynamicString"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: string): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: string): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
              case "dynamicBoolean": {
                return {
                  discriminator: "dynamicBoolean",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindDynamicBoolean; id: bigint; kind: string }): unknown => {
                    return {
                      discriminator: ((value: "dynamicBoolean"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: string): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: boolean): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
              case "dynamicNumber": {
                return {
                  discriminator: "dynamicNumber",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindDynamicNumber; id: bigint; kind: string }): unknown => {
                    return {
                      discriminator: ((value: "dynamicNumber"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: string): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: number): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
              case "dynamicVoid": {
                return {
                  discriminator: "dynamicVoid",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindDynamicVoid; id: bigint; kind: string }): unknown => {
                    return {
                      discriminator: ((value: "dynamicVoid"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: string): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                };
              }
              case "channel": {
                return {
                  discriminator: "channel",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindChannel; id: bigint; kind: KindChannel }): unknown => {
                    return {
                      discriminator: ((value: "channel"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: "channel"): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: string): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
              case "key-quality": {
                return {
                  discriminator: "key-quality",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindKeyQuality; id: bigint; kind: KindKeyQuality }): unknown => {
                    return {
                      discriminator: ((value: "key-quality"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: "key-quality"): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: string): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
              case "classification": {
                return {
                  discriminator: "classification",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindClassification; id: bigint; kind: KindClassification }): unknown => {
                    return {
                      discriminator: ((value: "classification"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: "classification"): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: string): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
            }
          },
        );
      })(value["tags"]),
      teaser: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["teaser"]),
      title: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["title"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_SummaryMetadata(str: string): SummaryMetadata | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          activatedAt: DateTime<true> | null;
          activationTime: DateTime<true> | null;
          active: boolean;
          audioActivatedAt: DateTime<true> | null;
          audioActive: boolean;
          audioDuration: number;
          authorInfo: string | null;
          authors: {
            authorId: number;
            firstName: string | null;
            gender: ("MALE" | "FEMALE" | "CUSTOM" | "UNKNOWN") | null;
            lastName: string | null;
            middleName: string | null;
          }[];
          bestseller: boolean;
          businessClassic: boolean;
          canonicalName: string;
          contentItemId: bigint;
          contentItemType: "UNKNOWN" | "SUMMARY" | "CATEGORY" | "DISCUSSION" | "CHANNEL" | "LGXP" | "ACTIONABLE" | "MYLIST" | "CUSTOMPAGE";
          countriesExcluded: Country[];
          countriesIncluded: Country[];
          coverModifiedAt: DateTime<true> | null;
          coverUri: string;
          dataId: number;
          descriptionHtml: string | null;
          documentType: "ABSTRACT" | "REVIEW";
          duration: number;
          imageBorder: boolean;
          language: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr";
          modifiedAt: DateTime<true> | null;
          originalTitle: string | null;
          previewAvailable: boolean;
          publicationDate: string | null;
          publisherInfo: string | null;
          publisherName: string | null;
          rating: number | null;
          sourceType: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST";
          subtitle: string | null;
          summaryPages: number;
          summaryWords: number;
          tags: Tag[];
          teaser: string | null;
          title: string | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "activatedAt")) {
      return { success: false };
    }
    const parsed_activatedAt = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "number") {
          return { success: false };
        }
        const parsed = DateTime.fromMillis(value);
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["activatedAt"]);
    })();
    if (!parsed_activatedAt.success) {
      return { success: false };
    }
    if (!hasProp(value, "activationTime")) {
      return { success: false };
    }
    const parsed_activationTime = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "number") {
          return { success: false };
        }
        const parsed = DateTime.fromMillis(value);
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["activationTime"]);
    })();
    if (!parsed_activationTime.success) {
      return { success: false };
    }
    if (!hasProp(value, "active")) {
      return { success: false };
    }
    const parsed_active = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["active"]);
    })();
    if (!parsed_active.success) {
      return { success: false };
    }
    if (!hasProp(value, "audioActivatedAt")) {
      return { success: false };
    }
    const parsed_audioActivatedAt = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "number") {
          return { success: false };
        }
        const parsed = DateTime.fromMillis(value);
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["audioActivatedAt"]);
    })();
    if (!parsed_audioActivatedAt.success) {
      return { success: false };
    }
    if (!hasProp(value, "audioActive")) {
      return { success: false };
    }
    const parsed_audioActive = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["audioActive"]);
    })();
    if (!parsed_audioActive.success) {
      return { success: false };
    }
    if (!hasProp(value, "audioDuration")) {
      return { success: false };
    }
    const parsed_audioDuration = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["audioDuration"]);
    })();
    if (!parsed_audioDuration.success) {
      return { success: false };
    }
    if (!hasProp(value, "authorInfo")) {
      return { success: false };
    }
    const parsed_authorInfo = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["authorInfo"]);
    })();
    if (!parsed_authorInfo.success) {
      return { success: false };
    }
    if (!hasProp(value, "authors")) {
      return { success: false };
    }
    const parsed_authors = (() => {
      return ((
        value: unknown,
      ):
        | { success: false }
        | {
            success: true;
            value: {
              authorId: number;
              firstName: string | null;
              gender: ("MALE" | "FEMALE" | "CUSTOM" | "UNKNOWN") | null;
              lastName: string | null;
              middleName: string | null;
            }[];
          } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map(
          (
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  authorId: number;
                  firstName: string | null;
                  gender: ("MALE" | "FEMALE" | "CUSTOM" | "UNKNOWN") | null;
                  lastName: string | null;
                  middleName: string | null;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "authorId")) {
              return { success: false };
            }
            const parsed_authorId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["authorId"]);
            })();
            if (!parsed_authorId.success) {
              return { success: false };
            }
            if (!hasProp(value, "firstName")) {
              return { success: false };
            }
            const parsed_firstName = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["firstName"]);
            })();
            if (!parsed_firstName.success) {
              return { success: false };
            }
            if (!hasProp(value, "gender")) {
              return { success: false };
            }
            const parsed_gender = (() => {
              return ((value: unknown): { success: false } | { success: true; value: ("MALE" | "FEMALE" | "CUSTOM" | "UNKNOWN") | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "MALE":
                    return { success: true, value: "MALE" };
                  case "FEMALE":
                    return { success: true, value: "FEMALE" };
                  case "CUSTOM":
                    return { success: true, value: "CUSTOM" };
                  case "UNKNOWN":
                    return { success: true, value: "UNKNOWN" };
                  default:
                    return { success: false };
                }
              })(value["gender"]);
            })();
            if (!parsed_gender.success) {
              return { success: false };
            }
            if (!hasProp(value, "lastName")) {
              return { success: false };
            }
            const parsed_lastName = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["lastName"]);
            })();
            if (!parsed_lastName.success) {
              return { success: false };
            }
            if (!hasProp(value, "middleName")) {
              return { success: false };
            }
            const parsed_middleName = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["middleName"]);
            })();
            if (!parsed_middleName.success) {
              return { success: false };
            }
            return { success: true, value: { authorId: parsed_authorId.value, firstName: parsed_firstName.value, gender: parsed_gender.value, lastName: parsed_lastName.value, middleName: parsed_middleName.value } };
          },
        );
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["authors"]);
    })();
    if (!parsed_authors.success) {
      return { success: false };
    }
    if (!hasProp(value, "bestseller")) {
      return { success: false };
    }
    const parsed_bestseller = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["bestseller"]);
    })();
    if (!parsed_bestseller.success) {
      return { success: false };
    }
    if (!hasProp(value, "businessClassic")) {
      return { success: false };
    }
    const parsed_businessClassic = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["businessClassic"]);
    })();
    if (!parsed_businessClassic.success) {
      return { success: false };
    }
    if (!hasProp(value, "canonicalName")) {
      return { success: false };
    }
    const parsed_canonicalName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["canonicalName"]);
    })();
    if (!parsed_canonicalName.success) {
      return { success: false };
    }
    if (!hasProp(value, "contentItemId")) {
      return { success: false };
    }
    const parsed_contentItemId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["contentItemId"]);
    })();
    if (!parsed_contentItemId.success) {
      return { success: false };
    }
    if (!hasProp(value, "contentItemType")) {
      return { success: false };
    }
    const parsed_contentItemType = (() => {
      return ((value: unknown): { success: false } | { success: true; value: "UNKNOWN" | "SUMMARY" | "CATEGORY" | "DISCUSSION" | "CHANNEL" | "LGXP" | "ACTIONABLE" | "MYLIST" | "CUSTOMPAGE" } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        switch (value) {
          case "UNKNOWN":
            return { success: true, value: "UNKNOWN" };
          case "SUMMARY":
            return { success: true, value: "SUMMARY" };
          case "CATEGORY":
            return { success: true, value: "CATEGORY" };
          case "DISCUSSION":
            return { success: true, value: "DISCUSSION" };
          case "CHANNEL":
            return { success: true, value: "CHANNEL" };
          case "LGXP":
            return { success: true, value: "LGXP" };
          case "ACTIONABLE":
            return { success: true, value: "ACTIONABLE" };
          case "MYLIST":
            return { success: true, value: "MYLIST" };
          case "CUSTOMPAGE":
            return { success: true, value: "CUSTOMPAGE" };
          default:
            return { success: false };
        }
      })(value["contentItemType"]);
    })();
    if (!parsed_contentItemType.success) {
      return { success: false };
    }
    if (!hasProp(value, "countriesExcluded")) {
      return { success: false };
    }
    const parsed_countriesExcluded = (() => {
      return ((value: unknown): { success: false } | { success: true; value: Country[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: Country } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  countryCode: string;
                  currency: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY";
                  names: {
                    de: string;
                    en: string;
                    es: string;
                    fr: string;
                    pt: string;
                    ru: string;
                    zh: string;
                  };
                  phonePrefix: string;
                  region: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA";
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "countryCode")) {
              return { success: false };
            }
            const parsed_countryCode = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["countryCode"]);
            })();
            if (!parsed_countryCode.success) {
              return { success: false };
            }
            if (!hasProp(value, "currency")) {
              return { success: false };
            }
            const parsed_currency = (() => {
              return ((value: unknown): { success: false } | { success: true; value: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "CHF":
                    return { success: true, value: "CHF" };
                  case "CNY":
                    return { success: true, value: "CNY" };
                  case "USD":
                    return { success: true, value: "USD" };
                  case "EUR":
                    return { success: true, value: "EUR" };
                  case "GBP":
                    return { success: true, value: "GBP" };
                  case "INR":
                    return { success: true, value: "INR" };
                  case "JPY":
                    return { success: true, value: "JPY" };
                  default:
                    return { success: false };
                }
              })(value["currency"]);
            })();
            if (!parsed_currency.success) {
              return { success: false };
            }
            if (!hasProp(value, "names")) {
              return { success: false };
            }
            const parsed_names = (() => {
              return ((
                value: unknown,
              ):
                | { success: false }
                | {
                    success: true;
                    value: {
                      de: string;
                      en: string;
                      es: string;
                      fr: string;
                      pt: string;
                      ru: string;
                      zh: string;
                    };
                  } => {
                if (typeof value !== "object") {
                  return { success: false };
                }
                if (value === null) {
                  return { success: false };
                }
                if (!hasProp(value, "de")) {
                  return { success: false };
                }
                const parsed_de = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["de"]);
                })();
                if (!parsed_de.success) {
                  return { success: false };
                }
                if (!hasProp(value, "en")) {
                  return { success: false };
                }
                const parsed_en = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["en"]);
                })();
                if (!parsed_en.success) {
                  return { success: false };
                }
                if (!hasProp(value, "es")) {
                  return { success: false };
                }
                const parsed_es = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["es"]);
                })();
                if (!parsed_es.success) {
                  return { success: false };
                }
                if (!hasProp(value, "fr")) {
                  return { success: false };
                }
                const parsed_fr = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["fr"]);
                })();
                if (!parsed_fr.success) {
                  return { success: false };
                }
                if (!hasProp(value, "pt")) {
                  return { success: false };
                }
                const parsed_pt = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["pt"]);
                })();
                if (!parsed_pt.success) {
                  return { success: false };
                }
                if (!hasProp(value, "ru")) {
                  return { success: false };
                }
                const parsed_ru = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["ru"]);
                })();
                if (!parsed_ru.success) {
                  return { success: false };
                }
                if (!hasProp(value, "zh")) {
                  return { success: false };
                }
                const parsed_zh = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["zh"]);
                })();
                if (!parsed_zh.success) {
                  return { success: false };
                }
                return { success: true, value: { de: parsed_de.value, en: parsed_en.value, es: parsed_es.value, fr: parsed_fr.value, pt: parsed_pt.value, ru: parsed_ru.value, zh: parsed_zh.value } };
              })(value["names"]);
            })();
            if (!parsed_names.success) {
              return { success: false };
            }
            if (!hasProp(value, "phonePrefix")) {
              return { success: false };
            }
            const parsed_phonePrefix = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["phonePrefix"]);
            })();
            if (!parsed_phonePrefix.success) {
              return { success: false };
            }
            if (!hasProp(value, "region")) {
              return { success: false };
            }
            const parsed_region = (() => {
              return ((value: unknown): { success: false } | { success: true; value: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "SOUTHAMERICA":
                    return { success: true, value: "SOUTHAMERICA" };
                  case "EUROPE":
                    return { success: true, value: "EUROPE" };
                  case "OCEANIA":
                    return { success: true, value: "OCEANIA" };
                  case "NORTHAMERICA":
                    return { success: true, value: "NORTHAMERICA" };
                  case "AFRICA":
                    return { success: true, value: "AFRICA" };
                  case "ANTARCTICA":
                    return { success: true, value: "ANTARCTICA" };
                  case "ASIA":
                    return { success: true, value: "ASIA" };
                  default:
                    return { success: false };
                }
              })(value["region"]);
            })();
            if (!parsed_region.success) {
              return { success: false };
            }
            return { success: true, value: { countryCode: parsed_countryCode.value, currency: parsed_currency.value, names: parsed_names.value, phonePrefix: parsed_phonePrefix.value, region: parsed_region.value } };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["countriesExcluded"]);
    })();
    if (!parsed_countriesExcluded.success) {
      return { success: false };
    }
    if (!hasProp(value, "countriesIncluded")) {
      return { success: false };
    }
    const parsed_countriesIncluded = (() => {
      return ((value: unknown): { success: false } | { success: true; value: Country[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: Country } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  countryCode: string;
                  currency: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY";
                  names: {
                    de: string;
                    en: string;
                    es: string;
                    fr: string;
                    pt: string;
                    ru: string;
                    zh: string;
                  };
                  phonePrefix: string;
                  region: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA";
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "countryCode")) {
              return { success: false };
            }
            const parsed_countryCode = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["countryCode"]);
            })();
            if (!parsed_countryCode.success) {
              return { success: false };
            }
            if (!hasProp(value, "currency")) {
              return { success: false };
            }
            const parsed_currency = (() => {
              return ((value: unknown): { success: false } | { success: true; value: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "CHF":
                    return { success: true, value: "CHF" };
                  case "CNY":
                    return { success: true, value: "CNY" };
                  case "USD":
                    return { success: true, value: "USD" };
                  case "EUR":
                    return { success: true, value: "EUR" };
                  case "GBP":
                    return { success: true, value: "GBP" };
                  case "INR":
                    return { success: true, value: "INR" };
                  case "JPY":
                    return { success: true, value: "JPY" };
                  default:
                    return { success: false };
                }
              })(value["currency"]);
            })();
            if (!parsed_currency.success) {
              return { success: false };
            }
            if (!hasProp(value, "names")) {
              return { success: false };
            }
            const parsed_names = (() => {
              return ((
                value: unknown,
              ):
                | { success: false }
                | {
                    success: true;
                    value: {
                      de: string;
                      en: string;
                      es: string;
                      fr: string;
                      pt: string;
                      ru: string;
                      zh: string;
                    };
                  } => {
                if (typeof value !== "object") {
                  return { success: false };
                }
                if (value === null) {
                  return { success: false };
                }
                if (!hasProp(value, "de")) {
                  return { success: false };
                }
                const parsed_de = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["de"]);
                })();
                if (!parsed_de.success) {
                  return { success: false };
                }
                if (!hasProp(value, "en")) {
                  return { success: false };
                }
                const parsed_en = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["en"]);
                })();
                if (!parsed_en.success) {
                  return { success: false };
                }
                if (!hasProp(value, "es")) {
                  return { success: false };
                }
                const parsed_es = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["es"]);
                })();
                if (!parsed_es.success) {
                  return { success: false };
                }
                if (!hasProp(value, "fr")) {
                  return { success: false };
                }
                const parsed_fr = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["fr"]);
                })();
                if (!parsed_fr.success) {
                  return { success: false };
                }
                if (!hasProp(value, "pt")) {
                  return { success: false };
                }
                const parsed_pt = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["pt"]);
                })();
                if (!parsed_pt.success) {
                  return { success: false };
                }
                if (!hasProp(value, "ru")) {
                  return { success: false };
                }
                const parsed_ru = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["ru"]);
                })();
                if (!parsed_ru.success) {
                  return { success: false };
                }
                if (!hasProp(value, "zh")) {
                  return { success: false };
                }
                const parsed_zh = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["zh"]);
                })();
                if (!parsed_zh.success) {
                  return { success: false };
                }
                return { success: true, value: { de: parsed_de.value, en: parsed_en.value, es: parsed_es.value, fr: parsed_fr.value, pt: parsed_pt.value, ru: parsed_ru.value, zh: parsed_zh.value } };
              })(value["names"]);
            })();
            if (!parsed_names.success) {
              return { success: false };
            }
            if (!hasProp(value, "phonePrefix")) {
              return { success: false };
            }
            const parsed_phonePrefix = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["phonePrefix"]);
            })();
            if (!parsed_phonePrefix.success) {
              return { success: false };
            }
            if (!hasProp(value, "region")) {
              return { success: false };
            }
            const parsed_region = (() => {
              return ((value: unknown): { success: false } | { success: true; value: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "SOUTHAMERICA":
                    return { success: true, value: "SOUTHAMERICA" };
                  case "EUROPE":
                    return { success: true, value: "EUROPE" };
                  case "OCEANIA":
                    return { success: true, value: "OCEANIA" };
                  case "NORTHAMERICA":
                    return { success: true, value: "NORTHAMERICA" };
                  case "AFRICA":
                    return { success: true, value: "AFRICA" };
                  case "ANTARCTICA":
                    return { success: true, value: "ANTARCTICA" };
                  case "ASIA":
                    return { success: true, value: "ASIA" };
                  default:
                    return { success: false };
                }
              })(value["region"]);
            })();
            if (!parsed_region.success) {
              return { success: false };
            }
            return { success: true, value: { countryCode: parsed_countryCode.value, currency: parsed_currency.value, names: parsed_names.value, phonePrefix: parsed_phonePrefix.value, region: parsed_region.value } };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["countriesIncluded"]);
    })();
    if (!parsed_countriesIncluded.success) {
      return { success: false };
    }
    if (!hasProp(value, "coverModifiedAt")) {
      return { success: false };
    }
    const parsed_coverModifiedAt = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "number") {
          return { success: false };
        }
        const parsed = DateTime.fromMillis(value);
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["coverModifiedAt"]);
    })();
    if (!parsed_coverModifiedAt.success) {
      return { success: false };
    }
    if (!hasProp(value, "coverUri")) {
      return { success: false };
    }
    const parsed_coverUri = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["coverUri"]);
    })();
    if (!parsed_coverUri.success) {
      return { success: false };
    }
    if (!hasProp(value, "dataId")) {
      return { success: false };
    }
    const parsed_dataId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["dataId"]);
    })();
    if (!parsed_dataId.success) {
      return { success: false };
    }
    if (!hasProp(value, "descriptionHtml")) {
      return { success: false };
    }
    const parsed_descriptionHtml = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["descriptionHtml"]);
    })();
    if (!parsed_descriptionHtml.success) {
      return { success: false };
    }
    if (!hasProp(value, "documentType")) {
      return { success: false };
    }
    const parsed_documentType = (() => {
      return ((value: unknown): { success: false } | { success: true; value: "ABSTRACT" | "REVIEW" } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        switch (value) {
          case "ABSTRACT":
            return { success: true, value: "ABSTRACT" };
          case "REVIEW":
            return { success: true, value: "REVIEW" };
          default:
            return { success: false };
        }
      })(value["documentType"]);
    })();
    if (!parsed_documentType.success) {
      return { success: false };
    }
    if (!hasProp(value, "duration")) {
      return { success: false };
    }
    const parsed_duration = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["duration"]);
    })();
    if (!parsed_duration.success) {
      return { success: false };
    }
    if (!hasProp(value, "imageBorder")) {
      return { success: false };
    }
    const parsed_imageBorder = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["imageBorder"]);
    })();
    if (!parsed_imageBorder.success) {
      return { success: false };
    }
    if (!hasProp(value, "language")) {
      return { success: false };
    }
    const parsed_language = (() => {
      return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        switch (value) {
          case "en":
            return { success: true, value: "en" };
          case "de":
            return { success: true, value: "de" };
          case "es":
            return { success: true, value: "es" };
          case "ru":
            return { success: true, value: "ru" };
          case "zh":
            return { success: true, value: "zh" };
          case "pt":
            return { success: true, value: "pt" };
          case "fr":
            return { success: true, value: "fr" };
          default:
            return { success: false };
        }
      })(value["language"]);
    })();
    if (!parsed_language.success) {
      return { success: false };
    }
    if (!hasProp(value, "modifiedAt")) {
      return { success: false };
    }
    const parsed_modifiedAt = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "number") {
          return { success: false };
        }
        const parsed = DateTime.fromMillis(value);
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["modifiedAt"]);
    })();
    if (!parsed_modifiedAt.success) {
      return { success: false };
    }
    if (!hasProp(value, "originalTitle")) {
      return { success: false };
    }
    const parsed_originalTitle = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["originalTitle"]);
    })();
    if (!parsed_originalTitle.success) {
      return { success: false };
    }
    if (!hasProp(value, "previewAvailable")) {
      return { success: false };
    }
    const parsed_previewAvailable = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["previewAvailable"]);
    })();
    if (!parsed_previewAvailable.success) {
      return { success: false };
    }
    if (!hasProp(value, "publicationDate")) {
      return { success: false };
    }
    const parsed_publicationDate = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["publicationDate"]);
    })();
    if (!parsed_publicationDate.success) {
      return { success: false };
    }
    if (!hasProp(value, "publisherInfo")) {
      return { success: false };
    }
    const parsed_publisherInfo = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["publisherInfo"]);
    })();
    if (!parsed_publisherInfo.success) {
      return { success: false };
    }
    if (!hasProp(value, "publisherName")) {
      return { success: false };
    }
    const parsed_publisherName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["publisherName"]);
    })();
    if (!parsed_publisherName.success) {
      return { success: false };
    }
    if (!hasProp(value, "rating")) {
      return { success: false };
    }
    const parsed_rating = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["rating"]);
    })();
    if (!parsed_rating.success) {
      return { success: false };
    }
    if (!hasProp(value, "sourceType")) {
      return { success: false };
    }
    const parsed_sourceType = (() => {
      return ((value: unknown): { success: false } | { success: true; value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST" } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        switch (value) {
          case "BOOK":
            return { success: true, value: "BOOK" };
          case "VIDEO":
            return { success: true, value: "VIDEO" };
          case "ARTICLE":
            return { success: true, value: "ARTICLE" };
          case "CHAPTER":
            return { success: true, value: "CHAPTER" };
          case "REPORT":
            return { success: true, value: "REPORT" };
          case "PODCAST":
            return { success: true, value: "PODCAST" };
          default:
            return { success: false };
        }
      })(value["sourceType"]);
    })();
    if (!parsed_sourceType.success) {
      return { success: false };
    }
    if (!hasProp(value, "subtitle")) {
      return { success: false };
    }
    const parsed_subtitle = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["subtitle"]);
    })();
    if (!parsed_subtitle.success) {
      return { success: false };
    }
    if (!hasProp(value, "summaryPages")) {
      return { success: false };
    }
    const parsed_summaryPages = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["summaryPages"]);
    })();
    if (!parsed_summaryPages.success) {
      return { success: false };
    }
    if (!hasProp(value, "summaryWords")) {
      return { success: false };
    }
    const parsed_summaryWords = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["summaryWords"]);
    })();
    if (!parsed_summaryWords.success) {
      return { success: false };
    }
    if (!hasProp(value, "tags")) {
      return { success: false };
    }
    const parsed_tags = (() => {
      return ((value: unknown): { success: false } | { success: true; value: Tag[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: Tag } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value:
                  | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                  | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                  | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                  | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                  | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                  | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                  | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
              } => {
            return ((
              value: unknown,
            ):
              | { success: false }
              | {
                  success: true;
                  value:
                    | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                    | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                    | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                    | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                    | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                    | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                    | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                } => {
              if (value == null) {
                return { success: false };
              }
              if (typeof value != "object") {
                return { success: false };
              }
              if (!hasProp(value, "discriminator")) {
                return { success: false };
              }
              if (typeof value["discriminator"] != "string") {
                return { success: false };
              }
              const discriminator = value["discriminator"];
              switch (discriminator) {
                case "dynamicString": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindDynamicString;
                          value: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "CHANNEL_HIERARCHY":
                              return { success: true, value: "CHANNEL_HIERARCHY" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicString } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindDynamicString;
                                id: bigint;
                                kind: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindDynamicString } => {
                              return ((value: unknown): { success: false } | { success: true; value: "dynamicString" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "dynamicString":
                                    return { success: true, value: "dynamicString" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "dynamicString" as const, ...parsedVariant.value } };
                }
                case "dynamicBoolean": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindDynamicBoolean;
                          value: boolean;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "CHANNEL_HIERARCHY":
                              return { success: true, value: "CHANNEL_HIERARCHY" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicBoolean } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindDynamicBoolean;
                                id: bigint;
                                kind: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindDynamicBoolean } => {
                              return ((value: unknown): { success: false } | { success: true; value: "dynamicBoolean" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "dynamicBoolean":
                                    return { success: true, value: "dynamicBoolean" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "dynamicBoolean" as const, ...parsedVariant.value } };
                }
                case "dynamicNumber": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindDynamicNumber;
                          value: number;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "CHANNEL_HIERARCHY":
                              return { success: true, value: "CHANNEL_HIERARCHY" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicNumber } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindDynamicNumber;
                                id: bigint;
                                kind: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindDynamicNumber } => {
                              return ((value: unknown): { success: false } | { success: true; value: "dynamicNumber" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "dynamicNumber":
                                    return { success: true, value: "dynamicNumber" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: number } => {
                        if (typeof value === "number") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "dynamicNumber" as const, ...parsedVariant.value } };
                }
                case "dynamicVoid": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindDynamicVoid;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "CHANNEL_HIERARCHY":
                              return { success: true, value: "CHANNEL_HIERARCHY" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicVoid } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindDynamicVoid;
                                id: bigint;
                                kind: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindDynamicVoid } => {
                              return ((value: unknown): { success: false } | { success: true; value: "dynamicVoid" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "dynamicVoid":
                                    return { success: true, value: "dynamicVoid" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "dynamicVoid" as const, ...parsedVariant.value } };
                }
                case "channel": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindChannel;
                          value: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "CHANNEL_HIERARCHY":
                              return { success: true, value: "CHANNEL_HIERARCHY" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindChannel } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindChannel;
                                id: bigint;
                                kind: KindChannel;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                              return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "channel":
                                    return { success: true, value: "channel" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                              return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "channel":
                                    return { success: true, value: "channel" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "channel" as const, ...parsedVariant.value } };
                }
                case "key-quality": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindKeyQuality;
                          value: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "CHANNEL_HIERARCHY":
                              return { success: true, value: "CHANNEL_HIERARCHY" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindKeyQuality } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindKeyQuality;
                                id: bigint;
                                kind: KindKeyQuality;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                              return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "key-quality":
                                    return { success: true, value: "key-quality" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                              return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "key-quality":
                                    return { success: true, value: "key-quality" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "key-quality" as const, ...parsedVariant.value } };
                }
                case "classification": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindClassification;
                          value: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "CHANNEL_HIERARCHY":
                              return { success: true, value: "CHANNEL_HIERARCHY" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindClassification } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindClassification;
                                id: bigint;
                                kind: KindClassification;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                              return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "classification":
                                    return { success: true, value: "classification" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                              return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "classification":
                                    return { success: true, value: "classification" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "classification" as const, ...parsedVariant.value } };
                }
                default:
                  return { success: false };
              }
            })(value);
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["tags"]);
    })();
    if (!parsed_tags.success) {
      return { success: false };
    }
    if (!hasProp(value, "teaser")) {
      return { success: false };
    }
    const parsed_teaser = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["teaser"]);
    })();
    if (!parsed_teaser.success) {
      return { success: false };
    }
    if (!hasProp(value, "title")) {
      return { success: false };
    }
    const parsed_title = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["title"]);
    })();
    if (!parsed_title.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        activatedAt: parsed_activatedAt.value,
        activationTime: parsed_activationTime.value,
        active: parsed_active.value,
        audioActivatedAt: parsed_audioActivatedAt.value,
        audioActive: parsed_audioActive.value,
        audioDuration: parsed_audioDuration.value,
        authorInfo: parsed_authorInfo.value,
        authors: parsed_authors.value,
        bestseller: parsed_bestseller.value,
        businessClassic: parsed_businessClassic.value,
        canonicalName: parsed_canonicalName.value,
        contentItemId: parsed_contentItemId.value,
        contentItemType: parsed_contentItemType.value,
        countriesExcluded: parsed_countriesExcluded.value,
        countriesIncluded: parsed_countriesIncluded.value,
        coverModifiedAt: parsed_coverModifiedAt.value,
        coverUri: parsed_coverUri.value,
        dataId: parsed_dataId.value,
        descriptionHtml: parsed_descriptionHtml.value,
        documentType: parsed_documentType.value,
        duration: parsed_duration.value,
        imageBorder: parsed_imageBorder.value,
        language: parsed_language.value,
        modifiedAt: parsed_modifiedAt.value,
        originalTitle: parsed_originalTitle.value,
        previewAvailable: parsed_previewAvailable.value,
        publicationDate: parsed_publicationDate.value,
        publisherInfo: parsed_publisherInfo.value,
        publisherName: parsed_publisherName.value,
        rating: parsed_rating.value,
        sourceType: parsed_sourceType.value,
        subtitle: parsed_subtitle.value,
        summaryPages: parsed_summaryPages.value,
        summaryWords: parsed_summaryWords.value,
        tags: parsed_tags.value,
        teaser: parsed_teaser.value,
        title: parsed_title.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// SummarySourceType
export function serialize_SummarySourceType(obj: SummarySourceType): string {
  const serialized = ((value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_SummarySourceType(str: string): SummarySourceType | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "BOOK":
        return { success: true, value: "BOOK" };
      case "VIDEO":
        return { success: true, value: "VIDEO" };
      case "ARTICLE":
        return { success: true, value: "ARTICLE" };
      case "CHAPTER":
        return { success: true, value: "CHAPTER" };
      case "REPORT":
        return { success: true, value: "REPORT" };
      case "PODCAST":
        return { success: true, value: "PODCAST" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// Tag
export function serialize_Tag(obj: Tag): string {
  const serialized = ((
    value:
      | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
      | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
      | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
      | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
      | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
      | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
      | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string },
  ): unknown => {
    switch (value.discriminator) {
      case "dynamicString": {
        return {
          discriminator: "dynamicString",
          exclude: ((value: boolean): unknown => {
            return value;
          })(value["exclude"]),
          source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
            return value;
          })(value["source"]),
          tagKind: ((value: { discriminator: KindDynamicString; id: bigint; kind: string }): unknown => {
            return {
              discriminator: ((value: "dynamicString"): unknown => {
                return value;
              })(value["discriminator"]),
              id: ((value: bigint): unknown => {
                return value;
              })(value["id"]),
              kind: ((value: string): unknown => {
                return value;
              })(value["kind"]),
            };
          })(value["tagKind"]),
          value: ((value: string): unknown => {
            return value;
          })(value["value"]),
        };
      }
      case "dynamicBoolean": {
        return {
          discriminator: "dynamicBoolean",
          exclude: ((value: boolean): unknown => {
            return value;
          })(value["exclude"]),
          source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
            return value;
          })(value["source"]),
          tagKind: ((value: { discriminator: KindDynamicBoolean; id: bigint; kind: string }): unknown => {
            return {
              discriminator: ((value: "dynamicBoolean"): unknown => {
                return value;
              })(value["discriminator"]),
              id: ((value: bigint): unknown => {
                return value;
              })(value["id"]),
              kind: ((value: string): unknown => {
                return value;
              })(value["kind"]),
            };
          })(value["tagKind"]),
          value: ((value: boolean): unknown => {
            return value;
          })(value["value"]),
        };
      }
      case "dynamicNumber": {
        return {
          discriminator: "dynamicNumber",
          exclude: ((value: boolean): unknown => {
            return value;
          })(value["exclude"]),
          source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
            return value;
          })(value["source"]),
          tagKind: ((value: { discriminator: KindDynamicNumber; id: bigint; kind: string }): unknown => {
            return {
              discriminator: ((value: "dynamicNumber"): unknown => {
                return value;
              })(value["discriminator"]),
              id: ((value: bigint): unknown => {
                return value;
              })(value["id"]),
              kind: ((value: string): unknown => {
                return value;
              })(value["kind"]),
            };
          })(value["tagKind"]),
          value: ((value: number): unknown => {
            return value;
          })(value["value"]),
        };
      }
      case "dynamicVoid": {
        return {
          discriminator: "dynamicVoid",
          exclude: ((value: boolean): unknown => {
            return value;
          })(value["exclude"]),
          source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
            return value;
          })(value["source"]),
          tagKind: ((value: { discriminator: KindDynamicVoid; id: bigint; kind: string }): unknown => {
            return {
              discriminator: ((value: "dynamicVoid"): unknown => {
                return value;
              })(value["discriminator"]),
              id: ((value: bigint): unknown => {
                return value;
              })(value["id"]),
              kind: ((value: string): unknown => {
                return value;
              })(value["kind"]),
            };
          })(value["tagKind"]),
        };
      }
      case "channel": {
        return {
          discriminator: "channel",
          exclude: ((value: boolean): unknown => {
            return value;
          })(value["exclude"]),
          source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
            return value;
          })(value["source"]),
          tagKind: ((value: { discriminator: KindChannel; id: bigint; kind: KindChannel }): unknown => {
            return {
              discriminator: ((value: "channel"): unknown => {
                return value;
              })(value["discriminator"]),
              id: ((value: bigint): unknown => {
                return value;
              })(value["id"]),
              kind: ((value: "channel"): unknown => {
                return value;
              })(value["kind"]),
            };
          })(value["tagKind"]),
          value: ((value: string): unknown => {
            return value;
          })(value["value"]),
        };
      }
      case "key-quality": {
        return {
          discriminator: "key-quality",
          exclude: ((value: boolean): unknown => {
            return value;
          })(value["exclude"]),
          source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
            return value;
          })(value["source"]),
          tagKind: ((value: { discriminator: KindKeyQuality; id: bigint; kind: KindKeyQuality }): unknown => {
            return {
              discriminator: ((value: "key-quality"): unknown => {
                return value;
              })(value["discriminator"]),
              id: ((value: bigint): unknown => {
                return value;
              })(value["id"]),
              kind: ((value: "key-quality"): unknown => {
                return value;
              })(value["kind"]),
            };
          })(value["tagKind"]),
          value: ((value: string): unknown => {
            return value;
          })(value["value"]),
        };
      }
      case "classification": {
        return {
          discriminator: "classification",
          exclude: ((value: boolean): unknown => {
            return value;
          })(value["exclude"]),
          source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
            return value;
          })(value["source"]),
          tagKind: ((value: { discriminator: KindClassification; id: bigint; kind: KindClassification }): unknown => {
            return {
              discriminator: ((value: "classification"): unknown => {
                return value;
              })(value["discriminator"]),
              id: ((value: bigint): unknown => {
                return value;
              })(value["id"]),
              kind: ((value: "classification"): unknown => {
                return value;
              })(value["kind"]),
            };
          })(value["tagKind"]),
          value: ((value: string): unknown => {
            return value;
          })(value["value"]),
        };
      }
    }
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_Tag(str: string): Tag | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value:
          | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
          | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
          | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
          | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
          | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
          | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
          | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
      } => {
    return ((
      value: unknown,
    ):
      | { success: false }
      | {
          success: true;
          value:
            | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
            | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
            | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
            | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
            | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
            | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
            | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
        } => {
      if (value == null) {
        return { success: false };
      }
      if (typeof value != "object") {
        return { success: false };
      }
      if (!hasProp(value, "discriminator")) {
        return { success: false };
      }
      if (typeof value["discriminator"] != "string") {
        return { success: false };
      }
      const discriminator = value["discriminator"];
      switch (discriminator) {
        case "dynamicString": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  exclude: boolean;
                  source: TagSourceType;
                  tagKind: TagKindDynamicString;
                  value: string;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "exclude")) {
              return { success: false };
            }
            const parsed_exclude = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["exclude"]);
            })();
            if (!parsed_exclude.success) {
              return { success: false };
            }
            if (!hasProp(value, "source")) {
              return { success: false };
            }
            const parsed_source = (() => {
              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "USER":
                      return { success: true, value: "USER" };
                    case "CHANNEL_HIERARCHY":
                      return { success: true, value: "CHANNEL_HIERARCHY" };
                    case "AI":
                      return { success: true, value: "AI" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["source"]);
            })();
            if (!parsed_source.success) {
              return { success: false };
            }
            if (!hasProp(value, "tagKind")) {
              return { success: false };
            }
            const parsed_tagKind = (() => {
              return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicString } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        discriminator: KindDynamicString;
                        id: bigint;
                        kind: string;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "discriminator")) {
                    return { success: false };
                  }
                  const parsed_discriminator = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: KindDynamicString } => {
                      return ((value: unknown): { success: false } | { success: true; value: "dynamicString" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "dynamicString":
                            return { success: true, value: "dynamicString" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["discriminator"]);
                  })();
                  if (!parsed_discriminator.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "id")) {
                    return { success: false };
                  }
                  const parsed_id = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                      if (typeof value === "number") {
                        return { success: true, value: BigInt(value) };
                      }
                      if (typeof value === "bigint") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["id"]);
                  })();
                  if (!parsed_id.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "kind")) {
                    return { success: false };
                  }
                  const parsed_kind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["kind"]);
                  })();
                  if (!parsed_kind.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                })(value);
              })(value["tagKind"]);
            })();
            if (!parsed_tagKind.success) {
              return { success: false };
            }
            if (!hasProp(value, "value")) {
              return { success: false };
            }
            const parsed_value = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["value"]);
            })();
            if (!parsed_value.success) {
              return { success: false };
            }
            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { discriminator: "dynamicString" as const, ...parsedVariant.value } };
        }
        case "dynamicBoolean": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  exclude: boolean;
                  source: TagSourceType;
                  tagKind: TagKindDynamicBoolean;
                  value: boolean;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "exclude")) {
              return { success: false };
            }
            const parsed_exclude = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["exclude"]);
            })();
            if (!parsed_exclude.success) {
              return { success: false };
            }
            if (!hasProp(value, "source")) {
              return { success: false };
            }
            const parsed_source = (() => {
              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "USER":
                      return { success: true, value: "USER" };
                    case "CHANNEL_HIERARCHY":
                      return { success: true, value: "CHANNEL_HIERARCHY" };
                    case "AI":
                      return { success: true, value: "AI" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["source"]);
            })();
            if (!parsed_source.success) {
              return { success: false };
            }
            if (!hasProp(value, "tagKind")) {
              return { success: false };
            }
            const parsed_tagKind = (() => {
              return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicBoolean } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        discriminator: KindDynamicBoolean;
                        id: bigint;
                        kind: string;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "discriminator")) {
                    return { success: false };
                  }
                  const parsed_discriminator = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: KindDynamicBoolean } => {
                      return ((value: unknown): { success: false } | { success: true; value: "dynamicBoolean" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "dynamicBoolean":
                            return { success: true, value: "dynamicBoolean" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["discriminator"]);
                  })();
                  if (!parsed_discriminator.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "id")) {
                    return { success: false };
                  }
                  const parsed_id = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                      if (typeof value === "number") {
                        return { success: true, value: BigInt(value) };
                      }
                      if (typeof value === "bigint") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["id"]);
                  })();
                  if (!parsed_id.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "kind")) {
                    return { success: false };
                  }
                  const parsed_kind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["kind"]);
                  })();
                  if (!parsed_kind.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                })(value);
              })(value["tagKind"]);
            })();
            if (!parsed_tagKind.success) {
              return { success: false };
            }
            if (!hasProp(value, "value")) {
              return { success: false };
            }
            const parsed_value = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["value"]);
            })();
            if (!parsed_value.success) {
              return { success: false };
            }
            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { discriminator: "dynamicBoolean" as const, ...parsedVariant.value } };
        }
        case "dynamicNumber": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  exclude: boolean;
                  source: TagSourceType;
                  tagKind: TagKindDynamicNumber;
                  value: number;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "exclude")) {
              return { success: false };
            }
            const parsed_exclude = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["exclude"]);
            })();
            if (!parsed_exclude.success) {
              return { success: false };
            }
            if (!hasProp(value, "source")) {
              return { success: false };
            }
            const parsed_source = (() => {
              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "USER":
                      return { success: true, value: "USER" };
                    case "CHANNEL_HIERARCHY":
                      return { success: true, value: "CHANNEL_HIERARCHY" };
                    case "AI":
                      return { success: true, value: "AI" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["source"]);
            })();
            if (!parsed_source.success) {
              return { success: false };
            }
            if (!hasProp(value, "tagKind")) {
              return { success: false };
            }
            const parsed_tagKind = (() => {
              return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicNumber } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        discriminator: KindDynamicNumber;
                        id: bigint;
                        kind: string;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "discriminator")) {
                    return { success: false };
                  }
                  const parsed_discriminator = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: KindDynamicNumber } => {
                      return ((value: unknown): { success: false } | { success: true; value: "dynamicNumber" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "dynamicNumber":
                            return { success: true, value: "dynamicNumber" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["discriminator"]);
                  })();
                  if (!parsed_discriminator.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "id")) {
                    return { success: false };
                  }
                  const parsed_id = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                      if (typeof value === "number") {
                        return { success: true, value: BigInt(value) };
                      }
                      if (typeof value === "bigint") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["id"]);
                  })();
                  if (!parsed_id.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "kind")) {
                    return { success: false };
                  }
                  const parsed_kind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["kind"]);
                  })();
                  if (!parsed_kind.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                })(value);
              })(value["tagKind"]);
            })();
            if (!parsed_tagKind.success) {
              return { success: false };
            }
            if (!hasProp(value, "value")) {
              return { success: false };
            }
            const parsed_value = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["value"]);
            })();
            if (!parsed_value.success) {
              return { success: false };
            }
            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { discriminator: "dynamicNumber" as const, ...parsedVariant.value } };
        }
        case "dynamicVoid": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  exclude: boolean;
                  source: TagSourceType;
                  tagKind: TagKindDynamicVoid;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "exclude")) {
              return { success: false };
            }
            const parsed_exclude = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["exclude"]);
            })();
            if (!parsed_exclude.success) {
              return { success: false };
            }
            if (!hasProp(value, "source")) {
              return { success: false };
            }
            const parsed_source = (() => {
              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "USER":
                      return { success: true, value: "USER" };
                    case "CHANNEL_HIERARCHY":
                      return { success: true, value: "CHANNEL_HIERARCHY" };
                    case "AI":
                      return { success: true, value: "AI" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["source"]);
            })();
            if (!parsed_source.success) {
              return { success: false };
            }
            if (!hasProp(value, "tagKind")) {
              return { success: false };
            }
            const parsed_tagKind = (() => {
              return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicVoid } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        discriminator: KindDynamicVoid;
                        id: bigint;
                        kind: string;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "discriminator")) {
                    return { success: false };
                  }
                  const parsed_discriminator = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: KindDynamicVoid } => {
                      return ((value: unknown): { success: false } | { success: true; value: "dynamicVoid" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "dynamicVoid":
                            return { success: true, value: "dynamicVoid" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["discriminator"]);
                  })();
                  if (!parsed_discriminator.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "id")) {
                    return { success: false };
                  }
                  const parsed_id = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                      if (typeof value === "number") {
                        return { success: true, value: BigInt(value) };
                      }
                      if (typeof value === "bigint") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["id"]);
                  })();
                  if (!parsed_id.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "kind")) {
                    return { success: false };
                  }
                  const parsed_kind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["kind"]);
                  })();
                  if (!parsed_kind.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                })(value);
              })(value["tagKind"]);
            })();
            if (!parsed_tagKind.success) {
              return { success: false };
            }
            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { discriminator: "dynamicVoid" as const, ...parsedVariant.value } };
        }
        case "channel": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  exclude: boolean;
                  source: TagSourceType;
                  tagKind: TagKindChannel;
                  value: string;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "exclude")) {
              return { success: false };
            }
            const parsed_exclude = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["exclude"]);
            })();
            if (!parsed_exclude.success) {
              return { success: false };
            }
            if (!hasProp(value, "source")) {
              return { success: false };
            }
            const parsed_source = (() => {
              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "USER":
                      return { success: true, value: "USER" };
                    case "CHANNEL_HIERARCHY":
                      return { success: true, value: "CHANNEL_HIERARCHY" };
                    case "AI":
                      return { success: true, value: "AI" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["source"]);
            })();
            if (!parsed_source.success) {
              return { success: false };
            }
            if (!hasProp(value, "tagKind")) {
              return { success: false };
            }
            const parsed_tagKind = (() => {
              return ((value: unknown): { success: false } | { success: true; value: TagKindChannel } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        discriminator: KindChannel;
                        id: bigint;
                        kind: KindChannel;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "discriminator")) {
                    return { success: false };
                  }
                  const parsed_discriminator = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                      return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "channel":
                            return { success: true, value: "channel" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["discriminator"]);
                  })();
                  if (!parsed_discriminator.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "id")) {
                    return { success: false };
                  }
                  const parsed_id = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                      if (typeof value === "number") {
                        return { success: true, value: BigInt(value) };
                      }
                      if (typeof value === "bigint") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["id"]);
                  })();
                  if (!parsed_id.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "kind")) {
                    return { success: false };
                  }
                  const parsed_kind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                      return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "channel":
                            return { success: true, value: "channel" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["kind"]);
                  })();
                  if (!parsed_kind.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                })(value);
              })(value["tagKind"]);
            })();
            if (!parsed_tagKind.success) {
              return { success: false };
            }
            if (!hasProp(value, "value")) {
              return { success: false };
            }
            const parsed_value = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["value"]);
            })();
            if (!parsed_value.success) {
              return { success: false };
            }
            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { discriminator: "channel" as const, ...parsedVariant.value } };
        }
        case "key-quality": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  exclude: boolean;
                  source: TagSourceType;
                  tagKind: TagKindKeyQuality;
                  value: string;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "exclude")) {
              return { success: false };
            }
            const parsed_exclude = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["exclude"]);
            })();
            if (!parsed_exclude.success) {
              return { success: false };
            }
            if (!hasProp(value, "source")) {
              return { success: false };
            }
            const parsed_source = (() => {
              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "USER":
                      return { success: true, value: "USER" };
                    case "CHANNEL_HIERARCHY":
                      return { success: true, value: "CHANNEL_HIERARCHY" };
                    case "AI":
                      return { success: true, value: "AI" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["source"]);
            })();
            if (!parsed_source.success) {
              return { success: false };
            }
            if (!hasProp(value, "tagKind")) {
              return { success: false };
            }
            const parsed_tagKind = (() => {
              return ((value: unknown): { success: false } | { success: true; value: TagKindKeyQuality } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        discriminator: KindKeyQuality;
                        id: bigint;
                        kind: KindKeyQuality;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "discriminator")) {
                    return { success: false };
                  }
                  const parsed_discriminator = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                      return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "key-quality":
                            return { success: true, value: "key-quality" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["discriminator"]);
                  })();
                  if (!parsed_discriminator.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "id")) {
                    return { success: false };
                  }
                  const parsed_id = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                      if (typeof value === "number") {
                        return { success: true, value: BigInt(value) };
                      }
                      if (typeof value === "bigint") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["id"]);
                  })();
                  if (!parsed_id.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "kind")) {
                    return { success: false };
                  }
                  const parsed_kind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                      return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "key-quality":
                            return { success: true, value: "key-quality" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["kind"]);
                  })();
                  if (!parsed_kind.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                })(value);
              })(value["tagKind"]);
            })();
            if (!parsed_tagKind.success) {
              return { success: false };
            }
            if (!hasProp(value, "value")) {
              return { success: false };
            }
            const parsed_value = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["value"]);
            })();
            if (!parsed_value.success) {
              return { success: false };
            }
            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { discriminator: "key-quality" as const, ...parsedVariant.value } };
        }
        case "classification": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  exclude: boolean;
                  source: TagSourceType;
                  tagKind: TagKindClassification;
                  value: string;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "exclude")) {
              return { success: false };
            }
            const parsed_exclude = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["exclude"]);
            })();
            if (!parsed_exclude.success) {
              return { success: false };
            }
            if (!hasProp(value, "source")) {
              return { success: false };
            }
            const parsed_source = (() => {
              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "USER":
                      return { success: true, value: "USER" };
                    case "CHANNEL_HIERARCHY":
                      return { success: true, value: "CHANNEL_HIERARCHY" };
                    case "AI":
                      return { success: true, value: "AI" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["source"]);
            })();
            if (!parsed_source.success) {
              return { success: false };
            }
            if (!hasProp(value, "tagKind")) {
              return { success: false };
            }
            const parsed_tagKind = (() => {
              return ((value: unknown): { success: false } | { success: true; value: TagKindClassification } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        discriminator: KindClassification;
                        id: bigint;
                        kind: KindClassification;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "discriminator")) {
                    return { success: false };
                  }
                  const parsed_discriminator = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                      return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "classification":
                            return { success: true, value: "classification" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["discriminator"]);
                  })();
                  if (!parsed_discriminator.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "id")) {
                    return { success: false };
                  }
                  const parsed_id = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                      if (typeof value === "number") {
                        return { success: true, value: BigInt(value) };
                      }
                      if (typeof value === "bigint") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["id"]);
                  })();
                  if (!parsed_id.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "kind")) {
                    return { success: false };
                  }
                  const parsed_kind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                      return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "classification":
                            return { success: true, value: "classification" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["kind"]);
                  })();
                  if (!parsed_kind.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                })(value);
              })(value["tagKind"]);
            })();
            if (!parsed_tagKind.success) {
              return { success: false };
            }
            if (!hasProp(value, "value")) {
              return { success: false };
            }
            const parsed_value = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["value"]);
            })();
            if (!parsed_value.success) {
              return { success: false };
            }
            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { discriminator: "classification" as const, ...parsedVariant.value } };
        }
        default:
          return { success: false };
      }
    })(value);
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// TagAdminFilter
export function serialize_TagAdminFilter(obj: TagAdminFilter): string {
  const serialized = ((value: { query: string; tagKind: TagKind }): unknown => {
    return {
      query: ((value: string): unknown => {
        return value;
      })(value["query"]),
      tagKind: ((
        value:
          | { discriminator: "classification"; id: bigint; kind: KindClassification }
          | { discriminator: "channel"; id: bigint; kind: KindChannel }
          | { discriminator: "key-quality"; id: bigint; kind: KindKeyQuality }
          | { discriminator: "dynamicVoid"; id: bigint; kind: string }
          | { discriminator: "dynamicString"; id: bigint; kind: string }
          | { discriminator: "dynamicBoolean"; id: bigint; kind: string }
          | { discriminator: "dynamicNumber"; id: bigint; kind: string },
      ): unknown => {
        switch (value.discriminator) {
          case "classification": {
            return {
              discriminator: "classification",
              id: ((value: bigint): unknown => {
                return value;
              })(value["id"]),
              kind: ((value: "classification"): unknown => {
                return value;
              })(value["kind"]),
            };
          }
          case "channel": {
            return {
              discriminator: "channel",
              id: ((value: bigint): unknown => {
                return value;
              })(value["id"]),
              kind: ((value: "channel"): unknown => {
                return value;
              })(value["kind"]),
            };
          }
          case "key-quality": {
            return {
              discriminator: "key-quality",
              id: ((value: bigint): unknown => {
                return value;
              })(value["id"]),
              kind: ((value: "key-quality"): unknown => {
                return value;
              })(value["kind"]),
            };
          }
          case "dynamicVoid": {
            return {
              discriminator: "dynamicVoid",
              id: ((value: bigint): unknown => {
                return value;
              })(value["id"]),
              kind: ((value: string): unknown => {
                return value;
              })(value["kind"]),
            };
          }
          case "dynamicString": {
            return {
              discriminator: "dynamicString",
              id: ((value: bigint): unknown => {
                return value;
              })(value["id"]),
              kind: ((value: string): unknown => {
                return value;
              })(value["kind"]),
            };
          }
          case "dynamicBoolean": {
            return {
              discriminator: "dynamicBoolean",
              id: ((value: bigint): unknown => {
                return value;
              })(value["id"]),
              kind: ((value: string): unknown => {
                return value;
              })(value["kind"]),
            };
          }
          case "dynamicNumber": {
            return {
              discriminator: "dynamicNumber",
              id: ((value: bigint): unknown => {
                return value;
              })(value["id"]),
              kind: ((value: string): unknown => {
                return value;
              })(value["kind"]),
            };
          }
        }
      })(value["tagKind"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_TagAdminFilter(str: string): TagAdminFilter | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          query: string;
          tagKind: TagKind;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "query")) {
      return { success: false };
    }
    const parsed_query = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["query"]);
    })();
    if (!parsed_query.success) {
      return { success: false };
    }
    if (!hasProp(value, "tagKind")) {
      return { success: false };
    }
    const parsed_tagKind = (() => {
      return ((value: unknown): { success: false } | { success: true; value: TagKind } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value:
                | { discriminator: "classification"; id: bigint; kind: KindClassification }
                | { discriminator: "channel"; id: bigint; kind: KindChannel }
                | { discriminator: "key-quality"; id: bigint; kind: KindKeyQuality }
                | { discriminator: "dynamicVoid"; id: bigint; kind: string }
                | { discriminator: "dynamicString"; id: bigint; kind: string }
                | { discriminator: "dynamicBoolean"; id: bigint; kind: string }
                | { discriminator: "dynamicNumber"; id: bigint; kind: string };
            } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value:
                  | { discriminator: "classification"; id: bigint; kind: KindClassification }
                  | { discriminator: "channel"; id: bigint; kind: KindChannel }
                  | { discriminator: "key-quality"; id: bigint; kind: KindKeyQuality }
                  | { discriminator: "dynamicVoid"; id: bigint; kind: string }
                  | { discriminator: "dynamicString"; id: bigint; kind: string }
                  | { discriminator: "dynamicBoolean"; id: bigint; kind: string }
                  | { discriminator: "dynamicNumber"; id: bigint; kind: string };
              } => {
            if (value == null) {
              return { success: false };
            }
            if (typeof value != "object") {
              return { success: false };
            }
            if (!hasProp(value, "discriminator")) {
              return { success: false };
            }
            if (typeof value["discriminator"] != "string") {
              return { success: false };
            }
            const discriminator = value["discriminator"];
            switch (discriminator) {
              case "classification": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        id: bigint;
                        kind: KindClassification;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "id")) {
                    return { success: false };
                  }
                  const parsed_id = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                      if (typeof value === "number") {
                        return { success: true, value: BigInt(value) };
                      }
                      if (typeof value === "bigint") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["id"]);
                  })();
                  if (!parsed_id.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "kind")) {
                    return { success: false };
                  }
                  const parsed_kind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                      return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "classification":
                            return { success: true, value: "classification" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["kind"]);
                  })();
                  if (!parsed_kind.success) {
                    return { success: false };
                  }
                  return { success: true, value: { id: parsed_id.value, kind: parsed_kind.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { discriminator: "classification" as const, ...parsedVariant.value } };
              }
              case "channel": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        id: bigint;
                        kind: KindChannel;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "id")) {
                    return { success: false };
                  }
                  const parsed_id = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                      if (typeof value === "number") {
                        return { success: true, value: BigInt(value) };
                      }
                      if (typeof value === "bigint") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["id"]);
                  })();
                  if (!parsed_id.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "kind")) {
                    return { success: false };
                  }
                  const parsed_kind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                      return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "channel":
                            return { success: true, value: "channel" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["kind"]);
                  })();
                  if (!parsed_kind.success) {
                    return { success: false };
                  }
                  return { success: true, value: { id: parsed_id.value, kind: parsed_kind.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { discriminator: "channel" as const, ...parsedVariant.value } };
              }
              case "key-quality": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        id: bigint;
                        kind: KindKeyQuality;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "id")) {
                    return { success: false };
                  }
                  const parsed_id = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                      if (typeof value === "number") {
                        return { success: true, value: BigInt(value) };
                      }
                      if (typeof value === "bigint") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["id"]);
                  })();
                  if (!parsed_id.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "kind")) {
                    return { success: false };
                  }
                  const parsed_kind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                      return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "key-quality":
                            return { success: true, value: "key-quality" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["kind"]);
                  })();
                  if (!parsed_kind.success) {
                    return { success: false };
                  }
                  return { success: true, value: { id: parsed_id.value, kind: parsed_kind.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { discriminator: "key-quality" as const, ...parsedVariant.value } };
              }
              case "dynamicVoid": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        id: bigint;
                        kind: string;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "id")) {
                    return { success: false };
                  }
                  const parsed_id = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                      if (typeof value === "number") {
                        return { success: true, value: BigInt(value) };
                      }
                      if (typeof value === "bigint") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["id"]);
                  })();
                  if (!parsed_id.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "kind")) {
                    return { success: false };
                  }
                  const parsed_kind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["kind"]);
                  })();
                  if (!parsed_kind.success) {
                    return { success: false };
                  }
                  return { success: true, value: { id: parsed_id.value, kind: parsed_kind.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { discriminator: "dynamicVoid" as const, ...parsedVariant.value } };
              }
              case "dynamicString": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        id: bigint;
                        kind: string;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "id")) {
                    return { success: false };
                  }
                  const parsed_id = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                      if (typeof value === "number") {
                        return { success: true, value: BigInt(value) };
                      }
                      if (typeof value === "bigint") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["id"]);
                  })();
                  if (!parsed_id.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "kind")) {
                    return { success: false };
                  }
                  const parsed_kind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["kind"]);
                  })();
                  if (!parsed_kind.success) {
                    return { success: false };
                  }
                  return { success: true, value: { id: parsed_id.value, kind: parsed_kind.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { discriminator: "dynamicString" as const, ...parsedVariant.value } };
              }
              case "dynamicBoolean": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        id: bigint;
                        kind: string;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "id")) {
                    return { success: false };
                  }
                  const parsed_id = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                      if (typeof value === "number") {
                        return { success: true, value: BigInt(value) };
                      }
                      if (typeof value === "bigint") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["id"]);
                  })();
                  if (!parsed_id.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "kind")) {
                    return { success: false };
                  }
                  const parsed_kind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["kind"]);
                  })();
                  if (!parsed_kind.success) {
                    return { success: false };
                  }
                  return { success: true, value: { id: parsed_id.value, kind: parsed_kind.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { discriminator: "dynamicBoolean" as const, ...parsedVariant.value } };
              }
              case "dynamicNumber": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        id: bigint;
                        kind: string;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "id")) {
                    return { success: false };
                  }
                  const parsed_id = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                      if (typeof value === "number") {
                        return { success: true, value: BigInt(value) };
                      }
                      if (typeof value === "bigint") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["id"]);
                  })();
                  if (!parsed_id.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "kind")) {
                    return { success: false };
                  }
                  const parsed_kind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["kind"]);
                  })();
                  if (!parsed_kind.success) {
                    return { success: false };
                  }
                  return { success: true, value: { id: parsed_id.value, kind: parsed_kind.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { discriminator: "dynamicNumber" as const, ...parsedVariant.value } };
              }
              default:
                return { success: false };
            }
          })(value);
        })(value);
      })(value["tagKind"]);
    })();
    if (!parsed_tagKind.success) {
      return { success: false };
    }
    return { success: true, value: { query: parsed_query.value, tagKind: parsed_tagKind.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// TagKind
export function serialize_TagKind(obj: TagKind): string {
  const serialized = ((
    value:
      | { discriminator: "classification"; id: bigint; kind: KindClassification }
      | { discriminator: "channel"; id: bigint; kind: KindChannel }
      | { discriminator: "key-quality"; id: bigint; kind: KindKeyQuality }
      | { discriminator: "dynamicVoid"; id: bigint; kind: string }
      | { discriminator: "dynamicString"; id: bigint; kind: string }
      | { discriminator: "dynamicBoolean"; id: bigint; kind: string }
      | { discriminator: "dynamicNumber"; id: bigint; kind: string },
  ): unknown => {
    switch (value.discriminator) {
      case "classification": {
        return {
          discriminator: "classification",
          id: ((value: bigint): unknown => {
            return value;
          })(value["id"]),
          kind: ((value: "classification"): unknown => {
            return value;
          })(value["kind"]),
        };
      }
      case "channel": {
        return {
          discriminator: "channel",
          id: ((value: bigint): unknown => {
            return value;
          })(value["id"]),
          kind: ((value: "channel"): unknown => {
            return value;
          })(value["kind"]),
        };
      }
      case "key-quality": {
        return {
          discriminator: "key-quality",
          id: ((value: bigint): unknown => {
            return value;
          })(value["id"]),
          kind: ((value: "key-quality"): unknown => {
            return value;
          })(value["kind"]),
        };
      }
      case "dynamicVoid": {
        return {
          discriminator: "dynamicVoid",
          id: ((value: bigint): unknown => {
            return value;
          })(value["id"]),
          kind: ((value: string): unknown => {
            return value;
          })(value["kind"]),
        };
      }
      case "dynamicString": {
        return {
          discriminator: "dynamicString",
          id: ((value: bigint): unknown => {
            return value;
          })(value["id"]),
          kind: ((value: string): unknown => {
            return value;
          })(value["kind"]),
        };
      }
      case "dynamicBoolean": {
        return {
          discriminator: "dynamicBoolean",
          id: ((value: bigint): unknown => {
            return value;
          })(value["id"]),
          kind: ((value: string): unknown => {
            return value;
          })(value["kind"]),
        };
      }
      case "dynamicNumber": {
        return {
          discriminator: "dynamicNumber",
          id: ((value: bigint): unknown => {
            return value;
          })(value["id"]),
          kind: ((value: string): unknown => {
            return value;
          })(value["kind"]),
        };
      }
    }
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_TagKind(str: string): TagKind | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value:
          | { discriminator: "classification"; id: bigint; kind: KindClassification }
          | { discriminator: "channel"; id: bigint; kind: KindChannel }
          | { discriminator: "key-quality"; id: bigint; kind: KindKeyQuality }
          | { discriminator: "dynamicVoid"; id: bigint; kind: string }
          | { discriminator: "dynamicString"; id: bigint; kind: string }
          | { discriminator: "dynamicBoolean"; id: bigint; kind: string }
          | { discriminator: "dynamicNumber"; id: bigint; kind: string };
      } => {
    return ((
      value: unknown,
    ):
      | { success: false }
      | {
          success: true;
          value:
            | { discriminator: "classification"; id: bigint; kind: KindClassification }
            | { discriminator: "channel"; id: bigint; kind: KindChannel }
            | { discriminator: "key-quality"; id: bigint; kind: KindKeyQuality }
            | { discriminator: "dynamicVoid"; id: bigint; kind: string }
            | { discriminator: "dynamicString"; id: bigint; kind: string }
            | { discriminator: "dynamicBoolean"; id: bigint; kind: string }
            | { discriminator: "dynamicNumber"; id: bigint; kind: string };
        } => {
      if (value == null) {
        return { success: false };
      }
      if (typeof value != "object") {
        return { success: false };
      }
      if (!hasProp(value, "discriminator")) {
        return { success: false };
      }
      if (typeof value["discriminator"] != "string") {
        return { success: false };
      }
      const discriminator = value["discriminator"];
      switch (discriminator) {
        case "classification": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  id: bigint;
                  kind: KindClassification;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "id")) {
              return { success: false };
            }
            const parsed_id = (() => {
              return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                if (typeof value === "number") {
                  return { success: true, value: BigInt(value) };
                }
                if (typeof value === "bigint") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["id"]);
            })();
            if (!parsed_id.success) {
              return { success: false };
            }
            if (!hasProp(value, "kind")) {
              return { success: false };
            }
            const parsed_kind = (() => {
              return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "classification":
                      return { success: true, value: "classification" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["kind"]);
            })();
            if (!parsed_kind.success) {
              return { success: false };
            }
            return { success: true, value: { id: parsed_id.value, kind: parsed_kind.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { discriminator: "classification" as const, ...parsedVariant.value } };
        }
        case "channel": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  id: bigint;
                  kind: KindChannel;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "id")) {
              return { success: false };
            }
            const parsed_id = (() => {
              return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                if (typeof value === "number") {
                  return { success: true, value: BigInt(value) };
                }
                if (typeof value === "bigint") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["id"]);
            })();
            if (!parsed_id.success) {
              return { success: false };
            }
            if (!hasProp(value, "kind")) {
              return { success: false };
            }
            const parsed_kind = (() => {
              return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "channel":
                      return { success: true, value: "channel" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["kind"]);
            })();
            if (!parsed_kind.success) {
              return { success: false };
            }
            return { success: true, value: { id: parsed_id.value, kind: parsed_kind.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { discriminator: "channel" as const, ...parsedVariant.value } };
        }
        case "key-quality": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  id: bigint;
                  kind: KindKeyQuality;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "id")) {
              return { success: false };
            }
            const parsed_id = (() => {
              return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                if (typeof value === "number") {
                  return { success: true, value: BigInt(value) };
                }
                if (typeof value === "bigint") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["id"]);
            })();
            if (!parsed_id.success) {
              return { success: false };
            }
            if (!hasProp(value, "kind")) {
              return { success: false };
            }
            const parsed_kind = (() => {
              return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "key-quality":
                      return { success: true, value: "key-quality" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["kind"]);
            })();
            if (!parsed_kind.success) {
              return { success: false };
            }
            return { success: true, value: { id: parsed_id.value, kind: parsed_kind.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { discriminator: "key-quality" as const, ...parsedVariant.value } };
        }
        case "dynamicVoid": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  id: bigint;
                  kind: string;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "id")) {
              return { success: false };
            }
            const parsed_id = (() => {
              return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                if (typeof value === "number") {
                  return { success: true, value: BigInt(value) };
                }
                if (typeof value === "bigint") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["id"]);
            })();
            if (!parsed_id.success) {
              return { success: false };
            }
            if (!hasProp(value, "kind")) {
              return { success: false };
            }
            const parsed_kind = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["kind"]);
            })();
            if (!parsed_kind.success) {
              return { success: false };
            }
            return { success: true, value: { id: parsed_id.value, kind: parsed_kind.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { discriminator: "dynamicVoid" as const, ...parsedVariant.value } };
        }
        case "dynamicString": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  id: bigint;
                  kind: string;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "id")) {
              return { success: false };
            }
            const parsed_id = (() => {
              return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                if (typeof value === "number") {
                  return { success: true, value: BigInt(value) };
                }
                if (typeof value === "bigint") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["id"]);
            })();
            if (!parsed_id.success) {
              return { success: false };
            }
            if (!hasProp(value, "kind")) {
              return { success: false };
            }
            const parsed_kind = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["kind"]);
            })();
            if (!parsed_kind.success) {
              return { success: false };
            }
            return { success: true, value: { id: parsed_id.value, kind: parsed_kind.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { discriminator: "dynamicString" as const, ...parsedVariant.value } };
        }
        case "dynamicBoolean": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  id: bigint;
                  kind: string;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "id")) {
              return { success: false };
            }
            const parsed_id = (() => {
              return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                if (typeof value === "number") {
                  return { success: true, value: BigInt(value) };
                }
                if (typeof value === "bigint") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["id"]);
            })();
            if (!parsed_id.success) {
              return { success: false };
            }
            if (!hasProp(value, "kind")) {
              return { success: false };
            }
            const parsed_kind = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["kind"]);
            })();
            if (!parsed_kind.success) {
              return { success: false };
            }
            return { success: true, value: { id: parsed_id.value, kind: parsed_kind.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { discriminator: "dynamicBoolean" as const, ...parsedVariant.value } };
        }
        case "dynamicNumber": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  id: bigint;
                  kind: string;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "id")) {
              return { success: false };
            }
            const parsed_id = (() => {
              return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                if (typeof value === "number") {
                  return { success: true, value: BigInt(value) };
                }
                if (typeof value === "bigint") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["id"]);
            })();
            if (!parsed_id.success) {
              return { success: false };
            }
            if (!hasProp(value, "kind")) {
              return { success: false };
            }
            const parsed_kind = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["kind"]);
            })();
            if (!parsed_kind.success) {
              return { success: false };
            }
            return { success: true, value: { id: parsed_id.value, kind: parsed_kind.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { discriminator: "dynamicNumber" as const, ...parsedVariant.value } };
        }
        default:
          return { success: false };
      }
    })(value);
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// TagKindChannel
export function serialize_TagKindChannel(obj: TagKindChannel): string {
  const serialized = ((value: { discriminator: KindChannel; id: bigint; kind: KindChannel }): unknown => {
    return {
      discriminator: ((value: "channel"): unknown => {
        return value;
      })(value["discriminator"]),
      id: ((value: bigint): unknown => {
        return value;
      })(value["id"]),
      kind: ((value: "channel"): unknown => {
        return value;
      })(value["kind"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_TagKindChannel(str: string): TagKindChannel | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          discriminator: KindChannel;
          id: bigint;
          kind: KindChannel;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "discriminator")) {
      return { success: false };
    }
    const parsed_discriminator = (() => {
      return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
        return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "channel":
              return { success: true, value: "channel" };
            default:
              return { success: false };
          }
        })(value);
      })(value["discriminator"]);
    })();
    if (!parsed_discriminator.success) {
      return { success: false };
    }
    if (!hasProp(value, "id")) {
      return { success: false };
    }
    const parsed_id = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["id"]);
    })();
    if (!parsed_id.success) {
      return { success: false };
    }
    if (!hasProp(value, "kind")) {
      return { success: false };
    }
    const parsed_kind = (() => {
      return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
        return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "channel":
              return { success: true, value: "channel" };
            default:
              return { success: false };
          }
        })(value);
      })(value["kind"]);
    })();
    if (!parsed_kind.success) {
      return { success: false };
    }
    return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// TagKindClassification
export function serialize_TagKindClassification(obj: TagKindClassification): string {
  const serialized = ((value: { discriminator: KindClassification; id: bigint; kind: KindClassification }): unknown => {
    return {
      discriminator: ((value: "classification"): unknown => {
        return value;
      })(value["discriminator"]),
      id: ((value: bigint): unknown => {
        return value;
      })(value["id"]),
      kind: ((value: "classification"): unknown => {
        return value;
      })(value["kind"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_TagKindClassification(str: string): TagKindClassification | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          discriminator: KindClassification;
          id: bigint;
          kind: KindClassification;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "discriminator")) {
      return { success: false };
    }
    const parsed_discriminator = (() => {
      return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
        return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "classification":
              return { success: true, value: "classification" };
            default:
              return { success: false };
          }
        })(value);
      })(value["discriminator"]);
    })();
    if (!parsed_discriminator.success) {
      return { success: false };
    }
    if (!hasProp(value, "id")) {
      return { success: false };
    }
    const parsed_id = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["id"]);
    })();
    if (!parsed_id.success) {
      return { success: false };
    }
    if (!hasProp(value, "kind")) {
      return { success: false };
    }
    const parsed_kind = (() => {
      return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
        return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "classification":
              return { success: true, value: "classification" };
            default:
              return { success: false };
          }
        })(value);
      })(value["kind"]);
    })();
    if (!parsed_kind.success) {
      return { success: false };
    }
    return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// TagKindDynamicBoolean
export function serialize_TagKindDynamicBoolean(obj: TagKindDynamicBoolean): string {
  const serialized = ((value: { discriminator: KindDynamicBoolean; id: bigint; kind: string }): unknown => {
    return {
      discriminator: ((value: "dynamicBoolean"): unknown => {
        return value;
      })(value["discriminator"]),
      id: ((value: bigint): unknown => {
        return value;
      })(value["id"]),
      kind: ((value: string): unknown => {
        return value;
      })(value["kind"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_TagKindDynamicBoolean(str: string): TagKindDynamicBoolean | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          discriminator: KindDynamicBoolean;
          id: bigint;
          kind: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "discriminator")) {
      return { success: false };
    }
    const parsed_discriminator = (() => {
      return ((value: unknown): { success: false } | { success: true; value: KindDynamicBoolean } => {
        return ((value: unknown): { success: false } | { success: true; value: "dynamicBoolean" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "dynamicBoolean":
              return { success: true, value: "dynamicBoolean" };
            default:
              return { success: false };
          }
        })(value);
      })(value["discriminator"]);
    })();
    if (!parsed_discriminator.success) {
      return { success: false };
    }
    if (!hasProp(value, "id")) {
      return { success: false };
    }
    const parsed_id = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["id"]);
    })();
    if (!parsed_id.success) {
      return { success: false };
    }
    if (!hasProp(value, "kind")) {
      return { success: false };
    }
    const parsed_kind = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["kind"]);
    })();
    if (!parsed_kind.success) {
      return { success: false };
    }
    return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// TagKindDynamicNumber
export function serialize_TagKindDynamicNumber(obj: TagKindDynamicNumber): string {
  const serialized = ((value: { discriminator: KindDynamicNumber; id: bigint; kind: string }): unknown => {
    return {
      discriminator: ((value: "dynamicNumber"): unknown => {
        return value;
      })(value["discriminator"]),
      id: ((value: bigint): unknown => {
        return value;
      })(value["id"]),
      kind: ((value: string): unknown => {
        return value;
      })(value["kind"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_TagKindDynamicNumber(str: string): TagKindDynamicNumber | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          discriminator: KindDynamicNumber;
          id: bigint;
          kind: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "discriminator")) {
      return { success: false };
    }
    const parsed_discriminator = (() => {
      return ((value: unknown): { success: false } | { success: true; value: KindDynamicNumber } => {
        return ((value: unknown): { success: false } | { success: true; value: "dynamicNumber" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "dynamicNumber":
              return { success: true, value: "dynamicNumber" };
            default:
              return { success: false };
          }
        })(value);
      })(value["discriminator"]);
    })();
    if (!parsed_discriminator.success) {
      return { success: false };
    }
    if (!hasProp(value, "id")) {
      return { success: false };
    }
    const parsed_id = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["id"]);
    })();
    if (!parsed_id.success) {
      return { success: false };
    }
    if (!hasProp(value, "kind")) {
      return { success: false };
    }
    const parsed_kind = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["kind"]);
    })();
    if (!parsed_kind.success) {
      return { success: false };
    }
    return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// TagKindDynamicString
export function serialize_TagKindDynamicString(obj: TagKindDynamicString): string {
  const serialized = ((value: { discriminator: KindDynamicString; id: bigint; kind: string }): unknown => {
    return {
      discriminator: ((value: "dynamicString"): unknown => {
        return value;
      })(value["discriminator"]),
      id: ((value: bigint): unknown => {
        return value;
      })(value["id"]),
      kind: ((value: string): unknown => {
        return value;
      })(value["kind"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_TagKindDynamicString(str: string): TagKindDynamicString | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          discriminator: KindDynamicString;
          id: bigint;
          kind: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "discriminator")) {
      return { success: false };
    }
    const parsed_discriminator = (() => {
      return ((value: unknown): { success: false } | { success: true; value: KindDynamicString } => {
        return ((value: unknown): { success: false } | { success: true; value: "dynamicString" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "dynamicString":
              return { success: true, value: "dynamicString" };
            default:
              return { success: false };
          }
        })(value);
      })(value["discriminator"]);
    })();
    if (!parsed_discriminator.success) {
      return { success: false };
    }
    if (!hasProp(value, "id")) {
      return { success: false };
    }
    const parsed_id = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["id"]);
    })();
    if (!parsed_id.success) {
      return { success: false };
    }
    if (!hasProp(value, "kind")) {
      return { success: false };
    }
    const parsed_kind = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["kind"]);
    })();
    if (!parsed_kind.success) {
      return { success: false };
    }
    return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// TagKindDynamicVoid
export function serialize_TagKindDynamicVoid(obj: TagKindDynamicVoid): string {
  const serialized = ((value: { discriminator: KindDynamicVoid; id: bigint; kind: string }): unknown => {
    return {
      discriminator: ((value: "dynamicVoid"): unknown => {
        return value;
      })(value["discriminator"]),
      id: ((value: bigint): unknown => {
        return value;
      })(value["id"]),
      kind: ((value: string): unknown => {
        return value;
      })(value["kind"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_TagKindDynamicVoid(str: string): TagKindDynamicVoid | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          discriminator: KindDynamicVoid;
          id: bigint;
          kind: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "discriminator")) {
      return { success: false };
    }
    const parsed_discriminator = (() => {
      return ((value: unknown): { success: false } | { success: true; value: KindDynamicVoid } => {
        return ((value: unknown): { success: false } | { success: true; value: "dynamicVoid" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "dynamicVoid":
              return { success: true, value: "dynamicVoid" };
            default:
              return { success: false };
          }
        })(value);
      })(value["discriminator"]);
    })();
    if (!parsed_discriminator.success) {
      return { success: false };
    }
    if (!hasProp(value, "id")) {
      return { success: false };
    }
    const parsed_id = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["id"]);
    })();
    if (!parsed_id.success) {
      return { success: false };
    }
    if (!hasProp(value, "kind")) {
      return { success: false };
    }
    const parsed_kind = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["kind"]);
    })();
    if (!parsed_kind.success) {
      return { success: false };
    }
    return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// TagKindKeyQuality
export function serialize_TagKindKeyQuality(obj: TagKindKeyQuality): string {
  const serialized = ((value: { discriminator: KindKeyQuality; id: bigint; kind: KindKeyQuality }): unknown => {
    return {
      discriminator: ((value: "key-quality"): unknown => {
        return value;
      })(value["discriminator"]),
      id: ((value: bigint): unknown => {
        return value;
      })(value["id"]),
      kind: ((value: "key-quality"): unknown => {
        return value;
      })(value["kind"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_TagKindKeyQuality(str: string): TagKindKeyQuality | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          discriminator: KindKeyQuality;
          id: bigint;
          kind: KindKeyQuality;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "discriminator")) {
      return { success: false };
    }
    const parsed_discriminator = (() => {
      return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
        return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "key-quality":
              return { success: true, value: "key-quality" };
            default:
              return { success: false };
          }
        })(value);
      })(value["discriminator"]);
    })();
    if (!parsed_discriminator.success) {
      return { success: false };
    }
    if (!hasProp(value, "id")) {
      return { success: false };
    }
    const parsed_id = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["id"]);
    })();
    if (!parsed_id.success) {
      return { success: false };
    }
    if (!hasProp(value, "kind")) {
      return { success: false };
    }
    const parsed_kind = (() => {
      return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
        return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "key-quality":
              return { success: true, value: "key-quality" };
            default:
              return { success: false };
          }
        })(value);
      })(value["kind"]);
    })();
    if (!parsed_kind.success) {
      return { success: false };
    }
    return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// TagSourceType
export function serialize_TagSourceType(obj: TagSourceType): string {
  const serialized = ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_TagSourceType(str: string): TagSourceType | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "USER":
        return { success: true, value: "USER" };
      case "CHANNEL_HIERARCHY":
        return { success: true, value: "CHANNEL_HIERARCHY" };
      case "AI":
        return { success: true, value: "AI" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// TagValidationResponse
export function serialize_TagValidationResponse(obj: TagValidationResponse): string {
  const serialized = ((value: { status: "success" } | { status: "error"; message: string }): unknown => {
    switch (value.status) {
      case "success": {
        return { status: "success" };
      }
      case "error": {
        return {
          status: "error",
          message: ((value: string): unknown => {
            return value;
          })(value["message"]),
        };
      }
    }
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_TagValidationResponse(str: string): TagValidationResponse | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: { status: "success" } | { status: "error"; message: string } } => {
    return ((value: unknown): { success: false } | { success: true; value: { status: "success" } | { status: "error"; message: string } } => {
      if (value == null) {
        return { success: false };
      }
      if (typeof value != "object") {
        return { success: false };
      }
      if (!hasProp(value, "status")) {
        return { success: false };
      }
      if (typeof value["status"] != "string") {
        return { success: false };
      }
      const discriminator = value["status"];
      switch (discriminator) {
        case "success": {
          const parsedVariant = ((value: unknown): { success: false } | { success: true; value: Record<string, never> } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            return { success: true, value: {} };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { status: "success" as const, ...parsedVariant.value } };
        }
        case "error": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  message: string;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "message")) {
              return { success: false };
            }
            const parsed_message = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["message"]);
            })();
            if (!parsed_message.success) {
              return { success: false };
            }
            return { success: true, value: { message: parsed_message.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { status: "error" as const, ...parsedVariant.value } };
        }
        default:
          return { success: false };
      }
    })(value);
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// TaggedItem
export function serialize_TaggedItem(obj: TaggedItem): string {
  const serialized = ((value: { item: ReferencedLearningItem; tag: Tag }): unknown => {
    return {
      item: ((value: { learningItemType: "SUMMARY"; id: number } | { learningItemType: "ACTIONABLE"; id: bigint }): unknown => {
        switch (value.learningItemType) {
          case "SUMMARY": {
            return {
              learningItemType: "SUMMARY",
              id: ((value: number): unknown => {
                return value;
              })(value["id"]),
            };
          }
          case "ACTIONABLE": {
            return {
              learningItemType: "ACTIONABLE",
              id: ((value: bigint): unknown => {
                return value;
              })(value["id"]),
            };
          }
        }
      })(value["item"]),
      tag: ((
        value:
          | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
          | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
          | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
          | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
          | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
          | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
          | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string },
      ): unknown => {
        switch (value.discriminator) {
          case "dynamicString": {
            return {
              discriminator: "dynamicString",
              exclude: ((value: boolean): unknown => {
                return value;
              })(value["exclude"]),
              source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                return value;
              })(value["source"]),
              tagKind: ((value: { discriminator: KindDynamicString; id: bigint; kind: string }): unknown => {
                return {
                  discriminator: ((value: "dynamicString"): unknown => {
                    return value;
                  })(value["discriminator"]),
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: string): unknown => {
                    return value;
                  })(value["kind"]),
                };
              })(value["tagKind"]),
              value: ((value: string): unknown => {
                return value;
              })(value["value"]),
            };
          }
          case "dynamicBoolean": {
            return {
              discriminator: "dynamicBoolean",
              exclude: ((value: boolean): unknown => {
                return value;
              })(value["exclude"]),
              source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                return value;
              })(value["source"]),
              tagKind: ((value: { discriminator: KindDynamicBoolean; id: bigint; kind: string }): unknown => {
                return {
                  discriminator: ((value: "dynamicBoolean"): unknown => {
                    return value;
                  })(value["discriminator"]),
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: string): unknown => {
                    return value;
                  })(value["kind"]),
                };
              })(value["tagKind"]),
              value: ((value: boolean): unknown => {
                return value;
              })(value["value"]),
            };
          }
          case "dynamicNumber": {
            return {
              discriminator: "dynamicNumber",
              exclude: ((value: boolean): unknown => {
                return value;
              })(value["exclude"]),
              source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                return value;
              })(value["source"]),
              tagKind: ((value: { discriminator: KindDynamicNumber; id: bigint; kind: string }): unknown => {
                return {
                  discriminator: ((value: "dynamicNumber"): unknown => {
                    return value;
                  })(value["discriminator"]),
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: string): unknown => {
                    return value;
                  })(value["kind"]),
                };
              })(value["tagKind"]),
              value: ((value: number): unknown => {
                return value;
              })(value["value"]),
            };
          }
          case "dynamicVoid": {
            return {
              discriminator: "dynamicVoid",
              exclude: ((value: boolean): unknown => {
                return value;
              })(value["exclude"]),
              source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                return value;
              })(value["source"]),
              tagKind: ((value: { discriminator: KindDynamicVoid; id: bigint; kind: string }): unknown => {
                return {
                  discriminator: ((value: "dynamicVoid"): unknown => {
                    return value;
                  })(value["discriminator"]),
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: string): unknown => {
                    return value;
                  })(value["kind"]),
                };
              })(value["tagKind"]),
            };
          }
          case "channel": {
            return {
              discriminator: "channel",
              exclude: ((value: boolean): unknown => {
                return value;
              })(value["exclude"]),
              source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                return value;
              })(value["source"]),
              tagKind: ((value: { discriminator: KindChannel; id: bigint; kind: KindChannel }): unknown => {
                return {
                  discriminator: ((value: "channel"): unknown => {
                    return value;
                  })(value["discriminator"]),
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: "channel"): unknown => {
                    return value;
                  })(value["kind"]),
                };
              })(value["tagKind"]),
              value: ((value: string): unknown => {
                return value;
              })(value["value"]),
            };
          }
          case "key-quality": {
            return {
              discriminator: "key-quality",
              exclude: ((value: boolean): unknown => {
                return value;
              })(value["exclude"]),
              source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                return value;
              })(value["source"]),
              tagKind: ((value: { discriminator: KindKeyQuality; id: bigint; kind: KindKeyQuality }): unknown => {
                return {
                  discriminator: ((value: "key-quality"): unknown => {
                    return value;
                  })(value["discriminator"]),
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: "key-quality"): unknown => {
                    return value;
                  })(value["kind"]),
                };
              })(value["tagKind"]),
              value: ((value: string): unknown => {
                return value;
              })(value["value"]),
            };
          }
          case "classification": {
            return {
              discriminator: "classification",
              exclude: ((value: boolean): unknown => {
                return value;
              })(value["exclude"]),
              source: ((value: "USER" | "CHANNEL_HIERARCHY" | "AI"): unknown => {
                return value;
              })(value["source"]),
              tagKind: ((value: { discriminator: KindClassification; id: bigint; kind: KindClassification }): unknown => {
                return {
                  discriminator: ((value: "classification"): unknown => {
                    return value;
                  })(value["discriminator"]),
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: "classification"): unknown => {
                    return value;
                  })(value["kind"]),
                };
              })(value["tagKind"]),
              value: ((value: string): unknown => {
                return value;
              })(value["value"]),
            };
          }
        }
      })(value["tag"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_TaggedItem(str: string): TaggedItem | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          item: ReferencedLearningItem;
          tag: Tag;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "item")) {
      return { success: false };
    }
    const parsed_item = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ReferencedLearningItem } => {
        return ((value: unknown): { success: false } | { success: true; value: { learningItemType: "SUMMARY"; id: number } | { learningItemType: "ACTIONABLE"; id: bigint } } => {
          return ((value: unknown): { success: false } | { success: true; value: { learningItemType: "SUMMARY"; id: number } | { learningItemType: "ACTIONABLE"; id: bigint } } => {
            if (value == null) {
              return { success: false };
            }
            if (typeof value != "object") {
              return { success: false };
            }
            if (!hasProp(value, "learningItemType")) {
              return { success: false };
            }
            if (typeof value["learningItemType"] != "string") {
              return { success: false };
            }
            const discriminator = value["learningItemType"];
            switch (discriminator) {
              case "SUMMARY": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        id: number;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "id")) {
                    return { success: false };
                  }
                  const parsed_id = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number } => {
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["id"]);
                  })();
                  if (!parsed_id.success) {
                    return { success: false };
                  }
                  return { success: true, value: { id: parsed_id.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { learningItemType: "SUMMARY" as const, ...parsedVariant.value } };
              }
              case "ACTIONABLE": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        id: bigint;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "id")) {
                    return { success: false };
                  }
                  const parsed_id = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                      if (typeof value === "number") {
                        return { success: true, value: BigInt(value) };
                      }
                      if (typeof value === "bigint") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["id"]);
                  })();
                  if (!parsed_id.success) {
                    return { success: false };
                  }
                  return { success: true, value: { id: parsed_id.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { learningItemType: "ACTIONABLE" as const, ...parsedVariant.value } };
              }
              default:
                return { success: false };
            }
          })(value);
        })(value);
      })(value["item"]);
    })();
    if (!parsed_item.success) {
      return { success: false };
    }
    if (!hasProp(value, "tag")) {
      return { success: false };
    }
    const parsed_tag = (() => {
      return ((value: unknown): { success: false } | { success: true; value: Tag } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value:
                | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
            } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value:
                  | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                  | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                  | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                  | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                  | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                  | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                  | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
              } => {
            if (value == null) {
              return { success: false };
            }
            if (typeof value != "object") {
              return { success: false };
            }
            if (!hasProp(value, "discriminator")) {
              return { success: false };
            }
            if (typeof value["discriminator"] != "string") {
              return { success: false };
            }
            const discriminator = value["discriminator"];
            switch (discriminator) {
              case "dynamicString": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        exclude: boolean;
                        source: TagSourceType;
                        tagKind: TagKindDynamicString;
                        value: string;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "exclude")) {
                    return { success: false };
                  }
                  const parsed_exclude = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["exclude"]);
                  })();
                  if (!parsed_exclude.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "source")) {
                    return { success: false };
                  }
                  const parsed_source = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "USER":
                            return { success: true, value: "USER" };
                          case "CHANNEL_HIERARCHY":
                            return { success: true, value: "CHANNEL_HIERARCHY" };
                          case "AI":
                            return { success: true, value: "AI" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["source"]);
                  })();
                  if (!parsed_source.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "tagKind")) {
                    return { success: false };
                  }
                  const parsed_tagKind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicString } => {
                      return ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              discriminator: KindDynamicString;
                              id: bigint;
                              kind: string;
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "discriminator")) {
                          return { success: false };
                        }
                        const parsed_discriminator = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: KindDynamicString } => {
                            return ((value: unknown): { success: false } | { success: true; value: "dynamicString" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "dynamicString":
                                  return { success: true, value: "dynamicString" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["discriminator"]);
                        })();
                        if (!parsed_discriminator.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "id")) {
                          return { success: false };
                        }
                        const parsed_id = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                            if (typeof value === "number") {
                              return { success: true, value: BigInt(value) };
                            }
                            if (typeof value === "bigint") {
                              return { success: true, value: value };
                            }
                            return { success: false };
                          })(value["id"]);
                        })();
                        if (!parsed_id.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "kind")) {
                          return { success: false };
                        }
                        const parsed_kind = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["kind"]);
                        })();
                        if (!parsed_kind.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                      })(value);
                    })(value["tagKind"]);
                  })();
                  if (!parsed_tagKind.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "value")) {
                    return { success: false };
                  }
                  const parsed_value = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["value"]);
                  })();
                  if (!parsed_value.success) {
                    return { success: false };
                  }
                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { discriminator: "dynamicString" as const, ...parsedVariant.value } };
              }
              case "dynamicBoolean": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        exclude: boolean;
                        source: TagSourceType;
                        tagKind: TagKindDynamicBoolean;
                        value: boolean;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "exclude")) {
                    return { success: false };
                  }
                  const parsed_exclude = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["exclude"]);
                  })();
                  if (!parsed_exclude.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "source")) {
                    return { success: false };
                  }
                  const parsed_source = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "USER":
                            return { success: true, value: "USER" };
                          case "CHANNEL_HIERARCHY":
                            return { success: true, value: "CHANNEL_HIERARCHY" };
                          case "AI":
                            return { success: true, value: "AI" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["source"]);
                  })();
                  if (!parsed_source.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "tagKind")) {
                    return { success: false };
                  }
                  const parsed_tagKind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicBoolean } => {
                      return ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              discriminator: KindDynamicBoolean;
                              id: bigint;
                              kind: string;
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "discriminator")) {
                          return { success: false };
                        }
                        const parsed_discriminator = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: KindDynamicBoolean } => {
                            return ((value: unknown): { success: false } | { success: true; value: "dynamicBoolean" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "dynamicBoolean":
                                  return { success: true, value: "dynamicBoolean" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["discriminator"]);
                        })();
                        if (!parsed_discriminator.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "id")) {
                          return { success: false };
                        }
                        const parsed_id = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                            if (typeof value === "number") {
                              return { success: true, value: BigInt(value) };
                            }
                            if (typeof value === "bigint") {
                              return { success: true, value: value };
                            }
                            return { success: false };
                          })(value["id"]);
                        })();
                        if (!parsed_id.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "kind")) {
                          return { success: false };
                        }
                        const parsed_kind = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["kind"]);
                        })();
                        if (!parsed_kind.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                      })(value);
                    })(value["tagKind"]);
                  })();
                  if (!parsed_tagKind.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "value")) {
                    return { success: false };
                  }
                  const parsed_value = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["value"]);
                  })();
                  if (!parsed_value.success) {
                    return { success: false };
                  }
                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { discriminator: "dynamicBoolean" as const, ...parsedVariant.value } };
              }
              case "dynamicNumber": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        exclude: boolean;
                        source: TagSourceType;
                        tagKind: TagKindDynamicNumber;
                        value: number;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "exclude")) {
                    return { success: false };
                  }
                  const parsed_exclude = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["exclude"]);
                  })();
                  if (!parsed_exclude.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "source")) {
                    return { success: false };
                  }
                  const parsed_source = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "USER":
                            return { success: true, value: "USER" };
                          case "CHANNEL_HIERARCHY":
                            return { success: true, value: "CHANNEL_HIERARCHY" };
                          case "AI":
                            return { success: true, value: "AI" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["source"]);
                  })();
                  if (!parsed_source.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "tagKind")) {
                    return { success: false };
                  }
                  const parsed_tagKind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicNumber } => {
                      return ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              discriminator: KindDynamicNumber;
                              id: bigint;
                              kind: string;
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "discriminator")) {
                          return { success: false };
                        }
                        const parsed_discriminator = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: KindDynamicNumber } => {
                            return ((value: unknown): { success: false } | { success: true; value: "dynamicNumber" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "dynamicNumber":
                                  return { success: true, value: "dynamicNumber" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["discriminator"]);
                        })();
                        if (!parsed_discriminator.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "id")) {
                          return { success: false };
                        }
                        const parsed_id = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                            if (typeof value === "number") {
                              return { success: true, value: BigInt(value) };
                            }
                            if (typeof value === "bigint") {
                              return { success: true, value: value };
                            }
                            return { success: false };
                          })(value["id"]);
                        })();
                        if (!parsed_id.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "kind")) {
                          return { success: false };
                        }
                        const parsed_kind = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["kind"]);
                        })();
                        if (!parsed_kind.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                      })(value);
                    })(value["tagKind"]);
                  })();
                  if (!parsed_tagKind.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "value")) {
                    return { success: false };
                  }
                  const parsed_value = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number } => {
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["value"]);
                  })();
                  if (!parsed_value.success) {
                    return { success: false };
                  }
                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { discriminator: "dynamicNumber" as const, ...parsedVariant.value } };
              }
              case "dynamicVoid": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        exclude: boolean;
                        source: TagSourceType;
                        tagKind: TagKindDynamicVoid;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "exclude")) {
                    return { success: false };
                  }
                  const parsed_exclude = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["exclude"]);
                  })();
                  if (!parsed_exclude.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "source")) {
                    return { success: false };
                  }
                  const parsed_source = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "USER":
                            return { success: true, value: "USER" };
                          case "CHANNEL_HIERARCHY":
                            return { success: true, value: "CHANNEL_HIERARCHY" };
                          case "AI":
                            return { success: true, value: "AI" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["source"]);
                  })();
                  if (!parsed_source.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "tagKind")) {
                    return { success: false };
                  }
                  const parsed_tagKind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicVoid } => {
                      return ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              discriminator: KindDynamicVoid;
                              id: bigint;
                              kind: string;
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "discriminator")) {
                          return { success: false };
                        }
                        const parsed_discriminator = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: KindDynamicVoid } => {
                            return ((value: unknown): { success: false } | { success: true; value: "dynamicVoid" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "dynamicVoid":
                                  return { success: true, value: "dynamicVoid" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["discriminator"]);
                        })();
                        if (!parsed_discriminator.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "id")) {
                          return { success: false };
                        }
                        const parsed_id = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                            if (typeof value === "number") {
                              return { success: true, value: BigInt(value) };
                            }
                            if (typeof value === "bigint") {
                              return { success: true, value: value };
                            }
                            return { success: false };
                          })(value["id"]);
                        })();
                        if (!parsed_id.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "kind")) {
                          return { success: false };
                        }
                        const parsed_kind = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["kind"]);
                        })();
                        if (!parsed_kind.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                      })(value);
                    })(value["tagKind"]);
                  })();
                  if (!parsed_tagKind.success) {
                    return { success: false };
                  }
                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { discriminator: "dynamicVoid" as const, ...parsedVariant.value } };
              }
              case "channel": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        exclude: boolean;
                        source: TagSourceType;
                        tagKind: TagKindChannel;
                        value: string;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "exclude")) {
                    return { success: false };
                  }
                  const parsed_exclude = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["exclude"]);
                  })();
                  if (!parsed_exclude.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "source")) {
                    return { success: false };
                  }
                  const parsed_source = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "USER":
                            return { success: true, value: "USER" };
                          case "CHANNEL_HIERARCHY":
                            return { success: true, value: "CHANNEL_HIERARCHY" };
                          case "AI":
                            return { success: true, value: "AI" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["source"]);
                  })();
                  if (!parsed_source.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "tagKind")) {
                    return { success: false };
                  }
                  const parsed_tagKind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagKindChannel } => {
                      return ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              discriminator: KindChannel;
                              id: bigint;
                              kind: KindChannel;
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "discriminator")) {
                          return { success: false };
                        }
                        const parsed_discriminator = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                            return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "channel":
                                  return { success: true, value: "channel" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["discriminator"]);
                        })();
                        if (!parsed_discriminator.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "id")) {
                          return { success: false };
                        }
                        const parsed_id = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                            if (typeof value === "number") {
                              return { success: true, value: BigInt(value) };
                            }
                            if (typeof value === "bigint") {
                              return { success: true, value: value };
                            }
                            return { success: false };
                          })(value["id"]);
                        })();
                        if (!parsed_id.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "kind")) {
                          return { success: false };
                        }
                        const parsed_kind = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                            return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "channel":
                                  return { success: true, value: "channel" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["kind"]);
                        })();
                        if (!parsed_kind.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                      })(value);
                    })(value["tagKind"]);
                  })();
                  if (!parsed_tagKind.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "value")) {
                    return { success: false };
                  }
                  const parsed_value = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["value"]);
                  })();
                  if (!parsed_value.success) {
                    return { success: false };
                  }
                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { discriminator: "channel" as const, ...parsedVariant.value } };
              }
              case "key-quality": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        exclude: boolean;
                        source: TagSourceType;
                        tagKind: TagKindKeyQuality;
                        value: string;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "exclude")) {
                    return { success: false };
                  }
                  const parsed_exclude = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["exclude"]);
                  })();
                  if (!parsed_exclude.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "source")) {
                    return { success: false };
                  }
                  const parsed_source = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "USER":
                            return { success: true, value: "USER" };
                          case "CHANNEL_HIERARCHY":
                            return { success: true, value: "CHANNEL_HIERARCHY" };
                          case "AI":
                            return { success: true, value: "AI" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["source"]);
                  })();
                  if (!parsed_source.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "tagKind")) {
                    return { success: false };
                  }
                  const parsed_tagKind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagKindKeyQuality } => {
                      return ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              discriminator: KindKeyQuality;
                              id: bigint;
                              kind: KindKeyQuality;
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "discriminator")) {
                          return { success: false };
                        }
                        const parsed_discriminator = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                            return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "key-quality":
                                  return { success: true, value: "key-quality" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["discriminator"]);
                        })();
                        if (!parsed_discriminator.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "id")) {
                          return { success: false };
                        }
                        const parsed_id = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                            if (typeof value === "number") {
                              return { success: true, value: BigInt(value) };
                            }
                            if (typeof value === "bigint") {
                              return { success: true, value: value };
                            }
                            return { success: false };
                          })(value["id"]);
                        })();
                        if (!parsed_id.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "kind")) {
                          return { success: false };
                        }
                        const parsed_kind = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                            return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "key-quality":
                                  return { success: true, value: "key-quality" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["kind"]);
                        })();
                        if (!parsed_kind.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                      })(value);
                    })(value["tagKind"]);
                  })();
                  if (!parsed_tagKind.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "value")) {
                    return { success: false };
                  }
                  const parsed_value = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["value"]);
                  })();
                  if (!parsed_value.success) {
                    return { success: false };
                  }
                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { discriminator: "key-quality" as const, ...parsedVariant.value } };
              }
              case "classification": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        exclude: boolean;
                        source: TagSourceType;
                        tagKind: TagKindClassification;
                        value: string;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "exclude")) {
                    return { success: false };
                  }
                  const parsed_exclude = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["exclude"]);
                  })();
                  if (!parsed_exclude.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "source")) {
                    return { success: false };
                  }
                  const parsed_source = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "CHANNEL_HIERARCHY" | "AI" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "USER":
                            return { success: true, value: "USER" };
                          case "CHANNEL_HIERARCHY":
                            return { success: true, value: "CHANNEL_HIERARCHY" };
                          case "AI":
                            return { success: true, value: "AI" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["source"]);
                  })();
                  if (!parsed_source.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "tagKind")) {
                    return { success: false };
                  }
                  const parsed_tagKind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagKindClassification } => {
                      return ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              discriminator: KindClassification;
                              id: bigint;
                              kind: KindClassification;
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "discriminator")) {
                          return { success: false };
                        }
                        const parsed_discriminator = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                            return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "classification":
                                  return { success: true, value: "classification" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["discriminator"]);
                        })();
                        if (!parsed_discriminator.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "id")) {
                          return { success: false };
                        }
                        const parsed_id = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                            if (typeof value === "number") {
                              return { success: true, value: BigInt(value) };
                            }
                            if (typeof value === "bigint") {
                              return { success: true, value: value };
                            }
                            return { success: false };
                          })(value["id"]);
                        })();
                        if (!parsed_id.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "kind")) {
                          return { success: false };
                        }
                        const parsed_kind = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                            return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "classification":
                                  return { success: true, value: "classification" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["kind"]);
                        })();
                        if (!parsed_kind.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                      })(value);
                    })(value["tagKind"]);
                  })();
                  if (!parsed_tagKind.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "value")) {
                    return { success: false };
                  }
                  const parsed_value = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["value"]);
                  })();
                  if (!parsed_value.success) {
                    return { success: false };
                  }
                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { discriminator: "classification" as const, ...parsedVariant.value } };
              }
              default:
                return { success: false };
            }
          })(value);
        })(value);
      })(value["tag"]);
    })();
    if (!parsed_tag.success) {
      return { success: false };
    }
    return { success: true, value: { item: parsed_item.value, tag: parsed_tag.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ToastState
export function serialize_ToastState(obj: ToastState): string {
  const serialized = ((value: { message: string; title: string | null; uuid: string; variant: ToastVariant }): unknown => {
    return {
      message: ((value: string): unknown => {
        return value;
      })(value["message"]),
      title: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["title"]),
      uuid: ((value: string): unknown => {
        return value;
      })(value["uuid"]),
      variant: ((value: "primary" | "success" | "warning" | "danger"): unknown => {
        return value;
      })(value["variant"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ToastState(str: string): ToastState | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          message: string;
          title: string | null;
          uuid: string;
          variant: ToastVariant;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "message")) {
      return { success: false };
    }
    const parsed_message = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["message"]);
    })();
    if (!parsed_message.success) {
      return { success: false };
    }
    if (!hasProp(value, "title")) {
      return { success: false };
    }
    const parsed_title = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["title"]);
    })();
    if (!parsed_title.success) {
      return { success: false };
    }
    if (!hasProp(value, "uuid")) {
      return { success: false };
    }
    const parsed_uuid = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["uuid"]);
    })();
    if (!parsed_uuid.success) {
      return { success: false };
    }
    if (!hasProp(value, "variant")) {
      return { success: false };
    }
    const parsed_variant = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ToastVariant } => {
        return ((value: unknown): { success: false } | { success: true; value: "primary" | "success" | "warning" | "danger" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "primary":
              return { success: true, value: "primary" };
            case "success":
              return { success: true, value: "success" };
            case "warning":
              return { success: true, value: "warning" };
            case "danger":
              return { success: true, value: "danger" };
            default:
              return { success: false };
          }
        })(value);
      })(value["variant"]);
    })();
    if (!parsed_variant.success) {
      return { success: false };
    }
    return { success: true, value: { message: parsed_message.value, title: parsed_title.value, uuid: parsed_uuid.value, variant: parsed_variant.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ToastVariant
export function serialize_ToastVariant(obj: ToastVariant): string {
  const serialized = ((value: "primary" | "success" | "warning" | "danger"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ToastVariant(str: string): ToastVariant | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "primary" | "success" | "warning" | "danger" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "primary":
        return { success: true, value: "primary" };
      case "success":
        return { success: true, value: "success" };
      case "warning":
        return { success: true, value: "warning" };
      case "danger":
        return { success: true, value: "danger" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// Translations
export function serialize_Translations(obj: Translations): string {
  const serialized = ((value: { keys: { additionalProperties: Record<string, string> } }): unknown => {
    return {
      keys: ((value: { additionalProperties: Record<string, string> }): unknown => {
        const unordered_obj: Record<string, unknown> = {
          ...Object.fromEntries(
            Object.entries(value.additionalProperties).map(([propertyKey, propertyValue]) => {
              return [
                propertyKey,
                ((value: string): unknown => {
                  return value;
                })(propertyValue),
              ];
            }),
          ),
        };
        return Object.keys(unordered_obj)
          .sort()
          .reduce(
            (obj, key) => {
              obj[key] = unordered_obj[key];
              return obj;
            },
            {} as Record<string, unknown>,
          );
      })(value["keys"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_Translations(str: string): Translations | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          keys: { additionalProperties: Record<string, string> };
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "keys")) {
      return { success: false };
    }
    const parsed_keys = (() => {
      return ((value: unknown): { success: false } | { success: true; value: { additionalProperties: Record<string, string> } } => {
        if (typeof value !== "object") {
          return { success: false };
        }
        if (value === null) {
          return { success: false };
        }
        const parsed_additionalProperties = ((): { success: false } | { success: true; value: Record<string, string> } => {
          const parsed = Object.entries(value).map(([key, value]): [string, { success: false } | { success: true; value: string }] => {
            return [
              key,
              ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value),
            ];
          });
          if (parsed.find(([_, value]) => !value.success) !== undefined) {
            console.log("1");
            console.log(parsed);
            return { success: false };
          }
          return {
            success: true,
            value: Object.fromEntries(
              parsed.map(([key, value]) => {
                assert(value.success);
                return [key, value.value];
              }),
            ),
          };
        })();
        if (!parsed_additionalProperties.success) {
          return { success: false };
        }
        return { success: true, value: { additionalProperties: parsed_additionalProperties.value } };
      })(value["keys"]);
    })();
    if (!parsed_keys.success) {
      return { success: false };
    }
    return { success: true, value: { keys: parsed_keys.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// VerifaiCreatorPageProps
export function serialize_VerifaiCreatorPageProps(obj: VerifaiCreatorPageProps): string {
  const serialized = ((value: { hashId: string }): unknown => {
    return {
      hashId: ((value: string): unknown => {
        return value;
      })(value["hashId"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_VerifaiCreatorPageProps(str: string): VerifaiCreatorPageProps | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          hashId: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "hashId")) {
      return { success: false };
    }
    const parsed_hashId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["hashId"]);
    })();
    if (!parsed_hashId.success) {
      return { success: false };
    }
    return { success: true, value: { hashId: parsed_hashId.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// VueDemoProps
export function serialize_VueDemoProps(obj: VueDemoProps): string {
  const serialized = ((value: { language: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"; subtitle: string }): unknown => {
    return {
      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
        return value;
      })(value["language"]),
      subtitle: ((value: string): unknown => {
        return value;
      })(value["subtitle"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_VueDemoProps(str: string): VueDemoProps | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          language: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr";
          subtitle: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "language")) {
      return { success: false };
    }
    const parsed_language = (() => {
      return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        switch (value) {
          case "en":
            return { success: true, value: "en" };
          case "de":
            return { success: true, value: "de" };
          case "es":
            return { success: true, value: "es" };
          case "ru":
            return { success: true, value: "ru" };
          case "zh":
            return { success: true, value: "zh" };
          case "pt":
            return { success: true, value: "pt" };
          case "fr":
            return { success: true, value: "fr" };
          default:
            return { success: false };
        }
      })(value["language"]);
    })();
    if (!parsed_language.success) {
      return { success: false };
    }
    if (!hasProp(value, "subtitle")) {
      return { success: false };
    }
    const parsed_subtitle = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["subtitle"]);
    })();
    if (!parsed_subtitle.success) {
      return { success: false };
    }
    return { success: true, value: { language: parsed_language.value, subtitle: parsed_subtitle.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}
