import { getHttpClient } from "@utils/httpClient";
import type Vue from "vue";
import type { PluginFunction } from "vue";
import type { Language } from "@utils/type/type";

export type RoleResource =
  | "ROLE_ADMIN"
  | "PPS_EDITORIAL"
  | "PPS_NEW_CONTENT_INPUT"
  | "PPS_NEW_CONTENT_INPUT_ADMIN"
  | "PPS_PORTAL"
  | "PPS_API_PRODUCTION_POINTS"
  | "PPS_API_PRODUCTION_POINTS_ADMIN"
  | "PPS_API_OFFER"
  | "PPS_API_WRITING_OFFER"
  | "PPS_API_EDITING_OFFER"
  | "PPS_FLOW"
  | "PPS_FLOW_ADMIN"
  | "PPS_API_JOB"
  | "PPS_API_INVOICE"
  | "PPS_AUTHOR_TOOL"
  | "PPS_RIGHTS_FLOW"
  | "PPS_RIGHTS_TEAM_REPORTS"
  | "PPS_RIGHTS"
  | "ADMIN_MARKETING_AUTHOR_TOOL"
  | "READ_ONLY_MARKETING_AUTHOR_TOOL"
  | "PPS_ALLSUMMARIES_LIST"
  | "PPS_GLOBAL_MOST_WANTED_SUMMARY_REPORT"
  | "PPS_USER_API"
  | "PPS_TEAM_MOST_WANTED_EDITORIAL_SUMMARY_REPORT"
  | "PPS_PERSONAL_MOST_WANTED_EDITORIAL_SUMMARY_REPORT"
  | "PPS_TEAM_MOST_WANTED_RIGHTS_SUMMARY_REPORT"
  | "PPS_PERSONAL_MOST_WANTED_RIGHTS_SUMMARY_REPORT"
  | "PPS_EDITORIAL_TEAM_REPORTS"
  | "PPS_PAYMENT_TOOL"
  | "PPS_VIEW"
  | "CHANNEL_ADMIN"
  | "ASP_ACCOUNT_ADMIN"
  | "ASP_STATISTICS"
  | "ASP_USER_ADMIN"
  | "PORTLET_ADMIN"
  | "ASP_LOGO_UPLOAD"
  | "PROMOBOXES"
  | "ASP_USER_ADMIN_LASTACCESS"
  | "PPS_COMPONENT_DEMO"
  | "ASP_MASSIMPORT_TOOL_EXTENDED"
  | "CUSTOMER_ADMIN"
  | "CUSTOMER_ADMIN_FINANCE"
  | "CUSTOMER_ADMIN_SUBSCRIPTION"
  | "PPS_USER_BETA_TEST"
  | "PPS_EXPENSE_TOOL"
  | "ADMIN_RELOAD"
  | "PPS_FREELANCER_CONTACT_TOOL"
  | "CORPORATE_CONTACT"
  | "CUSTOM_PAGE_ADMIN"
  | "PPS_PUBLISHERADMIN"
  | "VERIFY_DASHBOARD"
  | "VERIFY_INPUT_TOOL"
  | "VERIFY_PUBLISH"
  | "VERIFY_UPLOAD"
  | "ADMIN_TOOL"
  | "CSM_ADVANCED_SEARCH"
  | "VERIFY_VERIFICATION"
  | "VERIFY_EDITING";

class RoleResourceConstraint {
  resource: RoleResource | null;
  languages: Language[];
  corporateIds: number[];
  customerId: number;

  constructor(resource: RoleResource | null, languages: Language[], corporateIds: number[], customerId: number) {
    this.resource = resource;
    this.languages = languages;
    this.corporateIds = corporateIds;
    this.customerId = customerId;
  }

  hasLanguage(language?: Language): boolean {
    if (language === undefined || this.languages.length === 0) {
      return true;
    }
    return this.languages.includes(language);
  }

  hasCorporation(corporationId: number | undefined): boolean {
    if (corporationId === undefined || this.corporateIds.length === 0) {
      return true;
    }
    return this.corporateIds.includes(corporationId);
  }
}

const fetchedConstraints: RoleResourceConstraint[] = [];

export async function fetchRoleResources(): Promise<void> {
  const response = await getHttpClient().get<RoleResourceConstraint[]>("/gaapi/resources");
  const instances = response.data.map((constraint: RoleResourceConstraint) => {
    return new RoleResourceConstraint(constraint.resource, constraint.languages, constraint.corporateIds, constraint.customerId);
  });
  fetchedConstraints.push(...instances);
}

export const hasAccess = (resource: RoleResource, language?: Language, corporateId?: number): boolean => {
  const constraint = fetchedConstraints.find((roleResourceConstraint) => roleResourceConstraint.resource === resource);
  return constraint !== undefined && constraint.hasLanguage(language) && constraint.hasCorporation(corporateId);
};

export const getCustomerIdFromConstraints = (resource: RoleResource): number | null => {
  const constraint = fetchedConstraints.find((roleResourceConstraint) => roleResourceConstraint.resource === resource);
  return constraint?.customerId ?? null;
};

export class RoleService {
  constructor() {}

  static install: PluginFunction<never> = (vue: typeof Vue): void => {
    vue.prototype.$access = hasAccess;

    vue.prototype.$accessibleLanguagesOf = (resource: RoleResource, corporateId?: number): Language[] => {
      const constraint = fetchedConstraints.find((roleResourceConstraint) => roleResourceConstraint.resource === resource);

      if (constraint !== undefined && constraint.hasCorporation(corporateId)) {
        return constraint.languages;
      }
      return [];
    };
  };
}

declare module "vue/types/vue" {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Vue {
    $access: (resource: RoleResource, language?: Language, corporateId?: number) => boolean;
    $accessibleLanguagesOf: (resource: RoleResource, corporateId?: number) => number[];
  }
}
